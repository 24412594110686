import { pathname } from '@consts';
import type { Navigation } from '@utils/interfaces';
import { currentLocation, fromQuery } from './navigation.shared';

const getSchedulingProposingLocation = (data: Navigation.Scheduling.Proposal) => {
  return {
    pathname: `${pathname.SCHEDULING}/${data.projectId}/calls/${data.callId}/proposals`,
    search: fromQuery(),
    state: {
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId, /* Current user */
    },
  };
};

const getSchedulingProvidingLocation = (data: Navigation.Scheduling.Invitation) => {
  return {
    pathname: `${pathname.SCHEDULING}/${data.projectId}`,
    search: fromQuery(),
    state: {
      creatorId: data.creatorId,     /* Current user */
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId, /* Current user */
      userId: data.userId,
    },
  };
};

const getSchedulingSelectingLocation = (data: Navigation.Scheduling.Confirmation) => {
  return {
    pathname: `${pathname.SCHEDULING}/${data.projectId}/calls/${data.callId}`,
    search: fromQuery(),
    state: {
      scheduleeId: data.scheduleeId, /* Current user */
      schedulerId: data.schedulerId,
    },
  };
};

const getAdHocSchedulingProposingLocation = (data: Omit<Navigation.Scheduling.Proposal, 'projectId'>) => {
  return {
    pathname: `${pathname.SCHEDULING}/calls/${data.callId}/proposals`,
    search: fromQuery(),
    state: {
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId, /* Current user */
    },
  };
};

const getAdHocSchedulingProvidingLocation = (data: Omit<Navigation.Scheduling.Invitation, 'projectId'>) => {
  return {
    pathname: `${pathname.SCHEDULING}`,
    search: fromQuery(),
    state: {
      creatorId: data.creatorId,     /* Current user */
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId, /* Current user */
      userId: data.userId,
    },
  };
};

const getAdHocSchedulingSelectingLocation = (data: Omit<Navigation.Scheduling.Confirmation, 'projectId'>) => {
  return {
    pathname: `${pathname.SCHEDULING}/calls/${data.callId}`,
    search: fromQuery(),
    state: {
      scheduleeId: data.scheduleeId, /* Current user */
      schedulerId: data.schedulerId,
    },
  };
};

export const scheduling = {
  adHoc: {
    proposing: getAdHocSchedulingProposingLocation,
    providing: getAdHocSchedulingProvidingLocation,
    selecting: getAdHocSchedulingSelectingLocation,
  },
  proposing: getSchedulingProposingLocation,
  providing: getSchedulingProvidingLocation,
  selecting: getSchedulingSelectingLocation,
};