import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ConferenceTagSummaryItem } from '@/types/conferences.tags';
import * as $api from '@api';
import { TagSummaryVersionsModal } from '@/components/ConferenceTag.Summary/Modal.Versions';
import { ToggleTagSumaryVersionsContext } from './context';

type Props =
  ITranscriptId &
  ChildrenProps;

export const TranscriptSummaryVersionsContainer = ({ transcriptId, children }: Props) => {

  const [openItem, setOpenItem] = useState<ConferenceTagSummaryItem>();

  const tagSummaryId = openItem?.summary?.id;

  const {
    data,
    isInitialLoading: isLoading,
    isFetching,
  } = useQuery({ queryKey: ['get:transcript:tag-summaries:versions', {
    tagSummaryId,
  }], queryFn: ({ queryKey }) => {
    return $api.transcripts.getTagSummaryVersions({
      transcriptId,
      tagSummaryId,
    });
  }, enabled: !!tagSummaryId, refetchOnWindowFocus: false, placeholderData: {
    items: [],
  } });

  const handleOpen = useCallback((item: ConferenceTagSummaryItem) => {
    setOpenItem(item);
  }, []);

  const handleClose = useCallback(() => {
    setOpenItem(null);
  }, []);

  const open = !!tagSummaryId;

  return (
    <ToggleTagSumaryVersionsContext.Provider value={handleOpen}>
      {children}
      {open && (
        <TagSummaryVersionsModal
          open={open}
          onClose={handleClose}
          versions={data.items}
          summaryItem={openItem} />
      )}
    </ToggleTagSumaryVersionsContext.Provider>
  );
};