import { memo } from 'react';
import type { NotificationEvent } from '@enums';
import type { Notification as N } from '@/types';
import { getLocationFor } from '@utils';
import { NotificationAction, NotificationActions } from './Notification.Actions';
import { AdHocCallNotification } from './Notification.AdHocCall';

type Props = {
  item: N<NotificationEvent.AdHocCallRequested>;
};

export const AdHocCallRequestedNotification = memo(({ item }: Props) => {

  const to = getLocationFor.onboarding.call.details({ callId: item.payload.data.call.id });

  return (
    <AdHocCallNotification item={item}>
      <NotificationActions>
        <NotificationAction
          to={to}
          title="Review" />
      </NotificationActions>
    </AdHocCallNotification>
  );
});

export default AdHocCallRequestedNotification;