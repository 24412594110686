import type { EventInput } from '@fullcalendar/core';
import { addMinutes, startOfDay, startOfMinute, getMinutes } from 'date-fns';
import styles from './style/Calendar.css';

export const generateBackgroundEventSource = (): EventInput[] => {
  const now = Date.now();
  const delta = 30 - (getMinutes(now) % 30); // match slotDuration
  return [
    {
      start: startOfDay(now),
      end: startOfMinute(addMinutes(now, delta)),
      rendering: 'background',
      backgroundColor: '#ccc',
      extendedProps: {
        canRemove: false,
        isNew: false,
        type: 'past',
      },
    },
  ];
};

export const generateMoratoriumEventSource = (data: { minutes: number }): EventInput[] => {
  const minutes = data.minutes || 0;
  if (minutes <= 0) return [];

  const now = Date.now();
  const delta = 30 - (getMinutes(now) % 30); // match slotDuration
  return [
    {
      classNames: [ styles.moratorium ],
      start: startOfMinute(addMinutes(now, delta)),
      end: startOfMinute(addMinutes(now, minutes + delta)),
      rendering: 'background',
      backgroundColor: 'none',
      extendedProps: {
        canRemove: false,
        isNew: false,
        type: 'moratorium',
      },
    },
  ];
};