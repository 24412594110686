import { useContext, memo } from 'react';
import { Link } from 'react-router-dom';
import { pathname } from '@consts';
import type { RecentProfile } from '@/types';
import { getLocationFor, slugify } from '@utils';
import { useRecentProfiles } from '@utils/api';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { ResetContext } from './Context';
import styles from './style/RecentProfiles.css';

export default function RecentProfiles() {
  const { clickedResultReset } = useContext(ResetContext);
  const profiles = useRecentProfiles({
    notifyOnChangeProps: ['data'],
  });

  return (
    <div className={styles.root}>
      <div className={styles.title}>Recent Profiles</div>
      <div className={styles.profiles}>
        {profiles.data?.length
          ? profiles.data.map(item =>
            (
              <Profile
                key={item.id}
                item={item}
                onClick={clickedResultReset} />
            ))
          : <Nothing />}
      </div>
      <div className={styles.findExperts}>
        <Link to={pathname.SEARCH} onClick={clickedResultReset}>
          {`Find Experts >`}
        </Link>
      </div>
    </div>
  );
}

function Nothing() {
  return (
    <div className={styles.nothing}>
      No recent profiles
    </div>
  );
}

const Profile = memo(({ item, onClick }: ProfileProps) => {
  const profileLocation = getLocationFor.user.profile({
    slug: slugify({
      id: item.id,
      name: item.contact.profile.fullname,
    }) });

  return (
    <div
      className={styles.profile}
      onClick={onClick}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={item.contact.profile.pictureUrl}
        to={profileLocation}
        size={45} />
      <Link
        className={styles.name}
        to={profileLocation}>
        {item.contact.profile.fullname}
      </Link>
    </div>
  );
});

type ProfileProps = {
  item: RecentProfile;
  onClick: () => unknown;
};

export { RecentProfiles };