import { useCallback } from 'react';
import type { SurveyMetrics } from '@/types';
import { AdvancedQuotaTable } from '@/components/SurveyBuilder.Quota/Preview.Advanced.Table';
import styles from './style/AdvancedQuotaProgress.css';

type Props = {
  item: SurveyMetrics.AdvancedQuota.Progress;
};

export const AdvancedQuotaProgress = ({ item }: Props) => {

  const renderCell = useCallback((data: SurveyMetrics.AdvancedQuota.SetItem['data']) => {

    return (
      <>
        {data.total}/{data.limit}
      </>
    );
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{item.quota.name}</div>
      </div>
      <AdvancedQuotaTable
        items={item.items}
        numberOfSets={item.numberOfSets}
        renderLimitCell={renderCell} />
    </div>
  );
};

export default AdvancedQuotaProgress;