import { useCallback, useContext } from 'react';
import type * as API from '@api/interfaces';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { AccessRequestContext } from './Context';
import styles from './style/Modal.ApproveAccess.css';

type Props = {
  loading: boolean;
  onSubmit: (params: API.Access.UpdateRequestApproval.Request) => Promise<void>;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const DeclineAccess = (props: Props) => {
  const ctx = useContext(AccessRequestContext);

  const handleSubmit = useCallback(() => {
    props.onSubmit({
      objectId: ctx.object.id,
      requestId: ctx.request.id,
    })
    .then(() => props.onClose());
  }, [
    ctx.object.id,
    ctx.request.id,
    props,
  ]);

  return (
    <Modal
      disableOverlayClick={props.loading}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>Decline request?</div>
          <div className={styles.main}>
            <div className={styles.subtitle}>{`${ctx.user.name} will not receive access to ${ctx.object.name}.`}</div>
          </div>
          <div className={styles.footer}>
            <ButtonActivityIndicator
              className={styles.btn}
              loading={props.loading}
              onClick={handleSubmit}>
              Decline
            </ButtonActivityIndicator>
            <ButtonOutlined
              className={styles.btn}
              disabled={props.loading}
              color="silver"
              onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
          </div>
        </div>
      </div>
    </Modal>
  );
};

DeclineAccess.displayName = 'Modal.DeclineAccess';