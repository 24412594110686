import { generatePath } from 'react-router-dom';
import { memo } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import PinIcon from '@mui/icons-material/FiberPin';
import { cx, pin as pinUtils, path } from '@utils';
import type { CallRole } from '@enums';
import { utils as enumUtils } from '@enums';
import * as consts from '@consts';
import styles from './style/ConferenceLink.css';

type Props = {
  conferenceIdentifier: number;
  pins: Record<CallRole, string>;
};

export const GuestLinks = ({ conferenceIdentifier, pins }: Props) => {
  return (
    <div>
      <div className={styles.header}>
        Guest Links
      </div>
      {Object.entries(pins).map(([role, pin]) => (
        <div key={role}>
          <div className={styles.subheader}>{enumUtils.CallRole.getName(+role as CallRole)}</div>
          <GuestLink
            pin={pin}
            role={+role as CallRole}
            conferenceIdentifier={conferenceIdentifier} />
        </div>
      ))}
    </div>
  );
};

type GuestLinkProps = {
  conferenceIdentifier: number;
  pin: string;
  role: CallRole;
  className?: string;
};
export const GuestLink = ({ conferenceIdentifier, pin, role, className }: GuestLinkProps) => {
  const url = `${process.env.FRONT_BASE_URL}${generatePath(consts.path.Conference.Entry, { conferenceIdentifier })}${pin ? `?pin=${pin}` : ''}`;

  const displayPin = pinUtils.format(pin);

  return (
    <div className={cx(className)}>
      <div className={styles.url}>
        <LinkIcon />
        <div className={styles.details}>
          {url}
        </div>
      </div>
      {pin && (
        <div className={styles.pin}>
          <PinIcon />
          <div className={styles.details}>
            {displayPin}
          </div>
        </div>
      )}
    </div>
  );
};