import type { SurveyQuota, SurveyBuilderVersion } from '@/types/survey';
import {
  validateAction,
  validateLimit,
  validateName,
} from './validation.shared';

export function validateAdvancedQuota(item: SurveyQuota.Advanced.Record, survey: SurveyBuilderVersion) {

  const action = validateAction(item.actionType);

  const limit = validateLimit(item.limit);

  const name = validateName(item.name);

  const success = action.success &&
    limit.success &&
    name.success;

  return {
    action,
    limit,
    name,
    success,
  };
}