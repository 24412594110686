import type { ChangeEvent } from 'react';
import { useCallback, useContext, useMemo } from 'react';
import type { CellProps } from 'react-table';
import { Checkbox } from '@/components/Checkbox';
import { LeadsResultsContext, SelectedContext } from '$admin/Search/Leads/containers';
import type { CellItem } from '$admin/Search/Leads/interfaces';

type Props = CellProps<CellItem>;

export const Cell = (props: Props) => {
  const [selected, setSelected] = useContext(SelectedContext);

  const checked = useMemo(() => {
    return selected.ids.has(props.row.original.id);
  }, [
    selected,
    props.row.original.id,
  ]);

  const handleChange = useCallback((e: ChangeEvent) => {
    setSelected(prev => {
      const mapped = props.row.original;

      if (checked) {
        prev.ids.delete(mapped.id);
      } else {
        prev.ids.add(mapped.id);
      }

      const itemsCopy = checked
        ? prev.items.filter(i => i.id !== mapped.id)
        : [...prev.items, mapped];

      return {
        ids: prev.ids,
        items: itemsCopy,
      };
    });
  }, [
    checked,
    props.row.original,
    setSelected,
  ]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: `100%`,
    }}>
      <Checkbox
        checked={checked}
        onChange={handleChange} />
    </div>
  );
};

Cell.displayName = 'Column.Checkbox.Cell';

export const Filter = (props: Props) => {
  const query = useContext(LeadsResultsContext);
  const [selected, setSelected, clear] = useContext(SelectedContext);

  const checked = useMemo(() => {
    return !!selected.ids.size;
  }, [selected.ids.size]);

  const indeterminate = useMemo(() => {
    return selected.ids.size > 0
      && selected.ids.size !== query.data?.pagination?.totalCount;
  }, [
    query.data?.pagination?.totalCount,
    selected.ids.size,
  ]);

  const rowsOnPage = useMemo(() => {
    return props.rows.map(r => r.original);
  }, [props.rows]);

  const userIdsOnPage = useMemo(() => {
    return rowsOnPage.map(m => m.id);
  }, [rowsOnPage]);

  const handleChange = useCallback(() => {
    if (indeterminate) {
      if (setContainsAll(selected.ids, userIdsOnPage)) {
        setSelected(prev => ({
          ids: new Set(Array.from(prev.ids).filter(e => !userIdsOnPage.some(p => p === e))),
          items: prev.items.filter(e => !rowsOnPage.some(p => p.id === e.id)),
        }));
      } else {
        setSelected(prev => ({
          ids: new Set([...prev.ids, ...userIdsOnPage]),
          items: [...prev.items, ...rowsOnPage],
        }));
      }
    } else {
      if (selected.ids.size) {
        clear();
      } else {
        setSelected({
          ids: new Set(userIdsOnPage),
          items: rowsOnPage,
        });
      }
    }
  }, [
    clear,
    indeterminate,
    rowsOnPage,
    selected,
    setSelected,
    userIdsOnPage,
  ]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: `100%`,
    }}>
      <Checkbox
        checked={checked}
        disabled={!props.rows.length}
        indeterminate={indeterminate}
        onChange={handleChange} />
    </div>
  );
};

function setContainsAll<T>(source: Set<T>, check: T[]) {
  for (const value of check) {
    if (!source.has(value)) return false;
  }
  return true;
}