import { useEffect } from 'react';
import { external as $service } from '@services/api/projects';
import { ExternalProjectSecretType } from '@enums';
import { SurveyQuotaOverLimit } from '@screens/ProjectDisqualification';
import { RedirectWrapper } from './RedirectWrapper';
import { useParams, useCompleteProject } from './hooks';
import type { SecretParams } from './interfaces';

export const ProjectQuotaLimit = () => {
  const params = useParams(ExternalProjectSecretType.QuotaExceeded);
  return (
    <RedirectWrapper {...params}>
      <ProjectQuotaLimitInner {...params} />
    </RedirectWrapper>
  );
};

const ProjectQuotaLimitInner = (props: SecretParams) => {
  const completeProject = useCompleteProject(props);

  useEffect(() => {
    $service.completeProject(props)
      .then(r => {
        completeProject();
      })
      .catch(e => {
        alert('There was an error saving your project response.');
        throw e;
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SurveyQuotaOverLimit />
  );
};