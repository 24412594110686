import { useMemo } from 'react';
import { SurveyTemplateType } from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import { SurveyTemplateAutocomplete } from './Input.Autocomplete';

type Props = {
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  template: SurveyTemplateType;
  value: SurveyTemplate.LinkedEntity;
};

export const TemplateTargetInput = ({ onChange, template, value }: Props) => {

  const includeProducts = useMemo(() => template !== SurveyTemplateType.EmployeeSatisfaction, [template]);

  const placeholder = includeProducts
    ? 'Enter a company or product name'
    : 'Enter a company';

  const source = {
    companies: true,
    products: includeProducts,
  };

  return (
    <SurveyTemplateAutocomplete
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      source={source} />
  );
};
