import http from '@services/http';
import { qs } from '@utils';
import type { Posts } from '$admin/api/interfaces';

export const fetchItems = (data: Posts.FetchItems.Request) => {
  const q = qs.stringify({
    index: data.index,
    size: data.size,
  });

  return http.get<Posts.FetchItems.Response>(`/admin/posts?${q}`);
};

export * as uploads from './posts.uploads';
export * as videos from './posts.videos';
export * as visibility from './posts.visibility';