import { memo } from 'react';
import { useSelectUser } from '@containers/Store';
import type { NotificationEvent } from '@enums';
import type { Notification as N } from '@/types';
import { getLocationFor } from '@utils';
import { NotificationAction, NotificationActions } from './Notification.Actions';
import { AdHocCallNotification } from './Notification.AdHocCall';

type Props = {
  item: N<NotificationEvent.AdHocCallTimesProposed>;
};

export const AdHocCallTimesProposedNotification = memo(({ item }: Props) => {
  const me = useSelectUser();

  const to = getLocationFor.scheduling.adHoc.selecting({
    callId: item.payload.data.call.id,
    schedulerId: item.payload.data.user.id,
    scheduleeId: me.id,
  });

  return (
    <AdHocCallNotification item={item}>
      <NotificationActions>
        <NotificationAction
          to={to}
          title="Review" />
      </NotificationActions>
    </AdHocCallNotification>
  );
});

export default AdHocCallTimesProposedNotification;