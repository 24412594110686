import { useCallback } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import * as enums from '@enums';
import type { ProjectSurveyDetailsInternalUser } from '@/types';
import { cx, getLocationFor, hasInternalAdminRole } from '@utils';
import { useSelectUser } from '@containers/Store';
import { EmploymentHistory } from '@/scenes/surveys/CompletedSurvey/EmploymentHistory';
import { SurveyDetail } from '@/scenes/surveys/CompletedSurvey/SurveyDetail';
import { SurveyResponseRating } from '@/components/SurveyResponseRating';
import { DocumentDownload } from '$admin/components/DocumentDownload';
import type { SurveyDetailsProps } from './interfaces';
import { useTerminationModal } from './TerminationModal';
import styles from './style.css';

export const SurveyDetailsInternalUser = (props: SurveyDetailsProps<ProjectSurveyDetailsInternalUser>) => {
  const user = useSelectUser();
  const completedOn = props.survey.statusId !== enums.ProjectSurveyResponseStatus.Incomplete
    ? format(new Date(props.survey.completedOn), 'MMM do yyyy')
    : 'N/A';

  const [toggleTerminationModal, TerminationModal] = useTerminationModal();

  const renderDocument = () => {
    if (props.project.documentSigning === enums.DocumentSigning.NotRequired) {
      return 'Not Required';
    }

    return (
      <DocumentDownload
        filename={`${props.user.profile.fullname} ${format(new Date(), 'M/d/yyyy')}.pdf`}
        item={props}>
        <div className={styles.link}>Download</div>
      </DocumentDownload>
    );
  };

  const renderVerification = () => (
    <span className={cx({
      [styles.unverified]: props.user.verificationStatusId === enums.VerificationStatus.New,
    })}>
      {enums.utils.VerificationStatus.getName(props.user.verificationStatusId)}
    </span>
  );

  const renderTermination = useCallback(() => {
    if (!props.termination) return null;

    return (
      <div className={styles.termination} onClick={toggleTerminationModal}>View Termination Reason</div>
    );
  }, [
    props.termination,
    toggleTerminationModal,
  ]);

  return (
    <div className={styles.details}>
      {hasInternalAdminRole(user) &&
        <SurveyDetail
          name="Group">
          {props.group.name}
        </SurveyDetail>
      }
      <SurveyDetail
        name={'Project'}>
        {props.project.name}
      </SurveyDetail>
      <SurveyDetail
        name={'Completed On'}>
        {completedOn}
      </SurveyDetail>
      <SurveyDetail
        name={'Document'}>
        {renderDocument()}
      </SurveyDetail>
      {hasInternalAdminRole(user) &&
        <SurveyDetail name="Client Fee">
          {
            props.billing?.chargeable?.id
              ? (
                <Link to={getLocationFor.admin.billing.chargeables.details({
                  chargeableId: props.billing.chargeable.id,
                })}>
                  ${props.billing.chargeable.amount}
                </Link>
              ) : 'Not available'
          }
        </SurveyDetail>
      }
      {hasInternalAdminRole(user) &&
        <SurveyDetail name="Expert Honoraria">
          {
            props.billing?.payoutable?.id
              ? (
                <Link to={getLocationFor.admin.billing.payoutables.details({
                  payoutableId: props.billing.chargeable.id,
                })}>
                  ${props.billing.payoutable.amount}
                </Link>
              ) : 'Not available'
          }
        </SurveyDetail>
      }
      {!!props.survey.classifications.length &&
        <SurveyDetail
          name="Classifications">
          {props.survey.classifications.map(m => m.name).join(', ')}
        </SurveyDetail>}
      <div className={styles.label}>Employments</div>
      <EmploymentHistory
        items={props.user.employments} />
      <div className={styles.label}>Rating</div>
      <SurveyResponseRating
        rating={props.rating}
        projectSurveyResponseId={props.project.responseId}
        projectId={props.project.id} />
      {renderTermination()}
      <TerminationModal termination={props.termination} />
    </div>
  );
};

export default SurveyDetailsInternalUser;