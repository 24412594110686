import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { ButtonActivityIndicator } from '@presentation';
import type { Currencies } from '@/types';
import { Input, NumberInput } from '@/components/Input';
import { SelectSearchUnderlined } from '$admin/components/Select';
import * as $honoraria from '$admin/components/Project.Honoraria/utils';
import type { HonorariumProps } from './interfaces';
import styles from './style/Popper.css';

type Props = {
  titles: string[];
} & HonorariumProps;

export const Honorarium = ({ titles = [], onSubmit, ...props }: Props) => {
  const [currency, setCurrency] = useState<Currencies.Item>(null);
  const [title, setTitle] = useState($honoraria.getInitialTitle(titles));
  const [amount, setAmount] = useState('');
  const query = useQuery({ queryKey: [`get:search/currencies`], queryFn: api.search.fetchCurrencies });

  const value = useMemo(() => {
    return currency?.sign ? amount.slice(1) : amount;
  }, [
    amount,
    currency,
  ]);

  const disabled = useMemo(() => {
    const valid = title.trim().length > 0
        && !!currency?.code
        && value
        && Number.isFinite(+value);

    return !valid;
  }, [
    currency,
    title,
    value,
  ]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      currency: currency.code,
      title: title.trim(),
      value: +value,
    });
  }, [
    currency,
    onSubmit,
    title,
    value,
  ]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>Title</div>
        <div className={styles.field}>
          <Input
            onChange={e => setTitle(e.target.value)}
            value={title} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Currency</div>
        <div className={styles.field}>
          <SelectSearchUnderlined
            getOptionLabel={option => option.sign ? `${option.code} (${option.sign})` : option.code}
            getOptionValue={option => option.code}
            onChange={(val: Currencies.Item) => setCurrency(val)}
            options={query.data?.items ?? []}
            placeholder="Select Currency"
            value={currency} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Amount</div>
        <div className={styles.field}>
          <NumberInput
            classes={{ input: styles.amount }}
            decimalScale={2}
            name="amount"
            onChange={e => setAmount(e.target.value)}
            placeholder={`${currency?.sign ?? ''}100.00`}
            prefix={currency?.sign ?? ''}
            value={amount} />
        </div>
      </div>
      <div className={styles.row}>
        <ButtonActivityIndicator
          className={styles.btn}
          color="secondary"
          disabled={disabled}
          implicitDisable={false}
          loading={props.loading}
          onClick={handleSubmit}
          variant="brick">
          Create Honoraria
        </ButtonActivityIndicator>
      </div>
    </>
  );
};

Honorarium.displayName = 'Project.Share.Honorarium';