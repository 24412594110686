import { Node } from 'slate';

export function getRawText(nodes: Node[]): string {
  const raw = nodes.map(node => Node.string(node)).join('');
  return raw;
}

export function getCharacterCount(nodes: Node[]): number {
  return getRawText(nodes).length;
}

export function isValid(nodes: Node[], minLength: number, maxLength: number) {
  const count = getCharacterCount(nodes);

  return count >= minLength &&
    count <= maxLength;
}