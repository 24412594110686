import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useEditorState } from '@remirror/react';
import * as api from '@api';
import { RefetchTranscriptContext } from '@containers/Transcript/context';
import { useTranscriptDocumentContext, useTranscriptEnhancementState, useUpdateEditorView } from '@containers/Transcript/hooks';
import { useTranscriptLockContext, useTranscriptRedactingContext } from '@containers/Transcript.Redaction/hooks';
import type { TranscribeRT } from '@/types';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { Button } from '@/components/Button';
import { useAlert, Alert } from '@/components/Modal/Alert';
import { FindReplaceContext } from '@/components/Transcript/context';
import { useRedactHighlightUndo } from '@/components/Transcript/hooks';
import { useRedactTranscript } from './hooks/useRedactTranscript';
import { useHasUnsavedChanges, useSaveTranscript } from './hooks';
import styles from './style/Toolbar.css';

type Props = unknown;

export const Toolbar = (props: Props) => {
  const [isLocked] = useTranscriptLockContext();
  const redactionContext = useTranscriptRedactingContext();
  const { query, stopFind } = useContext(FindReplaceContext);

  useEffect(() => {

    if (query && redactionContext.redactedRanges.length) {
      stopFind();
    }

  }, [
    query,
    redactionContext.redactedRanges.length,
    stopFind,
  ]);

  if (isLocked) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <SaveButtons />
      </div>
    </div>
  );
};

export const SaveButtons = () => {
  const transcript = useTranscriptDocumentContext();
  const saveTranscriptMutation = useSaveTranscript({ transcriptId: transcript.id });
  const enhancementState = useTranscriptEnhancementState();
  const redactionContext = useTranscriptRedactingContext();
  const hasUnsavedChanges = useHasUnsavedChanges();

  const isRevision = useMemo(() => enhancementState.user.isAssignee && enhancementState.status.isInProgress, [enhancementState.status.isInProgress, enhancementState.user.isAssignee]);

  const [showRedactAlert, RedactAlert] = useAlert();

  const onSave = useCallback(() => {
    if (isRevision || !(redactionContext.redactedRanges?.length > 0)) {
      saveTranscriptMutation.mutate();
    } else {
      showRedactAlert();
    }
  }, [isRevision, redactionContext.redactedRanges?.length, saveTranscriptMutation, showRedactAlert]);

  const [_, setTranscriptLocked] = useTranscriptLockContext();
  const onSaveConfirm = useCallback(() => {
    saveTranscriptMutation.mutateAsync().then(() => {
      setTranscriptLocked(true);
    });
  }, [saveTranscriptMutation, setTranscriptLocked]);

  const undo = useRedactHighlightUndo();

  if (!hasUnsavedChanges) return null;

  return (
    <>
      <div className={styles.btns}>
        {redactionContext.redactedRanges?.length > 0 &&
          <Button
            className={styles.btn}
            onClick={undo}
            color="secondary"
            size="small"
            variant="brick">
            Undo Redactions
          </Button>
        }
        <ButtonActivityIndicator
          loading={saveTranscriptMutation.isLoading}
          className={styles.btn}
          onClick={onSave}
          size="small"
          variant="brick">
          Save Changes
        </ButtonActivityIndicator>
      </div>
      <RedactAlert message={<RedactAlertMessage />} onConfirm={onSaveConfirm} />
      <RouteLeavingGuard block={hasUnsavedChanges}>
        {guard => (
          <Alert
            onConfirm={guard.confirmNavigation}
            onClose={guard.closePrompt}
            open={guard.open}
            cancelText='Go back'
            message={'You have unsaved transcript changes. Are you sure you want to leave without saving?'} />
        )}
      </RouteLeavingGuard>
    </>
  );
};

export const Redaction = () => {
  const transcript = useTranscriptDocumentContext();
  const refetch = useContext(RefetchTranscriptContext);
  const redactionContext = useTranscriptRedactingContext();
  const redactTranscript = useRedactTranscript({
    transcriptId: transcript?.id,
    redactRanges: redactionContext.redactedRanges,
  });
  const [_, setTranscriptLocked] = useTranscriptLockContext();

  const lockTranscript = useCallback(() => {
    setTranscriptLocked(true);
  }, [setTranscriptLocked]);

  const [showRedactAlert, RedactAlert] = useAlert();

  const editor = useEditorState();

  const formatUpdatedTranscript = useCallback((json: TranscribeRT.DocumentNode): TranscribeRT.Transcript => {
    return {
      document: json,
      id: transcript.id,
      source: transcript.source,
      version: transcript.version,
    };
  }, [transcript]);

  const onRedactClick = useCallback(() => {
    redactTranscript.mutateAsync({
      transcript: formatUpdatedTranscript(editor.doc.toJSON() as TranscribeRT.DocumentNode),
    }).then(lockTranscript);
  }, [editor.doc, formatUpdatedTranscript, redactTranscript, lockTranscript]);

  const undo = useRedactHighlightUndo();

  const updateEditorView = useUpdateEditorView();

  const mutation = useMutation({ mutationKey: [
    `put:transcripts/text/redactions`,
    redactionContext.redactedRanges,
    transcript.id,
  ], mutationFn: () => {
    return api.transcripts.text.storeTextRedactions({
      document: editor.doc.toJSON() as TranscribeRT.DocumentNode,
      redactions: redactionContext.redactedRanges,
      transcriptId: transcript.id,
    })
      .then(() => {
        return refetch()
          .then(res => updateEditorView(res.data.transcript.document))
          .then(() => redactionContext.setRedacting(false));
      });
  } });

  const state = useTranscriptEnhancementState();

  const handleClick = useCallback(() => {
    return state.user.isAssignee && state.status.isInProgress
      ? mutation.mutate()
      : showRedactAlert();
  }, [
    mutation,
    showRedactAlert,
    state,
  ]);

  const saveTranscriptMutation = useSaveTranscript({ transcriptId: transcript.id });

  return (
    <div className={styles.btns}>
      <Button
        className={styles.btn}
        color="secondary"
        disabled={mutation.isLoading}
        onClick={undo}
        size="small"
        variant="brick">
        Cancel
      </Button>
      <Button
        className={styles.btn}
        disabled={mutation.isLoading}
        onClick={handleClick}
        size="small"
        variant="brick">
        Save Redactions
      </Button>
      <ButtonActivityIndicator
        loading={saveTranscriptMutation.isLoading}
        className={styles.btn}
        onClick={() => saveTranscriptMutation.mutateAsync()}
        size="small"
        variant="brick">
        Save Changes
      </ButtonActivityIndicator>
      <RedactAlert message={<RedactAlertMessage />} onConfirm={onRedactClick} />
    </div>
  );
};

const RedactAlertMessage = () => {
  return (
    <div>
      <div>
        Saving these redactions will also redact the source audio/video as well. This will lock the transcript and recording from further editing for the duration.
      </div>
      <div>
        This can take up to 15 minutes.
      </div>
    </div>
  );
};
//const RedactAlertMessage = 'Saving these redactions will also redact the source audio/video as well. This will lock the transcript and recording from further editing for the duration.\r\nThis can take up to 15 minutes.';