import { useCallback, useContext, useEffect, useState } from 'react';
import { addMinutes, getDate, isAfter, isBefore, isSameDay, roundToNearestMinutes, set, setDate } from 'date-fns';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { buildTimesMap, createIntervalGenerator } from '@containers/Calendar/mapping';
import { DatePicker, TimePicker } from '@/components/DatePicker';
import { Focusable } from '@/components/Focusable';
import { PersonalCalendarContext } from './Context';
import type { EventsLookup } from './interfaces';
import styles from './style/AddAvailability.css';

type Props = {
  interval: number;
  lookup:   EventsLookup;
};

export const Fields = (props: Props) => {
  const ctx = useContext(PersonalCalendarContext);
  const [day, setDay] = useState<Date>(null);
  const [startTimes, setStartTimes] = useState<Date[]>([]);
  const [timesMap, setTimesMap] = useState<{ [key: string]: Date[] }>({});

  useEffect(() => {

    handleDayChange(new Date());

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const generateUnusedTimes = createIntervalGenerator(props.lookup);

  function handleDayChange(date: Date) {
    const unusedTimes = generateUnusedTimes(date, props.interval);
    const [ head ] = unusedTimes;

    setDay(date);
    setTimesMap(buildTimesMap(unusedTimes, props.interval));
    setStartTimes(unusedTimes);
    ctx.form.setValue({
      start: head,
      end: addMinutes(head, props.interval),
    });
  }

  const handleTimeStartChange = useCallback((date: Date) => {
    const start = set(day, { hours: date.getHours(), minutes: date.getMinutes(), milliseconds: 0 });
    const end = isAfter(ctx.form.range.end, start)
        ? ctx.form.range.end
        : addMinutes(setDate(start, getDate(day)), props.interval);

    ctx.form.setValue({
      start,
      end,
    });
  }, [
    day,
    ctx.form,
    props.interval,
  ]);

  const handleTimeEndChange = useCallback((date: Date) => {
    const time = isBefore(ctx.form.range.start, date)
        ? date
        : !isSameDay(ctx.form.range.start, date)
          ? set(day, { hours: date.getHours(), minutes: date.getMinutes(), milliseconds: 0 })
          : ctx.form.range.end;

    ctx.form.setValue({
      start: ctx.form.range.start,
      end: roundOff(time, props.interval),
    });
  }, [
    ctx.form,
    day,
    props.interval,
  ]);

  const filterEndTimes = useCallback((time: Date) => {
    return isAfter(time, ctx.form.range.start);
  }, [ctx.form]);

  if (day === null) return null;

  return (
    <div className={styles.fields}>
      <div className={styles.field}>
        <div className={styles.label}>Date</div>
        <Focusable>
          <div className={styles.datepicker}>
            <DatePicker
              minDate={new Date()}
              selected={day}
              onChange={handleDayChange} />
          </div>
        </Focusable>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>Start Time</div>
        <Focusable>
          <div className={styles.datepicker}>
            <TimePicker
              selected={ctx.form.range.start}
              onChange={handleTimeStartChange}
              showTimeSelect
              showTimeSelectOnly
              includeTimes={startTimes}
              timeFormat="hh:mm aa"
              timeIntervals={props.interval}
              timeCaption="Start"
              dateFormat="hh:mm aa" />
          </div>
        </Focusable>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>End Time</div>
        <Focusable>
          <div className={styles.datepicker}>
            <TimePicker
              selected={ctx.form.range.end}
              filterTime={filterEndTimes}
              onChange={handleTimeEndChange}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="hh:mm aa"
              timeIntervals={props.interval}
              timeCaption="End"
              dateFormat="hh:mm aa" />
          </div>
        </Focusable>
      </div>

      <div className={styles.field}>
        <div className={styles.icons}>
          <CancelOutlinedIcon
            className={styles.cancel}
            onClick={ctx.form.discardValue} />
        </div>
      </div>
    </div>
  );
};

Fields.displayName = 'AddAvailability.Fields';

function roundOff(time: Date, interval: number) {
  return roundToNearestMinutes(time, {
    nearestTo: interval,
  });
}