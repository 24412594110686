import { useCallback, useMemo, useState } from 'react';
import {
  useEditorDomRef,
  useMentionAtom,
  usePositioner,
} from '@remirror/react';
import { usePopper } from 'react-popper';
import { cursorPositioner } from '@remirror/extension-positioner';
import { cx } from '@utils';
import { useFetchSuggestedMentions } from './hooks/useSuggestedMentions';
import type { SuggestedMentionTransformed } from './interfaces.mention';
import { FloatingWrapper } from './FloatingWrapper';
import styles from './style/Mentions.Autocomplete.css';

/*
  TODO:
    debounce suggestions
    Positioning after deleting a mention
*/

export const MentionsAutocomplete = () => {

  const [items, setItems] = useState<SuggestedMentionTransformed[]>([]);
  const { state, getMenuProps, getItemProps, indexIsSelected } = useMentionAtom<SuggestedMentionTransformed>({
    items,
  });

  // workaround since blur event doesn't seem to be firing correctly
  const editorRef = useEditorDomRef();
  const editorFocused = editorRef.current.classList.contains('ProseMirror-focused');

  const enabled = useMemo(() => {
    return !!state && editorFocused;
  }, [state, editorFocused]);

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const position = usePositioner(cursorPositioner, []);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(position.element, popperElement, {
    placement: 'bottom-start',
  });

  const {
    isFetching,
  } = useFetchSuggestedMentions(state?.query?.full?.toLowerCase(), {
    enabled,
    onSuccess: res => {
      setItems(res);
    },
  });

  const renderMenuItem = useCallback((item: SuggestedMentionTransformed, index: number) => {
    const className = cx(styles.item, {
      [styles.focused]: indexIsSelected(index),
    });
    const itemProps = getItemProps({
      item,
      index,
    });

    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      itemProps.onClick(e);
    };
    const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      itemProps.onClick(e);
    };

    return (
      <div
        key={item.id}
        className={className}
        {...itemProps}
        onMouseDown={onMouseDown}
        onClick={onClick}>
        {item.label}
      </div>
    );
  }, [
    getItemProps,
    indexIsSelected,
  ]);

  const renderMenu = useCallback(() => {
    const menuProps = getMenuProps();

    return (
      <div
        {...menuProps}
        className={styles.menu}>
        {enabled && items.map(renderMenuItem)}
        {enabled && !items.length &&
          <div className={styles.noSuggestions}>No suggestions</div>
        }
      </div>
    );
  }, [
    enabled,
    getMenuProps,
    items,
    renderMenuItem,
  ]);

  return (
    <FloatingWrapper
      position={position}>
      <div
        ref={setPopperElement}
        className={styles.popper}
        style={popperStyles.popper}
        {...popperAttributes.popper}>
        {renderMenu()}
      </div>
    </FloatingWrapper>
  );
};

export default MentionsAutocomplete;