import type { Contact } from '@/types';
import { ConsultantEmployments } from './ConsultantEmployments';
import styles from './style/ConsultantCareer.css';

type Props =
  Pick<Contact, 'career'>;

const ConsultantCareer = (props: Props) => {

  return (
    <div className={styles.career}>
      <div className={styles.header}>Employments</div>

      <ConsultantEmployments career={props.career} />
    </div>
  );
};

export { ConsultantCareer };
export default ConsultantCareer;