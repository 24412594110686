import { useCallback } from 'react';
import { useProfileState, useProfileEditableState, useAccountProfileEditState, useSaveUserProfile } from '@containers/AccountProfile';
import { Editable } from '@/components/Editable';
import { Header } from './Profile.Section.Header';
import type { Section } from './interfaces';
import Placeholder from './Profile.Placeholder';
import { getSingleEntryTypeValues } from './Profile.Section.utils';

export const ProfileSectionSingleEntry = <T extends Section.SingleEntry.ProfileSingleEntry>(props: Section.SingleEntry.Props<T>) => {
  const [user] = useProfileState();
  const editable = useProfileEditableState();
  const [entryId, onToggleEditState] = useAccountProfileEditState();
  const toggleEditing = onToggleEditState(props.type);
  const save = useSaveUserProfile();

  const {
    hasValues,
    placeholder,
    title,
    Form,
    ReadOnly,
  } = getSingleEntryTypeValues(props.type);

  const isEmpty = !hasValues(user[props.type]);

  const canEdit = editable && !entryId;

  const handleSave: Section.SingleEntry.OnSave<T> = useCallback(data => {
    // @ts-ignore
    return save({
      ...data,
      type: props.type,
      userId: user.id,
    });
  }, [
    props.type,
    save,
    user.id,
  ]);

  if (entryId === props.type) {
    return (
      <>
        <Header title={title} />
        <Form
          value={user[props.type]}
          onCancel={toggleEditing}
          onSave={handleSave} />
      </>
    );
  }

  if (isEmpty && editable) {
    return (
      <>
        <Header title={title} />
        <Placeholder
          disabled={!canEdit}
          onClick={toggleEditing}
          text={placeholder} />
      </>
    );
  }

  if (isEmpty) return null;

  return (
    <Editable canEdit={canEdit} onClick={toggleEditing}>
      <Header title={title} />
      <ReadOnly
        value={user[props.type]} />
    </Editable>
  );
};

export default ProfileSectionSingleEntry;