import { memo } from 'react';
import { format } from 'date-fns/fp';
import type * as Users from '@api/interfaces/users';
import { useJoinConference } from '@containers/Scheduler/hooks';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import styles from './style/AgendaItem.css';

type Props = {
  description: string;
  item: {
    call: Pick<Users.GetUpcomingCalls.Item['call'], 'id' | 'statusId' | 'timeStart' | 'timeEnd'>;
    conference: Pick<Users.GetUpcomingCalls.Item['conference'], 'identifier'>;
  };
  onClick?: () => void;
  title: string;
} & Partial<ChildrenProps>;

const formatTime = format('h:mm a');

const BaseCallAgendaItem = memo(({
  children,
  description,
  item,
  onClick,
  title,
}: Props) => {

  const start = formatTime(item.call.timeStart);
  const end = formatTime(item.call.timeEnd);

  const [canJoin, joinConferenceProps] = useJoinConference(item);

  const className = cx(styles.root, {
    [styles.clickable]: !!onClick,
  });

  return (
    <div className={className}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.title} onClick={onClick}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
          <div className={styles.time}>{`${start} - ${end}`}</div>
        </div>
        <div className={styles.menu}>
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        {canJoin &&
          <Button
            {...joinConferenceProps}
            className={styles.join}
            color="primary"
            variant="pill">
            Join Call
          </Button>
        }
      </div>
    </div>
  );
});

export { BaseCallAgendaItem };
export default BaseCallAgendaItem;