import { forwardRef, memo, useCallback } from 'react';
import { cx } from '@utils';
import { Tooltip } from '@presentation/Tooltip';
import styles from './style/Label.Base.css';
import { TemplateLabelColor, getTemplateLabelColorClass } from './Label.colors';

type Props = {
  className?: string;
  color?: TemplateLabelColor;
  disabled: boolean;
  label: string;
  onClick?: () => void;
  tooltip?: string;
};

export const TemplateItemLabel = memo(({
  className,
  color,
  disabled,
  label,
  onClick,
  tooltip,
}: Props) => {

  const renderEnabledLabel = useCallback(() => {
    return (
      <BaseLabel
        className={className}
        color={color}
        onClick={onClick}
        value={label} />
    );
  }, [
    className,
    color,
    label,
    onClick,
  ]);

  if (disabled) {
    return (
      <BaseLabel
        className={cx(className, styles.disabled)}
        color={color}
        value={label} />
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {renderEnabledLabel()}
      </Tooltip>
    );
  } else {
    return renderEnabledLabel();
  }
});

type BaseLabelProps =
  { value: string } &
  Pick<Props, 'className' | 'color' | 'onClick'> &
  Record<string, unknown>;

const BaseLabel = memo(forwardRef<HTMLSpanElement, BaseLabelProps>(({
  className,
  color = TemplateLabelColor.One,
  onClick,
  value,
  ...otherProps }, ref) => {

  const clazz = cx(styles.root, className, getTemplateLabelColorClass(color));

  return (
    <span
      ref={ref}
      {...otherProps}
      className={clazz}
      onClick={onClick}>
      {value}
    </span>
  );
}));