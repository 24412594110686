import { useCallback, useState } from 'react';
import type { RedirectProps } from 'react-router-dom';
import { pathname } from '@consts';
import {
  useSelectUser,
} from '@containers/Store';
import {
  useResolveRouteForNewUser,
  useResolveRouteForNoMatch,
} from '@containers/Routing';

const useResolveRedirect = () => {
  const [redirect, setRedirect] = useState<RedirectProps['to'] | null>(null);
  const user = useSelectUser();
  const resolveRouteForNewUser = useResolveRouteForNewUser();
  const resolveRouteForNoMatch = useResolveRouteForNoMatch();

  const resolveRedirect = useCallback(() => {
    const location = user.meta.isNew
        ? resolveRouteForNewUser()
        : resolveRouteForNoMatch() ?? pathname.HOME;

    setRedirect(location);
  }, [
    resolveRouteForNewUser,
    resolveRouteForNoMatch,
    user.meta.isNew,
  ]);

  return [redirect, resolveRedirect] as const;
};

export { useResolveRedirect };
export default useResolveRedirect;