import { useCallback, useContext, useMemo, useState } from 'react';
import { isDate, isEqual } from 'date-fns';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import { CallStatus } from '@enums';
import { nullableDate } from '@utils';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { CallTimePicker, type OnChangeParams } from '@/components/Call.TimePicker';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal as RootModal } from '@/components/Modal/Modal';
import Toast from '@/components/Toast';
import type * as API from '$admin/api/interfaces';
import * as api from '$admin/api';
import * as $conference from '$admin/containers/Call/utils';
import { NotifyContext } from '$admin/components/Call.Notify/Context';
import { SettingsContext } from '$admin/components/Scheduling/Context';
import * as ConferenceSettings from '$admin/components/Scheduling/Settings.Form';
import { type Settings as CallSettings } from '$admin/components/Scheduling/interfaces';
import { CallEditSettingsContext } from './Context';
import styles from './style/EditSettings.Modal.css';

type Props = {
  onSuccess?: (data: API.Projects.UpdateCallSettings.Response) => unknown;
} & ICallId
  & IProjectId;

export const Modal = (props: Props) => {
  const ctx = {
    editing: useContext(CallEditSettingsContext),
    notify: useContext(NotifyContext),
  };

  const [settings, dispatch] = useState<CallSettings.Form>({
    call: null,
    conference: null,
    time: null,
    tags: null,
  });
  const [isValid, setValid] = useState(true);

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: QK.Projects.Scheduling.Settings.Get({
      callId: props?.callId,
      projectId: props?.projectId,
    }), queryFn: () => {
      return api.projects.scheduling.getCallSettings({
        callId: props.callId,
        projectId: props.projectId,
      })
        .then(res => ({
          call: res.call,
          conference: settings.conference ?? $conference.resolveDefaultSettings(res.conference, (res.call?.title ?? res.project.clientName?.trim()) || res.project.name),
          time: {
            end: nullableDate(res.call?.timeEnd ?? settings.time?.end ?? null),
            start: nullableDate(res.call?.timeStart ?? settings.time?.start ?? null),
          },
          tags: res.tags,
        }));
    }, onSuccess: res => {
      dispatch({
        call: res.call,
        conference: res.conference,
        time: res.time,
        tags: res.tags,
      });
    }, refetchOnWindowFocus: false,
  });

  const canNotify = useMemo(() => {
    return query.data?.call?.statusId === CallStatus.Scheduled;
  }, [query.data?.call?.statusId]);

  const showNotifyRespondent = useMemo(() => {
    return !ctx.notify?.participants?.respondent?.offPlatform
      || ctx.notify?.participants?.respondent?.offPlatform
      && ctx.notify?.participants?.respondent?.id !== 0;
  }, [
    ctx.notify.participants.respondent,
  ]);

  const [notifyModerators, setNotifyModerators] = useState(true);
  const [notifyAttendees, setNotifyAttendees] = useState(true);
  const [notifyRespondent, setNotifyRespondent] = useState(showNotifyRespondent);

  const mutation = useMutation({
    mutationKey: [
      `put:admin/projects/calls`,
      props.projectId,
      props.callId,
    ], mutationFn: (data: Mutation.Variables) => {
      return api.projects.calls.updateCallSettings({
        call: data.call,
        callId: props.callId,
        conference: data.conference,
        notify: data.notify,
        projectId: props.projectId,
        tags: data.tags,
      });
    }, onSuccess: res => {
      if (canNotify && (notifyModerators || notifyAttendees || (showNotifyRespondent && notifyRespondent))) {
        Toast.info({
          title: copy.toast,
        });
      }

      props.onSuccess?.(res);

      ctx.editing.resetContext();

      return res;
    },
  });

  const handleSubmit = useCallback(() => {
    const valid = $conference.ConferenceSettingsSchema.isValidSync(settings.conference);

    if (!valid) {
      setValid(false);
      return;
    }

    mutation.mutate({
      call: {
        end: settings.time?.end ?? null,
        start: settings.time?.start ?? null,
        title: settings.conference.title,
      },
      conference: settings.conference,
      notify: {
        attendees: canNotify && notifyAttendees,
        moderators: canNotify && notifyModerators,
        respondent: canNotify && notifyRespondent,
      },
      tags: settings.tags,
    });
  }, [
    canNotify,
    mutation,
    notifyAttendees,
    notifyModerators,
    notifyRespondent,
    settings,
  ]);

  const value = {
    conference: settings.conference,
    isLoading: query.isInitialLoading || !settings.conference,
    setValue: dispatch,
    time: settings.time,
    tags: settings.tags,
  };

  const hasChanges = useMemo(() => {
    if (query.isInitialLoading || !settings.conference || !query.data) return false;

    return settings.conference.title.trim() !== query.data.call.title
      || settings.conference.conferencePlatform !== query.data.conference.conferencePlatform
      || settings.conference.conferenceType !== query.data.conference.conferenceType
      || settings.conference.videoShareTypeId !== query.data.conference.videoShareTypeId
      || settings.conference.screenShareTypeId !== query.data.conference.screenShareTypeId
      || settings.conference.defaultDuration !== query.data.conference.defaultDuration
      || settings.conference.conferenceLink !== query.data.conference.conferenceLink
      || settings.conference.transcriptionQa !== query.data.conference.transcriptionQa
      || !areLanguagesEqual(settings.conference.languages, query.data.conference.languages)
      || !areDatesEqual(settings.time.start, query.data.time.start)
      || !areDatesEqual(settings.time.end, query.data.time.end)
      || JSON.stringify(settings.conference.clientNotes) !== JSON.stringify(query.data.conference.clientNotes)
      || JSON.stringify(settings.conference.conferenceDetails) !== JSON.stringify(query.data.conference.conferenceDetails)
      || JSON.stringify(settings.tags) !== JSON.stringify(query.data.tags);
  }, [
    settings,
    query.data,
    query.isInitialLoading,
  ]);

  return (
    <RootModal
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      onClose={ctx.editing.resetContext}
      open>
      <div className={styles.root}>
        <Header>Conference Settings</Header>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <SettingsContext.Provider value={value}>
              {!value.isLoading &&
                <CallTime statusId={query.data?.call?.statusId} />}
              {!value.isLoading &&
                <ConferenceSettings.Form />}

              {!isValid &&
                <div className={styles.validation}>
                  Details are invalid
                </div>}
            </SettingsContext.Provider>

            <div className={styles.notify}>
              {copy.notify}
            </div>

            <div className={styles.options}>
              <div className={styles.option}>
                {canNotify &&
                  <>
                    <Checkbox
                      checked={notifyModerators}
                      onChange={(e, checked) => setNotifyModerators(checked)} />
                    <div className={styles.message}>Moderators</div>
                  </>}
              </div>

              <div className={styles.option}>
                {canNotify &&
                  <>
                    <Checkbox
                      checked={notifyAttendees}
                      onChange={(e, checked) => setNotifyAttendees(checked)} />
                    <div className={styles.message}>Attendees</div>
                  </>}
              </div>

              {(canNotify && showNotifyRespondent) &&
                <div className={styles.option}>
                  <Checkbox
                    checked={notifyRespondent}
                    onChange={(e, checked) => setNotifyRespondent(checked)} />
                  <div className={styles.message}>Respondents</div>
                </div>}
            </div>
          </div>

          <ButtonSet className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              disabled={mutation.isLoading}
              onClick={ctx.editing.resetContext}>
              Cancel
            </ButtonOutlined>
            <ButtonActivityIndicator
              color="primary"
              className={styles.btn}
              disabled={!hasChanges || !isValid}
              implicitDisable={false}
              loading={mutation.isLoading}
              variant="brick"
              onClick={handleSubmit}>
              Save
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </RootModal>
  );
};

Modal.displayName = 'Call.EditSettings.Modal';

const copy = {
  notify: `Send invite emails with updated details to the following conference participants:`,
  toast: `Calendar invitations sent`,
};

namespace Mutation {
  export type Variables = Omit<API.Projects.UpdateCallSettings.Request, 'callId' | 'projectId'>;
}

type CallTimeProps = {
  statusId: CallStatus;
};

const CallTime = (props: CallTimeProps) => {
  const ctx = useContext(SettingsContext);

  const handleChange = useCallback((params: OnChangeParams) => {
    ctx.setValue(form => ({
      ...form,
      conference: {
        ...form.conference,
        defaultDuration: params.duration,
      },
      time: {
        end: params.end,
        start: params.start,
      },
    }));
  }, [ctx]);

  if (props.statusId !== CallStatus.Scheduled) return null;

  return (
    <div className={styles.row}>
      <CallTimePicker
        duration={ctx.conference.defaultDuration}
        end={ctx.time.end}
        onChange={handleChange}
        start={ctx.time.start} />
    </div>
  );
};

function areDatesEqual(a: Date, b: Date) {
  return !isDate(a) || !isDate(b)
    ? a === b
    : isEqual(a, b);
}

function areLanguagesEqual(a: Descriptor[], b: Descriptor[]) {
  const incoming = a.map(x => x.id).sort();
  const existing = b.map(x => x.id).sort();

  return incoming.length === existing.length
    && incoming.every((x, i) => x === existing[i]);
}