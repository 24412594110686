import { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { response as $response } from '@api/surveys';
import type { Surveys } from '@api/interfaces/surveys';
import { SurveyActionType } from '@/enums';
import type { SubmitOverrides } from '../Context';
import {
  useSaveCompletedSurveyResponse,
  useSurveyFormApi,
  useSurveyFormScreenContext,
  useSurveyFormVersionId,
  useSurveyResponseIdentifier,
} from '../Context';
import { useSurveyFormAIEItem } from './useSurveyFormAIEItem';

export const useSubmitAIEScreen = () => {

  const saveCompletedResponse = useSaveCompletedSurveyResponse();
  const [state, setState] = useSurveyFormScreenContext();

  const responseIdentifier = useSurveyResponseIdentifier();
  const surveyVersionId = useSurveyFormVersionId();

  const submitScreen = useSubmitAIEScreenApi();

  const item = useSurveyFormAIEItem();

  const handleSuccess = useCallback((res: Surveys.Response.SubmitMessageScreen.Response) => {
    if (res.action.type === SurveyActionType.SkipToEnd) {
      saveCompletedResponse();
    } else {
      setState(res.state);
    }
  }, [
    saveCompletedResponse,
    setState,
  ]);

  const {
    mutateAsync,
    ...mutation
  } = useMutation({ mutationKey: ['survey-response.submit-aie'], mutationFn: (overrides?: SubmitOverrides) => {
    return submitScreen({
      surveyVersionItemId: state.item.id,
      surveyVersionId,
      responseIdentifier,
      viewedEntryIdentifiers: item.content.exercise.entries.map(e => e.identifier),
      itemId: overrides?.itemId,
    });
  }, onSuccess: handleSuccess });

  const handleSubmit = useCallback((overrides?: SubmitOverrides) => {
    return mutateAsync(overrides);
  }, [
    mutateAsync,
  ]);

  return {
    ...mutation,
    mutateAsync: handleSubmit,
  };
};

function useSubmitAIEScreenApi() {
  const handlers = useSurveyFormApi();

  return useMemo(() => handlers?.submitAIEScreen || $response.submitAIEScreen, [handlers?.submitAIEScreen]);
}