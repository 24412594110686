import qs from 'query-string';
import http from '@services/http';
import type { Reports } from './interfaces';

export const downloadMonthlyCallsReport = (data: Reports.DownloadMonthlyCallsReport.Request) => {
  return http.get<Blob>(`/admin/reports/billing/monthly-calls?month=${data.month}&year=${data.year}`, {
    responseType: 'blob',
  });
};

export const downloadMonthlyMessagesReport = (data: Reports.DownloadMonthlyMessagesReport.Request) => {
  return http.get<Blob>(`/admin/reports/billing/monthly-messages?month=${data.month}&year=${data.year}`, {
    responseType: 'blob',
  });
};

export const downloadMonthlySurveysReport = (data: Reports.DownloadMonthlySurveysReport.Request) => {
  return http.get<Blob>(`/admin/reports/billing/monthly-surveys?month=${data.month}&year=${data.year}`, {
    responseType: 'blob',
  });
};

export const downloadMonthlyReferralsReport = (data: Reports.DownloadMonthlySurveysReport.Request) => {
  return http.get<Blob>(`/admin/reports/billing/monthly-referrals?month=${data.month}&year=${data.year}`, {
    responseType: 'blob',
  });
};

export const downloadMonthlyPackagesReport = (data: Reports.DownloadMonthlyPackagesReport.Request) => {
  return http.get<Blob>(`/admin/reports/billing/monthly-packages?month=${data.month}&year=${data.year}`, {
    responseType: 'blob',
  });
};