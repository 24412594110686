import { Fragment } from 'react';
import { utils } from '@enums';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <Fragment>
      {utils.CallStatus.getName(props.row.original.call.statusId)}
    </Fragment>
  );
};

Cell.displayName = 'Column.Status.Cell';