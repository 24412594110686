import { safeJsonDate, slugify } from '@utils';
import type { Contact, PipelineRecord, UserProfileProps } from '@/types';

export function normalizeUser(user: Store.User<string>): Store.User {

  return normalizeDates(user);
}

function normalizeDates(user: Store.User<string>): Store.User  {
  return Object.assign(user, {
    createdOn: typeof user.createdOn !== 'undefined'
      ? safeJsonDate(user.createdOn)
      : undefined,
  });
}

type ContactLike =
  & Partial<Pick<Contact, 'id' | 'profile'>
  & Pick<PipelineRecord, 'userId'>>;

type GenerateSlugParams =
  & ContactLike
  & Pick<UserProfileProps, 'firstName' | 'lastName'>;

type SlugProp =
  Required<Pick<UserProfileProps, 'slug'>>;

type ProfileProp =
  Partial<Pick<Contact, 'profile'>>;

function setUserSlug<T extends ContactLike>(user: T): T {
  const slug = slugify({
    id: user.id || user.userId,
    name: user.profile.fullname || `${user.profile.firstName} ${user.profile.lastName}`,
  });

  Object.assign<T['profile'], SlugProp>(user.profile, { slug });

  return user;
}

const setUserSlugGeneric = <T extends GenerateSlugParams>(user: T) => {
  return Object.assign<T, SlugProp>(user, { slug: generateSlug(user) });
};

function generateSlug(user: GenerateSlugParams) {
  return slugify({
    id: user.id || user.userId,
    name: `${user.firstName} ${user.lastName}`,
  });
}

function assignProfileProp<T extends ContactLike, U extends UserProfileProps>(user: T & U): T | U & ProfileProp {
  if (user.profile) {
    return user;
  }

  return Object.assign<U, ProfileProp>(user, {
    profile: {
      creditRate: user.creditRate,
      firstName: user.firstName,
      lastName: user.lastName,
      fullname: user.fullname,
      hourlyRate: user.hourlyRate,
      publicUrl: user.publicUrl,
      pictureUrl: user.pictureUrl,
      slug: user.slug,
      status: user.status,
    },
  });
}

export {
  assignProfileProp,
  setUserSlug,
  setUserSlugGeneric,
};