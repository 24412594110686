import { useLocation, Link } from 'react-router-dom';
import * as consts from '@consts';
import { getLocationFor } from '@utils';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style.css';

type Props = {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  pictureUrl: string;
  slug: string;
};

export const MemberNameCell = (props: Props) => {
  const location = useLocation();
  const from = `${location.pathname}${location.search}`;

  return (
    <div className={styles.profile}>
      <Link
        to={{ pathname: `${consts.pathname.USER_PROFILE}/${props.slug}`, state: { from } }}>
        <UserAvatar
          className={styles.avatar}
          pictureUrl={props.pictureUrl}
          size={25} />
      </Link>
      <Link
        className={styles.name}
        to={getLocationFor.admin.member.details({ userId: props.id })}>
        {props.name || [props.firstName, props.lastName].join(' ')}
      </Link>
    </div>
  );
};

export default MemberNameCell;