import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';
import type { StepperScreensState } from '@containers/ProjectOnboarding/interfaces';
import { Billing } from '@screens/ProjectOnboarding.Completion';
import { MedicalExperienceContinuationStepper } from './MedicalExperience.Continuation.Stepper';

export enum Step {
  CallBilling         = 'Billing.Call.Stepper',
  MedicalContinuation = 'Medical.Continuation.Stepper',
  SurveyBilling       = 'Billing.Survey.Stepper',
}

export const StepScreens = {
  [Step.CallBilling]: Billing.Call.Stepper,
  [Step.SurveyBilling]: Billing.Survey.Stepper,
  [Step.MedicalContinuation]: MedicalExperienceContinuationStepper,
};

const StepsMap = {
  [ProjectType.Call]: [
    Step.CallBilling,
    Step.MedicalContinuation,
  ],
  [ProjectType.Survey]: [
    Step.SurveyBilling,
    Step.MedicalContinuation,
  ],
  [ProjectType.SurveyCall]: [
    Step.CallBilling,
    Step.MedicalContinuation,
  ],
};

const selectProjectCompletedSteps = (state: Store.State) => (projectId: number): Step[]  => {
  const project = state.projects[projectId];

  return StepsMap[project.projectType];
};

export const useCompletedStepsSelector = (filter?: StepsFilter) => {
  const getSteps = useSelector(selectProjectCompletedSteps);

  const selectSteps = (projectId: number) => {
    return getSteps(projectId);
  };

  return useCallback(selectSteps, [
    getSteps,
  ]);
};

const options = {
  enabled: true,
};

export const useStepperConfig = (
  projectId: number,
  selector: UseStepperConfig.Selector,
  { enabled = options.enabled }: UseStepperConfig.Options = options,
) => {
  const [state, dispatch] = useState<StepperScreensState>({
    screens: [],
    steps: [],
  });

  const initialize = useCallback(() => {
    const steps = selector(projectId);
    const screens = steps.map(step => StepScreens[step]);

    dispatch({
      screens,
      steps,
    });
  }, [
    dispatch,
    projectId,
    selector,
  ]);

  useEffect(() => {

    if (enabled && !state.steps.length) {
      initialize();
    }

  }, [
    enabled,
    initialize,
    state.steps,
  ]);

  return state;

};

type StepsFilter = (steps: Step[]) => Step[];

declare namespace UseStepperConfig {
  export type Selector = (projectId: number) => Step[];

  export type Options = {
    enabled?: boolean;
  };
}