import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchComplianceCallReview } from '@utils/api';
import { CallReviewDispatchContext, CallReviewStateContext } from './Context';
import type { ComplianceReview, CallReviewParams } from './interfaces';

type Props = {
  children: React.ReactNode;
};

const CallReviewContainer = (props: Props) => {
  const params = useParams<CallReviewParams>();
  const [response, fetch] = useFetchComplianceCallReview();
  const [state, setItem] = useState<ComplianceReview.CallReviewState | undefined>();

  const fetchItem = useCallback(() => {

    fetch({
      callId: +params.callId,
      groupId: +params.groupId,
    });

  }, [
    fetch,
    params,
  ]);

  useEffect(() => {

    fetchItem();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {

    if (response.value) {
      setItem(response.value);
    }

  }, [
    setItem,
    response.value,
  ]);

  return (
    <CallReviewStateContext.Provider value={state}>
      <CallReviewDispatchContext.Provider value={setItem}>
        {props.children}
      </CallReviewDispatchContext.Provider>
    </CallReviewStateContext.Provider>
  );
};

export { CallReviewContainer };
export default CallReviewContainer;