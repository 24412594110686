import { AlertCircle } from 'react-feather';
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import type { TableCellItem } from './interfaces';
import styles from './style/Search.Leads.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  if (!(props.row.original.data || props.row.original.notes)) return null;

  return (
    <PopupState
      variant="popover"
      popupId="notes-popup-popover">
      {popupState => (
        <div>
          <AlertCircle size={22} {...bindHover(popupState)} />
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <div className={styles.notes}>
              <Notes notes={props.row.original.notes} />
              <ImportedFields fields={props.row.original.data} />
            </div>
          </HoverPopover>
        </div>)}
    </PopupState>
  );
};

Cell.displayName = 'Column.Notes.Cell';

type ImportedFieldsProps = {
  fields: Record<string, string>;
};

const ImportedFields = (props: ImportedFieldsProps) => {
  if (!props.fields) return null;

  return (
    <div className={styles.section}>
      <div className={styles.header}>Imported Fields</div>
      {Object.entries(props.fields).map(([key, value]) =>
        <div
          key={key}
          className={styles.field}>
          <div className={styles.label}>{key}:</div>
          <div>{value || 'N/A'}</div>
        </div>)}
    </div>
  );
};

const Notes = (props: { notes: string }) => {
  if (!props.notes) return null;

  return (
    <div className={styles.section}>
      <div className={styles.header}>Imported Notes</div>
      <div>{props.notes}</div>
    </div>
  );
};