/* import { Link } from 'react-router-dom'; */
import type * as API from '@api/interfaces';
import * as consts from '@consts';
/* import { useRequestCallWithUser } from '@containers/RequestCall/hooks/useRequestCall'; */
import { Skip } from '@presentation';
/* import { Button } from '@/components/Button'; */
import { UserAvatar } from '@/components/UserAvatar';
/* import { PaidMessageButton } from './PaidMessageButton'; */
import styles from './style/ShortProfile.css';

type Props = {
  item: API.Discovery.FetchContentItem.Response['author'];
};

const ShortProfile = (props: Props) => {
  const pathname = `${consts.path.Profile.Root}/${props.item.id}`;
  /* const requestCall = useRequestCallWithUser(props.item); */

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <UserAvatar
              className={styles.avatar}
              pictureUrl={props.item.profile.pictureUrl}
              to={pathname} />

            <div className={styles.info}>
              <div className={styles.name}>{props.item.profile.fullname}</div>
              <div className={styles.career}>
                <span>{props.item.career.current.company || props.item.career.former.company}</span>
                <span>{props.item.career.current.title || props.item.career.former.title}</span>
              </div>
            </div>
          </div>

          <div className={styles.btns}>
            {/* <PaidMessageButton
              className={styles.btn}
              item={props.item}
              variant="pill">
              Message
            </PaidMessageButton>

            <Button
              className={styles.btn}
              variant="pill"
              title="Request Call"
              onClick={requestCall} /> */}
          </div>

        </div>
        <div className={styles.bio}>
          {props.item.bio.summary}
          <div className={styles.overlay} />
        </div>
        <Skip
          className={styles.link}
          to={pathname}>
          View Profile
        </Skip>
      </div>
    </div>
  );
};

export { ShortProfile };
export default ShortProfile;