import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import { useUpdateStoredTranscriptTagSummaries } from '@utils/api';
import type { ConferenceTagSummaryItem, ConferenceTagSummaryRating } from '@/types';
import { SetSummaryRatingContext, SummaryFeedbackModalToggleContext } from './context';

type Props = ChildrenProps & ITranscriptId;

type Vars = {
  rating: ConferenceTagSummaryRating;
  tagSummaryId: number;
};

export const TranscriptSummaryRatingsContainer = ({ children, transcriptId }: Props) => {

  const updateStoredTagSummaries = useUpdateStoredTranscriptTagSummaries({ transcriptId });
  const toggleFeedbackModal = useContext(SummaryFeedbackModalToggleContext);

  const updateRatingInStore = useCallback((tagSummaryId: number, rating: ConferenceTagSummaryRating) => {
    updateStoredTagSummaries(oldData => {
      return oldData.map(item => {
        return {
          ...updateItem(item, rating),
          children: item.children.map(child => updateItem(child, rating)),
        };
      });
    });

    function updateItem(item: ConferenceTagSummaryItem, rating: ConferenceTagSummaryRating) {
      if (item.summary?.id !== tagSummaryId) return item;

      return {
        ...item,
        summary: {
          ...item.summary,
          feedback: {
            ...item.summary.feedback,
            rating,
          },
        },
      };
    }
  }, [updateStoredTagSummaries]);

  const { mutateAsync } = useMutation({ mutationKey: ['transcript:update-tag-summary-rating', { transcriptId }], mutationFn: ({ rating, tagSummaryId }: Vars) => {
    return $api.transcripts.updateTagSummaryRating({
      tagSummaryId,
      transcriptId,
      rating,
    });
  }, onMutate: ({ rating, tagSummaryId }) => {
    updateRatingInStore(tagSummaryId, rating);
  }, onSuccess: (data, vars) => {
    updateRatingInStore(vars.tagSummaryId, data.rating);
  } });

  const handleSetRating = useCallback((item: ConferenceTagSummaryItem) => (rating: ConferenceTagSummaryRating) => {
    const tagSummaryId = item.summary.id;

    if (rating <= 3) {
      toggleFeedbackModal(item, rating);
      return;
    }

    return mutateAsync({
      rating,
      tagSummaryId,
    });
  }, [mutateAsync, toggleFeedbackModal]);

  return (
    <SetSummaryRatingContext.Provider value={handleSetRating}>
      {children}
    </SetSummaryRatingContext.Provider>
  );
};
