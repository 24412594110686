import { cx } from '@utils';
import type { MainProps } from './interfaces';
import styles from './style.css';

export const CompletedSurvey = (props: MainProps) => (
  <div className={styles.root}>
    <div className={styles.header}>
      {props.userDetails}
      {props.buttons}
    </div>
    <div className={cx(props.bodyClassName)}>
      {props.surveyDetails}
      <div className={styles.survey}>
        {props.results}
      </div>
    </div>
  </div>
);