import { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { cx, useOnElementResize } from '@utils';
import { usePopperState, useZIndexModifier } from '@/components/Popper';
import { AppThemingContext } from '@containers/Branding/Context';
import { TabBarTitle } from './TabBarTitle';
import TabIndicator from './TabIndicator';
import type { TabBarProps } from './interfaces';
import styles from './style.css';

type Props<T> = {
  className?: string;
  classes?: {
    root?: string;
    tab?: string;
    tabs?: string;
  };
} & TabBarProps<T>;

// eslint-disable-next-line comma-spacing
const TabBar = <T,>({ classes = {}, ...props }: Props<T>) => {

  function getClassName(key: string) {
    const i = props.routes.findIndex(r => r.key === key);
    return cx(props.index === i ? styles.active : styles.tab);
  }

  const ctx = useContext(AppThemingContext);

  const getStyle = useCallback((key: string) => {
    const i = props.routes.findIndex(r => r.key === key);
    const color = ctx?.enabled && props.index === i ? ctx?.palette?.primary?.main : undefined;

    return { color };
  }, [
    ctx?.enabled,
    ctx?.palette,
    props.index,
    props.routes,
  ]);

  function handleClick(key: string) {
    const i = props.routes.findIndex(r => r.key === key);
    return () => props.onClick(i);
  }

  const [maxItems, setMaxItems] = useState(props.routes.length);
  const [itemContainer, setItemContainer] = useState<HTMLDivElement>();

  const recalculateMaxItems = useCallback(() => {
    if (itemContainer) {
      const parentSize = itemContainer.parentElement.clientWidth;
      const itemWidth = itemContainer.children[0].clientWidth;

      if (itemWidth * props.routes.length > parentSize) {
        setMaxItems(Math.floor(parentSize / itemWidth) - 1);
      } else {
        setMaxItems(props.routes.length);
      }
    }
  }, [itemContainer, props.routes.length]);

  useEffect(recalculateMaxItems, [itemContainer, recalculateMaxItems]);
  useOnElementResize({
    element: itemContainer,
    handler: recalculateMaxItems,
  });

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const popperState = usePopperState({ placement: 'bottom', modifiers: [zIndexModifier] });

  const orderedItems = useMemo(() => {
    if (props.index > maxItems - 1) {
      return arraymove(props.routes, props.index, maxItems - 1);
    } else {
      return props.routes;
    }
  }, [props.routes, props.index, maxItems]);

  const displayIndex = orderedItems.findIndex(i => i.key === props.routes[props.index].key);
  const showMore = maxItems < props.routes.length;

  return (
    <div className={cx(styles.root, classes.root)}>
      <div className={styles.wrap}>
        <div className={styles.scrollable}>
          <div ref={setItemContainer} className={cx(styles.tabs, classes.tabs, props.className)}>
            {orderedItems.slice(0, maxItems).map((item, i) =>
              <TabBarTitle
                className={cx(getClassName(item.key), classes.tab)}
                key={item.key}
                index={i}
                onClick={handleClick(item.key)}
                style={getStyle(item.key)}>
                {item.title}
              </TabBarTitle>)}
            {showMore &&
              <>
                <TabBarTitle
                  className={cx(getClassName('more'), classes.tab)}
                  key={'more'}
                  index={-1}
                  onClick={() => popperState.setOpen(true)}>
                  <div ref={popperState.setReferenceElement}>
                    More
                  </div>
                </TabBarTitle>
                <Popper
                  open={popperState.open}
                  ref={popperState.setPopperElement}
                  style={popperState.styles.popper}
                  anchorEl={popperState.referenceElement}
                  {...popperState.attributes.popper}>
                  <ClickAwayListener onClickAway={() => popperState.setOpen(false)}>
                    <div className={styles.moreMenu}>
                      {orderedItems.slice(maxItems).map((r, i) => (
                        <div
                          key={r.key}
                          className={styles.menuItem}
                          onClick={handleClick(r.key)}>
                          {r.title}
                        </div>
                      ))}
                    </div>
                  </ClickAwayListener>
                </Popper>
              </>}
            <TabIndicator
              count={maxItems + (showMore ? 1 : 0)}
              index={displayIndex} />
          </div>
        </div>
        <div className={styles.bar} />
      </div>
    </div>
  );
};

function arraymove<T>(arrParam: T[], fromIndex: number, toIndex: number) {
  const arr = [...arrParam];
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
}

export { TabBar };
export default TabBar;