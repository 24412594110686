import { useEffect } from 'react';
import coreCSS from '@fullcalendar/core/main.css';
import dayGridCSS from '@fullcalendar/daygrid/main.css';
import timeGridCSS from '@fullcalendar/timegrid/main.css';
import useableCSS from 'static/css/useable/full-calendar.css';

type Useable = {
  use: () => void;
  unuse: () => void;
};

const useInjectedStyles = () => {
  const styles = [
    coreCSS,
    dayGridCSS,
    timeGridCSS,
    useableCSS,
  ] as unknown as Useable[];

  useEffect(() => {
    styles.forEach(style => style.use());

    return () => styles.forEach(style => style.unuse());
  }, []);
};

export { useInjectedStyles };
export default useInjectedStyles;
