import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import * as consts from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { useFetchPost } from '@utils/api';
import type { Posts } from '@/types';

type Params = {
  postId?: string;
};

export const usePost = () => {
  const app = useAppReadyState();
  const params = useParams<Params>();
  const history = useHistory();

  const transformItem = useCallback((data: Response) => {
    if (!app.authenticated) {
      const { comments, likeDescription, ...post } = data;

      return {
        ...post,
        comments: null,
        likeDescription: null,
        query: null,
      };
    }

    return {
      ...data,
      query: null,
    };
  }, [app.authenticated]);

  const response = useFetchPost(+params.postId, {
    select: transformItem,
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        history.replace(consts.path.Posts.NotFound);
      }
    },
    retry: false,
  });

  return response;
};

type Response =
  & Posts.Post
  & Posts.AdditionalDetails;