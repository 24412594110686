import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import type { MultiselectQuestion } from '@/types/survey';
import { MatrixMultiselectQuestion } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';

type Props = {
  className?: string;
};

export const GridDisplay = ({ className }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItem() as MultiselectQuestion.Question;

  const toggleGridFormat = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        gridFormat: checked,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  return (
    <CheckboxLabel
      className={className}
      checked={item.settings.gridFormat}
      label="Display as grid"
      onChange={toggleGridFormat} />
  );
};