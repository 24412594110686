import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import type { SurveyRichText } from '@/types';
import { Remirror } from '@/types';

export const useRemoveRow = (questionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((identifier: string) => {
    dispatch({
      row: {
        identifier,
      },
      questionIdentifier,
      type: 'remove-question-row',
    });
  }, [dispatch, questionIdentifier]);
};

export const useUpdateRowValue = (questionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((identifier: string, value: string) => {

    const content: SurveyRichText.ParagraphNode[] = value.split(`\n`)
      .map(text => {

        if (text.length) {
          return {
            type: Remirror.NodeType.Paragraph,
            content: [{
              type: Remirror.NodeType.Text,
              text,
            }],
          };
        } else {
          return {
            content: [],
            type: Remirror.NodeType.Paragraph,
          };
        }
      });

    const richTextValue: SurveyRichText.RichTextValue = {
      type: Remirror.NodeType.Doc,
      content,
    };

    dispatch({
      type: 'update-question-row-value',
      questionIdentifier,
      row: {
        identifier,
      },
      value: richTextValue,
    });
  }, [dispatch, questionIdentifier]);
};

export const useUpdateRowRichValue = (questionIdentifier: string) => {
  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((identifier: string, value: SurveyRichText.RichTextValue) => {
    dispatch({
      type: 'update-question-row-value',
      questionIdentifier,
      row: {
        identifier,
      },
      value,
    });
  }, [dispatch, questionIdentifier]);
};

export const useAddRow = (questionIdentifier: string) => {

  const [_, dispatch] = useSurveyBuilderState();

  return useCallback(() => {

    dispatch({
      questionIdentifier,
      type: 'add-question-row',
    });

  }, [dispatch, questionIdentifier]);
};