import http from '@services/http';
import { qs } from '@utils';
import type { Search } from './interfaces';

export const fetchConditions = (data: Search.FetchMedicalConditions.Request) => {
  const q = qs.stringify(data, { arrayFormat: 'bracket' });

  return http.get<Search.FetchMedicalConditions.Response>(`/search/medical/conditions?${q}`);
};

export const fetchHospitals = (data: Search.FetchMedicalHospitals.Request) => {
  const q = qs.stringify(data, { arrayFormat: 'bracket' });

  return http.get<Search.FetchMedicalHospitals.Response>(`/search/medical/hospitals?${q}`);
};

export const fetchResearchParticipationOptions = () => {
  return http.get<Search.FetchResearchParticipationOptions.Response>(`/search/medical/options/research-participation`);
};