import { useCallback, useMemo } from 'react';
import type { SurveyItem } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { SurveyItemType } from '@/enums';
import { trunc } from '@utils';
import DropDown from '@/components/DropDown';

type Props = {
  disabled?: boolean;
  items: SurveyItem[];
  onSelect: (identifier: string) => void;
  value: SurveyItem;
};

type DropdownItem = {
  id: string;
  value: string;
};

export const SurveyItemsDropdown = ({
  disabled,
  items,
  onSelect,
  value,
}: Props) => {

  const options = useMemo(() => {
    let questionOrdinal = 1;
    let screenOrdinal = 1;
    return items.map(item => {
      let value = '';
      switch (item.type) {

        case SurveyItemType.Question:
          value = `Q${questionOrdinal}`;
          questionOrdinal++;
          break;

        case SurveyItemType.Message:
          value = `TS ${screenOrdinal}`;
          screenOrdinal++;
          break;

        case SurveyItemType.AlternateImageExercise:
          value = `AIC ${screenOrdinal}`;
          screenOrdinal++;
          break;
        default:
          throw new UnreachableCaseError(item.type);
      }

      return {
        id: item.identifier,
        value,
      };
    });
  }, [items]);

  const getItemValue = useCallback((item: DropdownItem) => {
    return item.value;
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = useMemo(() => {
    if (!value) return null;

    const item = options.find(f => f.id === value.identifier);
    return item.value;
  }, [options, value]);

  return (
    <DropDown<DropdownItem>
      placeholder="Select Item"
      disabled={disabled}
      items={options}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default SurveyItemsDropdown;