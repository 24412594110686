import { useMemo } from 'react';
import type { Worksheet, WorksheetCellMap } from '@/types';
import type { RowData } from '@/components/Workbook/interfaces';

type Options = {
  rowLimit: number;
};

export const useWorksheetData = (sheet: Worksheet, opts: Options): RowData[] => {
  const data = useMemo(() => {
    const rows = [];
    for (let rowIndex = 1; rowIndex <= Math.min(sheet.rows, opts.rowLimit); rowIndex++) {
      const cells: WorksheetCellMap = {};
      for (const columnIndex of sheet.columns) {
        const key = `${columnIndex}${rowIndex}`;
        cells[columnIndex] = sheet.cells[key];
      }
      rows.push({ cells });
    }
    return rows;
  }, [sheet]);

  return data;
};