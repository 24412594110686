import { memo, useCallback } from 'react';
import type { ExclusiveOptionsQuestion } from '@/types';
import MultiselectAnswerValue from './Multiselect';
import styles from './style/OptionGroups.css';

type Props = {
  answer: ExclusiveOptionsQuestion.Answer.Value;
  question: ExclusiveOptionsQuestion.Question;
  rationale: string;
};

export const OptionGroupsAnswer = memo(({ answer, question, rationale }: Props) => {

  const renderGroup = useCallback((group: ExclusiveOptionsQuestion.OptionSection) => {
    const items = answer.items.filter(f => f.option?.group?.identifier === group?.identifier);

    if (!items.length) return null;

    return (
      <div key={group.identifier}>
        <div className={styles.title}>{group.value}</div>
        <MultiselectAnswerValue
          answer={{ items }}
          rationale={rationale} />
      </div>
    );
  }, [
    answer.items,
    rationale,
  ]);

  if (question.settings.displayGroupTitles) {
    return (
      <MultiselectAnswerValue
        answer={answer}
        rationale={rationale} />
    );
  }

  return (
    <div>
      {question.settings.optionSections.map(renderGroup)}
    </div>
  );
});

export default OptionGroupsAnswer;