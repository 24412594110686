import { useCallback, useEffect, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import * as enums from '@enums';
import type { VICSMappedByType } from '@transformers/vics';
import type { Onboarding, VICS, VICSMap } from '@/types';
import ActivityIndicator from '@/components/ActivityIndicator';
import { MedicalExpertiseContext } from './Context';
import type { MedicalExpertiseData } from './interfaces';
import { useMedicalOnboardingQueriesState } from './hooks';

type Props = {
  children: React.ReactNode;
  onSuccess?: (data?: MedicalExpertiseData) => unknown;
} & IUserId;

export const MedicalOnboardingExpertiseContainer = (props: Props) => {
  const [{ primary, secondary }, setSpecialty] = useState<Onboarding.Expertise.Specialty>(initialSpecialty);
  const [sectors, setSectors] = useState<VICS[]>([]);
  const [industries, setIndustries] = useState<VICS[]>([]);
  const [subIndustries, setSubIndustries] = useState<VICS[]>([]);
  const [vics, setVics] = useState<VICSMappedByType>({ ids: [] } as VICSMappedByType);
  const [professionalRole, setProfessionalRole] = useState<enums.ProfessionalRole>();

  const setAll = useCallback((res: MedicalExpertiseData) => {
    setSpecialty(res.specialty);
    setSectors(res.expertise.sectors);
    setSubIndustries(res.expertise.subindustries);
    setIndustries(res.expertise.industries);
    setProfessionalRole(res.professionalRole);
    setVics(res.expertise);
  }, []);

  const qc = useQueryClient();

  const state = useMedicalOnboardingQueriesState(props.userId);

  const query = useQuery({ queryKey: QK.Users.Onboarding.Expertise.Get({
    userId: props.userId,
  }), queryFn: () => {
    return api.users.onboarding.fetchExpertise({
      userId: props.userId,
    });
  }, enabled: !state.expertise, refetchOnWindowFocus: false });

  useEffect(() => {

    if (state.expertise?.dataUpdateCount > 0) {
      setAll(state.expertise?.data);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state.expertise?.dataUpdateCount]);

  const role = useMutation({ mutationKey: [
    professionalRole,
    props.userId,
  ], mutationFn: () => {
    return api.users.onboarding.updateExpertise({
      professionalRole,
      userId: props.userId,
    });
  }, onSuccess: () => {
    qc.setQueryData(QK.Users.Onboarding.Expertise.Get({ userId: props.userId }), (data: typeof query.data) => ({
      ...data,
      professionalRole,
    }));
  } });

  const specialty = useMutation({ mutationKey: [
    `post:users/onboarding/expertise`, {
      specialty: {
        primary,
        secondary,
      },
      userId: props.userId,
    },
  ], mutationFn: () => {
    return api.users.onboarding.updateExpertise({
      specialty: {
        primary,
        secondary,
      },
      userId: props.userId,
    });
  } });

  const specialties = useMutation({ mutationKey: [
    props.userId,
    vics,
  ], mutationFn: () => {
    return api.users.onboarding.updateExpertise({
      expertise: transformExpertise(vics),
      userId: props.userId,
    });
  } });

  const isSelected = (item: VICS) => {
    return !!vics[item.id];
  };

  const getUpdatedSelectionsState = (item: VICS, type: enums.VICSType) => {
    const selections = type === enums.VICSType.Industry
      ? industries
      : type === enums.VICSType.SubIndustry
        ? subIndustries
        : [];

    const items = isSelected(item)
      ? selections.filter(x => x.id !== item.id)
      : selections.concat(item);

    return items;
  };

  const getUpdatedVICSState = (item: VICS) => {
    if (isSelected(item)) {
      const { [item.id]: _, ids, ...rest } = vics;

      return {
        ...rest,
        ids: ids.filter(id => id !== item.id),
      };
    } else {
      return {
        ...vics,
        [item.id]: item,
        ids: vics.ids.concat(item.id),
      };
    }
  };

  const getReplacedVICSState = (item: VICS) => {
    const newState = { ...vics };

    const itemsToRemove = newState.ids.filter(i => newState[i]?.typeId === item.typeId && i != item.id);
    for (const i of itemsToRemove) {
      delete newState[i];
    }
    newState.ids = newState.ids.filter(i => !itemsToRemove.includes(i)).concat(item.id);

    newState[item.id] = item;

    return newState;
  };

  const handleVICSSelected = (type: enums.VICSType) => {
    return (item: VICS, replace = false) => {
      const updatedSelections = replace ? [item] : getUpdatedSelectionsState(item, type);

      if (type === enums.VICSType.Industry) {
        setIndustries(updatedSelections);
      } else if (type === enums.VICSType.SubIndustry) {
        setSubIndustries(updatedSelections);
      }

      setVics(replace ? getReplacedVICSState(item) : getUpdatedVICSState(item));
    };
  };

  const context = {
    form: {
      disabled: !professionalRole,
    },
    industries,
    mutation: {
      role,
      specialty,
      specialties,
    },
    onProfessionalRoleUpdated: setProfessionalRole,
    onChangeSpecialty: setSpecialty,
    onVICSSelection: handleVICSSelected,
    professionalRole,
    sectors,
    specialty: {
      primary,
      secondary,
    },
    subIndustries,
    userId: props.userId,
    vics,
  };

  if ((state.expertise?.fetchStatus === 'fetching' && state.expertise?.dataUpdateCount === 0) || query.isInitialLoading) {
    return <ActivityIndicator show={true} />;
  }

  return (
    <MedicalExpertiseContext.Provider value={context}>
      {props.children}
    </MedicalExpertiseContext.Provider>
  );
};

MedicalOnboardingExpertiseContainer.displayName = 'MedicalOnboarding.Expertise.Container';

function transformExpertise(map: VICSMap) {
  return map.ids.map(id => ({
    id,
    isUserCreated: map[id].isUserCreated || false,
    name: map[id].name,
    typeId: map[id].typeId,
  }));
}

const initialSpecialtyItem = {
  group: {} as Descriptor,
  subspecialty: {} as Descriptor,
};

const initialSpecialty = {
  primary: initialSpecialtyItem,
  secondary: initialSpecialtyItem,
};