import { useCallback, useState } from 'react';
import type { LongTextResponseQuestion } from '@/types';

export const useResponseLengthModal = () => {
  const [show, setShow] = useState<boolean>(false);
  const [shown, setShown] = useState<boolean>(false);

  const open = useCallback(() => {
    setShow(true);
    setShown(true);
  }, [setShow, setShown]);

  const close = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const shouldOpen = useCallback((question: LongTextResponseQuestion.FormQuestion, answer: LongTextResponseQuestion.RespondentAnswer) => {
    if (shown) {
      return false;
    }

    return answer?.value?.length < question.settings.characterMinimum;
  }, [shown]);

  return {
    close,
    open,
    show,
    shouldOpen,
  };
};

export default useResponseLengthModal;