import { useCallback, useMemo } from 'react';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useRichTextListBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import type { SurveyQuestionMatrixRow } from '@/types/survey';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useFindAndReplaceRowMenuItem } from './hooks/row-menu';

type Props = {
  row: SurveyQuestionMatrixRow;
  items?: ContextMenuItemProps[];
  excludeFindAndReplace?: boolean;
};

export const RowContextMenu = ({
  row,
  items = [],
  excludeFindAndReplace = true,
}: Props) => {
  const canFar = useCanCreateFindAndReplace();
  const findAndReplaceMenuItem = useFindAndReplaceRowMenuItem();

  const { removeItem, ...context } = useRichTextListBuilderContext<SurveyQuestionMatrixRow>();

  const canRemove = useMemo(() => {
    return context.permissions.canRemoveItem &&
      row.metadata.canDelete;
  }, [context.permissions, row.metadata]);

  const removeRow = useCallback(() => {
    removeItem(row.base.identifier);
  }, [removeItem, row.base.identifier]);

  const menuItems = useMemo(() => {
    const values: ContextMenuItemProps[] = Array.from(items);

    if (!excludeFindAndReplace && findAndReplaceMenuItem && canFar) {
      values.unshift({
        children: findAndReplaceMenuItem.children,
        onClick: findAndReplaceMenuItem.onClick,
      });
    }

    if (canRemove) {
      values.push({
        children: 'Delete',
        onClick: removeRow,
      });
    }

    return values;
  }, [
    excludeFindAndReplace,
    canFar,
    findAndReplaceMenuItem,
    canRemove,
    removeRow,
    items,
  ]);

  return (
    <>
      <SurveyBuilderContextMenu
        items={menuItems} />
      {!excludeFindAndReplace && canFar && findAndReplaceMenuItem.Modal ? <findAndReplaceMenuItem.Modal /> : null}
    </>
  );
};