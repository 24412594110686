import * as enums from '@enums';
import { SearchParamsContainer } from '@containers/Search';
import type { Search } from '@/types';
import styles from './style.css';

const SearchQueryInitialState = SearchParamsContainer.initialState;

const queryNameMap = {
  [enums.AdvancedSearchQuery.Competitor]: 'Competitor',
  [enums.AdvancedSearchQuery.CurrentCompany]: 'Current Company',
  [enums.AdvancedSearchQuery.FormerCompany]: 'Former Company',
  [enums.AdvancedSearchQuery.Industry]: 'Specialty',
  [enums.AdvancedSearchQuery.JobFunction]: 'Job Function',
  [enums.AdvancedSearchQuery.Keyword]: 'Keyword',
  [enums.AdvancedSearchQuery.Location]: 'Location',
  [enums.AdvancedSearchQuery.Name]: 'Name',
  [enums.AdvancedSearchQuery.Product]: 'Product',
  [enums.AdvancedSearchQuery.Sector]: 'Sector',
  [enums.AdvancedSearchQuery.Seniority]: 'Seniority',
  [enums.AdvancedSearchQuery.SubIndustry]: 'Sub-specialty',
  [enums.AdvancedSearchQuery.Title]: 'Title',
};

const queryStylesMap = {
  [enums.AdvancedSearchQuery.Competitor]: styles.red,
  [enums.AdvancedSearchQuery.CurrentCompany]: styles.blue,
  [enums.AdvancedSearchQuery.FormerCompany]: styles.green,
  [enums.AdvancedSearchQuery.Industry]: styles.gray,
  [enums.AdvancedSearchQuery.JobFunction]: styles.gray,
  [enums.AdvancedSearchQuery.Keyword]: styles.gray,
  [enums.AdvancedSearchQuery.Location]: styles.gray,
  [enums.AdvancedSearchQuery.Name]: styles.gray,
  [enums.AdvancedSearchQuery.Product]: styles.gray,
  [enums.AdvancedSearchQuery.Sector]: styles.gray,
  [enums.AdvancedSearchQuery.Seniority]: styles.gray,
  [enums.AdvancedSearchQuery.SubIndustry]: styles.gray,
  [enums.AdvancedSearchQuery.Title]: styles.gray,
};

const queryOrdinalMap = {
  [enums.AdvancedSearchQuery.CurrentCompany]: 1,
  [enums.AdvancedSearchQuery.FormerCompany]: 2,
  [enums.AdvancedSearchQuery.Competitor]: 3,
  [enums.AdvancedSearchQuery.Title]: 4,
  [enums.AdvancedSearchQuery.JobFunction]: 5,
  [enums.AdvancedSearchQuery.SubIndustry]: 6,
  [enums.AdvancedSearchQuery.Product]: 6,
  [enums.AdvancedSearchQuery.Keyword]: 7,
  [enums.AdvancedSearchQuery.Industry]: 8,
  [enums.AdvancedSearchQuery.Location]: 9,
  [enums.AdvancedSearchQuery.Name]: 9,
  [enums.AdvancedSearchQuery.Sector]: 9,
  [enums.AdvancedSearchQuery.Seniority]: 9,
};

export const getQueryName = (value: enums.AdvancedSearchQuery) => {
  return queryNameMap[value];
};

export const getQueryStyle = (value: enums.AdvancedSearchQuery) => {
  return queryStylesMap[value];
};

export const sortByOrdinal = (a: enums.AdvancedSearchQuery, b: enums.AdvancedSearchQuery) => {
  return queryOrdinalMap[a] > queryOrdinalMap[b] ? 1 : -1;
};

const DIFF_ALWAYS = new Set(['sort', 'sortDir', 'priceLow', 'priceHigh']);
const DIFF_WHITELIST = new Set([
  'company', 'formerCompany', 'industry', 'jobfunction', 'keyword',
  'location', 'priceLow', 'priceHigh', 'sector', 'seniority', 'subindustry',
  'sort', 'sortDir', 'title', 'product',
]);

export const getSearchQueryParamsDiff = (params: Partial<Search.Params>) => {
  const compare = (a: unknown, b: unknown) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const keys = Object.keys(params).filter(k => DIFF_WHITELIST.has(k));
  const diff: Partial<Search.Params> = {};

  for (const key of keys) {
    if (key in params) {
      if (DIFF_ALWAYS.has(key) || !compare(params[key], SearchQueryInitialState[key])) {
        diff[key] = params[key];
      }
    }
  }

  return diff;
};