import type { AxiosResponse } from 'axios';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Topics } from '@api/interfaces';

type Data = Topics.FetchPosts.Response;

export default function useEnumerateTopicPosts<TData = Data>(
  slug: string,
  options?: UseInfiniteQueryOptions<Data, AxiosResponse, TData>
) {

  return useInfiniteQuery(['topic', slug, 'posts'], ctx => {
    return api.topics.fetchPosts({
      slug,
      cursor: ctx.pageParam ? +ctx.pageParam : null,
    });
  }, options);
}

export { useEnumerateTopicPosts };