import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { TranscribeRT } from '@/types';

type Props = {
  tagSummaryId: number;
} & IProjectId;
type Data = {
  citations: TranscribeRT.ProjectTaggedMoment[];
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectTagSummaryCitations = (props: Props, options?: Options) => {

  return useQuery({
    queryKey: ['get:projects/conference-tags/summaries/citations', props] as const, queryFn: ({ queryKey }) => {
      const [, { projectId, tagSummaryId }] = queryKey;
      return api.projects.conference.getTagSummaryCitations({
        projectId,
        tagSummaryId,
      });
    },
    ...options,
  });
};