import { useCallback, useState } from 'react';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';

export const useThemedTextArea = () => {
  const [focused, setFocused] = useState(false);
  const { palette, theme } = useSurveyThemingPalette();

  const onBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => setFocused(false), []);
  const onFocus = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => setFocused(true), []);

  const style: React.CSSProperties = !!theme && focused
    ? {
      border: `2px solid ${palette.primary.main}`,
    } : {};

  return {
    onBlur,
    onFocus,
    style,
  };
};