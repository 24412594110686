import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as QK from '@consts/querykey';
import * as scheduler from '@containers/Scheduler/utils';
import { useIsProjectTypeSurveyLike } from '@containers/Store';

const selectMyId = (state: Store.State) => state.user.id;

export const useInviteValidationQuery = (projectId: number) => {
  const dispatch = useDispatch();
  const isSurveyLike = useIsProjectTypeSurveyLike(projectId);
  const myId = useSelector(selectMyId);

  const handleSuccess = useCallback((res: Awaited<ReturnType<typeof api.projects.pipeline.rehydrate>>) => {
    dispatch(actions.documentsFetched({ documents: res.documents }));

    const invited = scheduler.projects.isInvited(res.record);

    const valid = isSurveyLike
        ? invited && res.sourcing.isSourcing
        : invited;

    return {
      project: {
        sourcing: res.sourcing.isSourcing,
      },
      user: {
        disqualified: scheduler.projects.isDisqualified(res.record),
      },
      valid,
    };
  }, [
    dispatch,
    isSurveyLike,
  ]);

  const query = useQuery({ queryKey: QK.Projects.Pipeline.Users.Hydrate.Get({
    projectId,
    userId: myId,
  }), queryFn: () => {

    return api.projects.pipeline.rehydrate({
      projectId,
      userId: myId,
    })
    .then(handleSuccess);

  }, enabled: false, refetchOnWindowFocus: false });

  return query;
};