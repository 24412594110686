import { useCallback } from 'react';
import { SurveyQuotaType } from '@/enums';
import { useSurveyQuotasState, useSurveyQuotasGoal } from '@containers/SurveyBuilder';
import { AdvancedQuotaContainer, BasicQuotaContainer } from '@containers/SurveyBuilder.Quota';
import type { SurveyQuota } from '@/types';
import { AddQuotaButton, SurveyQuotaPreview, AdvancedSurveyQuotaBuilder, BasicSurveyQuotaBuilder } from '@/components/SurveyBuilder.Quota';
import styles from './style/QuotasBuilder.css';

export const SurveyQuotasBuilder = () => {
  const goal = useSurveyQuotasGoal();

  const [state] = useSurveyQuotasState();

  const renderItem = useCallback((item: SurveyQuota.Record) => {
    if (item.identifier === state.editing.identifier) {
      if (item.type === SurveyQuotaType.Basic) {
        return (
          <BasicQuotaContainer initial={item}>
            <BasicSurveyQuotaBuilder />
          </BasicQuotaContainer>
        );
      } else {
        return (
          <AdvancedQuotaContainer initial={item}>
            <AdvancedSurveyQuotaBuilder />
          </AdvancedQuotaContainer>
        );
      }

    } else {
      return (
        <SurveyQuotaPreview
          goal={goal}
          item={item} />
      );
    }
  }, [goal, state.editing]);

  return (
    <div className={styles.root}>
      {state.items.map((item, i) => (
        <div key={item.identifier}>
          {renderItem(item)}
          {i !== (state.items.length - 1) && <div className={styles.divider} />}
        </div>
      ))}
      <div className={styles.addItem}>
        {!state.editing.identifier && <AddQuotaButton />}
      </div>
    </div>
  );
};

export default SurveyQuotasBuilder;