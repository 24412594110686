import { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import * as api from '$admin/api';
import type { Call as CD } from '$admin/interfaces';
import { UncancelContext } from './Context';

type Props = {
  children: React.ReactNode;
} & ICallId;

export const ProjectCallContainerLegacy = ({ callId, children }: Props) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    return QK.Calls.Call.Get({ callId });
  }, [callId]);

  const uncancel = useMutation({ mutationFn: () => {
    return api.scheduling.uncancelCall({
      callId,
    });
  }, onSuccess: res => {
    queryClient.setQueryData(queryKey, (old: CD.ProjectCallDetails) => ({
      ...old,
      call: {
        ...old.call,
        statusId: res.call.statusId,
      },
    }));
  } });

  return (
    <UncancelContext.Provider value={uncancel}>
      {children}
    </UncancelContext.Provider>
  );
};

export default ProjectCallContainerLegacy;