import { styled } from '@mui/material/styles';
import type { TooltipProps as MUITooltipProps } from '@mui/material/Tooltip';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import type { CSSProperties } from '@mui/material/styles/createTypography';

export type TooltipProps = {
  tooltipstyles?: CSSProperties;
} & MUITooltipProps;

type Props = TooltipProps;

const StyledTooltip = styled(({
  arrow = true,
  className,
  ...props
}: Props) => (
  <MuiTooltip
    arrow={arrow}
    classes={{ popper: className }}
    {...props} />
), {
  shouldForwardProp: prop => prop !== 'tooltipstyles',
})<Props>(({ theme, tooltipstyles }) => ({

  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    fontSize: 13,
    fontFamily: 'var(--font-semibold)',
    backgroundColor: 'var(--black-l)',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    ...tooltipstyles,
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: tooltipstyles?.backgroundColor || 'var(--black-l)',
  },
}));

type ConditionalTooltipProps = Props & {
  enabled: boolean;
};
export const ConditionalTooltip = ({ enabled, ...props }: ConditionalTooltipProps) => {
  if (enabled) {
    return <StyledTooltip {...props} />;
  } else {
    return props.children;
  }
};

export { StyledTooltip as Tooltip };
export default StyledTooltip;