import { useCallback, useContext, useMemo } from 'react';
import { utils } from '@enums';
import type { Remirror } from '@/types';
import { Input } from '@/components/Input';
import { PhoneInputControlled } from '@/components/TelInput';
import type { ConferenceDetailsEditorOnChange } from '@/components/Conference';
import { ConferenceDetailsEditor, ConferenceDetailsEditorContainer, Toolbar, useConferenceDetailsEditor } from '@/components/Conference';
import type { OptionsMap } from '$admin/components/Select';
import { SelectUnderlined, fromEnum } from '$admin/components/Select';
import * as $conference from '$admin/containers/Call/utils';
import { SettingsContext } from './Context';
import type { SetConferenceValue } from './interfaces';
import styles from './style/ConferenceSettings.css';

type Props = unknown;

export const OffPlatform = (props: Props) => {
  const ctx = {
    settings: useContext(SettingsContext),
  };

  const state = ctx.settings;

  const setValue = useCallback((data: SetConferenceValue.Params) => {
    ctx.settings.setValue(form => ({
      ...form,
      conference: {
        clientNotes: data.clientNotes ?? form.conference.clientNotes,
        conferenceDetails: data.conferenceDetails ?? form.conference.conferenceDetails,
        conferenceDialIn: data.conferenceDialIn ?? form.conference.conferenceDialIn,
        conferenceLink: data.conferenceLink ?? form.conference.conferenceLink,
        conferenceMediaType: data.conferenceMediaType ?? form.conference.conferenceMediaType,
        conferencePlatform: data.conferencePlatform ?? form.conference.conferencePlatform,
        conferenceType: data.conferenceType ?? form.conference.conferenceType,
        dialInValid: data.dialInValid ?? form.conference.dialInValid,
        defaultDuration: data.defaultDuration ?? form.conference.defaultDuration,
        title: data.title ?? form.conference.title,
        languages: data.languages ?? form.conference.languages,
        videoShareTypeId: data.videoShareTypeId ?? form.conference.videoShareTypeId,
        screenShareTypeId: data.screenShareTypeId ?? form.conference.screenShareTypeId,
        transcriptionQa: data.transcriptionQa ?? form.conference.transcriptionQa,
      },
    }));
  }, [ctx.settings]);

  const handleChangeNotes: ConferenceDetailsEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setValue({ clientNotes: value });

  }, [setValue]);

  const handleChangeDetails: ConferenceDetailsEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    const value = params.state.doc.toJSON() as Remirror.RootNode;

    setValue({ conferenceDetails: value });

  }, [setValue]);

  const notes = useConferenceDetailsEditor({
    initialState: state.conference.clientNotes,
    placeholder: `Enter client notes`,
  });

  const { manager, state: details } = useConferenceDetailsEditor({
    initialState: state.conference.conferenceDetails,
    placeholder: `Enter conference details`,
  });

  const options = useMemo(() => {
    const types = $conference.getConferenceTypes({
      conferencePlatform: state.conference.conferencePlatform,
    });

    return types.reduce<OptionsMap>((acc, x) => ({
      ...acc,
      [x]: utils.ConferenceType.getName(x),
    }), {});
  }, [state.conference.conferencePlatform]);

  return (
    <div className={styles.grid}>
      <div className={styles.col}>
        <div className={styles.label}>External Event Title <span className={styles.flag}>{copy.titleRestricted}</span></div>
        <Input
          onChange={e => setValue($conference.conferenceTitleChanged(e.target.value, state.conference))}
          value={state.conference.title} />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Platform</div>
        <SelectUnderlined
          onChange={e => setValue($conference.platformChanged(+e.target.value, state.conference))}
          options={fromEnum(utils.ConferencePlatform)}
          value={state.conference.conferencePlatform} />
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Conference Type</div>
        <SelectUnderlined
          onChange={e => setValue($conference.conferenceTypeChange(+e.target.value, state.conference))}
          options={options}
          value={state.conference.conferenceType} />
      </div>

      {$conference.showConferenceDialIn(state.conference) &&
        <div className={styles.col}>
          <div className={styles.label}>Dial-In</div>
          <PhoneInputControlled
            classes={{
              invalid: styles.inputError,
            }}
            value={state.conference.conferenceDialIn}
            invalid={state.conference.dialInValid}
            onChange={val => setValue($conference.phoneChanged(val, state.conference))} />
        </div>}

      {$conference.showConferenceLink(state.conference) &&
        <div className={styles.col}>
          <div className={styles.label}>Conference Link</div>
          <Input
            onChange={e => setValue({ conferenceLink: e.target.value })}
            value={state.conference.conferenceLink} />
        </div>}

      <div className={styles.full}>
        <div className={styles.label}>Client Notes <span className={styles.flag}>{copy.restricted}</span></div>
        <ConferenceDetailsEditorContainer
          onChange={handleChangeNotes}
          editable={true}
          manager={notes.manager}
          state={notes.state}>
          <Toolbar className={styles.toolbar} />
          <ConferenceDetailsEditor />
        </ConferenceDetailsEditorContainer>
      </div>

      {$conference.showConferenceDetails(state.conference) && state.conference.conferenceDetails &&
        <div className={styles.full}>
          <div className={styles.label}>Additional Details</div>
          <ConferenceDetailsEditorContainer
            onChange={handleChangeDetails}
            editable={true}
            manager={manager}
            state={details}>
            <Toolbar className={styles.toolbar} />
            <ConferenceDetailsEditor />
          </ConferenceDetailsEditorContainer>
        </div>}
    </div>
  );
};

OffPlatform.displayName = 'ConferenceSettings.Form.OffPlatform';

const copy = {
  titleRestricted: `(Displayed Only In Client & Trinity Invitations)`,
  restricted: `(Displayed Only In Client Invitations)`,
};