import { useCallback, useContext, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ProjectType, UserProjectStatus } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import Autocomplete from '@/components/AutoComplete';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { MoneyInput } from '@/components/Input';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';
import { ProjectOverviewContext } from '$admin/containers/Project.Overview/Context';
import type { ProjectOverview } from '$admin/containers/Project.Overview/interfaces';
import { Select } from '$admin/components/Select';
import styles from './style/Project.AddMember.css';

type Props = {
  onSuccess?: () => unknown;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const AddMember = (props: Props) => {
  const [, data] = useContext(ProjectOverviewContext);

  const [status, setStatus] = useState<StatusState>(UserProjectStatus.Staging);
  const [selected, setSelected] = useState<Descriptor>(null);
  const [value, setValue] = useState('');
  const [rate, setRate] = useState<number | string>(getInitialRate(data));

  const query = useQuery({ queryKey: [
    `get:admin/members/search`,
    data.project.id,
    value,
  ], queryFn: () => {
    return api.members.search.name({
      name: value,
      projectId: data.project.id,
    })
    .then(res => {
      return res.items.map(x => ({
        id: x.id,
        name: `${x.profile.fullname} (${x.contact.email})`,
      }));
    });
  }, enabled: true });

  const mutation = useMutation({ mutationKey: [
    `post:admin/projects/members/bulk`,
    data.project.id,
  ], mutationFn: (params: MutationParams) => {
    return api.projects.members.bulkAddToAProject({
      targetProjectId: data.project.id,
      statusId: params.statusId,
      userIds: [params.userId],
      rate: params.rate,
    });
  } });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    if (selected?.name === e.target.value) return;

    setValue(e.target.value);
    setSelected(null);

  }, [
    selected,
  ]);

  const handleSelect = useCallback((item: Descriptor) => {
    setSelected(item);
    setValue(item.name);
  }, []);

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync({
      rate: +rate,
      statusId: status,
      userId: selected.id,
    })
    .then(props.onSuccess);
  }, [
    mutation,
    props.onSuccess,
    rate,
    selected?.id,
    status,
  ]);

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <Header text="Add Expert to Project" />

        <div className={styles.body}>
          <div className={styles.row}>
            <div className={styles.label}>Name</div>
            <div className={styles.autocomplete}>
              <Autocomplete
                autoFocus
                getItemValue={(item: Descriptor) => item.name}
                item={selected}
                items={query.data || []}
                name="members"
                onChange={handleChange}
                onSelect={handleSelect}
                placeholder="Search Experts"
                value={value} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Rate</div>
            <MoneyInput
              value={rate}
              onValueChange={setRate} />
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Status</div>
            <Select
              className={styles.field}
              defaultValue={UserProjectStatus.Staging}
              id="status"
              name="status"
              onChange={e => setStatus(+e.target.value as StatusState)}
              options={OptionsMap}
              value={status} />
          </div>
        </div>

        <ButtonSet>
          <ButtonActivityIndicator
            className={styles.btn}
            disabled={!selected}
            implicitDisable={false}
            loading={mutation.isLoading}
            onClick={handleSubmit}
            variant="brick">
            Add
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </Modal>
  );
};

AddMember.displayName = 'Modal.Project.AddMember';

const OptionsMap = {
  [UserProjectStatus.Staging]: 'Staging',
  [UserProjectStatus.Recommended]: 'Recommended',
  [UserProjectStatus.Invited]: 'Invited',
};

type StatusState =
  | UserProjectStatus.Invited
  | UserProjectStatus.Recommended
  | UserProjectStatus.Staging;

function getInitialRate(data: ProjectOverview.State) {
  const value = data.project.projectType === ProjectType.Survey
      ? data.surveySettings?.payout?.value
      : data.callSettings?.callPayout?.value;

  return value || 0;
}

type MutationParams =
  & Required<Pick<API.Projects.BulkAddToAProject.Request, 'rate' | 'statusId'>>
  & IUserId;