import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { MatchListQueryParamsContext, MembersQueryContext } from '$admin/Search/Context';
import { useDebouncedFilters } from '$admin/Search/Members/hooks/useDebouncedFilters';

type Props = {
  children: React.ReactNode;
};

export const SearchMembersQueryContainer = (props: Props) => {
  const [list] = useContext(MatchListQueryParamsContext);
  const filters = useDebouncedFilters();

  const query = useQuery({ queryKey: [
    `post:search/members`,
    filters.active,
    filters.pageIndex,
    filters.pageSize,
    list?.id,
  ], queryFn: () => {
    return api.search.members.fetchItems({
      active: filters.active,
      listId: list?.id,
      pageIndex: filters.pageIndex,
      pageSize: filters.pageSize,
    });
  }, enabled: true, placeholderData: {
    items: [],
    pagination: {
      pageCount: 0,
      totalCount: 0,
    },
  }, refetchOnWindowFocus: false });

  return (
    <MembersQueryContext.Provider value={query}>
      {props.children}
    </MembersQueryContext.Provider>
  );
};

SearchMembersQueryContainer.displayName = 'Search.Members.Query.Container';