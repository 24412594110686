import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useFetchAccountProfileSyncState } from '@utils/api';
import type { UI } from '@/types';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { PrepopulatePrompt } from './PrepopulatePrompt';

type Props = {
  redirect: UI.AbstractEffectParam;
};

const EnrichmentCheck = (props: Props) => {
  const [state, fetchSyncState] = useFetchAccountProfileSyncState();

  useEffect(() => {

    fetchSyncState();
    /* eslint-disable-next-line */
  }, []);

  const awaiting = state.loading || !state.value;
  const sync = state.value?.enrichment?.sync;

  useEffect(() => {

    if (!awaiting &&
        typeof props.redirect === 'function' &&
        !sync
    ) {
      props.redirect();
    }

  }, [
    awaiting,
    props,
    sync,
  ]);

  if (awaiting) {
    return (
      <ActivityIndicator
        color="var(--gray)"
        show />
    );
  }

  if (state.value?.enrichment?.sync) {
    return (
      <PrepopulatePrompt redirect={props.redirect} />
    );
  } else if (typeof props.redirect !== 'function') {
    return (
      <Redirect to={props.redirect} />
    );
  } else {
    return null;
  }
};

export { EnrichmentCheck };
export default EnrichmentCheck;