import qs from 'query-string';
import * as enums from '@enums';
import http from '@services/http';
import type * as Compliance from '@services/api/interfaces/groups.compliance';
import * as xform from '@transformers';
import type { ProjectCall } from '@/types';

export const fetchAggregateReview = (data: Compliance.FetchAggregateReview.Request): Promise<Compliance.FetchAggregateReview.Response> => {
  const { groupId, ...query }  = data;
  const q = qs.stringify(query, { arrayFormat: 'bracket' });

  return http.get(`/groups/${groupId}/compliance/review?${q}`);
};

export const fetchCallReview = (data: Compliance.FetchCallReview.Request) => {
  return http.get(`/groups/${data.groupId}/compliance/review/calls/${data.callId}`)
  .then((res: Compliance.FetchCallReview.Response) => ({
    call: xform.normalizeCall<ProjectCall<string>, ProjectCall<Date>>(res.call),
    consultant: xform.setUserSlug(res.consultant),
    documents: res.documents,
    project: res.project,
    screener: xform.normalizeSurveyResponse(res.screener),
  }));
};

export const fetchMessageThreadReview = (data: Compliance.FetchMessageThreadReview.Request) => {
  return http.get(`/groups/${data.groupId}/compliance/review/threads/${data.threadId}`)
  .then((res: Compliance.FetchMessageThreadReview.Response) => ({
    consultant: res.consultant,
    thread: xform.transformMessageThread(res.thread),
  }));
};

export const fetchSurveyResponseReview = (data: Compliance.FetchSurveyResponseReview.Request) => {
  return http.get(`/groups/${data.groupId}/compliance/review/surveys/${data.responseId}`)
  .then((response: Compliance.FetchSurveyResponseReview.Response) => {
    return response.type === 'internal-user'
      ? {
        type: response.type,
        data: {
          ...response.data,
          user: xform.setUserSlug(response.data.user),
        },
      }
      : response;
  });
};

export const updateCallChaperone = (data: Compliance.UpdateCallChaperone.Request) => {
  return http.patch(`/groups/${data.groupId}/compliance/review/calls/${data.callId}/chaperone`, data)
  .then((res: Compliance.UpdateCallChaperone.Response) => ({
    call: xform.normalizeCall<ProjectCall<string>, ProjectCall<Date>>(res.call),
  }));
};

const updateMessageApproval = (data: Compliance.UpdateMessageApproval.Request) => {
  return http.put(`/groups/${data.groupId}/compliance/review/messages/${data.messageId}`, data)
  .then((res: Compliance.UpdateMessageApproval.Response) => ({
    message: xform.transformMessageItem(res.message),
  }));
};

export const setMessageApprovalApproved = (data: Omit<Compliance.UpdateMessageApproval.Request, 'approvalStatusId'>) => {
  return updateMessageApproval({
    approvalStatusId: enums.ApprovalStatus.Approved,
    groupId: data.groupId,
    messageId: data.messageId,
  });
};

export const setMessageApprovalRejected = (data: Omit<Compliance.UpdateMessageApproval.Request, 'approvalStatusId'>) => {
  return updateMessageApproval({
    approvalStatusId: enums.ApprovalStatus.Rejected,
    groupId: data.groupId,
    messageId: data.messageId,
  });
};