import { compareDesc } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { CallItem } from './CallItem';
import styles from './style/ProjectSummary.css';

type Props =  IProjectId;

export const ProjectCompletedCalls = ({ projectId }: Props) => {

  const { data, isLoading, isFetching } = useQuery({ queryKey: ['project-summary-calls', projectId], queryFn: async () => {
    const res = await api.projects.getProjectSummaryCalls({
      projectId,
    });

    return {
      calls: res.calls.sort((a, b) => compareDesc(a.timeStart, b.timeStart)),
    };
  }, placeholderData: {
    calls: [],
  }, refetchOnWindowFocus: false });

  const hasCalls = data.calls.length > 0;
  const loading = isLoading || isFetching;

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.h2}>Completed Calls</h2>
      </div>
      {!loading && hasCalls &&
        data.calls.map(c => (
          <CallItem
            item={c}
            key={c.id}
            projectId={projectId} />
        ))
      }
      {!loading && !hasCalls &&
        <div className={styles.nocalls}>No calls completed yet.</div>
      }
    </div>
  );
};