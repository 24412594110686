/* eslint-disable react/jsx-max-props-per-line */
import { memo } from 'react';
import type { ProjectIconProps } from './interfaces';

export const SurveyProjectIcon = memo(({ className, size }: ProjectIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Group-10" transform="translate(5.000000, 6.000000)" strokeWidth="2">
          <line x1="0.870862531" y1="14.5" x2="14.4638744" y2="14.5" id="Path-5" stroke="#626262" />
          <line x1="7.5" y1="10" x2="7.5" y2="3.46944695e-15" id="Path-4" stroke="#5977B4" />
          <line x1="2.5" y1="10" x2="2.5" y2="5" id="Path-4" stroke="#28AAE0" />
          <line x1="12.5" y1="10" x2="12.5" y2="3" id="Path-4" stroke="#5E5FA9" />
        </g>
      </g>
    </svg>
  );
});

export default SurveyProjectIcon;
