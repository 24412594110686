import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';

type Params = API.Projects.DownloadCallFiles.Request;

export const useDownloadProjectCallsData = (params: Params, options: UseDownloaderOptions<API.Projects.DownloadCallFiles.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:admin/projects/calls/export`,
      params,
    ],
    queryFn: () => {
      return api.projects.calls.downloadCallFiles({
        projectId: params.projectId,
      });
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};