import { useContext, useMemo } from 'react';
import Color from 'color';
import { useSelectGroup } from '@containers/Store';
import { color as $c } from '@/utils';
import { AppThemingContext, BrandThemingContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const AppThemingContainer = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const group = useSelectGroup();

  const themingCtx = useMemo(() => {
    return {
      enabled: group?.settings?.whitelabeling,
      palette: {
        ...transformColors({
          primary: query.data?.survey?.palette?.primary?.main,
          prompt: query.data?.survey?.palette?.primary?.main,
        }),
        primary: transformPrimary(query.data?.survey?.palette?.primary?.main),
        shade: transformShade(query.data?.survey?.palette?.primary?.main),
      },
      theme: query.data,
    };
  }, [
    query,
    group?.settings?.whitelabeling,
  ]);

  return (
    <AppThemingContext.Provider value={themingCtx}>
      {props.children}
    </AppThemingContext.Provider>
  );
};

AppThemingContainer.displayName = 'AppTheming.Container';

export const transformPrimary = (primary: string) => {
  if (!primary) return null;

  return {
    main: primary,
    dark: Color(primary)
      .darken(0.1)
      .hex(),
    light: Color(primary)
      .desaturate(0.06)
      .lightness(75)
      .hex(),
    lighter: Color(primary)
      .desaturate(0.06)
      .lightness(80)
      .hex(),
    text: $c.isDark(primary) ? `var(--pri-01)` : `var(--black-l)`,
  };
};

const transformShade = (bubble: string) => {
  if (!bubble) return null;

  return {
    light6: bubble,
    light5: Color(bubble)
      .desaturate(0.06)
      .lightness(75)
      .hex(),
    light4: Color(bubble)
      .desaturate(0.06)
      .lightness(70)
      .hex(),
    light3: Color(bubble)
      .desaturate(0.06)
      .lightness(65)
      .hex(),
    light2: Color(bubble)
      .desaturate(0.06)
      .lightness(60)
      .hex(),
    light1: Color(bubble)
      .desaturate(0.06)
      .lightness(50)
      .hex(),
    main: bubble,
    dark1: Color(bubble)
      .darken(0.1)
      .hex(),
    dark2: Color(bubble)
      .darken(0.2)
      .hex(),
    dark3: Color(bubble)
      .darken(0.3)
      .hex(),
  };
};

const transformColors = (color: { primary: string; prompt: string }) => {

  function createPrimaryColor() {
    if (!color?.primary) {
      return {
        blue: null,
      };
    }

    return {
      blue: null,
    };
  }

  function createPromptColor() {
    if (!color?.prompt) {
      return {
        prompt: null,
        green: null,
      };
    }

    const text = $c.isDark(color?.prompt) ? `var(--pri-01)` : `var(--black-l)`;

    return {
      green: null,
      prompt: {
        light6: color?.prompt,
        light5: Color(color?.prompt)
          .desaturate(0.06)
          .lightness(75)
          .hex(),
        light4: Color(color?.prompt)
          .desaturate(0.06)
          .lightness(70)
          .hex(),
        light3: Color(color?.prompt)
          .desaturate(0.06)
          .lightness(65)
          .hex(),
        light2: Color(color?.prompt)
          .desaturate(0.06)
          .lightness(60)
          .hex(),
        light1: Color(color?.prompt)
          .desaturate(0.06)
          .lightness(50)
          .hex(),
        main: color?.prompt,
        dark1: Color(color?.prompt)
          .darken(0.1)
          .hex(),
        dark2: Color(color?.prompt)
          .darken(0.2)
          .hex(),
        dark3: Color(color?.prompt)
          .darken(0.3)
          .hex(),
        text,
        icon: color?.primary || text,
      },
    };
  }

  return {
    ...createPrimaryColor(),
    ...createPromptColor(),
  };
};
