import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api/projects';
import type { Projects } from '@api/interfaces/projects';

type Data = Projects.CreateProjectResponse;
type Vars = Projects.CreateProjectRequest;

export function useCreateProject(
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation({ mutationKey: ['create-project'], mutationFn: params => {

    return api.createProject(params);

  }, ...options });
}