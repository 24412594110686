import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Integrations } from '@api/interfaces';

type Data = Integrations.Slack.GetTeams.Response['teams'];

export function useSlackTeams(
  options?: UseQueryOptions<Data, AxiosResponse>
) {

  return useQuery({ queryKey: ['slack-teams'], queryFn: () => {
    return api.integrations.getSlackTeams()
      .then(res => res.teams);
  }, ...options });
}