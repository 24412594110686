import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import { toTitleCase } from '@utils';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';

type Params = {
  extension: 'csv' | 'xlsx';
  type: 'calls' | 'messages' | 'packages'| 'referrals'  | 'surveys';
} & API.Reports.DownloadMonthlyBillingReport.Request;

export const useDownloadMonthlyBillingReport = (params: Params, options: UseDownloaderOptions<API.Reports.DownloadMonthlyBillingReport.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:admin/reports/billing/monthly`,
      params,
    ],
    queryFn: async () => {
      if (params.type === 'calls') {
        return api.reports.billing.downloadMonthlyCallsReport({
          month: params.month,
          year: params.year,
        });
      } else if (params.type === 'messages') {
        return api.reports.billing.downloadMonthlyMessagesReport({
          month: params.month,
          year: params.year,
        });
      } else if (params.type === 'surveys') {
        return api.reports.billing.downloadMonthlySurveysReport({
          month: params.month,
          year: params.year,
        });
      } else if (params.type === 'packages') {
        return api.reports.billing.downloadMonthlyPackagesReport({
          month: params.month,
          year: params.year,
        });
      } else if (params.type === 'referrals') {
        return api.reports.billing.downloadMonthlyReferralsReport({
          month: params.month,
          year: params.year,
        });
      }
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: `${params.month}_${params.year}_${toTitleCase(params.type)}.${params.extension}`,
        type: 'blob',
        value: res,
      }),
    },
  });
};