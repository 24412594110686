import { CallRole } from '@enums';
import type { IConference } from '@containers/Conference';
import { useConferenceInstance } from '@containers/Conference';

const rolesToViewInvisibleParticipants = [CallRole.Scheduler, CallRole.ModeratorAttendee, CallRole.Attendee];

export const useVisibleParticipants = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return instance.participants.filter(p => (p.id === instance.pid || p.isVisible || rolesToViewInvisibleParticipants.includes(instance.participant.role)));
};