import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { XCircle } from 'react-feather';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ZIndex } from '@consts';
import type { SurveyTemplate } from '@/types/survey';
import { cx, toTitleCase } from '@utils';
import { useDebounceValue } from '@utils/hooks';
import { useFetchSurveyTemplateRefData } from '@utils/api';
import { Input } from '@/components/Input';
import { useSameWidthModifier, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import styles from './style/Autocomplete.css';

type Props = {
  allowCustomSelection?: boolean;
  className?: string;
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  placeholder?: string;
  source: Source;
  value: SurveyTemplate.LinkedEntity;
};

type Source = {
  companies?: boolean;
  industries?: boolean;
  medicalConditions?: boolean;
  products?: boolean;
};

export const SurveyTemplateAutocomplete = ({
  allowCustomSelection,
  className,
  onChange,
  placeholder,
  source,
  value,
}: Props) => {

  const debouncedValue = useDebounceValue(value, 250);

  const [focused, setFocused] = useState(false);

  const entitySelected = useMemo(() => !!value.entity, [value.entity]);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const { isFetching, data: items } = useFetchSurveyTemplateRefData({
    ...{
      companies: false,
      industries: false,
      medicalConditions: false,
      products: false,
      ...source,
    },
    value: debouncedValue.value,
  }, {
    enabled: !entitySelected,
    keepPreviousData: true,
    placeholderData: [],
  });

  const sameWidthModifier = useSameWidthModifier();
  const zIndexModifier = useZIndexModifier({ zIndex: ZIndex.Modal + 1 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [sameWidthModifier, zIndexModifier],
    placement: 'bottom-start',
  });

  const includeCustomItem = useMemo(() => {
    if (!allowCustomSelection) return false;

    const inputValue = value.value;

    const hasInput = inputValue.length > 2;
    const missingFromList = !items.map(m => m.name.toLowerCase()).includes(inputValue.toLowerCase());

    return hasInput && missingFromList;
  }, [allowCustomSelection, value.value]);

  const popperVisible = useMemo(() => {
    if (!focused) return false;

    if (includeCustomItem) return true;

    return !entitySelected &&
      !!items.length;
  }, [
    focused,
    entitySelected,
    includeCustomItem,
    items,
  ]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    onChange({
      id: value.id,
      value: text,
      entity: null,
    });

  }, [
    onChange,
    value.id,
  ]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setFocused(false);
    }
  }, [referenceElement]);

  const handleItemClick = useCallback((item: Item) => () => {
    onChange({
      id: value.id,
      value: item.name,
      entity: {
        id: item.id,
        type: item.type,
      },
    });
    setFocused(false);
  }, [
    onChange,
    value.id,
  ]);

  const handleRemove = useCallback(() => {
    onChange({
      id: value.id,
      value: '',
      entity: null,
    });
  }, [
    onChange,
    value.id,
  ]);

  const handleSelectCustom = useCallback(() => {
    onChange({
      id: value.id,
      value: value.value,
      entity: {
        id: null,
        type: null,
      },
    });
    setFocused(false);
  }, [value]);

  const customItem = useMemo(() => {
    if (!includeCustomItem) return null;

    return (
      <div
        className={styles.menuItem}
        onClick={handleSelectCustom}>
        {value.value}
      </div>
    );
  }, [includeCustomItem, handleSelectCustom, value]);

  return (
    <>
      <div
        ref={setReferenceElement}
        className={cx(className, styles.root)}>
        <Input
          className={styles.input}
          disabled={!!value.entity}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          value={value.value} />
        {entitySelected &&
          <XCircle
            onClick={handleRemove}
            className={styles.close} />}
      </div>
      {popperVisible &&
        <Portal>
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={styles.menu}>
                {customItem}
                {items.map((item, i) => (
                  <div
                    key={i}
                    className={styles.menuItem}
                    onClick={handleItemClick(item)}>
                    {item.name} <span className={styles.type}>({toTitleCase(item.type)})</span>
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};

type Item = {
  id: number;
  name: string;
  type: SurveyTemplate.LinkedEntity['entity']['type'];
};