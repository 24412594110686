import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Integrations } from '@api/interfaces';

type Data = Integrations.Slack.GetChannels.Response['channels'];

export function useSlackChannels(
  teamId: string,
  options?: UseQueryOptions<Data, AxiosResponse>
) {

  return useQuery({ queryKey: ['slack-channels', teamId], queryFn: () => {
    return api.integrations.getSlackChannels({ teamId })
      .then(res => res.channels);
  }, ...options });
}