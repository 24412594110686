import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { Switch } from '@/components/Switch';

type Props = unknown;

export const Controls = (props: Props) => {
  const group = useSelectGroup();
  const dispatch = useDispatch();

  const mutation = useMutation({ mutationKey: [QKey.Groups.Settings.Patch, group.id], mutationFn: (data: Partial<Store.Group.Settings>) => {
    return api.groups.updateGroupSettings({
      groupId: group.id,
      ...data,
    });
  }, onError: (e: Error, variables, context: Store.Group.Settings) => {
    dispatch(actions.updateGroupSettings({ settings: context }));
  }, onMutate: variables => {
    const { settings } = group;

    dispatch(actions.updateGroupSettings({
      settings: {
        ...settings,
        ...variables,
      },
    }));

    return settings;
  } });

  const handleClick = useCallback(() => {
    mutation.mutate({
      whitelabeling: !group.settings.whitelabeling,
    });
  }, [
    group.settings.whitelabeling,
    mutation,
  ]);

  return (
    <Switch
      active={group.settings.whitelabeling}
      onClick={handleClick} />
  );
};

Controls.displayName = 'Settings.Branding.Controls';