import { useSelectUser } from '@containers/Store';
import { getLocationFor } from '@utils';
import type { Notifications } from '@/types';
import { NotificationAction, NotificationActions } from './Notification.Actions';
import ProjectCallNotification from './Notification.ProjectCall';

type Props = {
  item: Notifications.ProjectCallRescheduled.Item;
};

export const ProjectCallTimeChangeNotification = ({ item }: Props) => {

  const me = useSelectUser();

  const location = getLocationFor.scheduling.selecting({
    callId: item.payload.callId,
    projectId: item.payload.projectId,
    scheduleeId: me.id,
    schedulerId: item.payload.userId,
  });

  return (
    <ProjectCallNotification item={item}>
      <NotificationActions>
        <NotificationAction
          to={location}
          title="Confirm" />
      </NotificationActions>
    </ProjectCallNotification>
  );
};

export default ProjectCallTimeChangeNotification;