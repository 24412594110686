import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';

export const useUploadCoverImageMutation = (options?: Mutation.Options) => {
  const mutation = useMutation({ mutationKey: [QK.Posts.Articles.Attachments.CoverImage.Post], mutationFn: (data: Mutation.Variables) => {
    return api.posts.articles.uploadCoverImage({
      blob: data.blob,
      identifier: data.identifier,
    });
  }, ...options });

  return mutation;
};

namespace Mutation {
  export type Variables = {
    blob:       Blob;
    identifier: string;
  };

  export type Options =
    UseMutationOptions<
      API.Posts.Articles.UploadCoverImage.Response,
      unknown,
      API.Posts.Articles.UploadCoverImage.Request>;
}