import type { Validation } from '../interfaces';

type GetMinMaxParams = {
  hasNoneOfTheAbove: boolean;
  options: number;
};

export function getSelectionSettingsMinMax({ hasNoneOfTheAbove, options }: GetMinMaxParams) {
  const min = 1;
  const max = hasNoneOfTheAbove
    ? options - 2
    : options - 1;

  return [min, max] as const;
}

type AssertionParams = {
  min: number;
  max: number;
  settings: {
    maximumSelections: number;
    minimumSelections: number;
  };
};

export function assertValidSelectionSettings({ min, max, settings }: AssertionParams): Validation.AssertionResult {

  if (settings.maximumSelections && settings.minimumSelections &&
    settings.minimumSelections > settings.maximumSelections) {
    return {
      message: `Minimum selections can't be greater than maximum selections`,
      success: false,
    };
  }

  if (settings.maximumSelections &&
    (settings.maximumSelections < min || settings.maximumSelections > max)) {
    return {
      message: `Invalid Maximum selections value`,
      success: false,
    };
  }

  if (settings.minimumSelections &&
    (settings.minimumSelections < min || settings.minimumSelections > max)) {
    return {
      message: `Invalid Minimum selections value`,
      success: false,
    };
  }

  return {
    success: true,
  };
}