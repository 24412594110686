import * as types from '@store/action-types';
import type {
  AppDataFetchedAction,
  ConnectedAccountsActions,
  UserReducerActions,
} from '@store/interfaces';

type Actions =
    AppDataFetchedAction
  | ConnectedAccountsActions;

function connected(state: Store.User.Connected, action: UserReducerActions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return action.user.connected;

    case types.CONNECTED_ACOUNTS_CHANGED:
      return action.connected;

    default:
      return state;
  }
}

export default connected;