import type { Ref } from 'react';
import { forwardRef, useCallback } from 'react';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { GrayOutlineButton } from '@presentation/Buttons';
import * as enums from '@enums';
import { cx } from '@utils';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/UserAccessContextMenu.css';

type MenuProps = Omit<Props, 'role'> & {
  onClose: () => void;
};

const UserActionsContextMenu = forwardRef(({ onChange, onRemove, roles, onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const closeWrapper = useCallback((fn: () => void) => {
    return () => {
      fn();
      onClose();
    };
  }, [onClose]);
  return (
    <PopperMenu className={styles.menu} ref={ref}>
      {roles.map(role => (
        <PopperMenuItem key={role} onClick={closeWrapper(() => onChange(role))}>
          {enums.utils.WorkspaceObjectRole.getName(role)}
        </PopperMenuItem>
      ))}
      <div className={styles.divider} />
      <PopperMenuItem
        onClick={closeWrapper(onRemove)}>
        Remove
      </PopperMenuItem>
    </PopperMenu>
  );
});

type Props = {
  role: enums.WorkspaceObjectRole;
  onChange: (role: enums.WorkspaceObjectRole) => void;
  onRemove: () => void;
  roles: enums.WorkspaceObjectRole[];
};

export const RoleSelector = ({ role, ...props }: Props) => {
  return (
    <PopupState variant="popper" popupId="user-object-access-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <GrayOutlineButton
              onClick={popupState.open}
              className={cx(styles.roleBtn, { [styles.open]: popupState.isOpen })}>
              <div>{enums.utils.WorkspaceObjectRole.getName(role)}</div>
              <div className={styles.chevron} />
            </GrayOutlineButton>
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom-end"
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <UserActionsContextMenu
                onClose={popupState.close}
                {...props} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

export default RoleSelector;