import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import type { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddSafetyEndpoint: boolean;
  canRemoveSafetyEndpoint: boolean;
  safetyEndpoints: SurveyTemplate.LinkedEntity[];
  onAddSafetyEndpoint: () => void;
  onRemoveSafetyEndpoint: (id: string) => () => void;
  onUpdateSafetyEndpoint: (id: string) => (v: string) => void;
};

export const TemplateSafetyEndpointsInput = ({
  canAddSafetyEndpoint,
  canRemoveSafetyEndpoint,
  onAddSafetyEndpoint,
  onRemoveSafetyEndpoint,
  onUpdateSafetyEndpoint,
  safetyEndpoints,
}: Props) => {

  const items = useMemo(() => {
    return safetyEndpoints.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [safetyEndpoints]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter an endpoint"
        removeItem={onRemoveSafetyEndpoint}
        removeItemEnabled={canRemoveSafetyEndpoint}
        updateItemValue={onUpdateSafetyEndpoint} />
      {canAddSafetyEndpoint &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddSafetyEndpoint} />}
    </>
  );
};
