import { useContext, useEffect, useMemo } from 'react';
import { areIntervalsOverlapping, format } from 'date-fns';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CallRole } from '@enums';
import { getCurrentTimezone } from '@utils';
import type { CalendarRange } from '@/types';
import type { Scheduling } from '$admin/interfaces/project';
import { CalendarsContext, ParamsContext } from './Context';
import { useSchedulerCallRoleSelector } from './hooks';
import styles from './style/Booking.Schedule.css';

type Props = {
  end:       Date;
  onChange?: (hasConflict: boolean) => void;
  start:     Date;
};

export const ConflictWarning = ({ end, onChange, start, ...props }: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
    scheduling: useContext(ParamsContext),
  };

  const selectCallRole = useSchedulerCallRoleSelector();

  const bookings = useMemo<Booking[]>(() => {
    const items = ctx.calendars.query.data?.items || [];

    return items.map(calendar => ({
      ...calendar,
      events: calendar.events.filter(x => x.isBusy),
      roleId: selectCallRole(calendar.email)?.id,
    }));
  }, [
    ctx.calendars.query.data?.items,
    selectCallRole,
  ]);

  const conflicts = useMemo(() => {
    if (!start || !end) return [];

    return bookings.reduce<Conflict[]>((acc, { events, ...calendar }) => {
      const event = events.find(x => areIntervalsOverlapping(
        { start, end },
        { start: x.start, end: x.end },
      ));

      if (!event?.start || !event?.end) return acc;

      const name = calendar.roleId === CallRole.Scheduler
          ? calendar.name
          : `The Respondent`;

      const timerange = formatCallTime(event.start, event.end);

      return acc.concat({
        email: calendar.email,
        end: event.end,
        start: event.start,
        message: `${name} is already booked during this time slot from ${timerange}.`,
      });
    }, []);
  }, [
    bookings,
    end,
    start,
  ]);

  useEffect(() => {

    onChange?.(!!conflicts.length);

  }, [
    conflicts.length,
    onChange,
  ]);

  if (!conflicts.length) return null;

  return (
    <div className={styles.warning}>
      {conflicts.map(x =>
        <div
          className={styles.warn}
          key={x.email}>
          <ErrorOutlineIcon className={styles.icon} />
          <span>{x.message}</span>
        </div>)}
      <div className={styles.info}>
        {`Please select another time.`}
      </div>
    </div>
  );
};

ConflictWarning.displayName = 'Booking.ConflictWarning';

type Booking = {
  roleId: CallRole;
} & Scheduling.SchedulerCalendar;

type Conflict = {
  email:   string;
  message: string;
} & CalendarRange<Date>;

function formatCallTime(start: Date, end: Date) {
  if (!start || !end) return '';

  const pt1 = format(start, 'h:mm b');
  const pt2 = format(end, 'h:mm b');

  return `${pt1} to ${pt2} ${getCurrentTimezone()}`;
}