import * as types from '@store/action-types';
import type { Notifications } from '../interfaces';

export const notificationsLoaded = (data: Notifications.NotificationsLoaded.State) => {
  return {
    ...data,
    type: types.NOTIFICATIONS_LOADED,
  };
};

export const notificationsDismissed = (data: Notifications.NotificationsDismissed.State) => {
  return {
    ...data,
    type: types.NOTIFICATIONS_DISMISSED,
  };
};

export const notificationsSeen = (data: Notifications.NotificationsSeen.State) => {
  return {
    ...data,
    type: types.NOTIFICATIONS_SEEN,
  };
};

// export const dismissNotificationsByType = (data: Notifications.DismissByType.State) => {
//   const { type: notificationType, ...rest } = data;
//   return {
//     ...rest,
//     notificationType,
//     type: types.DISMISS_NOTIFICATIONS_BY_TYPE,
//   };
// };

export const notificationAdded = (data: Notifications.NotificationAdded.State) => {
  return {
    ...data,
    type: types.NOTIFICATION_ADDED,
  };
};
