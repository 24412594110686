import { forwardRef } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import { useProjectFormErrorContext } from '@screens/ProjectCreation/context';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import AutocompleteSearch from '@/components/AutoComplete/Search';
import AutocompleteVICS from '@/scenes/vics-selection/VICSAutocomplete';
import { Roles } from './Filters.Roles';
import * as Tags from './Filters.Tags';
import { SearchQualityMeter } from './SearchQualityMeter';
import type { ProjectFiltersProps } from './interfaces';
import styles from './style.css';
import * as utils from './utils';

type Props = {
  children?: React.ReactNode;
} & ProjectFiltersProps;

const ProjectFilters = forwardRef<HTMLInputElement, Props>(({ form, ...props }, ref) => {
  const { errors } = useProjectFormErrorContext();

  const handler = {
    roles: props.getFilterHandler('roles'),
    suggested: props.getFilterHandler('suggested'),
    titles: props.getFilterHandler('titles'),
    vics: props.getFilterHandler('vics'),
  };

  const getLimit = utils.getSuggestedItemsLimit({
    filters: props.filters,
    vics: form.vics,
  });

  function getVICSIdsByType(type: enums.VICS) {
    return utils.getVICSIdsByType(form.vics, type);
  }

  function getVICSItemsByType(type: enums.VICS) {
    return utils.getVICSItemsByType(form.vics, type);
  }

  function getSuggestedVICSByType(type: enums.VICS) {
    return utils.getVICSItemsByType(form.suggested, type).slice(0, getLimit(type));
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <Header className={styles.row}>
              <Title className={styles.row}>Search Filters</Title>
              <Subtitle className={styles.row}>Select the following to filter relevant profiles for this projects.</Subtitle>
            </Header>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Roles</div>
              </div>
              <Roles
                className={styles.input}
                onSelect={handler.roles.onAdd}
                selected={form.roles} />

              <div className={styles.tags}>
                <Tags.Selected
                  items={form.roles}
                  onClick={handler.roles.onRemove} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Specialty</div>
                <div className={styles.error}>{errors.industry}</div>
              </div>
              <AutocompleteVICS
                autocompleteClass={styles.input}
                onSelection={handler.vics.onAdd}
                parentIds={getVICSIdsByType(enums.VICS.Sector)}
                selectedItems={getVICSItemsByType(enums.VICS.Industry)}
                vicsType={enums.VICS.Industry} />
              <div className={styles.tags}>
                <Tags.Selected
                  items={getVICSItemsByType(enums.VICS.Industry)}
                  onClick={handler.vics.onRemove} />
                <Tags.Suggested
                  items={getSuggestedVICSByType(enums.VICS.Industry)}
                  onClick={handler.suggested.onAdd} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Subspecialty</div>
                <div className={styles.error}>{errors.subindustry}</div>
              </div>
              <AutocompleteVICS
                autocompleteClass={styles.input}
                onSelection={handler.vics.onAdd}
                parentIds={getVICSIdsByType(enums.VICS.Industry)}
                selectedItems={getVICSItemsByType(enums.VICS.SubIndustry)}
                vicsType={enums.VICS.SubIndustry} />
              <div className={styles.tags}>
                <Tags.Selected
                  items={getVICSItemsByType(enums.VICS.SubIndustry)}
                  onClick={handler.vics.onRemove} />
                <Tags.Suggested
                  items={getSuggestedVICSByType(enums.VICS.SubIndustry)}
                  onClick={handler.suggested.onAdd} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Job Title</div>
              </div>
              <AutocompleteSearch
                className={styles.input}
                name={enums.SearchType.Title}
                minimumCharacters={2}
                placeholder="Enter Job Titles"
                items={form.titles}
                onChange={api.autocomplete.title}
                onSelect={handler.titles.onAdd} />
              <div className={styles.tags}>
                <Tags.Selected
                  items={form.titles}
                  onClick={handler.titles.onRemove} />
              </div>
            </div>
          </div>

          <SearchQualityMeter value={utils.calculateFiltersQuality(form)} />

        </div>
      </div>
      {props.children}
    </>
  );
});

export { ProjectFilters };
export default ProjectFilters;