import { useCallback, useState } from 'react';
import { transformCalendarEvents } from '@containers/Calendar/transformers';
import * as api from '$admin/api';
import type { PersonalCalendar } from './interfaces';

type UseCalendarEventReturn = [
  PersonalCalendar,
  () => void,
  boolean,
];

const useCalendarEvents = (userId: number): UseCalendarEventReturn => {
  const [fetching, setFetching] = useState(false);
  const [calendar, setCalendar] = useState<PersonalCalendar>(null);

  const fetchCalendar = useCallback(() => {
    if (fetching) return;

    setFetching(true);

    api.scheduling.fetchPersonalCalendar({ userId })
    .then(data => {

      setCalendar({
        events: transformCalendarEvents(data.events),
      });

    })
    .finally(() => setFetching(false));

  }, [setCalendar, fetching, setFetching]);

  return [calendar, fetchCalendar, fetching];
};

export { useCalendarEvents };
export default useCalendarEvents;