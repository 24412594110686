import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Prompt } from '@/components/Modal/Prompt';
import type { CloneProjectPromptProps } from './interfaces';
import styles from './style/Prompt.css';

type Props =
  CloneProjectPromptProps;

export const CloneProjectPrompt = ({
  onClose,
  onConfirm,
  project,
  visible,
}: Props) => {

  const header = `Ready to create a new version of this survey?`;
  const body = `This action will archive the current version of the survey and will dismiss any pending invitations from respondents.`;

  const footer = (
    <ButtonSet>
      <Button
        className={styles.btn}
        color="destructive"
        onClick={onClose}
        variant="brick"
        title="Cancel" />
      <Button
        className={styles.btn}
        color="primary"
        onClick={onConfirm}
        variant="brick"
        title="Continue" />
    </ButtonSet>
  );

  return (
    <Prompt
      body={body}
      footer={footer}
      header={header}
      onClose={onClose}
      visible={visible} />
  );
};

export default CloneProjectPrompt;
