import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { querykey } from '@consts';
import type { Integrations } from '@api/interfaces';

type Data = Integrations.GetIntegrations.Response;

export function useFetchIntegrations(
  options?: UseQueryOptions<Data, AxiosResponse, Data>
) {

  return useQuery({ queryKey: querykey.Integrations.Get, queryFn: () => {
    return api.integrations.getIntegrations();
  }, ...options });
}