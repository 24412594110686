import type { ProfileEntry } from '@enums';
import { SeeMore } from '@presentation/SeeMore';
import styles from './style/Profile.Summary.css';
import type { Section } from './interfaces';

type Props =
  Section.SingleEntry.ReadOnlyProps<ProfileEntry.Bio>;

export const ProfileSummary = (props: Props) => {

  return (
    <SeeMore
className={styles.text}
lineHeight={22}
maxLines={5}>
      {props.value?.summary}
    </SeeMore>
  );
};

export default ProfileSummary;