import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSurveyThemingLoading } from '@containers/Branding/hooks/useSurveyThemingLoading';
import { MedicalExperienceContainer } from '@containers/ProjectOnboarding.MedicalExperience/MedicalExperience.Container';
import { AudienceType } from '@enums';
import { useStepper } from '@utils';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { ProjectInviteContext, ProjectOnboardingContext, ProjectScreensContext } from './Context';
import * as Footer from './Footer';
import { ProjectOnboardingInviteValidationContainer } from './ProjectOnboarding.InviteValidation.Container';
import { ProjectOnboardingResearchConsentContainer } from './ProjectOnboarding.ResearchConsent.Container';
import { ProjectOnboardingScreensContainer } from './ProjectOnboarding.Screens.Container';
import { useAssertProjectAudienceType, useEmitUpdateNewUserFromReferrer, useMatchProjectIdFromRoute, useTerminateProjectOnboarding } from './hooks';
import type { Step } from './Steps';
import { useFetchProjectInvite } from './hooks';
import type { ProjectOnboardingContextValue } from './interfaces';

type Props = {
  stepsFilter?: (step: Step) => boolean;
};

export const ProjectOnboardingContainer = (props: Props) => {
  useEmitUpdateNewUserFromReferrer();

  const history = useHistory();
  const { projectId } = useMatchProjectIdFromRoute();
  const assertProjectAudienceType = useAssertProjectAudienceType(AudienceType.Payer);

  const query = useFetchProjectInvite({ projectId }, {
    refetchOnWindowFocus: false,
    onError: () => {
      history.push('/');
    },
  });

  const decorate = assertProjectAudienceType(query.data?.project?.audienceType);

  if (query.isInitialLoading) {
    return null;
  }

  return (
    <ProjectInviteContext.Provider value={query}>
      <ProjectOnboardingInviteValidationContainer projectId={projectId}>
        <MedicalExperienceContainer>
          <ProjectOnboardingResearchConsentContainer>
            <ProjectOnboardingScreensContainer projectId={projectId} stepsFilter={props.stepsFilter}>
              <Footer.PoweredBy.ScreenDecorator decorate={decorate}>
                <ProjectOnboardingNavigationContainer projectId={projectId} />
              </Footer.PoweredBy.ScreenDecorator>
            </ProjectOnboardingScreensContainer>
          </ProjectOnboardingResearchConsentContainer>
        </MedicalExperienceContainer>
      </ProjectOnboardingInviteValidationContainer>
    </ProjectInviteContext.Provider>
  );
};

ProjectOnboardingContainer.displayName = 'Project.Onboarding.Container';

const ProjectOnboardingNavigationContainer = (props: IProjectId) => {
  const { screens, steps } = useContext(ProjectScreensContext);
  const [Screen, actions, step] = useStepper(screens);

  const { complete } = useTerminateProjectOnboarding({
    projectId: props.projectId,
  });

  const hasNextStep = useMemo(() => {
    return step !== steps.length - 1;
  }, [
    step,
    steps,
  ]);

  const next = useCallback(() => {
    if (!hasNextStep) {
      complete();
    } else {
      actions.next();
    }
  }, [
    actions,
    complete,
    hasNextStep,
  ]);

  const loading = useSurveyThemingLoading();
  const ready = !!(steps.length > 0 && !loading);

  if (!ready) {
    return (
      <AnimatedLoader
        color="var(--blue-l)"
        size={12} />
    );
  }

  const context: ProjectOnboardingContextValue = {
    back: actions.back,
    goTo: actions.goTo,
    hasNextStep,
    next,
    projectId: props.projectId,
  };

  return (
    <ProjectOnboardingContext.Provider value={context}>
      <Screen />
    </ProjectOnboardingContext.Provider>
  );
};

ProjectOnboardingNavigationContainer.displayName = 'Project.Onboarding.Navigation.Container';