import { useCallback, useRef } from 'react';
import type { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

type CreateCropPreview = {
  canvas: HTMLCanvasElement;
  contentType: string;
  crop: PixelCrop;
  image: HTMLImageElement;
  rotate?: number;
  scale?: number;
};

export const useCreateCropPreview = () => {
  const fileRef = useRef<string>();

  const createCropPreview = useCallback(({
    canvas,
    contentType,
    crop,
    image,
    rotate = 0,
    scale = 1,
  }: CreateCropPreview): Promise<string> => {

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
    const rotateRads = rotate * TO_RADIANS;

    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }

        if (fileRef.current) {
          window.URL.revokeObjectURL(fileRef.current);
        }
        fileRef.current = window.URL.createObjectURL(blob);
        resolve(fileRef.current);
      }, contentType);
    });
  }, []);

  return createCropPreview;
};

export default useCreateCropPreview;