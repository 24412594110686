import { useContext, useMemo } from 'react';
import C from 'color';
import { Theme } from '@mui/material/styles';
import { colors } from '@/brand-insights/components/Theme/theme';
import { AppThemingContext, BrandThemingContext } from '@containers/Branding/Context';

export const useThemeOverride = (muiTheme: Theme) => {
  const wl = useContext(AppThemingContext);
  const { query } = useContext(BrandThemingContext);

  const overwrite = useMemo(() => {
    if (query.isInitialLoading) {
      return {
        ...muiTheme,
        components: {
          ...muiTheme.components,
        },
        palette: {
          ...muiTheme.palette,
          blue: {
            ...muiTheme.palette.blue,
            ...wl.palette?.blue,
            ...wl.palette?.green,
          },
          primary: {
            ...muiTheme.palette.primary,
            main: colors.gray.main,
            dark: colors.gray.dark1,
            light: colors.gray.light1,
            light1: colors.gray.light1,
            light2: colors.gray.light2,
            light3: colors.gray.light3,
            light4: colors.gray.light4,
            light5: colors.gray.light5,
            light6: colors.gray.light6,
          },
          sentiment: {
            ...muiTheme.palette.sentiment,
            primary: {
              main: colors.gray.main,
              dark: colors.gray.dark1,
              light: colors.gray.light1,
              lighter: colors.gray.light2,
            },
          },
          badge: {
            ...muiTheme.palette.badge,
            citation: {
              bg: colors.gray.light1,
              color: colors.gray.main,
              hover: {
                bg: colors.gray.light2,
                color: colors.gray.main,
              },
            },
          },
          btnPrimary: {
            ...muiTheme.palette.btnPrimary,
            color: colors.gray.main,
            bg: colors.gray.light1,
            bg1: colors.gray.light1,
            border: colors.gray.main,
            active: {
              color: colors.gray.main,
              bg: colors.gray.light2,
              border: colors.gray.main,
            },
          },
          btnSecondary: {
            ...muiTheme.palette.btnSecondary,
            color: colors.gray.main,
            bg: colors.white.main,
            bg1: colors.white.main,
            border: colors.gray.main,
            active: {
              color: colors.gray.main,
              bg: colors.gray.light2,
              border: colors.gray.main,
            },
          },
          loader: {
            main: colors.gray.main,
          },
          skeleton: {
            main: colors.gray.light6,
          },
        },
      };
    }

    if (!wl?.enabled) {
      return muiTheme;
    }

    return {
      ...muiTheme,
      components: {
        ...muiTheme.components,
      },
      palette: {
        ...muiTheme.palette,
        wl: wl.palette,
        blue: {
          ...muiTheme.palette.blue,
          ...wl.palette?.blue,
          ...wl.palette?.green,
        },
        primary: {
          ...muiTheme.palette.primary,
          main: wl.palette?.primary?.main,
          dark: wl.palette?.primary?.dark,
          light: wl.palette?.primary?.light,
          light1: wl.palette?.shade?.light1,
          light2: wl.palette?.shade?.light2,
          light3: wl.palette?.shade?.light3,
          light4: wl.palette?.shade?.light4,
          light5: wl.palette?.shade?.light5,
          light6: wl.palette?.shade?.light6,
        },
        sentiment: {
          ...muiTheme.palette.sentiment,
          primary: {
            main: wl.palette?.primary?.main,
            dark: wl.palette?.primary?.dark,
            light: wl.palette?.primary?.light,
            lighter: wl.palette?.primary?.lighter,
          },
        },
        badge: {
          ...muiTheme.palette.badge,
          citation: {
            bg: lighten(wl.palette?.prompt?.main),
            color: wl?.palette?.prompt?.icon,
            hover: {
              bg: lighten(wl?.palette?.prompt?.dark1),
              color: wl?.palette?.prompt?.icon,
            },
          },
        },
        btnPrimary: {
          ...muiTheme.palette.btnPrimary,
          color: wl?.palette?.prompt?.icon,
          bg: wl?.palette?.prompt?.main,
          bg1: wl?.palette?.prompt?.main,
          border: wl?.palette?.prompt?.icon,
          active: {
            color: wl?.palette?.prompt?.icon,
            bg: wl?.palette?.prompt?.dark1,
            border: wl?.palette?.prompt?.icon,
          },
        },
        btnSecondary: {
          ...muiTheme.palette.btnSecondary,
          color: colors.gray.main,
          bg: colors.white.main,
          bg1: colors.white.main,
          border: colors.gray.main,
          active: {
            color: colors.gray.main,
            bg: colors.gray.light2,
            border: colors.gray.main,
          },
        },
        loader: {
          main: wl?.palette?.primary?.main,
        },
        skeleton: {
          main: wl?.palette?.prompt?.main,
        },
      },
    };
  }, [muiTheme, wl.palette, query?.isInitialLoading, wl?.enabled]);

  return overwrite;
};

function lighten(hex: string) {
  if (!hex) return;

  return C(hex)
    .desaturate(0.06)
    .lightness(80)
    .hex();
}