import { useCallback, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { cx } from '@utils';
import { AddItemButton } from '@/presentation/AddItemButton';
import { useSurveyConditionsBuilder } from '@/containers/SurveyBuilder.Logic';
import { SurveyConditionType } from '@/enums';
import { useZIndexModifier, PopperMenu } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { ContextMenuItem } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  className?: string;
};

export const AddLogicCondition = ({ className }: Props) => {

  const { addItem } = useSurveyConditionsBuilder();

  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleAddItem = useCallback((conditionType: SurveyConditionType) => () => {
    addItem(conditionType);
  }, [addItem]);

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const items: ContextMenuItemProps[] = [
    {
      children: 'Question Condition',
      onClick: handleAddItem(SurveyConditionType.Question),
    },
    {
      children: 'Sum Condition',
      onClick: handleAddItem(SurveyConditionType.AggregatedSum),
    },
    {
      children: 'Classification Condition',
      onClick: handleAddItem(SurveyConditionType.Classification),
    },
  ].filter(Boolean);

  return (
    <>
      <AddItemButton
        className={className}
        ref={setReferenceElement}
        onClick={handleClick}
        label="Add Condition" />
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <PopperMenu>
                {items.map((m, i) => {
                  return (
                    <ContextMenuItem
                      key={i}
                      {...m}
                      onClick={() => {
                        m.onClick();
                        setOpen(false);
                      }} />
                  );
                })}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

export default AddLogicCondition;