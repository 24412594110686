import { useCallback } from 'react';
import cuid from 'cuid';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';
import { useCanSaveList, useListElements } from './list-elements';

const minimum = 1;
const maximum = 10;

export const useCanSaveComparators = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minimum,
    maxItems: maximum,
  });
};

export const useTemplatePricingComparators = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const {
    canAdd: canAddComparator,
    canRemove: canRemoveComparator,
    items: comparators,
    onAddItem: onAddComparator,
    onRemoveItem: onRemoveComparator,
    onUpdateItem: onUpdateComparator,
    setItems,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.pricingComparators,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-pricing-comparators-updated',
      value: comparators,
    });
  }, [dispatch, comparators]);

  const onCopyTreatmentComparators = useCallback(() => {
    const value = templateData.treatmentComparators.map(m => ({
      id: cuid(),
      value: m.value,
    }));

    setItems(value);
  }, [templateData.treatmentComparators, setItems]);

  return {
    canAddComparator,
    canRemoveComparator,
    onAddComparator,
    onRemoveComparator,
    onUpdateComparator,
    onCopyTreatmentComparators,
    onSubmit,
    comparators,
  };
};

export const useTemplateTreatmentComparators = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const {
    canAdd: canAddComparator,
    canRemove: canRemoveComparator,
    items: comparators,
    onAddItem: onAddComparator,
    onRemoveItem: onRemoveComparator,
    onUpdateItem: onUpdateComparator,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.treatmentComparators,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-treatment-comparators-updated',
      value: comparators,
    });
  }, [dispatch, comparators]);

  return {
    canAddComparator,
    canRemoveComparator,
    onAddComparator,
    onRemoveComparator,
    onUpdateComparator,
    onSubmit,
    comparators,
  };
};