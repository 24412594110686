import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { WorkspaceFolders } from '@api/interfaces/workspace.folder';
import { useSelectWorkspaces } from '@containers/Store';
import type { CreateFolderParams } from '@/containers/Workspace.Folder/interfaces';
import { useCreateFolder } from '@/containers/Workspace.Folder/hooks/useCreateFolder';
import { getLocationFor } from '@utils';
import NewFolderModal from '@/components/Workspace.Folder/NewFolderModal';
import type { NewFolderModalProps } from '@/components/Workspace.Folder/interfaces';

type Props =
  Pick<NewFolderModalProps,
    'location' |
    'parentObject'>;

export const useNewFolderModal = (props: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);
  const history = useHistory();

  const workspaces = useSelectWorkspaces();

  const navigateToFolderPage = useCallback((res: WorkspaceFolders.CreateFolder.Response<Date>) => {
    const path = getLocationFor.workspaces.folder({
      folderId: res.folder.id,
    });
    history.push(path);
  }, [history]);

  const workspacesArr = useMemo(() => [workspaces.personal, workspaces.group], [workspaces]);
  const { mutateAsync: createFolder } = useCreateFolder({
    onSuccess: navigateToFolderPage,
  });

  const handleConfirm = useCallback((data: CreateFolderParams) => {
    return createFolder(data);
  }, [
    createFolder,
  ]);

  const Modal = useCallback(() => {
    if (!open) return null;
    return (
      <NewFolderModal
        onClose={toggleVisibility}
        onConfirm={handleConfirm}
        open={visible}
        workspaces={workspacesArr}
        {...props} />
    );
  }, [
    handleConfirm,
    visible,
    toggleVisibility,
    props,
    workspacesArr,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useNewFolderModal;