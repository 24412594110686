import type { RespondentProps } from '@/types';
import { MemberBaseballCard } from '@/components/BaseballCard/MemberCard.Client';
import { AnonymousBaseballCard } from '@/components/BaseballCard/AnonymousBaseballCard';
import { ExternalBasecallCard } from '@/components/BaseballCard/ExternalCard';

type Props = {
  classes?: {
    container?: string;
    tooltip?: string;
  };
  children: React.ReactNode;
  className?: string;
} & RespondentProps;

export function RespondentBaseballCard(props: Props) {
  if (props.anonymous) {
    return (
      <AnonymousBaseballCard
        classes={props.classes}
        user={props.user}
        subtext={null}>
        {props.children}
      </AnonymousBaseballCard>
    );
  }

  switch (props.type) {
    case 'external':
      return (
        <ExternalBasecallCard
          classes={props.classes}
          user={props.user}>
          {props.children}
        </ExternalBasecallCard>
      );
    case 'internal':
      return (
        <MemberBaseballCard
          classes={props.classes}
          userId={props.user.id}
          projectId={props.projectId}>
          {props.children}
        </MemberBaseballCard>
      );
  }
}

// export function RespondentBaseballCard(props: Props) {
//   if (props.type === 'internal') {
//     if (props.anonymity) {
// return (
//   <AnonymousBaseballCard
//     className={props.className}
//     user={props.user}
//     subtext={props.user.fullname == props.respondentName ? null : props.user.fullname}>
//     {props.children}
//   </AnonymousBaseballCard>
// );
//     } else {
// return (
//   <MemberBaseballCard
//     className={props.className}
//     userId={props.user.id}
//     projectId={props.projectId}>
//     {props.children}
//   </MemberBaseballCard>
// );
//     }
//   } else {
//     return (
// <ExternalBasecallCard
//   className={props.className}
//   user={props.user}>
//   {props.children}
// </ExternalBasecallCard>
//     );
//   }
// }