import { useCallback, useContext } from 'react';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import * as api from '@api';
import { VideoUploaderContext, VideoUploaderPublishContext } from '@containers/PostCreation/Context';
import { PostMediaType } from '@enums';
import { Uploader } from './interfaces/post.video';

type Props = {
  children: React.ReactNode;
};

export const PublishContainer = (props: Props) => {
  const [_, dispatch] = useContext(VideoUploaderContext);

  const handleSubmit = useCallback(({ post, topics }: Uploader.HandleSubmit.Params) => {
    dispatch({
      post,
      type: 'posting/init',
    });

    Promise.all(
      [post.video, post.thumbnail || post.snapshot]
      .filter(Boolean)
      .map(async file => {
        const type = resolveContentType(file);

        const format = parseFormat(type);
        const response = await api.posts.uploads.queue({
          format,
          postIdentifier: post.identifier,
          type: type,
        });

        return {
          bucket: response.data.bucket,
          fid: response.data.identifier,
          file,
          format,
          pid: post.identifier,
          s3Key: response.data.s3Key,
        };
      }),
    )
    .then(items => {

      return Promise.all(items.map(async upload => {
        const { credentials } = await api.posts.uploads.start({
          fileUploadId: upload.pid,
          s3Key: upload.s3Key,
        });

        const client = new S3Client({
          credentials,
          region: 'us-east-1',
          useAccelerateEndpoint: true,
        });

        const body = await resolveBody(upload.file);
        const type = resolveContentType(upload.file);

        const ul = new Upload({
          client,
          params: {
            Body: body,
            Bucket: upload.bucket,
            ContentType: type,
            Key: upload.s3Key,
            StorageClass: 'STANDARD_IA',
          },
        });

        ul.on('httpUploadProgress', event => {
          dispatch({
            fid: upload.fid,
            type: 'file-upload/progress-change',
            value: Math.ceil((event.loaded * 100) / event.total),
          });
        });

        dispatch({
          upload,
          type: 'file-upload/started',
        });

        await ul.done();

        return {
          identifier: upload.fid,
          format: upload.format,
          s3Key: upload.s3Key,
          type,
        };
      }));
    })
    .then(results => {

      const video = results.find(x => isVideo(x.type));
      const thumbnail = results.find(x => isImage(x.type)) ?? null;

      const params = {
        body: post.body,
        identifier: post.identifier,
        media: {
          content: {
            ...video,
            description: post.description.value,
          },
          thumbnail,
          title: post.title,
          typeId: PostMediaType.Video,
        },
        topics,
      };

      dispatch({
        pid: post.identifier,
        status: Uploader.PostingState.Saving,
        type: 'posting/state-change',
      });

      api.posts.publishPost(params)
      .then(item => {
        dispatch({
          post: item,
          type: 'posting/created',
        });
      });
    });

  }, [dispatch]);

  return (
    <VideoUploaderPublishContext.Provider value={handleSubmit}>
      {props.children}
    </VideoUploaderPublishContext.Provider>
  );
};

PublishContainer.displayName = 'PostCreation.Post.Video.Uploader.PublishContainer';

function parseFormat(type: File['type']) {
  return type
    ? type.replace(/(.*)\//g, '')
    : '';
}

function isVideo(type: File['type']) {
  return /^video\//.test(type);
}

function isImage(type: File['type']) {
  return /^image\//.test(type);
}

function resolveContentType(data: File | string) {
  return typeof data === 'string'
    ? 'image/png'
    : data.type;
}

async function resolveBody(data: File | string) {
  if (typeof data === 'string') {
    return fetch(data)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], 'snapshot.png', {
          type: 'image/png',
        });
      });
  }

  return data;
}