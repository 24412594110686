import { useCallback, useEffect, useMemo } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import { cx } from '@utils';
import * as $api from '@api';
import type { BaseProjectSurvey } from '@/types';
import { DropDown } from '@/components/DropDown';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Select } from '$admin/components/Select';
import { SelectedSurveyContext } from './context';
import styles from './styles.css';

type BaseProps = {
  classes?: {
    dropdownContainer?: string;
    dropdown?: string;
  };
  onChange?: (surveyId: number) => void;
  ActionsComponent?: React.ComponentType<ActionProps>;
} & ChildrenProps;

type ActionProps = {
  survey: BaseProjectSurvey;
};

type Props = BaseProps & IProjectId;

export const SurveyQuerySelector = ({ onChange: onChangeProp, ...props }: Props) => {
  const [surveyId, setSurveyId] = useQueryParam('surveyId', NumberParam);
  const query = useQuery({ queryKey: ['fetch-project-surveys', props.projectId], queryFn: () => {
    return $api.projects.surveys.getSurveys({ projectId: props.projectId });
  }, keepPreviousData: true, onSuccess: res => {
    if (!surveyId && res.surveys.length) {
      setSurveyId(res.surveys[0].id, 'replaceIn');
    }
  } });

  const survey = useMemo(() => {
    return query.data?.surveys.find(s => s.id === surveyId);
  }, [surveyId, query.data]);

  const onChange = useCallback((surveyId: number) => {
    onChangeProp?.(surveyId);
    setSurveyId(surveyId);
  }, [setSurveyId, onChangeProp]);

  if (query.isFetching) return <ActivityIndicator />;

  return (
    <SelectedSurveyContext.Provider value={{ selectedSurvey: surveyId }}>
      <div className={styles.root}>
        <div className={cx(styles.dropdownContainer, props.classes?.dropdownContainer)}>
          <Select
            classes={{
              root: cx(props.classes?.dropdown, styles.dropdown),
            }}
            value={surveyId ?? ''}
            options={query.data.surveys?.map(s => ({ id: s.id, name: s.name })) ?? []}
            onChange={e => onChange(+e.target.value)} />
          {props.ActionsComponent && survey && <props.ActionsComponent survey={survey} />}
        </div>
        <div className={styles.children}>
          {props.children}
        </div>
      </div>
    </SelectedSurveyContext.Provider>
  );
};