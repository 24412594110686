import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { AccountProfileLoadingContext } from '@containers/AccountProfile';
import { MedicalBackgroundContext } from '@containers/MedicalOnboarding/Context';
import { ProfileEntry, utils } from '@enums';
import { ProfileSectionMultiEntry } from '@/components/UserProfile';
import { SurveyResponseAnswer } from '@/components/SurveyResponse';
import { SurveyQuestionText } from '@/components/Survey.RichText';
import { Select } from '$admin/components/Select';
import * as Input from '$admin/Member.Experience/Input';
import { ProfileOverviewQueryContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Tab.Payer.css';

type Props = unknown;

export const Payer = (props: Props) => {
  const query = useContext(ProfileOverviewQueryContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Background />
          <Employment />
          <Screeners />
        </div>
      </div>
    </div>
  );
};

const Background = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const markets = useQuery({ queryKey: [QK.Search.Markets.Get], queryFn: api.search.fetchMarkets, refetchOnWindowFocus: false });

  if (ctx.query.isInitialLoading) {
    return (
      <div className={styles.background}>
        <Layout.Tile.Root className={styles.tile}>
          Loading
        </Layout.Tile.Root>
      </div>
    );
  }

  return (
    <div className={styles.background}>
      <Layout.Tile.Root className={styles.tile}>
        <div className={styles.cols}>
          <div className={styles.field}>
            <div className={styles.label}>Country</div>
            <div className={styles.value}>
              <Input.Country />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Payer / Admin Type</div>
            <div className={styles.value}>
              <Input.PayerAdminType classes={{ root: styles.type }} />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Market</div>
            <Input.Market
              classes={{ root: styles.market }}
              items={markets.data?.options} />
          </div>
        </div>
      </Layout.Tile.Root>
    </div>
  );
};

const Employment = (props: Props) => {
  const loading = useContext(AccountProfileLoadingContext);

  if (loading) {
    return (
      <div className={styles.employment}>
        <Layout.Tile.Root className={styles.tile}>
          <div>Loading...</div>
        </Layout.Tile.Root>
      </div>
    );
  }

  return (
    <div className={styles.employment}>
      <Layout.Tile.Root className={styles.tile}>
        <ProfileSectionMultiEntry type={ProfileEntry.Employment} />
      </Layout.Tile.Root>
    </div>
  );
};

const Screeners = (props: Props) => {
  const query = useContext(ProfileOverviewQueryContext);
  const [responseId, setResponseId] = useState(query.data?.screeners?.[0]?.responseId);

  const screener = useQuery({ queryKey: [
    `get:users/profile/screeners`,
    query.data?.id,
    responseId,
  ], queryFn: () => {
    return api.users.profiles.fetchScreenerResponse({
      responseId,
      userId: query.data.id,
    });
  }, enabled: !!responseId });

  if (!responseId) {
    return (
      <Layout.Tile.Root className={styles.empty}>
        No Screeners
      </Layout.Tile.Root>
    );
  }

  return (
    <div className={styles.screeners}>
      <div className={styles.header}>
        <div className={styles.label}>Questionnaire:</div>
        <Select
          className={styles.select}
          onChange={e => setResponseId(e.target.value)}
          options={query.data.screeners.map(x => ({
            name: x.name,
            id: x.responseId,
          }))}
          value={responseId} />
      </div>
      <Layout.Tile.Root className={styles.tile}>
        {screener.data?.items?.length
          ? <Screener items={screener.data.items} />
          : null}
      </Layout.Tile.Root>
    </div>
  );
};

Payer.displayName = 'Tab.Payer';

type ScreenerProps = Pick<API.Users.Profiles.FetchScreenerResponse.Response, 'items'>;

const Screener = (props: ScreenerProps) => {
  const index = Math.ceil(props.items.length / 2);
  const one = props.items.slice(0, index);
  const two = props.items.slice(index);

  return (
    <div className={styles.screener}>
      <div className={styles.col}>
        {one.map(x =>
          <div
            className={styles.item}
            key={x.question.id}>
            <SurveyQuestionText
              className={styles.q}
              value={x.question.value} />
            <SurveyResponseAnswer
              className={styles.a}
              item={x} />
          </div>)}
      </div>
      <div className={styles.col}>
        {two.map(x =>
          <div
            className={styles.item}
            key={x.question.id}>
            <SurveyQuestionText
              className={styles.q}
              value={x.question.value} />
            <SurveyResponseAnswer
              className={styles.a}
              item={x} />
          </div>)}
      </div>
    </div>
  );
};