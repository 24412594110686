import { useCallback } from 'react';
import { Calendar, ExternalLink, Info, Trash2 } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@presentation/Tooltip';
import { useIsInternalUser, useSelectUser } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import type { ProjectCall, UserProfileProps } from '@/types';
import { cx, getLocationFor } from '@utils';
import { /* useRescheduleProjectCall, */ useJoinConference } from '@containers/Scheduler/hooks';
import { EventDate, CallParticipant } from './EventPopover.Shared';
import styles from './style/EventPopover.css';

type Props = {
  conference: {
    identifier: number;
  };
  item: ProjectCall;
  onCancel: () => unknown;
  project: {
    id: number;
    name: string;
  };
  showActions: boolean;
  withUser: WithUser;
};

type WithUser = {
  id: number;
  profile: UserProfileProps;
};

export const ProjectCallEventPopover = ({ conference, item, onCancel, project, showActions, withUser }: Props) => {
  const me = useSelectUser();
  const hasClientRole = useHasClientRole();
  const isInternalUser = useIsInternalUser();

  const history = useHistory();

  /* const [canReschedule, reschedulingLocation] = useRescheduleProjectCall(item.id); */
  const [canJoin, joinConferenceProps] = useJoinConference({
    call: item,
    conference,
  });

  const renderMyself = useCallback(() => {
    return (
      <CallParticipant
        hideLink={true}
        item={item}
        user={me} />
    );
  }, [item, me]);

  const renderParticipant = useCallback(() => {

    const hideLink = !hasClientRole;

    return (
      <CallParticipant
        hideLink={hideLink}
        item={item}
        user={withUser} />
    );

  }, [
    hasClientRole,
    item,
    withUser,
  ]);

  /*
  const confirmingLocation = getLocationFor.scheduling.selecting({
    callId: item.id,
    projectId: item.projectId,
    scheduleeId: me.id,
    schedulerId: item.lastModifiedBy,
  });

  const handleReschedule = useCallback(() => {
    history.push(reschedulingLocation);
  }, [history, reschedulingLocation]);
  */
  const handleJoin = useCallback(() => {
    joinConferenceProps.onClick();
    history.push(joinConferenceProps.to);
  }, [history, joinConferenceProps]);

  const handleViewDetails = useCallback(() => {
    const location = getLocationFor.call.root({ callId: item.id });
    history.push(location);
  }, [history, item.id]);

  return (
    <>
      <div className={styles.body}>
        <EventDate
          start={item.timeStart}
          end={item.timeEnd} />

        <div className={styles.title}>
          {item.title}
        </div>

        {isInternalUser && renderMyself()}
        {isInternalUser && renderParticipant()}

      </div>

      {showActions &&
        <div className={styles.actions}>

          <Tooltip title="View Details">
            <Info
              className={styles.actionIcon}
              onClick={handleViewDetails}
              size={22} />
          </Tooltip>

          {canJoin &&
            <Tooltip title="Join Call">
              <ExternalLink
                className={styles.actionIcon}
                onClick={handleJoin}
                size={22} />
            </Tooltip>
          }

          {/* canReschedule &&
            <Tooltip title="Reschedule">
              <Calendar
                className={styles.actionIcon}
                onClick={handleReschedule}
                size={22} />
            </Tooltip>
        */}

          {/* <Tooltip title="Cancel">
            <Trash2
              className={cx(styles.actionIcon, styles.delete)}
              onClick={onCancel}
              size={22} />
          </Tooltip> */}
        </div>
      }
    </>
  );
};

export default ProjectCallEventPopover;