import { useCallback } from 'react';
import { useSurveyBuilderState, useHasUncommittedChanges } from '@containers/SurveyBuilder';
import { useDiscardSurveyDraft } from '@utils/api/survey';
import type { RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { Alert } from '@/components/Modal/Alert';

export const DraftGuard = () => {
  const hasUncommittedChanges = useHasUncommittedChanges();
  const [state] = useSurveyBuilderState();

  const { mutate: discardDraft } = useDiscardSurveyDraft({});

  const renderAlert = useCallback((guard: RouteLeavingGuardState) => {

    return (
      <Alert
        confirmText="Save"
        cancelText='Discard'
        message="You have unsaved changes, do you want to save your draft?"
        onClose={() => {
          discardDraft({ surveyVersionId: state.draft.surveyVersionId });
          guard.confirmNavigation();
        }}
        onConfirm={guard.confirmNavigation}
        open={guard.open} />
    );
  }, [discardDraft, state.draft.surveyVersionId]);

  const uncommittedChanges = hasUncommittedChanges();
  const hasDraft = !!state.draft.surveyVersionId;

  return (
    <RouteLeavingGuard
      block={uncommittedChanges && hasDraft}>
      {renderAlert}
    </RouteLeavingGuard>
  );
};