import { useCallback, useEffect, useState } from 'react';
import { X } from 'react-feather';
import * as api from '@api';
import type { SearchContact } from '@services/api/interfaces/users';
import { isValidEmail } from '@utils';
import { useDebounceCallback } from '@utils/hooks';
import AutoComplete from '@/components/AutoComplete';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { ImportContacts } from '@/components/ConnectAccount/ImportContacts';
import { useConnectAccountContactSync } from '@/components/ConnectAccount/hooks';
import type { ManageRecipientsProps } from './interfaces';
import styles from './style.css';

type Props = ManageRecipientsProps;

const ManageRecipients = (props: Props) => {
  const [acItems, setAcItems] = useState<SearchContact[]>([]);

  const sync = useConnectAccountContactSync();

  const searchContacts = useCallback(() => {
    api.users.searchContacts({
      query: props.email,
    }).then(data => {
      const filteredResults = data.results.filter(f => !props.recipients.includes(f.value));
      setAcItems(filteredResults);
    });
  }, [props.email, props.recipients]);

  const searchContactsDebounced = useDebounceCallback(searchContacts, 300);

  useEffect(() => {
    if (props.email) {
      searchContactsDebounced();
    } else {
      setAcItems([]);
    }
  }, [props.email, searchContactsDebounced]);

  const addEnabled = value => {
    return isValidEmail(value) &&
      !props.recipients.includes(value);
  };

  const handleEmailSubmit = () => {
    if (addEnabled(props.email)) {
      props.onEmailSubmit();
    }
  };

  const handleEmailKeydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleEmailSubmit();
      e.preventDefault();
    }
  };

  const handleEmailSelect = item => {
    const val = item.isCustom ? item.name : item.value;

    if (addEnabled(val)) {
      props.onEmailChange(val, true);
      setAcItems([]);
    }
  };

  const renderTag = (email: string) => {
    return (
      <Tag
        key={email}
        onClick={() => props.onRemoveRecipient(email)}
        value={email} />
    );
  };

  const renderEmailInput = () => {
    const placeholder = 'Enter email addresses of recipients.';
    if (sync.connection) {
      return (
        <AutoComplete.Multi
          className={styles.input}
          onChange={e => props.onEmailChange(e.target.value) }
          onSelect={handleEmailSelect}
          items={acItems}
          getItemValue={(item: SearchContact) => item.name}
          placeholder={placeholder} />
      );
    } else {
      return (
        <Input
          className={styles.input}
          rootClassName={styles.inputRoot}
          placeholder={placeholder}
          onChange={e => props.onEmailChange(e.target.value)}
          onKeyDown={handleEmailKeydown}
          value={props.email} />
      );
    }
  };

  return (
    <>
      <div className={styles.inputContainer}>
        {renderEmailInput()}
        <div className={styles.imports}>
          <div className={styles.label}>Import Contacts:</div>
          <ImportContacts />
        </div>
        {!sync.connection &&
          <Button.Secondary
            className={styles.add}
            disabled={!addEnabled(props.email)}
            onClick={handleEmailSubmit}>
            + Add
          </Button.Secondary>}
      </div>
      <div className={styles.recipients}>
        {props.recipients.map(renderTag)}
      </div>
    </>
  );
};

type TagProps = {
  onClick: () => void;
  value: string;
};

const Tag = (props: TagProps) => {
  return (
    <div
      className={styles.recipient}
      onClick={props.onClick}>
      <div className={styles.email}>{props.value}</div>
      <X
        className={styles.x}
        size={18} />
    </div>
  );
};

export default ManageRecipients;