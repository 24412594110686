import { Editable } from './Editor.Editable';
import { MentionsContainer } from './Editor.MentionsContainer';
import type { Editor } from './interfaces';

type Props = Editor.TextFieldProps;

export const TextField = (props: Props) => {

  return (
    <MentionsContainer>
      <Editable {...props} />
    </MentionsContainer>
  );
};

TextField.displayName = 'PostCreation.Editor.TextField';