import * as consts from '@consts';
import { usePersistentURLState } from '@containers/PersistentURLState';
import type { ProjectLinkURLState as URLState  } from '../interfaces';

const usePersistentProjectInvite = () => {
  const urlstate = usePersistentURLState();

  return {
    get: () => urlstate.get<URLState>(consts.path.Invite.Root),
    set: <T extends URLState>(val: T) => urlstate.set(consts.path.Invite.Root, val),
    unset: () => urlstate.unset(consts.path.Invite.Root),
  };
};

export { usePersistentProjectInvite };
export default usePersistentProjectInvite;