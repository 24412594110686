import type { LeadFormData } from '$website/types';
import { LeadFormContext } from './Context';
import { useLeadForm } from './useLeadForm';

type Props = {
  onSubmit: (data: LeadFormData) => Promise<unknown>;
} & ChildrenProps;

export const LeadFormContainer = ({ children, onSubmit }: Props) => {

  const form = useLeadForm({ onSubmit });

  return (
    <LeadFormContext.Provider value={form}>
      {children}
    </LeadFormContext.Provider>
  );
};

export default LeadFormContainer;