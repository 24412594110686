import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import {
  AccountProfileBioContainer,
  AccountProfileFieldsStepper,
} from '@containers';
import * as AccountProfile from '@screens/AccountProfileSetup';
import type { StepperActions } from '@utils';
import { createNamedContext, useStepper } from '@utils';
import { EnrichmentCheck } from './AccountProfile.EnrichmentCheck';

const AccountProfileStepperContext = createNamedContext<StepperActions | undefined>(undefined, 'AccountProfileStepper.Context');

type Props = unknown;

export const EducationSetup = (props: Props) => {
  const actions = useContext(AccountProfileStepperContext);

  const navigation = {
    back: actions.back,
    onClick: actions.next,
  };

  return (
    <AccountProfile.EducationSetup navigation={navigation} />
  );
};

export const EmploymentSetup = (props: Props) => {
  const actions = useContext(AccountProfileStepperContext);

  const navigation = {
    back: null,
    onClick: actions.next,
  };

  return (
    <AccountProfile.EmploymentSetup navigation={navigation} />
  );
};

export const LaunchSetup = (props: Props) => {
  const actions = useContext(AccountProfileStepperContext);

  return (
    <EnrichmentCheck redirect={actions.next} />
  );
};

export const SummarySetup = (props: Props) => {
  const history = useHistory();

  const navigation = {
    onClick: () => history.push(pathname.HOME),
  };

  return (
    <AccountProfileBioContainer>
      <AccountProfile.SummarySetup navigation={navigation} />
    </AccountProfileBioContainer>
  );
};

export const Stepper = (props: Props) => {
  const [Screen, actions] = useStepper([
    LaunchSetup,
    EmploymentSetup,
    EducationSetup,
    SummarySetup,
  ]);

  return (
    <AccountProfileFieldsStepper>
      <AccountProfileStepperContext.Provider value={actions}>
        <Screen />
      </AccountProfileStepperContext.Provider>
    </AccountProfileFieldsStepper>
  );
};