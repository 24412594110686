import { cx } from '@utils';
import { Focusable } from '@/components/Focusable';
import type { SelectBaseProps } from './interfaces';
import styles from './style.css';

type Props<T> = {
  autoFocus?: boolean;
  className?: string;
  inputValueClassName?: string;
  onBlur: () => unknown;
  onClick: () => unknown;
} & SelectInputValueProps<T>;

const SelectInput = <T, >(props: Props<T>) => {
  const className = cx(styles.input, props.className);

  return (
    <Focusable autoFocus={props.autoFocus}>
      <div
        className={className}
        onBlur={props.onBlur}
        onClick={props.onClick}
        tabIndex={0}>
        <SelectInputValue
          className={props.inputValueClassName}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          value={props.value} />
        <SelectInputIcon />
      </div>
    </Focusable>
  );
};

type SelectInputValueProps<T> = {
  className?: string;
} &
  Pick<SelectBaseProps<T>,
    'defaultValue'
    | 'placeholder'
    | 'value'>;

export const SelectInputValue = <T, >(props: SelectInputValueProps<T>) => {
  const className = cx(styles.text, props.className, {
    [styles.placeholder]: !!props.placeholder && !(props.value || props.defaultValue),
  });

  const text = props.value
    || props.defaultValue
    || props.placeholder;
  return (
    <div className={className}>
      {text}
    </div>
  );
};

export const SelectInputIcon = () => {
  return <div className={styles.icon} />;
};

export { SelectInput };
export default SelectInput;