import { useCallback, useState } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { SurveyFormQuestionAnswerContext, SurveyFormAnswerRationaleContext } from '@containers/SurveyForm/Context';
import type { RespondentAnswer } from '@/types';
import { transformResponse } from './utils';
import EditingQuestionItem from './EditingQuestionItem';
import type { EditingAnswerProps } from './interfaces';
import styles from './style.css';

export const EditingAnswer = ({ item, onCancel, onConfirm }: EditingAnswerProps) => {
  const [answer, setAnswer] = useState<RespondentAnswer>(transformResponse(item));
  const [rationale, setRationale] = useState<string>(item.rationale);

  const handleConfirm = useCallback(() => {
    onConfirm(answer, rationale);
  }, [onConfirm, answer, rationale]);

  return (
    <>
      <div className={styles.root}>
        <SurveyFormQuestionAnswerContext.Provider value={[answer, setAnswer]}>
          <SurveyFormAnswerRationaleContext.Provider value={[rationale, setRationale]}>
            <EditingQuestionItem
              question={item.question} />
          </SurveyFormAnswerRationaleContext.Provider>
        </SurveyFormQuestionAnswerContext.Provider>
      </div>
      <FormButtons
        className={styles.form}
        archivable={false}
        onCancel={onCancel}
        onSubmit={handleConfirm} />
    </>
  );
};

export default EditingAnswer;