import type * as enums from '@enums';
import { Label, MatchTag } from '@presentation';
import { cx, trunc } from '@utils';
import * as utils from '../utils';
import styles from './style.css';

type Props = {
  className?: string;
  items: enums.AdvancedSearchQuery[];
};

const ItemMatchTags = (props: Props) => {
  if (!props.items.length) return null;

  const tags = [...props.items]
  .sort(utils.sortByOrdinal)
  .map(x =>
    <MatchTag
      key={x}
      className={cx(styles.match, utils.getQueryStyle(x))}
      text={trunc(utils.getQueryName(x), 21)} />
  );

  return (
    <div className={styles.matches}>
      <Label
        className={styles.label}
        label="Match">
        {tags}
      </Label>
    </div>
  );
};

export default ItemMatchTags;