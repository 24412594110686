import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { reorderTags } from '@api/projects.conference';
import { indexBy } from '@utils/array';
import { TagStateContext } from '@/components/Project.Tags/Context';
import Toast from '@/components/Toast';

type HookParams = IProjectId;

type MutationParams = {
  ordinalMap: Record<number, number>;
};

export const useReorderTagsMutation = ({ projectId }: HookParams) => {
  const { state, dispatch } = useContext(TagStateContext);

  return useMutation({ mutationKey: ['update-project-tag-ordinals', projectId], mutationFn: ({ ordinalMap }: MutationParams) => {
    return reorderTags({ projectId, ordinalMap });
  }, onMutate: variables => {
    dispatch({
      type: 'update-ordinals',
      ordinals: variables.ordinalMap,
    });

    return {
      oldState: indexBy(state.tags.flatMap(t => t.children).concat(state.tags), k => k.id, v => v.ordinal),
    };
  }, onError: (error, variables, ctx) => {
    //Reset state
    dispatch({
      type: 'update-ordinals',
      ordinals: ctx.oldState,
    });

    Toast.error({
      title: 'Error reordering tags',
    });
  } });
};