import { useContext } from 'react';
import { MinusCircle } from 'react-feather';
import type { FieldArrayRenderProps } from 'formik';
import { useFormikContext } from 'formik';
import { ProjectHonorariaContext } from './Context';
import * as Field from './Field';
import type * as PH from './interfaces';
import styles from './style/Project.Honoraria.css';

type Props = {
  helpers: FieldArrayRenderProps;
  index:   number;
  item:    PH.Form.Item;
};

export const Entry = ({ helpers, index, item }: Props) => {
  const formik = useFormikContext<PH.Form.State>();
  const ctx = useContext(ProjectHonorariaContext);

  return (
    <div
      className={styles.root}
      key={item.id}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.title}>
            <Field.Title
              onChange={e => helpers.replace(index, { ...item, title: e.target.value })}
              value={item.title} />
          </div>
          <div className={styles.currency}>
            <Field.Currency
              onChange={currency => helpers.replace(index, { ...item, currency })}
              options={ctx.currencies}
              value={item.currency} />
          </div>
          <div className={styles.amount}>
            <Field.Amount
              onChange={e => helpers.replace(index, { ...item, value: e.target.value })}
              prefix={''}
              // prefix={item.currency?.sign}
              value={String(item.value)} />
          </div>
          {formik.values.honoraria.length > 1 &&
            <div className={styles.remove}>
              <button
                className={styles.minus}
                onClick={() => {
                  helpers.remove(index);
                  formik.validateForm();
                }}
                type="button">
                <MinusCircle
                  size={22}
                  strokeWidth={2.5} />
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
};

Entry.displayName = 'Project.Honoraria.Row.Entry';