import { useContext } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { SurveyAggregate, SurveyQuestion } from '@/types';
import { SurveyQuestionResponsesContext } from '@containers/SurveyResponses/Context';

const useSurveyQuestionResponsesData = <T extends SurveyQuestionType = SurveyQuestionType>() => {
  const context = useContext(SurveyQuestionResponsesContext);

  return {
    question: context.question as SurveyQuestion<T>,
    rationale: context.rationale,
    responses: context.responses as SurveyAggregate.QuestionData<T>,
    total: context.total,
  };
};

export { useSurveyQuestionResponsesData };
export default useSurveyQuestionResponsesData;