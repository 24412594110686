import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toggleTagVisibility } from '@api/projects.conference';
import type { ConferenceTags } from '@api/interfaces/projects';
import { TagStateContext } from '@/components/Project.Tags/Context';

type HookParams = Omit<ConferenceTags.ToggleTagVisibility.Request, 'visible'>;

type MutationParams = {
  visible: boolean;
};
export const useToggleTagVisiblityMutation = ({ projectId, projectTagId }: HookParams) => {
  const { dispatch } = useContext(TagStateContext);
  return useMutation({ mutationKey: ['toggle-project-tag-visibility', projectTagId], mutationFn: ({ visible }: MutationParams) => {
    return toggleTagVisibility({
      projectId,
      projectTagId,
      visible,
    });
  }, onSuccess: (result, params) => {
    dispatch({
      type: 'update-tag',
      projectTagId,
      partialTag: {
        hidden: !params.visible,
      },
      baseTag: { },
    });
  } });
};