import { cx, formatDisplayAge } from '@utils';
import type { ContentFeed } from '@/types';
import styles from './style/NewsArticle.Header.css';

type Props = {
  className?: string;
  item:       ContentFeed.NewsArticle;
};

export const Header = ({ className, item }: Props) => {
  const thumbnailUrl = `${process.env.CDN_URL}/rss-source-thumbnails/${item.source.thumbnailImageName}`;
  const timestamp = formatDisplayAge(new Date(item.publishDate));

  return (
    <div className={cx(styles.root, className)}>
      <ExternalLink href={item.source.sourceUrl}>
        <img
          className={styles.img}
          src={thumbnailUrl} />
      </ExternalLink>

      <ExternalLink href={item.source.sourceUrl}>
        <span className={styles.name}>
          {item.source.feedName}
        </span>
      </ExternalLink>
      <span className={styles.age}>
        {timestamp}
      </span>
    </div>
  );
};

Header.displayName = 'NewsArticle.Header';

type ExternalLinkProps = {
  children: React.ReactNode;
  href?:    string;
};

const ExternalLink = (props: ExternalLinkProps) => {
  if (!props.href) return props.children as JSX.Element;

  return (
    <a
      href={props.href}
      rel="noreferrer"
      target="_blank">
      {props.children}
    </a>
  );
};

ExternalLink.displayName = 'NewsArticle.Header.ExternalLink';