import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveClient, useTemplateClient } from './hooks/client';
import { TemplateClientInput } from './Input.Client';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateClientModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateClient();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveClient(value);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Client" />

      <div className={styles.body}>
        <TemplateClientInput
          onChange={onChange}
          value={value} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateClientModal = () => useModal(TemplateClientModal);

export default TemplateClientModal;