import { Calendar } from 'react-feather';
import { Link } from 'react-router-dom';
import { useSelectContact, useSelectUser } from '@containers/Store/hooks';
import { cx, getLocationFor } from '@utils';
import type { AdHocCall } from '@/types';
import { Button } from '@/components/Button';
import styles from './style/Invitations.Item.css';

type Props = {
  className?: string;
  item: AdHocCall;
};

export const AdHocCallInvitation = ({ className, item }: Props) => {

  const to = getLocationFor.onboarding.call.details({ callId: item.id });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <Calendar className={styles.icon} />
          <div className={styles.details}>
            <Link to={to} className={styles.title}>
              {item.request.subject}
            </Link>
            <div className={styles.description}>
              A client has selected you to participate on a call.
            </div>
          </div>
        </div>
        <Button.Secondary
          className={styles.btn}
          variant="brick"
          to={to}>
          Review
        </Button.Secondary>
      </div>
    </div>
  );
};

export default AdHocCallInvitation;
