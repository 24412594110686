import { useCallback, useContext, forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ProjectActionsMenuContext } from './Context';
import { Clone, Preview, Publish, Timeline } from './Item';
import type { Item } from './interfaces';

type Props = {
  onPreviewSurvey?: Item.OnClick;
  showPreviewSurvey?: boolean;
  onCloneSurvey?: Item.OnClick;
  showCloneSurvey?: boolean;
  onMonitorSurvey?: Item.OnClick;
  showMonitorSurvey?: boolean;
  onPublish?: Item.OnClick;
  showPublish?: boolean;
};

export const Survey = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { close } = useContext(ProjectActionsMenuContext);

  const closer = useCallback((handler: Item.OnClick) => () => {
    close();
    handler();
  }, [close]);

  return (
    <div ref={ref}>
      {props.showPreviewSurvey && (
        <MenuItem
          disableRipple
          disabled={!props.onPreviewSurvey}
          onClick={closer(props.onPreviewSurvey)}>
          <Preview title="Preview" />
        </MenuItem>
      )}
      {props.showCloneSurvey && (
        <MenuItem
          disableRipple
          disabled={!props.onCloneSurvey}
          onClick={closer(props.onCloneSurvey)}>
          <Clone title="Clone" />
        </MenuItem>
      )}
      {props.showMonitorSurvey && (
        <MenuItem
          disableRipple
          disabled={!props.onMonitorSurvey}
          onClick={closer(props.onMonitorSurvey)}>
          <Timeline title="Monitor" />
        </MenuItem>
      )}
      {props.showPublish && (
        <MenuItem
          disableRipple
          onClick={closer(props.onPublish)}>
          <Publish title="Publish Draft" />
        </MenuItem>
      )}
    </div>
  );
});