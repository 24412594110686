import { useCallback } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { Input } from '@/components/Input';
import { CheckboxLabel } from './CheckboxLabel';
import type { CheckboxGroupProps, CheckboxGroupItem } from './interfaces';
import styles from './style/CheckboxGroup.css';

type Props =
  CheckboxGroupProps;

export default function CheckboxGroup({ className, checkedItems, items, onToggle, onInputChange }: Props) {

  const renderInputLabel = useCallback((item: CheckboxGroupItem, checked: boolean) => {
    if (!item.allowText || !checked) return item.label;

    return (
      <div className={styles.openEnded}>
        <div className={styles.label}>{item.label}</div>
        <Input
          name={`${item.id}-text`}
          onChange={e => onInputChange({ id: item.id, text: e.target.value })}
          placeholder="Enter additional text"
          value={item.text || ''} />
      </div>
    );
  }, [
    onInputChange,
  ]);

  const renderItem = useCallback((item: CheckboxGroupItem, index: number) => {
    const onChange = () => onToggle({ id: item.id });
    const checked = checkedItems.includes(item.id);

    const style: React.CSSProperties = {
      marginBottom: index === items.length - 1
        ? 0
        : 15,
      alignItems: 'flex-start',
    };

    return (
      <CheckboxLabel
        key={item.id}
        checked={checked}
        label={renderInputLabel(item, checked)}
        onChange={onChange}
        disabled={item.disabled}
        style={style} />
    );
  }, [
    checkedItems,
    items.length,
    onToggle,
    renderInputLabel,
  ]);

  return (
    <FormGroup className={className}>
      {items.map(renderItem)}
    </FormGroup>
  );
}

export { CheckboxGroup };