import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api/universal-search';
import type { UniversalSearch } from '@api/interfaces';

type Data = UniversalSearch.Experts.Response;

export function useFetchUniversalSearchPageExperts<TData = Data>(
  query: string,
  size: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {
  return useQuery({ queryKey: keyUseFetchUniversalSearchPageExperts(query), queryFn: () => {
    const source = Axios.CancelToken.source();

    const p = api.experts({
      query,
      size,
      cancelToken: source.token,
    });

    p.cancel = () => {
      source.cancel();
    };

    return p;
  }, staleTime: 60000, ...options });
}

export function keyUseFetchUniversalSearchPageExperts(query: string) {
  return ['universal-search', query, 'experts'];
}