import { memo } from 'react';

type Props = {
  className?: string;
  size?: number;
};

export const SendIcon = memo(({ className, size = 24 }: Props) => {
  return (
    <svg
width="20px"
height="20px"
style={{ marginLeft: '4px' }}
viewBox="0 0 15 15"
version="1.1"
xmlns="http://www.w3.org/2000/svg">
      <g
id="Med-Affairs-Chat"
stroke="none"
strokeWidth="1"
fill="none"
fillRule="evenodd">
        <g
className={className}
id="1"
transform="translate(-722.000000, -571.000000)"
fillRule="nonzero">
          <g id="u158_disabled" transform="translate(722.000000, 571.000000)">
            <path d="M1.30622886,8.73264264 C0.856863395,10.6856906 0.0303349576,14.2770286 0.0218226647,14.3154327 C-0.104531683,14.8818188 0.341261655,15.1215849 0.754905887,14.9406853 L14.7350169,7.94795376 L14.7364229,7.94795376 C14.9031727,7.85620655 15,7.69087622 15,7.50015322 C15,7.30931902 14.9031727,7.14387748 14.7364229,7.05216734 L14.7350169,7.05216734 L0.754905887,0.0593245887 C0.341261655,-0.121612034 -0.104531683,0.118228152 0.0218226647,0.684651344 C0.0303729589,0.72301836 0.856863395,4.31428226 1.30622886,6.26744139 L8.60407594,7.50011615 L1.30622886,8.73264264 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </svg>
  );
});