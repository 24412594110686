import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { path } from '@consts';
import { AnalyticsEvent } from '@enums';
import { cx } from '@utils';
import { useTrackEvent } from '@utils/analytics';
import type { Topic } from '@/types/topics';
import { Link } from '@/components/Tag/Link';
import styles from './style/Topics.css';

type Props = {
  className?: string;
  items: Pick<Topic, 'id' | 'name' | 'slug'>[];
  onClick: (topicId: number) => () => unknown;
};

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

const Topics = memo(({ className, items, onClick }: Props) => {
  const authenticated = useSelector(selectAuthenticated);

  return (
    <div className={cx(styles.tags, className)}>
      {items.map(x =>
        <Link
          className={styles.tag}
          key={x.id}
          onClick={onClick(x.id)}
          to={!authenticated ? null : getLocation(x.slug)}>
          {x.name}
        </Link>)}
    </div>
  );
});

export const PostTopics = ({ postId, ...props }: Omit<Props, 'onClick'> & IPostId) => {

  const trackEvent = useTrackEvent(AnalyticsEvent.ViewPostTopic);
  const track = useCallback((topicId: number) => () => {
    trackEvent({
      postId,
      topicId,
    });
  }, [
    postId,
    trackEvent,
  ]);

  return (
    <Topics
      {...props}
      onClick={track} />
  );
};

export const ArticleTopics = ({ articleId, ...props }: Omit<Props, 'onClick'> & IArticleId) => {

  const trackEvent = useTrackEvent(AnalyticsEvent.ViewArticleTopic);
  const track = useCallback((topicId: number) => () => {
    trackEvent({
      articleId,
      topicId,
    });
  }, [
    articleId,
    trackEvent,
  ]);

  return (
    <Topics
      {...props}
      onClick={track} />
  );
};

function getLocation(slug: string) {
  return slug
    ? `${path.Topics.Root}/${slug}`
    : null;
}

export default PostTopics;