import { useCallback, useContext, useEffect, useMemo } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { useVideoPostUploaderContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { PostCreationSubscriptionContext, VideoUploaderPublishContext } from '@containers/PostCreation/Context';
import type { Posts } from '@/types';
import * as PC from './interfaces';

type Props = {
  children:          React.ReactNode;
  onError?:          (e: Error) => unknown;
  onPublished?:      PC.Subscription.OnPublished.Fn;
  onTextSubmit?:     PC.Subscription.OnTextSubmit.Fn<PC.PostContentType.Text>;
  onVideoSubmit?:    PC.Subscription.OnVideoSubmit.Fn;
};

export const Subscription = ({ children, ...props }: Props) => {
  const submitText = useTextPostSubmit({
    onPublished: props.onPublished,
    onError: props.onError,
    onSubmit: props.onTextSubmit,
  });
  const submitVideo = useVideoPostSubmit({
    onPublished: props.onPublished,
    onError: props.onError,
    onSubmit: props.onVideoSubmit,
  });

  const context = {
    text: {
      submit: submitText,
    },
    video: {
      submit: submitVideo,
    },
  };

  return (
    <PostCreationSubscriptionContext.Provider value={context}>
      {children}
    </PostCreationSubscriptionContext.Provider>
  );
};

Subscription.displayName = 'PostCreation.Subscription';

type TextPostParams = {
  onError?:     (e: Error) => unknown;
  onPublished?: PC.Subscription.OnPublished.Fn<PC.PostContentType.Text>;
  onSubmit?:    PC.Subscription.OnTextSubmit.Fn<PC.PostContentType.Text>;
};

const useTextPostSubmit = ({ onError, onPublished, onSubmit }: TextPostParams) => {
  const submit = (data: API.Posts.PublishPost.Request) => {
    const params = onSubmit?.({
      post: data,
      type: PC.PostContentType.Text,
    }) || data;

    return api.posts.publishPost(params)
    .then(item => {
      onPublished?.({
        post: item,
        type: PC.PostContentType.Text,
      });

      return item;
    })
    .catch(e => onError?.(e)) as Promise<Posts.Post>;
  };

  return useCallback(submit, [
    onError,
    onPublished,
    onSubmit,
  ]);
};

type VideoPostParams = {
  onError?:     (e: Error) => unknown;
  onPublished?: PC.Subscription.OnPublished.Fn<PC.PostContentType.Video>;
  onSubmit?:    PC.Subscription.OnVideoSubmit.Fn;
};

type Params =
  & Pick<PC.Video.Uploader.HandleSubmit.Params, 'topics'>
  & PC.Video.Uploader.HandleSubmit.Params['post'];

const useVideoPostSubmit = ({ onError, onPublished, onSubmit }: VideoPostParams) => {
  const [state] = useVideoPostUploaderContext();
  const publish = useContext(VideoUploaderPublishContext);
  const [_, dispatch] = useVideoPostUploaderContext();

  const submit = (data: Params) => {
    const { topics, ...post } = onSubmit?.({
      post: data,
      type: PC.PostContentType.Video,
    }) || data;

    publish({
      post,
      topics,
    });
  };

  const handleVideoPublished = useCallback(async (items: Posts.Post[]) => {
    /**
     * Invoke callback
     */
    for await (const item of items) {

      const response = await api.feed.fetchPost({
        postId: item.id,
      });

      const post = response?.post
          ? response.post
          : item;

      onPublished?.({
        post,
        type: PC.PostContentType.Video,
      });
    }

    /**
     * Clear uploaded videos from uploader state
     */
    dispatch({
      postIds: items.map(x => x.id),
      type: `posting/rendered`,
    });
  }, [
    dispatch,
    onPublished,
  ]);

  const posts = useMemo(() => {

    return state.posts;

  }, [state.posts]);

  useEffect(() => {

    if (!posts.length) return;

    handleVideoPublished(posts);

  }, [
    handleVideoPublished,
    posts,
  ]);

  return useCallback(submit, [
    onSubmit,
    publish,
  ]);
};