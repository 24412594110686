import { useMemo } from 'react';
import type { Cell, CellProps, Column, Row, TableCellProps, TableHeaderProps, UseTableCellProps, UseTableColumnProps } from 'react-table';
import { useFlexLayout, useTable } from 'react-table';
import { cx } from '@utils';
import { Table } from '$admin/components/Table';
import { CSVFieldMappingSelect } from './CSVFieldMapping.Select';
import styles from './style/CSVFieldMapping.Table.css';

type Props = {
  items: TableRow[];
  unknownColumns: `row${number}`[];
};

export const CSVFieldMappingTable = (props: Props) => {
  const columns = useMemo<Column<TableRow>[]>(() => {
    const dynamicColumns = props.unknownColumns.map((col, i) => ({
      Header: `Row ${i + 1}`,
      Cell: (data: CellProps<TableRow>) => {
        const value = data.row.original[col];

        return (
          <div
            className={styles.trunc}
            title={value}>
            {value}
          </div>
        );
      },
      id: col,
    }));

    return [{
      Header: `Column Name`,
      Cell: (data: CellProps<TableRow>) => {
        const value = data.row.original.name;

        return (
          <div
            className={cx(styles.trunc, styles.name)}
            title={value}>
            {value}
          </div>
        );
      },
      id: `name`,
    }, {
      Header: `Mapped Field`,
      Cell: (data: CellProps<TableRow>) => {
        return (
          <CSVFieldMappingSelect column={data.row.original.name} />
        );
      },
      id: `field`,
      width: 170,
    }].concat(dynamicColumns);
  }, [props.unknownColumns]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<TableRow>({
    columns,
    data: props.items,
  }, useFlexLayout);

  return (
    <div
      {...getTableProps()}
      className={styles.root}>
      <div className={styles.wrap}>
        {headerGroups.map((headerGroup, i) => (
          <Table.Header
            key={i}
            {...headerGroup.getHeaderGroupProps({ className: styles.th })}>
            {headerGroup.headers.map((column, i) =>
              <div
                key={column.id}
                {...column.getHeaderProps(headerProps)}>
                {column.render('Header')}
              </div>)}
          </Table.Header>))}
      </div>

      <div
        {...getTableBodyProps()}
        className={styles.body}>
        {rows.map((row: Row<TableRow>) => {

          prepareRow(row);

          return (
            <div
              key={row.original.name}
              {...row.getRowProps({ className: styles.row })}>
              {row.cells.map((cell: Cell<TableRow>, i) =>
                <div
                  key={i}
                  {...cell.getCellProps(cellProps)}>
                  {cell.render('Cell')}
                </div>)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

type CellFn = Parameters<UseTableCellProps<TableRow>['getCellProps']>[number];
type HeaderFn = Parameters<UseTableColumnProps<TableRow>['getHeaderProps']>[number];

const cellProps: CellFn = (props, { cell }) => {
  const classnames = cx(props.className, {
    [styles.field]: cell.column.id === 'field',
    [styles.cell]: true,
  });

  return getStyles({
    ...props,
    className: classnames,
  });
};

const headerProps: HeaderFn = (props, { column }) => {
  const classnames = cx(props.className, {
    [styles.field]: column.id === 'field',
    [styles.header]: true,
  });

  return getStyles({
    ...props,
    className: classnames,
  });
};

const getStyles = (props: Partial<TableCellProps> | Partial<TableHeaderProps>, align = 'center') => {
  return [
    props,
    {
      style: {
        justifyContent: 'flex-start',
        alignItems: align,
        display: 'flex',
      },
    },
  ];
};

CSVFieldMappingTable.displayName = 'CSVFieldMappingTable';

type TableRow = {
  name: string;
  field: string;
  [row: `row${number}`]: string;
};