import { isAfter, isBefore } from 'date-fns';
import type * as enums from '@enums';
import * as utils from '@utils';
import type { Project } from '@/types';
import { WorkspaceObjectCapabilities } from '@/types';
import {
  assertProjectRequestAccepted,
  createSelectorIsProjectActive,
} from './helpers';

export const selectActiveProjects = (state: Store.State): Project[] => {
  const isProjectActive = createSelectorIsProjectActive(state);

  return state.projects.ids.reduce((acc, id) => {
    const project = state.projects[id];
    return isProjectActive(project)
         ? acc.concat(project)
         : acc;
  }, []);
};

export const selectActiveManagedProjects = (state: Store.State): Project[] => {
  return [];

  // const activeProjects = selectActiveProjects(state);

  // return utils.hasClientRole(state.user)
  //   ? activeProjects.filter(canManage)
  //   : activeProjects;

  // function canManage(data: Project) {
  //   const objectId = state.projects.parents[data.parentId].objectId;
  //   const caps =  state.objects.capabilities[objectId] as WorkspaceObjectCapabilities<enums.WorkspaceObjectType.ProjectParent>;
  //   return caps.canManage;
  // }
};

export const selectProject = (projectId: number) =>
  (state: Store.State) => state.projects[projectId];

export const selectProjectParent = (projectParentId: number) =>
  (state: Store.State) => state.projects.parents[projectParentId];

export const selectProjects = (state: Store.State) => {
  return state.projects.ids.map(id => state.projects[id]);
};

export const selectProjectValues = (state: Store.State) => {
  const { parents, ids, ...values } = state.projects;
  return values;
};
export const selectProjectParentValues = (state: Store.State) => {
  const { ids, ...values } = state.projects.parents;
  return values;
};

export const selectProjectParentIds = (state: Store.State) => state.projects.parents.ids;

export const selectProjectParents = (state: Store.State) => {
  return state.projects.parents.ids.map(id => state.projects.parents[id]);
};

export const selectProjectsForParent = (projectParentId: number) => (state: Store.State) => {
  return selectProjects(state)
    .filter(f => f.parentId === projectParentId)
    .sort((a, b) => a.id - b.id);
};

export const selectProjectsSortedByName = (state: Store.State) => {
  const { ids, parents, ...projects } = state.projects;

  return Object.values(projects)
  .sort((a: Project, b: Project) => a.name.localeCompare(b.name));
};

export const selectRecentProjects = (state: Store.State, limit = 5) => {
  const acceptedProjectActiviy = state.projects.ids
    .filter(id => assertProjectRequestAccepted(state.pipeline.me[id]))
    .map(id => ({
      activity: getDateOfMostRecentActivity(id),
      id,
    }));

  const a = [...acceptedProjectActiviy]
    .sort((a, b) => isAfter(b.activity, a.activity) ? 1 : -1)
    .slice(0, limit)
    .map(p => state.projects[p.id]);

  return a;

  function getDateOfMostRecentActivity(projectId: number) {
    const acceptedOn = state.pipeline.me[projectId].acceptedOn;
    const mostRecentCallActivity = state.calls.ids
      .filter(id => state.calls[id].projectId === projectId)
      .reduce((acc, callId) => {
        const start = state.calls[callId].timeStart;
        return start && (!acc || isAfter(acc, start)) ?
          start :
          acc;
      }, null as Date);

    return mostRecentCallActivity && isAfter(mostRecentCallActivity, acceptedOn)
      ? mostRecentCallActivity
      : acceptedOn;
  }
};

export const selectProjectParentsByStatus = (statusIds: enums.ProjectStatus[]) => (state: Store.State) => {
  return selectProjectParents(state)
    .filter(f => statusIds.includes(state.projects[f.latestChild].projectStatusId))
    .sort((a, b) => isBefore(new Date(state.projects[a.latestChild].createdOn), new Date(state.projects[b.latestChild].createdOn)) ? 1 : -1);
};