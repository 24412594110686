import { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import * as api from '$website/api';
import { ContactInfo } from './ContactInfo';
import { Form } from './Form';
import { FormSubmitted } from './FormSubmitted';
import type { FormData } from './interfaces';
import styles from './style.css';

const H = memo(() => {
  const description = ``;

  return (
    <Helmet
      title="Contact Us">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Contact}`} />
      {/* <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} /> */}
    </Helmet>
  );
});

const Contact = props => {
  const [isSubmitted, setSubmitted] = useState(false);

  function handleSubmit(data: FormData) {
    return api.visitors.contact(data)
    .then(() => setSubmitted(true));
  }

  return (
    <div className={styles.root}>
      <H />
      <div className={styles.main}>
        {!isSubmitted
          ? <Form onSubmit={handleSubmit} />
          : <FormSubmitted />}
        <ContactInfo />
      </div>
    </div>
  );
};

export { Contact };
export default Contact;