import type { OptionQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import type { SurveyQuestion, SurveyQuestionOption } from '@/types';
import { QuestionOption } from './Option';
import styles from './style/Options.css';

type Props = {
  item: SurveyQuestion<OptionQuestionType>;
  renderOptionExtras?: (option: SurveyQuestionOption) => React.ReactNode;
};

export const Options = ({ item, renderOptionExtras }: Props) => (
  <>
    {item.options
      .filter(f => f.type !== SurveyOptionType.NotApplicable)
      .map(option => (
        <QuestionOption
          key={option.base.identifier}
          className={styles.option}
          item={option}>
          {renderOptionExtras?.(option)}
        </QuestionOption>
      ))
    }
  </>
);

export default Options;