import { useCallback } from 'react';
import { GrayOutlineButton } from '@presentation/Buttons';
import { useSubmitSurveyDraft } from '@/containers/SurveyBuilder';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { useCanSaveDrug, useTemplateDrug } from './hooks/drug';
import { TemplateDrugInput } from './Input.Drug';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const TemplateDrugModal = ({
  onClose,
  open,
}: Props) => {

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateDrug();

  const submitDraft = useSubmitSurveyDraft();

  const handleSubmit = useCallback(() => {

    onSubmit();
    submitDraft();
    onClose();
  }, [
    onSubmit,
    onClose,
    submitDraft,
  ]);

  const canSubmit = useCanSaveDrug(value);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Update Target Drug" />

      <div className={styles.body}>
        <TemplateDrugInput
          onChange={onChange}
          value={value} />
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>
    </Modal>
  );
};

export const useTemplateDrugModal = () => useModal(TemplateDrugModal);

export default TemplateDrugModal;