import * as api from '@api';
import type * as API from '@api/interfaces';
import * as consts from '@consts';
import * as survey from '@containers/Survey/utils';
import { BackLink } from '@presentation/Back';
import { HideAnswerButton, ShowAnswerButton } from '@presentation';
import type { ProjectSurveyDetailsInternalUser, SurveyResponseItem } from '@/types';
import { CompletedProjectSurveyReviewInternalUser } from '@/scenes/surveys/CompletedSurvey/ProjectReview';
import { ComplianceScreener } from '@/scenes/surveys/CompletedSurvey';
import { ApprovalButtonInternalUser, ProjectSurveyResponseSection } from '@/scenes/surveys/CompletedSurvey/ProjectReview';
import styles from './style.css';

type Props = {
  item:     ProjectSurveyDetailsInternalUser;
  onUpdate: (item: ProjectSurveyDetailsInternalUser) => unknown;
};

const ProjectSurveyApprovalReview = (props: Props) => {

  const updateResponse = (data: {
    questionId: number;
    hidden: boolean;
  }) => {
    const newItems = props.item.survey.items.reduce<SurveyResponseItem[]>((acc, x) => {
      if (x.question.id === data.questionId) {
        acc.push({
          ...x,
          hidden: data.hidden,
        });
      } else {
        acc.push(x);
      }
      return acc;
    }, []);

    props.onUpdate({
      ...props.item,
      survey: {
        ...props.item.survey,
        items: newItems,
      },
    });
  };

  const hideAnswer = (data: Omit<API.Surveys.ToggleAnswerVisibility.Request, 'visible'>) => () => {
    api.surveys.responses.toggleAnswerVisibility({
      ...data,
      visible: false,
    })
    .then(() => updateResponse({
      hidden: true,
      questionId: data.questionId,
    }));
  };

  const showAnswer = (data: Omit<API.Surveys.ToggleAnswerVisibility.Request, 'visible'>) => () => {
    api.surveys.responses.toggleAnswerVisibility({
      ...data,
      visible: true,
    })
    .then(() => updateResponse({
      hidden: false,
      questionId: data.questionId,
    }));
  };

  const renderQuestionButton = (item: SurveyResponseItem) => {
    if (!survey.questions.isTextResponse(item.question)) {
      return null;
    }

    return survey.response.isAnswerHidden(item) ?
      (
        <ShowAnswerButton
          onClick={showAnswer({
            questionId: item.question.id,
            responseId: props.item.survey.id,
          })} />
      ) : (
        <HideAnswerButton
          onClick={hideAnswer({
            questionId: item.question.id,
            responseId: props.item.survey.id,
          })} />
      )
    ;
  };

  const renderApprovalButtons = () => (
    <ApprovalButtonInternalUser
      project={props.item.project}
      user={props.item.user} />
  );

  const renderSurveyBody = () => (
    <>
      {props.item.complianceSurvey
        && <ComplianceScreener survey={props.item.complianceSurvey} />}
      <ProjectSurveyResponseSection
        survey={props.item.survey}
        renderQuestionButton={renderQuestionButton} />
    </>
  );

  const renderBody = () => {
    if (!props.item) return null;

    return (
      <CompletedProjectSurveyReviewInternalUser
        data={props.item}
        renderBody={renderSurveyBody}
        renderButtons={renderApprovalButtons} />
    );
  };

  return (
    <div className={styles.root}>
      <BackLink
        className={styles.back}
        to={consts.pathname.HOME} />
      <div className={styles.body}>
        {renderBody()}
      </div>
    </div>
  );
};

export { ProjectSurveyApprovalReview };
export default ProjectSurveyApprovalReview;