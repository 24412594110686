import { forwardRef, useCallback } from 'react';
import { VICS as VICSEnum } from '@enums/VICS';
import type { VICS } from '@/types/vics';
import { useProjectFormErrorContext } from './context';
import type { AdditionalFilter, ProjectFiltersContainerProps } from './interfaces';
import { ProjectFilters } from './Filters';

type Props = {
  children?: React.ReactNode;
} & ProjectFiltersContainerProps;

export const ProjectFiltersContainer = forwardRef<HTMLInputElement, Props>(({ form, ...props }, ref) => {
  const setFormState = props.onChange;

  const {
    clearErrors,
    setIndustryError,
    setSectorError,
    setSubIndustryError,
  } = useProjectFormErrorContext();

  const validateVICSFilter = useCallback((item: VICS) => {
    const { typeId } = item;
    const hasReachedLimit = filters.limit[typeId] === form.vics.filter(x => x.typeId === typeId).length;

    if (!hasReachedLimit) return true;

    switch (typeId) {
      case VICSEnum.Sector:
        setSectorError(`You may only select up to ${filters.limit[typeId]}`);
        return false;

      case VICSEnum.Industry:
        setIndustryError(`You may only select up to ${filters.limit[typeId]}`);
        return false;

      case VICSEnum.SubIndustry:
        setSubIndustryError(`You may only select up to ${filters.limit[typeId]}`);
        return false;
    }
  }, [
    form.vics,
    setIndustryError,
    setSectorError,
    setSubIndustryError,
  ]);

  const handleVICSItemAddedToFilter = useCallback((item: VICS) => {
    if (validateVICSFilter(item)) {
      clearErrors();

      setFormState({
        suggested: form.suggested.filter(x => x.id !== item.id),
        vics: form.vics.concat(item),
      });
    }
  }, [
    clearErrors,
    form.suggested,
    form.vics,
    setFormState,
    validateVICSFilter,
  ]);

  const handleAddFilter = useCallback((field: AdditionalFilter) => {
    switch (field) {
      case 'suggested':
      case 'vics':
        return handleVICSItemAddedToFilter;

      case 'roles':
        return (item: Descriptor) => {
          setFormState({
            roles: form.roles.concat(item),
          });
        };
      case 'titles':
        return (item: Descriptor) => {
          setFormState({
            titles: form.titles.concat(item),
          });
        };
    }
  }, [
    form.roles,
    form.titles,
    handleVICSItemAddedToFilter,
    setFormState,
  ]);

  const handleAdditionalFilterRemoved = useCallback((field: AdditionalFilter) => (item: FilterItem) => {
    setFormState({
      [field]: form[field].filter(f => f.id !== item.id),
    });
  }, [
    form,
    setFormState,
  ]);

  const getProjectFilterHandler = useCallback((field: AdditionalFilter) => {
    return {
      onAdd: handleAddFilter(field),
      onRemove: handleAdditionalFilterRemoved(field),
    };
  }, [
    handleAddFilter,
    handleAdditionalFilterRemoved,
  ]);

  return (
    <ProjectFilters
      ref={ref}
      filters={filters}
      form={form}
      getFilterHandler={getProjectFilterHandler}>
      {props.children}
    </ProjectFilters>
  );
});

const filters = {
  limit: {
    [VICSEnum.Industry]: 3,
    [VICSEnum.Sector]: 2,
    [VICSEnum.SubIndustry]: 5,
  },
};

type FilterItem = {
  id: number;
};