import { Button } from '@/components/Button';
import { useSignupModal } from '$website/components';
import styles from './style/Funnel.css';

type Props = unknown;

const Funnel = (props: Props) => {
  const [_, setSignupModalOpen] = useSignupModal();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>Sign up now and get paid!</div>
        <Button
          className={styles.btn}
          onClick={() => setSignupModalOpen(true)}
          variant="brick">
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export { Funnel };
export default Funnel;