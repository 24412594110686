import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import type { LocationDescriptor } from 'history';
import styles from './style/Menu.css';

type Props = {
  to: LocationDescriptor;
  text: string;
};

export const ActionLink = forwardRef<HTMLLIElement, Props>(({ to, text }, ref) => {
  return (
    <MenuItem
      ref={ref}
      className={styles.menuItem}
      onClick={() => { }}>
      <Link to={to}>{text}</Link>
    </MenuItem>
  );
});