import { useContext } from 'react';
import * as api from '@api';
import { PrivacyConsentContext } from '@containers/ConsentManagement/Context';
import { useAsyncStateLazy } from '@utils';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { Button } from '@/components/Button';
import { Dialog } from '@/components/Modal/Dialog';
import { GDPR } from '$website/components';
import styles from './style/GDPRPrivacyNotice.css';

type Props = unknown;

const GDPRPrivacyNotice = (props: Props) => {
  const [consentId, onComplete] = useContext(PrivacyConsentContext);

  const [response, submitConsent] = useAsyncStateLazy(async () => {
    if (response.loading) return;

    return api.users.consent.completePrivacyConsentRequest({
      consentId,
      value: true,
    })
    .finally(onComplete);
  }, [consentId]);

  return (
    <Dialog
      className={styles.modal}
      open={!!consentId}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>

            <div className={styles.title}>
              <span className={styles.br}>Sentiment GDPR Privacy Notice</span>
              <span className={styles.br}>(for EU persons)</span>
            </div>

            <div className={styles.content}>
              <div className={styles.scroll}>
                <GDPR.PrivacyNoticeHeader />
                <GDPR.PrivacyNotice />
              </div>

              <SubmitButton onClick={submitConsent}>
                {response.loading
                  ? <AnimatedLoader color="var(--pri-01)" />
                  : `I Agree`}
              </SubmitButton>

            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

type SubmitButtonProps = {
  children: React.ReactNode;
  onClick: () => unknown;
};

const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <div className={styles.confirm}>
      <Button
        className={styles.btn}
        onClick={props.onClick}
        variant="brick">
        {props.children}
      </Button>
    </div>
  );
};

export { GDPRPrivacyNotice };
export default GDPRPrivacyNotice;