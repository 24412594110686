import { useCallback } from 'react';
import cuid from 'cuid';
import { SurveyConditionType, SurveyQuestionConditionType } from '@enums/Survey';
import type {
  SurveyTemplateQuestion } from '@enums/survey.template';
import {
  MessageTesting,
  SurveyTemplateType,
  SurveyTemplateEntityType,
} from '@enums/survey.template';
import type {
  SurveyTemplate,
  SurveyBuilderVersion,
  SurveyLogic,
} from '@/types/survey';
import type { SurveyBuilder } from '@containers/SurveyBuilder/interfaces';
import * as $templateUtils from '@containers/SurveyBuilder/state/state.template.utils';
import * as $template from '@containers/SurveyBuilder/template/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { questionAdded } from '@/containers/SurveyBuilder/state/state.questions';

export const useSubmitTemplateMedicalSpecialties = () => {
  const [state, dispatch] = useSurveyBuilderState();

  return useCallback((value: SurveyTemplate.MessageTesting.Specialties) => {

    const actions = computeActions({
      saved: state.survey,
      value,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type: 'batch-actions',
    });
  }, [
    dispatch,
    state.survey,
  ]);
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  value: SurveyTemplate.MessageTesting.Specialties;
};

function computeActions({ saved, value }: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const actions: SurveyBuilder.NonBatchActions[] = [];

  actions.push({
    type: 'template-specialties-updated',
    payload: { value },
  });

  const question = saved.questions.find(f => f.metadata.template.key === MessageTesting.Question.PrimarySpecialty);

  if (!questionAdded) return actions;

  const oldValues = (saved.template.data as SurveyTemplate.MessageTesting.Data).specialties;

  const {
    added: addedEnabled,
    removed: removedEnabled,
    updated: updatedEnabled,
  } = $templateUtils.computeChangedValues({
    oldValues: oldValues.enabled,
    values: value.enabled,
  });
  const {
    added: addedDisabled,
    removed: removedDisabled,
    updated: updatedDisabled,
  } = $templateUtils.computeChangedValues({
    oldValues: oldValues.disabled,
    values: value.disabled,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    MessageTesting.Question.PrimarySpecialty,
  ];

  const removedDisabledOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.MedicalSpecialty,
    questions: saved.questions,
    removedItems: removedDisabled,
  });

  const removedEnabledOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.MedicalSpecialty,
    questions: saved.questions,
    removedItems: removedEnabled,
  });

  const addedDisabledOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateMedicalSpecialtyOption,
    addedItems: addedDisabled,
    questions: saved.questions,
    toCheck: optionQuestions,
  });

  const addedEnabledOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateMedicalSpecialtyOption,
    addedItems: addedEnabled,
    questions: saved.questions,
    toCheck: optionQuestions,
  });

  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: saved.questions,
    toCheck: optionQuestions,
    updatedItems: [...updatedEnabled, ...updatedDisabled],
  });

  const logicItem = saved.logic.find(f => f.metadata.template?.key === MessageTesting.Logic.PrimarySpecialty);

  if (logicItem && question) {
    const addedConditions = addedDisabledOptions.reduce<SurveyLogic.ItemCondition<SurveyConditionType.Question>[]>((acc, x) => {
      const newConditions: SurveyLogic.ItemCondition<SurveyConditionType.Question>[] = x.options.map(option => {
        return {
          conditionType: SurveyConditionType.Question,
          id: null,
          identifier: cuid(),
          data: {
            type: SurveyQuestionConditionType.OptionSelected,
            question: { identifier: question.base.identifier },
            value: {
              option: { identifier: option.base.identifier },
            },
          },
        };
      });
      return acc.concat(newConditions);
    }, []);

    const removedOptionIdentifiers = removedDisabledOptions.map(m => m.option.identifier);

    const updatedConditions = logicItem.conditions
      .filter(f => !removedOptionIdentifiers.includes((f.data as SurveyLogic.OptionSelectedCondition).value.option.identifier))
      .concat(addedConditions);

    const newLogicItem: SurveyLogic.Item = {
      ...logicItem,
      conditions: updatedConditions,
    };

    actions.push({
      type: 'logic-item-saved',
      payload: {
        value: newLogicItem,
      },
    });
  }

  [...removedDisabledOptions, ...removedEnabledOptions]
    .forEach(f => {
      actions.push({
        type: 'remove-question-option',
        ...f,
      });
    });

  [...addedEnabledOptions, ...addedDisabledOptions]
    .forEach(f => {
      actions.push({
        type: 'add-question-options',
        ...f,
      });
    });

  updatedOptions.forEach(f => {
    actions.push({
      type: 'update-question-option-value',
      ...f,
    });
  });

  return actions;
}