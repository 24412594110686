import { useSelector } from 'react-redux';
import { hasConsultantRole } from '@utils';
import type { CalendarPickerHeaderParams } from '@/components/CalendarPicker';
import { CalendarPicker, CalendarPickerHeader } from '@/components/CalendarPicker';
import { ExternalCalendarSelector } from '@/components/Calendar/ExternalCalendarSelector';
import CalendarButton from '@/components/Calendar/Button';
import type { CalendarMainProps } from '@/components/Calendar/interfaces';
import styles from '@/components/Calendar/style/Providing.css';
import { useReapplySubmitButtonDisabledState } from '@/components/Calendar/hooks/useReapplySubmitButtonDisabledState';
import { getAvailableEvents } from '@/components/Calendar/utils';
import { TimeZone } from './TimeZone';

const mapState = (state: Store.State) => ({
  user: state.user,
});

type Props =
  CalendarMainProps;

const ProvidingMain = (props: Props) => {
  const { user } = useSelector(mapState);
  const events = getAvailableEvents(props.events);
  const dates = events.map(x => x.start);

  useReapplySubmitButtonDisabledState({
    disabled: !events.length,
    fcContainerId: props.id,
  });

  function SubmitButton() {
    if (!hasConsultantRole(user)) return null;

    return (
      <CalendarButton
        className={styles.button}
        disabled={!events.length}
        onClick={props.onSubmit}>
        Send
      </CalendarButton>
    );
  }

  function renderCustomHeader(params: CalendarPickerHeaderParams) {
    return (
      <CalendarPickerHeader
        className={styles.picker}
        {...params} />
    );
  }

  return (
    <div
      className={styles.root}
      onClick={props.onClickContainer}>
      <div className={styles.header}>
        <div className={styles.title}>Provide Your Availability</div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.sidebar}>
          <div className={styles.gutter}>
            <CalendarPicker
              marked={dates}
              onChange={props.gotoDate}
              renderCustomHeader={renderCustomHeader} />

            <ExternalCalendarSelector
              calendar={props.calendar}
              onChange={props.onChangeExternalCalendar}
              user={user} />
          </div>
        </div>
        <div className={styles.main}>
          <div
            id={props.id}
            className={styles.calendar} />
          <TimeZone className={styles.tz} />
        </div>
        <SubmitButton />
      </div>
    </div>
  );
};

export { ProvidingMain };
export default ProvidingMain;