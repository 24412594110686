import type { ComponentType } from 'react';
import { memo } from 'react';
import { SurveyTemplateType } from '@/enums';
import { cx } from '@utils';
import {
  CompetitiveIntelligenceCard,
  CustomSurveyCard,
  EmployeeSatisfactionCard,
  NetPromoterScoreCard,
  PersonaResearchCard,
  PricingStrategyCard,
  MarketingCampaignEffectivenessCard,
  MarketAnalysisCard,
  MessageTestingCard,
  ExternalSurveyCard,
  TargetProductProfileCard,
} from './Card';
import styles from './style/Templates.css';

type Props = {
  className?: string;
  onClick: (template: SurveyTemplateType) => () => void;
  templates: SurveyTemplateType[];
};

export const SurveyTemplates = memo(({ className, onClick, templates }: Props) => {

  return (
    <div className={cx(styles.items, className)}>
      {templates.map(template => {
        const CardComponent = componentMap[template];
        return (
          <CardComponent
            key={template}
            onClick={onClick(template)} />
        );
      })}
    </div>
  );
});

const componentMap: ComponentMap = {
  [SurveyTemplateType.None]: CustomSurveyCard,
  [SurveyTemplateType.TargetProductProfile]: TargetProductProfileCard,
  [SurveyTemplateType.CompetitiveIntelligence]: CompetitiveIntelligenceCard,
  [SurveyTemplateType.PersonaResearch]: PersonaResearchCard,
  [SurveyTemplateType.NetPromoterScore]: NetPromoterScoreCard,
  [SurveyTemplateType.EmployeeSatisfaction]: EmployeeSatisfactionCard,
  [SurveyTemplateType.MarketingCampaignEffectiveness]: MarketingCampaignEffectivenessCard,
  [SurveyTemplateType.PricingStrategy]: PricingStrategyCard,
  [SurveyTemplateType.MarketAnalysis]: MarketAnalysisCard,
  [SurveyTemplateType.ExternalSurvey]: ExternalSurveyCard,
  [SurveyTemplateType.MessageTesting]: MessageTestingCard,
};

type CompProps = { onClick: () => void };

type ComponentMap = Record<SurveyTemplateType, ComponentType<CompProps>>;