import { useCallback, useContext, useEffect, useState } from 'react';
import { CallRole } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { AnimatedLoader } from '@/components/ActivityIndicator';
import { Checkbox } from '@/components/Checkbox';
import * as utils from '@/components/Conference.Participants/utils';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { FrameContext, ParamsContext, ParticipantsContext } from './Context';
import { Autosuggest } from './ModeratorSchedulers';
import type { ModeratorOption, ParticipantItem } from './interfaces';
import { FrameKey } from './interfaces';
import styles from './style/ModeratorSchedulers.css';

type Props = unknown;

export const ModeratorSchedulers = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    participants: useContext(ParticipantsContext),
    scheduling: useContext(ParamsContext),
  };

  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState<ParticipantItem[]>(null);
  const [schedulers, setSchedulers] = useState(new Set<string>(
    ctx.participants.items.filter(x => x.scheduler).map(x => x.email)
  ));

  const handleAddModeratorOption = useCallback((item: ModeratorOption) => {
    const option = { ...item, roleId: CallRole.Scheduler };

    setOptions(options => [...options, option]);
  }, []);

  const handleChangeScheduler = useCallback((checked: boolean, item: ParticipantItem) => {
    checked
      ? schedulers.add(item.email)
      : schedulers.delete(item.email);

    setSchedulers(new Set([...schedulers]));
  }, [schedulers]);

  const handleConfirmSchedulers = useCallback(() => {
    const added = options
      .filter(option => {
        return schedulers.has(option.email)
            && !ctx.participants.items.some(item => item.email === option.email);
      })
      .map(option => ({ ...option, scheduler: true }));

    const updated = ctx.participants.items
      .filter(item => {
        return item.roleId !== CallRole.Scheduler
            || schedulers.has(item.email);
      })
      .map(item => schedulers.has(item.email)
        ? { ...item, scheduler: true }
        : item);

    const items = [...added, ...updated].sort(utils.sortParticipants);

    ctx.participants.setValue(items);
    ctx.frame.goToFrame({ frame: FrameKey.Booking });
  }, [
    ctx.frame,
    ctx.participants,
    options,
    schedulers,
  ]);

  const setInitialModeratorOptions = useCallback(() => {
    const state = ctx.participants.items.filter(x => x.roleId === CallRole.Scheduler);

    const defaults = ctx.participants.query.data.items.filter(x => {
      return x.roleId === CallRole.Scheduler
          && !state.some(option => option.email === x.email);
    });

    const options = [...state, ...defaults];

    const schedulers = state.filter(x => x.scheduler).map(x => x.email);

    setOptions(options);
    setSchedulers(new Set([...schedulers]));
  }, [
    ctx.participants.items,
    ctx.participants.query,
  ]);

  useEffect(() => {

    if (ctx.participants.query.data?.items && !options) {
      setInitialModeratorOptions();
    }

  }, [
    ctx.participants.query,
    options,
    setInitialModeratorOptions,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.subtitle}>{copy.subtitle}</div>

          <div className={styles.label}>Add Moderator</div>
          <div className={styles.input}>
            <Autosuggest
              keyword={keyword}
              onChange={setKeyword}
              onSelect={handleAddModeratorOption} />
          </div>

          {ctx.participants.query.isLoading &&
            <AnimatedLoader className={styles.loader} />}

          {(options || []).map(x =>
            <Option
              checked={schedulers.has(x.email)}
              key={x.email}
              label={`${x.name} (${x.email})`}
              onChange={checked => handleChangeScheduler(checked, x)} />)}

        </div>

        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={ctx.scheduling.resetContext}>
            Back
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            color="primary"
            disabled={schedulers.size < 1}
            implicitDisable={false}
            onClick={handleConfirmSchedulers}>
            Next
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

ModeratorSchedulers.displayName = 'Frame.ModeratorSchedulers';

const copy = {
  subtitle: `Select which moderator(s) you’d like to schedule the call with.`,
};

type OptionProps = {
  checked:  boolean;
  label:    string;
  onChange: (checked: boolean) => void;
};

const Option = (props: OptionProps) => {
  return (
    <div className={styles.option}>
      <Checkbox
        checked={props.checked}
        onChange={(e, checked) => props.onChange(checked)} />
      <div className={styles.name}>{props.label}</div>
    </div>
  );
};