import { useState } from 'react';
import { useSelector } from 'react-redux';
import type * as enums from '@enums';

const mapState = (state: Store.State) => state.calls;

const useRecordingConsentStatusId = (callId: number) => {
  const calls = useSelector(mapState);
  const [status, setStatus] = useState<enums.ApprovalStatus>(calls[callId]?.recordingConsentStatusId);

  return [ status, setStatus] as const;
};

export { useRecordingConsentStatusId };
export default useRecordingConsentStatusId;