import { format } from 'date-fns';
import { Contact } from '@/types/contacts';
import type { UserProfileProps } from '@/types';

export * from './format.currency';

const SlugReplaceMapping = {
  Source: ['·', '/', '_', ',', ':', ';'],
  // eslint-disable-next-line key-spacing
  Dest: ['-', '-', '-', '-', '-', '-'],
};

export const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map(ch => `${ch.charAt(0).toUpperCase()}${ch.slice(1)}`)
    .join(' ');
};

export const trunc = (text: string, max = Infinity) => {
  if (!text) return '';

  return text.length > max
    ? text.substring(0, max).trimEnd() + '...'
    : text;
};

export const formatTenure = (item: { start: number; end: number }) => {

  function formatDate(date: number) {
    const year = date / 100;
    const month = date % 100;

    return month === 0
      ? year
      : format(new Date(year, month - 1), 'LLL y');
  }

  return item.end
    ? `${formatDate(item.start)} - ${formatDate(item.end)}`
    : `${formatDate(item.start)} - Present`;
};

type Slugify = {
  id?: number | string;
  name: string;
};

const slugRegex = new RegExp(SlugReplaceMapping.Source.join('|'), 'g');

export function slugify({ id, name }: Slugify) {
  const value = name
    ? name.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(slugRegex, r => SlugReplaceMapping.Dest[SlugReplaceMapping.Source.indexOf(r)])
      .replace(/&/g, '-and-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
    : '';

  return [value, id].filter(v => v && v.toString().length).join('-');
}

export const formatTruncatedName = (contact: { profile: Pick<UserProfileProps, 'firstName' | 'lastName'> }) => {
  return [
    contact.profile.firstName,
    `${contact.profile.lastName?.length ? `${contact.profile.lastName[0]}.` : ''}`,
  ].join(' ');
};

//https://stackoverflow.com/a/9462382
export function formatNumber(num: number, digits: number) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' },
    { value: 1E12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }

  const value = (num / si[i].value)
    .toFixed(digits)
    .replace(rx, '$1');

  const symbol = si[i].symbol;

  return `${value}${symbol}`;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function formatFileSize(bytesParam: number, si = false, dp = 1) {
  let bytes = bytesParam;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes.toString() + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}
