import type { SurveyQuota, SurveyBuilderVersion } from '@/types/survey';
import { validateConditions, validateRule } from '../SurveyBuilder.Logic';
import {
  validateAction,
  validateLimit,
  validateName,
} from './validation.shared';

export function validateBasicQuota(item: SurveyQuota.Basic.Record, survey: SurveyBuilderVersion) {

  const action = validateAction(item.actionType);

  const conditions = validateConditions(item.data.items, survey);

  const limit = validateLimit(item.limit);

  const name = validateName(item.name);

  const rule = validateRule(item.data.rule);

  const success = action.success &&
    conditions.success &&
    limit.success &&
    name.success &&
    rule.success;

  return {
    action,
    limit,
    name,
    conditions: conditions.map,
    rule,
    success,
  };
}