import http from '@services/http';
import type { OpenAI } from './interfaces';

export const createOpenAICompletion = async (data: OpenAI.GetOpenAICompletion.Request): Promise<OpenAI.GetOpenAICompletion.Response> => {
  return http.post(`/admin/openai/completions`, data);
};

export const updateOpenAICompletionRating = async ({ completionId, rating }: OpenAI.UpdateOpenAICompletionRating.Request): Promise<OpenAI.UpdateOpenAICompletionRating.Response> => {
  return http.post(`/admin/openai/completions/${completionId}/rating`, { rating });
};

export const updateOpenAICompletionFeedback = async ({ completionId, feedback }: OpenAI.UpdateOpenAICompletionFeedback.Request): Promise<OpenAI.UpdateOpenAICompletionFeedback.Response> => {
  return http.post(`/admin/openai/completions/${completionId}/feedback`, { feedback });
};