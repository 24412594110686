import { useCallback, useState } from 'react';
import type { SurveyMatchValidation } from '@containers/SurveyBuilder.Logic/interfaces';
import { ConditionsValidationsMap } from '@containers/SurveyBuilder.Logic/interfaces';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { useSurveyBasicQuotaState } from '../Context';
import type { QuotaValidations } from '../interfaces';
import { validateBasicQuota } from '../validation';

export const useBasicQuotaValidations = () => {
  const [validations, setValidations] = useState<QuotaValidations>({
    action: { success: true },
    limit: { success: true },
    name: { success: true },
  });
  const [matchValidation, setMatchValidation] = useState<SurveyMatchValidation>({
    conditions: {},
    rule: { success: true },
  });

  const [item] = useSurveyBasicQuotaState();
  const [state] = useSurveyBuilderState();

  const runValidations = useCallback(() => {
    const { success, conditions, rule, ...rest } = validateBasicQuota(item, state.survey);

    setValidations(rest);
    setMatchValidation({ conditions, rule });
    return success;
  }, [item, state.survey]);

  return {
    validations,
    matchValidation,
    runValidations,
  };
};