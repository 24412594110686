import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import * as $api from '@api';
import {
  SurveyFormVersionIdContext,
  SurveyFormMetadataContext,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
  SurveyFormTemplateContext,
  SurveyFormApiContext,
} from '@containers/SurveyForm';
import type { SurveyActionType } from '@/enums';
import { ActivityBlocker } from '@presentation/ActivityBlocker';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { FormContainer } from './FormContainer';
import { SurveyForm } from './SurveyForm';
import { useSurveyFormQuery, useSurveyPreviewQuery, useSubmitAnswerMutation, useSubmitMessageScreenMutation, useGoBackMutation, useSubmitAIEScreenMutation, useSaveSurveyMutation } from './hooks';
import { FormPreviewContext, LeftBarWindowContext } from './context';
import styles from './style/Form.css';
import { LeftBarContainer } from './LeftBarContainer';

type RouteParams = Stringify<ISurveyId & ICallId>;

export const SurveyScreen = () => {
  const params = useParams<RouteParams>();
  const [simpleMessage, setSimpleMessage] = useState<string>();

  const surveyId = +params.surveyId;
  const callId = +params.callId;

  const { data, isLoading, isError, refetch } = useSurveyFormQuery({ callId, surveyId });

  const statusQuery = useQuery({ queryKey: ['capture-sheet-get-response-status', callId, surveyId], queryFn: () => {
    return $api.surveys.captureSheet.getSurveyResponseStatus({
      callId,
      surveyId,
    });
  }, onSuccess: result => {
    switch (result.responseStatus) {
      case 'none':
      case 'in-progress':
        refetch();
        return;
      case 'completed':
        setSimpleMessage(SIMPLE_MESSAGES.previouslyCompleted);
        return;
      case 'locked':
        setSimpleMessage(SIMPLE_MESSAGES.surveyLocked);
        return;
    }
  }, onError: err => Sentry.captureException(err), refetchOnWindowFocus: false, refetchOnReconnect: false, retry: 1, staleTime: Infinity });

  const previewQuery = useSurveyPreviewQuery({ callId, surveyVersionId: data?.survey?.id });

  const goBackMutation = useGoBackMutation({ callId, surveyId });
  const submitAnswerMutation = useSubmitAnswerMutation({ callId, surveyId });
  const submitMessageScreenMutation = useSubmitMessageScreenMutation({ callId, surveyId });
  const submitAIEScreenMutation = useSubmitAIEScreenMutation({ callId, surveyId });
  const saveSurveyMutation = useSaveSurveyMutation({ callId, surveyId });

  const isSubmitting = useMemo(() => goBackMutation.isLoading || submitAnswerMutation.isLoading || submitMessageScreenMutation.isLoading || submitAIEScreenMutation.isLoading, [goBackMutation.isLoading, submitAnswerMutation.isLoading, submitMessageScreenMutation.isLoading, submitAIEScreenMutation.isLoading]);

  const apis = useMemo(() => ({
    goBack: goBackMutation.mutateAsync,
    submitAIEScreen: submitAIEScreenMutation.mutateAsync,
    submitAnswer: submitAnswerMutation.mutateAsync,
    submitMessageScreen: submitMessageScreenMutation.mutateAsync,
  }), [
    goBackMutation.mutateAsync,
    submitAnswerMutation.mutateAsync,
    submitMessageScreenMutation.mutateAsync,
    submitAIEScreenMutation.mutateAsync,
  ]);

  const handleSaveSurvey = useCallback(() => {
    return saveSurveyMutation.mutateAsync({
      responseIdentifier: data.response.identifier,
      surveyVersionId: data.survey.id,
    });
  }, [
    saveSurveyMutation,
    data?.response?.identifier,
    data?.survey?.id,
  ]);

  const handleCompletion = useCallback(() => {
    handleSaveSurvey()
      .then(() => {
        setSimpleMessage(SIMPLE_MESSAGES.onCompletion);
      });
  }, [handleSaveSurvey]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    handleSaveSurvey()
      .then(() => {
        setSimpleMessage(SIMPLE_MESSAGES.onDisqualification);
      });
  }, [handleSaveSurvey]);

  const [window, setWindow] = useState<Window>();

  if (isError || statusQuery.isError || previewQuery.isError) {
    return <div>Error</div>;
  }

  if (simpleMessage) {
    return <div className={styles.simpleMessage}>{simpleMessage}</div>;
  }

  if (statusQuery.isLoading || isLoading || previewQuery.isLoading || !data) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <SurveyFormVersionIdContext.Provider value={data.survey.id}>
      <SurveyFormMetadataContext.Provider value={data.metadata}>
        <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
          <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
            <SurveyResponseIdentifierContext.Provider value={data.response.identifier}>
              <SurveyFormTemplateContext.Provider value={data.survey.template}>
                <SurveyFormApiContext.Provider value={apis}>
                  <FormPreviewContext.Provider value={{ preview: previewQuery.data }}>
                    <LeftBarWindowContext.Provider value={{ window, setWindow, thisRole: 'parent' }}>
                      <LeftBarContainer callId={callId}>
                        <FormContainer state={data.response.state}>
                          <SurveyForm />
                          {isSubmitting && <ActivityBlocker />}
                        </FormContainer>
                      </LeftBarContainer>
                    </LeftBarWindowContext.Provider>
                  </FormPreviewContext.Provider>
                </SurveyFormApiContext.Provider>
              </SurveyFormTemplateContext.Provider>
            </SurveyResponseIdentifierContext.Provider>
          </SaveDisqualifiedSurveyResponseContext.Provider>
        </SaveCompletedSurveyResponseContext.Provider>
      </SurveyFormMetadataContext.Provider>
    </SurveyFormVersionIdContext.Provider>
  );
};

export default SurveyScreen;

const SIMPLE_MESSAGES = {
  previouslyCompleted: `This survey has already been completed.`,
  surveyLocked: `This survey is already in progress by another user.`,
  onCompletion: `You have finished the survey!`,
  onDisqualification: `The user has been disqualified from this survey.`,
};