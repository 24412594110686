import type { ChangeEvent } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import type { MatrixSliderQuestion } from '@/types/survey';
import { cx } from '@utils';
import { useSurveyRichTextEditor, SurveyRichTextEditorContainer, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { TextareaAutosize } from '@/components/Textarea';
import { SurveyNumberInput } from '@/components/SurveyForm/SurveyNumberInput';
import MatrixSlider from './MatrixSlider';
import styles from './style/SurveyMatrixSliders.css';

type Props = {
  answerItem: MatrixSliderQuestion.RespondentAnswer.Item;
  settings: MatrixSliderQuestion.Settings<number>;
  row: MatrixSliderQuestion.Row;
  textAnswer: string;
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputChange: (value: NumberFormatValues) => void;
  onPositionChange: (position: number) => void;
  pctOfTotal: number;
  updateRowText: (value: string) => void;
};

const MatrixSliderRow = (props: Props) => {

  return (
    <div
      className={cx(styles.row, {
        [styles.noSlider]: props.settings.slider.hideSlider,
      })}>
      <div className={styles.label}>
        <div>
          <SurveyRichTextEditor />
          {props.row.metadata.isOpenEnded &&
            <TextareaAutosize
              className={styles.openEnded}
              onChange={e => props.updateRowText(e.target.value)}
              value={props.answerItem?.rowText || ''} />}
        </div>
      </div>
      <div className={styles.value}>
        <div className={styles.valueInput}>
          <SurveyNumberInput
            valueIsNumericString={true}
            settings={props.settings.numberSettings}
            value={props.textAnswer}
            onValueChange={props.onInputChange}
            onBlur={props.onBlur} />
        </div>
        {props.settings.slider.displayPctOfTotal &&
          <div className={styles.pct}>({props.pctOfTotal}%)</div>
        }
      </div>
      {!props.settings.slider.hideSlider &&
        <MatrixSlider
          className={styles.content}
          incrementBy={props.settings.slider.increment}
          maxValue={props.settings.slider.maxValue}
          minValue={props.settings.slider.minValue}
          onPositionChange={props.onPositionChange}
          value={props.answerItem?.value} />}
    </div>
  );
};

const Container = (props: Props) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.row.value,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <MatrixSliderRow {...props} />
    </SurveyRichTextEditorContainer>
  );
};

export { Container as MatrixSliderRow };
export default Container;