import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import styles from './style/Modal.Disclaimer.css';

type Props = {
  text: string;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const CallDisclaimerModal = ({ onClose, open, text }: Props) => {

  return (
    <Modal open={open} onClose={onClose}>
      <Header text="Disclaimer" />
      <div className={styles.body}>
        {text}
      </div>
    </Modal>
  );
};

export default CallDisclaimerModal;