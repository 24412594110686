import { createElement, useCallback, useContext, useState } from 'react';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import type { TabViewRenderParams } from '@/components/TabView';
import { TabView } from '@/components/TabView';
import * as Tab from './Booking';
import { BookingSegmentContext, CalendarsContext, FrameContext, ParticipantsContext, SettingsContext } from './Context';
import type { BookingSegmentState } from './interfaces';
import { TabKey } from './interfaces';
import styles from './style/Modal.CallScheduling.css';

type Props = unknown;

export const Booking = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
    frame: useContext(FrameContext),
    participants: useContext(ParticipantsContext),
    settings: useContext(SettingsContext),
  };

  const [range, setRange] = useState<BookingSegmentState>(null);
  const [index, setIndex] = useState(0);

  const renderTabView = useCallback((params: TabViewRenderParams) => {
    const tab = SceneMap[params.route.key as TabKey]?.component ?? null;

    return createElement(tab);
  }, []);

  if (ctx.participants.query.isInitialLoading || ctx.calendars.query.isInitialLoading || ctx.settings.isLoading) {
    return (
      <div className={styles.loader}>
        <ActivityIndicator show />
      </div>
    );
  }

  return (
    <BookingSegmentContext.Provider value={[range, setRange]}>
      <TabView
        classes={{
          bar: styles.bar,
          tab: styles.tab,
          tabs: styles.tabs,
        }}
        index={index}
        onIndexChange={idx => setIndex(idx)}
        renderTabView={renderTabView}
        routes={Object.values(SceneMap)} />
    </BookingSegmentContext.Provider>
  );
};

Booking.displayName = 'Frame.Booking';

const SceneMap = {
  [TabKey.ViewAvailability]: {
    component: Tab.ViewAvailability,
    key: TabKey.ViewAvailability,
    title: `View Availability`,
  },
  [TabKey.Schedule]: {
    component: Tab.Schedule,
    key: TabKey.Schedule,
    title: `Schedule`,
  },
};