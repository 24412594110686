import { useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import type { CellProps } from 'react-table';
import { Cell } from 'react-table';
import { Node, createEditor } from 'slate';
import type { RenderElementProps } from 'slate-react';
import { Editable, Slate, withReact } from 'slate-react';
import { PostMediaType } from '@enums';
import { VideoPreviewOverlay } from '@presentation/VideoPreviewOverlay';
import { SeeMore } from '@presentation/SeeMore';
import { getLocationFor, formatDisplayAge } from '@utils';
import { RichTextTag } from '@/components/RichTextMentions';
import { UserAvatar } from '@/components/UserAvatar';
import type { PostsTableItem } from './interfaces';
import styles from './style/Cell.Post.css';

export const Post = memo(({ row: { original: data } }: CellProps<PostsTableItem>) => {
  switch (data.metadata.media?.typeId) {
    case PostMediaType.Video: return <ItemVideo item={data} />;
    default: return <ItemText item={data} />;
  }
});

const ItemVideo = memo(({ item }: ItemProps) => {
  const meta = item.metadata;
  const career = meta.author.career.current?.display ?? meta.author.career.former?.display;
  return (
    <div className={styles.root}>
      <Link to={getLocationFor.discovery.video({ postId: item.entityId })}>
        <div className={styles.author}>
          <UserAvatar
            className={styles.avatar}
            pictureUrl={meta.author.profile.pictureUrl}
            size={36} />
          <div className={styles.name}>{`${meta.author.profile.fullname}, ${career}`}</div>
          <div className={styles.age}>{formatDisplayAge(meta.createdOn)}</div>
        </div>
      </Link>
      <div className={styles.video}>
        <VideoPreviewOverlay
          className={styles.left}
          previewUrl={meta.media.thumbnail.url}
          to={getLocationFor.discovery.video({ postId: item.entityId })} />
        <div className={styles.right}>
          <div className={styles.title}>{meta.media.title}</div>
          <div className={styles.description}>{meta.media.content.description.map(node => Node.string(node)).join('\n')}</div>
        </div>
      </div>
    </div>
  );
});

const ItemText = memo(({ item }: ItemProps) => {
  const meta = item.metadata;
  const career = meta.author.career.current?.display ?? meta.author.career.former?.display;

  const editor = withReact(createEditor());
  const renderElement = useCallback(props => <Element {...props} />, []);

  return (
    <div className={styles.root}>
      <Link to={getLocationFor.post.root({ postId: item.entityId })}>
        <div className={styles.author}>
          <UserAvatar
            className={styles.avatar}
            pictureUrl={meta.author.profile.pictureUrl}
            size={36} />
          <div className={styles.name}>{`${meta.author.profile.fullname}, ${career}`}</div>
          <div className={styles.age}>{formatDisplayAge(meta.createdOn)}</div>
        </div>
      </Link>
      <div className={styles.text}>
        <SeeMore maxLines={2} lineHeight={20}>
          <Slate
            editor={editor}
            onChange={() => {}}
            value={meta.body}>
            <Editable
              readOnly
              renderElement={renderElement} />
          </Slate>
        </SeeMore>
      </div>
    </div>
  );
});

const Element = (props: RenderElementProps)  => {
  switch (props.element.type) {
    case 'mention':
      return <RichTextTag {...props} />;
    default:
      return <p {...props.attributes} className={styles.p}>{props.children}</p>;
  }
};

type ItemProps = {
  item: PostsTableItem;
};

export default Post;