import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { cx } from '@utils';
import type { TableItem } from './interfaces';
import styles from './style/Main.css';

type Props = {
  className?: string;
  item: TableItem;
  children: React.ReactNode;
};

export default function ResultClickContainer({ className, item, children }: Props) {
  const history = useHistory();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push(`/${item.path}`);
  }, [item.path, history]);

  return (
    <a
className={cx(styles.clicker, className)}
href={`/${item.path}`}
onClick={handleClick}>
      {children}
    </a>
  );
}

export { ResultClickContainer };