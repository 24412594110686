import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const loc = getLocationFor.call.cleanup({
    callId: props.row.original.call.id,
    projectId: props.row.original.project.id,
  });

  return (
    <Link
      className={styles.link}
      to={loc}>
      {props.row.original.call.id}
    </Link>
  );
};

Cell.displayName = 'Column.Id.Cell';