// import { Skip } from '@presentation';
import { useProjectOnboardingContext, useIsFreeProject } from '@containers/ProjectOnboarding/hooks';
import Button from '@/components/Button';
import type { OverviewProps } from './interfaces';
import styles from './style/Overview.css';

export default function Survey({ navigation }: OverviewProps) {
  const { projectId } = useProjectOnboardingContext();
  const isFreeSurvey = useIsFreeProject(projectId);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{`Thank you! Your responses have been submitted.`}</h1>
        <div className={styles.copy}>{`Please add a payout account so we can compensate you for ${isFreeSurvey ? `future opportunities` : `completing this survey`}.`}</div>
        <div className={styles.navigation}>
          <Button
            variant="brick"
            title="Add Payout Account"
            onClick={navigation.next} />
          {/* <div className={styles.skip}>
            <Skip onClick={navigation.skip}>
              {`I'll do this later`}
            </Skip>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export { Survey };