import { ComplianceSurveyResponse, EditableComplianceSurveyResponse } from '@/components/SurveyResponse';
import { CompletedSurvey } from '@/scenes/surveys/CompletedSurvey/CompletedSurvey';
import type { CompletedSurveyProps, EditableCompletedSurveyProps } from '../interfaces';
import UserDetails from '../Project/UserDetails';
import SurveyDetails from './SurveyDetails';
import styles from './style.css';

export const BaseCompletedComplianceSurvey = (props: CompletedSurveyProps & {
  results: React.ReactNode;
}) => (
  <CompletedSurvey
    bodyClassName={styles.body}
    surveyDetails={
      <SurveyDetails
        completedOn={props.response.completedOn}
        project={props.project} />
    }
    userDetails={<UserDetails user={props.user} />}
    results={props.results} />
);

export const CompletedComplianceSurvey = (props: CompletedSurveyProps) => {
  return (
    <BaseCompletedComplianceSurvey
      {...props}
      results={<ComplianceSurveyResponse response={props.response} />} />
  );
};

export const EditableCompletedComplianceSurvey = (props: EditableCompletedSurveyProps) => {
  return (
    <BaseCompletedComplianceSurvey
      {...props}
      results={<EditableComplianceSurveyResponse onSave={props.onSaveAnswer} response={props.response} />} />
  );
};

export default CompletedComplianceSurvey;