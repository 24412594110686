import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundPracticeContext } from './Context';
import type { FormState, QueryData, SetValueParams } from './interfaces.practice';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const [practice, setPractice] = useState<FormState>(getStateFromQueryData(ctx.query.data.practice));

  const rehydrate = useCallback(() => {
    return ctx.query.refetch()
    .then(res => setPractice(getStateFromQueryData(res.data.practice)));
  }, [
    ctx.query,
    setPractice,
  ]);

  const handleChange = useCallback((data: SetValueParams) => {
    setPractice({
      practiceTime: {
        answer: data.practiceTime ?? practice.practiceTime.answer,
        question: practice.practiceTime.question,
      },
      medicalFacilityType: {
        answer: data.medicalFacilityType ?? practice.medicalFacilityType.answer,
        question: practice.medicalFacilityType.question,
      },
      employer: data.employer?.name || data.employer?.name === ''
        ? !data.employer?.id
          ? { ...data.employer, custom: true }
          : data.employer
        : practice.employer,

    });
  }, [practice]);

  const value = {
    form: practice,
    rehydrate,
    setValue: handleChange,
  };

  return (
    <MedicalBackgroundPracticeContext.Provider value={value}>
      {props.children}
    </MedicalBackgroundPracticeContext.Provider>
  );
};

StateContainer.displayName = 'MedicalBackground.Practice.StateContainer';

function getStateFromQueryData(data: QueryData): FormState {
  return {
    practiceTime: {
      answer: data.practiceTime.answer,
      question: {
        ...data.practiceTime.question,
        matrixRows: data.practiceTime.question.rows,
      },
    },
    medicalFacilityType: {
      answer: data.medicalFacilityType.answer?.value,
      question: data.medicalFacilityType.question,
    },
    employer: data.employer,
  };
}