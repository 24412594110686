import { VICSType } from '@enums';
import type { VICS } from '@/types';

export const mapVICSByType = (items: VICS[]) => {
  return items.reduce<VICSMappedByType>((acc, x) => {
    if (x.typeId === VICSType.Industry) {
      acc.industries = acc.industries.concat(x);
    } else if (x.typeId === VICSType.Sector) {
      acc.sectors = acc.sectors.concat(x);
    } else if (x.typeId === VICSType.SubIndustry) {
      acc.subindustries = acc.subindustries.concat(x);
    }

    acc.ids = acc.ids.concat(x.id);
    acc[x.id] = x;

    return acc;
  }, {
    ids: [],
    industries: [],
    sectors: [],
    subindustries: [],
  });
};

export type VICSMappedByType<T extends VICS = VICS> = {
  [id: number]:  T;
  ids:           T['id'][];
  industries:    T[];
  sectors:       T[];
  subindustries: T[];
};