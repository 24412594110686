import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import * as api from '@api';

type Data = API.Projects.FetchRepeatSurveyResponses.Response;
type QueryKey = [string, Props];
type Props = {
  limit?: number;
  projectParentId: number;
  questionId?: number;
} & ISurveyId;

export function useFetchRepeatSurveyResponses<TData = Data>(
  props: Props,
  options?: UseQueryOptions<Data, AxiosResponse, TData, QueryKey>
) {

  return useQuery({ queryKey: keyUseFetchRepeatSurveyResponses(props), queryFn: ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.projects.surveys.fetchRepeatResponses(params);
  }, ...options });
}

export function keyUseFetchRepeatSurveyResponses(props: Props) {
  return ['repeat-project-survey-responses', props];
}

export default useFetchRepeatSurveyResponses;