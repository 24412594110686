import http from '@services/http';
import type { GMV } from '$admin/api/interfaces';

export function getGMVDailyRevenue(startDate: Date, endDate: Date) {
  return http.get<GMV.GetGMVDailyRevenue.Response>(`/admin/gmv/daily-revenue?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
}

export function getGMVGroupedRevenue(startDate: Date, endDate: Date) {
  return http.get<GMV.GetGMVGroupedRevenue.Response>(`/admin/gmv/grouped-revenue?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
}

export function getGMVGroupedRevenueCSV(data: GMV.GetGMVGroupedRevenueCSV.Request) {
  return http.get<GMV.GetGMVGroupedRevenueCSV.Response>(`/admin/gmv/grouped-revenue-csv?startDate=${data.startDate.toISOString()}&endDate=${data.endDate.toISOString()}`, {
    responseType: 'blob',
  });
}