import type { SurveyItemType } from '@enums';
import type { SurveyFormItem } from '@/types/survey';
import { useSurveyFormScreenContext } from '../Context';

export function useSurveyFormAIEItem() {
  const [state] = useSurveyFormScreenContext();

  return state.item as SurveyFormItem<SurveyItemType.AlternateImageExercise>;

}

export default useSurveyFormAIEItem;