import { useMutation, useQueries } from '@tanstack/react-query';
import * as api from '@api';
import type { Users } from '@api/interfaces';
import * as QK from '@consts/querykey';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import * as AdditionalExpertise from './Background.AdditionalExpertise.StateContainer';
import * as Contact from './Background.Contact.StateContainer';
import * as Identification from './Background.Identification.StateContainer';
import * as Leadership from './Background.Leadership.StateContainer';
import * as Practice from './Background.Practice.StateContainer';
import { MedicalBackgroundContext } from './Context';
import type { ScreenActionOnSuccess } from './interfaces';

type Props = {
  children:   React.ReactNode;
  disableActivityIndicator?: boolean;
  onSuccess?: ScreenActionOnSuccess;
} & IUserId;

export const MedicalOnboardingBackgroundContainer = (props: Props) => {

  const keys = {
    background: QK.Users.Onboarding.Background.Get({ userId: props.userId }),
    expertise: QK.Users.Onboarding.Expertise.Get({ userId: props.userId }),
  };

  const [background, expertise] = useQueries({
    queries: [
      {
        queryKey: keys.background,
        queryFn: () => api.users.onboarding.fetchBackground({
          userId: props.userId,
        }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: keys.expertise,
        queryFn: () => api.users.onboarding.fetchExpertise({
          userId: props.userId,
        }),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const mutation = useMutation({ mutationKey: [
    QK.Users.Onboarding.Background.Put,
    props.userId,
  ], mutationFn: (data: Omit<Users.Onboarding.UpdateBackground.Request, 'userId'>) => {
    return api.users.onboarding.updateBackground({
      ...data,
      userId: props.userId,
    });
  }, onSuccess: (data, variables) => {
    props.onSuccess?.(variables);
  } });

  const value = {
    mutation,
    query: background,
    userId: props.userId,
  };

  if (props.disableActivityIndicator) {
    return props.children;
  }

  if (background.isLoading || expertise.isLoading) {
    return <ActivityIndicator show={true} />;
  }

  return (
    <MedicalBackgroundContext.Provider value={value}>
      <Identification.StateContainer>
        <AdditionalExpertise.StateContainer>
          <Practice.StateContainer>
            <Leadership.StateContainer>
              <Contact.StateContainer>
                {props.children}
              </Contact.StateContainer>
            </Leadership.StateContainer>
          </Practice.StateContainer>
        </AdditionalExpertise.StateContainer>
      </Identification.StateContainer>
    </MedicalBackgroundContext.Provider>
  );
};

MedicalOnboardingBackgroundContainer.displayName = 'MedicalOnboarding.Background.Container';