import type { NumberInputTable } from './interfaces';
import { NumberTableNumberInput } from './NumberInputTable.NumberInput';
import { NumberInputTableDropdown } from './NumberInputTable.Dropdown';

type Props = {
  disabled: boolean;
} & NumberInputTable.InputProps;

export const NumberInputTableInput = ({ disabled, ...props }: Props) => {

  if (disabled) return (
    <>{props.value}</>
  );

  if (props.settings.dropdowns.enabled) {
    return <NumberInputTableDropdown {...props} />;
  }

  return (
    <NumberTableNumberInput {...props} />
  );
};

export default NumberInputTableInput;