import axios from 'axios';
import type { Discovery } from '@api/interfaces';
import { logRequest } from '@services/http/console';
import { normalizePost } from '@transformers';

const http = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

http.interceptors.request.use(config => {
  logRequest(config);
  return config;
});

export const fetchContentItem = (data: Discovery.FetchContentItem.Request) => {
  return http.get<Discovery.FetchContentItem.Response>(`/discovery/posts/${data.postId}`)
  .then(({ data })  => normalizePost(data));
};

export const fetchFeaturedCreators = () => {
  return http.get<Discovery.FetchFeaturedCreators.Response>(`/discovery/videos/creators/featured`)
  .then(({ data }) => data);
};

export const fetchRelatedItems = (data: Discovery.FetchRelatedItems.Request): Promise<Discovery.FetchRelatedItems.Response> => {
  return http.get(`/discovery/posts/${data.postId}/related`)
  .then(({ data }) => data);
};

export const updateVideoViewCount = (data: Discovery.UpdateVideoViewCount.Request): Promise<Discovery.UpdateVideoViewCount.Response> => {
  return http.put(`/discovery/statistics/videos/${data.mediaId}`, data)
  .then(({ data }) => data);
};

export const fetchPopularVideoTopics = (): Promise<Discovery.FetchPopularVideoTopics.Response> => {
  return http.get(`/discovery/videos`)
  .then(({ data }) => data);
};

export const fetchVideoComments = (data: Discovery.FetchVideoComments.Request) => {
  return http.get<Discovery.FetchVideoComments.Response>(`/discovery/videos/comments/${data.mediaId}`)
  .then(({ data }) => data);
};

export const fetchVideoTopics = (): Promise<Discovery.FetchVideoTopics.Response> => {
  return http.get(`/discovery/videos/topics`)
  .then(({ data }) => data);
};

export const fetchVideoTopicContent = (data: Discovery.FetchVideoTopicContent.Request) => {
  return http.get<Discovery.FetchVideoTopicContent.Response>(`/discovery/videos/topics/${data.slug}`)
  .then(({ data }) => data);
};

export const fetchVideoTopicCreators = (data: Discovery.FetchVideoTopicCreators.Request) => {
  return http.get<Discovery.FetchFeaturedCreators.Response>(`/discovery/videos/topics/${data.slug}/creators`)
  .then(({ data }) => data);
};

export const fetchVideoTranscript = (data: Discovery.FetchVideoTranscript.Request) => {
  return http.get<Discovery.FetchVideoTranscript.Response>(`/discovery/videos/transcripts/${data.mediaId}`)
  .then(({ data }) => data);
};