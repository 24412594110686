import { useCallback, useContext } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { X } from 'react-feather';
import CheckCircle from '@mui/icons-material/CheckCircleOutlineOutlined';
import RemoveCircle from '@mui/icons-material/HighlightOffOutlined';
import type * as API from '@api/interfaces';
import * as api from '@api';
import { TaggingSearchContainer } from '@containers/Group.Contacts.Import';
import { TaggingAutoSuggestContext } from '@containers/Group.Contacts.Import/Context';
import type { Tag } from '@containers/Group.Contacts.Import/interfaces.tagging';
import { useSelectGroup } from '@containers/Store';
import { Chip } from '@presentation';
import { cx, useToggle } from '@utils';
import AutoComplete from '@/components/AutoComplete';
import { GroupContactRecordContext } from './Context';
import * as Layout from './Layout';
import styles from './style/Section.Tags.css';

type Props = unknown;

export const Tags = (props: Props) => {
  const ctx = useContext(GroupContactRecordContext);

  if (!ctx.query.data) return null;

  return (
    <div className={styles.root}>
      <Tagging items={ctx.query.data.tags} />
    </div>
  );
};

Tags.displayName = 'Group.Contacts.Record.Section.Tags';

type TaggingProps = {
  items: Descriptor[];
};

const Tagging = (props: TaggingProps) => {
  const [editing, toggle] = useToggle(false);

  const mutation = useContactRecordMutation({
    onSuccess: toggle,
  });

  if (editing) {
    return (
      <div className={styles.wrap}>
        <TaggingSearchContainer initial={props.items}>
          <Layout.Header>
            <Layout.Title>Tags</Layout.Title>
            <div className={styles.action}>
              <EditTagsButtonSet
                loading={mutation.isLoading}
                onCancel={toggle}
                onSubmit={mutation.mutate} />
            </div>
          </Layout.Header>
          <div className={styles.main}>
            <EditTags />
          </div>
        </TaggingSearchContainer>
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <Layout.Header>
        <Layout.Title>Tags</Layout.Title>
        <div className={styles.action}>
          <button
            className={styles.edit}
            onClick={toggle}>
            {`+ Add`}
          </button>
        </div>
      </Layout.Header>
      <div className={styles.main}>
        <DisplayTags items={props.items} />
      </div>
    </div>
  );
};

type EditTagsButtonSetProps = {
  loading: boolean;
  onSubmit: (params: Pick<API.Groups.Contacts.UpdateRecordTags.Request, 'tagging'>) => unknown;
  onCancel: () => void;
};

const EditTagsButtonSet = ({ onSubmit, ...props }: EditTagsButtonSetProps) => {
  const { selected } = useContext(TaggingAutoSuggestContext);

  const handleSubmit = useCallback(() => {
    onSubmit({
      tagging: getTaggingParamValue(selected.items),
    });
  }, [
    onSubmit,
    selected.items,
  ]);

  return (
    <>
      <button
        className={styles.save}
        disabled={props.loading}
        onClick={handleSubmit}>
        <CheckCircle />
      </button>
      <button
        className={styles.cancel}
        disabled={props.loading}
        onClick={props.onCancel}>
        <RemoveCircle />
      </button>
    </>
  );
};

const EditTags = (props: Props) => {
  const ctx = useContext(TaggingAutoSuggestContext);

  return (
    <>
      <AutoComplete
        autoComplete="off"
        classes={{
          root: styles.inputroot,
          input: styles.input,
        }}
        getItemValue={ctx.suggestions.renderOptionValue}
        items={ctx.suggestions.items}
        onChange={ctx.input.onChange}
        onSelect={ctx.suggestions.onSelect}
        placeholder="Search for or create tags"
        value={ctx.input.value} />
      <div className={styles.tags}>
        {ctx.selected.items.map(x =>
          <div
            className={styles.removable}
            key={x.id}>
            <Chip
              className={styles.tag}
              color="basic">
              <Chip.Body.Basic>
                <div>{x.name}</div>
              </Chip.Body.Basic>
            </Chip>
            <div
              className={styles.icon}
              onClick={() => ctx.selected.onDelete(x)}>
              <X size={18} />
            </div>
          </div>)}
      </div>
    </>
  );
};

EditTags.displayName = 'Group.Contacts.Record.Section.Tags.Edit';

type DisplayTagsProps = {
  items: Descriptor[];
};

const DisplayTags = (props: DisplayTagsProps) => {
  const ctx = useContext(GroupContactRecordContext);

  return (
    <div className={styles.tags}>
      {ctx.query.data.tags.map(x =>
        <Chip
          className={styles.tag}
          color="basic"
          key={x.id}>
          <Chip.Body.Basic>
            <div>{x.name}</div>
          </Chip.Body.Basic>
        </Chip>)}
    </div>
  );
};

DisplayTags.displayName = 'Group.Contacts.Record.Section.Tags.Display';

declare namespace Mutation {
  export type Variables =
    Omit<API.Groups.Contacts.UpdateRecordTags.Request,
    | 'contactId'
    | 'groupId'>;

  export type Data = API.Groups.Contacts.UpdateRecordTags.Response;

  export type Options =
    UseMutationOptions<
      Data,
      unknown,
      Variables>;
}

const useContactRecordMutation = (options: Mutation.Options = {}) => {
  const ctx = useContext(GroupContactRecordContext);
  const group = useSelectGroup();

  const qc = useQueryClient();

  const mutation = useMutation<Mutation.Data, unknown, Mutation.Variables>({ mutationKey: [
    `post:groups/contacts/records/tags`,
    group?.id,
    ctx.query.data?.id,
  ], mutationFn: data => {
    return api.groups.contacts.updateRecordTags({
      contactId: ctx.query.data.id,
      groupId: group.id,
      ...data,
    });
  }, ...options, onSuccess: (res, variables, context) => {
    qc.setQueryData<API.Groups.Contacts.FetchRecord.Response>([
      `get:groups/contacts/records`,
      group.id,
      ctx.query.data.id,
    ], data => ({
      ...data,
      tags: res.tags,
    }));

    options?.onSuccess(res, variables, context);
  } });

  return mutation;
};

function getTaggingParamValue(items: Tag[]) {
  return items.reduce((acc, x) => ({
    apply: !x.new ? acc.apply.concat(x.id as number) : acc.apply,
    create: x.new ? acc.create.concat(x.name) : acc.create,
  }), {
    apply: [],
    create: [],
  } as API.Groups.Contacts.UpdateRecordTags.Request['tagging']);
}