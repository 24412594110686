import { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useQueries, QueryObserverOptions } from '@tanstack/react-query';
import * as api from '@api';
import { useAppReadyState } from '@containers/AppReadyState';
import { SurveyFormContext } from '@containers/SurveyForm';
import { useStepper } from '@utils';
import { path } from '@consts';
import { UserProjectStatus } from '@/enums';
import Modal from '@/components/Modal/Modal.Legacy';
import RatingsStepper from './RatingsStepper';
import QuestionnareStepper from './QuestionnareStepper';
import { SecondaryScreener } from './SecondaryScreener';
import type {
  CallFeedbackStepperContextValue,
} from './interfaces';
import { CallFeedbackStepperContext } from './Context';

const DefaultQueryOptions = {
  staleTime: 1000 * 60 * 15,
};

const CallFeedbackQuestionnareStepper = () => {
  const ctx = useContext(CallFeedbackStepperContext);

  return (
    <QuestionnareStepper
      onCompletion={ctx.onCompletion}
      callIds={ctx.questionnareItems} />
  );
};

const CallFeedbackRatingsStepper = () => {
  const ctx = useContext(CallFeedbackStepperContext);

  const handleCompletion = () => {
    if (ctx.questionnareItems.length) {
      ctx.next();
    } else {
      ctx.onCompletion();
    }
  };

  return (
    <RatingsStepper
      items={ctx.ratingItems}
      onCompletion={handleCompletion} />
  );
};

const selectOnboardingCompleted = (state: Store.State) => state.onboarding.completed;
const selectPipeline = (projectId: number) => (state: Store.State) => state.pipeline.me[projectId];

const CallFeedbackModal = () => {
  const { initialized } = useAppReadyState();
  const [completed, setCompleted] = useState<boolean>(false);
  const hideViaRoute = useRouteMatch({ exact: false, path: path.UninterruptibleRoutes });
  const onboardingComplete = useSelector(selectOnboardingCompleted);

  const fetchQuestionnareItems = useCallback(() => {
    return api.users.getOutstandingPostCallQuestionnares();
  }, []);

  const [{ data: feedbackData, isLoading: feedbackLoading, refetch: refetchFeedback }, { data: questionnaireData, isLoading: questionnaireLoading }] = useQueries({
    queries: [
      { queryKey: ['get-feedback-items'], queryFn: api.users.getFeedbackItems, initialData: { callsToRate: [], availableScreenerProjectId: null }, ...DefaultQueryOptions, refetchOnMount: false },
      { queryKey: ['fetch-questionare-items'], queryFn: fetchQuestionnareItems, initialData: { survey: null, ids: [] }, ...DefaultQueryOptions },
    ],
  });

  const project = useSelector(selectPipeline(feedbackData.availableScreenerProjectId));

  useEffect(() => {
    if (onboardingComplete) {
      refetchFeedback();
    }
  }, [onboardingComplete, refetchFeedback]);

  useEffect(() => {
    if (feedbackData.availableScreenerProjectId && project?.statusId !== UserProjectStatus.Active) {
      refetchFeedback();
    }
  }, [project?.statusId, refetchFeedback, feedbackData.availableScreenerProjectId]);

  const hide = useMemo(() => {
    return !initialized ||
      hideViaRoute ||
      completed ||
      (!feedbackData.callsToRate.length && !questionnaireData.ids.length && !feedbackData.availableScreenerProjectId);
  }, [
    hideViaRoute,
    completed,
    initialized,
    feedbackData,
    questionnaireData,
  ]);

  const steps = useMemo(() => {
    const steps = [];
    if (feedbackData.availableScreenerProjectId) {
      steps.push(SecondaryScreener);
    }
    if (feedbackData.callsToRate.length > 0) {
      steps.push(CallFeedbackRatingsStepper);
    }

    if (questionnaireData.ids.length > 0) {
      steps.push(CallFeedbackQuestionnareStepper);
    }

    return steps as React.ComponentType[];
  }, [feedbackData, questionnaireData]);

  const [Screen, actions, stepIndex] = useStepper(steps);

  const handleCompletion = useCallback(() => {
    setCompleted(true);
  }, []);

  const handleClickAway = useCallback(() => {
    setCompleted(true);
  }, []);

  if (hide) {
    return null;
  }

  const context: CallFeedbackStepperContextValue = {
    back: actions.back,
    next: stepIndex < steps.length - 1 ? actions.next : handleCompletion,
    onCompletion: handleCompletion,
    ratingItems: feedbackData.callsToRate.map(call => ({
      call,
      participants: [],
    })),
    questionnareItems: questionnaireData.ids,
    secondaryScreenerProjectId: feedbackData.availableScreenerProjectId,
  };

  return (
    <Modal
      onClose={handleClickAway}
      blocking={false}
      visible={true}>
      <SurveyFormContext.Provider value={questionnaireData.survey}>
        <CallFeedbackStepperContext.Provider value={context}>
          <Screen />
        </CallFeedbackStepperContext.Provider>
      </SurveyFormContext.Provider>
    </Modal>
  );
};

const CallFeedback = () => {
  const { authenticated } = useAppReadyState();

  return authenticated && <CallFeedbackModal />;
};

export { CallFeedback };
export default CallFeedback;