import type { RowsQuestionType } from '@/enums';
import type { SurveyQuestion, SurveyQuestionMatrixRow } from '@/types';
import { QuestionRow } from './Row';
import styles from './style/Options.css';

type Props = {
  item: SurveyQuestion<RowsQuestionType>;
  renderRowExtras?: (row: SurveyQuestionMatrixRow) => React.ReactNode;
};

export const Rows = ({ item, renderRowExtras }: Props) => (
  <>
    {item.matrixRows.map(row => (
      <QuestionRow
        key={row.base.identifier}
        className={styles.option}
        item={row}>
        {renderRowExtras?.(row)}
      </QuestionRow>
    ))}
  </>
);

export default Rows;