import { useMemo, useCallback } from 'react';
import type { BarTooltipDatum, BarMouseEventHandler } from '@nivo/bar';
import * as chart from '@containers/SurveyResponses/utils';
import { RankingBarChartTooltip } from '@presentation/ProjectSurveyResponses';
import type { SurveyAggregate } from '@/types';
import { useRankingColors } from './hooks/usePaletteColors';
import type { StackedBarChartData } from './interfaces';
import { StackedBarChart, percentProps } from './StackedBarChart';

type Props = {
  responses: SurveyAggregate.StackedBarChart.Data;
  onClick: (id: number, key: string) => void;
};

function sortOptions(a: SurveyAggregate.StackedBarChart.IndexData, b: SurveyAggregate.StackedBarChart.IndexData) {
  return a.aggregate.score > b.aggregate.score
    ? -1
    : a.aggregate.score === b.aggregate.score
      ? a.value > b.value
        ? 1
        : -1
      : 1
  ;
}

export const ResponseRankingChart = ({ onClick, ...props }: Props) => {
  const { extendedStrong, extendedWeak, strong, weak } = useRankingColors();

  const colorMap = useMemo(() => {
    return props.responses.keys.ids.reduce((acc, key) => {
      acc[key] = chart.ranking.getSegmentColor({
        key,
        keys: props.responses.keys,
      }, {
        extendedStrong,
        extendedWeak,
        strong,
        weak,
      });
      return acc;
    }, {} as { [key: string]: string });
  }, [
    extendedStrong,
    extendedWeak,
    props.responses.keys,
    strong,
    weak,
  ]);

  const items: StackedBarChartData[] = useMemo(() => {
    return props.responses.indexes.ids
      .sort((a, b) => sortOptions(props.responses.indexes.values[a], props.responses.indexes.values[b]))
      .reduce((acc, indexId) => {
        const item = props.responses.indexes.values[indexId];
        const keyValues = props.responses.keys.ids
          .reduce((acc2, key) => {
            return {
              [key]: item.keys[key].pct,
              ...acc2,
            };
          }, {});

        const colors = props.responses.keys.ids.reduce((acc3, key) => {
          return {
            ...acc3,
            [key]: colorMap[key],
          };
        }, {});

        acc.push({
          colors,
          indexId: indexId.toString(),
          name: item.value,
          ...keyValues,
        } as StackedBarChartData);

        return acc;
      }, [] as StackedBarChartData[]);
  }, [props.responses, colorMap]);

  const keys = useMemo(() => {
    return [
      ...props.responses.keys.ids
        .filter(f => f !== props.responses.keys.notApplicableKey),
      ...props.responses.keys.ids
        .filter(f => f === props.responses.keys.notApplicableKey),
    ];
  }, [props.responses.keys]);

  const handleSegmentClick: BarMouseEventHandler<SVGRectElement, StackedBarChartData> = useCallback((item, e) => {
    onClick(+item.data.indexId, item.id.toString());
  }, [onClick]);

  const renderTooltip = useCallback((item: BarTooltipDatum) => {
    const data = props.responses.indexes.values[+item.data.indexId].keys[item.id];

    return (
      <RankingBarChartTooltip
        name={item.data.name}
        count={data.userIds.length}
        percent={Math.round(data.pct)}
        ordinal={+item.id} />
    );
  }, [props.responses]);

  return (
    <StackedBarChart
      {...percentProps}
      handleSegmentClick={handleSegmentClick}
      items={items}
      keys={keys}
      notApplicableKeys={[props.responses.keys.notApplicableKey]}
      renderTooltip={renderTooltip} />
  );
};

export default ResponseRankingChart;