import { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { response as $response } from '@api/surveys';
import type { Surveys } from '@api/interfaces/surveys';
import { SurveyActionType } from '@/enums';
import type {
  SubmitOverrides } from '../Context';
import {
  useSaveCompletedSurveyResponse,
  useSurveyFormApi,
  useSurveyFormScreenContext,
  useSurveyFormVersionId,
  useSurveyResponseIdentifier,
} from '../Context';

export const useSubmitMessageScreen = () => {

  const saveCompletedResponse = useSaveCompletedSurveyResponse();
  const [state, setState] = useSurveyFormScreenContext();

  const responseIdentifier = useSurveyResponseIdentifier();
  const surveyVersionId = useSurveyFormVersionId();

  const submitMessageScreen = useSubmitMessageScreenApi();

  const handleSuccess = useCallback((res: Surveys.Response.SubmitMessageScreen.Response) => {
    if (res.action.type === SurveyActionType.SkipToEnd) {
      saveCompletedResponse();
    } else {
      setState(res.state);
    }
  }, [
    saveCompletedResponse,
    setState,
  ]);

  const {
    mutateAsync,
    ...mutation
  } = useMutation({ mutationKey: ['survey-response.submit-message'], mutationFn: (overrides?: SubmitOverrides) => {
    return submitMessageScreen({
      surveyVersionItemId: state.item.id,
      surveyVersionId,
      responseIdentifier,
      itemId: overrides?.itemId,
    });
  }, onSuccess: handleSuccess });

  const handleSubmit = useCallback((overrides?: SubmitOverrides) => {
    return mutateAsync(overrides);
  }, [
    mutateAsync,
  ]);

  return {
    ...mutation,
    mutateAsync: handleSubmit,
  };
};

function useSubmitMessageScreenApi() {
  const handlers = useSurveyFormApi();

  return useMemo(() => handlers?.submitMessageScreen || $response.submitMessageScreen, [handlers?.submitMessageScreen]);
}