import { useCallback } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { SearchTopicsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const TopicsContainer = (props: Props) => {

  const search = useCallback((data: API.AutoCompleteRequest) => api.autocomplete.topic(data), []);

  return (
    <SearchTopicsContext.Provider value={search}>
      {props.children}
    </SearchTopicsContext.Provider>
  );
};

TopicsContainer.displayName = 'PostCreation.Search.TopicsContainer';