import { useCallback, useState } from 'react';
import { PaletteEditorContext } from './Context';
import type { PaletteEditorContextValue } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const EditorContainer = (props: Props) => {
  const [colors, setColors] = useState<string[]>([]);
  const [name, setName] = useState<string>('');

  const init: PaletteEditorContextValue['init'] = useCallback(params => {
    setName(params.name);
    setColors(params.colors);
  }, []);

  const reset = useCallback(() => {
    setName('');
    setColors([]);
  }, []);

  const context = {
    colors,
    init,
    limit: {
      max: Colors.Max,
      min: Colors.Min,
    },
    name,
    reset,
    setColors,
    setName,
  };

  return (
    <PaletteEditorContext.Provider value={context}>
      {props.children}
    </PaletteEditorContext.Provider>
  );
};

EditorContainer.displayName = 'ColorPalette.Editor.Container';

const Colors = {
  Min: 12,
  Max: 25,
};