import { qs } from '@utils';
import type { ProjectLinkURLState } from '@containers/ProjectLinkInvitation/interfaces';
import { useCacheProjectInviteTheming } from './useCacheProjectInviteTheming';
import { usePersistentProjectInvite } from './usePersistentProjectInvite';

export const useResolvedProjectInvite = () => {
  const cacheSurveyTheming = useCacheProjectInviteTheming();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  if (state) return state;

  const params = qs.base64Decode<ProjectLinkURLState | null>();

  if (params?.token) {
    urlstate.set(params);

    if (params.data?.theming) {
      cacheSurveyTheming({
        metadata: params.data.metadata,
        project: params.data.project,
        theming: params.data.theming,
      });
    }

    return params;
  }

  return null;
};