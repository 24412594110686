import { Fragment, useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import type { SurveyQuestionType } from '@enums';
import { formatNumber } from '@utils';
import { ProjectVersionHeaderRow, MatrixSliderValueRow, ResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import type { SurveyQuestionMatrixRow } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { useFilteredRows, useProjectVersion } from './hooks';
import styles from './style/Data.css';

export const MatrixSliderVersionData = () => {
  const { query, setQueryState, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.MatrixSlider>();
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.Sliders>();

  const version = useProjectVersion(query.projectId);
  const rows = useFilteredRows();

  const versionData = useMemo(() => {
    return historical[query.projectId].data;
  }, [
    historical,
    query.projectId,
  ]);

  const handleClick = useCallback((rowId: number) => (key: string) => {
    setQueryState({
      key,
      projectId: query.projectId,
      rowIds: [rowId],
    });
  }, [
    query,
    setQueryState,
  ]);

  const renderBuckets = useCallback((row: SurveyQuestionMatrixRow, data: chart.matrixSlider.BucketData) => {
    return data.items.map(b => {
      const key = chart.matrixSlider.formatBucketKey(b);
      const values = data.map[key];

      if (!values.length) return null;

      return (
        <MatrixSliderValueRow
          key={key}
          count={values.length}
          onClick={() => handleClick(row.base.id)(key)}
          value={chart.matrixSlider.formatBucketDisplay(b)} />
      );
    }).filter(Boolean);
  }, [
    handleClick,
  ]);

  const renderValues = useCallback((row: SurveyQuestionMatrixRow, data: chart.matrixSlider.ValueData) => {
    return data.items.map(b => {
      const values = data.map[b];

      if (!values.length) return null;

      return (
        <MatrixSliderValueRow
          key={b}
          count={values.length}
          onClick={() => handleClick(row.base.id)(b.toString())}
          value={b.toString()} />
      );
    }).filter(Boolean);
  }, [
    handleClick,
  ]);

  const renderRowData = useCallback((row: SurveyQuestionMatrixRow) => {
    const rowData = versionData.rows[row.base.id];
    const subtitle = `(Avg: ${formatNumber(rowData.avg, 1)})`;

    const data = chart.matrixSlider.getRowData(question, rowData.responses);

    const value = parseSurveyRichText(row.value);

    return (
      <Fragment key={row.base.id}>
        <ResponseOptionRow
          color={chart.options.OptionsColors[row.ordinal - 1]}
          subtitle={subtitle}
          label={value} />
        {data.useBuckets
          ? renderBuckets(row, data)
          : renderValues(row, data as chart.matrixSlider.ValueData)
        }
      </Fragment>
    );
  }, [
    question,
    renderBuckets,
    renderValues,
    versionData,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      {rows.map(renderRowData)}
    </>
  );
};

export default MatrixSliderVersionData;