import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useAppReadyState } from '@containers/AppReadyState';
import { PostArticleAdditionalDetailsContext } from './Context';

type Props = {
  children: React.ReactNode;
  postId:   number;
};

export const PostArticleContainer = (props: Props) => {
  const app = useAppReadyState();

  const comments = useQuery({ queryKey: [QK.Posts.Articles.Comments.Get, props.postId], queryFn: () => {
    return api.posts.articles.fetchComments({
      postId: props.postId,
    });
  }, enabled: app.authenticated && !!props.postId });

  const context = {
    comments: {
      enabled: app.authenticated,
      query: comments,
    },
    postId: props.postId,
  };

  return (
    <PostArticleAdditionalDetailsContext.Provider value={context}>
      {props.children}
    </PostArticleAdditionalDetailsContext.Provider>
  );
};

PostArticleContainer.displayName = 'Post.Article.Container';