import { useMemo } from 'react';
import { useProjectFormContext } from '@screens/ProjectCreation/context';
import type { AutoFocusRefObject, ProjectCreationScreen } from '../interfaces';
import { ProjectTypeSteps, ProjectCreationStepsMap } from '../steps';

export const useProjectCreationSteps = (): ProjectCreationScreen<AutoFocusRefObject>[] => {

  const [form] = useProjectFormContext();

  return useMemo(() => {
    return ProjectTypeSteps[form.projectType]
      .map(m => ProjectCreationStepsMap[m]);
  }, [
    form.projectType,
  ]);
};