import { useCallback, useMemo } from 'react';
import type { OnSelectChange } from '$admin/components/Select';
import { Select } from '$admin/components/Select';

type Props = {
  className?: string;
  numberOfItems: number;
  onChange:   (value: number) => unknown;
  value: number;
};

const EmptyValue = 'none';
const MinRankings = 3;

type Options = {
  [v: string]: string;
};

export const RequiredRankings = ({
  className,
  numberOfItems,
  onChange,
  value,
}: Props) => {
  const options = useMemo(() => {

    const optionValues = new Array(numberOfItems - 1)
      .fill(null)
      .map((_, i) => i + 2);

    return {
      [EmptyValue]: '',
      ...optionValues.reduce((acc, x) => ({
        ...acc,
        [x.toString()]: x,
      }), {} as Options),
    };
  }, [numberOfItems]);

  const handleChange: OnSelectChange<string> = useCallback(e => {
    const v = e.target.value === EmptyValue
      ? null
      : +e.target.value;

    return onChange(v);
  }, [onChange]);

  const selectValue = useMemo(() => {
    if (!value) return EmptyValue;

    return value.toString();
  }, [value]);

  return (
    <Select
      className={className}
      defaultValue={EmptyValue}
      id="required-rankings"
      name="required-rankings"
      onChange={handleChange}
      options={options}
      value={selectValue} />
  );
};

export default RequiredRankings;