import type { ComponentType } from 'react';
import { SurveyTemplateEntityType } from '@enums';
import { TemplateCategory } from './Label.Category';
import { TemplateCompetitor } from './Label.Competitor';
import { TemplateEvaluationCriteria, TemplatePartnershipCriteria } from './Label.Criteria';
import { TemplateDrug } from './Label.Drug';
import { TemplateDrugIndication } from './Label.DrugIndication';
import { TemplateEfficacyEndpoint } from './Label.EfficacyEndpoint';
import { TemplateLaunchYear } from './Label.LaunchYear';
import { TemplateMedicalCondition } from './Label.MedicalCondition';
import { TemplateOtherAttribute } from './Label.OtherAttribute';
import { TemplateQualityOfLife } from './Label.QualityOfLife';
import { TemplateSafetyEndpoint } from './Label.SafetyEndpoint';
import { TemplateTarget } from './Label.Target';
import { TemplateTargetRole } from './Label.TargetRole';
import { TemplateTrialDesign } from './Label.TrialDesign';
import { TemplatePricingComparator, TemplateTreatmentComparator } from './Label.Comparator';
import { TemplateDrugPrice } from './Label.DrugPrice';
import { TemplateDoseAdministration } from './Label.DoseAdministration';
import { TemplateMedicalSpecialty } from './Label.MedicalSpecialty';
import { TemplateMedicalConditionMT } from './Label.MedicalCondition.MessageTesting';
import { TemplateCategoryMessage } from './Label.CategoryMessage';
import { TemplateAttitudinal } from './Label.Attitudinal';

type LabelComponentProps = {
  disabled?: boolean;
  value: string;
};

type LabelComponent = ComponentType<LabelComponentProps>;

const map: Record<SurveyTemplateEntityType, LabelComponent> = {
  [SurveyTemplateEntityType.Category]: TemplateCategory,
  [SurveyTemplateEntityType.Competitor]: TemplateCompetitor,
  [SurveyTemplateEntityType.DoseAdministration]: TemplateDoseAdministration,
  [SurveyTemplateEntityType.EvaluationCriteria]: TemplateEvaluationCriteria,
  [SurveyTemplateEntityType.PartnershipCriteria]: TemplatePartnershipCriteria,
  [SurveyTemplateEntityType.DrugIndication]: TemplateDrugIndication,
  [SurveyTemplateEntityType.PrimaryEfficacyEndpoint]: TemplateEfficacyEndpoint,
  [SurveyTemplateEntityType.CoPrimaryEfficacyEndpoint]: TemplateEfficacyEndpoint,
  [SurveyTemplateEntityType.SecondaryEfficacyEndpoint]: TemplateEfficacyEndpoint,
  [SurveyTemplateEntityType.OtherAttribute]: TemplateOtherAttribute,
  [SurveyTemplateEntityType.QualityOfLife]: TemplateQualityOfLife,
  [SurveyTemplateEntityType.SafetyEndpoint]: TemplateSafetyEndpoint,
  [SurveyTemplateEntityType.Target]: TemplateTarget,
  [SurveyTemplateEntityType.TargetRole]: TemplateTargetRole,
  [SurveyTemplateEntityType.TrialDesign]: TemplateTrialDesign,
  [SurveyTemplateEntityType.PricingComparator]: TemplatePricingComparator,
  [SurveyTemplateEntityType.TreatmentComparator]: TemplateTreatmentComparator,
  [SurveyTemplateEntityType.DrugPrice]: TemplateDrugPrice,

  [SurveyTemplateEntityType.MedicalCondition]: TemplateMedicalConditionMT,
  [SurveyTemplateEntityType.MedicalSpecialty]: TemplateMedicalSpecialty,
  [SurveyTemplateEntityType.CategoryMessage]: TemplateCategoryMessage,
  [SurveyTemplateEntityType.Attitudinal]: TemplateAttitudinal,
};

export function getTemplateEntityLabelComponent(type: SurveyTemplateEntityType): LabelComponent {
  const component = map[type];

  if (!component) {
    throw new Error(`Missing label component for ${type} template entity type`);
  }

  return component;
}