import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';

type Props = {
  chatInstanceId: number;
  onSuccess?: () => void;
};

export const useDeleteChatInstanceMutation = ({ chatInstanceId, onSuccess }: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation({ mutationKey: ['chat:delete'], mutationFn: () => {
    return $api.deleteChatInstance({
      chatInstanceId,
    });
  }, onSuccess: res => {
    onSuccess?.();
    dispatch({
      type: 'chat-instance-removed',
      payload: {
        chatInstance: {
          id: chatInstanceId,
        },
      },
    });
  } });

  return mutation;
};