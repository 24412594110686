import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { usePastSearches } from '@containers/WorkspaceGlobalSearch/hooks/usePastSearches';
import { Workspaces } from '@consts/path';
import { HeroSearchBar } from '@presentation/HeroSearchBar';
import { qs, hash64 } from '@utils';
import type { WorkspaceGlobalSearchBarResultKeyword } from '@/types';
import { WorkspaceGlobalSearchBarPopupContext, WorkspaceGlobalSearchBarQueryContext, WorkspaceGlobalSearchKeyboardContext, WorkspaceGlobalSearchBarResetContext } from './Context';

export const Form = () => {
  const history = useHistory();
  const popup = useContext(WorkspaceGlobalSearchBarPopupContext);
  const { query, setQuery, barRefs } = useContext(WorkspaceGlobalSearchBarQueryContext);
  const { index } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const { clickedResultReset } = useContext(WorkspaceGlobalSearchBarResetContext);
  const { addPast } = usePastSearches();

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (index !== -1) return;

    if (query.length) {
      const item = keywordItem(query);
      addPast({
        query,
        entityId: item.id,
        item,
      });
      history.push(`/${item.path}`);
    }

    clickedResultReset();
  }, [index, query, clickedResultReset, addPast, history]);

  const handleQueryFocus = useCallback(() => {
    if (!popup.isOpen) {
      popup.setOpen(true);
    }
  }, [popup]);

  const handleQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!popup.isOpen) {
      popup.setOpen(true);
    }
    setQuery(e.target.value);
  }, [setQuery, popup]);

  return (
    <HeroSearchBar
      ref={barRefs}
      query={query}
      onChange={handleQueryChange}
      onFocus={handleQueryFocus}
      onSubmit={handleSubmit} />
  );
};

function keywordItem(query: string): WorkspaceGlobalSearchBarResultKeyword {
  return {
    id: hash64(`keyword:${query}`),
    name: query,
    path: `${Workspaces.Search.substring(1)}?${qs.stringify({ keywords: [query] })}`,
    type: 'keyword',
  };
}

export default Form;