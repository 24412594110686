import { memo } from 'react';
import QuotedText from '@presentation/QuotedText';
import { Rationale } from './Rationale';
import type { TextAnswerValueProps } from './interfaces';

export const TextAnswerValue = memo(({ answer, rationale }: TextAnswerValueProps) => {
  return (
    <>
      <QuotedText>{answer.value}</QuotedText>
      <Rationale value={rationale} />
    </>
  );
});

export default TextAnswerValue;