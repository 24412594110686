import { useMutation } from '@tanstack/react-query';
import { saveProjectSurveyResponse } from '@api/surveys.capture-sheet';
import type { Surveys } from '@api/interfaces/surveys';

type Props = ICallId & ISurveyId;

export const useSaveSurveyMutation = ({ surveyId, callId }: Props) => {
  return useMutation({ mutationKey: ['capture-sheet-save-survey-response', surveyId, callId], mutationFn: (data: Surveys.CaptureSheets.SaveProjectSurveyResponse.Request['data']) => {
    return saveProjectSurveyResponse({
      data,
      callId,
    });
  } });
};