import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';

type Params = Omit<API.Projects.FetchProjectMembersExport.Request, 'export'>;

export const useDownloadProjectMembersData = (params: Params, options: UseDownloaderOptions<API.Projects.FetchProjectMembersExport.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:admin/projects/members`, {
        export: true,
        ...params,
      },
    ],
    queryFn: () => {
      return api.projects.members.fetchMembersExport({
        export: true,
        name: params.name,
        projectId: params.projectId,
        statusIds: params.statusIds,
        surveyProgressStatus: params.surveyProgressStatus,
      });
    },
    ...options,
    meta: {
      ...options.meta,
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });
};