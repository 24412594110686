import { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { SurveyActionType } from '@enums';
import { response as $response } from '@api/surveys';
import type { Surveys } from '@api/interfaces/surveys';
import type { RespondentAnswer } from '@/types';
import type {
  SubmitOverrides } from '../Context';
import {
  useSaveCompletedSurveyResponse,
  useSaveDisqualifiedSurveyResponse,
  useSurveyFormAnswerRationale,
  useSurveyFormApi,
  useSurveyFormQuestionAnswer,
  useSurveyFormScreenContext,
  useSurveyFormVersionId,
  useSurveyResponseIdentifier,
} from '../Context';
import { useSurveyFormQuestionCompleteEvent } from './useSurveyFormQuestionCompleteEvent';

const disqualifyingTypes = [
  SurveyActionType.Disqualification,
  SurveyActionType.QuotaOverLimit,
];

export const useSubmitAnswer = () => {

  const questionCompleteEvent = useSurveyFormQuestionCompleteEvent();
  const saveCompletedResponse = useSaveCompletedSurveyResponse();
  const saveDisqualifiedResponse = useSaveDisqualifiedSurveyResponse();

  const responseIdentifier = useSurveyResponseIdentifier();
  const surveyVersionId = useSurveyFormVersionId();
  const [answer] = useSurveyFormQuestionAnswer();
  const [rationale] = useSurveyFormAnswerRationale();
  const [state, setState] = useSurveyFormScreenContext();

  const submitAnswer = useSubmitAnswerApi();

  const handleSuccess = useCallback(({ action, state }: Surveys.Response.SubmitAnswer.Response) => {
    if (disqualifyingTypes.includes(action.type)) {
      saveDisqualifiedResponse(action.type);
    } else if (action.type === SurveyActionType.SkipToEnd) {
      saveCompletedResponse();
    } else {
      if (!state.item.id) {
        saveCompletedResponse();
      } else {
        setState(state);
      }
    }
  }, [
    saveCompletedResponse,
    saveDisqualifiedResponse,
    setState,
  ]);

  const {
    mutateAsync,
    ...mutation
  } = useMutation({ mutationKey: ['survey-response.submit-answer'], mutationFn: (options?: SubmitOverrides) => {
    return submitAnswer({
      surveyVersionItemId: state.item.id,
      surveyVersionId,
      rationale,
      responseIdentifier,
      value: options.answer ? {
        ...answer,
        ...options.answer,
      } as RespondentAnswer : answer,
      itemId: options.itemId,
    });
  }, onSuccess: handleSuccess });

  const handleSubmit = useCallback((answer: SubmitOverrides = {}) => {
    questionCompleteEvent();

    return mutateAsync(answer);
  }, [
    mutateAsync,
    questionCompleteEvent,
  ]);

  return {
    ...mutation,
    mutateAsync: handleSubmit,
  };

};

function useSubmitAnswerApi() {
  const handlers = useSurveyFormApi();

  return useMemo(() => handlers?.submitAnswer || $response.submitAnswer, [handlers?.submitAnswer]);
}

export default useSubmitAnswer;