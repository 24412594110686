import { useCallback, useContext } from 'react';
import { trunc } from '@utils';
import { Breadcrumbs } from '@presentation/Breadcrumbs';
import type { WorkspaceObject } from '@/types/workspace';
import { PopperMenuItem } from '@/components/Popper';
import { ObjectPathPopper } from '@/components/Workspace/ObjectPathPopper';
import { SetFilesTableFiltersContext } from '@/components/Workspace.FilesTable';
import styles from './style/Breadcrumbs.css';

type Props = {
  items: WorkspaceObject[];
};

const LevelsToShow = 1;

export const ProjectFilesBreadcrumbs = ({ items }: Props) => {

  const setFilters = useContext(SetFilesTableFiltersContext);

  const handleItemClicked = useCallback((item: WorkspaceObject) => () => {
    setFilters({
      parentObjectId: item.id,
    });
  }, [setFilters]);

  const renderEllipsesItem = useCallback((item: WorkspaceObject) => {
    return (
      <PopperMenuItem onClick={handleItemClicked(item)}>
        {trunc(item.name, 50)}
      </PopperMenuItem>
    );
  }, [handleItemClicked]);

  const renderFilesEllipses = useCallback(() => {
    const onClick = () => {
      setFilters({ parentObjectId: undefined });
    };

    return (
      <div className={styles.back} onClick={onClick}>
        Root
      </div>
    );
  }, [setFilters]);

  const renderEllipses = useCallback(() => {
    if (items.length < 2) return renderFilesEllipses();

    const ellipsesItems = items.slice(0, items.length - LevelsToShow);

    return (
      <ObjectPathPopper
        items={ellipsesItems}
        renderItem={renderEllipsesItem} />
    );
  }, [items, renderEllipsesItem, renderFilesEllipses]);

  const renderItem = useCallback((item: WorkspaceObject) => {
    return (
      <div key={item.id} className={styles.folder}>
        {item.name}
      </div>
    );
  }, []);

  const renderItems = useCallback(() => {
    const itemsToShow = items.slice(items.length - LevelsToShow, items.length);

    return itemsToShow.map(renderItem);
  }, [items, renderItem]);

  return (
    <Breadcrumbs>
      {renderEllipses()}
      {renderItems()}
    </Breadcrumbs>
  );
};