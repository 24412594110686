import { useMemo } from 'react';
import type { NumberInputTableQuestion } from '@/types/survey';
import { SurveyQuestionFormOption } from '@/types/survey';
import { useNumberInputTableRowValue, useNumberTableAnswerMap, useNumberTableColumnTotals, useNumberTableWarnings } from '@containers/SurveyForm';
import { roundToPlace } from '@utils';
import { Input } from '@/components/Input';
import { useSurveyRichTextEditor, SurveyRichTextEditorContainer, SurveyRichTextEditor } from '@/components/Survey.RichText';
import NumberInputTableInput from './NumberInputTable.Input';
import styles from './style/NumberInputTable.List.css';

type Props = {
  option: NumberInputTableQuestion.FormOption;
  onChange: (rowId: number, optionId: number) => (value: number) => void;
  row: NumberInputTableQuestion.FormRow;
  settings: NumberInputTableQuestion.Settings<number>;
};

const NumberInputTableListRowInput = ({
  onChange,
  option,
  row,
  settings,
}: Props) => {

  const warnings = useNumberTableWarnings();
  const [rowValue, setRowValue] = useNumberInputTableRowValue(row.id);

  const answerMap = useNumberTableAnswerMap();
  const totalsMap = useNumberTableColumnTotals();

  const value = answerMap[row.id][option.id];
  const isInvalid = warnings.cell[row.id][option.id];

  const pctTotal = useMemo(() => {

    const total = settings.total.comparate
      ? settings.total.comparate
      : totalsMap[(row.id, option.id)];

    if (total === 0 || value === 0) return 0;

    const pct = (value || 0) / total * 100;

    return roundToPlace(pct, 1);
  }, [row.id, option.id, settings.total, totalsMap, value]);

  return (
    <div
      className={styles.row}>
      <div className={styles.name}>
        <div>
          <SurveyRichTextEditor />
          {row.metadata.isOpenEnded &&
            <Input
              onChange={e => setRowValue(e.target.value)}
              value={rowValue} />}
        </div>
      </div>
      <div className={styles.cell}>
        <div className={styles.input}>
          <NumberInputTableInput
            disabled={option.metadata.isReadOnly}
            id={`nti-${row.id}-${option.id}`}
            settings={settings}
            invalid={isInvalid}
            onChange={onChange(row.id, option.id)}
            value={value} />
        </div>
        {/* {settings.displayCellPct &&
          <div className={styles.pct}>
            {pctTotal}%
          </div>
        } */}
      </div>
    </div>
  );
};

const Container = (props: Props) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.row.value,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <NumberInputTableListRowInput {...props} />
    </SurveyRichTextEditorContainer>
  );
};

export { Container as NumberInputTableListRowInput };
export default Container;