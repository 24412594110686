import { useCallback, useMemo } from 'react';
import type { RespondentProps } from '@/types';
import { OpenedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import type { ConjointAnalysisQuestion } from '@/types';
import ResponseOptionUser from './ResponseOptionUser';
import Respondent from './ConjointAnalysis.Data.Level.User';

type Props = {
  color: string;
  level: ConjointAnalysisQuestion.Level;
  data: ConjointAnalysisQuestion.Aggregate.LevelResults;
  onClose: () => unknown;
};

export const ConjointAnalysisLevel = ({
  color,
  data,
  level,
  onClose,
}: Props) => {

  const sortedUserIds = useMemo(() => {
    const userIds = data.responses.userIds;

    return userIds.sort((a, b) => {
      return data.responses.values[b] -
      data.responses.values[a];
    });
  }, [
    data,
  ]);

  const renderUser = useCallback((item: RespondentProps) => {
    const count = data.responses.values[item.user.id];
    return (
      <Respondent
        {...item}
        count={count} />
    );
  }, [
    data.responses.values,
  ]);

  return (
    <>
      <OpenedResponseOptionRow
        color={color}
        label={level.value}
        onClose={onClose}
        subtitle={`(${data.utility})`} />
      {
        sortedUserIds.map(userId => (
          <ResponseOptionUser
            key={userId}
            renderItem={renderUser}
            userId={userId} />
        ))
      }
    </>
  );
};

export default ConjointAnalysisLevel;