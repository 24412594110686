import { useMemo } from 'react';
import { cx, formatPlurality } from '@utils';
import Close from './Close';
import ViewAll from './ViewAll';
import styles from './style/MaxDiff.Choice.css';

export function MaxDiffChoiceOpen({ label, value, onClick }: Props) {
  const displayValue = useMemo(() => `(${value} ${formatPlurality('time', value)})`, [value]);

  return (
    <div className={cx(styles.root, styles.open)}>
      <div className={styles.label}>
        <span className={styles.labelValue}>{label}</span>
        <span className={styles.value}>{displayValue}</span>
      </div>
      <Close onClick={onClick} />
    </div>
  );
}

export function MaxDiffChoiceClosed({ label, value, onClick }: Props) {
  const displayValue = useMemo(() => `(${value} ${formatPlurality('time', value)})`, [value]);

  return (
    <div className={cx(styles.root, styles.closed)} onClick={onClick}>
      <div className={styles.label}>
        <span className={styles.labelValue}>{label}</span>
        <span className={styles.value}>{displayValue}</span>
      </div>
      <ViewAll
        onClick={onClick}
        title="View All Respondents" />
    </div>
  );
}

type Props = {
  label: string;
  value: number;
  onClick: () => unknown;
};
