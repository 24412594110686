import { Fragment } from 'react';
import { UserProjectStatus } from '@enums';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  const text = isInvited(props.row.original.respondent.statusId)
    ? `Pending Expert`
    : isStaging(props.row.original.respondent.statusId)
      ? `Awaiting Client`
      : null;

  return (
    <Fragment>
      {text}
    </Fragment>
  );
};

Cell.displayName = 'Column.Note.Cell';

function isInvited(statusId: UserProjectStatus) {
  return [
    UserProjectStatus.Invited,
  ].includes(statusId);
}

function isStaging(statusId: UserProjectStatus) {
  return [
    UserProjectStatus.Added,
    UserProjectStatus.Staging,
  ].includes(statusId);
}