import type { ComponentType } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyFormTemplateData } from '@containers/SurveyForm/hooks';
import { useSurveyBuilderState, useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplateType } from '@/enums';
import { ResolvedTPP } from '@/components/Survey.Templates/TPPTable';

export const TPPNode: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />
  ;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const [data] = useTargetProductProfileData();

  return (
    <ResolvedTPP data={data} />
  );
}

function Node({ node }: NodeViewComponentProps) {

  const data = useSurveyFormTemplateData<SurveyTemplateType.TargetProductProfile>();

  return (
    <ResolvedTPP data={data} />
  );
}