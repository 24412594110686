import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import type { HandleSurveySubmittedParams } from '../interfaces';

export const useSubmitComplianceSurvey = (projectId: number) => {
  const dispatch = useDispatch();
  const user = useSelectUser();

  const submitComplianceSurvey = (data: HandleSurveySubmittedParams) => {
    return api.projects.surveys.saveComplianceSurveyResponse({
      surveyVersionId: data.surveyVersionId,
      responseIdentifier: data.responseIdentifier,
      projectId,
      userId: user.id,
    })
    .then(data => {
      const { pipeline, ...updates } = data;
      dispatch(actions.batchActions([
        actions.projectUpdated({
          project: updates,
          projectId,
        }),
        actions.projectPipelineUpdated({
          pipeline,
          projectId,
        }),
      ]));
    });
  };

  return submitComplianceSurvey;
};

export default useSubmitComplianceSurvey;