import { useCallback, useState } from 'react';
import type { SurveyTemplate } from '@/types/survey';
import { useMessageTestingData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';

export const useCanSaveQualificationVariable = (value: SurveyTemplate.MessageTesting.Qualification) => {

  return !!value;
};

export const useTemplateQualificationVariable = () => {
  const [templateData] = useMessageTestingData();
  const [value, setValue] = useState(templateData.qualification);
  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-mt-qualification-updated',
      payload: { value },
    });
  }, [
    value,
    dispatch,
  ]);

  return {
    onChange: setValue,
    onSubmit,
    value,
  };
};