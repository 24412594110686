import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { Edit, Query, ProjectSurveys, SurveyStepperContextValue } from './interfaces';

export const EditContext = createNamedContext<Edit.Context>(undefined, 'Project.Survey.Edit.Context');
export const QueryContext = createNamedContext<Query.Context>(undefined, 'Project.Survey.Query.Context');

export const ProjectSurveysContext = createNamedContext<ProjectSurveys.ContextValue>(undefined, 'Project.Surveys.Context');

export const SurveyStepperContext = createNamedContext<SurveyStepperContextValue>(null, 'SurveyStepper.Context');

export const useSurveyStepperContext = () => useContext(SurveyStepperContext);