import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import * as $api from '@api';

type Props = ICallId & ISurveyVersionId;

export const useSurveyPreviewQuery = ({ callId, surveyVersionId }: Props) => {
  return useQuery({ queryKey: ['capture-sheet-get-project-survey-form-preview', callId, surveyVersionId], queryFn: () => {
    return $api.surveys.captureSheet.getSurveyFormPreview({
      callId,
      surveyVersionId,
    });
  }, enabled: !!surveyVersionId, retry: 1, staleTime: Infinity, onError: err => Sentry.captureException(err) });
};