import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useCreateChartPaletteMutation, useUpdateChartPaletteMutation } from '@containers/Branding/hooks';
import type { Branding } from '@/types';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal as RootModal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { PaletteEditorContext, ThemeGroupContext } from './Context';
import { Palette } from './Palette';
import { Input } from './Editor.Input';
import { ButtonSet } from './Editor.ButtonSet';
import styles from './style/Editor.css';

type Props =
  & Pick<Branding.CategoryTheme, 'categoryId'>
  & Pick<ModalProps, 'open' | 'onClose'>;

export const Modal = (props: Props) => {
  const ctx = {
    category: useContext(ThemeGroupContext),
    palette: useContext(PaletteEditorContext),
  };

  const create = useCreateChartPaletteMutation({
    onSuccess: data => {
      props.onClose();
    },
    onError: e => {
      console.log('error', e);
    },
  });

  const update = useUpdateChartPaletteMutation({
    onSuccess: () => {
      props.onClose();
    },
    onError: e => {
      console.log('error', e);
    },
  });

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    ctx.palette.setName(e.target.value);
  }, [ctx.palette]);

  const handleCreate = useCallback(() => {
    create.mutate({
      name: ctx.palette.name,
      categoryId: props.categoryId,
      data: {
        colors: ctx.palette.colors,
      },
    });
  }, [
    create,
    ctx.palette.colors,
    ctx.palette.name,
    props.categoryId,
  ]);

  const handleUpdate = useCallback(() => {
    update.mutate({
      name: ctx.palette.name,
      data: {
        colors: ctx.palette.colors,
      },
      paletteId: ctx.category.editingId,
    });
  }, [
    ctx.category.editingId,
    ctx.palette.colors,
    ctx.palette.name,
    update,
  ]);

  const handleSubmit = useCallback(() => {
    ctx.category.editingId
      ? handleUpdate()
      : handleCreate();
  }, [
    ctx.category.editingId,
    handleCreate,
    handleUpdate,
  ]);

  const disabled = useMemo(() => {
    return ctx.palette.colors.length < ctx.palette.limit.min
        || !ctx.palette.name.trim().length;
  }, [
    ctx.palette.colors,
    ctx.palette.limit,
    ctx.palette.name,
  ]);

  return (
    <RootModal
      classes={{
        root: styles.root,
        wrap: styles.wrap,
      }}
      disableEscapeClose={!disabled}
      disableOverlayClick={!disabled}
      hideCloseIcon
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.wrap}>
        <Header>
          Edit Template
        </Header>

        <div className={styles.main}>
          <div className={styles.input}>
            <Input
              onChange={handleChangeName}
              value={ctx.palette.name} />
          </div>
          <div className={styles.palette}>
            <div className={styles.scroll}>
              <PaletteEditor />
            </div>
          </div>
          <ButtonSet
            className={styles.btns}
            disabled={disabled}
            loading={create.isLoading || update.isLoading}
            onCancel={props.onClose}
            onSubmit={handleSubmit} />
        </div>
      </div>
    </RootModal>
  );
};

Modal.displayName = 'ColorPalette.Modal';

const PaletteEditor = (props: unknown) => {
  const ctx = {
    category: useContext(ThemeGroupContext),
    palette: useContext(PaletteEditorContext),
  };

  const activePalette = useMemo(() => {
    if (!ctx.category.editingId) return DefaultEditState;

    const palette = ctx.category.themes.find(x => x.editable && x.id === ctx.category.editingId);

    return {
      colors: palette?.colors,
      name: palette?.name,
    };
  }, [
    ctx.category.editingId,
    ctx.category.themes,
  ]);

  useEffect(() => {

    ctx.palette.init(activePalette);

    return () => {
      ctx.palette.reset();
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activePalette]);

  return (
    <Palette
      colors={ctx.palette.colors}
      max={ctx.palette.limit.max}
      min={ctx.palette.limit.min}
      onChange={ctx.palette.setColors} />
  );
};

const InitialColors = [
  '#4D7FB3',
  '#DE674F',
  '#47C5C5',
  '#6784CC',
  '#B7D97C',
];

const DefaultEditState = {
  colors: InitialColors,
  name: '',
};