import { useLayoutEffect, useMemo, useRef } from 'react';
import { Breakpoint, Carousel, MaxHeight } from '@/components/Discovery';
import { useCarouselContext, useCarouselRowStyle, useVideoRowItemDimensions } from '@/components/Discovery/hooks';
import * as consts from '@/consts';
import type { Discovery } from '@/types';
import { cx, useDimensions } from '@/utils';
import { Item } from './Category.Item';
import styles from './style/Category.css';

type Props = {
  classes?: {
    items?: string;
    root?:  string;
  };
  name:       string;
  slug:       string;
} & Discovery.PopularVideoTopic;

const View = (props: Props) => {
  const windowDimensions = useDimensions();
  const carousel = useCarouselContext<Discovery.VideoTopicPost>();
  const anchorSize = useRef(0);

  const buttons = useMemo(() => {
    if (!carousel.canGoBack && !carousel.canGoNext) return null;

    return (
      <Carousel.ButtonSet
        className={styles.buttons}
        back={carousel.back}
        canGoBack={carousel.canGoBack}
        canGoNext={carousel.canGoNext}
        next={carousel.next} />
    );
  }, [
    carousel.back,
    carousel.canGoBack,
    carousel.canGoNext,
    carousel.next,
  ]);

  const [ref, dimensions, calc] = useVideoRowItemDimensions();

  useLayoutEffect(() => {
    calc({ size: carousel.itemSize });

    anchorSize.current = windowDimensions.width >= Breakpoint.Desktop && dimensions.height > MaxHeight.DesktopThumbnail
      ? MaxHeight.DesktopThumbnail
      : dimensions.height;

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    carousel.itemSize,
    dimensions.height,
    windowDimensions.width,
  ]);

  const row = useCarouselRowStyle(dimensions.width);

  const itemsStyle = useMemo(() => {
    return !carousel.canGoNext && carousel.position > 0
      ? { transform: `translateX(${row.translateX - anchorSize.current}px)`, width: row.width }
      : { transform: `translateX(${row.translateX}px)`, width: row.width };
  }, [
    carousel.canGoNext,
    carousel.position,
    row,
  ]);

  const itemsClasses = cx(
    styles.items,
    props.classes.items,
  );

  const more = !carousel.canGoNext && carousel.position > 0;

  return (
    <div className={cx(styles.row, props.classes.root)}>
      <div className={styles.header}>
        <div className={styles.category}>{props.name}</div>
        {buttons}
      </div>
      <Carousel.Slider ref={ref}>
        <div
          className={itemsClasses}
          style={itemsStyle}>
          {props.items.map(x =>
            <Item
              item={x}
              key={x.post.id}
              style={{ width: `${dimensions.width}px` }} />)}
        </div>
        {more &&
          <Carousel.SeeAll
            size={anchorSize.current}
            to={`${consts.path.Discovery.Topics.Root}/${props.slug}`} />}
      </Carousel.Slider>
    </div>
  );
};

export const Row = (props: Props) => {
  return (
    <Carousel.Container items={props.items}>
      <View {...props} />
    </Carousel.Container>
  );
};

const defaultProps = {
  classes: {},
};

Row.defaultProps = defaultProps;
Row.displayName = 'Category.Row';