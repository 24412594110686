import { useContext } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { cx, useToggle } from '@utils';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import * as Landscape from './Settings.Branding.Logo.Landscape';
import * as Square from './Settings.Branding.Logo.Square';
import styles from './style/Settings.Branding.Logo.Cropper.css';

type Props = Pick<ModalProps, 'onClose' | 'open'>;

export const Cropper = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const [on, toggle] = useToggle(false);

  const classes = {
    square: cx({ [styles.hidden]: on }),
    landscape: cx({ [styles.hidden]: !on }),
  };

  return (
    <Modal
      classes={{ root: styles.root }}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Header>Upload a logo</Header>
            <ViewSwitch
              on={on}
              toggle={toggle} />
            <div className={classes.square}>
              <Square.Cropper imageSrc={query.data?.logos?.square?.url} />
            </div>
            <div className={classes.landscape}>
              <Landscape.Cropper imageSrc={query.data?.logos?.landscape?.url} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Cropper.displayName = 'Settings.Branding.Logo.Cropper';

type ViewSwitchProps = {
  on:     boolean;
  toggle: () => void;
};

const ViewSwitch = ({ on, toggle }: ViewSwitchProps) => {

  const thumbclasses = cx({
    [styles.on]: on,
  }, styles.thumb);

  const classes = {
    off: cx(styles.control, { [styles.active]: !on }),
    on: cx(styles.control, { [styles.active]: on }),
  };

  return (
    <div className={styles.switch}>
      <div className={styles.btns}>
        <Button
          color="transparent"
          onClick={toggle}
          variant="link">
          <div className={styles.track}>
            <div className={classes.off}>
              Square
            </div>
            <div className={classes.on}>
              Horizontal
            </div>
          </div>
        </Button>
        <div className={thumbclasses} />
      </div>
    </div>
  );
};