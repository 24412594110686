import { useCallback } from 'react';
import type { InvalidContentHandler } from '@remirror/core';
import { useRemirror, ReactComponentExtension } from '@remirror/react';
import type { PlaceholderOptions } from 'remirror/extensions';
import { DocExtension, PlaceholderExtension } from 'remirror/extensions';
import type { SurveyLogicExpression } from '@/types/survey.logic.expression';
import { OpeningParenthesisExtension, ClosingParenthesisExtension } from '../Expression.Extension.Parentheses';
import { LogicalOperatorExtension } from '../Expression.Extension.Operator';
import { LogicConditionExtension } from '../Expression.Extension.Condition';

type Props = {
  initialState?: SurveyLogicExpression.RootNode;
  placeholder?: string;
};

export const useSurveyExpressionEditor = ({
  initialState,
  placeholder = 'Enter expression',
}: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Expression Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  const placeholderExtensionParams: PlaceholderOptions = {
    placeholder,
  };

  return useRemirror({
    content: initialState,
    extensions: () => [
      new DocExtension({}),
      new PlaceholderExtension(placeholderExtensionParams),
      new ReactComponentExtension({}),
      new OpeningParenthesisExtension({}),
      new ClosingParenthesisExtension({}),
      new LogicalOperatorExtension({}),
      new LogicConditionExtension({}),
    ],
    onError,
  });

};