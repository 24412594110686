import type { AxiosError } from 'axios';
import Axios from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api/universal-search';
import type { GetPast as IGetPast } from '@api/interfaces/universal-search';

type Data = IGetPast.Response;

export default function usePastUniversalSearch<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {
  const source = Axios.CancelToken.source();

  return useQuery({ queryKey: ['past-universal-searches'], queryFn: () => {
    const p = api.getPast();

    p.cancel = () => {
      source.cancel();
    };

    return p;
  }, staleTime: 60 * 1000, ...options });
}

export { usePastUniversalSearch };