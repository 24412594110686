import Select from './SelectBase';

type OnChangeParams = {
  id:    string;
  value: string;
};

type OptionData = {
  id:   number;
  name: string;
};

type Props = {
  className?:    string;
  id:            string;
  defaultValue?: string;
  onChange:      (data: OnChangeParams) => unknown;
  options:       string[];
  placeholder?:  string;
};

export const TextSelect = (props: Props) => {

  function getIndexOfInitialValue(val: string, items: string[]): number {
    const item = items.find(x => x.toLowerCase() === val.trim().toLowerCase());

    return items.indexOf(item);
  }

  function handleChange(data: OptionData) {
    props.onChange({
      id: props.id,
      value: data.name,
    });
  }

  const options = props.options.reduce<OptionData[]>((acc, x, i) => acc.concat({
    id: i,
    name: x,
  }), []);

  const index = getIndexOfInitialValue(props.defaultValue || '', props.options);

  return (
    <Select
      className={props.className}
      defaultValue={props.options[index]}
      getItemValue={x => x.name}
      options={options}
      onSelect={handleChange}
      placeholder={props.placeholder} />
  );
};