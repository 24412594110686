import axios from 'axios';
import type { Visitors } from './interfaces/visitors';

const http = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

export const contact = (data: Visitors.ContactRequest) => {
  return http.post(`/visitors/contact`, data);
};

export const createLead = (data: Visitors.CreateLeadRequest) => {
  return http.post(`/visitors/leads`, data);
};

export const demo = (data: Visitors.DemoRequest) => {
  return http.post(`/visitors/demo`, data);
};

export const verifyCaptcha = (data: Visitors.VerifyCaptchaRequest): Promise<Visitors.VerifyCaptchaResponse> => {
  return http.post<Visitors.VerifyCaptchaResponse>(`/visitors/verify`, data)
  .then(res => res.data);
};

export const ping = (data?: Visitors.Ping) => {
  return http.post(`/visitors/ping`, {
    url: data?.url ?? (window.location.pathname + window.location.search + window.location.hash),
  });
};

export const pricing = (data: Visitors.Pricing.Request) => {
  return http.post(`/visitors/pricing`, {
    company: data.company,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    payment: data.payment,
    phone: data.phone,
    plan: data.plan,
  });
};

export const snowflake = (data: Visitors.Snowflake.Request): Promise<Visitors.Snowflake.Response> => {
  return http.post<Visitors.Snowflake.Response>(`/visitors/snowflake`, data, {
    responseType: 'blob',
  }).then(res => res.data);
};

export const productResearchGuide = (data: Visitors.ProductResearchGuide.Request): Promise<Visitors.ProductResearchGuide.Response> => {
  return http.post<Visitors.ProductResearchGuide.Response>(`/visitors/product-research-guide`, data, {
    responseType: 'blob',
  }).then(res => res.data);
};

export const surveyBestPracticesGuide = (data: Visitors.SurveyBestPracticesGuide.Request): Promise<Visitors.SurveyBestPracticesGuide.Response> => {
  return http.post<Visitors.SurveyBestPracticesGuide.Response>(`/visitors/survey-best-practices-guide`, data, {
    responseType: 'blob',
  }).then(res => res.data);
};