import * as ContentFeed from '@containers/ContentFeed';
import { FeedItemType } from '@enums';
import { Card } from '@screens/Main.Member/Card';
import { cx } from '@utils';
import type { FeedItem } from '@/types';
import * as Feed from './Feed';
import { useTransitionClasses } from './hooks/useTransitionClasses';
import styles from './style/Feed.css';

type Props = {
  className?: string;
  item: FeedItem;
};

export const ContentFeedItem = ({ className, item }: Props) => {
  const classes = useTransitionClasses();

  if (item.contentType === FeedItemType.NewsArticle) {
    return (
      <Card
        className={classes}
        key={item.id}>
        <Feed.NewsArticle item={item} />
        <Feed.SupplementalActions item={item} />
      </Card>
    );
  } else if (item.contentType === FeedItemType.Post) {
    return (
      <ContentFeed.Post.DeleteContainer
        key={item.id}
        postId={item.id}>
        <Card
          className={classes}
          key={item.id}>
          <Feed.Post item={item} />
          <Feed.SupplementalActions item={item} />
        </Card>
      </ContentFeed.Post.DeleteContainer>
    );
  } else if (item.contentType === FeedItemType.SuggestedTopics) {
    return (
      <Card
        className={cx(styles.topics, classes)}
        key={item.id}>
        <Feed.SuggestedTopics items={item.items} />
      </Card>
    );
  } else if (item.contentType === FeedItemType.ArticlePost) {
    return (
      <Card
        className={classes}
        key={item.id}>
        <Feed.ArticlePost item={item} />
      </Card>
    );
  } else {
    //TODO: add some sort of logging break here?
    return null;
  }
};