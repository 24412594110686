import { useCallback, Fragment } from 'react';
import { cx } from '@utils';
import type { SurveyTemplate } from '@/types';
import styles from './style/TPPTable.css';

type Props = {
  data: SurveyTemplate.TargetProductProfile;
};

export const TPPTable = ({ data }: Props) => {

  const header = `${data.drug.value} Attributes`;

  const createRow = useCallback((title: string, value: string) => {
    return (
      <tr>
        <td className={styles.title}>{title}</td>
        <td>{value}</td>
      </tr>
    );
  }, []);

  const primaryEndpoint = data.efficacyEndpoints.primary[0];
  const coPrimaryEndpoint = data.efficacyEndpoints.primary[1];

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.header}>
          <th colSpan={2}>{header}</th>
        </tr>
      </thead>
      <tbody>
        {createRow(`Indication`, data.drugIndication)}
        {createRow(`Trial Design`, data.trialDesign)}

        {primaryEndpoint &&
          createRow(`Efficacy - 1ary EP`, primaryEndpoint.value)
        }
        {coPrimaryEndpoint &&
          createRow(`Efficacy - Co-1ary EP`, coPrimaryEndpoint.value)}

        {data.efficacyEndpoints.secondary.map(((m, i) => (
          <Fragment key={i}>
            {createRow(`Efficacy - 2ary EP`, m.value)}
          </Fragment>
        )))}

        {data.safetyEndpoints.map(((m, i) => (
          <Fragment key={i}>
            {createRow(`Safety/tolerability`, m.value)}
          </Fragment>
        )))}

        {data.qualityOfLife &&
          createRow(`Qol`, data.qualityOfLife)}

        {data.otherAttributes.map(((m, i) => (
          <Fragment key={i}>
            {createRow(`Other`, m.value)}
          </Fragment>
        )))}

        {data.doseAdministration.map(((m, i) => (
          <Fragment key={i}>
            {createRow(`Dosing & Administration`, m.value)}
          </Fragment>
        )))}
      </tbody>
    </table>
  );
};

export const ResolvedTPP = ({ data }: Props) => {

  if (data.tppUrl) {
    return (
      <img
        className={styles.img}
        src={data.tppUrl} />
    );
  }

  return (
    <TPPTable data={data} />
  );

};