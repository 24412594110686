import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useThemeOverride } from '@containers/Branding/hooks';

type Props =
  ChildrenProps;

const muiTheme = createTheme();

export const MuiThemeProvider = ({ children }: Props) => {

  const theme = useThemeOverride(muiTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MuiThemeProvider;