import type { Ref } from 'react';
import { forwardRef, useCallback, useContext, useMemo } from 'react';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { PostDeleteTogglePromptContext } from '@containers/Post/Context';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';

const ContextMenuItems = ({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {

  const toggleDeletePrompt = useContext(PostDeleteTogglePromptContext);

  const handleClick = useCallback((fn: () => unknown) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const remove = useMemo(() => {
    return {
      children: 'Delete',
      onClick: toggleDeletePrompt,
    };
  }, [toggleDeletePrompt]);

  const items = [
    remove,
  ];

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>))}
    </PopperMenu>
  );
};

ContextMenuItems.displayName = 'Post.ContextMenu.Items';

const MenuItems = forwardRef<HTMLDivElement, MenuProps>(ContextMenuItems);

export const ContextMenu = ({ placement = 'bottom-end', renderAnchor }: ActionsPopperProps) => {

  const defaultAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchorSmall open={state.isOpen} />
    );
  }, []);

  const popupState = usePopupState({
    popupId: 'content-feed-post-popper',
    variant: 'popper',
  });

  return (
    <div>
      <div {...bindToggle(popupState)}>
        {(renderAnchor || defaultAnchor)(popupState)}
      </div>
      <Popper
        {...bindPopper(popupState)}
        placement={placement}>
        <ClickAwayListener onClickAway={popupState.close}>
          <MenuItems onClose={popupState.close} />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

ContextMenu.displayName = 'Post.ContextMenu';

type MenuProps = {
  onClose: () => unknown;
};

type ActionsPopperProps = {
  renderAnchor?: (state: PopupStateProps) => React.ReactNode;
} & Pick<PopperProps, 'placement'>;