import type { AlertModalProps } from '@/components/Modal/Alert';
import Alert from '@/components/Modal/Alert';

type Props =
  Omit<AlertModalProps,
    'confirmText' | 'message'>;

export const DeleteAlternateImagesAlert = (props: Props) => (
  <Alert
    confirmText="Delete"
    message={(
      <>
        <div style={{
          fontFamily: 'var(--font-semibold)',
          fontSize: 18,
        }}>
          Are you sure you want to delete this exercise?
        </div>
      </>
    )}
    {...props} />
);

export default DeleteAlternateImagesAlert;