import { useCallback } from 'react';
import type {
  QueryParamConfig } from 'use-query-params';
import {
  useQueryParams, StringParam,
  NumberParam, NumericArrayParam, encodeString,
  decodeString,
} from 'use-query-params';
import type { MaxDifferenceQuestion } from '@/types';

const ChoiceParam: QueryParamConfig<MaxDifferenceQuestion.Choices, MaxDifferenceQuestion.Choices> = {
  encode: (choice: MaxDifferenceQuestion.Choices) => encodeString(choice),
  decode: (choice: string) => decodeString(choice) as MaxDifferenceQuestion.Choices,
};

export function useMatchRepeatSurveyStateFromUrl() {
  const [query, setQuery] = useQueryParams({
    optionIds: NumericArrayParam,
    projectId: NumberParam,
    questionId: NumberParam,
    surveyId: NumberParam,
    rank: NumberParam,
    rowIds: NumericArrayParam,
    rowId: NumberParam,
    key: StringParam,
    page: NumberParam,
    attributeIds: NumericArrayParam,
    levelId: NumberParam,
    choice: ChoiceParam,
  });

  const reset = useCallback(() => {
    setQuery({
      optionIds: undefined,
      projectId: undefined,
      surveyId: undefined,
      rank: undefined,
      rowIds: undefined,
      rowId: undefined,
      key: undefined,
      page: undefined,
      attributeIds: undefined,
      levelId: undefined,
      choice: undefined,
    });
  }, [setQuery]);

  return [query, setQuery, reset] as const;
}