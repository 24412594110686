import { useMemo } from 'react';
import * as survey from '@containers/Survey/utils';
import { cx } from '@utils';
import SurveyResponseAnswer from './Answer';
import SurveyResponseQuestionText from './QuestionText';
import type { EditableSurveyResponseProps, ProjectSurveyResponseItemProps, ProjectSurveyResponseProps } from './interfaces';
import EditableSurveyResponse from './EditableResponse';
import StarAnswerToggle from './StarAnswerToggle';
import styles from './style/Project.css';

export const ProjectSurveyResponseItem = ({ item }: ProjectSurveyResponseItemProps) => {

  const className = useMemo(() => cx(styles.question, {
    [styles.hidden]: survey.response.isAnswerHidden(item),
  }), [item]);

  return (
    <div className={className}>
      <SurveyResponseQuestionText
        item={item.question} />
      <div className={styles.answer}>
        <div className={styles.star}>
          <StarAnswerToggle
            questionId={item.question.id} />
        </div>
        <SurveyResponseAnswer
          className={styles.answerValue}
          item={item} />
      </div>
    </div>
  );
};

export const EditableProjectSurveyResponse = (props: Omit<EditableSurveyResponseProps, 'QuestionComponent'>) => {
  return (
    <EditableSurveyResponse
      {...props}
      QuestionComponent={ProjectSurveyResponseItem} />
  );
};

export const ProjectSurveyResponse = ({ response }: ProjectSurveyResponseProps) => {
  return (
    <>
      {response.items.map(m => (
        <ProjectSurveyResponseItem
          key={m.question.id}
          item={m} />
      ))}
    </>
  );
};