import { useContext } from 'react';
import { MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import type { ButtonProps, RouteNavigation } from '@screens/MedicalOnboarding.Background/interfaces';
import { Optional } from '@containers/MedicalOnboarding/utils';
import * as Field from '@/components/MedicalProfile/Field';
import * as Layout from '@/components/MedicalProfile/Layout';
import styles from './style/Specialty.css';

type Props = {
  button:     ButtonProps;
  navigation: RouteNavigation;
};

export const Specialty = (props: Props) => {
  const ctx = useContext(MedicalExpertiseContext);

  const isRequired = !Optional.specialtyGroup.includes(ctx.professionalRole);

  const primary = {
    group: {
      label: `Primary Specialty Group`,
      required: isRequired,
      value: ctx.specialty.primary.group,
    },
    subspecialty: {
      disabled: !ctx.specialty.primary.group?.id,
      label: `Primary Specialty`,
      required: isRequired,
      value: ctx.specialty.primary.subspecialty,
    },
  };

  const secondary = {
    group: {
      label: `Secondary Specialty Group (Board Certified or Eligible)`,
      value: ctx.specialty.secondary.group,
    },
    subspecialty: {
      disabled: !ctx.specialty.secondary.group?.id,
      label: `Secondary Specialty`,
      value: ctx.specialty.secondary.subspecialty,
    },
  };

  return (
    <Layout.Screen>
      <Layout.Header
        subtitle={copy.subtitle}
        title={copy.title} />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Field.Specialty
              classes={{ select: styles.select }}
              group={primary.group}
              onChange={primary => ctx.onChangeSpecialty({ primary, secondary: ctx.specialty.secondary })}
              name="primary"
              subspecialty={primary.subspecialty} />
            <div className={styles.divider} />
            <Field.Specialty
              classes={{ select: styles.select }}
              group={secondary.group}
              name="secondary"
              onChange={secondary => ctx.onChangeSpecialty({ primary: ctx.specialty.primary, secondary })}
              subspecialty={secondary.subspecialty} />
          </div>
        </div>
      </div>
      <Layout.Footer>
        <Layout.BackButton
          onClick={props.navigation.back} />
        <Layout.NextButton
          disabled={props.button.disabled}
          implicitDisable={false}
          loading={props.button.loading}
          onClick={props.navigation.onClick}
          to={props.navigation.to}>
          {props.button.text}
        </Layout.NextButton>
      </Layout.Footer>
    </Layout.Screen>
  );
};

Specialty.displayName = 'MedicalOnboarding.Expertise.Specialty';

const copy = {
  title: `Please indicate your medical specialty`,
  subtitle: `Select your medical specialties to filter projects for your professional experience.`,
};