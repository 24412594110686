import { useCallback, useContext } from 'react';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import type { Topics } from '@/types';

export const useToggleFollowing = () => {
  const ctx = useContext(FollowedTopicsContext);

  const handleToggleFollowing = (item: Topics.Base) => {
    if (ctx.topics.find(x => x.id === item.id)) {
      ctx.unfollow.mutate(item);
    } else {
      ctx.follow.mutate(item);
    }
  };

  return useCallback(handleToggleFollowing, [
    ctx.topics,
    ctx.follow,
    ctx.unfollow,
  ]);
};