import http, { transformers } from '@services/http';
import type { Users } from './interfaces';

export const deleteItem = (data: Users.DeleteResume.Request) => {
  return http.delete<Users.DeleteResume.Response>(`/admin/users/${data.userId}/resumes/${data.resumeId}`);
};

export const fetchItems = (data: Users.FetchResumes.Request) => {
  return http.get<Users.FetchResumes.Response>(`/admin/users/${data.userId}/resumes`, { transformResponse: transformers.transformDates });
};

export const fetchDownloadUrl = (data: Users.FetchResumeUrl.Request) => {
  return http.get<Users.FetchResumeUrl.Response>(`/admin/users/${data.userId}/resumes/${data.resumeId}`);
};

export const upload = (data: Users.UploadResume.Request) => {
  const form = new FormData();

  form.append('file', data.file);

  return http.post<Users.UploadResume.Response>(`/admin/users/${data.userId}/resumes`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};