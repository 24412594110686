import { useMemo, useCallback, useState } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { useSurveyAdvancedQuotaState } from '../Context';
import type { QuotaValidations } from '../interfaces';
import { validateAdvancedQuota } from '../validation';

export const useAdvancedQuotaValidations = () => {
  const [validations, setValidations] = useState<QuotaValidations>({
    action: { success: true },
    limit: { success: true },
    name: { success: true },
  });

  const [item] = useSurveyAdvancedQuotaState();
  const [state] = useSurveyBuilderState();

  const runValidations = useCallback(() => {
    const { success, ...rest } = validateAdvancedQuota(item, state.survey);

    setValidations(rest);
    return success;
  }, [item, state.survey]);

  return {
    validations,
    runValidations,
  };
};