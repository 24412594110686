import { createElement, useEffect, useState } from 'react';
import type { UseMutationResult } from '@tanstack/react-query';
import Check from '@mui/icons-material/Check';
import { cx } from '@utils';
import styles from './style/OptimisticIndicator.css';

type Props = {
  classes?: {
    root?: string;
    icon?: string;
  };
  Icon?: React.ComponentType;
} & Pick<UseMutationResult,
    | 'isError'
    | 'isLoading'
    | 'isSuccess'>;

export const OptimisticIndicator = ({ classes = {}, ...props }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {

    if (!visible && props.isSuccess) {
      setVisible(true);
    }

    if (visible && props.isLoading) {
      setVisible(false);
    }

  }, [props.isSuccess, props.isLoading, visible]);

  const rootclasses = cx(styles.root, {
    [styles.enter]: visible,
  }, classes.root);

  return (
    <span className={rootclasses}>
      <Icon
        as={props.Icon}
        classes={classes} />
    </span>
  );
};

type IconProps = {
  as?:      Props['Icon'];
  classes?: Pick<Props['classes'], 'icon'>;
};

const Icon = ({ classes = {}, ...props }: IconProps) => {
  return props.as
    ? createElement(props.as)
    : <Check className={classes.icon} />;
};

Icon.displayName = 'OptimisticIndicator.Icon';