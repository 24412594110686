import * as xform from '@transformers/helpers';
import { nullableDate } from '@utils';
import type { Onboarding } from '@/types';

function normalizeOnboarding(data: Onboarding.Background.Completion) {
  if (!data) {
    return null;
  }

  const runTransforms = xform.compose<Onboarding.Background.Completion, UnparsedOnboarding>(
    normalizeDates,
  );

  return runTransforms<Onboarding.Background.Completion>(data);

  function normalizeDates(status: UnparsedOnboarding) {
    return {
      completed: status.completed,
      completedOn: nullableDate(status.completedOn),
    };
  }
}

type UnparsedOnboarding = {
  [P in keyof Pick<Onboarding.Background.Completion, 'completedOn'>]: string;
} & Omit<Onboarding.Background.Completion, 'completedOn'>;

export default { normalizeOnboarding };
export {
  normalizeOnboarding,
};