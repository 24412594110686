import type { SurveyTemplateType } from '@enums';
import type { SurveyTemplate } from '@/types/survey.templates';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSurveyTemplateData = <T extends SurveyTemplateType = SurveyTemplateType, D = SurveyTemplate.Data<T>>() => {

  const [state, dispatch] = useSurveyBuilderState();

  return [state.survey.template.data as D, dispatch] as const;
};

export const usePersonaResearchData = () => useSurveyTemplateData<SurveyTemplateType.PersonaResearch>();
export const useCompetitiveIntelligenceData = () => useSurveyTemplateData<SurveyTemplateType.CompetitiveIntelligence>();
export const useNetPromoterScoreData = () => useSurveyTemplateData<SurveyTemplateType.NetPromoterScore>();
export const useTargetProductProfileData = () => useSurveyTemplateData<SurveyTemplateType.TargetProductProfile>();
export const useMessageTestingData = () => useSurveyTemplateData<SurveyTemplateType.MessageTesting>();