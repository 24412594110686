import { useCallback, memo, Fragment } from 'react';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';
import { cx } from '@utils';
import type { TelInputState } from '@/components/TelInput';
import { PhoneInputControlled } from '@/components/TelInput';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
  };
  isEditing?: boolean;
  label: string;
  name: string;
  displayValue: string;
  validField?: string;
  onChange?: (value: TelInputState) => void;
};

export const FieldPhone = (props: Props) => {
  return !props.isEditing
    ? <Display {...props} />
    : <Editable {...props} />;
};

export function Editable({ classes = {}, label, name, onChange }: Props) {

  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback((data: TelInputState) => {
    setFieldValue(name, data.value);
    onChange?.(data);
  }, [name, setFieldValue, onChange]);

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const invalid = meta.touched && !!meta.error;
        return (
          <div>
            <div className={cx(styles.label, classes.label)}>
              {label} {invalid && <span className={styles.formError}>{meta.error}</span>}
            </div>
            <div className={styles.value}>
              <PhoneInputControlled
                classes={{
                  invalid: styles.inputError,
                }}
                value={field.value as string}
                invalid={invalid}
                onChange={handleChange} />
            </div>
          </div>
        );
      }}
    </Field>
  );
}

const Display = memo(({ classes = {}, label, displayValue }: Pick<Props, 'classes' | 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={cx(styles.label, classes.label)}>{label}</div>
      <span className={styles.inputValue}>{displayValue ?? '-'}</span>
    </Fragment>
  );
});