import { memo } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import type { ConferenceDialInfo } from '@/types';
import type { SelectOptionProps } from '@/components/Select/interfaces';
import { PhoneInformation } from './PhoneInformation';
import { Visibility } from './Visibility';
import styles from './style/Tab.Dial.css';

type Props = {
  phoneInformation: {
    className?: string;
  } & ConferenceDialInfo;
  visibilityValue?: number;
  disabledVisibility?: boolean;
  visibilityVisible?: boolean;
  onSelectVisibility: (value: SelectOptionProps) => void;
};

export const Dial = memo((props: Props) => {
  return (
    <div className={styles.root}>
      <PhoneInformation
        className={styles.row}
        {...props.phoneInformation} />
      <Visibility
        className={styles.row}
        value={props.visibilityValue}
        disabled={props.disabledVisibility}
        visible={props.visibilityVisible}
        onSelect={props.onSelectVisibility} />
    </div>
  );
});

export const DialTitle = memo(() => (
  <div className={styles.dialTitle}>
    <PhoneIcon />
    Join By Phone
  </div>
));