import { useCallback, useContext, useMemo } from 'react';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { FolderActionsContextMenuPopper } from '@/components/Workspace.Folder/Folder.Actions.ContextMenu';
import { MoreHorizontalAnchor } from '@/presentation/Anchor';
import { FolderActionsContainer } from '@/containers/Workspace.Folder/FolderMenuContainer';
import styles from '@screens/Workspace.File/style/Header.Actions.css';
import type { FolderMenuItemsContextValue } from '@/containers/Workspace.Folder/Context';
import { FolderMenuItemsContext, FolderStateContext } from '@/containers/Workspace.Folder/Context';

export const FolderContextMenu = () => {

  const { capabilities, object, folder } = useContext(FolderStateContext);

  const menu: FolderMenuItemsContextValue = useMemo(() => ({
    canChangeOwner: false, // capabilities.canChangeOwner,
    canDelete: false, // capabilities.canDelete,
    canManageAccess: false, // capabilities.canManageAccess,
    canMove: false, // capabilities.canMove,
    canRename: capabilities.canRename,
    canAddChildren: false,
  }), [
    capabilities,
  ]);

  const renderAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchor className={styles.menu} open={state.isOpen} />
    );
  }, []);

  return (
    <FolderMenuItemsContext.Provider value={menu}>
      <FolderActionsContainer
        folder={folder}
        object={object}>
        <FolderActionsContextMenuPopper renderAnchor={renderAnchor} />
      </FolderActionsContainer>
    </FolderMenuItemsContext.Provider>
  );
};