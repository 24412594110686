import { nullableDate } from '@utils';
import { transformCalendarEvent } from '@services/api/transformers/calendar';
import type { Projects } from '$admin/api/interfaces';

export const transformRespondentUnconfirmedCalls = (data: Projects.Scheduling.FindRespondentUnconfirmedCalls.Response<string>): Projects.Scheduling.FindRespondentUnconfirmedCalls.Response => ({
  items: data.items.map(call => ({
    ...call,
    timeEnd: nullableDate(call.timeEnd),
    timeStart: nullableDate(call.timeStart),
  })),
});

export const transformSchedulerCalendars = (data: Projects.Scheduling.FindSchedulerCalendars.Response<string>): Projects.Scheduling.FindSchedulerCalendars.Response => {
  return {
    matches: data.matches
    .filter(Boolean)
    .map(({ end, start }) => ({
      end: nullableDate(end),
      start: nullableDate(start),
    })),
    items: data.items.map(transformSchedulerCalendarItem),
    suggested: data.suggested.map(transformSuggestedCalendarItem),
  };
};

function transformSuggestedCalendarItem(item: Projects.Scheduling.FindSchedulerCalendars.Response<string>['suggested'][number]) {
  return {
    ...item,
    items: item.items
    .filter(Boolean)
    .map(({ end, start }) => ({
      end: nullableDate(end),
      start: nullableDate(start),
    })),
  };
}

function transformSchedulerCalendarItem(item: Projects.Scheduling.FindSchedulerCalendars.Response<string>['items'][number]) {
  return {
    ...item,
    events: item.events
    .filter(Boolean)
    .map(transformCalendarEvent),
  };
}