import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks/useSurveyQuestionResponsesData';
import { roundToPlace } from '@utils';
import type { NumberInputTable } from '../interfaces';

export const useNumberInputTableChartData = () => {

  const {
    question,
    responses,
  } = useSurveyQuestionResponsesData<SurveyQuestionType.NumberInputTable>();

  return useMemo(() => {
    return question.matrixRows.reduce<NumberInputTable.ChartData[]>((acc, row) => {
      const item = question.options.reduce((acc2, o) => ({
        ...acc2,
        [o.base.id]: roundToPlace(responses[row.base.id].options[o.base.id].avg, 1),
      }), {
        rowId: row.base.id,
      });

      acc.push(item);

      return acc;
    }, []);

    // return unsorted.sort((a, b) => {
    //   return responses[b.rowId].avg - responses[a.rowId].avg;
    // });
  }, [
    question.matrixRows,
    question.options,
    responses,
  ]);
};