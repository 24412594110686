import { useCallback } from 'react';
import * as api from '@api';
import Toast from '@/components/Toast';
import type { DeleteFolderParams } from '../interfaces';

export const useDeleteFolder = () => {
  const deleteFolder = useCallback((data: DeleteFolderParams) => {
    return api.workspaces.folder.deleteFolder({
      folderId: data.folderId,
      workspaceId: data.workspaceId,
    }).then(_ => {
      Toast.alert({
        title: 'Folder Deleted',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, []);

  return deleteFolder;
};

export default useDeleteFolder;