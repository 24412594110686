import { useCallback, useState } from 'react';
import type { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { TextareaAutosize } from '@/components/Textarea';

type Props = TextareaAutosizeProps;

const ThemedInputBase = (props: Props) => {
  const [focused, setFocused] = useState(false);
  const { palette } = useSurveyThemingPalette();

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const handleBlur = useCallback(onBlur, []);
  const handleFocus = useCallback(onFocus, []);

  const style = focused ? {
    borderBottom: `2px solid ${palette.primary.main}`,
  } : {};

  return (
    <TextareaAutosize
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={style} />
  );
};

export const ThemedInput = (props: Props) => {
  const { theme } = useSurveyThemingPalette();

  return theme
    ? <ThemedInputBase {...props} />
    : <TextareaAutosize {...props} />
  ;
};