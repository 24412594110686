import { useMemo } from 'react';
import type { ActionMeta, InputActionMeta, OnChangeValue  } from 'react-select';
import Select from 'react-select';
import CancelIcon from '@mui/icons-material/Cancel';
import { cx } from '@utils';
import { createSelectStyles, createSelectTheme } from './select-styles';
import styles from './style/Select.Clearable.css';

type Props<T extends Descriptor> = {
  classes?: {
    input?: string;
    reset?: string;
    root?:  string;
  };
  readonly?: boolean;
  onReset?:  () => unknown;
} & InputProps<T>;

export const Clearable = <T extends Descriptor>({ className, classes = {}, onReset, ...props }: Props<T>) => {
  const highlightable = cx(styles.t, {
    [styles.cover]: props.readonly,
    [styles.placeholder]: !props.value?.name,
    [styles.value]: !!props.value?.name,
  });

  const text = props.value?.name || 'Not Set';

  return (
    <div className={cx(styles.root, className, classes.root)}>
      <div className={styles.wrap}>
        <Input
          className={classes.input}
          {...props} />
        <Reset
          className={classes.reset}
          onClick={onReset}
          visible={!!props.value?.id && !props.readonly} />
      </div>
      <div
        className={highlightable}
        draggable={false}>
        <div className={styles.i}>
          {text}
        </div>
      </div>
    </div>
  );
};

Clearable.displayName = 'Select.Clearable';

type InputProps<T extends Descriptor> = {
  className?:     string;
  disabled?:      boolean;
  defaultValue?:  T;
  name?:          string;
  inputValue?:    string;
  onBlur?:        (e: React.FocusEvent<HTMLDivElement>) => unknown;
  onChange:       (newValue: OnChangeValue<T, false>, actionMeta: ActionMeta<T>) => unknown;
  onInputChange?: (inputValue: string, actionMeta: InputActionMeta) => unknown;
  options:        T[];
  placeholder?:   string;
  readonly?:      boolean;
  value:          T;
};

const Input = <T extends Descriptor>(props: InputProps<T>) => {

  const style = useMemo(() => {
    return createSelectStyles<T>({
      readonly: props.readonly,
    });
  }, [props.readonly]);

  const theme = useMemo(() => createSelectTheme(), []);

  return (
    <Select
      className={props.className}
      defaultValue={props.defaultValue}
      isDisabled={props.disabled}
      getOptionLabel={x => x.name}
      getOptionValue={x => String(x.id)}
      inputValue={props.inputValue}
      name={props.name}
      noOptionsMessage={x => x.inputValue?.length ? `No matches found.` : null}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onInputChange={props.onInputChange}
      openMenuOnClick={!props.readonly}
      openMenuOnFocus={!props.readonly}
      options={props.options || []}
      placeholder={props.placeholder}
      styles={style}
      theme={theme}
      value={props.value} />
  );
};

Input.displayName = 'Select.Clearable.Input';

type ResetProps = {
  className?: string;
  onClick:    () => unknown;
  visible?:   boolean;
};

const Reset = ({ visible = true, ...props }: ResetProps) => {
  return (
    <button
      className={cx(styles.clear, props.className)}
      disabled={!visible}
      onClick={props.onClick}>
      <CancelIcon />
    </button>
  );
};

Reset.displayName = 'Select.Clearable.Button.Reset';