import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { X } from 'react-feather';
import Axios from 'axios';
import * as consts from '@consts';
import * as selectors from '@store/selectors';
import * as api from '@api';
import { isValidEmail } from '@utils';
import AutoComplete from '@/components/AutoComplete';
import CopyLink from '@/components/CopyLink';
import { GoogleContactSync, MicrosoftContactSync } from '@/components/ConnectAccount';
import styles from './style.css';

function defaultState() {
  return {
    acItem: null,
    acItems: [],
    email: '',
    emails: new Set(),
    error: null,
    note: '',
    connectSkipped: false,
  };
}

const mapState = state => ({
  contact: selectors.connectedContacts(state),
  user: state.user,
});

class ShareWithOthers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState(),
      url: `${process.env.FRONT_BASE_URL}${consts.path.Website.ReferralSignup}/${this.props.user.referral}`,
    };
  }

  link = createRef();

  cancelLastFetch = () => {
    if (this.fetchLast) {
      this.fetchLast.cancel();
    }
  };

  send = () => {
    const email = this.state.email;
    const emails = this.state.emails;

    const freeEmails = email.split(/\s*,\s*/gi).filter(isValidEmail);
    freeEmails.forEach(e => emails.add(e));

    if (!emails.size) {
      return this.setState({
        error: {
          message: 'Please provide at least 1 valid e-mail address.',
        },
      });
    }

    this.props.onSend({
      emails: [...emails],
      note: this.state.note,
      referral: this.props.user.referral,
    });

    this.setState(defaultState());
  };

  handleLinkCopied = () => {
    //
  };

  inputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchContacts = search => {
    if (this.fetchTimer) {
      clearTimeout(this.fetchTimer);
    }
    this.fetchTimer = setTimeout(() => {
      this.cancelLastFetch();
      this.fetchLast = Axios.CancelToken.source();
      api.users.searchContactsCancellable({
        query: search,
        cancelToken: this.fetchLast.token,
      })
      .then(resp => {
        if (resp.results) {
          this.setState({
            acItems: resp.results,
          });
        }
      });
    }, 100);
  };

  onChange = e => {
    this.setState({ email: e.target.value });

    if (!e.target.value) {
      this.cancelLastFetch();
      return this.setState({
        acItems: [],
      });
    }

    if (this.props.contact) {
      this.fetchContacts(e.target.value);
    }
  };

  onSelect = item => {
    const val = item.isCustom ? item.name : item.value;

    if (!val || !val.length) {
      return;
    }

    if (!isValidEmail(val)) {
      return;
    }

    this.setState({
      emails: this.state.emails.add(val),
      error: null,
      acItems: [],
    });
  };

  handleRemove = e => () => {
    this.setState({
      email: '',
      emails: new Set([...this.state.emails].filter(f => f !== e)),
    });
  };

  skipConnected = () => {
    this.setState({
      connectSkipped: true,
    });
  };

  showEmailInput = () => {
    return this.props.contact || this.state.connectSkipped;
  };

  renderConnectAccount = () => {
    return (
      <div className={styles.emailSection}>
        <div className={styles.label}>See Contact suggestions from Gmail and Outlook</div>
        <div className={styles.connected}>
          <GoogleContactSync />
          <MicrosoftContactSync />
        </div>
        <div
          className={styles.later}
          onClick={this.skipConnected}>{`Later >`}
        </div>
      </div>
    );
  };

  renderEmailInput = () => {
    return (
      <div className={styles.emailSection}>
        <div className={styles.label}>Or send an invitation to your contacts</div>
        <AutoComplete.Multi
          className={styles.emails}
          onChange={this.onChange}
          onSelect={this.onSelect}
          items={this.state.acItems}
          getItemValue={item => item.name}
          placeholder="Enter email addresses of recipients." />
        <div className={styles.sendto}>
          {[...this.state.emails].map(e =>
            <div
className={styles.sendtoitem}
key={e}
onClick={this.handleRemove(e)}>
              <label>{e}<X size={14} /></label>
            </div>
          )}
        </div>
        {/* <ul className={styles.sendto}>
          {[...this.state.emails].map(e =>
            <li key={e}>
              <label>{e}</label>
              <X size={18} onClick={this.handleRemove(e)} />
            </li>
          )}
        </ul> */}
        <textarea
          name="note"
          className={styles.note}
          type="text"
          placeholder="Customize your invitation note. (Optional)"
          onChange={this.inputChange} />
      </div>
    );
  };

  renderFooter = () => {
    if (!this.showEmailInput()) return null;

    return (
      <>
        {this.state.error && (<div className={styles.error}>{this.state.error.message}</div>)}
        <div className={styles.buttons}>
          <button
            onClick={this.send}
            className={styles.send}>
            Send Emails
          </button>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className={styles.wrap}>
        <h1 className={styles.title}>Share with others</h1>
        <div className={styles.subtitle}>{this.props.subtitle}</div>
        <div className={styles.body}>
          <div className={styles.section}>
            <div className={styles.label}>Send a link to sign up</div>
            <CopyLink
              onCopy={this.handleLinkCopied}
              url={this.state.url} />
          </div>
          {this.showEmailInput() ? this.renderEmailInput() : this.renderConnectAccount()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default connect(mapState, null)(ShareWithOthers);