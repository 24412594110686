import { useMemo } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as api from '@api';
import { useIsProjectTypeCallLike, useSelectProject } from '@containers/Store';

type Data = number;
type Options = UseQueryOptions<Data, AxiosResponse, Data>;

export const useProjectRequestCallRate = (params: IProjectId, options?: Options) => {
  const isCallLike = useIsProjectTypeCallLike(params.projectId);
  const project = useSelectProject(params.projectId);

  const enabled = useMemo(() => {
    return project?.id
        && isCallLike;
  }, [
    isCallLike,
    project?.id,
  ]);

  return useQuery({ queryKey: ['project-request-call-rate', { id: project?.id, projectType: project?.projectType }], queryFn: () => {
    return api.projects.pipeline.getProjectCallRate({
      projectId: project?.id,
    })
    .then(data => data.rate);
  }, enabled: !!enabled, ...options });
};
