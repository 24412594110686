import { useCallback } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import type { WorkspaceObjects } from '@api/interfaces';
import Toast from '@/components/Toast';

export const useSaveObjectOwnership = () => {
  const saveOwnership = useCallback((data: WorkspaceObjects.UpdateObjectOwnership.Request) => {
    return api.workspaces.object.updateObjectOwnership(data)
      .then(data => {
        Toast.alert({
          title: `${enums.utils.WorkspaceObjectType.getName(data.object.typeId)} Owner Updated`,
        });
      }).catch(e => {
        Toast.error({
          title: 'Error',
          body: `We're sorry, there was an issue changing owners`,
        });
      });
  }, []);

  return saveOwnership;
};

export default useSaveObjectOwnership;