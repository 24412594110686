import { useCallback, useState } from 'react';
import { CallPayoutOverrideType } from '@enums';
import { RespondentSetupContext } from './Context';
import * as Modal from './Modal.PickRespondent';
import type * as CallScheduling from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const RespondentSetup = (props: Props) => {
  const [form, dispatch] = useState<CallScheduling.RespondentSetup.Form>(initial);

  const resetContext = useCallback(() => {
    dispatch(initial);
  }, []);

  const setContext = useCallback((data: IProjectId) => {
    dispatch(state => ({
      ...state,
      projectId: data.projectId,
    }));
  }, []);

  const setValue = useCallback((data: CallScheduling.RespondentSetup.SetValueParams) => {
    dispatch(state => ({
      ...state,
      ...data,
    }));
  }, []);

  const value = {
    form,
    resetContext,
    setContext,
    setValue,
  };

  return (
    <RespondentSetupContext.Provider value={value}>
      {props.children}
      <Modal.PickRespondent open={!!form.projectId} />
    </RespondentSetupContext.Provider>
  );
};

RespondentSetup.displayName = 'Call.Scheduling.RespondentSetup';

const initial = {
  email: null,
  external: false,
  honorarium: {
    currency: `USD` as const,
    type: CallPayoutOverrideType.Custom,
    value: `0`,
  },
  payout: false,
  projectId: null,
  userId: null,
};