import { useCallback, useContext } from 'react';
import { AnalyticsEvent } from '@enums';
import { cx, formatDisplayAge } from '@utils';
import { useTrackEvent } from '@utils/analytics';
import type { FeedItemComment } from '@/types/comments';
import * as Author from './Comment.Author';
import { ContextMenu } from './Comment.ContextMenu';
import { CommentContextMenuContext } from './Context';
import styles from './style/Comment.css';

type Props = {
  className?: string;
  item:       FeedItemComment;
};

export const Header = (props: Props) => {
  const menu = useContext(CommentContextMenuContext);
  const timestamp = formatDisplayAge(new Date(props.item.createdOn));

  const trackEvent = useTrackEvent(AnalyticsEvent.ViewPostCommentAuthor);
  const trackViewAuthor = useCallback(() => trackEvent({
    commentId: props.item.id,
    postId: props.item.itemId,
    userId: props.item.author.id,
  }), [
    props.item.id,
    props.item.itemId,
    props.item.author.id,
    trackEvent,
  ]);

  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.author}>
        <Author.Picture
          author={props.item.author}
          onClick={trackViewAuthor} />
        <div className={styles.meta}>
          <div className={styles.title}>
            <Author.Name
              author={props.item.author}
              onClick={trackViewAuthor} />
            <span className={styles.age}>{timestamp}</span>
          </div>
          <Author.Career author={props.item.author} />
        </div>
      </div>
      <ContextMenu
        item={menu.item}
        depth={menu.depth}
        onDelete={menu.onDelete}
        onReply={menu.onReply} />
    </div>
  );
};

Header.displayName = 'Comment.Header';