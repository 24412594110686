import { useCallback, useMemo } from 'react';
import { useConjointAnalysisAttributeColors, useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import type { SurveyQuestionType } from '@enums';
import { ProjectVersionHeaderRow, ResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import type { ConjointAnalysisQuestion, RespondentProps } from '@/types';
import ResponseOptionUser from '@/components/SurveyResponses/ResponseOptionUser';
import Respondent from '@/components/SurveyResponses/ConjointAnalysis.Data.Level.User';
import { useProjectVersion } from './hooks';
import styles from './style/Data.css';

export const ConjointAnalysisRepeatLevelData = () => {
  const { query, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.ConjointAnalysis>();
  const version = useProjectVersion(query.projectId);
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  const colors = useConjointAnalysisAttributeColors();

  const data = useMemo(() => {
    return historical[query.projectId].data.levels[query.levelId];
  }, [
    historical,
    query.levelId,
    query.projectId,
  ]);

  const attribute = useMemo(() => {
    return question.attributes.reduce((acc, a) => {
      const l = a.levels.find(f => f.base.id === query.levelId);
      return l ? a : acc;
    }, null as ConjointAnalysisQuestion.Attribute);
  }, [
    query.levelId,
    question.attributes,
  ]);

  const level = useMemo(() => {
    return attribute.levels.find(f => f.base.id === query.levelId);
  }, [
    attribute,
    query.levelId,
  ]);

  const sortedUserIds = useMemo(() => {
    const userIds = data.responses.userIds;

    return userIds.sort((a, b) => {
      return data.responses.values[b] -
      data.responses.values[a];
    });
  }, [
    data.responses,
  ]);

  const renderUser = useCallback((item: RespondentProps) => {
    const count = data.responses.values[item.user.id];
    return (
      <Respondent
        {...item}
        count={count} />
    );
  }, [
    data.responses.values,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      <ResponseOptionRow
        className={styles.row}
        color={colors[attribute.base.id]}
        label={level.value}
        subtitle={`(${data.utility})`} />
      {
        sortedUserIds.map(userId => (
          <ResponseOptionUser
            key={userId}
            renderItem={renderUser}
            userId={userId} />
        ))
      }
    </>
  );
};

export default ConjointAnalysisRepeatLevelData;