import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import type * as API from '@api/interfaces';

const useFetchComplianceCallReview = () => {

  return useAsyncStateLazy((data: API.Groups.Compliance.FetchCallReview.Request) => {

    return api.groups.compliance.fetchCallReview({
      callId: data.callId,
      groupId: data.groupId,
    });

  });

};

export { useFetchComplianceCallReview };
export default useFetchComplianceCallReview;