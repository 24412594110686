import type { ConferenceTagSummary, ConferenceTagSummaryItem, TagSummaryFeedback } from '@/types/conferences.tags';
import { safeJsonDate } from '@/utils';

export function transformConferenceTagSummaryItem(
  item: ConferenceTagSummaryItem<string>,
): ConferenceTagSummaryItem {
  return {
    ...item,
    summary: transformTagSummary(item.summary),
    children: item.children.map(transformConferenceTagSummaryItem),
  };
}

export function transformTagSummary(summary: ConferenceTagSummary<string>): ConferenceTagSummary {
  if (!summary) {
    return null;
  }

  return {
    ...summary,
    generatedOn: safeJsonDate(summary.generatedOn),
  };
}

export function transformConferenceTagSummaryFeedback(
  item: TagSummaryFeedback<string>,
): TagSummaryFeedback {
  return {
    ...item,
    lastUpdatedOn: safeJsonDate(item.lastUpdatedOn),
  };
}