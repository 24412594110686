import { useCallback, useEffect, useMemo, useState } from 'react';
import type { TaggedProject } from '@containers/TaggedProjects';
import Header from './Header';
import Tags from './Tags';
import type {
  TaggedProjectsContextValue } from './Context';
import {
  TaggedProjectsContext,
  TaggedProjectsDispatchContext,
  TaggedProjectsResetContext,
  TaggedProjectsUpdateContext,
} from './Context';

type Props = {
  onSave: (items: TaggedProject[]) => Promise<unknown>;
  items: TaggedProject[];
};

export const GroupSidebarTaggedProjects = ({
  items,
  onSave,
}: Props) => {
  const [state, dispatch] = useState<TaggedProjectsContextValue>({
    editable: true,
    editing: false,
    items,
  });

  const handleReset = useCallback(() => {
    dispatch(state => ({ ...state, items }));
  }, [items]);

  useEffect(() => {
    dispatch(state => ({ ...state, items }));
  }, [items]);

  const canSave = useMemo(() => {
    return JSON.stringify(state.items) !== JSON.stringify(items);
  }, [state.items, items]);

  return (
    <TaggedProjectsContext.Provider value={state}>
      <TaggedProjectsResetContext.Provider value={handleReset}>
        <TaggedProjectsDispatchContext.Provider value={dispatch}>
          <TaggedProjectsUpdateContext.Provider value={[onSave, canSave]}>
            <Header />
            <Tags />
          </TaggedProjectsUpdateContext.Provider>
        </TaggedProjectsDispatchContext.Provider>
      </TaggedProjectsResetContext.Provider>
    </TaggedProjectsContext.Provider>
  );
};

export default GroupSidebarTaggedProjects;