import { useCallback, useContext } from 'react';
import { ArticleCoverImageContext } from '@containers/Post.Article.Form/Context';
import type { RemirrorParams } from '@containers/Post.Article.Form/interfaces';

export const useResolveArticlePreviewImageSrc = () => {
  const [coverimage] = useContext(ArticleCoverImageContext);

  return useCallback(({ helpers }: RemirrorParams) => {
    if (coverimage?.url) return coverimage.url;

    const [image] = helpers.findImageObjects();

    return image?.src ?? null;

  }, [coverimage]);
};