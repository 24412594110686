import type { SurveyFiltering } from '@/types';
import { useSurveyFilterDataContext } from '@containers/GroupProject/Context';

export const useFilterQuestion = (filter: SurveyFiltering.FilterEntry) => {
  const filterContext = useSurveyFilterDataContext();

  const question = filterContext.surveyData.find(s => s.surveyId === filter.surveyId)
    .questions.find(q => q.base.id === filter.questionId);

  return question;
};