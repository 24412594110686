import { Component } from 'react';
import { connect } from 'react-redux';
import { SettingsTitle } from '@/components/Settings';
import * as enums from '@enums';
import Fields from '../Fields';
import styles from './style.css';

const mapState = state => ({
  group: state.group,
  user: state.user,
});

class GroupSettings extends Component {
  renderDisableDocumentSigning = () => {
    const items = [
      { id: enums.DocumentSigning.Required, label: 'E-Signature Required' },
      { id: enums.DocumentSigning.NotRequired, label: 'E-Signature Not Required' },
      { id: enums.DocumentSigning.ManuallyUploaded, label: 'Documents Manually Uploaded' },
    ];

    const selected = items.find(x => x.id === this.props.group.features.documentSigning);

    return (
      <Fields.ReadOnly
        value={selected.label}
        label="Expert Agreement" />
    );
  };

  renderDisableMessaging = () => {
    return (
      <Fields.ReadOnly
        value={this.props.group.features.disableMessaging ? 'Disabled' : 'Enabled'}
        label="Messaging" />
    );
  };

  renderCallRecording = () => {
    return (
      <Fields.ReadOnly
        value={this.props.group.features.recordCalls ? 'Enabled' : 'Disabled'}
        label="Call Recordings" />
    );
  };

  render() {
    if (!this.props.user.roles.some(role => [enums.Role.FirmCompliance, enums.Role.InternalAdmin].includes(role))) {
      return null;
    }

    return (
      <div className={styles.root}>
        <SettingsTitle title="System Controls" />
        <div className={styles.main}>
          {this.renderDisableMessaging()}
          {this.renderCallRecording()}
          {this.renderDisableDocumentSigning()}
        </div>
      </div>
    );
  }
}

export default connect(mapState, null)(GroupSettings);