import { useMemo } from 'react';
import * as chart from '@containers/SurveyResponses/utils';
import type { SurveyQuestionType } from '@enums';
import { useRepeatQuestionResponsesData } from './hooks';
import { ConjointAnalysisAttributeColorsContext } from './Context';

type Props =
  ChildrenProps;

export const ConjointAnalysisRepeatSurveyContainer = (props: Props) => {
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  const colors = useMemo(() => {
    return question.attributes.reduce((acc, a, i) => ({
      ...acc,
      [a.base.id]: chart.conjointAnalysis.getAttributeColor(i),
    }), {});
  }, [
    question.attributes,
  ]);

  return (
    <ConjointAnalysisAttributeColorsContext.Provider value={colors}>
      {props.children}
    </ConjointAnalysisAttributeColorsContext.Provider>
  );
};

export default ConjointAnalysisRepeatSurveyContainer;