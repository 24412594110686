import type { ProfileEntry } from '@enums';
import { SeeMore } from '@presentation/SeeMore';
import { cx } from '@utils';
import DateRange from './Profile.DateRange';
import Icon from './Profile.Education.Icon';
import type { Section } from './interfaces';
import styles from './style/Profile.Section.Multiple.css';

type Props =
  Section.MultiEntry.ReadOnlyProps<ProfileEntry.Education>;

export const ProfileEducation = ({ value: item }: Props) => {

  return (
    <div className={cx(styles.root, styles.readOnly)}>
      <div className={styles.iconColumn}>
        <Icon />
      </div>
      <div className={styles.main}>
        <div className={styles.topItem}>
          <div className={styles.half}>
            <div className={styles.header}>{item.school}</div>
          </div>
          <DateRange
            startMonth={item.startMonth}
            startYear={item.startYear}
            endMonth={item.endMonth}
            endYear={item.endYear} />
        </div>
        <div className={styles.half}>
          <div className={styles.title}>{item.degree}</div>
        </div>

        <SeeMore
className={styles.description}
lineHeight={20}
maxLines={5}>
          {item.description}
        </SeeMore>
      </div>
    </div>
  );
};

export default ProfileEducation;