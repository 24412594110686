import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api';
import { LinkContext } from '@/brand-insights/components/BrandInsights/context';
import { ActiveChatSessionContext, AuthorContext } from '@/brand-insights/components/Chat';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { GetStartedContext } from '@/brand-insights/components/Chat.GetStarted';
import { FetchMoreHistoryContext, ChatHistoryManagerContext, ChatHistoryItemsContext } from './context';

type Props = {
  children: ReactNode;
};

export const ChatHistoryContainer = (props: Props) => {
  const historyManager = useContext(ChatHistoryManagerContext);
  const historyItems = useContext(ChatHistoryItemsContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const author = useContext(AuthorContext);
  const [showGetStarted] = useContext(GetStartedContext);
  const chat = useContext(ActiveChatSessionContext);

  const link = useContext(LinkContext);

  const getIncludeAdditional = useCallback(() => {
    if (!link?.initialized && link?.chatIdentifier && !historyItems.map(i => i.identifier).includes(link.chatIdentifier)) {
      return [link.chatIdentifier];
    }
  }, [historyItems, link?.initialized, link?.chatIdentifier]);

  const queryKey = [`chat:get:history`, {
    mode: historyManager.mode,
    cursor: historyManager.cursor,
    pageSize: 20,
    userId: author.id,
    includeAdditional: getIncludeAdditional(),
  }, chat.context] as const;

  const { refetch } = useQuery({ queryKey, queryFn: ({ queryKey }) => {
    dispatch({ type: 'chat-loading-started' });
    return $api.getChatHistory({
      mode: queryKey[1].mode,
      cursor: queryKey[1].cursor,
      pageSize: queryKey[1].pageSize,
      userId: queryKey[1].userId,
      includeAdditional: queryKey[1].includeAdditional,
      context: queryKey[2],
    });
  }, enabled: false, refetchOnWindowFocus: false, refetchOnMount: false, retry: 1, onSuccess: data => {
    const cursor = data.items.length ? data.items[data.items.length - 1].id : null;
    dispatch({
      type: queryKey[1].cursor ? 'chat-history-page-loaded' : 'chat-history-loaded',
      payload: {
        cursor,
        hasMore: data.hasMore,
        items: data.items,
        shared: data.shared,
      },
    });
  }, onError: () => {
    dispatch({ type: 'chat-loading-errored' });
  } });

  useEffect(() => {
    if (!showGetStarted && !historyManager.initialized) {
      refetch();
    }
  }, [refetch, showGetStarted, historyManager.mode, historyManager.initialized]);

  return (
    <FetchMoreHistoryContext.Provider value={refetch}>
      {props.children}
    </FetchMoreHistoryContext.Provider>
  );
};