import axios from 'axios';
import { logRequest } from '@services/http/console';
import { XHR } from '@services/http/interfaces';
import type { Settings } from './interfaces';

const http = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

http.interceptors.request.use(config => {
  logRequest(config);
  return config;
});

export const getEmailSettings = (data: Settings.Unsubscribe.GetEmailSettings.Request): Promise<Settings.Unsubscribe.GetEmailSettings.Response> => {
  return http.get(`/settings/email/${data.token}`).then(r => r.data);
};

export const updateUnsubscribeEmailSettings = (data: Settings.Unsubscribe.UpdateEmailSettings.Request): Promise<Settings.Unsubscribe.UpdateEmailSettings.Response> => {
  return http.patch(`/settings/email/${data.token}`, data.settings).then(r => r.data);
};