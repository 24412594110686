import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import * as api from '@api';
import type { Projects } from '@api/interfaces';

type Data = Projects.FetchSurveyMetricsAuth.Response;
type QueryKey =[string, number];

export const useProjectSurveyMetricsPassword = (
  { projectId }: IProjectId,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery({ queryKey: ['fetch-project-survey-metrics-password', projectId], queryFn: ({ queryKey }) => {
    const projectId = queryKey[1] as number;

    return api.projects.surveys.metrics.auth.get({
      projectId,
    });
  }, ...options });
};