import { useContext } from 'react';
import { FollowedTopicsContainer, SuggestedTopicsContainer } from '@containers/Topic';
import { SuggestedTopicsContext, FollowedTopicsContext } from '@containers/Topic/Context';
import { useSuggestedTopicsState, useCreateTopicsState } from '@containers/Topic/hooks';
import { useInfiniteWindowScroll } from '@utils';
import type { Topics } from '@api/interfaces';
import * as SuggestedTopics from '@/components/ContentFeed/SuggestedTopics';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useSearchBar } from '@/components/Topic/hooks';
import { Footer } from './Footer';
import styles from './style/TopicOnboarding.css';

type Props = unknown;

export const TopicOnboarding = (props: Props) => {

  return (
    <FollowedTopicsContainer>
      <SuggestedTopicsContainer size={24}>
        <TopicOnboardingScreen />
      </SuggestedTopicsContainer>
    </FollowedTopicsContainer>
  );
};

TopicOnboarding.displayName = 'Topic.Onboarding';

const TopicOnboardingScreen = (props: Props) => {
  const [searchState, SearchBar] = useSearchBar();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>{copy.header}</div>
        <SearchBar
          containerClassName={styles.searchBar}
          term={searchState.searchTerm}
          isLoading={searchState.isLoading} />
        <div className={styles.main}>
          {searchState.returnedItems &&
            <Searched items={searchState.returnedItems} />}
          {!searchState.returnedItems &&
            <Suggested />
          }
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Suggested = () => {
  const suggested = useContext(SuggestedTopicsContext);
  const items = useSuggestedTopicsState();

  useInfiniteWindowScroll({
    canFetch: suggested.query.hasNextPage && !suggested.query.isFetchingNextPage,
    fetchNext: suggested.query.fetchNextPage,
    bufferSize: 0.3,
  });

  return (
    <>
      {suggested.query.isLoading &&
        <ActivityIndicator show />}

      {!suggested.query.isLoading &&
        items.map(x =>
          <div
            className={styles.item}
            key={x.item.id}>
            <SuggestedTopics.Card
              className={styles.card}
              following={x.following}
              item={x.item}
              onToggleFollowing={x.onToggleFollowing} />
          </div>)}
    </>
  );
};

type SearchedProps = {
  items: Topics.SearchTopics.Response;
};
const Searched = ({ items }: SearchedProps) => {
  const followed = useContext(FollowedTopicsContext);

  const searchedItems = useCreateTopicsState({ data: items ?? [], followed: followed.topics });

  return (
    <>
      {searchedItems.map(x =>
        <div
          className={styles.item}
          key={`${x.item.id}-searched`}>
          <SuggestedTopics.Card
            className={styles.card}
            following={x.following}
            item={x.item}
            onToggleFollowing={x.onToggleFollowing} />
        </div>)}
    </>
  );
};

TopicOnboardingScreen.displayName = 'Topic.Onboarding.Screen';

const copy = {
  header: `Follow topics to customize your newsfeed and receive relevent projects.`,
};