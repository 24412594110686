import { useCallback, useContext } from 'react';
import { CallRole } from '@enums';
import { ButtonOutlined } from '@presentation';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { ConferenceParticipants } from '$admin/components/Conference.Participants';
import type { ParticipantItem } from '$admin/components/Scheduling/interfaces';
import * as $conference from '$admin/containers/Call/utils';
import { FrameContext, ParamsContext, ParticipantsContext, SettingsContext } from './Context';
import styles from './style/ManageAttendees.css';

type Props = unknown;

export const ManageAttendees = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    participants: useContext(ParticipantsContext),
    settings: useContext(SettingsContext),
    scheduling: useContext(ParamsContext),
  };

  const getItemDisabled = useCallback((item: ParticipantItem) => {
    return item.roleId === CallRole.Respondent && !item.offPlatform
      ? item.id === ctx.scheduling.params.userId
      : false;
  }, [
    ctx.scheduling.params.userId,
  ]);

  const offPlatform = $conference.isOffPlatform(ctx.settings.conference);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <ConferenceParticipants
            getItemDisabled={getItemDisabled}
            items={ctx.participants.items}
            offPlatform={offPlatform}
            updater={ctx.participants.setValue} />
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={ctx.frame.back}>
            Back
          </ButtonOutlined>
          <Button
            color="primary"
            className={styles.btn}
            variant="brick"
            onClick={ctx.frame.back}>
            Save
          </Button>
        </ButtonSet>
      </div>
    </div>
  );
};

ManageAttendees.displayName = 'Frame.ManageAttendees';