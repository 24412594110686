import { GroupType, Permission, Role } from '@enums';

export const roles: RolesMap = {
  admin: [
    Role.InternalAdmin,
  ],
  analyst: [
    Role.FirmAnalyst,
  ],
  client: [
    Role.FirmAnalyst,
    Role.FirmCompliance,
    Role.InsightsChatOnly,
  ],
  compliance: [
    Role.FirmCompliance,
  ],
  consultant: [
    Role.Expert,
  ],
  datasteward: [
    Role.DataSteward,
  ],
  transient: [
    Role.FirmTransient,
  ],
  recruiter: [
    Role.Recruiter,
  ],
  chatOnly: [
    Role.InsightsChatOnly,
  ],
};

type RolesMap = {
  [key: string]: Role[];
};

type HasRole = (user: Pick<Store.User, 'roles'>) => boolean;

export const hasInternalAdminRole: HasRole = user => {
  return user.roles.some(r => roles.admin.includes(r));
};

export const hasAnalystRole: HasRole = user => {
  return user.roles.some(r => roles.analyst.includes(r));
};

export const hasDataStewardRole: HasRole = user => {
  return user.roles.some(r => r === Role.DataSteward);
};

export const hasClientRole: HasRole = user => {
  return user.roles.some(r => roles.client.includes(r));
};

export const hasComplianceRole: HasRole = user => {
  return user.roles.some(r => roles.compliance.includes(r));
};

export const hasConsultantRole: HasRole = user => {
  return user.roles.some(r => roles.consultant.includes(r));
};

export const hasTransientRole: HasRole = user => {
  return user.roles.some(r => roles.transient.includes(r));
};

export const hasRecruiterRole: HasRole = user => {
  return user.roles.some(r => roles.recruiter.includes(r));
};

export const hasInsightsChatOnlyRole: HasRole = user => {
  return user.roles.some(r => roles.chatOnly.includes(r));
};

export const hasPermission: HasPermission = (user, permission) => {
  return user.permissions.some(p => p === permission);
};

export const hasInsightsChatAccess: HasChatAccess = (user, group) => {
  return (
    hasClientRole({ roles: user.roles }) &&
    hasPermission({ permissions: user.permissions }, Permission.BrandInsightsFullpage) &&
    group.features.insightsChat
  ) || (
    hasInsightsChatOnlyRole({ roles: user.roles }) &&
      group.features.insightsChat
  );
};

export const hasChatAccess: HasChatAccess = (user, group) => {

  if (!group.features) return false;

  if (group.features.disableMessaging) return false;

  const hasBetaPermission = hasPermission(user, Permission.BetaMain);

  if (!hasBetaPermission) return false;

  return true;
};

type HasPermission = (user: Pick<Store.User, 'permissions'>, permission: Permission) => boolean;
type HasChatAccess = (user: Pick<Store.User, 'permissions' | 'roles'>, group: Pick<Store.Group, 'features'>) => boolean;

export function isInternalUser(group: Pick<Store.Group, 'typeId'>) {
  return group.typeId === GroupType.Internal;
}

export const isPlatformUser = (user: Pick<Store.User, 'roles'>) => {
  const hasRoles = user?.roles?.length > 0;

  if (!hasRoles) return false;

  return !(hasConsultantRole(user) || hasInternalAdminRole(user));
};

export const isNonAdminClient = (user: Pick<Store.User, 'roles'>) => {
  const hasRoles = user?.roles?.length > 0;

  if (!hasRoles) return false;

  return hasClientRole(user) && !hasInternalAdminRole(user);
};

export const isAdmin = (user: Pick<Store.User, 'roles'>) => {
  const hasRoles = user?.roles?.length > 0;

  if (!hasRoles) return false;

  return hasInternalAdminRole(user);
};

export const isExpert = (user: Pick<Store.User, 'roles'>) => {
  const hasRoles = user?.roles?.length > 0;

  if (!hasRoles) return false;

  return hasConsultantRole(user);
};