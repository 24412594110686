import { CheckSquare } from 'react-feather';
import { Link } from 'react-router-dom';
import * as utils from '@utils';
import styles from './style/ComplianceSurveyResponseLink.css';

type ComplianceSurveyResponseLinkProps =
  IProjectId &
  IUserId;

const ComplianceSurveyResponseLink = (props: ComplianceSurveyResponseLinkProps) => {
  const to = utils.getLocationFor.admin.project.survey.complianceSurveyResponse(props);
  return (
    <Link
      to={to}>
      <CheckSquare className={styles.link} />
    </Link>
  );
};

export type { ComplianceSurveyResponseLinkProps };
export { ComplianceSurveyResponseLink };
export default ComplianceSurveyResponseLink;