import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { Role } from '@/enums';
import { updateUserRoles } from '$admin/api/users';
import type { UserPermissions } from '$admin/interfaces';

export const useUpdateUserRoles = (props: Props, options?: Options) => {

  return useMutation({ mutationKey: ['admin.update-user-roles'], mutationFn: ({ roles }) => {
    return updateUserRoles({
      roles,
      userId: props.userId,
    });
  }, ...options });
};

type Props = IUserId;
type Data = {
  permissions: UserPermissions;
  roles: Role[];
};
type Vars = {
  roles: Role[];
};
type Options = UseMutationOptions<Data, AxiosResponse, Vars>;