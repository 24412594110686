import { surveys } from '@consts';
import type { RankingQuestion, SurveyQuestionOption } from '@/types/survey';

const NotApplicableOrdinal = surveys.NotApplicableRankingOrdinal;

export type RefreshItems = {
  items: RankingQuestion.Option[];
  settings: RankingQuestion.Settings;
  sourceIndex: number;
  targetIndex: number;
  targetOrdinal: number;
};

export type ReorderRankAll = {
  items: RankingQuestion.Option[];
  sourceIndex: number;
  targetOrdinal: number;
};

export function refreshItems({
  items,
  settings,
  sourceIndex,
  targetIndex,
  targetOrdinal,
}: RefreshItems) {
  const sourceItem = items[sourceIndex];

  function computeAdjustedDestinationIndex() {
    const previouslyNA = targetOrdinal !== NotApplicableOrdinal &&
    sourceItem.ordinal === NotApplicableOrdinal;
    const maxNonNAIndex = Math.max(...items.filter(f => f.ordinal !== NotApplicableOrdinal).map((_, index) => index));
    const maxNewIndex = previouslyNA
      ? maxNonNAIndex + 1
      : maxNonNAIndex;

    return Math.min(maxNewIndex, targetIndex);
  }

  function computeNewOrdinal(item: SurveyQuestionOption, index: number) {
    const isDraggedItem = item.id === sourceItem.id;
    const isNotApplicable = isDraggedItem
      ? targetOrdinal === NotApplicableOrdinal
      : item.ordinal === NotApplicableOrdinal;

    if (isNotApplicable) return NotApplicableOrdinal;

    return index + 1;
  }

  function reorderItemsRankAll() {
    const adjustedDestinationIndex = computeAdjustedDestinationIndex();
    const otherItems = items.filter((_, index) => index !== sourceIndex);

    const reordered = [
      ...otherItems.slice(0, adjustedDestinationIndex),
      items[sourceIndex],
      ...otherItems.slice(adjustedDestinationIndex),
    ];

    return reordered.map((item, index) => ({
      id: item.id,
      ordinal: computeNewOrdinal(item, index),
    }));
  }

  return reorderItemsRankAll();

}

export function reorderItemsRankedOnly({ items, sourceIndex, targetOrdinal }: ReorderRankAll) {

  const sourceItem = items[sourceIndex];

  const alreadyRankedItems = items.filter(item => item.ordinal !== null && item.ordinal !== NotApplicableOrdinal);

  const { items: rankedItems } = [
    ...alreadyRankedItems.filter(f => f.ordinal < targetOrdinal),
    sourceItem,
    ...alreadyRankedItems.filter(f => f.ordinal >= targetOrdinal),
  ].reduce((acc, item) => {
    let ordinal = item.ordinal;
    if (item.id === sourceItem.id) {
      ordinal = targetOrdinal;
      acc.ordinalToReplace = targetOrdinal;
    } else if (item.ordinal === acc.ordinalToReplace) {
      ordinal = acc.ordinalToReplace + 1;
      acc.ordinalToReplace = acc.ordinalToReplace + 1;
    }

    return {
      items: acc.items.concat({
        id: item.id,
        ordinal,
      }),
      ordinalToReplace: acc.ordinalToReplace,
    };
  }, {
    items: [] as { id: number; ordinal: number }[],
    ordinalToReplace: 0,
  });

  const unrankedItems = items.filter(item => item.ordinal === null || item.ordinal === NotApplicableOrdinal);

  const reorderedItems = rankedItems.concat(unrankedItems);

  const dedupedItems: Pick<RankingQuestion.Option, 'id' | 'ordinal'>[] = [];
  reorderedItems.forEach(item => {
    if (dedupedItems.some(f => f.id === item.id)) return;
    dedupedItems.push(item);
  });

  console.log('deduped', dedupedItems);

  return dedupedItems;
}