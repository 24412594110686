import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ReorderIcon from '@mui/icons-material/Reorder';
import { cx } from '@utils';
import * as Table from '@/components/Table';
import styles from './style/Draggable.css';

type Props = {
  depth?:  1 | 2;
  hidden?: boolean;
} & Partial<DraggableProvidedDragHandleProps>;

export const Cell = ({ depth = 1, hidden, ...props }: Props) => {
  const classnames = cx(styles.cell, {
    [styles.hidden]: hidden,
    [styles.child]: depth > 1,
  });

  return (
    <Table.Body.TD className={styles.handle}>
      <div
        className={classnames}
        {...props}>
        <ReorderIcon />
      </div>
    </Table.Body.TD>
  );
};

Cell.displayName = 'Column.DragHandle.Cell';

export const Header = (props: unknown) => {
  return (
    <Table.Header.TH className={styles.handle} />
  );
};

Header.displayName = 'Column.DragHandle.Header';