import { Link } from 'react-router-dom';
import { useProposeTimesLocation } from '@containers/Scheduler/hooks';
import { useSelectUser } from '@containers/Store';
import { hasConsultantRole } from '@utils';
import type { CalendarPickerHeaderParams } from '@/components/CalendarPicker';
import { CalendarPicker, CalendarPickerHeader } from '@/components/CalendarPicker';
import { ExternalCalendarSelector } from '@/components/Calendar/ExternalCalendarSelector';
import CalendarButton from '@/components/Calendar/Button';
import type { SelectingMainProps } from '@/components/Calendar/interfaces';
import styles from '@/components/Calendar/style/Selecting.css';
import { useReapplySubmitButtonDisabledState } from '@/components/Calendar/hooks/useReapplySubmitButtonDisabledState';
import { getAvailableEvents } from '@/components/Calendar/utils';
import { TimeZone } from './TimeZone';

type Props =
  SelectingMainProps;

const SelectingMain = (props: Props) => {
  const me = useSelectUser();
  const events = getAvailableEvents(props.events);
  const dates = events.map(x => x.start);

  useReapplySubmitButtonDisabledState({
    disabled: !props.canSubmit,
    fcContainerId: props.id,
  });

  function SubmitButton() {
    if (!hasConsultantRole(me)) return null;

    return (
      <CalendarButton
        className={styles.button}
        disabled={!props.canSubmit}
        onClick={props.onSubmit}>
        Confirm
      </CalendarButton>
    );
  }

  function renderCustomHeader(params: CalendarPickerHeaderParams) {
    return (
      <CalendarPickerHeader
        className={styles.picker}
        {...params} />
    );
  }

  const location = useProposeTimesLocation({ callId: props.calendar.call.id });

  return (
    <div
      className={styles.root}
      onClick={props.onClickContainer}>

      <div className={styles.header}>
        <div className={styles.title}>Select a Time</div>
        {!hasConsultantRole(me) &&
          <div className={styles.link}>
            <Link to={location}>
              <button className={styles.propose}>
                or propose different times
              </button>
            </Link>
          </div>}
      </div>

      <div className={styles.wrap}>
        <div className={styles.sidebar}>
          <div className={styles.gutter}>
            <CalendarPicker
              dates={dates}
              onChange={props.gotoDate}
              renderCustomHeader={renderCustomHeader} />

            <ExternalCalendarSelector
              calendar={props.calendar}
              onChange={props.onChangeExternalCalendar}
              user={me} />
          </div>
        </div>
        <div className={styles.main}>
          <div
            id={props.id}
            className={styles.calendar} />
          <TimeZone className={styles.tz} />
        </div>
        <SubmitButton />
      </div>
    </div>
  );
};

export { SelectingMain };
export default SelectingMain;