import qs from 'query-string';
import { xhr } from '@services/http';
import type { PostMediaType } from '@enums';
import { FeedItemType } from '@enums';
import { safeJsonDate } from '@utils';
import type { Posts } from '@/types';
import type { Profiles } from './interfaces';

export const fetchFeaturedItems = (data: Profiles.Activity.FetchFeaturedItems.Request) => {
  return xhr.get<Profiles.Activity.FetchFeaturedItems.Response>(`/profiles/${data.userId}/activity/featured`)
    .then(res => ({
      items: res.data.items.map(item => ({
        ...item,
        createdOn: safeJsonDate(item.createdOn),
        media: transformActivityPostMedia(item.media),
      })),
    }));
};

export const fetchItems = (data: Profiles.Activity.FetchItems.Request) => {
  const { userId, ...params } = data;
  const q = qs.stringify(params, { arrayFormat: 'bracket' });

  return xhr.get<Profiles.Activity.FetchItems.Response>(`/profiles/${userId}/activity/feed?${q}`)
  .then(res => ({
    items: res.data.items.map(item => {
      if (item.contentType === FeedItemType.Post) {
        return {
          ...item,
          author: res.data.user,
          createdOn: safeJsonDate(item.createdOn),
          media: transformActivityPostMedia(item.media),
          likeDescription: item.likes,
        };
      }

      return {
        ...item,
        author: res.data.user,
        createdOn: safeJsonDate(item.createdOn),
      };
    }),
    user: res.data.user,
  }));
};

type ActivityPostMedia<T = Date> = Omit<Posts.Media.Media<PostMediaType, T>, 'statistics'>;

const transformActivityPostMedia = (media: ActivityPostMedia<string>): ActivityPostMedia => {
  if (!media) return null;

  return {
    ...media,
    createdOn: safeJsonDate(media.createdOn),
  };
};