import type { MutateOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { Surveys } from '@api/interfaces/surveys';
import { useSurveyResponseIdentifier, useSurveyFormItemSubmit } from '@containers/SurveyForm/Context';

type Variables = { itemId: number };
type Data = Surveys.Response.GoToItem.Response;
type Options = MutateOptions<Data, AxiosError, Variables> & ICallId;

export const useSubmitItem = ({ callId, ...options }: Options) => {
  const responseIdentifier = useSurveyResponseIdentifier();

  const { handler } = useSurveyFormItemSubmit();

  return useMutation({ mutationKey: ['survey-response-go-to-item', responseIdentifier], mutationFn: ({ itemId }: Variables) => {
    return handler({
      itemId,
    });
  }, ...options });
};