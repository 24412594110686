import { group as initialState } from '@store/initial-state';
import * as types from '@store/action-types';
import type {
  AppDataFetchedAction,
  AppStateRehydratedAction,
  Group,
} from '@store/interfaces';

type Actions =
  | AppDataFetchedAction
  | AppStateRehydratedAction
  | Group.BrandingUpdated.Action
  | Group.SettingsUpdated.Action;

function groupReducer(state: Store.Group = initialState, action: Actions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
      return (action.group || {}) || state;

    case types.APP_STATE_REHYDRATED:
      return JSON.stringify(action.group) === JSON.stringify(state)
        ? state
        : action.group;

    case types.GROUP_BRANDING_UPDATED:
      return {
        ...state,
        branding: {
          ...state.branding,
          ...action.branding,
        },
      };

    case types.UPDATE_GROUP_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };

    default:
      return state;
  }
}

export default groupReducer;
