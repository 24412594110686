import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useResetMedicalOnboardingQueries } from '@containers/MedicalOnboarding/hooks';
import type { SurveyActionType } from '@enums';
import { getLocationFor } from '@utils';
import { useDismissProjectRequestNotification } from './useDismissProjectRequestNotification';

type Params = IProjectId;

export const useTerminateProjectOnboarding = ({ projectId }: Params) => {
  const history = useHistory();
  const dismissProjectRequestNotification = useDismissProjectRequestNotification(projectId);
  const resetMedicalQueries = useResetMedicalOnboardingQueries();
  const { pathname } = getLocationFor.onboarding.project({ projectId });

  const handleCompletion = useCallback(() => {

    dismissProjectRequestNotification();

    resetMedicalQueries();

    history.replace(`${pathname}/completion`);

  }, [
    dismissProjectRequestNotification,
    history,
    pathname,
    resetMedicalQueries,
  ]);

  const handleDisqualification = useCallback((type: SurveyActionType) => {

    dismissProjectRequestNotification();

    resetMedicalQueries();

    history.push(`${pathname}/exit`, {
      actionType: type,
    });

  }, [
    dismissProjectRequestNotification,
    history,
    pathname,
    resetMedicalQueries,
  ]);

  return {
    complete: handleCompletion,
    disqualify: handleDisqualification,
  };
};