import { memo } from 'react';
import { Calendar } from 'react-feather';
import type { AdHocCallNotification as N } from '@/types';
import Notification from './Notification.Base';

type Props = {
  item: N;
} & Partial<ChildrenProps>;

export const AdHocCallNotification = memo(({ children, item }: Props) => {

  return (
    <Notification
      Icon={Calendar}
      text={item.payload.body.text}
      title={item.payload.body.title}>
      {children}
    </Notification>
  );
});

export default AdHocCallNotification;