import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from '@/components/Modal/Alert';
import * as $api from '$admin/api/leads';
import { RemoveLeadsContext, SelectedContext, LeadsResultsContext } from './Context';

export const RemoveLeadsContainer = (props: ChildrenProps) => {
  const [toggleAlert, Alert] = useAlert();
  const [selected, selectedDispatch] = useContext(SelectedContext);
  const query = useContext(LeadsResultsContext);

  const mutation = useMutation({ mutationKey: ['delete:leads'], mutationFn: () => {
    return $api.deleteLeads({ leadIds: Array.from(selected.ids) });
  }, onSuccess: () => {
    query.refetch();
    selectedDispatch(v => ({
      ids: new Set(),
      items: [],
    }));
  } });

  return (
    <>
      <RemoveLeadsContext.Provider value={{ toggle: toggleAlert }}>
        {props.children}
      </RemoveLeadsContext.Provider>
      <Alert
        message={'Are you sure you want to remove these leads?'}
        onConfirm={() => { toggleAlert(); mutation.mutate(); }} />
    </>
  );
};