import { useCallback, useMemo } from 'react';
import type { BarSvgProps, ComputedDatum } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import type { OrdinalColorScaleConfigCustomFunction } from '@nivo/colors';
import * as chart from '@containers/SurveyResponses/utils';
import { theme } from './Chart.Common';
import ChartWrapper from './ChartWrapper';
import { useChartAnimation } from './hooks';

type Data = {
  color: string;
  id: number | string;
};

type Props<T extends Data> = {
  data: T[];
  onClick: (key: string | number) => unknown;
} & Omit<BarSvgProps<T>, 'data' | 'onClick' | 'height' | 'width'>;

export const BarChart = <T extends Data>({ data, onClick, ...props }: Props<T>) => {
  const animate = useChartAnimation();

  const handleMouseEnter: (datum: ComputedDatum<T>, event: React.MouseEvent<SVGRectElement>) => void = useCallback((item, e) => {
    e.currentTarget.style.cursor = 'pointer';
  }, []);

  const handleClick: (datum: ComputedDatum<T> & {
    color: string;
  }, event: React.MouseEvent<Element>) => void = useCallback((item, e) => {
    onClick(+item.id);
  }, [onClick]);

  const padding = useMemo(() => {
    return chart.calculateChartPadding(data.length);
  }, [data.length]);

  const height
    = useMemo(() => chart.calculateChartHeight(data.length), [data.length]);

  const colors: OrdinalColorScaleConfigCustomFunction<{ data: Data }> = useCallback((x: { data: Data }) => {
    return x.data.color;
  }, []);

  const items = useMemo(() => [...data].reverse(), [data]);

  return (
    <ChartWrapper style={{ height }}>
      <ResponsiveBar
        data={items}
        groupMode="stacked"
        layout="horizontal"
        indexBy="id"
        colors={colors}
        padding={padding}
        enableGridX={true}
        enableGridY={false}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisLeft={null}
        axisRight={null}
        labelTextColor={'#ffffff'}
        animate={animate}
        theme={theme}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        {...props} />
    </ChartWrapper>
  );
};

export default BarChart;