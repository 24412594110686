import { useCallback } from 'react';
import { usePersonaResearchData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';
import { minCriteria, maxCriteria } from '../utils';
import { useCanSaveList, useListElements } from './list-elements';

export const useCanSavePartnershipCriteria = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minCriteria,
    maxItems: maxCriteria,
  });
};

export const useTemplatePartnershipCriteria = () => {
  const [templateData, dispatch] = usePersonaResearchData();

  const {
    canAdd: canAddCriteria,
    canRemove: canRemoveCriteria,
    items: criteria,
    onAddItem: onAddCriteria,
    onRemoveItem: onRemoveCriteria,
    onUpdateItem: onUpdateCriteria,
  } = useListElements({
    min: minCriteria,
    max: maxCriteria,
    initial: templateData.partnershipCriteria,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-partnership-criteria-updated',
      value: criteria,
    });
  }, [dispatch, criteria]);

  return {
    canAddCriteria,
    canRemoveCriteria,
    onAddCriteria,
    onRemoveCriteria,
    onUpdateCriteria,
    onSubmit,
    criteria,
  };
};