import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { ProjectHonorariaContext } from './Context';
import type * as PH from './interfaces';

type Props = {
  children?: React.ReactNode;
  items?:    PH.Form.Item[];
};

export const ProjectHonorariaContainer = ({ items = [], ...props }: Props) => {
  const query = useQuery({ queryKey: [
    `get:search/currencies`,
  ], queryFn: api.search.fetchCurrencies, refetchOnWindowFocus: false });

  const value = {
    currencies: query.data?.items ?? [],
    items,
  };

  return (
    <ProjectHonorariaContext.Provider value={value}>
      {props.children}
    </ProjectHonorariaContext.Provider>
  );
};

ProjectHonorariaContainer.displayName = 'Project.Honoraria.Container';