import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';

export const useRemoveLogoMutation = <Context = unknown>(options: RemoveLogo.Options<Context> = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation({ mutationKey: [QKey.Groups.Branding.Logo.Delete, group.id], mutationFn: (params: RemoveLogo.Variables) => {
    return api.groups.branding.removeLogo({
      groupId: group.id,
      orientation: params.orientation,
    });
  }, ...options });

  return mutation;
};

export const useUpdateLogoMutation = (options: UpdateLogo.Options = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation({ mutationKey: [QKey.Groups.Branding.Logo.Post, group.id], mutationFn: (params: UpdateLogo.Variables) => {
    return api.groups.branding.updateLogo({
      blob: params.blob,
      groupId: group.id,
      orientation: params.orientation,
    });
  }, ...options });

  return mutation;
};

export declare namespace RemoveLogo {
  export type Variables = Omit<Branding.RemoveLogo.Request, 'groupId'>;

  export type Options<Context = unknown> = {
    onMutate?: (variables: Variables) => unknown;
  } & Omit<
        UseMutationOptions<
          Branding.RemoveLogo.Response,
          unknown,
          Variables,
          Context>, 'onMutate'>;
}

export declare namespace UpdateLogo {
  export type Variables = Omit<Branding.UpdateLogo.Request, 'groupId'>;

  export type Options =
    UseMutationOptions<
      Branding.UpdateLogo.Response,
      unknown,
      Variables>;
}