import { useCallback, useContext, useMemo, useState } from 'react';
import { areIntervalsOverlapping, addMinutes, isAfter, isBefore, isWithinInterval } from 'date-fns';
import { useMutation } from '@tanstack/react-query';
import { buildEventsMap } from '@containers/Calendar/mapping';
import { createIntervalGenerator, dateToEventsMapKey } from '@containers/Calendar/mapping';
import { buildTimeBlocks, buildTimeslots } from '@containers/Calendar/timeslots';
import type { TimeslotItem } from '@containers/Calendar/interfaces';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { useToggle } from '@utils';
import type { CalendarEvent, CalendarRange } from '@/types';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import * as api from '$admin/api';
import { CalendarEvents } from '$admin/Scheduling/CalendarEvents';
import { Editor } from './AddAvailability';
import { CalendarsContext, FrameContext, PersonalCalendarContext } from './Context';
import { useSelectSchedulerCalendar } from './hooks';
import type { AddAvailabilityFrameAction, EventsLookup } from './interfaces';
import { FrameKey } from './interfaces';
import styles from './style/AddAvailability.css';

const MinuteInterval = 5;

type Props = unknown;

export const AddAvailability = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
    frame: useContext(FrameContext),
  };
  const [editing, toggleEditing] = useToggle(false);

  const user = useSelectSchedulerCalendar((ctx.frame.view as AddAvailabilityFrameAction).data);

  const createLookup = useCallback((items: CalendarEvent[]) => {
    return buildEventsMap(buildTimeslots(items, MinuteInterval));
  }, []);

  const [lookup, setLookup] = useState<EventsLookup>(createLookup(user.events));
  const [range, setValue] = useState<CalendarRange<Date>>(null);

  const add = useMutation({ mutationKey: [
    `patch:admin/scheduling/users`,
    range?.end,
    range?.start,
    user.id,
  ], mutationFn: () => {
    const generateUnusedTimes = createIntervalGenerator(buildEventsMap(buildTimeslots(user.events)));
    const times = generateUnusedTimes(range.start, MinuteInterval);
    const overlaps = [range.start, ...times].filter(x => isWithinInterval(x, {
      start: range.start,
      end: range.end,
    }));

    const items = buildEventsMap(user.events as TimeslotItem[])[dateToEventsMapKey(range.start)] || [];

    const results = overlaps.reduce((acc, start) => {
      const end = addMinutes(start, MinuteInterval);

      if (isAfter(end, range.end)) return acc;

      const segment = { start, end };

      return !items.some(item => areIntervalsOverlapping(segment, item))
        ? acc.concat(segment)
        : acc;
    }, [] as CalendarRange<Date>[]);

    return api.scheduling.updatePersonalCalendar({
      new: results,
      removed: [],
      userId: user.id,
    })
    .then(async () => {
      await ctx.calendars.query.refetch();
    });
  }, onSuccess: () => {
    toggleEditing();
    setValue(null);
  } });

  const remove = useMutation({ mutationKey: [
    `patch:admin/scheduling/users`,
    user.id,
  ], mutationFn: (availabilityId: number) => {
    return api.scheduling.updatePersonalCalendar({
      new: [],
      removed: [availabilityId],
      userId: user.id,
    });
  }, onSuccess: () => ctx.calendars.query.refetch() });

  const discardValue = useCallback(() => {
    toggleEditing();
    setValue(null);
  }, [
    setValue,
    toggleEditing,
  ]);

  const value = {
    form: {
      discardValue,
      editing,
      range,
      setValue,
      toggleEditing,
    },
    lookup,
    mutation: add,
  };

  const valid = useMemo(() => {
    if (!range) return false;

    return isBefore(range.start, range.end);
  }, [range]);

  return (
    <PersonalCalendarContext.Provider value={value}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <div className={styles.events}>
              <CalendarEvents
                events={buildEventsMap(buildTimeBlocks(user.events))}
                onChange={data => remove.mutate(data.item.metadata.availabilityId)} />
            </div>
            <div className={styles.editor}>
              <Editor interval={MinuteInterval} />
            </div>
          </div>
          <ButtonSet className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              disabled={add.isLoading}
              color="silver"
              onClick={() => ctx.frame.goToFrame({ frame: FrameKey.Booking })}>
              Back
            </ButtonOutlined>
            <ButtonActivityIndicator
              className={styles.btn}
              implicitDisable={false}
              disabled={!valid}
              loading={add.isLoading}
              onClick={() => add.mutate()}>
              Complete
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </PersonalCalendarContext.Provider>
  );
};

AddAvailability.displayName = 'Frame.AddAvailability';