import { useCallback, useEffect, useState } from 'react';
import css from 'intl-tel-input/build/css/intlTelInput.css';
import { cx, useUseableCSS } from '@utils';
import type { FocusableClasses } from '@/components/Focusable/interfaces';
import { TelInput } from '@/components/TelInput/TelInput';
import overrides from 'static/css/useable/intl-tel-input.css';
import type { TelInputProps, TelInputState } from './interfaces';

type Props = {
  children?:     React.ReactNode;
  className?:    string;
  classes?: {
    focusable?: FocusableClasses;
    input?:     string;
    invalid?:   string;
    root?:      string;
  };
  defaultValue?: string;
  editing?:      boolean;
  id?:           string;
} & Pick<TelInputProps,
  | 'invalidClassName'
  | 'name'
  | 'onChange'
  | 'onChangeCountryCode'
  | 'style'>;

const PhoneInput = ({
  classes = {},
  editing = true,
  id = 'phone',
  onChangeCountryCode,
  onChange,
  style = {},
  ...props
}: Props) => {
  useUseableCSS(css, overrides);

  const computeDefaultMeta = useCallback(() => {
    return {
      empty: (props.defaultValue ?? '').length === 0,
      valid: true,
    };
  }, [props.defaultValue]);

  const [value, dispatch] = useState<string>(props.defaultValue ?? '');
  const [meta, setMeta] = useState<Omit<TelInputState, 'value'>>(computeDefaultMeta());

  const dispatchChange = useCallback((data: TelInputState) => {
    dispatch(data.value);
    setMeta({
      empty: data.empty,
      valid: data.valid,
    });
  }, []);

  const handleChangeCountryCode = useCallback((data: TelInputState) => {
    dispatchChange(data);
    onChangeCountryCode(data);
  }, [dispatchChange, onChangeCountryCode]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, data: TelInputState) => {
    dispatchChange(data);
    onChange(e, data);
  }, [dispatchChange, onChange]);

  const reinitializeState = useCallback(() => {
    dispatch(props.defaultValue);
    setMeta(computeDefaultMeta());
  }, [
    computeDefaultMeta,
    dispatch,
    props.defaultValue,
    setMeta,
  ]);

  useEffect(() => {

    if (props.defaultValue) {
      reinitializeState();
    }

  }, [
    reinitializeState,
    props.defaultValue,
  ]);

  useEffect(() => {

    if (!editing) {
      reinitializeState();
    }

  }, [
    reinitializeState,
    editing,
  ]);

  if (editing === false) return <>{props.children}</>;

  const classnames = {
    focusable: classes.focusable || {},
    input: classes.input,
    invalid: cx(props.invalidClassName, classes.invalid),
    root: cx(props.className, classes.root),
  };

  return (
    <TelInput
      classes={classnames}
      id={id}
      name={props.name}
      onChange={handleChange}
      onChangeCountryCode={handleChangeCountryCode}
      invalid={!meta.empty && !meta.valid}
      style={style}
      value={value} />
  );
};

export { PhoneInput };
export default PhoneInput;