import type { StepperActions } from '@utils';
import { createNamedContext } from '@utils';
import type * as PostCreation from './interfaces';

export const ContentPublisherContext = createNamedContext<PostCreation.ContentPublisherContextValue | undefined>(undefined, 'PostCreation.ContentPublisher.Context');

export const SearchConsultantsContext = createNamedContext<PostCreation.SearchMembers>(undefined, 'PostCreation.SearchConsultants.Context');
export const SearchTopicsContext = createNamedContext<PostCreation.SearchTopics>(undefined, 'PostCreation.SearchTopics.Context');

export const TextPublishContext = createNamedContext<PostCreation.Text.PublishContextValue>(undefined, 'PostCreation.Text.Publish.Context');
export const TextStateContext = createNamedContext<PostCreation.Text.StateContextValue>(undefined, 'PostCreation.Text.State.Context');
export const TextURLPreviewContext = createNamedContext<PostCreation.Text.URLPreviewContextValue>(undefined, 'PostCreation.Text.URLPreview.Context');

export const TopicTagsContext = createNamedContext<PostCreation.TopicTags.StateContextValue | undefined>(undefined, 'PostCreation.Topic.Tags.Context');

export const VideoStateContext = createNamedContext<PostCreation.Video.StateContextValue | undefined>(undefined, 'PostCreation.Video.State.Context');
export const VideoObjectURLContext = createNamedContext<PostCreation.Video.VideoObjectURLContextValue | undefined>(undefined, 'PostCreation.Video.ObjectURL.Context');
export const VideoPostIdentifierContext = createNamedContext<string | undefined>(undefined, 'PostCreation.Video.PostIdentifer.Context');
export const VideoStepperContext = createNamedContext<StepperActions | undefined>(undefined, 'PostCreation.Video.Stepper.Context');
export const VideoUploaderPublishContext = createNamedContext<PostCreation.Video.Uploader.PublishContextValue | undefined>(undefined, 'PostCreation.Video.Uploader.PublishPost.Context');
export const VideoUploaderContext = createNamedContext<PostCreation.Video.Uploader.ContextValue | undefined>(undefined, 'PostCreation.Video.Uploader.ContextValue');

export const PostCreationSubscriptionContext = createNamedContext<PostCreation.Subscription.ContextValue | undefined>(undefined, 'PostCreation.Subscription.Context');