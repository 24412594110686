import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { TranscriptComment } from '@/types/transcribe.rich-text';

type Props = {
  transcriptId: number;
};

export const useTranscriptionComments = (props: Props, options: Options = {}) => {

  const query = useQuery({
    queryKey: ['transcript-comments', props],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1] as Props;
      return api.transcripts.getTranscriptComments(params)
        .then(res => res.comments);
    },
    ...options,
  });

  return query;
};

type Data = TranscriptComment[];
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;