import { Logo } from '@/components/Branding';
import { useSocialAuth } from '$website/containers/Auth/hooks/useSocialAuth';
import * as AuthButton from '$website/components/AuthButton';
import styles from '$website/components/AuthModal/style/AuthModal.css';
import { PrivacyPolicy } from './PrivacyPolicy';
import * as Layout from './Layout';

type Props = {
  onChangeView: (view: 'signup:email' | 'login') => void;
};

export const Strategy = (props: Props) => {
  const social = useSocialAuth();

  return (
    <>
      <Layout.Header>
        <Layout.Row>
          <Logo.Square size={55} />
        </Layout.Row>

        <Layout.Row>
          <Layout.Title>Welcome</Layout.Title>
        </Layout.Row>

        <Layout.Row>
          <Layout.Subtitle>Sign up to continue to Sentiment.</Layout.Subtitle>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        {/* <Layout.Row>
          <AuthButton.Google onClick={social.authorizeGoogle}>
            Sign up with Google
          </AuthButton.Google>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Facebook onClick={social.authorizeFacebook}>
            Sign up with Facebook
          </AuthButton.Facebook>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Apple onClick={social.authorizeApple}>
            Sign up with Apple
          </AuthButton.Apple>
        </Layout.Row>

        <Layout.Row>
          <Layout.ThematicBreak>
            or
          </Layout.ThematicBreak>
        </Layout.Row> */}

        <Layout.Row>
          <AuthButton.Email onClick={() => props.onChangeView('signup:email')}>
            Sign up with Email
          </AuthButton.Email>
        </Layout.Row>

        <Layout.Row>
          <Layout.Subtitle>
            <div className={styles.loginLink} onClick={() => props.onChangeView('login')}>
              Already an expert? Log in.
            </div>
          </Layout.Subtitle>
        </Layout.Row>

      </div>

      <PrivacyPolicy />
    </>
  );
};

Strategy.displayName = 'Frame.Signup.Strategy';