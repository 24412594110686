import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Surveys } from '@consts/querykey';
import * as $api from '@api';

type Props = Partial<ISurveyId>;
type Options = UseMutationOptions;

export const useDiscardSurveyDrafts = ({ surveyId }: Props, options: Options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({ mutationFn: () => {
    return $api.surveys.draft.discardSurveyDrafts({ surveyId });
  }, ...options, onSuccess: (data, variables, context) => {
    queryClient.setQueryData(Surveys.Drafts.Get({ surveyId }), []);

    if (options?.onSuccess) {
      options?.onSuccess(data, variables, context);
    }
  } });
};