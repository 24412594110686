import type { ReactDatePickerProps } from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';
import pickerCSS from 'react-datepicker/dist/react-datepicker.css';
import { cx, useUseableCSS } from '@utils';
import styles from './style.css';

type Props = ReactDatePickerProps<never, true>;

export const DateRangePicker = ({
  className,
  ...rest
}: Props) => {

  useUseableCSS(pickerCSS);

  return (
    <ReactDatePicker
      className={cx(styles.range, className)}
      wrapperClassName={styles.wrap}
      selectsRange={true}
      {...rest} />
  );
};

export default DateRangePicker;