import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import * as $api from '@api';

type Props = ICallId & ISurveyId;

export const useSurveyFormQuery = ({ callId, surveyId }: Props) => {
  return useQuery({ queryKey: ['capture-sheet-get-project-survey-form', callId, surveyId], queryFn: () => {
    return $api.surveys.captureSheet.getSurveyForm({
      callId,
      surveyId,
    });
  }, enabled: false, retry: 1, staleTime: Infinity, onError: err => Sentry.captureException(err) });
};