import Color from 'color';
import { color } from '@utils';
import type { SurveyThemingPaletteBase } from './interfaces';

export const transformPalette = (palette: SurveyThemingPaletteBase) => {
  if (!palette) return null;

  return {
    primary: {
      main: palette.primary.main,
      light: Color(palette.primary.main)
        .desaturate(0.06)
        .lightness(75)
        .hex(),
      text: color.isDark(palette.primary.main) ? `var(--pri-01)` : `var(--black-l)`,
    },
  };
};