import type { SurveyLogic } from '@/types/survey';
import type {
  RemoveSurveyConditionContextValue } from './context';
import {
  useSurveyConditionsBuilder,
  RemoveSurveyConditionContext,
} from './context';

type Props = {
  item: SurveyLogic.SurveyCondition;
} & ChildrenProps;

export const RemoveConditionContainer = (props: Props) => {

  const ctx = useSurveyConditionsBuilder();

  const removeCtx: RemoveSurveyConditionContextValue = {
    canRemove: ctx.canRemoveItem,
    removeItem: ctx.removeItem(props.item.identifier),
  };

  return (
    <RemoveSurveyConditionContext.Provider value={removeCtx}>
      {props.children}
    </RemoveSurveyConditionContext.Provider>
  );
};