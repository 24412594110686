import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetTranscript } from '$admin/api/interfaces/transcripts';
import * as api from '$admin/api/transcripts';

type Data = GetTranscript.Response;
type Params = {
  transcriptId: number;
};
type QueryKey = readonly [string, Params];

export function useTranscript(
  data: Params,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  const queryKey = AdminGetTranscriptQueryKey(data);

  return useQuery({ queryKey, queryFn: ({ queryKey }) => {
    const { transcriptId } = queryKey[1];

    return api.getTranscript({
      transcriptId,
    });
  }, ...options });
}

export function AdminGetTranscriptQueryKey(data: Params) {
  return ['admin.transcript', data] as QueryKey;
}