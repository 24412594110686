import { memo, useContext } from 'react';
import { AppThemingContext } from '@containers/Branding/Context';
import { TabIndicatorProps } from './interfaces';
import styles from './style.css';

type Props =
  TabIndicatorProps;

const TabIndicator = memo((props: Props) => {
  const ctx = useContext(AppThemingContext);

  const backgroundColor = ctx?.enabled ? ctx?.palette?.primary?.main : undefined;

  const position = props.index + 1;
  const tabsCount = props.count;
  const left = `${(((position) / tabsCount) - (1 / tabsCount)) * 100}%`;
  const width = `${(1 / tabsCount) * 100}%`;

  return (
    <div
      className={styles.indicator}
      style={{ backgroundColor, left, width }} />
  );
});

export default TabIndicator;