import type { LogicItemBuilder } from './interfaces';
import { ConditionsBuilder } from './interfaces';
import { surveyConditionsReducer } from './reducer.conditions';

export * from './reducer.conditions';
export * from './reducer.match';

export function surveyLogicReducer(state: LogicItemBuilder.State, action: LogicItemBuilder.Action): LogicItemBuilder.State {

  const newState = {
    ...state,
    action: actionReducer(state.action, action),
    // conditions: surveyConditionsReducer(state.conditions, action as ConditionsBuilder.Action),
  };

  logAction(state, newState, action);

  return newState;
}

function actionReducer(state: LogicItemBuilder.State['action'], action: LogicItemBuilder.Action): LogicItemBuilder.State['action'] {

  switch (action.type) {

    case 'action-updated':
      return action.payload;

    default:
      return state;
  }
}

function logAction(oldState: LogicItemBuilder.State, newState: LogicItemBuilder.State, action: LogicItemBuilder.Action) {
  if (process.env.VANCERY_ENV !== 'production') {
    console.groupCollapsed('Survey Logic Builder Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}