import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { TableItem } from './interfaces';

export default function ResultClickContainer({ item, children, className }: ResultClickContainerProps) {
  const history = useHistory();
  const path =  useMemo(() => `${item.path.startsWith('/') ? '' : '/'}${item.path}`, [item.path]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push(path);
  }, [path, history]);

  return (
    <a
href={path}
onClick={handleClick}
className={className}>
      {children}
    </a>
  );
}

export { ResultClickContainer };

type ResultClickContainerProps = {
  item: TableItem;
  children: React.ReactNode;
  className?: string;
};