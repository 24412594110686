import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { SurveyMessage } from '@/types';

type MessageBuilderDelete = () => void;
type MessageBuilderSaveContextValue = {
  canSave: boolean;
  onSave: () => void;
};
type CancelValue = () => void;

export const SurveyMessageBuilderItemContext = createNamedContext<SurveyMessage>(undefined, 'SurveyMessageBuilderItemContext');
export const SurveyMessageBuilderDeleteContext = createNamedContext<MessageBuilderDelete>(undefined, 'SurveyMessageBuilderDeleteContext');
export const SurveyMessageBuilderSaveContext = createNamedContext<MessageBuilderSaveContextValue>(undefined, 'SurveyMessageBuilderSaveContext');
export const SurveyMessageBuilderCancelContext = createNamedContext<CancelValue>(undefined, 'SurveyMessageBuilderCancelContext');

export const useSurveyMessageBuilderItemContext = () => useContext(SurveyMessageBuilderItemContext);
export const useDeleteSurveyMessage = () => useContext(SurveyMessageBuilderDeleteContext);
export const useSaveMessageContext = () => useContext(SurveyMessageBuilderSaveContext);
export const useCancelMessageEditing = () => useContext(SurveyMessageBuilderCancelContext);

