import { useState } from 'react';
import { Copy, ExternalLink, Check } from 'react-feather';
import * as $tableau from '@services/api/tableau';
import { useSelectUser } from '@containers/Store';
import { Tooltip } from '@presentation/Tooltip';
import { Button } from '@/components/Button';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useInvalidateAPIKeyQuery } from './hooks';
import styles from './style/TableauIntegrationModal.css';

type Props = {
  existingKey: string;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const TableauIntegrationModal = (props: Props) => {
  const [apiKey, setApiKey] = useState(props.existingKey);
  const [showWarning, setShowWarning] = useState(false);
  const invalidateKeyQuery = useInvalidateAPIKeyQuery();
  const user = useSelectUser();
  const wdcUrl = `${window.location.protocol}//${window.location.hostname}/tableau/web-data-connector-v1.html`;

  function getNewAPIKey() {
    $tableau.requestNewAPIKey().then(k => changeKey(k.key));
  }

  function deleteConnection() {
    $tableau.deleteAPIKeys().then(() => changeKey(null));
  }

  function changeKey(newKey: string) {
    invalidateKeyQuery();
    setApiKey(newKey);
  }

  function deleteButtonClick() {
    if (!showWarning) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      deleteConnection();
    }
  }

  return (
    <Modal {...props}>
      <Header text={'Tableau Integration'} />
      <div className={styles.instruction}>You can integrate your Sentiment survey data with Tableau by adding the Sentiment Web Data Connector as a Data Source</div>
      <div className={styles.externalDoc}>For more information on how to add a Web Data Connector please review this documentation for <ExternalDocLink link={'https://help.tableau.com/current/pro/desktop/en-us/examples_web_data_connector.htm'} text={'Desktop'} /> and <ExternalDocLink link={'https://help.tableau.com/current/server/en-us/datasource_wdc.htm'} text={'Server'} /></div>

      {apiKey &&
        <>
          <div>Provide this as the Web Data Connector Url <CopyableInput value={wdcUrl} /></div>
          <div className={styles.authValues}>
            <div className={styles.instruction}>
              Enter these authentication values:
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.label}>User ID</span> <CopyableInput value={user.id} />
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.label}>API Key</span> <CopyableInput value={apiKey} />
            </div>
          </div>
          <div className={styles.delete}>
            {showWarning &&
              <div className={styles.warning}>
                Deleting this connection will prevent any connected Tableau Desktops or Tableau Servers from pulling new data. Are you sure you wish to continue?
              </div>
            }
            <Button.Destructive onClick={deleteButtonClick}>
              Delete Connection
            </Button.Destructive>
          </div>
        </>
      }
      {!apiKey &&
        <Button onClick={getNewAPIKey}>
          Request an API Key
        </Button>
      }
    </Modal>
  );
};

type DocLinkProps = {
  link: string;
  text: string;
};
const ExternalDocLink = ({ link, text }: DocLinkProps) => {
  return (
    <a
      className={styles.externalLink}
      target={'_blank'}
      href={link}
      rel={'noreferrer'}>
      {text}<ExternalLink className={styles.externalIcon} size={14} />
    </a>);
};

type CopyableProps = {
  value: string | number;
};
const CopyableInput = ({ value }: CopyableProps) => {
  const [showCopied, setShowCopied] = useState(false);
  function copyToClipboard() {
    window.navigator.clipboard.writeText(value.toString());
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 1500);
  }
  return (
    <div className={styles.copyContainer}>
      <span className={styles.copyValue}>{value}</span>
      {showCopied &&
        <Tooltip
          title={'Copied!'}
          open={true}
          placement={'top'}>
          <span>
            <Check
              color={'green'}
              className={styles.copy}
              onClick={copyToClipboard} />
          </span>
        </Tooltip>
      }
      {!showCopied &&
        <Copy
          className={styles.copy}
          onClick={copyToClipboard} />
      }
    </div>
  );
};

export const useTableauModal = () => useModal(TableauIntegrationModal);