import { SurveyQuestionType } from '@enums';
import type { SurveyAggregate, SurveyQuestion, SurveyQuestionOption } from '@/types';

const sortByOrdinal = [SurveyQuestionType.MultipleChoice, SurveyQuestionType.ExclusiveOptions];
export const sortOptions = (question: SurveyQuestion, responses: SurveyAggregate.Options.Map) => (a: SurveyQuestionOption, b: SurveyQuestionOption) => {
  return sortByOrdinal.includes(question.typeId)
    ? a.ordinal - b.ordinal
    : responses[b.base.id].pct - responses[a.base.id].pct;
};

export const OptionsColors = [
  '#346393',
  '#546eb2',
  '#199b9b',
  '#a3cb5f',
  '#d8482b',
  '#8d1130',
  '#1f3b58',
  '#31416c',
  '#0f5d5d',
  '#65872c',
  '#832a18',
  '#550a1d',
  '#4782be',
  '#768bc1',
  '#22d4d4',
  '#b5d57f',
  '#e06d55',
  '#cc1945',
  '#2a4f76',
  '#415791',
  '#147c7c',
  '#87b43a',
  '#af3820',
  '#710e26',
  '#75a1ce',
  '#98a8d1',
  '#54e4e4',
  '#c8e09f',
  '#e89180',
  '#e8436c',
  '#1a314a',
  '#29365a',
  '#0d4e4e',
  '#547025',
  '#6d2314',
  '#470918',
  '#5e92c6',
  '#8799c9',
  '#38dfdf',
  '#bfdb8f',
  '#e47f6b',
  '#e42353',
  '#244567',
  '#394c7e',
  '#116d6d',
  '#769d33',
  '#99311c',
  '#630c22',
  '#8cb1d6',
  '#aab7d8',
];