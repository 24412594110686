import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { Surveys } from '@consts/querykey';

type Data = Awaited<ReturnType<typeof $api.surveys.draft.getSurveyDrafts>>;
type Props = Partial<ISurveyId> & UseQueryOptions<Data>;

export const useGetSurveyDrafts = ({ surveyId, ...opts }: Props) => {
  return useQuery({ queryKey: Surveys.Drafts.Get({ surveyId }), queryFn: () => {
    return $api.surveys.draft.getSurveyDrafts({ surveyId });
  }, ...opts, refetchOnWindowFocus: false, refetchInterval: false });
};