import type { Dispatch, SetStateAction } from 'react';
import type { LeadFormData } from '$website/types';

export type TieredDescriptor = {
  id:    string;
  value: string | number;
};

export type UsageDescriptor = {
  id:    string;
  value: string | number;
};

export type FeatureCell = {
  id:       ServiceDescriptor['id'];
  included: boolean;
  type:     'feature';
  value:    ServiceDescriptor['name'];
};

export type TieredCell = {
  type: 'tiered';
} & TieredDescriptor;

export type UsageCell = {
  type: 'usage';
} & UsageDescriptor;

export type ServiceDescriptor = {
  id:   string;
  name: string;
};

export type SpacerCell = {
  id:    string;
  type:  'spacer';
  value: string;
};

export type HeaderCell = {
  id:    string;
  type:  'header';
  value: string;
};

type PlanPayment = {
  monthly: number;
  yearly:  number;
};

export type Plan<T extends PlanIdentifier = PlanIdentifier> = {
  id:          T;
  name:        string;
  description: string;
  features:    string[];
  payment:     PlanPayment;
  tiered:      TieredDescriptor[];
  usage:       UsageDescriptor[];
};

type PlanIdentifier =
  | ColumnIdentifer.Customer
  | ColumnIdentifer.Enterprise
  | ColumnIdentifer.Market;

export enum ColumnIdentifer {
  Customer   = 'customer',
  Enterprise = 'enterprise',
  Market     = 'market',
  Service    = 'service',
}

export type PlanCell =
  | HeaderCell
  | FeatureCell
  | SpacerCell
  | TieredCell
  | UsageCell;

export type PricingItem = {
  [ColumnIdentifer.Service]:    ServiceCell;
  [ColumnIdentifer.Customer]:   PlanCell;
  [ColumnIdentifer.Enterprise]: PlanCell;
  [ColumnIdentifer.Market]:     PlanCell;
};

export type ServiceCell = {
  type?: 'header' | 'spacer';
} & ServiceDescriptor;

export type Cell =
  | PlanCell
  | ServiceDescriptor;

export type CustomProps = {
  monthly:    boolean;
  plans: {
    [ColumnIdentifer.Customer]:   Plan<ColumnIdentifer.Customer>;
    [ColumnIdentifer.Enterprise]: Plan<ColumnIdentifer.Enterprise>;
    [ColumnIdentifer.Market]:     Plan<ColumnIdentifer.Market>;
  };
  setMonthly: Dispatch<SetStateAction<boolean>>;
};

export type CompactServices<T extends PlanIdentifier = PlanIdentifier> = {
  id:       T;
  features: ServiceDescriptor[];
  tiered:   ServiceDescriptor[];
  usage:    ServiceDescriptor[];
};

export type PricingContextValue = {
  compact: {
    items: CompactServices[];
  };
  table: {
    items: PricingItem[];
  };
} & CustomProps;

export type PricingModalContextValue = [
  boolean,
  () => unknown,
];

export type PricingModalPlanContextValue = [
  PlanIdentifier,
  (identifier: PlanIdentifier) => void,
];

export type PricingFormValues = LeadFormData;

export type Row =
  & PricingItem
  & CustomProps;