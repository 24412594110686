import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import type { Users } from '@api/interfaces';
import type { UserSettingsProps } from '@/types';

type Data = Users.UpdateSettings.Response;
type Variables = Partial<UserSettingsProps>;
type Context = UserSettingsProps;

type Options = UseMutationOptions<Data, AxiosResponse, Variables, Context>;

const selectUserId = (state: Store.State) => state.user.id;
const selectUserSettings = (state: Store.State) => state.user.settings;

export const useUpdateUserSettings = (options?: Options) => {
  const settings = useSelector(selectUserSettings);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  return useMutation({ mutationKey: ['update-user-settings'], mutationFn: (data: Variables) => {

    dispatch(actions.userSettingsUpdated({
      settings: {
        ...settings,
        ...data,
      },
    }));

    return api.users.updateSettings({
      ...data,
      userId,
    }).catch(e => {
      dispatch(actions.userSettingsUpdated({ settings }));
      throw e;
    });
  }, ...options });
};