import { useCallback, useState } from 'react';
import * as api from '@api';
import type {
  IProjectSurveyResponseId,
} from '@/types';
import type {
  StarredResponseAnswersContextValue } from './Context';
import {
  StarredResponseAnswersContext,
} from './Context';

type Props = {
  children: React.ReactNode;
  initialValues: number[];
} & IProjectId
  & IProjectSurveyResponseId;

export const StarredAnswersContainer = (props: Props) => {
  const [starred, setStarred] = useState<number[]>(props.initialValues);

  const onToggle = useCallback((surveyVersionQuestionId: number) => () => {
    const value = !starred.includes(surveyVersionQuestionId);

    return api.projects.surveys.starProjectSurveyResponseAnswer({
      projectId: props.projectId,
      projectSurveyResponseId: props.projectSurveyResponseId,
      surveyVersionQuestionId,
      value,
    }).then(() => {
      if (value) {
        setStarred([...starred, surveyVersionQuestionId]);
      } else {
        setStarred(starred.filter(f => f !== surveyVersionQuestionId));
      }
    });
  }, [
    props.projectId,
    props.projectSurveyResponseId,
    setStarred,
    starred,
  ]);

  const value: StarredResponseAnswersContextValue = {
    onToggle,
    starred,
  };

  return (
    <StarredResponseAnswersContext.Provider value={value}>
      {props.children}
    </StarredResponseAnswersContext.Provider>
  );
};

export default StarredAnswersContainer;