import { Fragment } from 'react';
import { TagSummaryItemContext } from '@containers/ConferenceTag.Summary';
import type { ConferenceTagSummaryItem } from '@/types';
import { ConferenceTagSummary } from './TagSummary';
import styles from './style/TagSummary.css';

type Props = {
  items: ConferenceTagSummaryItem[];
};

export const ConferenceTagSummaries = ({ items }: Props) => {

  return (
    <>
      {items.map(item => {
        const hasChildren = !!item.children.length;

        return (
          <Fragment key={item.tag.id}>
            <TagSummaryItemContext.Provider value={item}>
              <ConferenceTagSummary />
            </TagSummaryItemContext.Provider>

            {hasChildren && (
              <div className={styles.children}>
                {item.children.map(child => (
                  <TagSummaryItemContext.Provider value={child} key={child.tag.id}>
                    <ConferenceTagSummary />
                  </TagSummaryItemContext.Provider>
                ))}
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};