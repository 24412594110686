import { useCallback, useState } from 'react';
import { Redirect, generatePath, useHistory } from 'react-router-dom';
import type { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryDownloader } from '@/containers/QueryDownloader';
import * as $api from '@/services/api/workspaces.file';
import { path, querykey } from '@consts';
import { FileSummaryContext } from './Context';

type Props = {
  fileId: number;
} & ChildrenProps;

export function SummaryContainer(props: Props) {
  const history = useHistory();
  const [isRegenerating, setIsRegenerating] = useState(false);

  const { data, isLoading, refetch, ...query } = useQuery({ queryKey: querykey.Workspaces.FileSummary.Get({ fileId: props.fileId }), queryFn: () => {
    return $api.getFileSummary({
      fileId: props.fileId,
    });
  }, onError: (error: AxiosResponse) => {
    if (error.status === 404) {
      history.replace(path.Workspaces.FileNotFound);
    }
  }, onSuccess: data => {
    if (data?.summary?.status === 'pending') {
      setIsRegenerating(true);
    } else {
      setIsRegenerating(false);
    }
    return data;
  } });

  const regenerateMutation = useMutation({ mutationKey: querykey.Workspaces.FileSummary.Post({ fileId: props.fileId }), mutationFn: () => {
    return $api.regenerateFileSummary({
      fileId: props.fileId,
    });
  }, onMutate: () => {
    setIsRegenerating(true);
  }, onSuccess: () => {
    refetch();
  } });

  const handleRegenerate = useCallback(() => {
    return regenerateMutation.mutateAsync();
  }, [regenerateMutation]);

  const downloader = useQueryDownloader({
    queryKey: [
      `brand-insights:chat:download`,
      props.fileId,
    ],
    queryFn: ({ queryKey: [, fileId] }) => {
      return $api.downloadFileSummary({ fileId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.filename,
        type: 'blob',
        value: res.blob,
      }),
    },
  });

  const handleDownload = useCallback(() => {
    return downloader.download({
      title: `Generating Export`,
      name: `File Summary`,
      extension: 'docx',
    });
  }, [downloader]);

  if (query.isFetched && !data?.summary) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Workspaces.FileNotFound} />;
  }

  const value = {
    summary: data?.summary,
    isLoading,
    download: handleDownload,
    refetch,
    regenerate: handleRegenerate,
    isRegenerating,
  };

  return (
    <FileSummaryContext.Provider value={value}>
      {props.children}
    </FileSummaryContext.Provider>
  );
}

export default SummaryContainer;