import { useCallback } from 'react';
import * as api from '@api';
import type {
  GroupUserParams,
  ProjectUserParams } from './Context';
import {
  DocumentDownloadContext,
} from './Context';

type Props = {
  children: React.ReactNode;
};

export const DocumentDownloadContainer = (props: Props) => {
  const downloadGroupUserDocument = useCallback((data: GroupUserParams) => {
    return api.groups.downloadConsultantGroupDocument(data);
  }, []);

  const downloadProjectUserDocument = useCallback((data: ProjectUserParams) => {
    return api.groups.downloadConsultantProjectDocument(data);
  }, []);

  const value = {
    downloadGroupUserDocument,
    downloadProjectUserDocument,
  };

  return (
    <DocumentDownloadContext.Provider value={value}>
      {props.children}
    </DocumentDownloadContext.Provider>
  );
};