import { memo, useMemo } from 'react';
import type { SurveyLogic } from '@/types';
import { SurveyQuestion } from '@/types';
import { useSurveyBuilderState, useSurveyClassifications } from '@/containers/SurveyBuilder';

type Props = {
  condition: SurveyLogic.ClassificationCondition;
};

export const ClassificationCondition = ({ condition }: Props) => {

  const classifications = useSurveyClassifications();

  const classification = useMemo(() => {
    return classifications.find(f => f.identifier === condition.data.identifier);
  }, [classifications, condition.data.identifier]);

  return (
    <>
      Classified as {classification.name}
    </>
  );
};