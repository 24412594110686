import { useCallback } from 'react';
import type { SurveyQuestionFormOption } from '@/types/survey';
import { cx } from '@utils';
import { useMatrixFormContext } from '@/components/SurveyForm/context';
import type { MatrixForm } from '@/components/SurveyForm/interfaces';
import { SurveyMatrixListItem } from './ListItem';
import styles from './style/List.css';

type Props = {
  className?: string;
};

export const SurveyMatrixList = (props: Props) => {

  const {
    onSelect,
    onUpdateRowText,
    ...ctx
  } = useMatrixFormContext();

  const renderGroupedByRowItem = useCallback((row: MatrixForm.Row) => {
    const rowText = ctx.rowTextMap[row.id];

    const handleSelect = (optionId: number) => onSelect({
      optionId,
      rowId: row.id,
    });

    const selected = ctx.options
      .map(m => m.id)
      .filter(optionId => ctx.values[row.id][optionId]);

    return (
      <SurveyMatrixListItem
        key={row.id}
        selected={selected}
        options={ctx.options.map(m => ({
          id: m.id,
          value: m.value,
        }))}
        item={{
          id: row.id,
          isOpenEnded: row.metadata.isOpenEnded,
          value: row.value,
        }}
        openEndedValue={rowText}
        updateOpenEndedValue={onUpdateRowText(row.id)}
        onSelection={handleSelect}
        variant={ctx.variant} />
    );
  }, [
    ctx.options,
    onSelect,
    onUpdateRowText,
    ctx.rowTextMap,
    ctx.values,
    ctx.variant,
  ]);

  const renderGroupedByRow = useCallback(() => {
    return ctx.rows.map(renderGroupedByRowItem);
  }, [
    ctx.rows,
    renderGroupedByRowItem,
  ]);

  const renderGroupedByColumnItem = useCallback((option: SurveyQuestionFormOption) => {

    const handleSelect = (rowId: number) => onSelect({
      optionId: option.id,
      rowId,
    });

    const selected = ctx.rows
      .map(m => m.id)
      .filter(rowId => ctx.values[rowId][option.id]);

    return (
      <SurveyMatrixListItem
        key={option.id}
        selected={selected}
        options={ctx.rows.map(m => ({
          id: m.id,
          value: m.value,
        }))}
        item={{
          id: option.id,
          isOpenEnded: false,
          value: option.value,
        }}
        openEndedValue={null}
        updateOpenEndedValue={null}
        onSelection={handleSelect}
        variant={ctx.variant} />
    );
  }, [
    ctx.rows,
    ctx.values,
    ctx.variant,
    onSelect,
  ]);

  const renderGroupedByColumn = useCallback(() => {
    return ctx.options.map(renderGroupedByColumnItem);
  }, [
    ctx.options,
    renderGroupedByColumnItem,
  ]);

  return (
    <div className={cx(styles.list, props.className)}>
      {ctx.groupedBy === 'row'
        ? renderGroupedByRow()
        : renderGroupedByColumn()
      }
    </div>
  );
};

export default SurveyMatrixList;