import type { NewsArticles } from '@/types';
import { ArticleTopics } from './Topics';
import styles from './style/Post.Footer.css';

type Props = {
  item: NewsArticles.NewsArticle;
};

export const Footer = ({ item }: Props) => {

  return (
    <div className={styles.root}>
      {!!item.topics.length &&
        <ArticleTopics
          articleId={item.id}
          items={item.topics} />}
    </div>
  );
};

Footer.displayName = 'NewsArticle.Footer';