import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { pathname } from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import type { ActionableLinkType } from '@enums';
import { BookingConfirmed, LinkExpired, TimeNoLongerAvailable } from '@screens/ActionableCallTimeConfirmation';
import type { XHR } from '@services/http/interfaces';
import { getLocationFor, hasClientRole, qs } from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import type * as ActionableLink from './interfaces';

type ResponseBodyError<T extends number = number> = {
  status: T;
  data?:  API.Tokens.HandleActionableLink.ResponseError<T>;
};

type Props = {
  params: ActionableLink.ActionableParams.ProjectCallTimeConfimation;
  token:  ActionableLink.URLParams['token'];
};

export const ProjectCallTimeConfirmation = (props: Props) => {
  const dispatched = useRef(false);
  const dispatchChanges = useDispatchChanges();
  const [payload, setPayload] = useState<APIResponse>(null);
  const [error, setError] = useState<ResponseBodyError>(null);
  const app = useAppReadyState();

  const hydrated = app.authenticated && app.hydrated;
  const ready = !app.authenticated || hydrated;

  useEffect(() => {

    if (payload && hydrated) {
      dispatchChanges(payload);
    }

  }, [
    dispatchChanges,
    hydrated,
    payload,
  ]);

  useEffect(() => {
    if (dispatched.current) return;

    dispatched.current = true;

    api.tokens.handleActionableLink<ActionableLinkType.ProjectCallTimeConfirmation>({
      end: props.params.end,
      start: props.params.start,
      token: props.token,
    })
    .then(res => {
      setPayload(res);
    })
    .catch((e: XHR.Response) => {

      if (timeNoLongerAvailable(e)) {
        setError({
          data: e.data,
          status: e.status,
        });
      } else {
        setError({ status: e.status });
      }

    });

  }, [
    props.params.end,
    props.params.start,
    props.token,
  ]);

  if (error?.status === 403) {
    const location = {
      ...getLocationFor.scheduling.selecting({
        callId: error.data.call.id,
        projectId: error.data.project.id,
        scheduleeId: error.data.observerUserId,
        schedulerId: error.data.user.id,
      }),
      search: qs.from(pathname.Home),
    };

    return (
      <TimeNoLongerAvailable
        location={location}
        user={error.data.user} />
    );
  }

  if (error?.status) {
    return (
      <LinkExpired />
    );
  }

  if (payload && ready) {
    const user = hasClientRole(payload.observer)
        ? payload.user
        : undefined;
    return (
      <BookingConfirmed
        call={payload.call}
        project={payload.project}
        user={user} />
    );
  }

  return (
    <ActivityIndicator show />
  );
};

function timeNoLongerAvailable<T extends number>(e: XHR.Response): e is XHR.Response<ResponseBodyError<403>['data']> {
  return e.status === 403;
}

const useDispatchChanges = () => {
  const dispatch = useDispatch();

  const dispatchChanges = useCallback((res: APIResponse) => {
    const getActionsForCall = (): [ReturnType<typeof actions.callUpdated>] => {
      return [ actions.callUpdated({ call: res.call }) ];
    };

    const getActionsForPipeline = () => {
      return [
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: res.call.projectId,
        }),
      ];
    };

    dispatch(actions.batchActions([
      ...(res.call ? getActionsForCall() : []),
      ...(res.pipeline ? getActionsForPipeline() : []),
    ]));
  }, [
    dispatch,
  ]);

  return dispatchChanges;
};

type APIResponse = API.Tokens.HandleActionableLink.Response<ActionableLinkType.ProjectCallTimeConfirmation>;