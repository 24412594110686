import { Strategy, useAssertStrategy } from '$website/containers';
import aetna from 'static/images/signup/logo-aetna.svg?url';
import amazon from 'static/images/signup/logo-amazon.svg?url';
import paypal from 'static/images/signup/logo-paypal.svg?url';
import phillips from 'static/images/signup/logo-phillips-healthcare.svg?url';
import sap from 'static/images/signup/logo-sap.svg?url';
import { ConversionOptimization } from './ConversionOptimization';
import styles from './style/ValueProposition.css';

const items = [
  'Join now and set your hourly rate',
  'Create your own unique profile',
  'Receive relevant industry consulting opportunities',
  'Sentiment pays you for projects you complete',
];

const logos = [aetna, amazon, sap, phillips, paypal];

type Props = {
  items?: string[];
  logos?: string[];
  title?: string;
};

const ValuePropositionVisitor = (props: Props) => {
  const assert = useAssertStrategy();
  const show = !assert(Strategy.Corporate);

  if (!show) return null;

  const renderLogos = () => {
    return props.logos.map((m, i) => {
      return (
        <div key={i} className={styles.logo}>
          <img src={m} />
        </div>
      );
    });
  };

  return (
    <ConversionOptimization className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>{props.title}</div>
          <ListItems items={props.items} />
          <div className={styles.trusted}>Trusted by 24,000+ Professionals</div>
          <div className={styles.logos}>
            {renderLogos()}
          </div>
        </div>
      </div>
    </ConversionOptimization>
  );
};

export const ListItems = (props: Pick<Props, 'items'>) => {
  return (
    <div className={styles.items}>
      {props.items.map((m, i) =>
        <div key={i} className={styles.item}>
          <div className={styles.itemNumber}>{i + 1}</div>
          <div className={styles.itemText}>{m}</div>
        </div>)}
    </div>
  );
};

const defaultProps = {
  items,
  logos,
  title: `Let's Get Started`,
};

ValuePropositionVisitor.defaultProps = defaultProps;

export { ValuePropositionVisitor as ValueProposition };
export default ValuePropositionVisitor;