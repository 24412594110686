import { createNamedContext } from '@utils';
import type { BrandThemingContextValue, SurveyThemingContextValue, WhitelabelingPaletteContextValue } from './interfaces';
import type { Theme } from './theme';

export type AppThemingContextValue = {
  enabled: boolean;
  palette: Theme.WhiteLabelPalette;
};

export const AppThemingContext = createNamedContext<AppThemingContextValue>(undefined, 'AppTheming.Context');

export const BrandThemingContext = createNamedContext<BrandThemingContextValue>(undefined, 'Branding.Theming.Context');

export const WhitelabelingPaletteContext = createNamedContext<WhitelabelingPaletteContextValue>(undefined, 'Branding.WhitelabelingPalette.Context');

export const SurveyThemingContext = createNamedContext<SurveyThemingContextValue>(undefined, 'Branding.SurveyTheming.Context');