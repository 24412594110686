import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import type { CategoryTheme } from '@/types/branding';

export const useSetColorCategoryThemeMutation = <Context = unknown>(options: SetColorCategoryTheme.Options<Context> = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation({ mutationKey: [QKey.Groups.Branding.Theming.Put, group.id], mutationFn: (data: SetColorCategoryTheme.Variables) => {
    return api.groups.branding.setColorCategoryTheme({
      categoryId: data.categoryId,
      groupId: group.id,
      paletteId: data.paletteId,
    });
  }, ...options });

  return mutation;
};

export declare namespace SetColorCategoryTheme {
  export type Variables = CategoryTheme;

  export type Options<Context = unknown> = {
    onMutate?: (variables: Variables) => unknown;
  } & Omit<
      UseMutationOptions<
        Branding.SetColorCategoryTheme.Response,
        unknown,
        Variables,
        Context>, 'onMutate'>;
}