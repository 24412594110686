import { useCallback } from 'react';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { Input } from '@/components/Input';
import type { RadioGroupOnOptionChange } from '@/components/Radio';
import { RadioGroup } from '@/components/Radio';
import type { CheckboxGroupOnItemToggle } from '@/components/Checkbox';
import { CheckboxGroup } from '@/components/Checkbox';
import type { MatrixForm } from '@/components/SurveyForm/interfaces';
import { SurveyRichTextEditor, SurveyRichTextEditorContainer, useSurveyRichTextEditor } from '@/components/Survey.RichText';
import styles from './style/List.css';

type Props = MatrixForm.ListItemProps;

function SurveyMatrixListItem({
  className,
  openEndedValue,
  options,
  item,
  onSelection,
  selected,
  updateOpenEndedValue,
  variant,
}: Props) {

  const handleRadioToggle: RadioGroupOnOptionChange =
    useCallback(item => onSelection(+item.optionId), [onSelection]);

  const handleCheckboxToggle: CheckboxGroupOnItemToggle =
    useCallback(item => onSelection(+item.id), [onSelection]);

  const renderList = useCallback(() => {

    if (variant === 'single') {
      const optionsMapped = options.map(o => ({
        value: o.id,
        label: parseSurveyRichText(o.value),
      }));
      return (
        <RadioGroup
          className={className}
          selectedOption={selected[0] || ''}
          onOptionChange={handleRadioToggle}
          options={optionsMapped} />
      );
    } else {
      const optionsMapped = options.map(o => ({
        id: o.id,
        label: parseSurveyRichText(o.value),
      }));
      return (
        <CheckboxGroup
          className={className}
          checkedItems={selected}
          items={optionsMapped}
          onToggle={handleCheckboxToggle} />
      );
    }

  }, [
    className,
    handleCheckboxToggle,
    handleRadioToggle,
    options,
    selected,
    variant,
  ]);

  const onOpenEndedValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateOpenEndedValue(e.target.value);
  }, [updateOpenEndedValue]);

  return (
    <div className={styles.listItem}>
      <div className={styles.listTitle}>
        <SurveyRichTextEditor />
        {item.isOpenEnded &&
          <Input
            onChange={onOpenEndedValueChange}
            value={openEndedValue || ''} />}
      </div>
      {renderList()}
    </div>
  );
}

const Container = (props: Props) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.item.value,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyMatrixListItem {...props} />
    </SurveyRichTextEditorContainer>
  );
};

export { Container as SurveyMatrixListItem };
export default Container;