import { useCallback, useState } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';

export const useAdditionalPriceInputs = () => {
  const [{ additionalPriceLinks }, dispatch] = useTargetProductProfileData();
  const [inputs, setInputs] = useState<SurveyTemplate.AdditionalTPPPriceLinks>({
    canada: additionalPriceLinks.canada || '',
    france: additionalPriceLinks.france || '',
    germany: additionalPriceLinks.germany || '',
    italy: additionalPriceLinks.italy || '',
    spain: additionalPriceLinks.spain || '',
    uk: additionalPriceLinks.uk || '',
  });

  const onChange = useCallback((key: keyof SurveyTemplate.AdditionalTPPPriceLinks) => (value: string) => {
    setInputs(s => {
      s[key] = value;
      return s;
    });
  }, []);

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-additional-prices-updated',
      payload: {
        value: inputs,
      },
    });
  }, [dispatch, inputs]);

  return {
    onChange,
    onSubmit,
    value: inputs,
  };
};