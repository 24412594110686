import { useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { ProjectVersionHeaderRow, ResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import ResponseOptionUser from '@/components/SurveyResponses/ResponseOptionUser';
import type { SurveyQuestionType } from '@enums';
import { useProjectVersion } from './hooks';
import styles from './style/Data.css';

export const RankingRankData = () => {
  const { query, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Ranking>();
  const version = useProjectVersion(query.projectId);
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.Ranking>();

  const data = useMemo(() => {
    const optionId = query.optionIds[0];
    const versionData = historical[query.projectId].data;

    return versionData.indexes.values[optionId].keys[query.rank.toString()];
  }, [
    historical,
    query.optionIds,
    query.projectId,
    query.rank,
  ]);

  const option = useMemo(() => {
    return question.options.find(f => f.base.id === query.optionIds[0]);
  }, [
    query.optionIds,
    question,
  ]);

  const subtitle = useMemo(() => {
    return `(${chart.ranking.formatRankedText(query.rank)})`;
  }, [
    query.rank,
  ]);

  const color = useMemo(() => {
    return chart.options.OptionsColors[option.ordinal - 1];
  }, [
    option.ordinal,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      <ResponseOptionRow
        className={styles.row}
        color={color}
        label={parseSurveyRichText(option.value)}
        subtitle={subtitle} />
      {data.userIds.map(userId => (
        <ResponseOptionUser
          key={userId}
          userId={userId} />
      ))}
    </>
  );
};

export default RankingRankData;