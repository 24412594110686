import { memo } from 'react';
import { MessageSquare } from 'react-feather';
import type { Notification as N } from '@/types';
import Notification from './Notification.Base';

type Props = {
  item: N;
} & Partial<ChildrenProps>;

export const MessageNotification = memo(({ children, item }: Props) => {

  return (
    <Notification
      Icon={MessageSquare}
      text={item.text}
      title={item.title}>
      {children}
    </Notification>
  );
});

export default MessageNotification;