import type { SurveyMatchBuilder } from './interfaces';
import { surveyConditionsReducer } from './reducer.conditions';
import { surveyRuleReducer } from './reducer.rule';

type State = SurveyMatchBuilder.State;

export function surveyMatchReducer(state: State, action: SurveyMatchBuilder.Action): State {

  return {
    conditions: surveyConditionsReducer(state.conditions, action),
    rule: surveyRuleReducer(state.rule, action),
  };
}