import * as survey from '@containers/Survey/utils';
import type { ExclusiveOptionsQuestion, MatrixMultiselectQuestion, MultiTextboxQuestion, RespondentAnswer, SurveyResponseItem } from '@/types/survey';
import type { SurveyQuestionType } from '@/enums';

export const transformResponse = (item: SurveyResponseItem): RespondentAnswer => {
  if (survey.response.isTextResponse(item)) {
    return {
      value: item.answer.value,
    };
  } else if (survey.response.isMultipleChoice(item)) {
    const answer = item.answer.items[0];
    return {
      optionId: answer.option.id,
      text: answer.option.text,
    };
  } else if (survey.response.isMultiSelect(item)) {
    return {
      items: item.answer.items.map(item => ({
        optionId: item.option.id,
        text: item.option.text,
      })),
    };
  } else if (survey.response.isRanking(item)) {
    return {
      dontKnow: item.answer.dontKnow,
      options: item.answer.items.map(item => ({
        id: item.option.id,
        ordinal: item.ordinal,
      })),
      openEndedValues: item.answer.items.filter(f => !!f.option.openEndedValue).reduce((acc, x) => {
        return Object.assign(acc, {
          [x.option.id]: x.option.openEndedValue,
        });
      }, {}),
    };
  } else if (survey.response.isMatrixGrid(item)) {
    return transformMatrixAnswer(item);
  } else if (survey.response.isMatrixSlider(item)) {
    return {
      items: item.answer.items.map(m => ({
        matrixRowId: m.row.id,
        rowText: m.row.openEndedValue,
        value: m.value,
      })),
    };
  } else if (survey.response.isMatrixMultiselect(item)) {
    return transformMatrixAnswer(item);
  } else if (survey.response.isNumberInputTable(item)) {
    return {
      items: item.answer.items.map(m => ({
        optionId: m.option.id,
        rowId: m.row.id,
        value: m.value,
      })),
    };
  } else if (survey.response.isMultiTextbox(item)) {
    const answer: MultiTextboxQuestion.RespondentAnswer = {
      dontKnow: item.answer.dontKnow?.selected,
      values: item.answer.values,
    };
    return answer;
  } else if (survey.response.isExclusiveOptions(item)) {
    const answer: ExclusiveOptionsQuestion.RespondentAnswer = {
      items: item.answer.items.map(m => ({
        optionId: m.option.id,
        text: m.option.text,
      })),
    };
    return answer;
  }
};

const transformMatrixAnswer = ({ answer, question }: SurveyResponseItem<SurveyQuestionType.MatrixGrid | SurveyQuestionType.MatrixMultiselect>) => {

  const rowText = (answer.items as MatrixMultiselectQuestion.Answer.Item[]).reduce((acc, x) => {
    acc[x.group.id] = x.group.openEndedValue;
    return acc;
  }, {});

  const values: MatrixMultiselectQuestion.RespondentAnswer.Map = {};
  question.matrixRows.forEach(row => {
    values[row.id] = {};
    question.options.forEach(option => {
      values[row.id][option.id] = false;
    });
  });

  answer.items.forEach(item => {
    item.selected.forEach(s => {
      if (question.settings.groupBy === 'row') {
        values[item.group.id][s.id] = true;
      } else if (question.settings.groupBy === 'column') {
        values[s.id][item.group.id] = true;
      }
    });
  });

  return {
    rowText,
    values,
  };
};