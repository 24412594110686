import { format, formatDistanceToNowStrict } from 'date-fns';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';

export function useFetchBuildInfo() {
  const [response, fetch] = useAsyncStateLazy<BuildInfo>(async () => {
    const apiResult = await api.health.getBuild();
    return {
      api: formatApiBuild(apiResult?.build),
      env: formatEnv(apiResult?.env),
      web: formatWebBuild(),
    };
  }, []);

  return [response, fetch] as const;
}

function formatApiBuild(build: string) {
  if (!build?.length) return null;
  const [git, rawDate] = build.split(' ', 2);

  return `${git} ${formatBuildDate(new Date(rawDate))}`;
}

function formatEnv(env: string) {
  return env?.length
    ? env
    : process.env.VANCERY_ENV?.length
      ? process.env.VANCERY_ENV
      : 'dev';
}

function formatWebBuild() {
  const rawDate = process.env.APP_BUILT_DATE;
  const branch = process.env.APP_GIT_BRANCH;
  const sha = process.env.APP_GIT_SHA;

  if (!rawDate?.length && !branch?.length && !sha?.length) {
    return null;
  }

  return `${branch}/${sha.substr(0, 7)} ${formatBuildDate(new Date(rawDate))}`;
}

function formatBuildDate(rawDate: Date) {
  const date = format(rawDate, 'MMM do hh:mmaaa');
  const distance = formatDistanceToNowStrict(rawDate);
  return `${date} (${distance} ago)`;
}

type BuildInfo = {
  api: string;
  env: string;
  web: string;
};