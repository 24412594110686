import type { AlertModalProps } from './Alert';
import Alert from './Alert';

type Props =
  Omit<AlertModalProps, 'confirmText' | 'message'>;

export const UnsavedChangesAlert = (props: Props) => (
  <Alert
    confirmText='Discard'
    message='Are you sure you want to discard changes?'
    {...props} />
);

export default UnsavedChangesAlert;