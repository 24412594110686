import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as consts from '@consts';
import * as xform from '@api/transformers';
import { useAppReadyState } from '@containers/AppReadyState';
import type { ActionableLinkType } from '@enums';
import type { AdHocCall } from '@/types';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import Toast from '@/components/Toast';
import type * as ActionableLink from './interfaces';

type Props = {
  token:  ActionableLink.URLParams['token'];
};

export const AdHocCallRejection = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const app = useAppReadyState();

  const { mutateAsync, ...reject } = useMutation({ mutationKey: ['actionable-reject-call', props.token], mutationFn: () => {
    return api.tokens.handleActionableLink<ActionableLinkType.AdHocCallRejection>({
      token: props.token,
    }).then(res => ({
      call: xform.normalizeCall(res.call),
    }));
  }, onError: e => {
    Toast.warn({
      title: `Warning - Request Failed`,
      body: `We’re sorry. This link is no longer available.`,
    });
  }, onSuccess: res => {
    Toast.success({
      title: 'Call Rejected',
      body: 'The client has been sent a notification that you do not wish to participate on this call.',
    });

    const ready = app.authenticated && app.hydrated;

    if (ready) {
      dispatch(actions.adHocCallUpdated({
        call: res.call as AdHocCall,
      }));
      history.replace(consts.pathname.Home);
    } else {
      history.replace(consts.path.Website.Login);
    }

  } });

  useEffect(() => {

    if (!reject.isIdle) return;

    if (app.authenticated && app.hydrated) {
      mutateAsync();
    }

    if (!app.authenticated) {
      mutateAsync();
    }
  }, [
    app.authenticated,
    app.hydrated,
    reject.isIdle,
    mutateAsync,
  ]);

  return (
    <ActivityIndicator show />
  );
};

export default AdHocCallRejection;