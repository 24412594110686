import { useMemo } from 'react';
import { Editable, Slate, withReact } from 'slate-react';
import type { Node } from 'slate';
import { createEditor } from 'slate';
import { SeeMore } from '@presentation/SeeMore';

type Props = {
  value: Node[];
};

export const Body = (props: Props) => {
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <SeeMore
      lineHeight={21}
      maxLines={2}>
      <Slate
        editor={editor}
        onChange={_ => alert('This comment cannot be changed.')}
        value={props.value}>
        <Editable readOnly />
      </Slate>
    </SeeMore>
  );
};

Body.displayName = 'Comment.Body';