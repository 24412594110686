import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import type { Validation } from '@containers/SurveyBuilder/interfaces';
import type { MultiselectQuestion, MatrixMultiselectQuestion } from '@/types';
import type { SelectChangeEvent } from '$admin/components/Select';
import { Select } from '$admin/components/Select';
import { Warning } from './Warning';
import styles from './style/Multiselect.Settings.Selections.css';

type Props = {
  className?: string;
  item: MultiselectQuestion.Question | MatrixMultiselectQuestion.Question;
  selectItems: { [k: string]: string };
  validation: Validation.AssertionResult;
};

export const SelectionSettings = ({ className, item, selectItems, validation }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const handleSelectionsChange = useCallback((field: 'minimumSelections' | 'maximumSelections') => (e: SelectChangeEvent) => {

    const value = e.target.value
      ? +e.target.value
      : null;

    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        [field]: value,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
  ]);

  return (
    <div className={className}>
      <div className={styles.item}>
        <div className={styles.title}>Minimum Selections</div>
        <div className={styles.input}>
          <Select
            id="minimum-selections"
            name="minimum-selections"
            onChange={handleSelectionsChange('minimumSelections')}
            options={selectItems}
            value={item.settings.minimumSelections || 0} />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>Maximum Selections</div>
        <div className={styles.input}>
          <Select
            id="maximum-selections"
            name="maximum-selections"
            onChange={handleSelectionsChange('maximumSelections')}
            options={selectItems}
            value={item.settings.maximumSelections || 0} />
        </div>
      </div>
      <Warning
        className={styles.warning}
        result={validation} />
    </div>
  );
};

export default SelectionSettings;