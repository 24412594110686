import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { useDownloader } from '@utils/hooks';
import Toast from '@/components/Toast';

export const useDownloadAccountReport = () => {
  const download = useDownloader();

  return useQuery({ queryKey: ['download-account-report'], queryFn: () => {
    return api.users.exportUserData();
  }, enabled: false, onError: () => {
    Toast.error({
      body: 'Account Report',
      title: 'There was an error trying to download the report.',
    });
  }, retry: 1, onSuccess: res => {
    download({
      blob: res.blob,
      filename: res.filename,
    });
  } });

};