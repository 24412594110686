import type { Middleware } from '@reduxjs/toolkit';

const alerts: Middleware = store => next => action => {
  next(action);
};

const apply = (middleware: Middleware[]) => {
  return middleware.concat(alerts);
};

export default {
  apply,
  middleware: alerts,
};