import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import { DiscoveryContainer } from '@containers/Discovery/DiscoveryContainer';
import type * as Routing from '@routes/interfaces';
import { DiscoveryPost } from '@screens/Discovery.Post';

export const routes: Routing.Route[] = [{
  component: DiscoveryContainer,
  exact: true,
  path: [
    path.Discovery.Root,
    path.Discovery.Topics.Topic,
  ],
}, {
  component: DiscoveryPost,
  exact: true,
  path: path.Discovery.Post,
}];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.Discovery.Root,
});