import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { querykey } from '@/consts';
import * as $api from '@/services/api';
import type { WorkspaceBrand as WS } from '@/services/api/interfaces';

type Props = IWorkspaceBrandId;
type Data = WS.GetBrand.Response;
type QueryKey = readonly [string];
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export const useFetchBrand = (props: Props, options?: Options) => {
  const qk = querykey.Workspaces.Brand.Get(props);

  return useQuery({ queryKey: qk, queryFn: ({ queryKey }) => {
    return $api.workspaces.brand.getBrand({
      brandId: props.brandId,
    });
  }, ...options });
};