import { useContext, useEffect } from 'react';
import type { LocationState } from 'history';
import { ScrollSubscriptionContext } from '@containers/ScrollRestoration/Context';
import type { ScrollRestoration } from '@containers/ScrollRestoration/interfaces';

type Params<T> = ScrollRestoration.Subscriber<T>;

const usePreventScrollRestoration = <T = LocationState>(fn: Params<T>) => {
  const subscription = useContext(ScrollSubscriptionContext);

  useEffect(() => {

    subscription.on(fn);

    return () => {
      subscription.off(fn);
    };

  }, [
    fn,
    subscription,
  ]);
};

export { usePreventScrollRestoration };
export default usePreventScrollRestoration;