import { Invitations } from '@/components/Calendar/Invitations';
import type { CalendarState, SchedulingProvidingComponentProps } from './interfaces';

type Props = {
  className?: string;
} & SchedulingProvidingComponentProps;

const Invitation = (props: Props) => {
  const handleSubmit = (data: CalendarState) => {
    return props.onSubmit({
      creator: { id: props.creatorId },
      new: data.new,
      removed: data.removed,
      scheduleeId: props.scheduleeId,
      schedulerId: props.schedulerId,
      user: { id: props.userId },
    });
  };

  const screenProps = {
    with: { id: props.scheduleeId },
  };

  return (
    <Invitations
      className={props.className}
      onSubmit={handleSubmit}
      with={screenProps.with} />
  );
};

export { Invitation };
export default Invitation;