import { memo, useMemo } from 'react';
import styles from './style/Logic.css';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const SurveySettings = memo(({ children }: Props) => {

  const filteredChildren = useMemo(() => {
    return Array.isArray(children)
      ? children.filter(Boolean)
      : [children].filter(Boolean);
  }, [children]);

  if (!filteredChildren.length) return null;

  return (
    <>
      <div className={styles.label}>Settings</div>
      {filteredChildren.map((child, i) => (
        <div key={i} className={styles.item}>
          <span className={styles.bullet}>•</span>
          {child}
        </div>
      ))}
    </>
  );
});