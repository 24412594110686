import { io } from 'socket.io-client';
import type { ReportSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { Reports } from './interfaces';

const log = createLogger(SocketNamespace.Reports);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Reports}`, resolveSocketOptions());

export function open() {
  if (!socket.connected) {
    socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
    socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
    socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

    socket.prependAny((event: ReportSocketEvent, payload: unknown) => log.event(event, payload));
    socket.open();
  }
}

export function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

export const on = <T extends ReportSocketEvent>(event: T, handler: Reports.RegisterHandler<T>) => {
  return socket.on<ReportSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const off = <T extends ReportSocketEvent>(event: T, handler: Reports.RegisterHandler<T>) => {
  return socket.off<ReportSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const subscribe = (key: string) => {
  socket.emit('subscribe', key);
};

export const unsubscribe = (key: string) => {
  socket.emit('unsubscribe', key);
};