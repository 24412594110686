import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import type { ButtonColor } from '@/components/Button/interfaces';
import styles from './style.css';

type Props = {
  classes?: {
    accept?: string;
    btn?:    string;
    reject?: string;
    root?:   string;
  };
  onAccept: () => unknown;
  onReject: () => unknown;
};

export const ButtonSet = ({ classes = {}, ...props }: Props) => {
  const { palette, theme } = useSurveyThemingPalette();

  const btn = {
    color: theme ? `transparent` : `affirmative` as ButtonColor,
    style: theme ? {
      backgroundColor: palette.primary.main,
      color: palette.primary.text,
    } : {},
  };

  return (
    <div className={cx(styles.btns, classes.root)}>
      <Button
        className={cx(styles.btn, classes.btn, classes.reject)}
        color="destructive"
        onClick={props.onReject}
        variant="brick">
        Reject
      </Button>
      <Button
        className={cx(styles.btn, classes.btn, classes.accept)}
        color={btn.color}
        onClick={props.onAccept}
        style={btn.style}
        variant="brick">
        Accept
      </Button>
    </div>
  );
};

ButtonSet.displayName = 'Project.RequestReview.ButtonSet';