import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import type * as Twilio from '@twilio/conversations';
import { useQuery } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import * as $chat from '@services/chat';
import type { IChat } from '@/types';
import Messenger from './Messenger';
import { Sidebar } from './Sidebar';
import styles from './style/Messages.css';

// move onLoadUserId to query string or hash

const mapState = (state: Store.State) => ({
  conversations: state.chat.conversations,
  initialized: state.chat.initialized,
  participants: state.chat.participants,
  user: state.user,
});

export function Messages() {
  // const { initialized, user } = useSelector(mapState);
  const [sidebar, setSideBar] = useState<enums.ChatSidebar>(enums.ChatSidebar.Channels);
  const [messenger, setMessenger] = useState<IChat.DirectoryItem>();
  const [acquiring, setAcquiring] = useState<boolean>();

  const messengerActive = !!messenger;
  const activeConversationSid = messengerActive ? messenger.conversation?.state?.sid : '';

  useEffect(() => {
    const client = $chat.getClient();

    if (client) {
      const cleanupChannel = (conversation: Twilio.Conversation) => {
        if ($chat.getActiveConversation() === conversation.sid) {
          $chat.setActiveConversation(null);
          setMessenger(null);
        }
      };

      client.on('conversationRemoved', cleanupChannel);

      return () => {
        client.off('conversationRemoved', cleanupChannel);
      };
    }
  }, []);

  const setupChannel = useCallback((item: IChat.DirectoryItem) => {
    if (acquiring) return;

    if (!item.conversation) {
      setAcquiring(true);
      $chat.createConversation({
        withUserId: item.participant.contact.id,
      })
      .then(conversation => {
        $chat.setActiveConversation(conversation);
        setMessenger({
          participant: item.participant,
          conversation: {
            state: conversation,
            unreadCount: 0,
            messagesCount: 0,
            mostRecent: null,
          },
        });
        setSideBar(enums.ChatSidebar.Channels);
      })
      .finally(() => setAcquiring(false));
    } else {
      setAcquiring(true);
      const client = $chat.getClient();
      client.getConversationBySid(item.conversation.state.sid)
        .then(conversation => {
          $chat.setActiveConversation(conversation);
          conversation.setAllMessagesRead()
            .then(() => {
              if (conversation.lastMessage) {
                api.messages.consumed({
                  conversationSid: conversation.sid,
                  index: conversation.lastMessage.index,
                  timestamp: new Date(),
                });
              }
            });
          setMessenger({
            participant: item.participant,
            conversation: {
              state: conversation,
              unreadCount: 0,
              messagesCount: 0,
              mostRecent: null,
            },
          });
          setSideBar(enums.ChatSidebar.Channels);
        })
        .finally(() => setAcquiring(false));
    }
  }, [acquiring]);

  const handleSetChannel = useCallback((item: IChat.DirectoryItem) => {
    if (item) {
      setupChannel(item);
    }
  }, [setupChannel]);

  const handleToggleSidebar = useCallback((sidebar: enums.ChatSidebar) => setSideBar(sidebar), []);

  const unsetChannel = useCallback(() => {
    $chat.setActiveConversation(null);
    setMessenger(null);
  }, []);

  const deleteConversation = useCallback((conversationSid: string) => {
    $chat.deleteConversation(conversationSid)
         .then(() => unsetChannel());
  }, [unsetChannel]);

  const sendMessage = useCallback((data: { body: string; paid: boolean }) => {
    $chat.sendMessage(data);
  }, []);

  const dispatch = useDispatch();
  const state = useSelector(mapState);

  const userIds = useMemo(() => {
    const pids = Object.values(state.participants || {}).map(x => x.userId);
    const cids = state.conversations.ids.map(sid => {
      const conversation = state.conversations[sid];
      const attributes = conversation.attributes as IChat.ConversationAttributes;
      return attributes.userIds.find(id => id !== state.user.id);
    });

    return [...new Set([...pids, ...cids])];
  }, [
    state.conversations,
    state.participants,
    state.user,
  ]);

  const query = useQuery({ queryKey: [
    `get:users/contacts`,
    userIds,
  ], queryFn: () => {
    return api.users.fetchContacts({
      userIds,
    })
    .then(res => {
      dispatch(actions.contactsAdded({
        contacts: res.items,
      }));

      return res;
    });
  }, enabled: state.initialized && userIds.length > 0 });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Helmet title="Messages" />
        <Sidebar
          activeConversationSid={activeConversationSid}
          messengerActive={messengerActive}
          loading={query.isInitialLoading}
          setChannel={handleSetChannel}
          sidebar={sidebar}
          toggleView={handleToggleSidebar}
          onLoadUserId={null} />
        <Messenger
          conversation={messenger?.conversation}
          participant={messenger?.participant}
          messengerActive={messengerActive}
          onClose={unsetChannel}
          onDelete={deleteConversation}
          sendMessage={sendMessage}
          onScheduleCall={null} />
      </div>
    </div>
  );
}