import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';

type Data = unknown;
type Variables = {
  password: string;
};

export const useSubmitSurveyMonitoringAuth = (
  data: IProjectId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) => {

  return useMutation({ mutationKey: ['submit-survey-monitoring-auth'], mutationFn: (params: Variables) => {
    return api.projects.surveys.metrics.auth.post({
      password: params.password,
      projectId: data.projectId,
    });
  }, ...options });
};