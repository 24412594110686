import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride } from '@remirror/core';
import {
  extension,
  ExtensionTag,
  NodeExtension,
} from '@remirror/core';
import * as Remirror from '@/types/rich-text';
import { TPPNode } from './Node.TPP';

@extension({
  defaultOptions: {},
})
export class TPPNodeExtension extends NodeExtension {

  get name() {
    return Remirror.NodeType.TPP;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = TPPNode;
}