import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import type { UserPromptProps } from '@/types';

type Data = Partial<UserPromptProps>;

type Options =
  UseMutationOptions<unknown, unknown, Data>;

export const useUpdateUserPrompts = (options?: Options) => {

  return useMutation({ mutationKey: ['update-user-prompts'], mutationFn: data => {
    return api.users.updatePrompts(data);
  }, ...options });

};

export const useUpdateDisplayVerificationBanner = () => {
  const dispatch = useDispatch();

  const { mutateAsync } = useUpdateUserPrompts({
    onSuccess: (r, v, c) => {
      dispatch(actions.platformAlertChanged({
        displayVerificationBanner: false,
      }));
    },
  });

  return useCallback(() => {
    return mutateAsync({
      verifiedBannerDismissed: true,
    });
  }, [mutateAsync]);
};