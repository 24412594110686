import type { Reducer } from 'react';
import { useMemo, useReducer } from 'react';
import { SurveyConditionsState } from '@containers/SurveyBuilder.Logic/State.Conditions';
import { useSurveyConditionsMatchState } from '@containers/SurveyBuilder.Logic/context';
import { SurveyBasicQuotaStateContext } from './Context';
import type { SurveyBasicQuotaBuilder } from './interfaces';
import { surveyBasicQuotaReducer } from './reducer.basic';

type Props = {
  initial: SurveyBasicQuotaBuilder.State;
} & ChildrenProps;

const SurveyBasicQuotaBuilderState = ({
  children,
  initial,
}: Props) => {

  const [state, dispatch] = useReducer<Reducer<SurveyBasicQuotaBuilder.State, SurveyBasicQuotaBuilder.Action>>(surveyBasicQuotaReducer, initial);
  const [matchState] = useSurveyConditionsMatchState();

  const mergedState = useMemo(() => {
    return {
      ...state,
      data: {
        rule: matchState.rule,
        items: matchState.conditions,
      },
    };
  }, [state, matchState]);

  return (
    <SurveyBasicQuotaStateContext.Provider value={[mergedState, dispatch]}>
      {children}
    </SurveyBasicQuotaStateContext.Provider>
  );
};

const WithConditionsState = (props: Props) => {

  const initial = {
    conditions: props.initial.data.items,
    rule: props.initial.data.rule,
  };

  return (
    <SurveyConditionsState initial={initial}>
      <SurveyBasicQuotaBuilderState initial={props.initial}>
        {props.children}
      </SurveyBasicQuotaBuilderState>
    </SurveyConditionsState>
  );
};

export { WithConditionsState as SurveyBasicQuotaBuilderState };
