import { useState, useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useDownloadCallChatReport } from '@utils/api';
import type { CallChatLogReportFormat } from '@/types';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { SelectUnderlined } from '$admin/components/Select';
import styles from './style/TranscriptDownloadModal.css';

type Props =
  & ICallId
  & Pick<ModalProps, 'open' | 'onClose'>;

export const CallChatLogDownloadModal = (props: Props) => {
  const [format, setFormat] = useState<CallChatLogReportFormat>('txt');

  const { download, isFetching } = useDownloadCallChatReport({
    callId: props.callId,
    format,
  }, {
    onSuccess: () => {
      props.onClose();
    },
  });

  const handleSubmit = useCallback(() => {
    download({
      extension: format,
      name: `Call Chat Log`,
      title: `Generating Log`,
    });
  }, [
    download,
    format,
  ]);

  return (
    <Modal
      classes={{ root: styles.root }}
      onClose={props.onClose}
      open={props.open}>
      <Header>Select Chat Log File Format</Header>

      <div className={styles.field}>
        <SelectUnderlined<Record<CallChatLogReportFormat, string>>
          onChange={x => setFormat(x.target.value)}
          options={[
            { id: 'txt', name: 'TXT' },
            { id: 'xlsx', name: 'XLSX' },
          ]}
          value={format} />
      </div>

      <ButtonSet className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching}>
          Download
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useCallChatLogDownloadModal = () => useModal(CallChatLogDownloadModal);