import { Link } from 'react-router-dom';
import { cx } from '@utils';
import type { Activity } from '@/types';
import styles from './style/PostPreview.Text.css';

type Props = {
  className?: string;
  item:       Activity.Item.Article;
};

export const ArticlePost = (props: Props) => {
  const displayImage = props.item.preview.image?.url;

  const classnames = {
    root: cx(styles.root, props.className, {
      [styles.noimage]: !displayImage,
    }),
  };

  return (
    <div className={classnames.root}>
      <Link to={`/posts/articles/${props.item.id}`}>
        {displayImage &&
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${props.item.preview.image.url})` }} />}
        <div className={styles.info}>
          <div className={styles.details}>
            <div className={styles.title}>{props.item.preview.title}</div>
            <div className={styles.description}>{props.item.preview.description}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ArticlePost.displayName = 'POsts.ArticlePost';