import { forwardRef } from 'react';
import * as consts from '@consts';
import type { TextareaProps } from '@/components/Textarea';
import { TextareaCharacterLimit } from '@/components/Textarea';

type Props =
  TextareaProps;

export const ProjectDescriptionInput = forwardRef((props: Props, ref: React.Ref<HTMLTextAreaElement>) => {

  return (
    <TextareaCharacterLimit
      ref={ref}
      maxLength={consts.maxLengths.PROJECT_DESCRIPTION}
      name="description"
      {...props} />
  );
});

export default ProjectDescriptionInput;