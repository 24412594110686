import type { SurveyLogic } from '@/types';
import { SurveyMatchContext } from './context';

type Props = {
  value: SurveyLogic.SurveyConditionsMatch;
} & ChildrenProps;

export const SurveyMatchContainer = (props: Props) => {

  return (
    <SurveyMatchContext.Provider value={props.value}>
      {props.children}
    </SurveyMatchContext.Provider>
  );
};