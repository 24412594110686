import { SurveyLogicRule } from '@/enums';
import type { SurveyLogic } from '@/types';
import type { SurveyLogicExpression } from '@/types/survey.logic.expression';
import { SurveyLogicExpressionNode } from '@/types/survey.logic.expression';
import type { Validation } from './interfaces.validation';

export function validateRule(rule: SurveyLogic.SurveyConditionsRule): Validation {

  if (!rule.type) {
    return {
      message: 'Rule type must be set',
      success: false,
    };
  }

  if (rule.type === SurveyLogicRule.CustomExpression) {
    return validateLogicExpression(rule.expression);
  }

  return { success: true };
}

export function validateLogicExpression(expression: SurveyLogicExpression.RichTextValue): Validation {

  if (expression?.content?.length !== 1) {
    return {
      message: 'Invalid expression',
      success: false,
    };
  }

  const node = expression.content[0];

  if (!node?.content) {
    return {
      message: 'Invalid expression',
      success: false,
    };
  }

  const nodesValid = node.content.every(e => [
    SurveyLogicExpressionNode.OpeningParenthesis,
    SurveyLogicExpressionNode.ClosingParenthesis,
    SurveyLogicExpressionNode.Condition,
    SurveyLogicExpressionNode.LogicalOperator,
  ].includes(e.type));

  if (!nodesValid) {
    return {
      message: 'Invalid expression',
      success: false,
    };
  }

  let unClosedParentheses = 0;
  let lastItem: SurveyLogicExpressionNode = null;
  let valid = true;
  node.content.forEach((c, i) => {

    switch (c.type) {
      // has to be start or preceded by logical operator
      case SurveyLogicExpressionNode.OpeningParenthesis: {
        unClosedParentheses++;
        if (lastItem && lastItem !== SurveyLogicExpressionNode.LogicalOperator) valid = false;
        break;
      }
      // has to be preceded by condition or other closing parenthesis
      case SurveyLogicExpressionNode.ClosingParenthesis: {
        unClosedParentheses--;
        if (![SurveyLogicExpressionNode.Condition, SurveyLogicExpressionNode.ClosingParenthesis].includes(lastItem)) valid = false;
        break;
      }
      // has to be preceded by opening parenthesis or operator
      case SurveyLogicExpressionNode.Condition: {
        if (lastItem && ![SurveyLogicExpressionNode.OpeningParenthesis, SurveyLogicExpressionNode.LogicalOperator].includes(lastItem)) valid = false;
        break;
      }
      // has to be preceded by closing parenthesis or condition
      case SurveyLogicExpressionNode.LogicalOperator: {
        if (![SurveyLogicExpressionNode.ClosingParenthesis, SurveyLogicExpressionNode.Condition].includes(lastItem)) valid = false;
        break;
      }
    }
    lastItem = c.type;

    // last character has to be Condition or closing parenthesis
    const isLast = i === node.content.length - 1;
    if (isLast) {
      if ([SurveyLogicExpressionNode.OpeningParenthesis, SurveyLogicExpressionNode.LogicalOperator].includes(c.type)) valid = false;

      if (unClosedParentheses !== 0) valid = false;
    }

  });

  if (!valid) {
    return {
      message: 'Invalid expression',
      success: false,
    };
  }

  return { success: true };
}

function isOpeningParenthesis(token: SurveyLogicExpression.ParagraphContent): token is SurveyLogicExpression.OpeningParenthesisNode {
  return token.type === SurveyLogicExpressionNode.OpeningParenthesis;
}

function isClosingParenthesis(token: SurveyLogicExpression.ParagraphContent): token is SurveyLogicExpression.ClosingParenthesisNode {
  return token.type === SurveyLogicExpressionNode.ClosingParenthesis;
}

function isLogicalOperatorNode(token: SurveyLogicExpression.ParagraphContent): token is SurveyLogicExpression.LogicalOperatorNode {
  return token.type === SurveyLogicExpressionNode.LogicalOperator;
}

function isConditionNode(token: SurveyLogicExpression.ParagraphContent): token is SurveyLogicExpression.ConditionNode {
  return token.type === SurveyLogicExpressionNode.Condition;
}