import SearchIcon from '@mui/icons-material/Search';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  return (
    <div
      className={styles.value}
      title={props.row.original.project.name}>
      {props.row.original.project.name}
    </div>
  );
};

Cell.displayName = 'Column.Project.Cell';

type FilterProps = {
  setValue: (value: string) => void;
  value: string;
};

export const Filter = (props: FilterProps) => {
  return (
    <div className={styles.field}>
      <div className={styles.search}>
        <SearchIcon />
      </div>
      <input
        className={styles.input}
        placeholder="Project name"
        onChange={e => props.setValue(e.target.value || undefined)}
        value={props.value}
        type="search" />
    </div>
  );
};

Filter.displayName = 'Column.Project.Filter';