import type { TargetCompany as TargetCompanyType } from '@/types';
import { TargetCompanyInput } from '@/components/GroupProject';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props = {
  label:    string;
  onChange: (value: TargetCompanyType) => unknown;
  value:    TargetCompanyType;
};

export const TargetCompany = (props: Props) => {

  return (
    <Field label={props.label}>
      <TargetCompanyInput
        defaultItem={props.value}
        onChange={props.onChange} />
    </Field>
  );
};

TargetCompany.displayName = 'Project.Details.Field.TargetCompany';