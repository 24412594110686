import { BrandingContainer } from '@containers/Branding';
import { GroupProjectContainer } from '@containers/GroupProject';
import { ProjectTabs } from '@screens/GroupProject/ProjectTabs';

type Props = unknown;

export const GroupProject = (props: Props) => {
  return (
    <BrandingContainer>
      <GroupProjectContainer>
        <ProjectTabs />
      </GroupProjectContainer>
    </BrandingContainer>
  );
};

GroupProject.displayName = 'Group.Project';