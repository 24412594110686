import { platform as initialState } from '@store/initial-state';
import * as types from '@store/action-types';
import type { ApplicationActions, PlatformAction } from '@store/interfaces';

type Action =
  | ApplicationActions
  | PlatformAction;

function alertsReducer(state: Store.Platform.Alerts, action: Action) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED: {
      return {
        ...state,
        ...action.platform.alerts,
      };
    }

    case types.PLATFORM_ALERT_CHANGED:
      return {
        ...state,
        ...action.alerts,
      };

    default:
      return state;
  }
}

const platform = (state: Store.Platform = initialState, action: Action) => {
  return {
    alerts: alertsReducer(state.alerts, action),
  };
};

export default platform;