import { useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { UserProfileEnterpriseContainer } from '@containers/UserProfile/UserProfileEnterpriseContainer';
import { UserProfileIndividualContainer } from '@containers/UserProfile/UserProfileIndividualContainer';
import { useHasClientRole } from '@containers/User/hooks/useHasClientRole';
import { useSelectUser } from '@containers/Store';
import { Permission } from '@enums';
import { UserProfile as UserProfileEvap } from '@screens/User.Profile';
import { access } from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';

export const LegacyProfileView = 1;

export default function UserProfile(props: Record<string, unknown>) {
  const user = useSelectUser();
  const isClient = useHasClientRole();
  const [viewOverride] = useQueryParam('v', NumberParam);

  const [initiallyAuthenticated] = useState(user.state.authenticated);

  const hasEvapPermission = access.has.permission.some(Permission.EvapAnalystUserProfile);

  if (initiallyAuthenticated && !user.state.initialized) return <ActivityIndicator show />;

  if (hasEvapPermission(user.permissions) && viewOverride !== LegacyProfileView) {
    return (
      <UserProfileEvap />
    );
  }

  return isClient
    ? <UserProfileEnterpriseContainer {...props} />
    : <UserProfileIndividualContainer {...props} />;
}

export { UserProfile };