import { useMutation } from '@tanstack/react-query';
import AsyncSelect from 'react-select/async';
import type { SearchNetSuiteProject } from '$admin/api/interfaces/projects';
import * as api from '$admin/api';
import selectStyles from './SelectStyles';
import styles from './styles.css';

type Item = SearchNetSuiteProject.Response[number];

type Props = {
  selected: Item;
  onSelect: (val: Item) => void;
};

export const NetSuiteProjects = ({ selected, onSelect }: Props) => {

  const query = useMutation({ mutationKey: ['get:admin/projects/autocomplete'], mutationFn: async (name: string) => {
    const result = await api.projects.searchNetSuiteProjects({
      name,
    });

    return result;
  } });

  return (
    <AsyncSelect<Item>
      className={styles.select}
      loadOptions={(val: string) => query.mutateAsync(val)}
      isClearable={false}
      isLoading={query.isLoading}
      getOptionLabel={item => `${item.name} (${item.identifier})`}
      getOptionValue={item => item.id.toString()}
      onChange={onSelect}
      defaultValue={selected}
      noOptionsMessage={() => 'No results'}
      styles={selectStyles}
      components={{
        DropdownIndicator: null,
      }} />
  );
};

NetSuiteProjects.displayName = 'Autocomplete.NetSuiteProjects';