import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteTag } from '@api/projects.conference';
import type { ConferenceTags } from '@api/interfaces/projects';
import { indexBy } from '@utils/array';
import { TagStateContext } from '@/components/Project.Tags/Context';

type MutationParams = ConferenceTags.DeleteTag.Request;
export const useDeleteTagMutation = () => {
  const { dispatch } = useContext(TagStateContext);
  return useMutation({ mutationKey: ['delete-project-tag'], mutationFn: (data: MutationParams) => {
    return deleteTag(data);
  }, onSuccess: (result, params) => {
    dispatch({
      type: 'remove-tag',
      projectTagId: params.projectTagId,
    });
    dispatch({
      type: 'update-ordinals',
      ordinals: indexBy(result.rebalancedOrdinals, k => k.id, v => v.ordinal),
    });
  } });
};