import { ButtonOutlined } from '@presentation';
import { Button } from '@/components/Button';
import { OutlookCalendar } from '@/components/icons';
import { ButtonSet } from './ButtonSet';
import { Header } from './Header';
import type { ModalProps } from './Modal';
import { Modal } from './Modal';
import styles from './style/ConnectOutlookCalendar.css';

type Props = {
  onConnect: () => void;
  onDismiss: () => void;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const ConnectOutlookCalendar = (props: Props) => {

  return (
    <Modal
      classes={{
        root: styles.modal,
      }}
      disableEscapeClose
      disableOverlayClick
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header>{copy.title}</Header>
          <div className={styles.main}>
            <div className={styles.message}>{copy.subtitle}</div>
          </div>
          <div className={styles.footer}>
            <div className={styles.stacked}>
              <ButtonOutlined
                borderWidth="thin"
                className={styles.btn}
                color="silver"
                fontWeight="bold"
                onClick={props.onConnect}>
                <OutlookCalendar className={styles.icon} />
                <span>{copy.connect}</span>
              </ButtonOutlined>
              <Button
                className={styles.dismiss}
                onClick={props.onDismiss}
                variant="link">
                {copy.dismiss}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConnectOutlookCalendar.displayName = 'Modal.ConnectOutlookCalendar';

const copy = {
  connect: `Connect Outlook Calendar`,
  dismiss: `Close and do not show again`,
  subtitle: `Connect your Outlook calendar to the platform for a better scheduling experience.`,
  title: `Connect Your Calendar`,
};