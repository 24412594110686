import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import styles from './style/Skeleton.css';

type Props = {
  count: number;
};

export const Results = memo(({ count }: Props) => {
  const results = new Array(count)
    .fill(0)
    .map((_, i) => `result-${i + 1}`);

  return (
    <>
      {results.map(result => <Result key={result} />)}
    </>
  );
});

Results.displayName = 'Skeleton.Results';

export const Result = memo(() => {
  return (
    <div className={styles.result}>
      <div className={styles.circle}>
        <Skeleton
          variant="circular"
          height={28}
          width={28} />
      </div>
      <div className={styles.description}>
        <Skeleton
          height={24}
          variant="text"
          width="30%" />
        <Skeleton
          height={18}
          width="65%"
          variant="text"
          style={{ marginTop: -4 }} />
      </div>
    </div>
  );
});

Result.displayName = 'Skeleton.Result';