import { useCallback, useEffect } from 'react';
import { useObjectOwnershipContext, useCanSaveOwnership } from '@containers/WorkspaceObject';
import { useLock, useMounted } from '@utils';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ObjectOwnershipDropdown from './OwnershipDropdown';
import styles from './style/ObjectOwnershipModal.css';

type Props =
  Pick<ModalProps,
    'onClose' |
    'open'>;

export const ObjectOwnershipModal = ({ onClose, open }: Props) => {

  const canSave = useCanSaveOwnership();
  const { fetchUsers, save } = useObjectOwnershipContext();
  const [_, lock] = useLock();
  const isMounted = useMounted();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSave = useCallback(() => {
    return save()
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    onClose,
    save,
  ]);

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick">
          Save
        </Button.Affirmative>
      </ButtonSet>
    );
  }, [
    canSave,
    handleSave,
    lock,
    onClose,
  ]);

  return (
    <Modal
className={styles.modal}
onClose={onClose}
open={open}>
      <Header text="Change Owner" />
      <div className={styles.body}>
        <div className={styles.dropdown}>
          <ObjectOwnershipDropdown />
        </div>
      </div>
      <Footer />
    </Modal>
  );
};

export default ObjectOwnershipModal;