import http from '@services/http';
import { transformConferenceTagSummaryItem, transformConferenceTagSummaryFeedback, transformTagSummary } from '@transformers/conferences.tag.summary';
import { qs, safeJsonDate } from '@utils';
import type { ConferenceTags } from '@services/api/interfaces/projects';

export const getTags = ({ projectId }: ConferenceTags.GetTags.Request): Promise<ConferenceTags.GetTags.Response> => {
  return http.get(`/projects/${projectId}/conferences/tags`);
};

export const createTag = ({ projectId, ...data }: ConferenceTags.CreateTag.Request) => {
  return http.put<ConferenceTags.CreateTag.Response>(`/projects/${projectId}/conferences/tags`, data);
};

export const createTags = ({ projectId, ...data }: ConferenceTags.CreateTags.Request) => {
  return http.put<ConferenceTags.CreateTags.Response>(`/projects/${projectId}/conferences/tags/bulk`, data);
};

export const deleteTag = ({ projectId, projectTagId }: ConferenceTags.DeleteTag.Request): Promise<ConferenceTags.DeleteTag.Response> => {
  return http.delete(`/projects/${projectId}/conferences/tags/${projectTagId}`);
};

export const toggleTagVisibility = ({ projectId, projectTagId, ...data }: ConferenceTags.ToggleTagVisibility.Request) => {
  return http.patch(`/projects/${projectId}/conferences/tags/${projectTagId}/visibility`, data);
};

export const updateTag = ({ projectId, projectTagId, ...data }: ConferenceTags.UpdateTag.Request) => {
  return http.patch<ConferenceTags.UpdateTag.Response>(`/projects/${projectId}/conferences/tags/${projectTagId}`, data);
};

export const reorderTags = ({ projectId, ...data }: ConferenceTags.ReorderTags.Request) => {
  return http.patch(`/projects/${projectId}/conferences/tags/order`, data);
};

export const getTagsOverview = (data: ConferenceTags.GetProjectConferenceTagsOverview.Request) => {
  return http.get<ConferenceTags.GetProjectConferenceTagsOverview.Response>(`/projects/${data.projectId}/conferences/tags/overview`);
};

export const getTagSummaries = (data: ConferenceTags.GetProjectConferenceTagSummaries.Request): Promise<ConferenceTags.GetProjectConferenceTagSummaries.Response> => {
  return http.get<ConferenceTags.GetProjectConferenceTagSummaries.Response<string>>(`/projects/${data.projectId}/conferences/tags/summaries`)
    .then(res => {
      return {
        ...res,
        items: res.items.map(transformConferenceTagSummaryItem),
      };
    });
};

export const exportTagSummaries = ({ projectId, ...data }: ConferenceTags.ExportProjectConferenceTagsSummaries.Request) => {
  return http.get(`/projects/${projectId}/conferences/tags/summaries/export?${qs.stringify({
    format: data.format,
    tagIds: data.tagIds,
    rolesToInclude: data.rolesToInclude,
  }, { arrayFormat: 'bracket' })}`);
};

export const generateTagSummaries = ({ projectId, ...data }: ConferenceTags.GenerateProjectConferenceTagSummaries.Request): Promise<ConferenceTags.GenerateProjectConferenceTagSummaries.Response> => {
  return http.post(`/projects/${projectId}/conferences/tags/summaries/generate`, {
    tagIds: data.tagIds,
  });
};

export const getTagSummaryFeedbackSummary = ({ projectId, tagSummaryId }: ConferenceTags.GetTagSummaryFeedbackSummary.Request): Promise<ConferenceTags.GetTagSummaryFeedbackSummary.Response> => {
  return http.get<ConferenceTags.GetTagSummaryFeedbackSummary.Response<string>>(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/feedback-summary`)
    .then(res => {
      return {
        ...res,
        items: res.items.map(transformConferenceTagSummaryFeedback),
      };
    });
};

export const getTagSummaryFeedback = ({ projectId, tagSummaryId }: ConferenceTags.GetTagSummaryFeedback.Request) => {
  return http.get<ConferenceTags.GetTagSummaryFeedback.Response>(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/feedback`);
};

export const updateTagSummaryRating = ({ projectId, tagSummaryId, ...data }: ConferenceTags.UpdateTagSummaryRating.Request): Promise<ConferenceTags.UpdateTagSummaryRating.Response> => {
  return http.patch(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/rating`, data);
};

export const updateTagSummaryNotes = ({ projectId, tagSummaryId, ...data }: ConferenceTags.UpdateTagSummaryNotes.Request): Promise<ConferenceTags.UpdateTagSummaryNotes.Response> => {
  return http.patch(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/notes`, data);
};

export const updateTagSummaryFeedback = ({ projectId, tagSummaryId, ...data }: ConferenceTags.UpdateTagSummaryFeedback.Request): Promise<ConferenceTags.UpdateTagSummaryFeedback.Response> => {
  return http.patch(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/feedback`, data);
};

export const getTagSummaryNotes = ({ projectId, tagSummaryId }: ConferenceTags.GetTagSummaryNotes.Request): Promise<ConferenceTags.GetTagSummaryNotes.Response> => {
  return http.get(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/notes`);
};

export const getProjectTaggedMoments = (data: ConferenceTags.GetProjectTaggedMoments.Request): Promise<ConferenceTags.GetProjectTaggedMoments.Response> => {
  return http.get(`/projects/${data.projectId}/conferences/tagged-moments?${qs.stringify({ tagIds: data.tagIds }, { arrayFormat: 'bracket' })}`);
};

export const exportProjectTaggedMoments = (data: ConferenceTags.ExportProjectTaggedMoments.Request) => {
  return http.get<ConferenceTags.ExportProjectTaggedMoments.Response>(`/projects/${data.projectId}/conferences/tagged-moments/export`);
};

export const getTagSummaryCitations = ({ projectId, tagSummaryId }: ConferenceTags.GetTagSummaryCitations.Request): Promise<ConferenceTags.GetTagSummaryCitations.Response> => {
  return http.get(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/citations`);
};

export const getTagSummaryVersions = ({ projectId, tagSummaryId }: ConferenceTags.GetTagSummaryVersions.Request): Promise<ConferenceTags.GetTagSummaryVersions.Response> => {
  return http.get<ConferenceTags.GetTagSummaryVersions.Response<string>>(`/projects/${projectId}/conferences/tags/summaries/${tagSummaryId}/versions`)
    .then(res => {
      return {
        ...res,
        items: res.items.map(transformTagSummary),
      };
    });
};

export const createModuleTags = ({ projectId, ...data }: ConferenceTags.CreateModuleTags.Request) => {
  return http.post<ConferenceTags.CreateModuleTags.Response>(`/projects/${projectId}/conferences/tags/modules`, data);
};