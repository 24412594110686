import * as types from '@store/action-types';
import type {
  PreferencesSettingChangedAction,
  PreferencesSettingChangedState,
} from '@store/interfaces';

export const preferencesSettingChanged = (data: PreferencesSettingChangedState): PreferencesSettingChangedAction => {
  return {
    email: data.email || {},
    sms: data.sms || {},
    type: types.PREFERENCES_SETTING_CHANGED,
  };
};