import { useCallback } from 'react';
import { ClosedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatSurveyNumber } from '@utils/survey';
import type { NumberInputTableQuestion, SurveyQuestionMatrixRow } from '@/types';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { Accordion } from '@/components/Accordion';
import styles from './style/NumberInputTable.Data.css';

type Props = {
  colors?: string[];
  item: NumberInputTableQuestion.Question;
  onClick: (rowId: number, optionId: number) => void;
  responses: NumberInputTableQuestion.Aggregate.Data;
};

export const NumberInputTableAggregateData = ({ colors = chart.options.OptionsColors, item, onClick, responses }: Props) => {

  const renderLabel = useCallback((row: SurveyQuestionMatrixRow) => () => {
    const avg = responses[row.base.id].avg;

    const subtitle = `(Avg: ${formatSurveyNumber(avg, item.settings.numberSettings)})`;
    const value = parseSurveyRichText(row.value);

    return (
      <div className={styles.labelWrap}>
        <span className={styles.label}>{value}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    );
  }, [
    responses,
    item.settings.numberSettings,
  ]);

  const renderItem = useCallback((row: SurveyQuestionMatrixRow) => {

    return (
      <Accordion
        key={row.id}
        collapsed
        height={50}
        grows={false}
        className={styles.row}
        showCount={false}
        label={renderLabel(row)}>
        {item.options.map((o, i) => {
          const avg = responses[row.base.id].options[o.base.id].avg;
          const subtitle = `(Avg: ${formatSurveyNumber(avg, item.settings.numberSettings)})`;

          return (
            <ClosedResponseOptionRow
              key={o.id}
              className={styles.clickable}
              color={colors[i]}
              label={parseSurveyRichText(o.value)}
              subtitle={subtitle}
              onViewAll={() => onClick(row.base.id, o.base.id)}
              onClick={() => onClick(row.base.id, o.base.id)} />
          );
        })}
      </Accordion>
    );
  }, [
    colors,
    item.options,
    onClick,
    renderLabel,
    responses,
    item.settings.numberSettings,
  ]);

  return (
    <>
      {item.matrixRows.map(renderItem)}
    </>
  );
};

export default NumberInputTableAggregateData;