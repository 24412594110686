import { CallType } from '@enums';
import http from '@services/http';
import * as xform from '@transformers';
import type * as Scheduling from './interfaces/scheduling.ad-hoc';

const createCall = (data: Scheduling.CreateCallRequest.Request): Promise<Scheduling.CreateCallRequest.Response> => {
  return http.post(`/scheduling/calls`, {
    ...data,
    type: CallType.AdHoc,
  })
  .then((res: Scheduling.CreateCallRequest.Response<string>) => ({
    call: res.call ? xform.normalizeCall(res.call) : null,
    contacts: res.contacts,
  }));
};

const rejectCallRequest = (data: Scheduling.RejectCallRequest.Request): Promise<Scheduling.RejectCallRequest.Response> => {
  return http.post(`/scheduling/calls/${data.callId}/rejections`, data)
  .then((res: Scheduling.RejectCallRequest.Response<string>) => ({
    call: xform.normalizeCall(res.call),
  }));
};

const acceptCallRequest = (data: Scheduling.AcceptCallRequest.Request): Promise<Scheduling.AcceptCallRequest.Response> => {
  return http.post(`/scheduling/calls/${data.callId}/acceptances`, data)
  .then((res: Scheduling.AcceptCallRequest.Response<string>) => ({
    call: xform.normalizeCall(res.call),
  }));
};

const cancelCall = (data: Scheduling.CancelCall.Request): Promise<Scheduling.CancelCall.Response> => {
  return http.put(`/scheduling/calls/${data.callId}/cancellations`, data)
    .then((res: Scheduling.CancelCall.Response<string>) => ({
      call: xform.normalizeCall(res.call),
    }));
};

const confirmCall = (data: Scheduling.ConfirmCall.Request): Promise<Scheduling.ConfirmCall.Response> => {
  return http.patch(`/scheduling/calls/${data.callId}/confirmations`, data)
    .then((res: Scheduling.ConfirmCall.Response<string>) => ({
      call: xform.normalizeCall(res.call),
      conference: xform.normalizeConference(res.conference),
    }));
};

const proposeTimes = (data: Scheduling.ProposeTimes.Request): Promise<Scheduling.ProposeTimes.Response> => {
  return http.post(`/scheduling/calls/${data.callId}/proposals`, data)
    .then((res: Scheduling.ProposeTimes.Response<string>) => ({
      call: xform.normalizeCall(res.call),
    }));
};

export const adHocCall = {
  accept: acceptCallRequest,
  cancel: cancelCall,
  confirm: confirmCall,
  create: createCall,
  proposeTimes,
  reject: rejectCallRequest,
};