import { memo } from 'react';
import { cx } from '@utils';
import { Reset as ResetIcon } from '@/components/icons';
import type { Item } from './interfaces';
import styles from './style/Item.css';

export const Reset = memo(({ title = 'Reset', onClick }: Item.Props) => {
  return (
    <div
      className={cx(styles.root)}
      onClick={onClick}>
      <ResetIcon
        className={styles.icon}
        size={22} />
      {title}
    </div>
  );
});