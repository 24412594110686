import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { CardContext } from './CardContext';
import styles from './BaseballCard.css';

const StyledHTMLTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--pri-01)',
    color: 'var(--black-l)',
    fontFamily: 'var(--font-reg)',
    width: 300,
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '8px',
    padding: '0px',
    boxShadow: '0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)',
    zIndex: 2,
    position: 'relative',
    overflow: 'hidden',
  },

  [`& .${tooltipClasses.popper}`]: {
    zIndex: 2,
  },
}));

export type CardTooltipProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  classes?: {
    container?: string;
    tooltip?: string;
  };
};

export const CardTooltip = ({ classes = {}, ...props }: CardTooltipProps) => {
  const [isOpen, setOpen] = useState(false);

  const closeCard = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <CardContext.Provider value={{ closeCard }}>
      <StyledHTMLTooltip
        className={classes.tooltip}
        title={props.title}
        enterDelay={750}
        placement={'bottom-start'}
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PopperProps={{
          //Stop events from bubbling out of the baseball card, this is for situations where the tooltip is inside links (like viewing survey responses).
          onClick: e => e.stopPropagation(),
          className: styles.titleContainer,
        }}>
        <div className={classes.container}>
          {props.children}
        </div>
      </StyledHTMLTooltip>
    </CardContext.Provider>
  );
};