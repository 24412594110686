import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import * as consts from '@consts';
import { useFetchCompanyOverview } from '@utils/api';
import { CompanyCurrentEmployeesContainer } from './CompanyCurrentEmployeesContainer';
import { CompanyFormerEmployeesContainer } from './CompanyFormerEmployeesContainer';
import { CompanyOverviewContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
  slug:     string;
};

export function CompanyOverviewContainer({ slug, ...props }: Props) {
  const history = useHistory();

  const query = useFetchCompanyOverview(slug, {
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        history.replace(consts.path.Companies.NotFound);
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.enabled,
  });

  const value = useMemo(() => ({
    query,
    ready: !(query.status === 'loading'),
  }), [query]);

  return (
    <CompanyOverviewContext.Provider value={value}>
      <CompanyStateContainer slug={slug}>
        {props.children}
      </CompanyStateContainer>
    </CompanyOverviewContext.Provider>
  );
}

function CompanyStateContainer(props: Omit<Props, 'enabled'>) {
  const { query } = useContext(CompanyOverviewContext);

  const enabled = useMemo(() => ({
    hasCurrentEmployees: query.data?.meta?.hasCurrentEmployees ?? false,
    hasFormerEmployees: query.data?.meta?.hasFormerEmployees ?? false,
  }), [query.data?.meta?.hasCurrentEmployees, query.data?.meta?.hasFormerEmployees]);

  return (
    <CompanyCurrentEmployeesContainer
      enabled={enabled.hasCurrentEmployees}
      slug={props.slug}>
      <CompanyFormerEmployeesContainer
        enabled={enabled.hasFormerEmployees}
        slug={props.slug}>
        {props.children}
      </CompanyFormerEmployeesContainer>
    </CompanyCurrentEmployeesContainer>
  );
}