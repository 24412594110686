import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { ConjointAnalysisQuestion, SurveyResponseItem } from '@/types';
import styles from './style/ConjointAnalysis.css';

type Props = {
  item: SurveyResponseItem<SurveyQuestionType.ConjointAnalysis>;
};

export const ConjointAnalysisAnswer = ({ item }: Props) => {

  const details = useMemo(() => {
    return item.answer.items
    .filter(f => Number.isInteger(f.selected))
    .reduce((acc, x) => {
      x.concepts[x.selected].forEach(f => {
        acc.attributes[f.attribute.id] = acc.attributes[f.attribute.id] + 1;
        acc.levels[f.level.id] = acc.levels[f.level.id] + 1;
      });
      return acc;
    }, {
      attributes: item.question.attributes.reduce((acc2, a) => ({
        ...acc2,
        [a.id]: 0,
      }), {} as { [id: number]: number }),
      levels: item.question.attributes.reduce((acc2, a) => ({
        ...acc2,
        ...a.levels.reduce((acc3, l) => ({
          ...acc3,
          [l.id]: 0,
        }), {}),
      }), {} as { [id: number]: number }),
    });

  }, [item.question.attributes, item.answer.items]);

  const sortedAttributes = useMemo(() => {
    return item.question.attributes.sort((a, b) => {
      return details.attributes[b.id] - details.attributes[a.id];
    });
  }, [item.question.attributes, details]);

  const Attribute = useCallback(({ attribute }: { attribute: ConjointAnalysisQuestion.Attribute }) => {
    const levels = attribute.levels.sort((a, b) => {
      return details.levels[b.id] - details.levels[a.id];
    });
    return (
      <div className={styles.item}>
        <div className={styles.attribute}>
          {attribute.value} ({details.attributes[attribute.id] || 0})
        </div>
        <div>
          {levels.map(level => (
            <div key={level.id} className={styles.level}>
              {level.value} ({details.levels[level.id] || 0})
            </div>
          ))}
        </div>
      </div>
    );
  }, [details]);

  const NoneOfTheAbove = useCallback(() => {
    if (!item.question.settings.includeNoneOfTheAbove) return null;

    const noneCount = item.answer.items.filter(f => !Number.isInteger(f.selected)).length;
    const totalCount = item.answer.items.length;

    return (
      <div className={styles.nota}>
        None of the above ({noneCount} of {totalCount} sets)
      </div>
    );
  }, [
    item.answer.items,
    item.question.settings.includeNoneOfTheAbove,
  ]);

  return (
    <>
      {
        sortedAttributes.map(attribute => (
          <Attribute
            key={attribute.id}
            attribute={attribute} />
        ))
      }
      <NoneOfTheAbove />
    </>
  );
};

export default ConjointAnalysisAnswer;