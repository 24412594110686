import { useState } from 'react';
import type { SurveyFiltering } from '@/types';
import type { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import type { FilterProps } from './utils';
import styles from './style/SurveyResponseFilter.css';
import { Selector } from './Selector';
import { CommonRow } from './CommonRow';

export const TextFilter = ({ filterEntry, filterActions }: FilterProps<SurveyQuestionType.LongTextResponse>) => {
  const [shouldContain, setShouldContain] = useState<boolean>(filterEntry.filter ? filterEntry.filter.shouldContain : true);
  const [textFilter, setTextFilter] = useState(filterEntry.filter?.text || '');

  function passbackFilter(partialFilter: Partial<SurveyFiltering.QuestionFilter<SurveyQuestionType.LongTextResponse>>) {
    filterActions.changeFilter({
      ...filterEntry,
      filter: {
        ...{
          type: 'text-filter',
          shouldContain,
          text: textFilter,
        }, ...partialFilter,
      },
    });
  }

  function changeShouldContain(v: boolean) {
    setShouldContain(v);
    passbackFilter({ shouldContain: v });
  }

  function changeTextFilter(v: string) {
    setTextFilter(v);
    passbackFilter({ text: v });
  }

  return (
    <CommonRow filterEntry={filterEntry} filterActions={filterActions}>
      <Selector
        items={[true, false]}
        displayValue={v => v ? 'Contains' : 'Does Not Contain'}
        onSelect={changeShouldContain}
        value={shouldContain} />
      <input
        className={cx(styles.filterRowInput, styles.fancyTextInput)}
        type={'text'}
        onChange={e => changeTextFilter(e.target.value)}
        value={textFilter}
        placeholder={'Input Text'} />
    </CommonRow>
  );
};