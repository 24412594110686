import { useMemo } from 'react';
import { cx, getOperatorText } from '@utils';
import type { SurveyLogic } from '@/types';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { getSumConditionValuesText } from './Builder.Condition.Sum';
import styles from './style/Preview.Condition.css';

type Props = {
  className?: string;
  item: SurveyLogic.SumCondition;
};

export const SurveySumCondition = ({ className, item }: Props) => {

  const [state] = useSurveyBuilderState();

  const sumText = useMemo(() => {
    return getSumConditionValuesText(item.data.items, state.survey.questions);
  }, [item.data.items, state.survey.questions]);

  return (
    <div className={cx(styles.root, className)}>
      Sum of ({sumText}) is {getOperatorText(item.data.operator)} {item.data.comparate}
    </div>
  );
};