import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetConferenceParticipants } from '@api/interfaces/calls';
import * as api from '@api';

type Props = ICallId;

type Data = GetConferenceParticipants.Response;
type QueryKey = [string, Props];

export function useConferenceParticipants(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery({ queryKey: ['call-conference-participants', data], queryFn: ({ queryKey }) => {
    const params = queryKey[1] as Props;

    return api.calls.getConferenceParticipants(params);

  }, ...options });
}
