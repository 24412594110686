import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { TopicItemContainer } from '@containers/Topic';
import { TopicItemContext } from '@containers/Topic/Context';
import { Header } from './Header';
import { Column, ScreenLayout } from './Layout';
import { Tabs } from './Tabs';
import type { RouteParams } from './interfaces';
import styles from './style/TopicLanding.css';

type Props = unknown;

export const TopicLanding = (props: Props) => {
  const params = useParams<RouteParams>();

  return (
    <TopicItemContainer
      enabled={!!params.slug}
      slug={params.slug}>
      <TopicScreen />
    </TopicItemContainer>
  );
};

TopicLanding.displayName = 'Topic.Landing';

const TopicScreen = (props: Props) => {
  const { query, ready, toggleFollowing } = useContext(TopicItemContext);

  if (!ready) return null;

  return (
    <>
      <Helmet title={query.data.item?.name ?? 'Topic'} />
      <ScreenLayout>
        <Column>
          <div className={styles.header}>
            <Header
              item={query.data.item}
              following={query.data.meta.following}
              onFollow={toggleFollowing} />
          </div>
          <Tabs
            item={query.data.item}
            meta={query.data.meta} />
        </Column>
      </ScreenLayout>
    </>
  );
};

TopicScreen.displayName = 'Topic.Screen';