import { memo } from 'react';
import { cx } from '@utils';
import { MailBulk } from '@/components/icons';
import type { Item } from './interfaces';
import styles from './style/Item.css';

export const Followup = memo(({ title = 'Follow up', onClick }: Item.Props) => {
  return (
    <div
      className={cx(styles.root, styles.disabled)}
      onClick={onClick}>
      <MailBulk
        className={styles.icon}
        size={22} />
      {title}
    </div>
  );
});