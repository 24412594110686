import type { IConference } from '@containers/Conference';
import { useConferenceInstance } from '@containers/Conference';
import { Duration } from '@screens/ConferenceSession';
import { useYourParticipant } from './hooks';
import styles from './style/Session.Status.css';

export function SessionStatus() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();

  if (instance.conference.started) {
    return (
      <Duration
        className={styles.duration}
        startedOn={instance.conference.started} />
    );
  }

  if (you.isHost) {
    return (
      <div className={styles.waiting}>
        Awaiting for other participants to join...
      </div>
    );
  } else {
    return (
      <div className={styles.waiting}>
        Awaiting for host to join...
      </div>
    );
  }
}