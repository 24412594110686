import { useCallback } from 'react';
import { DocumentDownloadContext } from '@containers/Document/Context';
import type { DocumentItem } from '@/types';
import { DocumentDownload as DocumentDownloader } from '@/components/DocumentDownload';
import * as api from '$admin/api';

type Props = {
  children: React.ReactChild;
  filename: string;
  item:     DocumentItem;
};

export const DocumentDownload = (props: Props) => {
  const downloadGroupUserDocument = useCallback((data: IGroupId & IUserId) => {
    return api.documents.group.download(data);
  }, []);

  const downloadProjectUserDocument = useCallback((data: IProjectId & IUserId) => {
    return api.documents.project.download(data);
  }, []);

  const value = {
    downloadGroupUserDocument,
    downloadProjectUserDocument,
  };

  return (
    <DocumentDownloadContext.Provider value={value}>
      <DocumentDownloader
        filename={props.filename}
        item={props.item}>
        {props.children}
      </DocumentDownloader>
    </DocumentDownloadContext.Provider>
  );
};