import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import type * as API from '$admin/api/interfaces';
import * as CallRescheduling from '$admin/components/Call.Rescheduling';
import type { Call as CD } from '$admin/interfaces';

type Props = {
  children: React.ReactNode;
} & ICallId
  & IProjectId
  & IUserId;

export const CallReschedulingContainer = (props: Props) => {
  const qc = useQueryClient();

  const querykey = useMemo(() => {
    return QK.Calls.Call.Get({ callId: props.callId });
  }, [props.callId]);

  const setQueryData = useCallback((res: API.Calls.Bookings.Reschedule.Response) => {
    qc.setQueryData(querykey, (old: CD.ProjectCallDetails) => ({
      ...old,
      call: {
        ...old.call,
        lastModifiedBy: res.call.lastModifiedBy,
        statusId: res.call.statusId,
      },
    }));
  }, [
    qc,
    querykey,
  ]);

  const handleSuccess = useCallback((res: API.Calls.Bookings.Reschedule.Response) => {
    if (props.callId === res.call.id) {
      setQueryData(res);
    }
  }, [
    props.callId,
    setQueryData,
  ]);

  return (
    <CallRescheduling.Container onSuccess={handleSuccess}>
      {props.children}
    </CallRescheduling.Container>
  );
};

CallReschedulingContainer.displayName = 'Call.Rescheduling.Container';