import type { HTMLAttributes } from 'react';
import { memo, useCallback } from 'react';
import { cx } from '@utils';
import styles from './style/GroupTable.css';

type CellProps = {
  className?: string;
} & ChildrenProps
 & HTMLAttributes<HTMLDivElement>;

export const GroupTableRow = memo(({ children, className, ...props  }: CellProps) => {
  return (
    <div {...props} className={cx(styles.tr, className)}>
      {children}
    </div>
  );
});

export const GroupTableCell = memo(({ children, className, ...props  }: CellProps) => {
  return (
    <div {...props} className={cx(styles.td, className)}>
      {children}
    </div>
  );
});