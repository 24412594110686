import { useMemo } from 'react';
import type { MutateOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { response as $response } from '@api/surveys';
import type { Surveys } from '@api/interfaces/surveys';
import { useSurveyResponseIdentifier, useSurveyFormVersionId, useSurveyFormScreenContext, useSurveyFormQuestionAnswer, useSurveyFormAnswerRationale, useSurveyFormApi } from '../Context';

type Data = Surveys.Response.GoBack.Response;
type Options = MutateOptions<Data, AxiosError>;

export const useSurveyMessageGoBack = (options?: Options) => {
  const surveyVersionId = useSurveyFormVersionId();
  const responseIdentifier = useSurveyResponseIdentifier();
  const [state, setState] = useSurveyFormScreenContext();

  const goBack = useGoBackApi();

  return useMutation({ mutationKey: ['survey-response-go-back', responseIdentifier], mutationFn: () => {
    return goBack({
      rationale: null,
      responseIdentifier,
      surveyVersionId,
      surveyVersionItemId: state.item.id,
      value: null,
    });
  }, ...options, onSuccess: res => {
    setState(res.state);
  } });
};

export const useSurveyQuestionGoBack = (options?: Options) => {
  const surveyVersionId = useSurveyFormVersionId();
  const responseIdentifier = useSurveyResponseIdentifier();
  const [state, setState] = useSurveyFormScreenContext();
  const [answer] = useSurveyFormQuestionAnswer();
  const [rationale] = useSurveyFormAnswerRationale();

  const goBack = useGoBackApi();

  return useMutation({ mutationKey: ['survey-response-go-back', responseIdentifier], mutationFn: () => {
    return goBack({
      rationale,
      responseIdentifier,
      surveyVersionId,
      surveyVersionItemId: state.item.id,
      value: answer,
    });
  }, ...options, onSuccess: res => {
    setState(res.state);
  } });
};

function useGoBackApi() {
  const handlers = useSurveyFormApi();

  return useMemo(() => handlers?.goBack || $response.goBack, [handlers?.goBack]);
}