import { useCallback, useContext, useMemo, forwardRef } from 'react';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { ProjectActionsMenuContext } from './Context';
import { RemovePerson, Upload } from './Item';
import type { Item } from './interfaces';

type Props = {
  onUploadLeads?: Item.OnClick;
  showUploadLeads?: boolean;
  onRemoveLeads?: Item.OnClick;
  showRemoveLeads?: boolean;
};

export const Leads = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { close } = useContext(ProjectActionsMenuContext);

  const closer = useCallback((handler: Item.OnClick) => () => {
    close();
    handler();
  }, [close]);

  const showDivider = useMemo(() => props.showRemoveLeads, [props.showRemoveLeads]);

  return (
    <div ref={ref}>
      {props.showUploadLeads && (
        <MenuItem
          disableRipple
          disabled={!props.onUploadLeads}
          onClick={closer(props.onUploadLeads)}>
          <Upload title="Upload Leads" />
        </MenuItem>
      )}
      {showDivider && <Divider sx={{ margin: '8px 10px' }} />}
      {props.showRemoveLeads && (
        <MenuItem
          disableRipple
          disabled={!props.onRemoveLeads}
          onClick={closer(props.onRemoveLeads)}>
          <RemovePerson title="Remove Leads" />
        </MenuItem>
      )}
    </div>
  );
});