import { useCallback, useState } from 'react';
import ActivateProjectPrompt from '../ActivateProjectPrompt';
import type { UsePromptProps } from '../interfaces';

type Props =
  UsePromptProps;

export const useActivateProjectPrompt = ({ onConfirm, project }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);

  const handleConfirm = useCallback(() => {
    onConfirm()
    .then(toggleVisibility);
  }, [onConfirm, toggleVisibility]);

  const Modal = useCallback(() => (
    <ActivateProjectPrompt
      onConfirm={handleConfirm}
      onClose={toggleVisibility}
      project={project}
      visible={visible} />
  ), [
    handleConfirm,
    project,
    visible,
    toggleVisibility,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useActivateProjectPrompt;