import { useCallback } from 'react';
import type { ActionMeta, OnChangeValue  } from 'react-select';
import { createFilter  } from 'react-select';
import { SelectSearchUnderlined } from '$admin/components';

type Props = {
  autofilled?:  boolean;
  className?:   string;
  clearable?:   boolean;
  matchFrom?:   'start' | 'any';
  name?:        string;
  onBlur?:      (e: React.FocusEvent<HTMLDivElement>) => unknown;
  onChange:     (newValue: OnChangeValue<Descriptor, false>, actionMeta: ActionMeta<Descriptor>) => unknown;
  placeholder?: string;
  options:      Descriptor[];
  value:        number;
};

export const SelectSearch = ({ matchFrom = 'start', ...props }: Props) => {

  const filterOption = useCallback((option: FilterOptionOption<Descriptor>, rawInput: string) => {
    const defaultFilter = createFilter<Descriptor>({
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom,
    });

    const nameFilter = (option: FilterOptionOption<Descriptor>, input: string) => {
      if (input) {
        return option.data.name.toLowerCase() === input.toLowerCase();
      }
      return true;
    };

    return defaultFilter(option, rawInput) || nameFilter(option, rawInput);
  }, [matchFrom]);

  return (
    <SelectSearchUnderlined
      className={props.className}
      isClearable={props.clearable}
      defaultValue={props.options?.find?.(x => x.id === props.value)}
      value={props.options?.find?.(x => x.id === props.value)}
      options={props.options || []}
      getOptionLabel={x => x.name}
      getOptionValue={x => String(x.id)}
      name={props.name}
      noOptionsMessage={x => `No matches found.`}
      onBlur={props.onBlur}
      onChange={props.onChange}
      placeholder={props.placeholder}
      filterOption={filterOption}
      styles={makeStyles(props.autofilled)} />
  );
};

SelectSearch.displayName = 'Field.Setup.SelectSearch';

const makeStyles = (autofilled: boolean) => {
  return {
    control: {
      height: 33,
      ...autofilled ? { backgroundColor: '#faffbd' } : {},
    },
  };
};

type FilterOptionOption<D> = {
  label: string;
  value: string;
  data: D;
};