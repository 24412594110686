import { useCallback, useMemo, useState, Fragment } from 'react';
import cuid from 'cuid';
import { FormButtons } from '@presentation/FormButtons';
import { GrayOutlineButton } from '@/presentation/Buttons';
import type { NumberInputTableQuestion } from '@/types';
import { OperatorDropdown, OptionsDropdown } from '@/presentation/SurveyBuilder';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { AddItemButton } from '@presentation/AddItemButton';
import { Trash, Edit } from '@/components/icons';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { NumberInput } from '@/components/Input';
import styles from './style/NumberInputTable.Modal.ColumnValidations.css';

type Props = {
  question: NumberInputTableQuestion.Question;
  option: NumberInputTableQuestion.Option;
} & Pick<ModalProps,
  'open' |
  'onClose'>;

type ColumnValidationProps = {
  question: NumberInputTableQuestion.Question;
  option: NumberInputTableQuestion.Option;
  initialValue: NumberInputTableQuestion.OtherColumnValidation;
  onSave: (data: NumberInputTableQuestion.OtherColumnValidation) => void;
  onCancel: () => void;
};

type NumberValidationProps = {
  question: NumberInputTableQuestion.Question;
  option: NumberInputTableQuestion.Option;
  initialValue: NumberInputTableQuestion.ColumnNumberValidation;
  onSave: (data: NumberInputTableQuestion.ColumnNumberValidation) => void;
  onCancel: () => void;
};

const ColumnValidation = ({ question, option, onSave, onCancel, initialValue }: ColumnValidationProps) => {
  const [operator, setOperator] = useState<Operator>(initialValue.operator);
  const [optionIdentifier, setOptionIdentifier] = useState<string>(initialValue.comparate.value.option.identifier);

  const selectedOption = useMemo(() => {
    return question.options.find(f => f.base.identifier === optionIdentifier);
  }, [optionIdentifier, question.options]);

  const optionDropdownItems = useMemo(() => {
    return question.options.filter(f => f.base.identifier !== option.base.identifier);
  }, [question.options, option.base.identifier]);

  const canSubmit = useMemo(() => {
    return !!operator && !!optionIdentifier;
  }, [operator, optionIdentifier]);

  const handleSubmit = useCallback(() => {
    onSave({
      identifier: initialValue.identifier,
      comparate: {
        type: 'column-value',
        value: {
          option: { identifier: optionIdentifier },
        },
      },
      operator,
    });
  }, [initialValue, onSave, operator, optionIdentifier]);

  return (
    <div className={styles.row}>
      <div className={styles.operatorDropdown}>
        <OperatorDropdown
          onSelect={setOperator}
          value={operator} />
      </div>
      <div className={styles.optionsDropdown}>
        <OptionsDropdown
          placeholder="Select column"
          onSelect={setOptionIdentifier}
          items={optionDropdownItems}
          value={selectedOption} />
      </div>
      <FormButtons
        className={styles.formButtons}
        size={18}
        archivable={false}
        disabled={!canSubmit}
        deletable={false}
        onCancel={onCancel}
        onSubmit={handleSubmit} />
    </div>
  );
};

const NumberValidation = ({ question, option, onSave, onCancel, initialValue }: NumberValidationProps) => {
  const [operator, setOperator] = useState<Operator>(initialValue.operator);
  const [value, setValue] = useState<number>(initialValue.comparate.value);

  const canSubmit = useMemo(() => {
    return !!operator && value !== null;
  }, [operator, value]);

  const handleSubmit = useCallback(() => {
    onSave({
      identifier: initialValue.identifier,
      comparate: {
        type: 'number',
        value,
      },
      operator,
    });
  }, [initialValue, onSave, operator, value]);

  return (
    <div className={styles.row}>
      <div className={styles.operatorDropdown}>
        <OperatorDropdown
          onSelect={setOperator}
          value={operator} />
      </div>
      <div className={styles.numberInput}>
        <NumberInput
          onValueChange={e => setValue(e.floatValue)}
          value={value} />
      </div>
      <FormButtons
        className={styles.formButtons}
        size={18}
        archivable={false}
        disabled={!canSubmit}
        deletable={false}
        onCancel={onCancel}
        onSubmit={handleSubmit} />
    </div>
  );
};

export const NumberInputTableColumnValidationModal = ({
  onClose,
  open,
  option,
  question,
}: Props) => {

  const [validations, setValidations] = useState(option.metadata.validations || []);
  const [editing, setEditing] = useState<{
    identifier: string;
    isNew: boolean;
  }>({
    isNew: false,
    identifier: null,
  });

  const [_, dispatch] = useSurveyBuilderState();

  const isEditing = useMemo(() => {
    return !!editing.identifier;
  }, [editing]);

  const handleRemoveValidation = useCallback((identifier: string) => () => {
    setValidations(s => s.filter(f => f.identifier !== identifier));
    setEditing({
      identifier: null,
      isNew: false,
    });
  }, []);

  const handleSaveValidation = useCallback((identifier: string) => (value: NumberInputTableQuestion.ColumnValidation) => {
    setValidations(s => s.map(m => {
      if (m.identifier === identifier) {
        return value;
      }
      return m;
    }));
    setEditing({
      identifier: null,
      isNew: false,
    });
  }, []);

  const handleAddNumberValidation = useCallback(() => {
    const identifier = cuid();
    setValidations(s => s.concat({
      identifier,
      operator: '<',
      comparate: {
        type: 'number',
        value: null,
      },
    }));
    setEditing({
      identifier,
      isNew: true,
    });
  }, []);

  const handleEditValidation = useCallback((identifier: string) => () => {
    setEditing({
      identifier,
      isNew: false,
    });
  }, []);

  const handleAddColumnValidation = useCallback(() => {
    const identifier = cuid();
    setValidations(s => s.concat({
      identifier,
      operator: '<',
      comparate: {
        type: 'column-value',
        value: {
          option: { identifier: null },
        },
      },
    }));
    setEditing({
      identifier,
      isNew: true,
    });
  }, []);

  const handleCancelEditing = useCallback((initialValue: NumberInputTableQuestion.ColumnValidation) => () => {
    setEditing({
      identifier: null,
      isNew: false,
    });
    if (editing.isNew) {
      setValidations(s => s.filter(f => f.identifier !== editing.identifier));
    } else {
      setValidations(s => {
        return s.reduce((acc, x) => {
          if (x.identifier === editing.identifier) return acc.concat(initialValue);
          return acc.concat(x);
        }, []);
      });
    }
  }, [editing]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'number-input-table-option-validations-updated',
      payload: {
        questionIdentifier: question.base.identifier,
        option: { identifier: option.base.identifier },
        value: validations,
      },
    });
    onClose();
  }, [dispatch, validations, question.base.identifier, option.base.identifier, onClose]);

  const renderValidation = useCallback((item: NumberInputTableQuestion.ColumnValidation, index: number) => {
    if (item.comparate.type === 'column-value') {
      const castedItem = item as NumberInputTableQuestion.OtherColumnValidation;
      if (item.identifier === editing.identifier) {
        return (
          <ColumnValidation
            question={question}
            option={option}
            initialValue={castedItem}
            onCancel={handleCancelEditing(castedItem)}
            onSave={handleSaveValidation(item.identifier)} />
        );
      } else {
        const option = question.options.find(f => f.base.identifier === castedItem.comparate.value.option.identifier);
        return (
          <div className={styles.row}>
            <div className={styles.bullet}>V{index + 1}</div>
            All column values must be {item.operator} value in C{option.ordinal}
            {!isEditing &&
              <Trash
                className={styles.trash}
                size={18}
                onClick={handleRemoveValidation(item.identifier)} />
            }
            {!isEditing &&
              <Edit
                className={styles.edit}
                size={18}
                onClick={handleEditValidation(item.identifier)} />
            }
          </div>
        );
      }
    } else if (item.comparate.type === 'number') {
      const castedItem = item as NumberInputTableQuestion.ColumnNumberValidation;
      if (item.identifier === editing.identifier) {
        return (
          <NumberValidation
            question={question}
            option={option}
            initialValue={castedItem}
            onCancel={handleCancelEditing(castedItem)}
            onSave={handleSaveValidation(item.identifier)} />
        );
      } else {
        return (
          <div className={styles.row}>
            <div className={styles.bullet}>V{index + 1}</div>
            All column values must be {item.operator} {item.comparate.value}
            {!isEditing &&
              <Trash
                className={styles.trash}
                size={18}
                onClick={handleRemoveValidation(item.identifier)} />
            }
            {!isEditing &&
              <Edit
                className={styles.edit}
                size={18}
                onClick={handleEditValidation(item.identifier)} />
            }
          </div>
        );
      }
    }
  }, [
    isEditing,
    editing,
    question,
    option,
    handleRemoveValidation,
    handleSaveValidation,
    handleEditValidation,
    handleCancelEditing,
  ]);

  const title = `Manage Column Validations`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text={title} />

      {validations.map((item, i) => (
        <Fragment key={item.identifier}>
          {renderValidation(item, i)}
        </Fragment>
      ))}

      {!isEditing &&
        <AddItemButton
          className={styles.addItem}
          label="Add Column Validation"
          onClick={handleAddColumnValidation} />}

      {!isEditing &&
        <AddItemButton
          className={styles.addItem}
          label="Add Number Validation"
          onClick={handleAddNumberValidation} />}

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={isEditing}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>

    </Modal>
  );
};

export const useNumberInputTableColumnValidationsModal = () => useModal(NumberInputTableColumnValidationModal);