import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as $tableau from '@services/api/tableau';

const queryKey = ['get-tableau-api-key'];
export const useTableauAPIKey = () => {
  const queryResult = useQuery({ queryKey, queryFn: $tableau.getTableauAPIKey });

  return {
    loading: queryResult.isLoading,
    key: queryResult.data?.key,
  };
};

export const useInvalidateAPIKeyQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    await queryClient.invalidateQueries(queryKey);
  }, [queryClient]);
};