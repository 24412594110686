import { CreditRateWithLabel, CreditRateWithTooltip } from './Credit';
import DollarRate from './Dollar';
import type { ExpertRateProps } from './interfaces';

const ExpertRate = (props: ExpertRateProps) => {
  if (!props.visible || !props.user) return null;

  const { user, observer, visible, ...rest } = props;

  return <DollarRate
    rate={user.profile.hourlyRate}
    currency={rest.currency}
    {...rest} />;

  // return observer.id === user.id
  //        ? <DollarRate rate={user.profile.hourlyRate} {...rest} />
  //        : <CreditRateWithTooltip rate={user.profile.creditRate} {...rest} />;
};

const ExpertRateProfile = (props: ExpertRateProps) => {
  if (!props.visible || !props.user) return null;

  const { user, observer, visible, ...rest } = props;

  return <DollarRate
    rate={user.profile.hourlyRate}
    currency={rest.currency}
    {...rest} />;

  // return observer.id === user.id
  //        ? <DollarRate rate={user.profile.hourlyRate} {...rest} />
  //        : <CreditRateWithLabel rate={user.profile.creditRate} {...rest} />;
};

export {
  CreditRateWithLabel,
  CreditRateWithTooltip,
  ExpertRate,
  ExpertRateProfile,
  DollarRate,
};

export * from './Picker';