import { useEffect, useMemo } from 'react';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import * as api from '@/services/api/workspaces.file';
import { path, querykey } from '@consts';
import { DownloadFileContainer } from '@/containers/Workspace.File/DownloadFileContainer';
import { FileProjectsContainer } from '@/containers/TaggedProjects';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { GroupFileTagsContainer } from '@/containers/GroupTags';
import FileTranscriptStatusContainer from '@/containers/Workspace.File/TranscriptAvailableContainer';
import FileSummaryContainer from '@/containers/Workspace.File/SummaryContainer';
import { FilePreviewSocketContainer } from './SocketContainer';
import {
  WorkspaceFileStateContext,
  WorkspaceFilePreviewContext,
  WorkspaceFilePreviewReloadContext,
  WorkspaceFilePreviewLoadingContext,
} from './Context';

type Props =
  ChildrenProps;

type RouteParams = {
  fileId: string;
};

const usePageParams = () => {
  const params = useParams<RouteParams>();
  return useMemo(() => ({
    fileId: +params.fileId,
  }), [params.fileId]);
};

export const WorkspaceFilePreviewContainer = (props: Props) => {
  const params = usePageParams();
  const history = useHistory();

  const { data, isLoading, refetch, ...query } = useQuery({ queryKey: querykey.Workspaces.FilePreview.Get({ fileId: params.fileId }), queryFn: () => {
    return api.getFilePreview({
      fileId: params.fileId,
    });
  }, onError: (error: AxiosResponse) => {
    if (error.status === 404) {
      history.replace(path.Workspaces.FileNotFound);
    }
  } });

  const state = {
    capabilities: data?.capabilities,
    features: data?.features,
    project: data?.project,
    meta: data?.meta,
    file: data?.file,
    object: data?.object,
    transcript: data?.transcript,
  };

  const documentContext: BrandInsightsPageContext.Document = useMemo(() => {
    if (!state.features?.insightsChat) return null;

    return {
      type: ChatContextType.Document,
      data: {
        documentId: params.fileId,
        projectId: data?.project?.id,
      },
      metadata: {
        name: state?.file?.name,
      },
    };
  }, [params.fileId, data?.project?.id, state?.file?.name, state?.features?.insightsChat]);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    if (!documentContext) return;

    if (documentContext.data.documentId && documentContext.metadata.name) {
      register(documentContext);
    }
  }, [register, documentContext]);

  if (query.isFetched && !data?.preview) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Workspaces.FileNotFound} />;
  }

  return (
    <WorkspaceFilePreviewReloadContext.Provider value={refetch}>
      <WorkspaceFileStateContext.Provider value={state}>
        <WorkspaceFilePreviewContext.Provider value={data?.preview}>
          <WorkspaceFilePreviewLoadingContext.Provider value={isLoading}>
            <GroupFileTagsContainer fileId={params.fileId}>
              <FileProjectsContainer fileId={params.fileId} workspaceId={data?.object?.workspaceId}>
                <DownloadFileContainer
                  fileId={params.fileId}
                  workspaceId={state.object?.workspaceId}>
                  <FilePreviewSocketContainer fileId={params.fileId}>
                    <WorkspaceBreadcrumbsContext.Provider value={data?.breadcrumbs}>
                      <FileTranscriptStatusContainer fileId={params.fileId} transcript={data?.transcript}>
                        <FileSummaryContainer fileId={params.fileId}>
                          {props.children}
                        </FileSummaryContainer>
                      </FileTranscriptStatusContainer>
                    </WorkspaceBreadcrumbsContext.Provider>
                  </FilePreviewSocketContainer>
                </DownloadFileContainer>
              </FileProjectsContainer>
            </GroupFileTagsContainer>
          </WorkspaceFilePreviewLoadingContext.Provider>
        </WorkspaceFilePreviewContext.Provider>
      </WorkspaceFileStateContext.Provider>
    </WorkspaceFilePreviewReloadContext.Provider>
  );
};

export default WorkspaceFilePreviewContainer;
