import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from './useSurveyQuestionResponsesData';

export default function useMaxDiffSortedOptions() {
  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MaxDifference>();

  return useMemo(() => {
    return question.options.sort((a, b) => {
      const A = responses.options[a.base.id];
      const B = responses.options[b.base.id];
      return B.score - A.score;
    });
  }, [question.options, responses.options]);
}

export { useMaxDiffSortedOptions };