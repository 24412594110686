import http from '@services/http';
import type { Posts } from '$admin/api/interfaces';
import * as xform from '$admin/api/transformers';

export const createPost = (data: Posts.CreateVideoPost.Request): Promise<Posts.CreateVideoPost.Response> => {
  return http.post(`/admin/posts/videos`, data);
};

export const fetchItems = (): Promise<Posts.FetchVideoPosts.Response> => {
  return http.get(`/admin/posts/videos`)
  .then((res: Posts.FetchVideoPosts.Response<string>) => xform.posts.transformAggregateItems(res));
};