import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import type { SurveyTemplate } from '@/types';
import { Input } from '@/components/Input';

type Props = {
  className?: string;
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  placeholder: string;
  value: SurveyTemplate.LinkedEntity;
};

export const TemplateInput = ({ className, onChange, placeholder, value }: Props) => {

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      id: value.id,
      value: e.target.value,
    });
  }, [onChange, value.id]);

  return (
    <Input
      rootClassName={className}
      placeholder={placeholder}
      onChange={handleChange}
      value={value.value} />
  );
};