import { Edit3, Info } from 'react-feather';
import { Tooltip } from '@presentation/Tooltip';
import { useHasInternalAdminRole } from '@containers/User/hooks';
import {
  useSetSummaryRatingContext,
  useSummaryFeedbackModalToggleContext,
  useSummaryFeedbackClickedContext,
  useSummaryCitationsClickedContext,
} from '@containers/ConferenceTag.Summary/context';
import type { ConferenceTagSummaryItem } from '@/types';
import { StarRating } from '@/components/StarRating';
import styles from './style/Feedback.css';

type ViewFeedbackProps = {
  item: ConferenceTagSummaryItem;
};

export const ViewFeedback = ({ item }: ViewFeedbackProps) => {
  const hasAdminRole = useHasInternalAdminRole();

  const openFeedbackModal = useSummaryFeedbackClickedContext(item);

  if (!item.summary) return null;

  if (!hasAdminRole || !item.summary.meta.hasFeedback) return null;

  const tooltip = [
    'View Feedback',
    `(Avg. Rating: ${item.summary.meta.avgRating})`,
  ].join('\n');

  return (
    <Tooltip title={tooltip}>
      <Info
        className={styles.users}
        onClick={openFeedbackModal} />
    </Tooltip>
  );
};

type ViewCitationsProps = {
  item: ConferenceTagSummaryItem;
};

export const ViewCitations = ({ item }: ViewCitationsProps) => {
  const openCitationsModal = useSummaryCitationsClickedContext(item);

  if (!item.summary) return null;

  return (
    <div className={styles.viewFeedback} onClick={openCitationsModal}>
      View Citations ({item.summary.meta.taggedMomentsCount})
    </div>
  );
};

type FeedbackProps = {
  item: ConferenceTagSummaryItem;
};

export const SummaryFeedback = ({ item }: FeedbackProps) => {

  const setRating = useSetSummaryRatingContext();

  const openFeedbackModal = useSummaryFeedbackModalToggleContext(item);

  return (
    <div className={styles.feedback}>
      <StarRating
        rootClassName={styles.rating}
        onClick={setRating(item)}
        rating={item.summary.feedback.rating} />

      <div className={styles.divider} />

      <Tooltip title="Additional Feedback">
        <Edit3
          className={styles.notes}
          onClick={openFeedbackModal} />
      </Tooltip>
    </div>
  );
};