import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import type {
  QueryParamConfig } from 'use-query-params';
import {
  useQueryParams,
} from 'use-query-params';
import { pathname } from '@consts';
import { useSelectProject } from '@containers/Store';
import { getLocationFor } from '@utils';
import type { ProjectReportType } from '@/types';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useDownloadProjectReport } from '@/components/GroupProject/hooks/useDownloadProjectReport';

type RouteParams = {
  projectId: string;
};
const FormatParam: QueryParamConfig<ProjectReportType, ProjectReportType> = {
  encode: (format: ProjectReportType) => format as string,
  decode: (format: string) => format as ProjectReportType,
};

export const ProjectReport = () => {
  const params = useParams<RouteParams>();
  const history = useHistory();

  const [query] = useQueryParams({
    format: FormatParam,
  });

  const project = useSelectProject(+params.projectId);

  const {
    refetch: download,
  } = useDownloadProjectReport({
    format: query.format || 'ppt',
    isParentReport: false,
    projectId: +params.projectId,
    projectParentId: null,
  },
  {
    onError: e => {
      history.push(pathname.Home);
    },
    onSuccess: res => {
      const location = getLocationFor.project.root(project);
      history.push(location);
    },
  });

  useEffect(() => {
    download();

  }, [
    download,
  ]);

  return <ActivityIndicator show />;
};

export default ProjectReport;