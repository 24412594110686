import { useCallback, useMemo } from 'react';
import * as chart from '@containers/SurveyResponses/utils';
import { OpenedResponseOptionRow, ResponseRankingAvgRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import type { SurveyAggregate } from '@/types';
import { useRankingColors } from './hooks/usePaletteColors';
import ResponseOptionUser from './ResponseOptionUser';
import styles from './style/Ranking.Option.css';

type Props = {
  responses: SurveyAggregate.StackedBarChart.Data;
  optionId: number;
  selectedKey?: string;
  onReset: () => void;
};

export const ResponseRankingOption = (props: Props) => {
  const item = useMemo(() => props.responses.indexes.values[props.optionId], [props.optionId, props.responses]);
  const { extendedStrong, extendedWeak, strong, weak } = useRankingColors();

  const renderRankUsers = useCallback(() => {
    const userIds = item.keys[props.selectedKey].userIds;

    const backgroundColor = chart.ranking.getSegmentColor({
      key: props.selectedKey,
      keys: props.responses.keys,
    }, {
      extendedStrong,
      extendedWeak,
      strong,
      weak,
    });

    const label = chart.ranking.formatRankedText(props.selectedKey);
    const subtitle = `(${userIds.length} ${formatPlurality('Respondent', userIds.length)})`;

    return (
      <>
        <OpenedResponseOptionRow
          color={backgroundColor}
          label={label}
          subtitle={subtitle}
          onClose={props.onReset} />
        {userIds.map(userId => (
          <ResponseOptionUser
            key={userId}
            className={styles.rankUser}
            userId={userId} />
        ))}
      </>
    );
  }, [
    extendedStrong,
    extendedWeak,
    item,
    props.onReset,
    props.responses.keys,
    props.selectedKey,
    strong,
    weak,
  ]);

  return (
    <>
      <ResponseRankingAvgRow
        avg={item.aggregate.avg}
        value={item.value} />
      {props.responses.keys.ids.includes(props.selectedKey) && renderRankUsers()}
    </>
  );
};

export default ResponseRankingOption;