import type { Currencies } from '@/types';
import type * as PH from './interfaces';

export function getInitialTitle(items: string[]) {
  const numbers = items.map(x => {
    const match = x.match(/^Honoraria[\s]/);

    if (!match) return null;
    const tail = x.slice(10);

    if (tail.match(/\D/)) return null;

    const digit = tail.match(/\d/);

    return digit?.length ? +(digit[0]) : null;
  })
  .filter(Boolean);

  if (!numbers.length) return `Honoraria 1`;

  const max = Math.max(...numbers);

  return `Honoraria ${max + 1}`;
}

export function parseAmountValue(amount: number | string) {
  if (typeof amount === 'number') return amount;

  return parseFloat(amount.match(/^(\D|\W)/) ? amount.slice(1) : amount);
}

export function selectNonEmptyEntries(items: PH.Form.Item[]) {
  const filled = items.filter(x => {

    return validator.amount(x.value)
        || validator.currency(x.currency)
        || validator.title(x.title);
  });

  return filled;
}

export const transformSubmitData = (values: PH.Form.State) => {
  return values.honoraria
    .filter(validator.entry)
    .map(x => {
      const id = typeof x.id === 'number'
          ? { id: x.id }
          : {};
      const value = parseAmountValue(x.value);

      return {
        ...id,
        currency: x.currency?.code,
        title: x.title?.trim?.(),
        value: +value ?? 0,
      };
    });
};

export const validateForm = (items: PH.Form.Item[]) => {
  const filled = selectNonEmptyEntries(items);

  if (filled.length <= 0) return { honoraria: `Fields cannot be blank.` };

  return filled.every(validator.entry)
    ? {}
    : { honoraria: `Missing required field.` };
};

export const validator = {
  amount: (value: number | string) => {
    const parsed = parseAmountValue(value);

    return parsed >= 0
        && Number.isFinite(+parsed);
  },
  currency: (value: Pick<Currencies.Item, 'code'>) => {
    return !!value?.code;
  },
  entries: (items: PH.Form.Item[]) => {
    const errors = validateForm(items);

    return Object.keys(errors).length <= 0;
  },
  entry: (item: PH.Form.Item) => {
    return validator.amount(item.value)
        && validator.currency(item.currency)
        && validator.title(item.title);
  },
  title: (value: string) => {
    return value.trim().length > 0;
  },
};