import type { AxiosResponse } from 'axios';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { Chat } from '@/brand-insights/types';
import * as $api from '../queries';

type Props = {
  chatInstanceId: number;
  queryIdentifier: string;
};

type Data = {
  feedback: Chat.QueryFeedback;
};
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;
type QueryKey = readonly [string, Props];

function getQueryKey(props: Props): QueryKey {
  return [`query:get:feedback`, props];
}

export const useQueryFeedbackQuery = (props: Props, options?: Options) => {
  const qk = getQueryKey(props);

  return useQuery({ queryKey: qk, queryFn: ({ queryKey }) => {
    return $api.getQueryFeedback({
      chatInstanceId: queryKey[1].chatInstanceId,
      queryIdentifier: queryKey[1].queryIdentifier,
    });
  }, ...options });
};