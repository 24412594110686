import type { ConjointAnalysisQuestion } from '@/types';
import type { Validation } from '../interfaces';

type ValidAttributes = {
  maxAttributes: number;
  maxLevels: number;
  minAttributes: number;
  minLevels: number;
};

export function assertValidAttributes(attributes: ConjointAnalysisQuestion.Attribute[], opts: ValidAttributes): Validation.AssertionResult {
  const hasNumberOfAttributes
    = assertValidNumberOfAttributes(attributes, opts.minAttributes, opts.maxAttributes);

  if (hasNumberOfAttributes.success === false) {
    return {
      success: false,
      message: hasNumberOfAttributes.message,
    };
  }

  const hasUniqueAttributes
    = assertNoDuplicateAttributes(attributes);

  if (hasUniqueAttributes.success === false) {
    return {
      success: false,
      message: hasUniqueAttributes.message,
    };
  }

  const hasAttributeValues
    = assertAttributeValues(attributes);

  if (hasAttributeValues.success === false) {
    return {
      success: false,
      message: hasAttributeValues.message,
    };
  }

  const hasUniqueLevels
    = assertNoDuplicateLevels(attributes);

  if (hasUniqueLevels.success === false) {
    return {
      success: false,
      message: hasUniqueLevels.message,
    };
  }

  const hasLevelValues
    = assertLevelValues(attributes);

  if (hasLevelValues.success === false) {
    return {
      success: false,
      message: hasLevelValues.message,
    };
  }

  const hasNumberOfLevels
    = assertValidNumberOfLevels(attributes, opts.minLevels, opts.maxLevels);

  if (hasNumberOfLevels.success === false) {
    return {
      success: false,
      message: hasNumberOfLevels.message,
    };
  }

  return { success: true };
}

function assertAttributeValues(attributes: ConjointAnalysisQuestion.Attribute[]): Validation.AssertionResult {
  const hasEmptyValues = attributes.some(s => !s.value);

  if (hasEmptyValues) {
    return {
      success: false,
      message: 'Attribute value is empty.',
    };
  }

  return { success: true };
}

function assertLevelValues(attributes: ConjointAnalysisQuestion.Attribute[]): Validation.AssertionResult {
  const hasEmptyValues = attributes
    .some(a =>
      a.levels.some(l => !l.value)
    );

  if (hasEmptyValues) {
    return {
      success: false,
      message: 'Level value is empty.',
    };
  }

  return { success: true };
}

function assertNoDuplicateAttributes(attributes: ConjointAnalysisQuestion.Attribute[]): Validation.AssertionResult {
  const hasDuplicates
    = attributes
    .some((s, i) =>
      attributes.some((ss, ii) => ii !== i && s.value === ss.value)
    );

  if (hasDuplicates) {
    return {
      success: false,
      message: 'Duplicate attributes present.',
    };
  }

  return { success: true };
}

function assertNoDuplicateLevels(attributes: ConjointAnalysisQuestion.Attribute[]): Validation.AssertionResult {
  const hasDuplicates
    = attributes
    .some(a =>
      a.levels.some((l, i) =>
        a.levels.some((ll, ii) => ii !== i && l.value === ll.value)
      )
    );

  if (hasDuplicates) {
    return {
      success: false,
      message: 'Duplicate levels present.',
    };
  }

  return { success: true };
}

function assertValidNumberOfAttributes(attributes: ConjointAnalysisQuestion.Attribute[], min: number, max: number): Validation.AssertionResult {
  const attributesLength = (attributes ?? []).length;

  const isInvalid = attributesLength < min || attributesLength > max;

  if (isInvalid) {
    return {
      success: false,
      message: 'Invalid number of attributes.',
    };
  }

  return { success: true };
}

function assertValidNumberOfLevels(attributes: ConjointAnalysisQuestion.Attribute[], min: number, max: number): Validation.AssertionResult {
  const attributesLength = (attributes ?? []).length;

  const isInvalid = attributesLength < min || attributesLength > max;

  if (isInvalid) {
    return {
      success: false,
      message: 'Invalid number of levels.',
    };
  }

  return { success: true };
}
