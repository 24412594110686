import type { IConference } from '@containers/Conference';
import { useConferenceInstance } from '@containers/Conference';
import { Duration } from '@screens/ConferenceSession';
import styles from './style/Session.Status.css';

export function SessionStatus() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  if (!instance.conference.started) return null;

  return (
    <Duration
      className={styles.duration}
      startedOn={instance.conference.started} />
  );
}