import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useAsyncStateLazy } from '@utils';
import { useSendMessage } from '@utils/api';
import Toast from '@/components/Toast';
import type * as PlatformMessage from '../interfaces';

type Params = Partial<{
  paid: boolean;
  user: PlatformMessage.RecipientUser;
}>;

export const usePlatformMessageField = (params: Params = {}) => {
  const [value, setValue] = useState('');
  const sendMessage = useSendMessage();

  useEffect(() => {

    setValue('');

  }, [params.user.id]);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value), []);

  const firstName = params.user.profile?.firstName;

  const [state, submitForm] = useAsyncStateLazy(() => {

    return sendMessage({
      body: value.trim(),
      paid: params.paid,
      userId: params.user.id,
    })
    .then(res => {

      Toast.alert({
        title: 'Message Sent',
        body: `${firstName} has received your message. You will be alerted as soon as ${firstName} responds.`,
      });

      return res;
    });

  }, [
    firstName,
    params.paid,
    params.user.id,
    sendMessage,
    value,
  ]);

  const ctx = {
    onChange: handleChange,
    onSubmit: submitForm,
    submitting: state.loading,
    value,
  };

  return ctx;

};