import type { Application } from '@api/interfaces';
import { safeJsonDate } from '@utils';
import type { Workspace } from '@/types';

export const normalizeWorkspace = (data: Workspace<string>): Workspace => {
  if (!data) return null;

  return Object.assign(data, {
    createdOn: safeJsonDate(data.createdOn),
  });
};

export const normalizeWorkspaces = (data: Application.Workspaces): Store.Workspaces => {
  if (!data || !data.personal) return null;

  return {
    group: data.group.id,
    values: {
      [data.group.id]: normalizeWorkspace(data.group),
      [data.personal.id]: normalizeWorkspace(data.personal),
    },
    personal: data.personal.id,
    // shared: data.shared.map(normalizeWorkspace),
  };
};

export * from './workspaces.expert';
export * from './workspaces.global-search';