import type { CellProps } from 'react-table';
import type { CellItem } from '$admin/Search/Leads/interfaces';
import styles from './style/Search.Leads.css';

type Props = CellProps<CellItem>;

export const Cell = (props: Props) => {
  const { tags } = props.row.original;

  if (!tags?.length) return null;

  const [visible, ...rest] = tags;

  return (
    <div className={styles.tags}>
      <div className={styles.tag}>{visible.name}</div>
      {rest.length > 0 && <div className={styles.tag}>{`+${rest.length}`}</div>}
    </div>
  );
};

Cell.displayName = 'Column.Tags.Cell';