import { memo } from 'react';
import { Helmet } from 'react-helmet';
import type { FAQPage } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { path } from '@consts';
import { FAQ } from './FAQ';
import { Landing } from './Landing';
import { ValueProposition } from './ValueProposition';
import { faqs } from './data';
import styles from './style/Compliance.css';

const H = memo(() => {
  const description = ``;

  const faqMarkup = helmetJsonLdProp<FAQPage>({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(m => ({
      '@type': 'Question',
      name: m.label,
      acceptedAnswer: {
        '@type': 'Answer',
        text: m.value,
      },
    })),
  });

  return (
    <Helmet
      script={[faqMarkup]}
      title="Compliance">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Compliance}`} />
      {/* <meta
          name='description'
          content={description} />
        <meta
          name='og:description'
          content={description} /> */}
    </Helmet>
  );
});

export const Compliance = () => {
  return (
    <div className={styles.root}>
      <H />
      <Landing />
      <ValueProposition />
      <FAQ />
    </div>
  );
};