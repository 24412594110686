import * as api from '@api';
import { useAsyncStateLazy, useMounted } from '@utils';
import type { ProjectGoal } from '@/types';

const useFetchProjectGoal = () => {
  const isMounted = useMounted();

  const [response, fetch] = useAsyncStateLazy<{ goal: ProjectGoal }>((projectId: number) => {

    if (!projectId) return Promise.resolve(null);

    return api.projects.goals.fetch({ projectId })
    .then(res => {

      if (isMounted()) return res;

    });

  }, [isMounted]);

  return [response, fetch] as const;
};

export { useFetchProjectGoal };
export default useFetchProjectGoal;