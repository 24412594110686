import { useCallback, useMemo, useState } from 'react';
import { useMessageTestingData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';

export const useCanSaveMTMaxDiff = (data: SurveyTemplate.MessageTesting.Data['maxDiff']) => {

  return useMemo(() => {
    return !!data.dimension && !!data.attributesPerSet && !!data.sets;

  }, [data]);
};

export const useMTTemplateMaxDiff = () => {
  const [templateData] = useMessageTestingData();
  const [value, setValue] = useState(templateData.maxDiff);

  const [_, dispatch] = useSurveyBuilderState();

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-data-updated',
      value: {
        ...templateData,
        maxDiff: value,
      },
    });
  }, [
    value,
    dispatch,
    templateData,
  ]);

  const handleChange = useCallback(<T extends keyof SurveyTemplate.MessageTesting.MaxDiff = keyof SurveyTemplate.MessageTesting.MaxDiff, V = SurveyTemplate.MessageTesting.MaxDiff[T]>(key: T) => (value: V) => {
    setValue(s => ({
      ...s,
      [key]: value,
    }));
  }, []);

  return {
    onChange: handleChange,
    onSubmit,
    value,
  };
};
