import { useCallback, useContext, useState, useRef } from 'react';
import type { HeroSearchBarRef } from '@presentation/HeroSearchBar';
import { WorkspaceGlobalSearchBarQueryContext, WorkspaceGlobalSearchBarPopupContext } from './Context';

export default function Query({ children }: ChildrenProps) {
  const [query, setQuery] = useState<string>('');
  const barRefs = useRef<HeroSearchBarRef>();

  const { isOpen, setOpen } = useContext(WorkspaceGlobalSearchBarPopupContext);

  const clearQuery = useCallback(() => {
    if (isOpen) {
      setOpen(false);
    }
    setQuery('');
    barRefs.current.input.blur();
  }, [isOpen, setOpen]);

  const value = {
    query,
    setQuery,
    clearQuery,
    barRefs,
  };

  return (
    <WorkspaceGlobalSearchBarQueryContext.Provider value={value}>
      {children}
    </WorkspaceGlobalSearchBarQueryContext.Provider>
  );
}

export { Query };