import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { CallTranscriptDetails } from '$admin/api/interfaces/calls';
import * as api from '$admin/api/calls';

type Data = CallTranscriptDetails.Response;
type QueryKey = readonly [string, ICallId];

export function useCallTranscriptDetails(
  data: ICallId,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery({
    queryKey: [AdminTranscriptDetailsKey, data] as const, queryFn: ({ queryKey }) => {
      const callId = queryKey[1].callId;

      return api.getTranscriptDetails({
        callId,
      });
    },
    ...options,
  });
}

export const AdminTranscriptDetailsKey = 'admin.transcript-details';
