import * as types from '@store/action-types';
import type {
  ConnectedAccountsChangedAction,
  ConnectedAccountsChangedState,
} from '@store/interfaces';

export const connectedAccountsChanged = (data: ConnectedAccountsChangedState): ConnectedAccountsChangedAction => {
  return {
    connected: data.connected,
    type: types.CONNECTED_ACOUNTS_CHANGED,
  };
};