import { memo, useCallback } from 'react';
import { cx } from '@utils';
import type { SurveyMetrics } from '@/types';
import { ConditionsPopper } from './ConditionsPopover';
import styles from './style/Table.css';

type Props = {
  items: SurveyMetrics.Termination[];
};

export const SurveyTerminationsTable = memo(({ items }: Props) => {

  const renderRow = useCallback((item: SurveyMetrics.Termination, i: number) => {
    const reason = item.reason === 'quota'
      ? `Quota: ${item.name}`
      : 'Logic';

    return (
      <tr key={i}>
        <td>{reason}</td>
        <td>
          <ConditionsPopper
            items={item.match.conditions}
            rule={item.match.rule} />
        </td>
        <td>{item.total}</td>
      </tr>
    );

  }, []);

  if (!items.length) {
    return (
      <div className={styles.empty}>No responses have been conditionally terminated.</div>
    );
  }

  return (
    <table className={cx(styles.table, styles.terminations)}>
      <thead>
        <tr>
          <th>Reason</th>
          <th>Condition</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        {items.map(renderRow)}
      </tbody>
    </table>
  );
});

export default SurveyTerminationsTable;