import type { Dispatch } from 'react';
import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { SurveyAdvancedQuotaBuilder, SurveyBasicQuotaBuilder, QuotaValidations } from './interfaces';

export type SurveyAdvancedQuotaStateContextValue =
  [SurveyAdvancedQuotaBuilder.State, Dispatch<SurveyAdvancedQuotaBuilder.Action>];

export type SurveyBasicQuotaStateContextValue =
  [SurveyBasicQuotaBuilder.State, Dispatch<SurveyBasicQuotaBuilder.Action>];

export const SurveyAdvancedQuotaStateContext = createNamedContext<SurveyAdvancedQuotaStateContextValue>(null, 'SurveyAdvancedQuotaStateContext');
export const SurveyBasicQuotaStateContext = createNamedContext<SurveyBasicQuotaStateContextValue>(null, 'SurveyBasicQuotaStateContext');

export const useSurveyAdvancedQuotaState = () => useContext(SurveyAdvancedQuotaStateContext);
export const useSurveyBasicQuotaState = () => useContext(SurveyBasicQuotaStateContext);

export const QuotaValidationsContext = createNamedContext<QuotaValidations>(null, 'QuotaValidationsContext');
export const useQuotaValidations = () => useContext(QuotaValidationsContext);

export const RunQuotaValidationsContext = createNamedContext<() => boolean>(null, 'RunQuotaValidationsContext');
export const useRunQuotaValidations = () => useContext(RunQuotaValidationsContext);