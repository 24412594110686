import type { SurveyLogic } from '@/types/survey';
import { SurveyConditionType } from '@/enums';
import type { ConditionsBuilder } from './interfaces';
import { getDefaultQuestionConditionData } from './utils';

type State = ConditionsBuilder.State;

function updateQuestionCondition(state: State, identifier: string, updater: QuestionConditionUpdater): State {
  return state.reduce<State>((acc, x) => {

    return x.identifier === identifier
      ? acc.concat(updater(x as SurveyLogic.QuestionCondition))
      : acc.concat(x);

  }, []);
}

type QuestionConditionUpdater = (value: SurveyLogic.QuestionCondition) => SurveyLogic.QuestionCondition;

export function questionUpdated(state: State, { payload }: ConditionsBuilder.ConditionQuestionUpdated.Action): State {

  const updater = (value: SurveyLogic.QuestionCondition): SurveyLogic.QuestionCondition => {
    return {
      ...value,
      conditionType: SurveyConditionType.Question,
      data: {
        question: payload.question,
        type: null,
        value: null,
      },
    };
  };

  return updateQuestionCondition(state, payload.identifier, updater);
}

export function valueUpdated(state: State, { payload }: ConditionsBuilder.QuestionConditionValueUpdated.Action): State {

  const updater = (value: SurveyLogic.QuestionCondition): SurveyLogic.QuestionCondition => {
    return {
      ...value,
      conditionType: SurveyConditionType.Question,
      data: {
        question: value.data.question,
        type: value.data.type,
        value: payload.value,
      } as SurveyLogic.QuestionConditionData,
    };
  };

  return updateQuestionCondition(state, payload.identifier, updater);
}

export function typeUpdated(state: State, { payload }: ConditionsBuilder.QuestionConditionTypeUpdated.Action): State {

  const updater = (value: SurveyLogic.QuestionCondition): SurveyLogic.QuestionCondition => {
    return {
      ...value,
      data: getDefaultQuestionConditionData({
        question: value.data.question,
        type: payload.type,
      }),
    };
  };

  return updateQuestionCondition(state, payload.identifier, updater);
}