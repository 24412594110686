import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as consts from '@consts';
import { slugify } from '@utils';
import SurveyDetail from '../SurveyDetail';
import type { SurveyDetailsProps } from './interfaces';
import styles from './style.css';

const SurveyDetails = (props: SurveyDetailsProps) => {
  const completedOn = format(new Date(props.completedOn), 'MMM do yyyy');
  const renderProjectLink = () => (
    <Link
      className={styles.link}
      to={`${consts.path.Projects.Root}/${slugify(props.project)}`}>
      {props.project.name}
    </Link>
  );

  return (
    <div className={styles.details}>
      <SurveyDetail
        name={'Project'}>
        {renderProjectLink()}
      </SurveyDetail>
      <SurveyDetail
        name={'Completed On'}>
        {completedOn}
      </SurveyDetail>
    </div>
  );
};

export default SurveyDetails;