import { useMemo, useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import type { ExclusiveOptionsQuestion } from '@/types';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const ExclusiveOptionsFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ExclusiveOptions>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.ExclusiveOptions>();
  const validateRationale = useValidateRationale();

  const isSectionValid = useCallback((section: ExclusiveOptionsQuestion.OptionSection) => {
    const sectionOptions = question.options.filter(o => o.metadata.sectionId === section.identifier);
    const selectedOptions = answer.items.filter(i => sectionOptions.some(o => o.id === i.optionId));

    if (section.settings.requireSelection && !selectedOptions.length) return false;

    return true;
  }, [question, answer]);

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    const isNASelected = answer.items
      .some(s => {
        const option = question.options.find(f => f.id === s.optionId);

        return option.type === SurveyOptionType.NoneOfTheAbove || option.metadata.isExclusive;
      });

    if (isNASelected) return true;

    const sectionsValid = question.settings.optionSections
      .map(isSectionValid)
      .reduce((acc, v) => acc && v, true);

    return sectionsValid;
  }, [
    answer,
    isSectionValid,
    question.options,
    question.settings,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default ExclusiveOptionsFormContainer;