import { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { AppThemingContext } from '@containers/Branding/Context';
import { Lightbulb } from './Lightbulb';

type Props = {
  onClick?: () => void;
};

export const ChatWidget = memo(({ onClick }: Props) => {
  const ctx = useContext(AppThemingContext);

  const backgroundColor = ctx?.enabled ? ctx?.palette?.primary?.main : undefined;

  return (
    <AppWidthWrapper>
      <Root onClick={onClick} style={{ backgroundColor }}>
        <Lightbulb size={28} />
      </Root>
    </AppWidthWrapper>
  );
});

const WidgetSize = 75;
const positioning = 35;

const r = 92;
const g = 113;
const b = 175;

const full = `rgba(${r}, ${g}, ${b}, 1)`;
const dim = `rgba(${r}, ${g}, ${b}, 0.7)`;
const none = `rgba(${r}, ${g}, ${b}, 0)`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${dim};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px ${none};
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${none};
  }
`;

const Root = styled('div')(({ theme }) => {
  return {
    position: 'absolute',

    bottom: 65,
    right: 0,
    '@media (min-width: 1465px)': {
      bottom: positioning,
      right: -WidgetSize,
    },

    width: WidgetSize,
    height: WidgetSize,
    borderRadius: '50%',

    background: theme.palette.primary.light6,
    boxShadow: `0 0 0 0 ${theme.palette.primary.light6}`,
    cursor: 'pointer',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    animation: `${pulse} 6s infinite`,
  }
});

const AppWidthWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  maxWidth: theme.breakpoints.values.navbarWidth,
  margin: '0 auto',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.widget,
  boxSizing: 'border-box',
}));
