import { useCallback, useMemo, useState } from 'react';
import { ProjectStatus, ProjectType, UserProjectStatus } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { ProjectRate } from '$admin/components/Project.Rate';
import * as Autocomplete from '$admin/components/Autocomplete';
import { Select } from '$admin/components/Select';
import styles from './style/Project.BulkAddToAProject.css';

type Props = {
  defaultSelectedProject?: Descriptor;
  loading?: boolean;
  onChangeProject?: (project: Descriptor) => unknown;
  onSubmit: (params: OnSubmitParams) => unknown;
  project?: Descriptor;
  title?: string | number;
} & Pick<ModalProps, 'open' | 'onClose'>;

type StatusState = UserProjectStatus.Invited | UserProjectStatus.Recommended | UserProjectStatus.Staging;

type Rate = {
  currency: string;
  value: number;
};

type OnSubmitParams = {
  currency: string;
  project: Descriptor;
  rate: number;
};

export function BulkAddToAProject({ project, open, onChangeProject, onClose, onSubmit, ...props }: Props) {
  const [selectedStatus, setSelectedStatus] = useState<StatusState>(UserProjectStatus.Staging);
  const [selectedProject, setSelectedProject] = useState<Descriptor>(props.defaultSelectedProject);
  const [rate, setRate] = useState<number | string>(null);
  const [currency, setCurrency] = useState<string>(null);

  const handleSubmit = useCallback(() => {
    onSubmit({
      currency,
      project: selectedProject,
      rate: +rate,
    });
  }, [
    onSubmit,
    selectedProject,
    rate,
    currency,
  ]);

  const handleChangeProject = useCallback((item: Descriptor) => {
    setSelectedProject(item);
    onChangeProject?.(item);
  }, [
    onChangeProject,
    setSelectedProject,
  ]);

  const handleChangeRate = useCallback((rate: Rate) => {
    setRate(rate?.value ?? null);
    setCurrency(rate?.currency ?? null);
  }, [
    setRate,
    setCurrency,
  ]);

  const canSubmit = useMemo(() => {
    return !!selectedProject?.id
      && (rate != null && rate != '' && (rate as number) >= 0)
      && !!currency;
  }, [
    currency,
    rate,
    selectedProject?.id,
  ]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <div className={styles.root}>
        <Header>{props.title}</Header>
        <div className={styles.row}>
          <div className={styles.label}>Target Project</div>
          {selectedProject
            ? (
              <div className={styles.project}>
                {`${selectedProject.name} (${selectedProject.id})`}
                <div
                  onClick={() => handleChangeProject(null)}
                  className={styles.clear}>
                  ✕
                </div>
              </div>
            )
            : (
              <Autocomplete.Projects
                excludeIds={project ? [project.id] : []}
                statusId={[ProjectStatus.Active, ProjectStatus.Draft]}
                onSelect={item => {
                  handleChangeProject(item);
                  setRate((item.projectType === ProjectType.Survey ? item.surveyPayout : item.callPayout) ?? '');
                }} />
            )}
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Status</div>
          <Select
            className={styles.field}
            defaultValue={UserProjectStatus.Staging}
            disabled={true}
            id="status"
            name="status"
            onChange={e => setSelectedStatus(+e.target.value as StatusState)}
            options={OptionsMap}
            value={selectedStatus} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Rate</div>
          <ProjectRate
            onChange={handleChangeRate}
            projectId={selectedProject?.id} />
        </div>
        <div className={styles.footer}>
          <ButtonSet>
            <ButtonActivityIndicator
              className={styles.btn}
              disabled={!canSubmit}
              implicitDisable={false}
              loading={props.loading}
              onClick={handleSubmit}
              variant="brick">
              Add
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
}

const OptionsMap = {
  [UserProjectStatus.Staging]: 'Staging',
  [UserProjectStatus.Recommended]: 'Recommended',
  [UserProjectStatus.Invited]: 'Invited',
};