import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $projects from '@api/projects';
import { DownloadExternalSurveyLinksContext, ExternalSurveyConfigurationMutationsContext } from './context';

type Props =
  ChildrenProps &
  IProjectId;

export const OffPlatformSurveyMutationContainer = ({ projectId, ...props }: Props) => {

  const deleteUnassignedLinksMutation = useMutation({ mutationKey: ['delete-unassigned-external-links'], mutationFn: () => {
    return $projects.external.deleteUnassignedExternalProjectLinks({ projectId });
  } });

  const handleDownload = useCallback(() => $projects.external.downloadExternalProjectLinks({ projectId }), [projectId]);

  const importLinksMutation = useMutation({ mutationKey: ['admin.import-external-project-links'], mutationFn: (params: { file: File; fixed?: boolean }) => {
    return $projects.external.importExternalProjectLinks(projectId, params.file, params.fixed);
  } });

  const importSingleLinkMutation = useMutation({ mutationKey: ['admin.import-single-external-project-link'], mutationFn: (link: string) => {
    return $projects.external.importSingleExternalProjectLink({
      link,
      projectId,
    });
  } });

  const deleteProjectConfigMutation = useMutation({ mutationFn: () => {
    return $projects.external.deleteExternalProjectConfiguration({ projectId });
  } });

  const updateProjectConfigMutation = useMutation({ mutationKey: [
    'patch:projects/external-configuration',
    projectId,
  ], mutationFn: (params: { fixedLinks: boolean }) => {
    return $projects.external.updateExternalProjectConfiguration({
      projectId,
      ...params,
    });
  } });

  const mutations = {
    deleteProjectConfigMutation,
    deleteUnassignedLinksMutation,
    importSingleLinkMutation,
    importLinksMutation,
    updateProjectConfigMutation,
  };

  return (
    <DownloadExternalSurveyLinksContext.Provider value={handleDownload}>
      <ExternalSurveyConfigurationMutationsContext.Provider value={mutations}>
        {props.children}
      </ExternalSurveyConfigurationMutationsContext.Provider>
    </DownloadExternalSurveyLinksContext.Provider>
  );
};