import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppReadyState } from '@containers/AppReadyState';
import { useHasClientRole, useUpdateDisplayVerificationBanner } from '@containers/User';
import type { BannerHookReturnType } from './interfaces';

const mapState = (state: Store.State) => ({
  displayBanner: state.platform.alerts.displayVerificationBanner,
  emailVerified: state.user.meta.isEmailVerified,
});

const useVerifiedBanner = (): BannerHookReturnType => {
  const { initialized } = useAppReadyState();
  const hasClientRole = useHasClientRole();
  const { displayBanner, emailVerified } = useSelector(mapState);
  const updateDisplayBanner = useUpdateDisplayVerificationBanner();

  const show = useMemo(() => {
    return initialized &&
      emailVerified &&
      displayBanner &&
      !hasClientRole;
  }, [
    displayBanner,
    emailVerified,
    hasClientRole,
    initialized,
  ]);

  const dismiss = useCallback(() => {
    updateDisplayBanner();
  }, [updateDisplayBanner]);

  return [show, dismiss];
};

export { useVerifiedBanner };
export default useVerifiedBanner;