import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { cx } from '@utils';
import type { MatrixSliderQuestion, SurveyRichText, SurveySettings } from '@/types';
import { Input } from '@/components/Input';
import { CheckboxLabel } from '@/components/Checkbox';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import { SurveyRichTextEditorContainer, useSurveyRichTextEditor, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace.Button';
import styles from './style/MatrixSlider.Settings.Slider.css';
import { TotalsPopper } from './Settings.Total.Popper';

type Props = {
  className?: string;
  onTotalChange: (value: SurveySettings.TotalSetting) => void;
  onDisplayPctChange: (value: boolean) => void;
  onHideSlideChange: (value: boolean) => void;
  onIncrementChange: (value: number) => void;
  onLabelChange: (value: SurveyRichText.RichTextValue) => void;
  onMaxValueChange: (value: number) => void;
  onMinValueChange: (value: number) => void;
  settings: MatrixSliderQuestion.SliderSettings;
  identifier: string;
};

export const SliderSettings = ({
  className,
  onTotalChange,
  onDisplayPctChange,
  onHideSlideChange,
  onIncrementChange, onMaxValueChange,
  onMinValueChange, onLabelChange,
  settings,
  identifier,
}: Props) => {

  const handleIncrementChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onIncrementChange(Math.abs(+e.target.value));
  }, [onIncrementChange]);

  const handleMaxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onMaxValueChange(e.target.value !== '' ? +e.target.value : null);
  }, [onMaxValueChange]);

  const handleMinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onMinValueChange(e.target.value !== '' ? +e.target.value : null);
  }, [onMinValueChange]);

  const handleLabelChange = useCallback((value: SurveyRichText.RichTextValue) => {
    onLabelChange(value);
  }, [onLabelChange]);

  const handleHideSliderChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onHideSlideChange(!settings.hideSlider);
  }, [onHideSlideChange, settings.hideSlider]);

  const handleDisplayPctChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onDisplayPctChange(!settings.displayPctOfTotal);
  }, [onDisplayPctChange, settings.displayPctOfTotal]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <div className={styles.label}>Label</div>
        <div className={styles.labelInput}>
          <EditorContainer
            onChange={handleLabelChange}
            value={settings.label}
            identifier={`${identifier}:settings:slider:label`} />
        </div>
      </div>

      <CheckboxLabel
        className={styles.checkbox}
        label="Display pct of total"
        onChange={handleDisplayPctChange}
        checked={settings.displayPctOfTotal} />

      <div>
        <CheckboxLabel
          className={styles.checkbox}
          label="Hide slider"
          onChange={handleHideSliderChange}
          checked={settings.hideSlider} />
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Low Value</div>
        <div className={styles.input}>
          <Input
            type="number"
            name="minValue"
            placeholder="-"
            onChange={handleMinChange}
            value={settings.minValue !== null ? settings.minValue : ''} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.label}>High Value</div>
        <div className={styles.input}>
          <Input
            type="number"
            name="maxValue"
            placeholder="-"
            onChange={handleMaxChange}
            value={settings.maxValue !== null ? settings.maxValue : ''} />
        </div>
      </div>

      {!settings.hideSlider &&
        <div className={styles.row}>
          <div className={styles.label}>Unit Increment</div>
          <div className={styles.input}>
            <Input
              type="number"
              name="increment"
              placeholder="-"
              onChange={handleIncrementChange}
              value={settings.increment || ''} />
          </div>
        </div>
      }

      <div className={styles.row}>
        <div className={styles.label}>Required Sum</div>
        <TotalsPopper
          onChange={onTotalChange}
          value={settings.total} />
      </div>

    </div>
  );
};

type EditorProps = {
  onChange: (value: SurveyRichText.RichTextValue) => void;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

const EditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const canFar = useCanCreateFindAndReplace();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
    placeholder: 'Enter a label (optional)',
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    onChange,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
      {canFar ? <FindAndReplaceButton className={styles.editorBtn} /> : null}
    </SurveyRichTextEditorContainer>
  );
};

export default SliderSettings;