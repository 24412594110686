import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { useCompatSearchParams } from '@containers/Search';
import { HeroSearchBar } from '@presentation/HeroSearchBar';
import { qs } from '@utils';
import { actions } from '@/scenes/search/SearchProvider';

const SearchBarOld = () => {
  const [params, dispatch] = useCompatSearchParams();
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const hasSearchTerm = () => {
    return searchText && searchText.length;
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSearchText(e.target.value);
  };

  const updateSearchKeyword = data => dispatch(actions.updateSearchKeyword(data));

  const submitSearch: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (!hasSearchTerm()) {
      history.push(consts.pathname.SEARCH);
    } else {
      const params = { keyword: [{ id: searchText }] };
      history.push(`${consts.pathname.SEARCH}?${qs.stringify({ q: qs.encode(params) })}`);
    }

    if (hasSearchTerm()) {
      updateSearchKeyword({ keyword: searchText });
    }

    setSearchText('');
  };

  return (
    <HeroSearchBar
      query={searchText}
      onChange={onChange}
      onSubmit={submitSearch} />
  );
};

export default withRouter(SearchBarOld);