import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api/transcripts';
import type { GetTranscriptTagsOverview } from '@api/interfaces/transcripts';

type Data = GetTranscriptTagsOverview.Response;
type Params = ITranscriptId;
type QueryKey = readonly [string, Params];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export function useTranscriptTagsOverview(
  data: Params,
  options?: Options
) {

  return useQuery({
    queryKey: ['transcript-tag-overview', data] as const,
    queryFn: ({ queryKey }) => {
      const [_, { transcriptId }] = queryKey;

      return $api.getTranscriptTagsOverview({ transcriptId });

    },
    ...options,
  });
}