import { useCallback, useMemo } from 'react';
import type { Node, Path } from 'slate';
import { Text, createEditor } from 'slate';
import type { RenderElementProps, RenderLeafProps } from 'slate-react';
import { Editable, Slate, withReact } from 'slate-react';
import { SeeMore } from '@presentation/SeeMore';
import { RichTextTag } from '@/components/RichTextMentions';
import styles from './style/Post.Body.css';

type Props = {
  maxLines: number;
  value: Node[];
};

export const Body = ({ maxLines, value }: Props) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  const decorate = useCallback(([node, path]: [Node, Path]) => {
    if (!Text.isText(node)) {
      return [];
    }

    /* eslint-disable-next-line */
    const regex = /(https?:\/\/|www\.)?[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi;
    /* eslint-disable-next-line */
    const result = node.text.matchAll(regex);

    if (result) {
      const indices = getIndices(result);
      if (!indices.length) return [];

      return indices.map(({ end, start, value }) => ({
        link: true,
        anchor: { path, offset: start },
        focus: { path, offset: end },
        href: value,
      }));
    }

    return [];

  }, []);

  return (
    <SeeMore
      lineHeight={20}
      maxLines={maxLines}>
      <Slate
        editor={editor}
        onChange={() => {}}
        value={value}>
        <Editable
          decorate={decorate}
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf} />
      </Slate>
    </SeeMore>
  );
};

Body.displayName = 'Post.Body';

const Element = (props: RenderElementProps)  => {
  switch (props.element.type) {
    case 'mention':
      return <RichTextTag {...props} />;
    default:
      return (
        <p
          {...props.attributes}
          className={styles.p}>
          {props.children}
        </p>
      );
  }
};

const Leaf = (props: RenderLeafProps) => {
  if (props.leaf.link) {
    return (
      <span
        {...props.attributes}
        style={{ color: 'var(--pri-02' }}>
        <a
          href={props.leaf.href as string}
          rel="noreferrer"
          target="_blank">
          {props.children}
        </a>
      </span>
    );
  }

  return (
    <span {...props.attributes}>
      {props.children}
    </span>
  );
};

type Indices = {
  end:   number;
  start: number;
  value: string;
};

const getIndices = (regex: IterableIterator<RegExpMatchArray>) => {
  const indices: Indices[] = [];

  for (const re of regex) {
    const match = re[0];

    const safe = match.replace(/^[^\w]+|[^\w]+$/, '');

    indices.push({
      start: re.index,
      end: re.index + safe.length,
      value: normalizeURL(safe),
    });
  }

  return indices;
};

const normalizeURL = (url: string) => {
  if (url.startsWith('https://')) return url;

  if (url.startsWith('http://')) return url.replace('http', 'https');

  return `https://${url}`;
};