import { useLocation } from 'react-router-dom';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import { useAppReadyState } from '@containers/AppReadyState/hooks';
import { Button } from '@/components/Button';
import { Footer } from '$website/components/core/Footer';
import styles from './style/ThankYou.css';

type Props = unknown;

export const ThankYou = (props: Props) => {
  const { authenticated } = useAppReadyState();
  const location = useLocation<{ subtitle: string }>();
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <CheckCircleRounded className={styles.icon} />
          </div>
          <div className={styles.title}>Thank You!</div>
          <div className={styles.subtitle}>{location?.state?.subtitle || subtitle}</div>
          <Button
            color="primary"
            to="/"
            className={styles.btn}>
            Return
          </Button>
        </div>
        <div className={styles.bg1} />
        <div className={styles.bg2} />
      </div>
      {!authenticated && <Footer />}
    </div>
  );
};

const subtitle = 'We will be in touch soon.';

ThankYou.displayName = 'ThankYou';