import { useCallback, useEffect, useRef, useState } from 'react';
import type { Workbook as Book } from '@/types';
import { cx, trunc } from '@utils';
import Worksheet from './Worksheet';
import styles from './style/Workbook.css';

type Props = {
  workbook: Book;
  rowLimit?: number;
};

export const Workbook = ({
  rowLimit = 300,
  workbook,
}: Props) => {

  const [activeSheetIndex, setActiveSheetIndex] = useState<number>(0);
  const sheetRef = useRef<HTMLDivElement>();

  useEffect(() => {
    sheetRef.current?.scrollTo({
      behavior: 'auto',
      top: 0,
    });
  }, [activeSheetIndex]);

  const renderLabels = useCallback(() => {
    return (
      <div className={styles.tabsScroll}>
        <div className={styles.tabs}>
          {workbook.sheets.slice().reverse().map((sheet, i) => {
            const index = workbook.sheets.length - i - 1;
            return (
              <div
                key={i}
                onClick={() => setActiveSheetIndex(index)}
                className={cx(styles.tab, {
                  [styles.active]: index === activeSheetIndex,
                })}>
                {trunc(sheet.name, 40)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [
    activeSheetIndex,
    workbook.sheets,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.sheet}>
        <Worksheet
          ref={sheetRef}
          rowLimit={rowLimit}
          sheet={workbook.sheets[activeSheetIndex]} />
      </div>
      <div className={styles.footer}>
        {renderLabels()}
      </div>
    </div>
  );
};

export default Workbook;