import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import { ActionTypeDropdown } from '@presentation/SurveyBuilder/Dropdown';
import { useQuotaValidations } from '@containers/SurveyBuilder.Quota';
import type { SurveyAdvancedQuotaBuilder, SurveyBasicQuotaBuilder } from '@containers/SurveyBuilder.Quota/interfaces';
import { useSurveyQuotasGoal } from '@containers/SurveyBuilder';
import { Input } from '@/components/Input';
import { LogicValidation } from '@/components/SurveyBuilder.Logic/Builder.Validation';
import { QuotaBuilderLimit } from './Builder.Limit';
import { QuotaBuilderTitle } from './Title';
import styles from './style/Builder.css';

type Props = {
  onActionUpdate: (value: SurveyActionType) => void;
  onUpdateLimit: (value: number) => void;
  onUpdateName: (value: string) => void;
  state: SurveyAdvancedQuotaBuilder.State | SurveyBasicQuotaBuilder.State;
} & ChildrenProps;

export const SurveyQuotaBuilder = ({
  children,
  onActionUpdate,
  onUpdateLimit,
  onUpdateName,
  state,
}: Props) => {

  const goal = useSurveyQuotasGoal();
  const validations = useQuotaValidations();

  const updateName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateName(e.target.value);
  }, [onUpdateName]);

  return (
    <div className={styles.wrap}>

      <div className={styles.marginBottom}>
        <div className={styles.row}>
          <span className={styles.title}>Name:</span>
          <div className={styles.name}>
            <Input
              placeholder="Quota Name"
              onChange={updateName}
              value={state.name} />
          </div>
        </div>
        <LogicValidation value={validations.name} />
      </div>

      <div className={styles.marginBottom}>
        <div className={styles.row}>
          <span className={styles.title}>Limit:</span>
          <div className={styles.limit}>
            <div className={styles.value}>
              <QuotaBuilderLimit
                onChange={onUpdateLimit}
                value={state.limit} />
            </div>
            <div className={styles.slash}>/</div>
            <div className={styles.value}>{goal}</div>
          </div>
        </div>
        <LogicValidation value={validations.limit} />
      </div>

      <QuotaBuilderTitle label="Action" />
      <div className={styles.action}>
        <ActionTypeDropdown
          items={[
            SurveyActionType.None,
            SurveyActionType.SkipToEnd,
            SurveyActionType.QuotaOverLimit,
          ]}
          onSelect={onActionUpdate}
          value={state.actionType} />
      </div>
      <LogicValidation value={validations.action} />

      {children}
    </div>
  );
};

export default SurveyQuotaBuilder;