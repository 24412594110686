import { Tooltip } from '@presentation/Tooltip';
import styles from './style.css';
import type { CreditRateProps } from './interfaces';

const CREDIT_UNIT = 0.25;
const CREDIT_UNITS_WHOLE = 4;

const builder = (left: number, take: number, credits: number[] = []): number[] => {
  if (left <= 0) return credits;
  const taken = Math.min(left, take);
  return builder(left - taken, take, credits.concat(taken));
};

const buildIcons = (rate: number) => {
  return builder(Math.ceil(rate / CREDIT_UNIT), CREDIT_UNITS_WHOLE);
};

const buildLabel = (rate: number) => {
  return `${rate.toLocaleString()} credit${rate !== 1 ? 's' : ''} per hour`;
};

const Credit = (props: CreditProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.rotated}>
        <div className={styles[`credit${props.value}`]}></div>
      </div>
    </div>
  );
};

const Credits = (props: CreditsProps) => {
  return (
    <>
      {props.items.map((v, i) => <Credit key={i} value={v} />)}
    </>
  );
};

export const CreditRateWithTooltip = (props: CreditRateProps) => {
  if (!props.rate) return null;

  return (
    <div className={styles.root}>
      <Tooltip
        placement="right"
        title={buildLabel(props.rate)}
        tooltipstyles={{
          backgroundColor: 'var(--azure)',
        }}>
        <div>
          <Credits items={buildIcons(props.rate)} />
        </div>
      </Tooltip>
    </div>
  );
};

export const CreditRateWithLabel = (props: CreditRateProps) => {
  if (!props.rate) return null;

  return (
    <div className={styles.root}>
      <div>
        <Credits items={buildIcons(props.rate)} /> <span className={styles.label}>{buildLabel(props.rate)}</span>
      </div>
    </div>
  );
};

type CreditProps = {
  value: number;
};

type CreditsProps = {
  items: Array<CreditProps['value']>;
};