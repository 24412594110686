import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './style/Carousel.css';

type Props = {
  size: number;
} & Pick<LinkProps, 'to'>;

export const SeeAll = (props: Props) => {

  return (
    <div
      className={styles.seeall}
      style={{ width: props.size }}>
      <Link to={props.to}>
        <div
          className={styles.anchor}
          style={{ width: props.size, height: props.size - 16 }}>
          {`See all >`}
        </div>
      </Link>
    </div>
  );
};

SeeAll.displayName = 'Carousel.SeeAll';