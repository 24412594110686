import { plans, services } from '$website/containers/Pricing/content';
import type * as Pricing from '$website/containers/Pricing/interfaces';

export function buildCompactServices(identifier: Pricing.Plan['id']) {
  const plan = plans[identifier];

  return {
    id: plan.id,
    features: buildFeatureGroup(plan.features),
    tiered: buildTieredGroup(plan.tiered),
    usage: buildUsageGroup(plan.usage),
  };
}

const features = [
  ...services.support,
  ...services.custom,
  ...services.tools,
];

type FeatureId = typeof features[number]['id'];

export function buildFeatureGroup(ids: FeatureId[]) {
  return ids.reduce<Pricing.ServiceDescriptor[]>((acc, feature) => {
    const item = features.find(x => x.id === feature);
    return item
      ? acc.concat(item)
      : acc;
  }, []);
}

export function buildTieredGroup(items: Pricing.TieredDescriptor[]) {
  return items.reduce<Pricing.ServiceDescriptor[]>((acc, tiered) => {
    const item = services.tiered.find(x => x.id === tiered.id);

    if (!item) return acc;

    return acc.concat({
      id: item.id,
      name: `${tiered.value} ${item.name}`,
    });
  }, []);
}

export function buildUsageGroup(items: Pricing.UsageDescriptor[]) {
  return items.reduce<Pricing.ServiceDescriptor[]>((acc, usage) => {
    const item = services.usage.find(x => x.id === usage.id);

    if (!item) return acc;

    const value = !Number.isInteger(usage.value)
        ? 0
        : usage.value;

    return acc.concat({
      id: item.id,
      name: `${value} ${item.name}`,
    });
  }, []);
}
