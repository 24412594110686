import type { RankingQuestion } from '@/types/survey';
import type { AltRankingForm } from './interfaces';

export const StartDroppableId = 'start-droparea';
export const DestinationDroppableId = 'destination-droparea';

export function getDraggableId(item: AltRankingForm.DraggableItem) {
  return `draggable-${item.id}`;
}
export function parseIdFromDraggableId(value: string) {
  return +value.split('-')[1];
}

export const reorderItems = ({ items, settings, target }: ReorderItems): RankingQuestion.RespondentAnswer.Option[] => {
  const filteredItems = items.filter(f => f.id !== target.id);
  const targetIndex = target.ordinal - 1;

  const pre = filteredItems.slice(0, targetIndex);
  const post = filteredItems.slice(targetIndex);

  const merged = [
    ...pre,
    target,
    ...post,
  ].map((m, i) => ({
    id: m.id,
    ordinal: i + 1,
  })).filter(f => f.ordinal <= settings.maxRank);

  return merged;
};

type ReorderItems = {
  target: RankingQuestion.RespondentAnswer.Option;
  items: RankingQuestion.RespondentAnswer.Option[];
  settings: {
    maxRank: number;
    minRank: number;
  };
};