import { cx } from '@utils';
import type { SurveyDetailProps } from './interfaces';
import styles from './style.css';

export const SurveyDetail = (props: SurveyDetailProps) => (
  <div className={cx(styles.detail, props.containerClassName)}>
    <div className={styles.detailHeader}>{props.name}:</div>
    <div className={styles.detailText}>{props.children}</div>
  </div>
);

export default SurveyDetail;