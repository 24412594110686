import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import type { SurveyQuestionType } from '@enums';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import Chart from './MaxDiff.Chart';
import Data from './MaxDiff.Data';

export default function MaxDiffRepeatSurveyResponses() {
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();

  return (
    <>
      <SurveyResponsesHeader
        question={question} />
      <Chart />
      <Data />
    </>
  );
}

export { MaxDiffRepeatSurveyResponses };