import type { SurveyTagging } from '@/types/survey';
import { SurveyLogicMatch } from '@/components/SurveyBuilder.Logic';
import { TaggingItemContextMenu } from './Preview.ContextMenu';
import { TaggingAction } from './Preview.Action';
import styles from './style/Preview.css';

type Props = {
  hideContextMenu: boolean;
  item: SurveyTagging.Item;
};

export const SurveyTaggingItemPreview = ({ hideContextMenu, item }: Props) => {

  return (
    <div className={styles.root}>
      {!hideContextMenu &&
        <TaggingItemContextMenu
          className={styles.menu}
          item={item} />}

      <div className={styles.row}>
        <div className={styles.label}>Action:</div>
        <TaggingAction action={item.action} />
      </div>

      <SurveyLogicMatch
        match={{
          conditions: item.conditions,
          rule: item.rule,
        }} />
    </div>
  );
};