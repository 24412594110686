import { Badge } from '$admin/components/Table.Column';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <Badge.UserProjectStatus
      scheduling={props.row.original.scheduling.unconfirmed}
      survey={props.row.original.survey}
      statusId={props.row.original.respondent.statusId} />
  );
};

Cell.displayName = 'Column.Status.Cell';