import { useReducer } from 'react';
import { ContentFeedContext } from './Context';
import type { ContentFeedState, ContentFeedActions } from './interfaces';

type Props = {
  children: React.ReactElement;
};

const initialState = {
  items: [],
  newsItems: [],
  loading: false,
  loadingMore: false,
  morePostsToFetch: true,
};

export const ContentFeedStateContainer = (props: Props) => {

  const [state, dispatch] = useReducer(contentFeedReducer, initialState);

  return (
    <ContentFeedContext.Provider value={[state, dispatch]}>
      {props.children}
    </ContentFeedContext.Provider>
  );
};

ContentFeedStateContainer.displayName = 'ContentFeed.StateContainer';

function contentFeedReducer(state: ContentFeedState, action: ContentFeedActions) {

  switch (action.type) {
    case 'feed-refresh-started':
      return {
        ...state,
        loading: true,
      };

    case 'feed-refreshed':
      return {
        ...state,
        items: action.items,
        morePostsToFetch: action.morePostsToFetch,
        loading: false,
      };

    case 'fetch-more-started':
      return {
        ...state,
        loadingMore: true,
      };

    case 'more-posts-fetched':
      return {
        ...state,
        loadingMore: false,
        morePostsToFetch: action.morePostsToFetch,
        items: [
          ...state.items,
          ...action.items,
        ],
      };

    case 'post-created':
      return {
        ...state,
        items: [
          {
            ...action.item,
            query: action.item.query || null,
            likeDescription: action.item.likeDescription || null,
          },
          ...state.items,
        ],
      };

    case 'post-deleted':
      return {
        ...state,
        items: state.items.filter(f => f.id !== action.postId),
      };
  }
}