import { useCallback } from 'react';
import type { NumberInputTableQuestion, SurveyQuestionOption } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import styles from './style/NumberInputTable.List.css';

type Props = {
  getCellAnswer: (rowId: number, optionId: number) => string;
  getColumnTotal: (optionId: number) => string;
  getDisplayRowText: (row: NumberInputTableQuestion.Row) => string;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableAnswerList = ({
  getCellAnswer,
  getColumnTotal,
  getDisplayRowText,
  question,
}: Props) => {

  const renderItem = useCallback((option: SurveyQuestionOption) => {

    return (
      <div
        key={option.ordinal}
        className={styles.item}>
        <div className={styles.header}>{parseSurveyRichText(option.value)}</div>
        <div>
          {question.matrixRows.map(row => {
            const cellAnswer = getCellAnswer(row.id, option.id);
            const rowText = getDisplayRowText(row);

            return (
              <div
                key={row.id}
                className={styles.row}>
                <div className={styles.name}>{rowText}</div>
                <div className={styles.cell}>
                  {cellAnswer}
                </div>
              </div>
            );
          })}
        </div>
        {question.settings.displayTotals &&
          <div className={styles.footer}>
            <div className={styles.name}>Total</div>
            <div className={styles.cell}>{getColumnTotal(option.id)}</div>
          </div>
        }
      </div>
    );
  }, [
    getCellAnswer,
    getColumnTotal,
    getDisplayRowText,
    question.matrixRows,
    question.settings.displayTotals,
  ]);

  return (
    <div className={styles.root}>
      {question.options.map(renderItem)}
    </div>
  );
};

export default NumberInputTableAnswerList;