import type { Reducer } from 'react';
import { useReducer } from 'react';
import { SortingContext } from './Context';
import type { SortingAction, SortingInitialState, SortingState } from './interfaces';

type Props<T extends unknown> = {
  children:     React.ReactNode;
  initialState: SortingInitialState<T>;
};

const SortingContainer = <T extends unknown>(props: Props<T>) => {
  const [state, dispatch] = useReducer<Reducer<SortingState<T>, SortingAction<T>>>(sorting, {
    ...defaultProps,
    ...props.initialState,
  } as SortingState<T>);

  return (
    <SortingContext.Provider value={[state, dispatch]}>
      {props.children}
    </SortingContext.Provider>
  );
};

function sorting<T extends unknown>(acc: SortingState<T>, x: SortingAction<T>): SortingState<T> {
  if (x.filter) {
    const order = x.filter !== acc.filter
        ? 'asc'
        : acc.order === 'asc' ? 'desc' : 'asc';

    return {
      ...acc,
      filter: x.filter,
      order,
    };
  }

  return { ...acc, ...x };
}

const defaultProps = {
  initialState: {
    filter: '',
    order: 'asc',
  },
};

export { SortingContainer };
export default SortingContainer;