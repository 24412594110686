import { useCallback, useMemo } from 'react';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@/containers/SurveyResponses/hooks';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { OpenedResponseOptionRow, CloseRow } from '@presentation/ProjectSurveyResponses';
import { MatrixRangeQuestion } from '@/types';
import type { SurveyQuestionType } from '@/enums';
import { formatPlurality } from '@/utils';
import ResponseOptionUsers from './ResponseOptionUsers';
import styles from './style/MatrixRange.Data.css';

export const MatrixRangeOptionData = () => {

  const { question, rationale, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MatrixRange>();

  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixRange>();

  const row = useMemo(() => {
    return question.matrixRows.find(f => f.base.id === query.rowId);
  }, [question.matrixRows, query.rowId]);

  const option = useMemo(() => {
    return question.options.find(f => f.base.id === query.optionId);
  }, [question.options, query.optionId]);

  const rowData = responses[query.rowId];
  const optionData = rowData.options[query.optionId];

  const renderHeader = useCallback(() => {
    const rowValue = parseSurveyRichText(row.value);
    const optionValue = parseSurveyRichText(option.value);
    const count = optionData.userIds.length;
    return (
      <div className={styles.rowHeader}>
        <div className={styles.left}>
          <div className={styles.label}>{rowValue} - {optionValue}</div>
          <div className={styles.subtitle}>({count} {formatPlurality('Respondent', count)})</div>
        </div>
        <CloseRow onClick={resetQueryState} />
      </div>
    );
  }, [resetQueryState, option.value, row.value, optionData.userIds]);

  return (
    <>
      {renderHeader()}
      <ResponseOptionUsers
        userIds={optionData.userIds}
        option={option}
        rationaleMap={rationale}
        question={question} />
    </>
  );
};