import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import Prompt from '@/components/Modal/Prompt';
import type { PromptProps } from './interfaces';
import styles from './style/Prompt.css';

type Props =
  PromptProps;

export const ArchiveProjectPrompt = ({
  onClose,
  onConfirm,
  project,
  visible,
}: Props) => {

  const header = `Are you sure you want to archive the ${project.name} project?`;
  const body = `If you archive this project, all pending calls and expert requests will be cancelled and the project will be locked for editing.`;

  const footer = (
    <ButtonSet>
      <Button.Destructive
        className={styles.btn}
        onClick={onClose}
        variant="brick"
        title="Cancel" />
      <Button.Primary
        className={styles.btn}
        onClick={onConfirm}
        variant="brick"
        title="Archive" />
    </ButtonSet>
  );

  return (
    <Prompt
      body={body}
      footer={footer}
      header={header}
      onClose={onClose}
      visible={visible} />
  );
};

export default ArchiveProjectPrompt;