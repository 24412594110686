import { cx } from '@utils';
import { QuestionText } from '@presentation/Survey';
import type {
  SurveyResponseQuestionTextProps,
} from './interfaces';
import styles from './style/QuestionText.css';

export const SurveyResponseQuestionText = ({ item, textClassName }: SurveyResponseQuestionTextProps) => {
  return (
    <QuestionText
      textClassName={cx(textClassName, styles.root)}
      item={item} />
  );
};

export default SurveyResponseQuestionText;