import { Website } from '@consts/path';
import { Button } from '@/components/Button';
import { Nav, Navbar as NavbarCore, useLoginModal } from '$website/components';
import * as Banner from './Banner';
import { BubbleNote } from './BubbleNote';
import { Landing } from './Landing';
import { SignUpButton } from './SignUpButton';
import { ValuePropositions } from './ValuePropositions';
import styles from './style/ResearchCommunity.css';

type Props = unknown;

export const ResearchCommunity = (props: Props) => {

  return (
    <div className={styles.root}>
      <Navbar />
      <Landing />
      <Banner.Trinity />
      <ValuePropositions />
      <BubbleNote />
      <Banner.Commitment />
      <Footer />
    </div>
  );
};

ResearchCommunity.displayName = 'ResearchCommunity';

const Navbar = (props: Props) => {
  return (
    <NavbarCore.LayoutRoot>
      <div className={styles.navbar}>
        <Nav.Logo />
        <div className={styles.nav}>
          <ButtonSet />
        </div>
      </div>
    </NavbarCore.LayoutRoot>
  );
};

const ButtonSet = (props: Props) => {
  const [l, setLoginModalOpen] = useLoginModal();

  return (
    <div className={styles.btns}>
      <Button
        className={styles.login}
        onClick={() => setLoginModalOpen(true)}
        variant="link">
        Log In
      </Button>
      <SignUpButton />
    </div>
  );
};

const Footer = (props: Props) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <Button
          className={styles.subscript}
          to={Website.PrivacyPolicy.Root2}
          variant="link">
          Privacy Policy
        </Button>
        <Button
          className={styles.subscript}
          to={Website.Terms.Root2}
          variant="link">
          Terms of Use
        </Button>
      </div>
      <div className={styles.subscript}>
        &copy; {new Date().getFullYear()} Trinity. All rights Reserved.
      </div>
    </footer>
  );
};