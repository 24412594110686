import { Star } from 'react-feather';
import { cx } from '@utils';
import type { StarRatingProps } from './interfaces';
import { MaxStars } from './interfaces';
import styles from './style.css';

export const StarRating = ({ rating, onClick, size = 24, rootClassName }: StarRatingProps) => {
  return (
    <div className={cx(styles.root, rootClassName)} style={{ height: size }}>
      {Array.from(new Array(MaxStars)).map((_, i) => (
        <Star
          key={i}
          className={cx(styles.star, styles.clickable, i < rating ? styles.filled : null)}
          strokeWidth={1}
          size={size}
          onClick={() => onClick(i + 1)} />))}
    </div>
  );
};