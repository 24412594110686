import type { Reducer } from 'react';
import { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { ApprovalStatus, ProjectSurveyResponseStatus, utils } from '@enums';
import { useFetchComplianceAggregateReview } from '@utils/api';
import { AggregateFilterDispatchContext, AggregateFilterParamsContext, AggregateReviewRefetchContext, AggregateReviewStateContext } from './Context';
import type { ComplianceReview } from './interfaces';

const mapState = (state: Store.State) => state.group;

type Props = {
  children: React.ReactNode;
};

const ComplianceAggregateReviewContainer = (props: Props) => {
  const [params, dispatch] = useReducer<Reducer<ComplianceReview.AggregateFilterParams, ComplianceReview.AggregateFilterAction>>(paramsReducer, {
    approvalStatusIds: [
      ApprovalStatus.NeedsApproval,
    ],
    statusIds: [
      ProjectSurveyResponseStatus.Pending,
    ],
    index: 0,
    limit: 10,
  });
  const [state, fetch] = useFetchComplianceAggregateReview();
  const group = useSelector(mapState);

  const fetchItems = useCallback(() => {

    fetch({
      approvalStatusIds: params.approvalStatusIds,
      groupId: group.id,
      pageIndex: params.index,
      pageSize: params.limit,
      statusIds: params.statusIds,
    });

  }, [
    fetch,
    group,
    params,
  ]);

  useEffect(() => {

    fetchItems();

  }, [
    fetchItems,
    params,
  ]);

  useEffect(() => {
    if (params.index > 0 && !state.loading &&
      state.value?.items?.length === 0) {
      dispatch({
        type: 'page',
        data: { index: params.index - 1 },
      });
    }
  }, [
    params.index,
    state.loading,
    state.value?.items?.length,
  ]);

  return (
    <AggregateFilterDispatchContext.Provider value={dispatch}>
      <AggregateFilterParamsContext.Provider value={params}>
        <AggregateReviewStateContext.Provider value={state}>
          <AggregateReviewRefetchContext.Provider value={fetchItems}>
            {props.children}
          </AggregateReviewRefetchContext.Provider>
        </AggregateReviewStateContext.Provider>
      </AggregateFilterParamsContext.Provider>
    </AggregateFilterDispatchContext.Provider>
  );
};

function paramsReducer(acc: ComplianceReview.AggregateFilterParams, action: ComplianceReview.AggregateFilterAction) {
  switch (action.type) {
    case 'filter':
      return {
        ...filterReducer(action.data.filter),
        index: 0,
        limit: 10,
      };

    case 'page':
      return {
        ...acc,
        index: action.data.index,
      };

    default:
      return acc;
  }
}

function filterReducer(data: ComplianceReview.AggregateFilter) {
  switch (data) {
    case 'all':
      return {
        approvalStatusIds: utils.ApprovalStatus.values(),
        statusIds: [],
      };

    case 'approved':
      return {
        approvalStatusIds: [
          ApprovalStatus.Approved,
        ],
        statusIds: [],
      };

    case 'needs-approval':
      return {
        approvalStatusIds: [
          ApprovalStatus.NeedsApproval,
        ],
        statusIds: [
          ProjectSurveyResponseStatus.Pending,
        ],
      };

    case 'rejected':
      return {
        approvalStatusIds: [
          ApprovalStatus.Rejected,
        ],
        statusIds: [],
      };
  }
}

const options: ComplianceReview.AggregateFilterLookup  = {
  ['all']: 'All',
  ['needs-approval']: 'Requires Review',
  ['approved']: 'Approved',
  ['rejected']: 'Rejected',
};

ComplianceAggregateReviewContainer.filter = {
  defaultOption: 'needs-approval',
  options,
};

export { ComplianceAggregateReviewContainer };
export default ComplianceAggregateReviewContainer;