import type { MultiTextboxQuestion } from '@/types/survey';
import { SurveyBuilderQuestionSettings } from '@/components/Survey.RichText/ReadOnly';
import { SurveySettings } from './Settings';
import styles from './style/MultiTextboxes.css';

type Props = {
  item: MultiTextboxQuestion.Question;
};

export const MultiTextboxesQuestion = ({ item }: Props) => {

  const showEntryLabels = item.settings.entryLabels.some(s => !!s);

  return (
    <SurveySettings>
      {item.settings.label &&
        <div className={styles.row}>
          <div className={styles.label}>Label:</div>
          <SurveyBuilderQuestionSettings
              value={item.settings.label}
              identifier={`${item.base.identifier}:settings:label`} />
        </div>
      }
      <div className={styles.row}>
        <>Minimum Textboxes: {item.settings.minimumEntries}, Maximum Textboxes: {item.settings.maximumEntries}</>
      </div>
      {showEntryLabels && (
        <div>
          <div className={styles.label}>Entry Labels:</div>
          {item.settings.entryLabels.map((label, index) => (
            <div className={styles.entryLabel} key={index}>
              <>{index + 1}) {label}</>
            </div>
          ))}
        </div>
      )}
      {item.settings.dontKnow.enabled && <>Show &quot;{item.settings.dontKnow.label}&quot;</>}
    </SurveySettings>
  );
};