import { useCallback } from 'react';
import { cx } from '@utils';
import styles from './style/Sidebar.css';

type Step = {
  key: number | string;
  title: string;
};

type Props = {
  className?: string;
  index: number;
  steps: Step[];
};

export const Sidebar = (props: Props) => {

  const Item = useCallback(({ item, index }: { item: Step; index: number }) => {
    const bulletClass = cx(styles.bullet, {
      [styles.outline]: index === props.index,
      [styles.fill]: index < props.index,
    });

    const textClass = cx(styles.step, {
      [styles.active]: index == props.index,
      [styles.done]: index < props.index,
    });

    return (
      <div className={styles.item}>
        <div className={bulletClass} />
        <div className={textClass}>{item.title}</div>
      </div>
    );
  }, [
    props.index,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      {props.steps.map((x, i) =>
        <Item
          key={x.key}
          index={i}
          item={x} />)}
    </div>
  );
};

export default Sidebar;