import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import type { Users } from '@api/interfaces';
import { path } from '@consts';
import { MedicalExpertiseContext, MedicalBackgroundEnabledStateContext, MedicalOnboardingCompletionContext, MedicalOnboardingScreensContext } from '@containers/MedicalOnboarding/Context';
import { Step } from '@containers/MedicalOnboarding/Steps';
import { Stepper } from '@/components/Stepper';
import * as MedicalBackground from './MedicalOnboarding.Background';

type Props = unknown;

export const Background = (props: Props) => {
  const history = useHistory();

  const ctx = {
    completion: useContext(MedicalOnboardingCompletionContext),
  };
  // const routing = {
  //   background: useContext(MedicalBackgroundEnabledStateContext),
  // };

  const { lookup, stepper } = useContext(MedicalOnboardingScreensContext);

  const expertise = useContext(MedicalExpertiseContext);

  const handleSuccessBackground = useCallback((data: Omit<Users.Onboarding.UpdateBackground.Request, 'userId'>) => {

    if (!stepper.remaining.length) {
      ctx.completion.mutation.mutateAsync()
      .then(() => history.push(stepper.route.next));
    } else {
      history.push(stepper.route.next);
    }

  }, [
    ctx.completion.mutation,
    history,
    stepper.remaining,
    stepper.route.next,
  ]);

  // if (!routing.background.enabled) return null;

  return (
    <>
      {lookup[Step.BackgroundIdentification] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Background.Identification}>
          <MedicalBackground.Identification
            expertise={{
              professionalRole: expertise.professionalRole,
            }}
            onSuccess={handleSuccessBackground}
            next={{ text: stepper.remaining.length ? `Next` : `Complete` }} />
        </Stepper.Route>}

      {lookup[Step.BackgroundAdditionalExpertise] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Background.AdditionalExpertise}>
          <MedicalBackground.AdditionalExpertise onSuccess={handleSuccessBackground} />
        </Stepper.Route>}

      {lookup[Step.BackgroundPractice] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Background.Practice}>
          <MedicalBackground.Practice onSuccess={handleSuccessBackground} />
        </Stepper.Route>}

      {lookup[Step.BackgroundLeadership] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Background.Leadership}>
          <MedicalBackground.Leadership onSuccess={handleSuccessBackground} />
        </Stepper.Route>}

      {lookup[Step.BackgroundContact] &&
        <Stepper.Route
          exact
          path={path.Onboarding.Background.Contact}>
          <MedicalBackground.Contact
            next={{ text: 'Complete ' }}
            onSuccess={handleSuccessBackground} />
        </Stepper.Route>}
    </>
  );
};

Background.displayName = 'MedicalOnboarding.Stepper.Routes.Background';