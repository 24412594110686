import { memo } from 'react';
import type { RespondentProps } from '@/types';
import { ResponsesViewAll } from '@presentation/ProjectSurveyResponses';
import { RespondentBaseballCard as BaseballCard } from '@/components/BaseballCard';
import styles from './style/ConjointAnalysis.Data.Level.User.css';

type Props = {
  count: number;
} & RespondentProps;

export const ConjointAnalysisResponseUser = memo((props: Props) => {
  return (
    <>
      <div className={styles.text}>
        <div className={styles.name}>
          <BaseballCard {...props}>
            {props.user.name}
          </BaseballCard>
        </div>
        <div className={styles.count}>({props.count})</div>
      </div>
      <ResponsesViewAll />
    </>
  );
});

export default ConjointAnalysisResponseUser;