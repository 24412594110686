import { useContext } from 'react';
import type { QueryParamConfig } from 'use-query-params';
import { NumberParam, decodeString, encodeString, useQueryParams, withDefault } from 'use-query-params';
import { GroupContactsQueryContext, GroupContactsQueryParamsContext } from '@containers/Group.Contacts/Context';
import { QueryContainer } from '@containers/Group.Contacts/Query.Container';
import { RemoveContactsContainer } from '@containers/Group.Contacts/RemoveContacts.Container';
import { SelectedStateContainer } from '@containers/Group.Contacts/SelectedState.Container';
import * as Panel from './Panel';
import styles from './style/Panel.css';

type Props = unknown;

const PanelView = (props: Props) => {
  const query = useContext(GroupContactsQueryContext);
  const [qp, setQuery] = useContext(GroupContactsQueryParamsContext);

  const pagination = {
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.header}>
          <div className={styles.title}>Contacts</div>
        </div>

        <div className={styles.main}>
          <Panel.Toolbar />

          <Panel.Table
            data={query.data?.items || []}
            query={qp}
            setQuery={setQuery}
            pagination={pagination} />

        </div>
      </div>
    </div>
  );
};

PanelView.displayName = 'Group.Contacts.Panel.View';

export const GroupContactsPanel = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    name: TextInputConfig,
    size: withDefault(NumberParam, 25),
  });

  return (
    <GroupContactsQueryParamsContext.Provider value={[qp, setQuery]}>
      <QueryContainer>
        <SelectedStateContainer>
          <RemoveContactsContainer>
            <PanelView />
          </RemoveContactsContainer>
        </SelectedStateContainer>
      </QueryContainer>
    </GroupContactsQueryParamsContext.Provider>
  );
};

GroupContactsPanel.displayName = 'Group.Contacts.Panel';

const TextInputConfig: QueryParamConfig<string> = {
  encode: (val: string) => {
    return typeof val === 'string' && !val.length
      ? encodeString(undefined)
      : encodeString(val);
  },
  decode: (val: string) => decodeString(val),
};