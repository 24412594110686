import { useMemo } from 'react';
import type { ConferenceType } from '@enums';
import { utils as enumUtils } from '@enums';
import { FieldSelectNumber } from './Field.Select';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
    value?: string;
  };
  isEditing: boolean;
  items: ConferenceType[];
  displayValue: string;
  onChange?: (value: ConferenceType) => void;
};

export function FieldConferenceType({ classes = {}, isEditing, items, displayValue, onChange }: Props) {
  const options = useMemo(() => {

    return items.reduce<{ [k: string]: string }>((acc, curr) => {
      acc[curr] = enumUtils.ConferenceType.getName(curr);
      return acc;
    }, {});
  }, [items]);

  return (
    <div className={styles.section}>
      <FieldSelectNumber
        classes={classes}
        editing={isEditing}
        label="Conference Type"
        name="conferenceType"
        displayValue={displayValue}
        onChange={onChange}
        options={options} />
    </div>
  );
}