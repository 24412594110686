import type { ClickAwayListenerProps } from '@mui/base';
import { ClickAwayListener as MUIClickAwayListener } from '@mui/base';

type Props = ClickAwayListenerProps & {
  disabled: boolean;
};

export const ClickAwayListener = ({ disabled, children, onClickAway, ...props }: Props) => {
  return (
    <MUIClickAwayListener {...props} onClickAway={disabled ? () => { } : onClickAway}>
      {children}
    </MUIClickAwayListener>
  );
};