import { lazy } from 'react';
import { RouteAuth } from '@enums';
import type * as Routing from '@routes/interfaces';
import { routes as websiteRoutes } from '$website';
import { enumerateRoutePaths } from './utils';

const Website = lazy(() => import(
  /* webpackChunkName: "website" */
  '$website/Website')
);

const paths = enumerateRoutePaths(websiteRoutes);

export const router: Routing.RouteConfig = {
  auth: RouteAuth.Unauthenticated,
  component: Website,
  path: paths,
};

export const root: Routing.RouteConfig = {
  auth: RouteAuth.Unauthenticated,
  component: Website,
  exact: true,
  path: '/',
};