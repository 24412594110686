import { useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import type { SurveyQuestionType } from '@enums';

export const useFilteredAttributes = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.ConjointAnalysis>();
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  const attributes = useMemo(() => {
    return query.attributeIds
      ? question.attributes
          .filter(f => query.attributeIds.includes(f.base.id))
      : question.attributes;
  }, [
    query.attributeIds,
    question.attributes,
  ]);

  return attributes;
};

export default useFilteredAttributes;