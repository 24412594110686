import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '$admin/api/transcripts';
import type { GetTranscriptMedia } from '$admin/api/interfaces/transcripts';

type Props = {
  transcriptId: number;
};

export const useTranscriptMedia = (props: Props, options: Options = {}) => {

  const query = useQuery({
    queryKey: ['admin.transcript-media', props] as const, queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return api.getTranscriptMedia(params);
    },
    ...options,
  });

  return query;
};

type Data = GetTranscriptMedia.Response;
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;