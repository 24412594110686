import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { Project } from '@/types';
import AutoComplete from '@/components/AutoComplete';

type Props = {
  ignoreList?: number[];
  onSelect: (item: AutocompleteItem) => unknown;
};

type SearchProjectProps = {
  ignoreList: number[];
  search?: string;
};

const selectSearchProjects = (state: Store.State) => ({ ignoreList, search }: SearchProjectProps) => {
  const filtered = state.projects.ids.reduce((acc, projectId) => {
    const project = state.projects[projectId];

    if (ignoreList.includes(projectId)) {
      return acc;
    }

    if (search) {
      const exp = new RegExp(`${search}`, 'i');
      if (!exp.test(project.name)) {
        return acc;
      }
    }

    return [...acc, {
      id: project.id,
      name: project.name,
    }];
  }, [] as AutocompleteItem[]);

  return [...filtered]
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .slice(0, 10);
};

export const ProjectAutocomplete = ({ ignoreList, onSelect }: Props) => {
  const searchProjects = useSelector(selectSearchProjects);
  const [text, setText] = useState<string>('');
  const [items, setItems] = useState<AutocompleteItem[]>(searchProjects({ ignoreList }));

  const handleSelect = useCallback((item: AutocompleteItem) => {
    setText('');
    onSelect(item);

  }, [onSelect]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);

  }, []);

  useEffect(() => {
    setItems(searchProjects({
      ignoreList,
      search: text,
    }));
  }, [
    ignoreList,
    searchProjects,
    text,
  ]);

  return (
    <AutoComplete
      getItemValue={(item: AutocompleteItem) => item.name}
      items={items}
      name="projects"
      onChange={handleSearch}
      onSelect={handleSelect}
      placeholder="Search for a project"
      value={text} />
  );
};

type AutocompleteItem =
  Pick<Project, 'id' | 'name'>;

export default ProjectAutocomplete;