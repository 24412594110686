import { useCallback, useState } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useDownloadProjectInsightsReport } from '@utils/api';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Header } from '@/components/Modal/Header';
import { Select } from '$admin/components/Select';
import styles from './style/Modal.Export.css';

type Props = IProjectId & Pick<ModalProps, 'onClose' | 'open'>;

export const ExportProjectInsightsModal = ({ onClose, open, projectId }: Props) => {
  const [format, setFormat] = useState<'docx' | 'pptx'>('pptx');

  const { download, isFetching } = useDownloadProjectInsightsReport({
    format,
    projectId,
  });

  const handleClickExport = useCallback(() => {
    download({
      extension: format,
      name: `Project Insights Summary`,
      title: `Generating Report`,
    });
    onClose();
  }, [
    download,
    format,
    onClose,
  ]);

  return (
    <Modal
      classes={{ root: styles.modal }}
      disableOverlayClick={isFetching}
      onClose={onClose}
      open={open}>
      <Header>Export Insights Summary</Header>

      <div className={styles.format}>

        <div className={styles.field}>
          <div className={styles.label}>File format:</div>

          <Select<Record<'pptx' | 'docx', string>>
            onChange={x => setFormat(x.target.value)}
            options={[
              { id: 'pptx', name: 'pptx' },
              { id: 'docx', name: 'docx' },
            ]}
            value={format} />
        </div>
      </div>

      <ButtonSet className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.action}
          color="primary"
          loading={isFetching}
          onClick={handleClickExport}>
          Download
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

ExportProjectInsightsModal.displayName = 'Insights.ExportModal';

export const useExportProjectInsightsModal = () => useModal(ExportProjectInsightsModal);