import { useMemo } from 'react';
import * as utils from '@utils';
import { useUserRoles } from './useUserRoles';

export const useHasInternalAdminRole = () => {
  const roles = useUserRoles();

  const hasInternalAdminRole = useMemo(() => {
    return utils.hasInternalAdminRole({ roles });
  }, [roles]);

  return hasInternalAdminRole;
};

export default useHasInternalAdminRole;