import type {
  SurveyTemplateBuilder,
  SurveyBuilder,
} from '../interfaces';
import * as $actions from './state.template';

export function templateReducer(state: SurveyTemplateBuilder.State, action: SurveyBuilder.Actions): SurveyTemplateBuilder.State {

  switch (action.type) {

    case 'template-data-updated': {
      return {
        ...state,
        data: action.value,
      };
    }

    case 'template-updated': {
      return {
        ...state,
        type: action.value,
      };
    }

    case 'template-category-updated':
      return $actions.templateCategoryUpdated(state, action);

    case 'template-target-updated':
      return $actions.templateTargetUpdated(state, action);

    case 'template-competitors-updated':
      return $actions.templateCategoriesUpdated(state, action);

    case 'template-partnership-criteria-updated':
      return $actions.templatePartnershipCriteriaUpdated(state, action);

    case 'template-eval-criteria-updated':
      return $actions.templateEvaluationCriteriaUpdated(state, action);

    case 'template-target-roles-updated':
      return $actions.templateRolesUpdated(state, action);

    case 'template-medical-condition-updated':
      return $actions.templateMedicalConditionUpdated(state, action);

    case 'template-drug-updated':
      return $actions.templateDrugUpdated(state, action);

    case 'template-launch-year-updated':
      return $actions.templateLaunchYearUpdated(state, action);

    case 'template-trial-design-updated':
      return $actions.templateTrialDesignUpdated(state, action);

    case 'template-qol-updated':
      return $actions.templateQualityOfLifeUpdated(state, action);

    case 'template-safety-endpoints-updated':
      return $actions.safetyEndpointsUpdated(state, action);

    case 'template-dose-administration-updated':
      return $actions.doseAdministrationUpdated(state, action);

    case 'template-other-attributes-updated':
      return $actions.otherAttributesUpdated(state, action);

    case 'template-drug-indication-updated':
      return $actions.drugIndicationUpdated(state, action);

    case 'template-efficacy-endpoints-updated':
      return $actions.efficacyEndpointsUpdated(state, action);

    case 'template-pricing-comparators-updated':
      return $actions.pricingComparatorsUpdated(state, action);

    case 'template-treatment-comparators-updated':
      return $actions.treatmentComparatorsUpdated(state, action);

    case 'template-drug-price-updated':
      return $actions.drugPriceUpdated(state, action);

    case 'template-treatment-category-updated':
      return $actions.treatmentCategoryUpdated(state, action);

    case 'template-stimulus-updated':
      return $actions.stimulusUpdated(state, action);

    case 'template-client-updated':
      return $actions.clientUpdated(state, action);

    case 'template-tpp-url-updated':
      return $actions.tppUrlUpdated(state, action);

    case 'template-tpp-updated':
      return $actions.tppUpdated(state, action);

    case 'template-additional-prices-updated':
      return $actions.templateAdditionalPricesUpdated(state, action);

    case 'template-specialties-updated':
      return $actions.medicalSpecialtiesUpdated(state, action);

    case 'template-mt-conditions-updated':
      return $actions.mtMedicalConditionsUpdated(state, action);

    case 'template-message-categories-updated':
      return $actions.messageCategoriesUpdated(state, action);

    case 'template-product-updated':
      return $actions.productUpdated(state, action);

    case 'template-attitudinals-updated':
      return $actions.attitudinalsUpdated(state, action);

    case 'template-mt-qualification-updated':
      return $actions.qualificationVariableUpdated(state, action);

    default:
      return state;
  }
}