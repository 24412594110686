import type { AudioHTMLAttributes, DetailedHTMLProps } from 'react';
import {
  useAudioPlayerRef,
  useAudioPlayerScan,
  useAudioPlayerState,
  useBootstrapAudioPlayer,
  useToggleAudioPlaying,
} from '@containers/AudioPlayer';
import { cx } from '@utils';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import Button from './Button';
import Time from './Progress.Time';
import Track from './Progress.Track';
import styles from './style/Player.css';

type Props = {
  className?: string;
  audio: DetailedHTMLProps<AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>;
};

export const AudioPlayer = ({
  audio,
  className,
}: Props) => {

  const [_, setPlayerRef] = useAudioPlayerRef();
  const [{ duration, playing }] = useAudioPlayerState();

  const togglePlaying = useToggleAudioPlaying();
  const { scan, seek, position } = useAudioPlayerScan();
  const bootstrapAudioPlayer = useBootstrapAudioPlayer();

  useKeyboardShortcuts();

  return (
    <>
      <div className={cx(styles.root, className)}>
        <Button
          playing={playing}
          togglePlaying={togglePlaying} />
        <Track
          position={position}
          scan={scan}
          seek={seek} />
        <Time
          className={styles.time}
          currentTime={position}
          duration={duration} />
      </div>
      <audio
        ref={setPlayerRef}
        {...bootstrapAudioPlayer()}
        {...audio} />
    </>
  );
};