import { useMemo } from 'react';
import * as consts from '@consts';
import WorkspaceTabFiles from './WorkspaceTabFiles';
import type { WorkspaceSceneMap } from './interfaces';
import { WorkspaceRouteKey } from './interfaces';
import WorkspaceTabView from './WorkspaceTabView';

export const WorkspaceTabViewContainer = () => {

  const map: WorkspaceSceneMap = useMemo(() => ({
    [WorkspaceRouteKey.Files]: {
      component: WorkspaceTabFiles,
      path: consts.path.WorkspaceTabs.Files,
      title: 'Files',
    },
  }), []);

  return (
    <WorkspaceTabView
      sceneMap={map} />
  );
};

export default WorkspaceTabViewContainer;