import { useCallback, useMemo } from 'react';
import type { Point } from '@nivo/line';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks';
import type { SurveyQuestionType } from '@enums';
import { PointsTooltip } from '@presentation/ProjectSurveyResponses';
import { roundToPlace } from '@utils';
import type { PointsTooltipProps } from './interfaces';

type Props =
  PointsTooltipProps;

export const ConjointAnalysisPointTooltip = ({ points: rawPoints }: Props) => {
  const { question } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  const getPointLabel = useCallback((point: Point) => {
    const attribute = question.attributes.find(f => f.base.id === +point.serieId);
    return attribute.value;
  }, [
    question.attributes,
  ]);

  const pointsValueText = useMemo(() => {
    const importance = roundToPlace(+rawPoints[0].data.y, 1);
    return `${importance}%`;
  }, [
    rawPoints,
  ]);

  return (
    <PointsTooltip
      points={rawPoints}
      getPointLabel={getPointLabel}
      pointsValueText={pointsValueText} />
  );
};

export default ConjointAnalysisPointTooltip;