import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import type * as enums from '@enums';
import { Selecting } from '@screens/Scheduling/Selecting';
import { qs } from '@utils';
import { useConfirmProjectCall } from '@utils/api';
import { useNotifier } from './Notifier';
import type { CalendarConfirmTimeData } from './interfaces';

type Props = {
  recordingConsentStatusId: enums.ApprovalStatus;
  scheduleeId: number;
  schedulerId: number;
} & ICallId
  & IProjectId;

const ProjectCallConfirmationCalendar = (props: Props) => {
  const notifier = useNotifier();
  const history = useHistory();

  const { mutateAsync } = useConfirmProjectCall({
    callId: props.callId,
  }, {
    onSuccess: res => {
      const from = qs.getParam('from');

      notifier.callScheduled(res.call);

      history.replace(from || consts.pathname.HOME);
    },
    onError: notifier.error,
  });

  const handleSubmit = useCallback((data: CalendarConfirmTimeData) => {
    return mutateAsync({
      recordingConsentStatusId: props.recordingConsentStatusId,
      slot: data.slot,
    });
  }, [
    mutateAsync,
    props.recordingConsentStatusId,
  ]);

  return (
    <Selecting
      callId={props.callId}
      onSubmit={handleSubmit}
      scheduleeId={props.scheduleeId}
      schedulerId={props.schedulerId} />
  );
};

export { ProjectCallConfirmationCalendar };
export default ProjectCallConfirmationCalendar;