import { Fragment, useCallback } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { SurveyQuestionType } from '@enums';
import { AggregateDataHeaderRow, ProjectVersionDataRow, ResponseOption } from '@presentation/ProjectSurveyResponses';
import type { RepeatSurvey, SurveyQuestionOption } from '@/types';
import { SimpleAccordion } from '@/components/Accordion';
import { useOptionsColors } from '@/components/SurveyResponses/hooks/usePaletteColors';
import { useFilteredOptions, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const OptionsAggregateData = () => {
  const { query, resetQueryState, setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.Options>();
  const { projectIds } = useRepeatSurveyData();
  const { aggregate, historical } = useRepeatQuestionResponsesData<SurveyQuestionType.Multiselect | SurveyQuestionType.MultipleChoice>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('optionIds');
  const colors = useOptionsColors();

  const options = useFilteredOptions();

  const onVersionClick = useCallback((optionId: number, projectId: number) => () => {
    setQueryState({
      optionIds: [optionId],
      projectId,
    });
  }, [setQueryState]);

  const renderVersions = useCallback((option: SurveyQuestionOption) => {
    return [...projectIds]
      .sort((a, b) => b - a)
      .reduce((acc, projectId, i) => {
        const avg = historical[projectId]?.[option.base.id]?.pct;
        if (avg !== undefined) {
          acc.push(
            <ProjectVersionDataRow
              key={projectId}
              label={`${Math.round(avg)}% of Total`}
              onClick={onVersionClick(option.base.id, projectId)}
              version={projectIds.length - i} />
          );
        }

        return acc;
      }, []);
  }, [
    historical,
    onVersionClick,
    projectIds,
  ]);

  const renderOption = useCallback((option: SurveyQuestionOption) => {
    const data = aggregate[option.base.id];
    return (
      <SimpleAccordion
        grows={false}
        className={styles.row}
        key={option.base.id}
        height={50}
        open={isAccordionOpen(option.base.id)}
        toggleOpen={toggleAccordion(option.base.id)}
        label={renderAccordionLabel(data, option, colors)}>
        {renderVersions(option)}
      </SimpleAccordion>
    );
  }, [
    aggregate,
    colors,
    isAccordionOpen,
    renderVersions,
    toggleAccordion,
  ]);

  return <>
    <AggregateDataHeaderRow
      className={styles.row}
      label="Key"
      onClose={query.optionIds && resetQueryState} />
    {options
      .map(option => (
        <Fragment key={option.base.id}>
          {renderOption(option)}
        </Fragment>
      ))
    }
  </>;
};

function renderAccordionLabel(data: RepeatSurvey.Options.Data, option: SurveyQuestionOption, colors: string[]) {
  const color = colors[option.ordinal - 1];
  const subtitle = `(Avg. ${Math.round(data.pct)}%)`;
  const label = parseSurveyRichText(option.value);

  return (
    <ResponseOption
      color={color}
      label={label}
      subtitle={subtitle} />
  );
}

export default OptionsAggregateData;