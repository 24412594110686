import * as enums from '@enums';
import { EnumDropDown } from '@/components/DropDown';
import type { ProjectFrequencyDropdownProps } from './interfaces';

type Props =
  ProjectFrequencyDropdownProps;

export const ProjectFrequencyDropdown = ({
  items,
  onSelect,
  value,
}: Props) => {

  return (
    <EnumDropDown<enums.ProjectFrequency>
      enum={enums.utils.ProjectFrequency}
      items={items}
      onSelect={onSelect}
      value={value} />
  );
};

export default ProjectFrequencyDropdown;