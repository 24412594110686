import { Fragment, useCallback, useContext } from 'react';
import { createNamedContext, cx } from '@utils';
import type { ModalProps as RootModalProps } from '@/components/Modal/Modal';
import { Modal as RootModal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import type { RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { DiscardPostAlert } from './Publisher.DiscardPostAlert';
import styles from './style/Publisher.Modal.css';

const PublisherModalContext = createNamedContext<PublisherModalContextValue | undefined>(undefined, 'PostCreation.Publisher.Modal.Context');

type Props = {
  classes?: {
    close?:  string;
    footer?: string;
    header?: string;
    root?:   string;
    title?:  string;
  };
  blocking?: boolean;
} & Pick<RootModalProps, 'onClose' | 'open'>
  & Partial<Omit<RootModalProps, 'onClose' | 'open' | 'wrapClassName'>>;

export const Footer = (props: Pick<Props, 'children'>) => {
  const ctx = useContext(PublisherModalContext);

  return (
    <div className={cx(styles.footer, ctx.classes.footer)}>
      {props.children}
    </div>
  );
};

export const Header = (props: Pick<Props, 'children'>) => {
  const ctx = useContext(PublisherModalContext);

  return (
    <div className={cx(styles.header, ctx.classes.header)}>
      <div className={cx(styles.title, ctx.classes.title)}>{props.children}</div>
      {!ctx.hideCloseIcon &&
        <div
          className={cx(styles.x, ctx.classes.close)}
          onClick={ctx.onRequestClose}>
          ✕
        </div>}
    </div>
  );
};

export const Modal = (props: Props) => {
  const { blocking, children, classes, onClose, ...modalProps } = props;
  const [toggle, UnmountGuardAlert] = useModal(DiscardPostAlert);

  const handleRequestClose = useCallback(() => {
    blocking
      ? toggle()
      : onClose();
  }, [
    blocking,
    onClose,
    toggle,
  ]);

  const context = {
    ...props,
    onRequestClose: handleRequestClose,
  };

  return (
    <Fragment>

      <RootModal
        {...modalProps}
        hideCloseIcon
        onClose={handleRequestClose}
        classes={{
          wrap: cx(styles.root, classes.root),
        }}>
        <PublisherModalContext.Provider value={context}>
          {props.children}
        </PublisherModalContext.Provider>
      </RootModal>

      <UnmountGuardAlert onConfirm={onClose} />
      <RouteLeavingGuard block={blocking}>
        {(guard: RouteLeavingGuardState) => (
          <DiscardPostAlert
            onClose={guard.closePrompt}
            onConfirm={guard.confirmNavigation}
            open={guard.open} />
        )}
      </RouteLeavingGuard>

    </Fragment>
  );
};

const defaultProps = {
  blocking: false,
  classes: {},
  hideCloseIcon: false,
};

Footer.displayName = 'PostCreation.Publisher.Modal.Footer';
Header.displayName = 'PostCreation.Publisher.Modal.Header';
Modal.displayName = 'PostCreation.Publisher.Modal';
Modal.defaultProps = defaultProps;

export type ModalProps = Props;

export type PublisherModalContextValue = {
  onRequestClose: () => unknown;
} & Props;