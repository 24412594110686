import imgPath from 'static/icons/member-search.svg?url';

export const MemberSearch = ({ className, size }: Props) => {
  return (<img
className={className}
src={imgPath}
height={size}
width={size} />);
};

type Props = {
  className?: string;
  size: number;
};