import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { DropdownQuestion as DQ } from '@/types/survey';
import { Input } from '@/components/Input';
import type { OnSelectChange } from '$admin/components/Select';
import { SelectUnderlined, Lookup } from '$admin/components/Select';
import { Rationale } from './Rationale';
import styles from './style/Dropdown.css';

type Props = {
  item: DQ.FormQuestion;
};

export default function DropdownQuestion({ item }: Props) {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Dropdown>();

  const handleChange: OnSelectChange<number> = useCallback(e => {
    setAnswer({
      optionId: +e.target.value,
      text: answer.text,
    });
  }, [setAnswer, answer.text]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer({
      optionId: answer.optionId,
      text: e.target.value,
    });
  }, [setAnswer, answer.optionId]);

  const selectedOption = useMemo(() => {
    return item.options.find(f => f.id === answer.optionId);
  }, [answer.optionId, item.options]);

  const dropdownOptions = useMemo(() => {
    return item.options.map(o => ({
      name: parseSurveyRichText(o.value),
      id: o.id,
    }));
  }, [item.options]);

  return (
    <>
      <div className={styles.dropdown}>
        <SelectUnderlined
          placeholder="Select an option"
          onChange={handleChange}
          options={dropdownOptions}
          value={answer?.optionId || ''} />
      </div>
      {selectedOption?.metadata?.isOpenEnded &&
        <div className={styles.input}>
          <Input
            placeholder="Enter your answer"
            onChange={handleInputChange}
            value={answer.text || ''} />
        </div>
      }
      <Rationale settings={item.settings.rationale} />
    </>
  );
}

export { DropdownQuestion };