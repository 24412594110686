import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query';
import type { ConferenceTagSummaryItem } from '@/types';
import * as api from '@api';

type Props =
  ITranscriptId;

type Data = ConferenceTagSummaryItem[];
type Options = UseQueryOptions<Data, unknown, Data, QueryKey>;
type QueryKey = readonly [string, number];

export function getTranscriptSummaryQueryKey(data: ITranscriptId) {
  return [
    `get:transcripts/summary`,
    data.transcriptId,
  ] as const;
}

export const useTranscriptSummaryLoading = (data: ITranscriptId) => {
  const key = getTranscriptSummaryQueryKey(data);

  return useIsFetching(key);
};

export const useFetchTranscriptSummary = (data: Props, options?: Options) => {
  const qk = getTranscriptSummaryQueryKey(data);

  return useQuery({ queryKey: qk, queryFn: ({ queryKey }) => {
    const [, transcriptId] = queryKey;

    return api.transcripts.fetchSummary({
      transcriptId,
    }).then(res => res.items);
  }, ...options, refetchOnWindowFocus: false });
};

type Updater = (old: Data) => Data;

export const useUpdateStoredTranscriptTagSummaries = (props: Props) => {
  const qk = getTranscriptSummaryQueryKey(props);

  const queryClient = useQueryClient();

  const handleUpdate = useCallback((updater: Updater) => {
    queryClient.setQueryData(qk, updater);
  }, [queryClient, qk]);

  return handleUpdate;
};

export const useInvalidateTranscriptTagSummaries = (props: Props) => {
  const qk = getTranscriptSummaryQueryKey(props);

  const queryClient = useQueryClient();

  const handleInvalidate = useCallback(() => {
    return queryClient.invalidateQueries(qk);
  }, [queryClient, qk]);

  return handleInvalidate;
};