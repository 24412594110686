import { cx } from '@utils';
import Modal from '@/components/Modal/Modal';
import Header from './Header';
import Body from './Body';
import type { PromptModalProps } from './interfaces';
import styles from './style/Base.css';

type Props =
    PromptModalProps;

export const Prompt = (props: Props) => {

  const containerClassName = cx(styles.promptModal, props.className);

  return (
    <Modal
      blocking={props.blocking}
      className={containerClassName}
      disablePortal={props.disablePortal}
      onClose={props.onClose}
      open={props.visible}>
      <div className={styles.promptRoot}>
        {props.header && <Header className={styles.promptHeader}>{props.header}</Header>}
        {props.body && <Body className={styles.promptBody}>{props.body}</Body>}
        {props.footer && <div className={styles.promptFooter}>{props.footer}</div>}
      </div>
    </Modal>
  );
};

export default Prompt;