import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import { SetDisplayDashboardIdContext } from '@containers/GroupProject/Context';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { useModal } from '@/components/Modal/hooks';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import styles from './style/DashboardModal.css';

type Props = {
  initialDashboardId?: string;
} & Pick<ModalProps,
  'onClose' |
  'open'>
  & IProjectId;

export const DisplayrDashboardModal = ({
  initialDashboardId = '',
  onClose, open, projectId,
}: Props) => {

  const [value, setValue] = useState<string>(initialDashboardId);
  const setDisplayDashboardId = useContext(SetDisplayDashboardIdContext);

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const { isLoading: isSubmitting, mutateAsync } = useMutation({ mutationFn: () => {
    return $api.projects.updateProjectDisplayrDashboard({
      projectId,
      dashboardId: value,
    });
  }, onSuccess: res => {
    setDisplayDashboardId(res.dashboardId);
    onClose();
  } });

  const handleSubmit = useCallback(() => {
    return mutateAsync();
  }, [mutateAsync]);

  const submitDisabled = useMemo(() => {
    return isSubmitting || !value;
  }, [isSubmitting, value]);

  return (
    <Modal
      onClose={onClose}
      open={open}>

      <Header text="Create Displayr Dashboard" />

      <div className={styles.input}>
        <Input
          onChange={handleInput}
          placeholder="Enter Dashboard ID"
          value={value} />
      </div>

      <div className={styles.note}>
        * The Dashboard ID can be obtained from the URL of the dashboard in Displayr.
        <br /><br />
        https://app.displayr.com/Dashboard?id=<span className={styles.id}>6fbbacab-37de-4239-bb75-6623f69476d2</span>
      </div>

      <div className={styles.footer}>

        <ButtonSet>
          <Button.Primary
            disabled={submitDisabled}
            onClick={handleSubmit}
            variant="brick">
            Save
          </Button.Primary>
        </ButtonSet>

      </div>

    </Modal>
  );
};

export const useDisplayrDashboardModal = () => useModal(DisplayrDashboardModal);