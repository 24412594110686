import { useEffect, useState } from 'react';
import { useCompatSearchQuery } from '@containers';
import type { ProjectGoalProgressInfo } from './interfaces';
import styles from './Project.css';

type Props = {
  item: null | ProjectGoalProgressInfo;
};

const ProjectGoalMessage = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const [query] = useCompatSearchQuery();
  const [count, setItemsCount] = useState<number>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!query.loading && mounted) {
      setItemsCount(query.value.total);
    }
  }, [query.loading, mounted, query.value]);

  if (props.item === null) return null;

  if (count !== null && props.item.remaining > count) {
    return <div className={styles.message}>{messages.insufficient}</div>;
  }

  const low = 40;
  const mid = 80;
  const text = props.item.progress >= mid
             ? messages.hi
             : props.item.progress < low
               ? messages.low
               : messages.mid;

  return <div className={styles.message}>{text}</div>;
};

const messages = {
  low: `Select additional profiles to build up a candidate pipeline`,
  mid: `Getting closer! Add a few more candidates to fill out the pipeline.`,
  hi: `Great! Looks like you have plenty of people to talk to. Go to Pipeline to proceed with scheduling.`,
  insufficient: `We have been notified and are sourcing profiles for this project.`,
};

export { ProjectGoalMessage };
export default ProjectGoalMessage;