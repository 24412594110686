import { Fragment, memo } from 'react';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';
import { Chip } from '@presentation/Chip';
import { DatePicker } from '@/components/DatePicker';
import { NetSuiteProjects as NetSuiteAutocomplete } from '$admin/components/Autocomplete';
import styles from './style.css';

type NetSuiteProject = {
  id: number;
  name: string;
  identifier: string;
};

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: NetSuiteProject;
};

export function FieldNetSuiteProject({ editing, label, name, displayValue }: Props) {
  const { setFieldValue } = useFormikContext();

  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps<NetSuiteProject>) => (
        <div>
          <div className={styles.label}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <NetSuiteAutocomplete
              onSelect={val => setFieldValue(name, val)}
              selected={field.value} />
          </div>
        </div>
      )}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.dateValue}>
        {displayValue &&
          <>
            {displayValue.name}({displayValue.identifier})
          </>
        }
        {!displayValue &&
          <>
            Not Set
          </>
        }
      </span>
    </Fragment>
  );
});