import { createRef } from 'react';
import { body } from '@containers/PostCreation/utils';
import { PostMediaType, UGCImpressionType } from '@enums';
import { cx } from '@utils';
import { useCreateImpression } from '@utils/analytics/useCreateImpression';
import type { ContentFeed } from '@/types';
import { URLPreview } from '@/components/URLPreview';
import { Body, Header, Media } from './Post';
import { PostTopics } from './Topics';
import styles from './style/Post.css';

type Props = {
  className?: string;
  item:       Omit<ContentFeed.Post, 'comments' | 'likeDescription'>;
};

export const Post = ({ className, item }: Props) => {
  const ref = createRef<HTMLDivElement>();
  const hasBody = !body.isEmpty({ value: item.body });

  useCreateImpression({
    itemId: item.id,
    ref,
    typeId: UGCImpressionType.FeedPostCard,
  });

  const hasVideo = item.media?.typeId === PostMediaType.Video;
  const hasTopics = !!item.topics.length;
  const hasPreview = !!item.preview;
  const showUrlPreview = hasPreview && !item.media;
  const showPostTopics = hasTopics && !item.media;

  return (
    <div
      className={cx(styles.root, className)}
      ref={ref}>
      <Header
        className={styles.header}
        item={item} />

      {hasBody &&
        <Body
          maxLines={5}
          value={item.body} />}

      {hasVideo &&
        <div className={styles.media}>

          <Media.Video item={item} />

          {hasTopics &&
            <PostTopics
              className={styles.topics}
              postId={item.id}
              items={item.topics} />}

        </div>}

      {showUrlPreview &&
        <div className={styles.preview}>
          <URLPreview
            item={item.preview}
            url={item.preview.url} />
        </div>}

      {showPostTopics &&
        <PostTopics
          className={styles.topics}
          postId={item.id}
          items={item.topics} />}

    </div>
  );
};

Post.displayName = 'Feed.Post';