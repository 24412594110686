import type { ComponentType } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyFormTemplateData } from '@containers/SurveyForm/hooks';
import { useIsEditingSurvey, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplateType } from '@/enums';
// eslint-disable-next-line import/no-cycle
import {
  useTemplateStimulusModal,
  useEditableTemplateStimulusModal,
} from '@/components/Survey.Templates/Modal.Stimulus';
import styles from './style/Node.ViewStimulus.css';

export const ViewStimulusNode: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />
  ;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const editing = useIsEditingSurvey();

  const [toggleModal, StimulusModal] = useEditableTemplateStimulusModal();

  return (
    <>
      <span className={styles.link} onClick={toggleModal}>
        View Stimulus
      </span>
      <StimulusModal canEdit={!editing} />
    </>
  );
}

function Node({ node }: NodeViewComponentProps) {

  const [toggleModal, StimulusModal] = useTemplateStimulusModal();
  const data = useSurveyFormTemplateData<SurveyTemplateType.TargetProductProfile>();

  if (!data) return null;

  return (
    <>
      <span className={styles.link} onClick={toggleModal}>
        View Stimulus
      </span>
      {data && <StimulusModal stimulus={data.stimulus} />}
    </>
  );
}