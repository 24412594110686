import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, focusManager } from '@tanstack/react-query';
import * as $session from '@services/auth/session';
import * as $sessionHttp from '@services/auth/session-http';
import { useEventListener } from '@utils/hooks';

type Props = {
  enabled: boolean;
};

const mapState = (state: Store.State) => ({
  userId: state.user.id,
});

export function useValidSessionChecker({ enabled }: Props) {
  const state = useSelector(mapState);
  const [focused, setFocused] = useState<boolean>(focusManager.isFocused());

  useQuery({ queryKey: ['valid-session-checker'], queryFn: () => $sessionHttp.getSessionInfo(), onSuccess: data => {
    if (!data?.authenticated || !data?.userId || data.userId !== state.userId) {
      window.location.reload();
    }
  }, refetchInterval: () => focused ? false : 15 * 60 * 1000, refetchIntervalInBackground: true, staleTime: 1000, enabled });

  const handleFocusChange = useCallback(() => setFocused(focusManager.isFocused()), []);

  const handleStorageChange = useCallback((e: StorageEvent) => {
    if (e.key === $session.Keys.Session) {
      if (e.oldValue && !e.newValue) {
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    const unsubscribe = focusManager.subscribe(handleFocusChange);
    return () => {
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('storage', handleStorageChange);
}