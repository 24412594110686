import { useMemo } from 'react';
import type { ActionMeta, OnChangeValue } from 'react-select';
import Select from 'react-select';
import * as enums from '@enums';
import { cx } from '@utils';
import { createSelectStyles, createSelectTheme } from './select-styles';
import styles from './style/Select.Clearable.css';

type Props = {
  autoFocus?: boolean;
  className?: string;
  onChange:  (newValue: OnChangeValue<Item, false>, actionMeta: ActionMeta<Item>) => unknown;
  readonly?: boolean;
  value:     enums.ProfessionalRole;
};

export const Role = (props: Props) => {
  const roles = useMemo(() => {
    return [
      enums.ProfessionalRole.AdministrativeStaff,
      enums.ProfessionalRole.FormerExec,
      enums.ProfessionalRole.HealthEconomist,
      enums.ProfessionalRole.Nurse,
      enums.ProfessionalRole.NursePractitioner,
      enums.ProfessionalRole.Payer,
      enums.ProfessionalRole.Patient,
      enums.ProfessionalRole.PatientCaregiver,
      enums.ProfessionalRole.Physician,
      enums.ProfessionalRole.PhysiciansAssistant,
      enums.ProfessionalRole.Pharmacist,
      enums.ProfessionalRole.Professor,
      enums.ProfessionalRole.Other,
    ].map(i => ({
      value: i,
      label: enums.utils.ProfessionalRole.getName(i),
    }));
  }, []);

  const selectStyles = useMemo(() => {
    return createSelectStyles<Item>({
      readonly: props.readonly,
    });
  }, [props.readonly]);
  const selectTheme = useMemo(() => createSelectTheme(), []);

  const item = roles.find(i => i.value === props.value);

  const highlightable = cx(styles.t, {
    [styles.cover]: props.readonly,
    [styles.placeholder]: props.readonly && !item,
  });

  return (
    <div className={styles.root}>
      <Select
        autoFocus={props.autoFocus}
        className={props.className}
        defaultValue={item}
        value={item}
        options={roles}
        getOptionLabel={o => o.label}
        getOptionValue={o => `${+o.value}`}
        noOptionsMessage={() => `Role not found`}
        placeholder="Role"
        onChange={props.onChange}
        styles={selectStyles}
        theme={selectTheme} />
      <div
        className={highlightable}
        draggable={false}>
        <div className={styles.i}>
          {item?.label ?? 'Not Set'}
        </div>
      </div>
    </div>
  );
};

Role.displayName = 'MedicalProfile.Field.Role';

type Item = {
  label: string;
  value: enums.ProfessionalRole;
};