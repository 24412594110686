import * as types from '@store/action-types';
import type { Chat } from '@store/interfaces';

export function chatConversationAdded(data: Chat.ConversationAdded.State): Chat.ConversationAdded.Action {
  return {
    type: types.CHAT_CONVERSATION_ADDED,
    conversation: data.conversation,
    conversationSid: data.conversationSid,
    messages: data.messages ?? [],
    participants: data.participants,
  };
}

export function chatConversationJoined(data: Chat.ConversationJoined.State): Chat.ConversationJoined.Action {
  return {
    type: types.CHAT_CONVERSATION_JOINED,
    conversationSid: data.conversationSid,
    messages: data.messages,
  };
}

export function chatConversationRemoved(data: Chat.ConversationRemoved.State): Chat.ConversationRemoved.Action {
  return {
    type: types.CHAT_CONVERSATION_REMOVED,
    conversationSid: data.conversationSid,
    userIds: data.userIds,
  };
}

export function chatConversationUpdated(data: Chat.ConversationUpdated.State): Chat.ConversationUpdated.Action {
  return {
    type: types.CHAT_CONVERSATION_UPDATED,
    conversation: data.conversation,
    conversationSid: data.conversationSid,
  };
}

export function chatConversationsLoaded(data: Chat.ConversationsLoaded.State): Chat.ConversationsLoaded.Action {
  return {
    type: types.CHAT_CONVERSATIONS_LOADED,
    conversations: data.conversations,
    messages: data.messages,
    participants: data.participants,
  };
}

export function chatConnectionStateChange(data: Chat.ConnectionChanged.State): Chat.ConnectionChanged.Action {
  return {
    type: types.CHAT_CONNECTION_STATE_CHANGE,
    connection: data.connection,
  };
}

export function chatConnectionAdded(data: Chat.ConnectionAdded.State): Chat.ConnectionAdded.Action {
  return {
    type: types.CHAT_CONNECTION_ADDED,
    userId: data.userId,
    createdOn: data.createdOn,
  };
}

export function platformMessageAdded(data: Chat.PlatformMessageAdded.State): Chat.PlatformMessageAdded.Action {
  return {
    type: types.CHAT_PLATFORM_MESSAGE_ADDED,
    conversationSid: data.conversationSid,
    message: data.message,
  };
}

export function platformMessageUpdated(data: Chat.PlatformMessageUpdated.State): Chat.PlatformMessageUpdated.Action {
  return {
    type: types.CHAT_PLATFORM_MESSAGE_UPDATED,
    conversationSid: data.conversationSid,
    message: data.message,
  };
}

export function platformMessageRemoved(data: Chat.PlatformMessageRemoved.State): Chat.PlatformMessageRemoved.Action {
  return {
    type: types.CHAT_PLATFORM_MESSAGE_REMOVED,
    conversationSid: data.conversationSid,
    messageId: data.messageId,
  };
}

export const chatConversationParticipantAdded = (data: Chat.ParticipantAdded.State): Chat.ParticipantAdded.Action => {
  return {
    type: types.CHAT_PARTICIPANT_ADDED,
    participant: data.participant,
  };
};

export const chatMessageAdded = (data: Chat.MessageAdded.State): Chat.MessageAdded.Action => {
  return {
    type: types.CHAT_MESSAGE_ADDED,
    conversationSid: data.conversationSid,
    message: data.message,
  };
};

export const chatMessageSent = (data: Chat.MessageSent.State): Chat.MessageSent.Action => {
  return {
    type: types.CHAT_MESSAGE_SENT,
    conversationSid: data.conversationSid,
    message: data.message,
  };
};