import type { WorkspaceFile, WorkspaceFileUpload } from '@/types';
import { safeJsonDate } from '@utils';

export const normalizeFile = (data: WorkspaceFile<string>): WorkspaceFile => {
  if (!data) return null;

  return Object.assign(data, {
    createdOn: safeJsonDate(data.createdOn),
    lastDownloadedOn: safeJsonDate(data.lastDownloadedOn),
    lastModifiedOn: safeJsonDate(data.lastModifiedOn),
  });
};

export const normalizeFileUpload = (data: WorkspaceFileUpload<string>): WorkspaceFileUpload => {
  if (!data) return null;

  return Object.assign(data, {
    initiatedOn: safeJsonDate(data.initiatedOn),
    lastUpdatedOn: safeJsonDate(data.lastUpdatedOn),
  });
};