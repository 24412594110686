import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { TopicLogo } from '@presentation/Topic.Logo';
import type { Topics } from '@/types';
import { Button } from './Following.Button';
import styles from './style/Following.Item.css';

type Props = {
  following:         boolean;
  item:              Topics.Following;
  onToggleFollowing: () => void;
};

export const Item = (props: Props) => {
  const count = useRef(Math.round(Math.random() * 100));

  const location = `${path.Topics.Root}/${props.item.slug}`;

  const stat = count.current >= 1
      ? count.current
      : `< 1`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.info}>
            <Link to={location}>
              <TopicLogo
                classes={{
                  root: styles.img,
                  img: styles.img,
                }}
                url={props.item.thumbnailUrl} />
            </Link>
            <div className={styles.name}>
              <Link to={location}>
                <div className={styles.clamp}>
                  {props.item.name}
                </div>
              </Link>
            </div>
            <div className={styles.stats}>{`| ${stat}k followers`}</div>
          </div>
          <div className={styles.following}>
            <Button
              following={props.following}
              onClick={props.onToggleFollowing} />
          </div>
        </div>
      </div>
    </div>
  );
};

Item.displayName = 'Following.Item';