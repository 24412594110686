import type { SurveyLogic, SurveyQuota, SurveyTagging } from '@/types/survey';
import type { SurveyBuilder, SurveyCommittedBuilder } from '../interfaces';

export function committedReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyCommittedBuilder.State {

  switch (action.type) {

    case 'draft-loaded':
      return {
        alternateImageExercises: action.draft.alternateImageExercises,
        classifications: action.draft.classifications,
        items: action.draft.items,
        logic: action.draft.logic,
        messages: action.draft.messages,
        questions: action.draft.questions,
        quotas: action.draft.quotas,
        tagging: action.draft.tagging,
      };

    case 'toggle-editing':
      return {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        logic: state.survey.logic,
        messages: state.survey.messages,
        questions: state.survey.questions,
        quotas: state.survey.quotas,
        tagging: state.survey.tagging,
      };

    case 'reset-survey':
      return {
        alternateImageExercises: action.value.alternateImageExercises,
        classifications: action.value.classifications,
        items: action.value.items,
        logic: action.value.logic,
        messages: action.value.messages,
        questions: action.value.questions,
        quotas: action.value.quotas,
        tagging: action.value.tagging,
      };

    case 'save-quota-item': {
      const quotas = state.survey.quotas.reduce<SurveyQuota.Record[]>((acc, x) => {
        if (x.identifier === action.value.identifier) {
          acc.push(action.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);

      return {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        logic: state.survey.logic,
        messages: state.survey.messages,
        questions: state.survey.questions,
        quotas,
        tagging: state.survey.tagging,
      };
    }

    case 'tagging-item-saved': {
      const tagging = state.survey.tagging.reduce<SurveyTagging.Item[]>((acc, x) => {
        if (x.identifier === action.payload.value.identifier) {
          acc.push(action.payload.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);

      return {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        logic: state.survey.logic,
        messages: state.survey.messages,
        questions: state.survey.questions,
        quotas: state.survey.quotas,
        tagging,
      };
    }

    case 'logic-item-saved': {
      const logic = state.survey.logic.reduce<SurveyLogic.Item[]>((acc, x) => {
        if (x.identifier === action.payload.value.identifier) {
          acc.push(action.payload.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);

      return {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        logic,
        messages: state.survey.messages,
        questions: state.survey.questions,
        quotas: state.survey.quotas,
        tagging: state.survey.tagging,
      };
    }

    case 'draft-survey-data-updated':
      return {
        alternateImageExercises: action.value.alternateImageExercises,
        classifications: action.value.classifications,
        items: action.value.items,
        logic: action.value.logic,
        messages: action.value.messages,
        questions: action.value.questions,
        quotas: action.value.quotas,
        tagging: action.value.tagging,
      };

    default:
      return state.committed;

  }
}