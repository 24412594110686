import { cx } from '@utils';
import type { LeftProps } from './interfaces';
import styles from './style.css';

type Props =
    LeftProps;

const Left = (props: Props) => {
  return (
    <div className={cx(styles.left, props.className)}>
      {props.children}
    </div>
  );
};

export { Left };
export default Left;