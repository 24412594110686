import { usePositioner } from '@remirror/react';
import type { Positioner } from 'remirror/extensions';
import { hasStateChanged, selectionPositioner } from 'remirror/extensions';
import { usePreviousDistinct } from '@/utils';
import { useLinkPopperContext } from '../Context';

export const useLinkPositioner = () => {

  const { selection } = useLinkPopperContext();
  const open = !!selection;
  const previousOpen = usePreviousDistinct(open);

  return usePositioner((): Positioner<{ from: Coords; to: Coords }> => {
    return selectionPositioner.clone({
      hasChanged: props => {
        return hasStateChanged(props) || open !== previousOpen;
      },
      getActive: ({ helpers, view }) => {

        if (!selection) return [];

        const from = view.coordsAtPos(selection.range.from);
        const to = view.coordsAtPos(selection.range.to);

        return [{ from, to }];
      },
    });

  }, [
    open,
    previousOpen,
    selection,
  ]);
};

type Coords = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};