import { io } from 'socket.io-client';
import type { NotificationsSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { Notifications } from './interfaces';

const log = createLogger(SocketNamespace.Notifications);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Notifications}`, resolveSocketOptions());

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: NotificationsSocketEvent, payload: unknown) => log.event(event, payload));
  socket.open();
}

export function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

export const on = <T extends NotificationsSocketEvent>(event: T, handler: Notifications.RegisterHandler<T>) => {
  return socket.on<NotificationsSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const off = <T extends NotificationsSocketEvent>(event: T, handler: Notifications.RegisterHandler<T>) => {
  return socket.off<NotificationsSocketEvent | LifeCycleSocketEvent>(event, handler);
};

export const emit = (type: string, data: unknown) => {
  return socket.emit(type, data);
};