import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import type { Slack } from '@/types';
import { createNamedContext } from '@utils';

type SlackChannelsContextValue =
  [Slack.Channel[], Dispatch<SetStateAction<Slack.Channel[]>>];

export const SlackChannelsContext = createNamedContext<SlackChannelsContextValue>(undefined, 'SlackChannelsContext');

export const useSlackChannelsContext = () => useContext(SlackChannelsContext);