import http from '@services/http';
import { qs } from '@utils';
import type { Groups } from './interfaces';

export const fetchInstallURL = (data: Groups.Apps.FetchHubSpotInstallURL.Request) => {
  const { state } = data;

  const q = state
      ? `?${qs.stringify({ state })}`
      : '';

  return http.get<{ url: string }>(`/groups/${data.groupId}/apps/hubspot/install${q}`);
};

export const connect = (data: Groups.Apps.ConnectHubSpot.Request) => {
  return http.post(`/groups/${data.groupId}/apps/hubspot/connect`, data);
};