import { useCallback, useMemo } from 'react';
import type { ComputedSerie } from '@nivo/line';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import type { SurveyQuestionType } from '@enums';
import { SeriesTooltip } from '@presentation/ProjectSurveyResponses';
import { roundToPlace } from '@utils';
import type { SeriesTooltipProps } from './interfaces';

type Props =
  SeriesTooltipProps;

export const ConjointAnalysisSeriesTooltip = ({ series: rawSeries }: Props) => {
  const { question, aggregate } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  const getSerieLabel = useCallback((serie: ComputedSerie) => {
    const attribute = question.attributes.find(f => f.base.id === +serie.id);
    return attribute.value;
  }, [
    question.attributes,
  ]);

  const getSerieAvg = useCallback((serie: ComputedSerie) => {
    const avg = aggregate[+serie.id].avg;
    return roundToPlace(avg, 1);
  }, [aggregate]);

  const getSerieStat = useCallback((serie: ComputedSerie) => {
    const avg = getSerieAvg(serie);
    return `Avg. ${avg}%`;
  }, [getSerieAvg]);

  const series = useMemo(() => {
    return [...rawSeries]
      .sort((a, b) => getSerieAvg(b) - getSerieAvg(a));
  }, [
    getSerieAvg,
    rawSeries,
  ]);

  return (
    <SeriesTooltip
      getSerieLabel={getSerieLabel}
      getSerieStat={getSerieStat}
      series={series} />
  );
};

export default ConjointAnalysisSeriesTooltip;