import { useMemo, useCallback } from 'react';
import type { BarTooltipProps, ComputedDatum } from '@nivo/bar';
import { trunc } from '@utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import * as chart from '@containers/SurveyResponses/utils';
import { OptionsBarChartTooltip } from '@presentation/ProjectSurveyResponses';
import type { SurveyAggregate, SurveyQuestion, SurveyQuestionOption } from '@/types';
import type { OptionsBarData } from './interfaces';
import BarChart from './BarChart';
import { theme } from './Chart.Common';

type Props = {
  colors?: string[] | ((option: SurveyQuestionOption) => string);
  question: SurveyQuestion;
  responses: SurveyAggregate.Options.Map;
  onClick: (optionId: number) => unknown;
};

function getDistinctUserCount(question: SurveyQuestion, responses: SurveyAggregate.Options.Map) {
  const allUserIds = question.options.map(o => o as SurveyQuestionOption).reduce((acc, x) => [...acc, ...responses[x.base.id].userIds], [] as number[]);
  return Array.from(new Set(allUserIds)).length;
}

const ResponseOptionsChart = ({ colors = chart.options.OptionsColors, ...props }: Props) => {
  const count = useMemo(() => getDistinctUserCount(props.question, props.responses), [props.responses, props.question]);

  const emptyValue = useMemo(() => count * 0.0025, [count]);
  const items = [...props.question.options]
    .sort(chart.options.sortOptions(props.question, props.responses))
    .map<OptionsBarData>((option, i) => {
    const data = props.responses[option.base.id];
    return {
      color: typeof colors === 'function' ? colors(option) : colors[option.ordinal - 1],
      count: data.userIds.length,
      id: option.base.id,
      ordinal: option.ordinal,
      name: parseSurveyRichText(option.value),
      percent: Math.round(data.pct),
      [option.base.id]: data.userIds.length || emptyValue,
    };
  });

  const formatLabel = useCallback((data: ComputedDatum<OptionsBarData>) => {
    if (data.value === emptyValue) return '';

    const responseData = props.responses[data.data.id];

    const int = parseInt(`${Math.round(responseData.pct)}`);
    return int < 3
      ? ''
      : `${int}%`;
  }, [emptyValue, props.responses]);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const formatLeftAxisLabel = useCallback((value: string) => {
    const item = props.question.options.find(o => o.base.id === +value);
    if (!item) return null;
    return trunc(parseSurveyRichText(item.value), 10);
  }, [
    props.question,
  ]);

  const renderTooltip = useCallback((item: BarTooltipProps<OptionsBarData>) => {
    return (
      <OptionsBarChartTooltip
        count={item.data.count || 0}
        name={item.data.name}
        percent={item.data.percent} />
    );
  }, []);

  const keys = useMemo(() => items.map(x => x.id.toString()), [items]);

  const maxValue = useMemo(() => {
    const max = Math.max(...items.map(x => x[x.id] as number));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [items]);

  return (
    <BarChart
      data={items}
      keys={keys}
      minValue={0}
      maxValue={maxValue}
      axisBottom={{
        format: formatBottomAxisLabel,
      }}
      axisLeft={{
        format: formatLeftAxisLabel,
      }}
      margin={{ top: 0, right: 25, bottom: 30, left: 75 }}
      onClick={props.onClick}
      label={formatLabel}
      tooltip={renderTooltip}
      theme={theme} />
  );
};

export { ResponseOptionsChart };
export default ResponseOptionsChart;