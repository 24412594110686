import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { maxLengths } from '@consts';
import type { ProfileEntry } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import { TextareaCharacterLimit } from '@/components/Textarea';
import type { Section } from './interfaces';
import styles from './style/Profile.Summary.css';

type Props =
  Section.SingleEntry.FormProps<ProfileEntry.Bio>;

export const ProfileSummaryForm = ({ onSave, ...props }: Props) => {
  const [value, setValue] = useState(props.value?.summary || '');

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    return onSave({
      summary: value,
    });
  }, [
    onSave,
    value,
  ]);

  const canSave = useMemo(() => {
    return value.length <= maxLengths.BIO && value.length;
  }, [value.length]);

  return (
    <>
      <div className={styles.textarea}>
        <TextareaCharacterLimit
          className={styles.input}
          autoFocus
          name="summary"
          onChange={handleChange}
          value={value}
          maxLength={maxLengths.BIO} />
      </div>
      <FormButtons
        disabled={!canSave}
        onSubmit={handleSave}
        onCancel={props.onCancel} />
    </>
  );
};

export default ProfileSummaryForm;