import { useCallback, useContext } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useHelpers, useRemirrorContext } from '@remirror/react';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { ArticleFormContext, ArticleCoverImageContext, ArticleIdentifierContext } from '@containers/Post.Article.Form/Context';
import { usePublishArticlePostMutation } from '@containers/Post.Article.Form/hooks/usePublishArticlePostMutation';
import { useUploadCoverImageMutation } from '@containers/Post.Article.Form/hooks/useUploadCoverImageMutation';
import { useUploadImageObjectsMutation } from '@containers/Post.Article.Form/hooks/useUploadImageObjectsMutation';
import { useTopicTagsContext } from '@containers/PostCreation/hooks';
import { trunc } from '@utils';
import { useGetText } from '@/components/Remirror/hooks';
import type { ImageExtension } from '@/components/Remirror';

namespace Mutation {
  export type Options =
    UseMutationOptions<API.Posts.Articles.Publish.Response>;
}

export const useSubmitArticlePostMutation = (options: Mutation.Options = {}) => {
  const uploadArticleImageContent = useUploadArticleImageContent();
  const publishArticlePost = usePublishArticlePost();

  const mutation = useMutation({ mutationKey: [QK.Posts.Articles.$.Publish], mutationFn: async () => {
    const html = await uploadArticleImageContent();
    const article = await publishArticlePost({ html });

    return article;
  }, ...options });

  return mutation;
};

namespace PublishArticlePost {
  export type Params = {
    html: string;
  };
}

const usePublishArticlePost = () => {
  const identifier = useContext(ArticleIdentifierContext);
  const { title } = useContext(ArticleFormContext);
  const getText = useGetText();
  const mutation = usePublishArticlePostMutation();
  const [topic] = useTopicTagsContext();

  const handleSubmit = useCallback(({ html }: PublishArticlePost.Params) => {
    const text = getText();
    const excerpt = text.length > 200
        ? trunc(text, 200)
        : text;

    return mutation.mutateAsync({
      content: {
        html,
        text,
      },
      excerpt,
      identifier,
      title,
      topics: topic.items.map(x => x.id),
    });
  }, [
    getText,
    identifier,
    mutation,
    title,
    topic.items,
  ]);

  return handleSubmit;
};

const useUploadArticleImageContent = () => {
  const { getHTML } = useHelpers();
  const submitImageAttachments = useUploadImageAttachments();
  const submitCoverImage = useUploadCoverImage();

  const handleSubmit = useCallback(async () => {
    await Promise.all([
      submitImageAttachments(),
      submitCoverImage(),
    ]);

    return getHTML();
  }, [
    getHTML,
    submitCoverImage,
    submitImageAttachments,
  ]);

  return handleSubmit;
};

const useUploadImageAttachments = () => {
  const helpers = useHelpers<ImageExtension>();
  const { onSubmitImageContent } = useContext(ArticleFormContext);
  const identifier = useContext(ArticleIdentifierContext);
  const { setContent } = useRemirrorContext();
  const mutation = useUploadImageObjectsMutation();

  const handleSubmit = useCallback(async () => {
    const json = await onSubmitImageContent({ helpers }, images => {
      return mutation.mutateAsync({
        identifier,
        images,
      });
    });

    setContent(json);

  }, [
    helpers,
    identifier,
    mutation,
    onSubmitImageContent,
    setContent,
  ]);

  return handleSubmit;

};

const useUploadCoverImage = () => {
  const [image] = useContext(ArticleCoverImageContext);
  const identifier = useContext(ArticleIdentifierContext);
  const mutation = useUploadCoverImageMutation();

  const handleSubmit = useCallback(async () => {
    if (!image) return;

    await mutation.mutateAsync({
      blob: image.blob,
      identifier,
    });

  }, [
    identifier,
    image,
    mutation,
  ]);

  return handleSubmit;
};