import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/GridColumnIndicator.css';

export type GridColumnIndicatorProps = {
  className?: string;
  values: React.ReactNode[];
};

export const GridColumnIndicator = memo((props: GridColumnIndicatorProps) => {

  return (
    <div className={cx(styles.root, props.className)}>
      {props.values.map((v, i) => {
        const className = cx(styles.item, {
          [styles.first]: i === 0,
          [styles.last]: i === props.values.length - 1,
        });
        return (
          <div
            key={i}
            className={className}
            style={{ width: `${100 / props.values.length}%` }}>
            <div className={styles.itemWrap}>
              <div className={styles.value}>{v}</div>
            </div>
            <div className={styles.tick} />
            <div className={styles.border} />
          </div>
        );
      })}
    </div>
  );
});

export default GridColumnIndicator;