import { ButtonOutlined } from '@presentation';
import { cx } from '@utils';
import type { Topics } from '@/types';
import styles from './style/Following.Item.css';

type Props<T extends Topics.Following = Topics.Following> = {
  className?: string;
  classes?: {
    root?:      string;
    following?: string;
  };
  following: boolean;
  item?:     T;
  onClick:   (item?: T) => void;
};

export const Button = ({ classes = {}, ...props }: Props) => {
  const following = cx(styles.unfollow, classes.following);

  const classnames = cx(
    styles.btn,
    props.className,
    classes.root,
    { [following]: props.following },
  );

  return (
    <ButtonOutlined
      borderWidth="thin"
      className={classnames}
      color="blue"
      fontWeight="semibold"
      onClick={() => props.onClick(props.item)}
      variant="rounded">
      {props.following
          ? 'Unfollow'
          : 'Follow'}
    </ButtonOutlined>
  );
};

Button.displayName = 'Following.Button';