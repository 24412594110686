import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyQuestionConditionType, SurveyConditionType, SurveyLogicRule, SurveyActionType } from '@enums';
import type { SurveyLogic, SurveyQuestionMatrixRow } from '@/types';
import { getOperatorText } from '@utils/operator';
import { SurveyAction } from '@/components/SurveyBuilder.Logic/Preview.Action';
import { SurveyMetadata } from '../Metadata';

export const useGetSlidersRowLogic = (questionLogic: SurveyLogic.Item[]) => {
  return useCallback((row: SurveyQuestionMatrixRow) => {
    const filteredLogic = questionLogic
    .filter(f => f.rule.type !== SurveyLogicRule.CustomExpression)
    .reduce((acc, x) => {
      if (x.conditions.length > 1) return acc;
      if (x.conditions[0].conditionType !== SurveyConditionType.Question) return acc;

      const condition = x.conditions[0].data as SurveyLogic.QuestionTypeConditionData<SurveyQuestionType.Sliders>;

      if (condition.type === SurveyQuestionConditionType.SliderValue) {
        if (condition.value.row.identifier === row.base.identifier) {
          return acc.concat({
            action: x.action,
            condition: condition,
          });
        }
      }

      return acc;
    }, [] as {
      action: SurveyLogic.Action;
      condition: SurveyLogic.SliderValueCondition;
    }[]);

    return filteredLogic.map((m, i) => {

      const text = ` if answer is ${getOperatorText(m.condition.value.operator)} ${m.condition.value.comparate}`;

      const Item = (
        <>
          <SurveyAction action={m.action} />
          {text}
        </>
      );

      return (
        <SurveyMetadata
          key={i}
          items={[Item]}
          classes={{ red: m.action.type === SurveyActionType.Disqualification }} />
      );
    });
  }, [questionLogic]);
};