import http from '@services/http';
import type { Members } from './interfaces';

export const match = (data: Members.ImportMatch.Request) => {
  const form = new FormData();

  form.append('users', data.file, data.file.name);

  return http.post<Members.ImportMatch.Response>(`/admin/members/import/match`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};