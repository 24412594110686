import { useContext } from 'react';
import { ProjectPipelineQueryContext, ProjectPipelineQueryParamsContext } from '@containers/Group.Project.Pipeline/Context';
import { useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import * as Table from '@/components/Table';
import { RespondentsTable } from './Respondents.Table';
import type { RespondentItem } from './interfaces';
import styles from './style/Project.Pipeline.css';

type Props = unknown;

export const ProjectPipeline = (props: Props) => {
  const query = useContext(ProjectPipelineQueryContext);
  const [qp, setQuery] = useContext(ProjectPipelineQueryParamsContext);

  const pagination = {
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  };

  const loading = query.isInitialLoading && !query.data;

  const data = loading
    ? getLazyTableData(25)
    : query.data?.items ?? [];

  const empty = query.isFetchedAfterMount && !loading && !data.length;

  const suspended = useSuspendInitialLoading({
    data,
    empty,
    isInitialLoading: query.isInitialLoading,
    loading,
    pagination,
  }, 500);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <Table.Layout.Box>
          <Table.Layout.Header title="Respondent Pipeline" />

          <RespondentsTable
            data={suspended.data}
            empty={suspended.empty}
            loading={suspended.loading}
            pagination={suspended.pagination}
            projectType={query.data?.project?.projectType}
            query={qp}
            setQuery={setQuery} />
        </Table.Layout.Box>
      </div>
    </div>
  );
};

ProjectPipeline.displayName = 'Project.Pipeline';

const getLazyTableData = <T extends RespondentItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};