import { setHours, setMinutes } from 'date-fns';
import { format } from 'date-fns/fp';
import { segmentRange } from '@containers/Calendar/utils';
import type { AvailableTimeslot, TimeslotItem, TimeslotProps } from './interfaces';

export const excludeTimes = () => {
  return segmentRange({
    start: setHours(setMinutes(new Date(), 30), 0),
    end: setHours(setMinutes(new Date(), 30), 6),
  }).map(x => x.start);
};

export const keyToReadable = (key: string) => {
  const [ year, month, day ] = key.split('-');
  const date = new Date(+year, +month - 1, +day);
  const f = format('eee, LLL do yyyy');

  return f(date);
};

export const reconcileChanges = (info: TimeslotProps<AvailableTimeslot>, items: TimeslotItem[]) => {
  const isAvailable = (e: TimeslotItem): e is AvailableTimeslot => !e.isBusy;
  const availabilityId = info.item.metadata.availabilityId;

  return items.filter(x => isAvailable(x)
       ? x.metadata.availabilityId === availabilityId
         && x.start.toISOString() !== info.item.start.toISOString()
       : false);
};

export * from './useCalendarEvents';