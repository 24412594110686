import { useCallback } from 'react';
import { AddQuestionButton } from '@presentation';
import { EditableSurveyState } from '@containers/SurveyBuilder/EditableSurveyState';
import { PostCallSurveyBuilderState } from '@containers/SurveyBuilder/PostCallSurveyBuilderState';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { useAddSurveyQuestion } from '@/containers/SurveyBuilder';
import { EditableSurvey } from '@/scenes/surveys/EditableSurvey/EditableSurvey';
import { SurveyQuestionsBuilder } from '@/components/SurveyBuilder/QuestionsBuilder';
import type { PostCallSurveyProps } from './interfaces';
import styles from './style.css';

const Content = () => {
  const [state] = useSurveyBuilderState();
  const section = state.survey.sections[0];
  const addQuestion = useAddSurveyQuestion(section.identifier)(1);

  if (!state.survey.questions.length) {
    return (
      <>
        <div className={styles.placeholder}>
          {`No additional post call questions required`}
        </div>
        <AddQuestionButton
          onClick={addQuestion} />
      </>
    );
  }

  return (
    <SurveyQuestionsBuilder />
  );
};

const helpText = `Researchers will be prompted to provide answers to the following questions after calls are completed.`;
const title = `Post Call Questions`;

export const EditablePostCallSurvey = (props: PostCallSurveyProps) => {

  const renderContent = useCallback(() => {
    return <Content />;
  }, []);

  return (
    <PostCallSurveyBuilderState>
      <EditableSurveyState
        onDelete={props.onDelete}
        onFetch={props.onFetch}
        onSave={props.onSave}>
        <EditableSurvey
          helpText={helpText}
          renderContent={renderContent}
          title={title} />
      </EditableSurveyState>
    </PostCallSurveyBuilderState>
  );
};

export default EditablePostCallSurvey;