import { memo } from 'react';
import { PlayCircle } from 'react-feather';
import type { FileIconProps } from './interfaces';

export const AudioIcon = memo((props: FileIconProps) => {

  return (
    <PlayCircle
      color="var(--pri-02)"
      {...props} />
  );
});

export default AudioIcon;