import { useCallback, useContext } from 'react';
import { MedicalBackgroundPracticeContext } from '@containers/MedicalOnboarding/Context';
import type { OnChangeValue } from './MatrixSlider';
import { MatrixSlider } from './MatrixSlider';

type Props = unknown;

export const PracticeTime = (props: Props) => {
  const practice = useContext(MedicalBackgroundPracticeContext);
  const { answer, question } = practice.form.practiceTime;

  const setValue = useCallback((value: OnChangeValue) => {
    practice.setValue({
      practiceTime: value,
    });
  }, [practice]);

  return (
    <MatrixSlider
      answer={answer}
      onChange={setValue}
      question={question} />
  );
};

PracticeTime.displayName = 'MedicalProfile.Field.PracticeTime';