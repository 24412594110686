import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { cx } from '@utils';
import { Button } from '@/components/Button';
import { useSignupModal } from '$website/components';
import { useLoginModal } from '$website/components/AuthModal/useLoginModal';
import styles from './style/Landing.css';

type Props = unknown;

export const ButtonSet = (props: Props) => {
  const [l, setLoginModalOpen] = useLoginModal();
  const [_, setSignupModalOpen] = useSignupModal();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  const btn = {
    login: {
      style: state.data.theming ? {
        backgroundColor: `var(--pri-01)`,
        borderColor: state.data.theming.palette.primary.main,
        color: state.data.theming.palette.primary.main,
      } : {},
    },
    signup: {
      style: state.data.theming ? {
        backgroundColor: state.data.theming.palette.primary.main,
        borderColor: state.data.theming.palette.primary.main,
        color: state.data.theming.palette.primary.text,
      } : {},
    },
  };

  return (
    <div className={cx(styles.row, styles.btns)}>
      {!state.data?.project?.createdFor &&
        <Button
          className={styles.btn}
          color="primary"
          onClick={() => setSignupModalOpen(true)}
          variant="pill"
          style={btn.signup.style}>
          Sign Up
        </Button>
      }
      <Button
        className={styles.btn}
        color="secondary"
        onClick={() => setLoginModalOpen(true)}
        variant="pill"
        style={btn.login.style}>
        Log In
      </Button>
    </div>
  );
};

ButtonSet.displayName = 'ProjectInvite.Landing.ButtonSet';