import { createConditional } from '@/containers/Conditional';
import styles from '@/access/admin/Accounts/Details/style.css';
import type { SelectBaseProps } from '@/components/Select';
import { Select } from '@/components/Select';

type Props<T> = {
  title: string;
  isEditing: boolean;
} & SelectBaseProps<T>;

export const InlineSelect = <T, >({ title, isEditing, ...props }: Props<T>) => {
  const DisplayValue = () => {
    return (<div>{props.value}</div>);
  };
  const $Form = createConditional(isEditing, DisplayValue);

  return (
    <div>
      <div className={styles.label}>{title}</div>
      <$Form>
        <Select {...props} />
      </$Form>
    </div>
  );
};