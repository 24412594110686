import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as api from '@api';
import { MessageReviewDispatchContext } from '@containers/ComplianceReview/Context';
import type { PlatformMessage } from '@/types';

const mapState = (state: Store.State) => state.group;

const useMessageApprovalActions = () => {
  const dispatch = useContext(MessageReviewDispatchContext);

  const group = useSelector(mapState);

  const messageUpdated = useCallback((item: Pick<PlatformMessage, 'message'>) => {

    dispatch({
      data: item,
      type: 'update',
    });

    return item;

  }, [dispatch]);

  const approve = useCallback((id: number) => {

    return api.groups.compliance.setMessageApprovalApproved({
      groupId: group.id,
      messageId: id,
    })
    .then(messageUpdated);

  }, [
    group.id,
    messageUpdated,
  ]);

  const reject = useCallback((id: number) => {

    return api.groups.compliance.setMessageApprovalRejected({
      groupId: group.id,
      messageId: id,
    })
    .then(messageUpdated);

  }, [
    group.id,
    messageUpdated,
  ]);

  return {
    approve,
    reject,
  };

};

export { useMessageApprovalActions };
export default useMessageApprovalActions;