import { Component } from 'react';
import { connect } from 'react-redux';
import { FileText } from 'react-feather';
import { SettingsTitle } from '@/components/Settings';
import * as api from '@services/api';
import * as actions from '@store/actions';
import * as enums from '@enums';
import AutoComplete from '@/components/AutoComplete';
import ExportComplianceReport from '../ExportComplianceReport';
import GroupControls from '../GroupControls';
import ReviewControls from '../ReviewControls';
import { SectionHeaderButtons } from '../SectionHeader';
import styles from './style.css';

const mapState = state => ({
  group: state.group,
});

const mapDispatch = {
  updateGroupSettings: actions.updateGroupSettings,
};

class ComplianceSettings extends Component {
  state = {
    activeCompanyRow: null,
    blacklistedCompanies: [],
    companyList: [],
    editingBlacklist: false,
    originalBlacklistedCompanies: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const blacklists = await api.group.getGroupBlacklistedCompanies({ groupId: this.props.group.id });

    const companies = blacklists.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({
      blacklistedCompanies: companies,
      originalBlacklistedCompanies: companies,
    });
  };

  fetchCompanyAutocomplete = text => {
    api.search.getAutocompleteResults({
      type: enums.SearchType.Company,
      text,
      ignoreList: this.state.blacklistedCompanies.map(m => `${m.id}_company`),
    })
      .then(data => {
        this.setState({
          companyList: data,
        });
      });
  };

  handleBlacklistSave = () => {
    const adds = this.state.blacklistedCompanies
      .filter(f => !this.state.originalBlacklistedCompanies.some(s => s.id == f.id))
      .map(m => ({ companyId: m.id, groupId: this.props.group.id }));
    const deletes = this.state.originalBlacklistedCompanies
      .filter(f => !this.state.blacklistedCompanies.some(s => s.id == f.id))
      .map(m => ({ companyId: m.id, groupId: this.props.group.id }));

    api.group.updateGroupCompanyBlacklist({
      adds,
      deletes,
      groupId: this.props.group.id,
    })
    .then(d => {
      const sorted = this.state.blacklistedCompanies.sort((a, b) => a.name.localeCompare(b.name));

      this.setState({
        originalBlacklistedCompanies: sorted,
        blacklistedCompanies: sorted,
        editingBlacklist: false,
      });
    });
  };

  handleCompanyChange = e => {
    const input = e.target.value;

    if (!input) {
      return this.setState({ companyList: [] });
    }

    this.fetchCompanyAutocomplete(e.target.value);
  };

  handleSwitchClick = name => active => {
    this.setState({ [name]: active });

    api.groups.updateGroupSettings({
      groupId: this.props.group.id,
      [name]: active,
    })
    .then(settings => {
      this.props.updateGroupSettings({
        settings,
      });
    });
  };

  getDropdownObject = m => {
    return {
      num: m,
      text: `${m.toString()} Months`,
    };
  };

  handleCompanySelected = c => {
    this.setState({
      companyList: [],
      blacklistedCompanies: this.state.blacklistedCompanies.concat({ id: c.id, name: c.name } || []),
    });
  };

  handleCompanyRemoved = id => () => {
    if (this.state.editingBlacklist) {
      this.setState({
        blacklistedCompanies: this.state.blacklistedCompanies.filter(f => f.id !== id),
      });
    }
  };

  toggleActiveCompanyRow = id => () => {
    if (this.state.editingBlacklist) {
      this.setState({
        activeCompanyRow: id,
      });
    }
  };

  toggleBlacklistEdit = () => {
    this.setState({
      editingBlacklist: !this.state.editingBlacklist,
    });
  };

  revertBlacklistEdit = () => {
    this.setState({
      blacklistedCompanies: this.state.originalBlacklistedCompanies,
      editingBlacklist: false,
    });
  };

  onExclusionMonthsChange = val => {
    this.setState({ publicCompanyExclusionMonths: val });
  };

  renderAgreement = () => {
    if ([enums.DocumentSigning.NotRequired].includes(this.props.group.features.documentSigning)) {
      return null;
    }

    const tooltipText = [
      `Experts must sign this document before`,
      `participating on calls. To update,`,
      `contact support@sentimentglobal.com`,
    ].join('\n');

    return (
      <>
        <SettingsTitle
          title="Expert Agreement"
          helpText={tooltipText} />
        <div className={styles.info}>
          <div className={styles.leftRow}>
            <div className={styles.paper}>
              <FileText size={25} />
            </div>
            <div className={styles.text}>{this.props.group.document ? this.props.group.document.title : 'N/A'}</div>
          </div>
        </div>
      </>
    );
  };

  renderButtons = () => {
    if (this.state.editingBlacklist) {
      return (
        <SectionHeaderButtons.Editing
          onCancel={this.revertBlacklistEdit}
          onSubmit={this.handleBlacklistSave} />
      );
    }

    return <SectionHeaderButtons.Edit onClick={this.toggleBlacklistEdit} />;
  };

  renderBlacklist = () => {
    const tooltipText = [`Experts with these companies in their profile`,
      `will be flagged for review and excluded from searches`,
    ].join('\n');

    return (
      <>
        <SettingsTitle
          title="Flagged Companies"
          helpText={tooltipText}>
          {this.renderButtons()}
        </SettingsTitle>
        <div className={styles.info}>
          { !this.state.editingBlacklist && this.state.blacklistedCompanies.length === 0 ?
            <div className={styles.text}>No flagged companies</div> : null
          }
          { this.state.editingBlacklist ?
            <div className={styles.autocomplete}>
              <AutoComplete.Tags
                onChange={this.handleCompanyChange}
                onSelect={this.handleCompanySelected}
                items={this.state.companyList}
                getItemValue={item => item.name}
                placeholder={'Select a company'} />
            </div> : null
          }
          <div className={styles.companyContainer}>
            {
              this.state.blacklistedCompanies.map(m => {
                return (
                  <div
                    onMouseEnter={this.toggleActiveCompanyRow(m.id)}
                    onMouseLeave={this.toggleActiveCompanyRow(null)}
                    className={styles.companyRow}
                    key={m.id}>
                    <div className={this.state.editingBlacklist ? styles.editCompany : styles.company}>
                      <div>{m.name}</div>
                      {this.state.activeCompanyRow === m.id ?
                        <div
                          className={styles.remove}
                          onClick={this.handleCompanyRemoved(m.id)}>
                          Remove
                        </div> : null }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className={styles.content}>
        <GroupControls />
        <ExportComplianceReport />
        {this.renderAgreement()}
        <ReviewControls
          settings={this.props.group.settings}
          features={this.props.group.features}
          groupId={this.props.group.id} />
        {this.renderBlacklist()}
      </div>
    );
  }
}

export default connect(mapState, mapDispatch)(ComplianceSettings);