import type { ProjectFrequency } from '@enums';
import { ProjectFrequencyDropdown } from '@/components/GroupProject';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props = {
  label:    string;
  tooltip:  string;
  onChange: (value: ProjectFrequency) => unknown;
  value:    ProjectFrequency;
};

export const Frequency = (props: Props) => {

  return (
    <Field
      label={props.label}
      tooltip={props.tooltip}>
      <div className={styles.type}>
        <ProjectFrequencyDropdown
          onSelect={props.onChange}
          value={props.value} />
      </div>
    </Field>
  );
};

Frequency.displayName = 'Project.Details.Field.Frequency';