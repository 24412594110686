import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import type { Routing } from '@containers/Routing';
import { Messages } from '@screens/Messages';
import { hasChatAccess } from '@utils';

const routes: Routing.Route[] = [
  {
    component: Messages,
    path: path.Messages.Root,
  },
];

const validator = (state: Store.State) => {
  const hasAccess = hasChatAccess(state.user, state.group);

  return hasAccess
    ? routes
    : [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Messages.Root,
});