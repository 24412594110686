import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UniversalSearch } from '@consts/path';
import { HeroSearchBar } from '@presentation/HeroSearchBar';
import { qs, hash64 } from '@utils';
import { useMutatePastUniversalSearch } from '@utils/api';
import type { IUniversalSearch } from '@/types';
import { PopupContext, KeyboardContext, QueryContext, ResetContext } from './Context';

export const Form = () => {
  const history = useHistory();
  const popup = useContext(PopupContext);
  const { query, setQuery, barRefs } = useContext(QueryContext);
  const { index } = useContext(KeyboardContext);
  const { clickedResultReset } = useContext(ResetContext);
  const pastSearches = useMutatePastUniversalSearch();

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (index !== -1) return;

    if (query.length) {
      const item = keywordItem(query);
      pastSearches.mutate({
        query,
        entityId: item.id,
        item,
      });
      history.push(`/${item.path}`);
    }

    clickedResultReset();
  }, [index, query, clickedResultReset, pastSearches, history]);

  const handleQueryFocus = useCallback(() => {
    if (!popup.isOpen) {
      popup.setOpen(true);
    }
  }, [popup]);

  const handleQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!popup.isOpen) {
      popup.setOpen(true);
    }
    setQuery(e.target.value);
  }, [setQuery, popup]);

  return (
    <HeroSearchBar
      ref={barRefs}
      query={query}
      onChange={handleQueryChange}
      onFocus={handleQueryFocus}
      onSubmit={handleSubmit} />
  );
};

function keywordItem(query: string): IUniversalSearch.SearchBarResultKeyword {
  return {
    id: hash64(`keyword:${query}`),
    name: query,
    path: `${UniversalSearch.Root.substring(1)}?${qs.stringify({ q: query })}`,
    type: 'keyword',
  };
}

export default Form;