import type { MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import type { Point } from '@/types';
import type { TooltipAnchor, TooltipState } from '../interfaces';

const DefaultValue: TooltipState = {
  anchor: 'top',
  visible: false,
  content: null,
  position: null,
};

export const useTooltipHandlers = () => {
  const [state, setState] = useState<TooltipState>(DefaultValue);

  const showTooltipAt = useCallback((content: React.ReactNode, position: Point, anchor?: TooltipAnchor) => {
    setState({
      anchor,
      visible: true,
      position,
      content,
    });
  }, []);

  const showTooltipFromEvent = useCallback(
    (content: React.ReactNode, event: MouseEvent, anchor?: TooltipAnchor) => {
      const x = event.pageX;
      const y = event.pageY;

      setState({
        visible: true,
        position: { x, y },
        anchor,
        content,
      });
    },
    [setState]
  );

  const hideTooltip = useCallback(() => {
    setState(DefaultValue);
  }, []);

  return {
    ...state,
    hideTooltip,
    showTooltipAt,
    showTooltipFromEvent,
  } as const;
};

export default useTooltipHandlers;