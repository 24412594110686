import { useCallback, useMemo, useState } from 'react';
import { MinusCircle } from 'react-feather';
import cuid from 'cuid';
import { SurveyQuestionType, SurveySumConditionItemType } from '@enums';
import { GrayOutlineButton } from '@presentation/Buttons';
import { AddQuestionButton, QuestionDropdown, RowDropdown, OptionsDropdown } from '@presentation/SurveyBuilder';
import type { Validation } from '@containers/SurveyBuilder.Logic/interfaces.validation';
import type { SurveyLogic, SurveyQuestion } from '@/types';
import { useParseSurveyRichText, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { AddItemButton } from '@/presentation/AddItemButton';
import { getDefaultItemValue, getDefaultNumberTableItemValue, getDefaultSlidersItemValue, validateSumConditionItems } from '@/containers/SurveyBuilder.Logic';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { LogicValidation } from './Builder.Validation';
import styles from './style/Builder.Condition.Sum.Modal.css';

type Props = {
  initial: SurveyLogic.SumConditionItem[];
  onSubmit: (value: SurveyLogic.SumConditionItem[]) => void;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

// TODO: validations around adding values
// TODO: move some logic into a container

function resolveInitialState(initial: SurveyLogic.SumConditionItem[]) {
  if (initial.length) return initial;
  return [{
    identifier: cuid(),
    question: { identifier: '' },
    type: null,
    values: [],
  }];
}

export const SumConditionModal = ({ initial, onClose, onSubmit, open }: Props) => {

  const [items, setItems] = useState<SurveyLogic.SumConditionItem[]>(resolveInitialState(initial));
  const [validation, setValidation] = useState<Validation>({ success: true });

  const [state] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  const questionDropdownItems = useMemo(() => {
    return state.survey.questions
      .filter(f => [SurveyQuestionType.Sliders, SurveyQuestionType.NumberInputTable].includes(f.typeId))
      .map(m => ({
        base: m.base,
        ordinal: m.ordinal,
        value: getQuestionText(m.value),
      }));
  }, [getQuestionText, state.survey.questions]);

  const unusedQuestionDropdownItems = useMemo(() => {
    return questionDropdownItems
    .filter(f => !items.some(s => s.question.identifier === f.base.identifier));
  }, [questionDropdownItems, items]);

  const handleSubmit = useCallback(() => {

    const valid = validateSumConditionItems(items);

    if (!valid.success) {
      setValidation(valid);
      return;
    }

    onSubmit(items);
    onClose();
  }, [items, onClose, onSubmit]);

  const addItem = useCallback(() => {
    setItems(s => s.concat({
      identifier: cuid(),
      question: { identifier: '' },
      type: null,
      values: [],
    }));
  }, []);

  const addItemValue = useCallback((itemIdentifier: string) => () => {

    setItems(items => {
      return items.reduce((acc, x) => {
        if (x.identifier === itemIdentifier) {
          if (x.type === SurveySumConditionItemType.NumberTableCellValue) {
            return acc.concat({
              ...x,
              values: x.values.concat(getDefaultNumberTableItemValue()),
            });
          } else if (x.type === SurveySumConditionItemType.SliderRowValue) {
            return acc.concat({
              ...x,
              values: x.values.concat(getDefaultSlidersItemValue()),
            });
          }
        }
        return acc.concat(x);
      }, []);
    });
  }, []);

  const setItemQuestion = useCallback((itemIdentifier: string) => (questionIdentifier: string) => {
    const question = state.survey.questions.find(f => f.base.identifier === questionIdentifier);
    const type = getItemType(question.typeId);

    setItems(items => {
      return items.reduce((acc, x) => {
        if (x.identifier === itemIdentifier) {
          return acc.concat({
            identifier: x.identifier,
            type,
            question: { identifier: questionIdentifier },
            values: [getDefaultItemValue(type)],
          });
        }
        return acc.concat(x);
      }, []);
    });
  }, [state.survey.questions]);

  const setValueOption = useCallback((itemIdentifier: string, valueIdentifier: string) => (optionIdentifier: string) => {
    setItems(items => {
      return items.reduce((acc, x) => {
        if (x.identifier === itemIdentifier) {
          const values = x.values.map((v: SurveyLogic.SumConditionNumberTableItemValue) => ({
            ...v,
            option: v.identifier === valueIdentifier
              ? { identifier: optionIdentifier }
              : v.option,
          }));
          return acc.concat({
            ...x,
            values,
          });
        }

        return acc.concat(x);
      }, []);
    });
  }, []);

  const setValueRow = useCallback((itemIdentifier: string, valueIdentifier: string) => (rowIdentifier: string) => {
    setItems(items => {
      return items.reduce((acc, x) => {
        if (x.identifier === itemIdentifier) {
          const values = x.values.map((v: SurveyLogic.SumConditionSlidersQuestionItemValue) => ({
            ...v,
            row: v.identifier === valueIdentifier
              ? { identifier: rowIdentifier }
              : v.row,
          }));
          return acc.concat({
            ...x,
            values,
          });
        }

        return acc.concat(x);
      }, []);
    });
  }, []);

  const removeItem = useCallback((itemIdentifier: string) => () => {
    setItems(items => {
      return items.filter(f => f.identifier !== itemIdentifier);
    });
  }, []);

  const removeItemValue = useCallback((itemIdentifier: string, valueIdentifier: string) => () => {
    setItems(items => {
      return items.map(item => {
        if (item.identifier !== itemIdentifier) return item;
        const values = item.values.filter(f => f.identifier !== valueIdentifier);
        const newValue = { ...item, values } as SurveyLogic.SumConditionItem;
        return newValue;
      });
    });
  }, []);

  const renderNumberTableItem = useCallback((item: SurveyLogic.SumConditionItem<SurveySumConditionItemType.NumberTableCellValue>) => {
    const question = state.survey.questions.find(f => f.base.identifier === item.question.identifier) as SurveyQuestion<SurveyQuestionType.NumberInputTable>;

    return (
      <>
        {item.values.map(value => {
          const selectedRow = question.matrixRows.find(f => f.base.identifier === value.row.identifier);
          const selectedOption = question.options.find(f => f.base.identifier === value.option.identifier);

          return (
            <div className={styles.valueRow} key={value.identifier}>
              <div className={styles.rowsDropdown}>
                <RowDropdown
                  items={question.matrixRows}
                  onSelect={setValueRow(item.identifier, value.identifier)}
                  value={selectedRow} />
              </div>
              <div className={styles.optionsDropdown}>
                <OptionsDropdown
                  items={question.options}
                  onSelect={setValueOption(item.identifier, value.identifier)}
                  value={selectedOption} />
              </div>
              <MinusCircle
                className={styles.remove}
                onClick={removeItemValue(item.identifier, value.identifier)} />
            </div>
          );
        })}
        <AddItemButton
          className={styles.addValue}
          onClick={addItemValue(item.identifier)}
          label="Add value" />
      </>
    );
  }, [
    addItemValue,
    removeItemValue,
    state.survey.questions,
    setValueOption,
    setValueRow,
  ]);

  const renderSlidersItem = useCallback((item: SurveyLogic.SumConditionItem<SurveySumConditionItemType.SliderRowValue>) => {
    const question = state.survey.questions.find(f => f.base.identifier === item.question.identifier) as SurveyQuestion<SurveyQuestionType.Sliders>;

    return (
      <>
        {item.values.map(value => {
          const selectedRow = question.matrixRows.find(f => f.base.identifier === value.row.identifier);

          return (
            <div className={styles.valueRow} key={value.identifier}>
              <div className={styles.rowsDropdown}>
                <RowDropdown
                  items={question.matrixRows}
                  onSelect={setValueRow(item.identifier, value.identifier)}
                  value={selectedRow} />
              </div>
              <MinusCircle
                className={styles.remove}
                onClick={removeItemValue(item.identifier, value.identifier)} />
            </div>
          );
        })}
        <AddItemButton
          className={styles.addValue}
          onClick={addItemValue(item.identifier)}
          label="Add value" />
      </>
    );
  }, [
    addItemValue,
    removeItemValue,
    state.survey.questions,
    setValueRow,
  ]);

  const renderItem = useCallback((item: SurveyLogic.SumConditionItem) => {
    if (item.type === SurveySumConditionItemType.NumberTableCellValue) {
      return renderNumberTableItem(item);
    } else if (item.type === SurveySumConditionItemType.SliderRowValue) {
      return renderSlidersItem(item);
    }

    return null;
  }, [renderNumberTableItem, renderSlidersItem]);

  const canAddQuestion = useMemo(() => {
    return !!unusedQuestionDropdownItems.length &&
      !items.some(s => s.question.identifier === '');
  }, [items, unusedQuestionDropdownItems.length]);

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}>

      <ModalHeader text="Values to sum" />

      <div>
        <div className={styles.items}>
          {items.map(item => {
            const selectedQuestionItem = questionDropdownItems.find(f => f.base.identifier === item.question.identifier);
            const canRemove = items.length > 1;
            return (
              <div className={styles.item} key={item.identifier}>
                <div className={styles.row}>
                  <div className={styles.label}>Question: </div>
                  <div className={styles.questionDropdown}>
                    <QuestionDropdown
                      onSelect={setItemQuestion(item.identifier)}
                      value={selectedQuestionItem}
                      items={unusedQuestionDropdownItems} />
                  </div>
                  {canRemove &&
                    <MinusCircle
                      className={styles.remove}
                      onClick={removeItem(item.identifier)} />}
                </div>
                {selectedQuestionItem &&
                  <div className={styles.values}>
                    {renderItem(item)}
                  </div>
                }
              </div>
            );
          })}
        </div>
        {canAddQuestion &&
          <AddQuestionButton
            className={styles.addQuestion}
            onClick={addItem} />
        }
      </div>
      <LogicValidation value={validation} />

      <div className={styles.footer}>
        <GrayOutlineButton
          className={styles.btn}
          onClick={onClose}>
          Cancel
        </GrayOutlineButton>
        <Button.Primary
          variant="brick"
          onClick={handleSubmit}
          className={styles.btn}>
          Save
        </Button.Primary>
      </div>
    </Modal>
  );
};

export const useSumConditionModal = () => useModal(SumConditionModal);

function getItemType(questionTypeId: SurveyQuestionType) {
  if (questionTypeId === SurveyQuestionType.Sliders) return SurveySumConditionItemType.SliderRowValue;
  else if (questionTypeId === SurveyQuestionType.NumberInputTable) return SurveySumConditionItemType.NumberTableCellValue;
  else return null;
}