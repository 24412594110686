import { useCallback, useContext } from 'react';
import * as PostCreation from '@containers/PostCreation';
import type * as PC from '@containers/PostCreation/interfaces';
import { TopicItemContext, TopicPostsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const PostCreationContainer = (props: Props) => {
  const subscriber = useSubscriber();

  return (
    <PostCreation.Container
      onPublished={subscriber.onPublished}
      onTextSubmit={subscriber.onTextSubmit}
      onVideoSubmit={subscriber.onVideoSubmit}>
      {props.children}
    </PostCreation.Container>
  );
};

PostCreationContainer.displayName = 'Topic.Posts.PostCreation.Container';

const useSubscriber = () => {
  const { query: overview } = useContext(TopicItemContext);
  const { query: posts } = useContext(TopicPostsContext);

  const onPublished = () => {
    setTimeout(() => {
      if (overview.data?.meta?.hasPosts) {
        posts.refetch();
      } else {
        overview.refetch();
      }

    }, 2000);
  };

  const onTextSubmit = <T extends PC.Subscription.OnTextSubmit.Params<PC.PostContentType.Text>>(data: T) => {
    return {
      ...data.post,
      topics: [ ...new Set([...data.post.topics, overview.data.item.id]) ],
    };
  };

  const onVideoSubmit = <T extends PC.Subscription.OnVideoSubmit.Params>(data: T) => {
    return {
      ...data.post,
      topics: [ ...new Set([...data.post.topics, overview.data.item.id]) ],
    };
  };

  return {
    onPublished: useCallback(onPublished, [posts, overview]),
    onTextSubmit: useCallback(onTextSubmit, [overview]),
    onVideoSubmit: useCallback(onVideoSubmit, [overview]),
  };
};