import { useContext } from 'react';
import { MedicalBackgroundEnabledStateContext, MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { Step as MedStep, useMedicalOnboardingStepsSelector } from '@containers/MedicalOnboarding/Steps';
import { Medical } from '@screens/ProjectOnboarding.Completion';
import { CompletionMedicalEnabledStateContext } from './Context';
import type { MedicalStep } from './interfaces';

export const Step = {
  BackgroundAdditionalExpertise: MedStep.BackgroundAdditionalExpertise,
  BackgroundContact: MedStep.BackgroundContact,
  BackgroundLeadership: MedStep.BackgroundLeadership,
  BackgroundPractice: MedStep.BackgroundPractice,
  PromptMedical: `Prompt.Medical` as Extract<MedicalStep, `Prompt.Medical`>,
};

export const StepScreens = {
  [Step.BackgroundAdditionalExpertise]: Medical.AdditionalExpertise,
  [Step.BackgroundContact]: Medical.Contact,
  [Step.BackgroundLeadership]: Medical.Leadership,
  [Step.BackgroundPractice]: Medical.Practice,
  [Step.PromptMedical]: Medical.Prompt,
};

export const useSelectMedicalBackgroundSteps = () => {
  // const background = useContext(MedicalBackgroundEnabledStateContext);
  const ctx = useContext(MedicalExpertiseContext);

  const medicalsteps = useMedicalOnboardingStepsSelector(ctx.userId, steps => {
    // if (background.enabled) return steps.filter(step => [
    //   Step.BackgroundAdditionalExpertise,
    //   Step.BackgroundContact,
    //   Step.BackgroundLeadership,
    //   Step.BackgroundPractice,
    // ].includes(step));

    return [
      Step.BackgroundAdditionalExpertise,
      Step.BackgroundContact,
      Step.BackgroundLeadership,
      Step.BackgroundPractice,
    ];
    // return [];
  });

  const steps = medicalsteps.length
      ? [Step.PromptMedical, ...medicalsteps]
      : medicalsteps;

  return steps as MedicalStep[];
};

export const useMedicalStepperStateSelector = (filter?: StepsFilter) => {
  const { steps } = useContext(CompletionMedicalEnabledStateContext);

  const screens = typeof filter === 'function'
      ? filter(steps).map(step => StepScreens[step])
      : steps.map(step => StepScreens[step]);

  return {
    screens,
    steps,
  };
};

type StepsFilter = (steps: MedicalStep[]) => MedicalStep[];