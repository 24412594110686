import { useState, useEffect } from 'react';
import type { Participant } from 'twilio-video';
import { MAX_PARTICIPANTS_PER_PAGE } from '../constants';

export function usePagination(participants: Participant[]) {
  const [currentPage, setCurrentPage] = useState(1); // Pages are 1 indexed
  const maxGalleryViewParticipants = MAX_PARTICIPANTS_PER_PAGE;

  const totalPages = Math.max(Math.ceil(participants.length / maxGalleryViewParticipants), 1);
  const isBeyondLastPage = currentPage > totalPages;

  useEffect(() => {
    if (isBeyondLastPage) {
      setCurrentPage(totalPages);
    }
  }, [isBeyondLastPage, totalPages]);

  const paginatedParticipants = participants.slice(
    (currentPage - 1) * maxGalleryViewParticipants,
    currentPage * maxGalleryViewParticipants
  );

  return { paginatedParticipants, setCurrentPage, currentPage, totalPages };
}
