import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';

namespace UpdateMutation {
  export type Variables = API.Posts.Articles.Update.Request;

  export type Options =
    UseMutationOptions<
      API.Posts.Articles.Update.Response,
      unknown,
      Variables>;
}

export const useUpdateArticlePostMutation = (options?: UpdateMutation.Options) => {
  const mutation = useMutation({ mutationKey: [QK.Posts.Articles.Put], mutationFn: (data: UpdateMutation.Variables) => {
    return api.posts.articles.update({
      id: data.id,
      content: data.content,
      excerpt: data.excerpt,
      identifier: data.identifier,
      title: data.title,
      topics: data.topics,
    });
  }, ...options });

  return mutation;
};