import type { AnyAction } from '@reduxjs/toolkit';
import { BATCH_ACTIONS, USER_LOGOUT } from '@store/action-types';
import appReducer from '@store/reducers';

const rootReducer = (state: Store.State, action: RootAction) => {
  if (action.type === BATCH_ACTIONS) {
    const batchAction = action as BatchAction;
    return batchAction.actions.reduce(rootReducer, state);
  }

  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

type BatchAction = {
  actions: AnyAction[];
  type: typeof BATCH_ACTIONS;
};

type RootAction =
  BatchAction |
  AnyAction;

export default rootReducer;