import type { LinkOptions } from 'remirror/extensions';
import { BoldExtension, BulletListExtension, HeadingExtension, ItalicExtension, LinkExtension, OrderedListExtension, UnderlineExtension } from 'remirror/extensions';
import { CommandsExtension } from '@remirror/core';
import { useRemirror, Remirror, ThemeProvider } from '@remirror/react';
import { PostArticleFormContainer } from '@containers/Post.Article.Form';
import { PublishButton } from '@/components/Post.Article.Form/Toolbar.Publish';
import { Toolbar } from '@/components/Post.Article.Form/Toolbar';
import type { ImageOptions } from '@/components/Remirror';
import { ImageExtension, LinkContainer } from '@/components/Remirror';
import { useRemirrorStyles } from '@/components/Remirror/hooks';
import { Author } from './Author';
import { CoverImage } from './CoverImage';
import * as Form from './Form';
import styles from './style/PostNew.css';

export const PostNew = (props: unknown) => {

  const linkExtensionParams: LinkOptions = {
    autoLink: true,
  };

  const imageExtensionParams: ImageOptions = {
    enableResizing: true,
  };

  const { manager, state } = useRemirror({
    extensions: () => [
      new CommandsExtension({}),
      new HeadingExtension({}),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ImageExtension(imageExtensionParams),
      new LinkExtension(linkExtensionParams),
    ],
    content: '',
    selection: 'end',
    stringHandler: 'html',
  });

  useRemirrorStyles();

  return (
    <PostArticleFormContainer>
      <ThemeProvider>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <Remirror
                autoFocus
                autoRender="end"
                initialContent={state}
                manager={manager}
                placeholder="Share your insights here.">
                <LinkContainer>
                  <Toolbar>
                    <PublishButton />
                  </Toolbar>
                  <CoverImage />
                  <Form.Title />
                  <Author />
                  <Form.Topics />
                </LinkContainer>
              </Remirror>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </PostArticleFormContainer>
  );
};