import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import type * as Routing from '@routes/interfaces';
import { PostNotFound, PostScreen } from '@screens/Post';
import { PostArticle } from '@screens/Post.Article';
import { PostEdit } from '@screens/Post.Article.Edit';
import { PostNew } from '@screens/Post.Article.New';

const all: Routing.Route[] = [
  {
    component: PostNotFound,
    exact: true,
    path: path.Posts.NotFound,
  },
  {
    component: PostScreen,
    exact: true,
    path: path.Posts.Post,
  },
  {
    component: PostArticle,
    exact: true,
    path: path.Posts.Articles.Post,
  },
];

const auth: Routing.Route[] = [
  {
    component: PostNew,
    exact: true,
    path: path.Posts.Articles.New,
  },
  {
    component: PostEdit,
    exact: true,
    path: path.Posts.Articles.Edit,
  },
];

export const routes = {
  all,
  auth,
};

export const router = {
  all: BasicRouter(routes.all, {
    auth: RouteAuth.Ignore,
    path: path.Posts.Root,
  }),
  auth: BasicRouter(routes.auth, {
    auth: RouteAuth.Authenticated,
    path: routes.auth.map(x => x.path).flat(),
  }),
};