import type { AxiosResponse } from 'axios';
import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import * as $api from '@api/projects.conference';
import type { CallRole } from '@enums';
import type * as API from '$admin/api/interfaces';
import * as $admin from '$admin/api/projects.conference-tags';

type Params = {
  format: 'xlsx' | 'docx';
  tagIds: number[];
  rolesToInclude: CallRole[];
} & IProjectId;

type Data = API.Projects.ExportProjectConferenceTagsSummaries.Response;

type QueryKey = readonly [string, Params];

type Options = UseDownloaderOptions<Data, AxiosResponse, Data, QueryKey>;

export const useExportProjectConferenceTagSummaries = (params: Params, options?: Options) => {

  const isAdminPage = window.location.pathname.startsWith('/admin');

  const queryKey = isAdminPage
    ? AdminProjectConferenceTagsOverviewKey
    : ProjectConferenceTagsOverviewKey;

  return useQueryDownloader({
    queryKey: [queryKey, params],
    queryFn: ({ queryKey }) => {
      const data = queryKey[1];

      return isAdminPage
        ? $admin.exportProjectConferenceTagsSummaries({
          format: data.format,
          projectId: data.projectId,
          tagIds: data.tagIds,
        })
        : $api.exportTagSummaries({
          format: data.format,
          projectId: data.projectId,
          tagIds: data.tagIds,
          rolesToInclude: data.rolesToInclude,
        });
    },
    ...options,
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
  });
};

const AdminProjectConferenceTagsOverviewKey = `get:admin/projects/conference-tags/overview`;
const ProjectConferenceTagsOverviewKey = `get:projects/conference-tags/overview`;