import { Select } from '@/components/Select';
import type { MinimumResponseLengthProps } from './interfaces';

type Props =
  MinimumResponseLengthProps;

const options = [0, 75, 150, 300];

function formatValue(value: number) { return value.toString(); }

export const MinimumResponseLengthSelect = (props: Props) => {

  return (
    <Select<number>
      getItemValue={formatValue}
      onSelect={props.onSelect}
      options={options}
      value={props.value} />
  );
};

export default MinimumResponseLengthSelect;