import { useCallback, useState } from 'react';
import { WorkspaceGlobalSearchBarResultsContext, WorkspaceGlobalSearchBarDispatchContext } from './Context';
import type { WorkspaceGlobalSearchBarResultsContextValue } from './interfaces';

export default function Results({ children }: ChildrenProps) {
  const [state, dispatch] = useState<WorkspaceGlobalSearchBarResultsContextValue>(mergeInitial());

  const clear = useCallback(() => {
    dispatch(mergeInitial());
  }, []);

  return (
    <WorkspaceGlobalSearchBarResultsContext.Provider value={state}>
      <WorkspaceGlobalSearchBarDispatchContext.Provider value={[dispatch, clear]}>
        {children}
      </WorkspaceGlobalSearchBarDispatchContext.Provider>
    </WorkspaceGlobalSearchBarResultsContext.Provider>
  );
}

function mergeInitial(): WorkspaceGlobalSearchBarResultsContextValue {
  return {
    items: [],
  };
}

export { Results };