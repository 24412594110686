import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { OnboardingChecklistItem } from '@enums';
import * as api from '@api/users.onboarding';
import { useSelectUser } from '@/containers/Store';

export const useFetchOnboardingChecklist = (options?: Options) => {

  const me = useSelectUser();

  return useQuery({ queryKey: ['user.onboarding.checklist'], queryFn: () => {
    return api.fetchChecklist({ userId: me.id }).then(data => data.items);
  }, ...options });
};

type Data = OnboardingChecklistItem[];
type Options = UseQueryOptions<Data, AxiosResponse, Data>;