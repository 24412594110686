import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api/scheduling.project';
import type * as Scheduling from '@api/interfaces/scheduling.project';
import * as actions from '@store/actions';
import type { CalendarState } from '@/types';

type Data = Scheduling.CreateCall.Response;
type Variables = CalendarState;
type Params = {
  creatorId:   number;
  projectId:   number;
  scheduleeId: number;
  schedulerId: number;
  userId:      number;
};

const selectCalls = (state: Store.State) => state.calls;

export function useProjectCallInvite(
  data: Params,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) {
  const dispatch = useDispatch();
  const calls = useSelector(selectCalls);

  const onSuccess = useCallback((res: Data, v: Variables, ctx) => {
    const getActionsForCall = (): [ReturnType<typeof actions.projectCallCreated | typeof actions.callUpdated>] => {
      const isUpdating = calls[res.call.id];
      const call = { ...res.call, userId: data.userId };

      return [
        isUpdating
          ? actions.callUpdated({ call })
          : actions.projectCallCreated({ call }),
      ];
    };

    const getActionsForContacts = () => {
      return [
        actions.contactsAdded({ contacts: res.contacts }),
      ];
    };

    const getActionsForPipeline = () => {
      return [
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
      ];
    };

    dispatch(actions.batchActions([
      ...(res.call ? getActionsForCall() : []),
      ...(res.contacts ? getActionsForContacts() : []),
      ...(res.pipeline ? getActionsForPipeline() : []),
    ]));

    options?.onSuccess(res, v, ctx);
  }, [
    calls,
    data.projectId,
    data.userId,
    dispatch,
    options?.onSuccess,
  ]);

  return useMutation({ mutationKey: keyProjectCallInvite(data), mutationFn: params => {

    return api.projectCall.create({
      creator: { id: data.creatorId },
      new: params.new,
      projectId: data.projectId,
      removed: params.removed,
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId,
      user: { id: data.userId },
    });

  }, ...options, onSuccess });
}

export function keyProjectCallInvite(data: Params) {
  return ['project-call-invite', data];
}

export default useProjectCallInvite;