import { Fragment, useCallback, useState } from 'react';
import type { RespondentAnswer, SurveyResponseItem } from '@/types';
import { EditingAnswer } from '@/scenes/surveys';
import {
  EditableProjectSurveyResponseReviewItem,
  ProjectSurveyResponseReviewItem,
} from './EditableQuestionItem';
import type { EditableProjectSurveyProps } from './interfaces';

export const EditableProjectSurveyResponse = ({ incomplete, onSave, survey, ...props }: EditableProjectSurveyProps) => {
  const [editing, setEditing] = useState<number>(null);

  const toggleEditingOff = useCallback(() => {
    setEditing(null);
  }, [setEditing]);

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    setEditing(ordinal);
  }, [setEditing]);

  const handleConfirm = useCallback((questionId: number) => (answer: RespondentAnswer, rationale: string) => {
    onSave({
      questionId,
      responseId: survey.id,
      answer,
      rationale,
    }).then(toggleEditingOff);
  }, [
    onSave,
    survey.id,
    toggleEditingOff,
  ]);

  const renderQuestion = (item: SurveyResponseItem) => {
    if (editing === item.question.ordinal) {
      return (
        <EditingAnswer
          item={item}
          onCancel={toggleEditingOff}
          onConfirm={handleConfirm(item.question.id)} />
      );
    } else if (!editing && !incomplete) {
      return (
        <EditableProjectSurveyResponseReviewItem
          onEdit={toggleEditingOn(item.question.ordinal)}
          item={item}>
          {props.renderQuestionButton && props.renderQuestionButton(item)}
        </EditableProjectSurveyResponseReviewItem>
      );
    } else {
      return (
        <ProjectSurveyResponseReviewItem
          item={item}>
          {props.renderQuestionButton && props.renderQuestionButton(item)}
        </ProjectSurveyResponseReviewItem>
      );
    }
  };
  return (
    <>
      {
        (survey.items || []).map(q => (
          <Fragment key={q.question.id}>
            {renderQuestion(q)}
          </Fragment>
        ))
      }
    </>
  );
};

export default EditableProjectSurveyResponse;