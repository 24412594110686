import { useMemo } from 'react';
import type { SurveyTemplate } from '@/types/survey.templates';
import { SurveyTemplateAutocomplete } from './Input.Autocomplete';
import { SurveyTemplateAutocompleteMulti } from './Input.Autocomplete.Multi';
import styles from './style/Input.MedicalConditions.css';

type Props = {
  onChangePrimary: (value: SurveyTemplate.LinkedEntity<'medical-condition'>) => void;
  onRemoveOther: (id: string) => void;
  onSelectOther: (value: SurveyTemplate.LinkedEntity<'medical-condition'>) => void;
  value: SurveyTemplate.MessageTesting.Conditions;
};

export const TemplateMedicalConditionsInput = ({ onChangePrimary, onRemoveOther, onSelectOther, value }: Props) => {

  const placeholder = 'Enter a medical condition';

  const source = {
    medicalConditions: true,
  };

  return (
    <>
      <div className={styles.label}>Primary Condition</div>
      <div className={styles.input}>
        <SurveyTemplateAutocomplete
          allowCustomSelection={true}
          placeholder={placeholder}
          source={source}
          value={value.primary}
          onChange={onChangePrimary} />
      </div>
      <div className={styles.label}>Secondary Conditions</div>
      <div className={styles.input}>
        <SurveyTemplateAutocompleteMulti
          allowCustomSelection={true}
          placeholder={placeholder}
          values={value.other}
          onRemove={onRemoveOther}
          onSelect={onSelectOther}
          source={source} />
      </div>
    </>
  );
};
