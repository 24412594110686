import { Editable } from './Tag.Editable';
import { Ongoing } from './Tag.Ongoing';
import type { Tagging } from './interfaces';

type Props = {
  className?: string;
  focused:    boolean;
  item:       Tagging.Tag;
} & Pick<Tagging.Context,
    | 'discard'
    | 'remove'
    | 'setFocus'
    | 'setValue'>;

export const Item = (props: Props) => {
  const { identifier, ...item } = props.item;

  if (props.focused) {
    return (
      <Editable
        className={props.className}
        discard={props.discard}
        identifier={identifier}
        item={item}
        remove={props.remove}
        setFocus={props.setFocus}
        setValue={props.setValue} />
    );
  }

  return (
    <Ongoing
      className={props.className}
      discard={props.discard}
      identifier={identifier}
      item={item}
      remove={props.remove}
      setFocus={props.setFocus}
      setValue={props.setValue} />
  );
};

Item.displayName = 'Tagging.Tag.Item';