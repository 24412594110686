import { forwardRef, useCallback, useState } from 'react';
import { useRemoveChartPaletteMutation } from '@containers/Branding/hooks';
import { ThemeGroupContext } from './Context';
import { EditorContainer } from './Palette.Editor.Container';
import { CreateTheme } from './Select.CreateTheme';
import { Input } from './Select.Input';
import { Modal } from './Modal.Editor';
import { Option } from './Select.Option';
import type { Theme as T } from './interfaces';

type Props = {
  category: Descriptor;
  items:    T[];
  onChange: (data: ThemeOnChange.Params) => unknown;
  value:    T['id'];
};

export const ThemeMenu = ({ category, onChange, value, ...props }: Props, ref: React.RefObject<HTMLDivElement>) => {
  const [open, setOpen] = useState(false);
  const [editingId, setEditingId] = useState<T['id']>(null);

  const handleCloseModal = useCallback(() => {
    if (editingId) {
      setEditingId(null);
    }

    setOpen(false);
  }, [editingId]);

  const remove = useRemoveChartPaletteMutation({
    onError: e => {
      console.log('error', e);
    },
  });

  const deletePalette = useCallback((id: T['id']) => {
    remove.mutate({
      paletteId: id,
    });
  }, [remove]);

  const editPalette = useCallback((id: T['id']) => {
    setEditingId(id);

    setOpen(true);
  }, []);

  const setTheme = useCallback((id: T['id']) => {
    if (id !== value) {
      onChange({
        categoryId: category.id,
        paletteId: id,
      });
    }
  }, [
    category.id,
    onChange,
    value,
  ]);

  const context = {
    deletePalette,
    editPalette,
    editingId,
    selected: value,
    setTheme,
    themes: props.items,
  };

  return (
    <ThemeGroupContext.Provider value={context}>
      <Input>
        <div ref={ref}>
          {props.items.map(x =>
            <Option
              editable={x.editable}
              item={x}
              key={x.id} />)}
          <CreateTheme onClick={() => setOpen(true)} />
        </div>
      </Input>

      <EditorContainer>
        <Modal
          categoryId={category.id}
          onClose={handleCloseModal}
          open={open} />
      </EditorContainer>

    </ThemeGroupContext.Provider>
  );
};

ThemeMenu.displayName = 'ColorPalette.Select.Menu';

export const Menu = forwardRef(ThemeMenu);

declare namespace ThemeOnChange {
  export type Params = {
    categoryId: number;
    paletteId:  number;
  };
}