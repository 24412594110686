import { useCallback } from 'react';
import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesState';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import MatrixSliderAggregateData from './MatrixSliderAggregateData';
import MatrixSliderVersionData from './MatrixSliderVersionData';
import MatrixSliderBucketData from './MatrixSliderBucketData';
import styles from './style/Data.css';

export const MatrixSliderData = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.MatrixSlider>();

  const renderContent = useCallback(() => {
    if (query.rowIds !== undefined && query.key) {
      return <MatrixSliderBucketData />;
    } else if (query.projectId) {
      return <MatrixSliderVersionData />;
    } else {
      return <MatrixSliderAggregateData />;
    }
  }, [
    query.key,
    query.projectId,
    query.rowIds,
  ]);

  return (
    <div className={styles.root}>
      {renderContent()}
    </div>
  );
};

export default MatrixSliderData;