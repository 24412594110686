import { useHistory } from 'react-router-dom';
import { SurveyApprovalActions } from '@containers/Review/Survey';
import * as consts from '@consts';
import { AcceptResponseButton, RejectResponseButton } from '@presentation';
import type { ProjectSurveyDetailsExternalUser, ProjectSurveyDetailsInternalUser } from '@/types';
import { Buttons } from '@/scenes/surveys/CompletedSurvey/Buttons';
import type { ApprovalButtonsProps } from './interfaces';

export const ApprovalButtonInternalUser = (props: ApprovalButtonsProps<ProjectSurveyDetailsInternalUser>) => {
  const history = useHistory();
  const navigateHome = () => {
    history.push(consts.pathname.HOME);
  };

  return (
    <Buttons>
      <SurveyApprovalActions
        onChange={navigateHome}
        item={{
          project: {
            id: props.project.id,
            name: props.project.name,
          },
          projectSurveyResponseId: props.project.responseId,
          user: {
            id: props.user.id,
            name: props.user.profile.fullname,
          },
        }}
        approveComponent={(
          <AcceptResponseButton
            onClick={navigateHome} />
        )}
        rejectComponent={(
          <RejectResponseButton
            onClick={navigateHome} />
        )} />
    </Buttons>
  );
};

export const ApprovalButtonExternalUser = (props: ApprovalButtonsProps<ProjectSurveyDetailsExternalUser>) => {
  const history = useHistory();
  const navigateHome = () => {
    history.push(consts.pathname.HOME);
  };

  return (
    <div>ApprovalButtonExternalUser</div>
    // <Buttons>
    //   <SurveyApprovalActions
    //     onChange={navigateHome}
    //     item={{
    //       project: {
    //         id: props.project.id,
    //         name: props.project.name,
    //       },
    //       projectSurveyResponseId: props.project.responseId,
    //       user: {
    //         id: props.user.id,
    //         name: props.user.profile.fullname,
    //       },
    //     }}
    //     approveComponent={(
    //       <AcceptResponseButton
    //         onClick={navigateHome} />
    //     )}
    //     rejectComponent={(
    //       <RejectResponseButton
    //         onClick={navigateHome} />
    //     )} />
    // </Buttons>
  );
};