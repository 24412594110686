import { useCopy } from '@utils';
import { CopyButton, CopiedButton } from './Button';
import type { Props } from './interfaces';
import styles from './style.css';

export const CopyLink = (props: Props) => {
  const {
    copied,
    copy,
    target,
  } = useCopy();

  const renderCopy = () => {
    if (copied) {
      return <CopiedButton />;
    }

    return (
      <CopyButton
        disabled={props.disabled}
        onClick={copy} />
    );
  };

  return (
    <div className={styles.root}>
      <input
        ref={target}
        className={styles.link}
        type="text"
        readOnly
        value={props.url}
        placeholder="Loading..." />
      {renderCopy()}
    </div>
  );
};

export default CopyLink;
export {
  CopyButton,
  CopiedButton,
};