import * as types from '@store/action-types';
import type {
  PlatformAlertChangedAction,
  PlatformAlertChangedState,
} from '@store/interfaces';

export const platformAlertChanged = (data: PlatformAlertChangedState): PlatformAlertChangedAction => {
  return {
    alerts: data,
    type: types.PLATFORM_ALERT_CHANGED,
  };
};