import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { cx } from '@utils';
import type { MenuItem } from './interfaces';
import styles from './style/Menu.css';

type Props = {
  className?: string;
  items: MenuItem[];
};

export const SettingsMenu = memo(({ className, items }: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      {items.map((x, i) =>
        <NavLink
          activeClassName={styles.active}
          className={styles.link}
          exact={x.exact}
          key={i}
          to={x.path as string}>
          <div className={styles.item}>
            <x.icon className={styles.icon} />
            <div>{x.name}</div>
          </div>
        </NavLink>
      )}
    </div>
  );
});

export default SettingsMenu;