import { useCallback, useContext, useMemo } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Button } from '@/components/Button';
import { Modal, Header } from '@/components/Modal';
import type { ModalProps } from '@/components/Modal/Modal';
import { defaultColor as ColorSelectDefault } from '@/components/Conference.Tagging/Tagging.ColorSelect';
import { TagFormContext, TagStateContext } from '@/components/Project.Tags/Context';
import { useCreateTagMutation } from '@/components/Project.Tags/hooks';
import { TagForm } from './TagForm';
import { TagFormContainer } from './TagFormContainer';
import styles from './style/CreateTagModal.css';
import { tagFormIsValid } from './utils';

type Props = Pick<ModalProps, 'open' | 'onClose'> & {
  parentTagId: number;
  defaultColor?: string;
};
export const CreateTag = ({ open, onClose, parentTagId, defaultColor = ColorSelectDefault }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Header>Add New Tag</Header>
      <TagFormContainer defaultState={{ parentId: parentTagId, title: '', color: defaultColor, includeSummary: true }}>
        <ModalContent onClose={onClose} parentTagId={parentTagId} />
      </TagFormContainer>
    </Modal>
  );
};

type ContentProps = Omit<Props, 'open'>;

const ModalContent = ({ onClose, parentTagId }: ContentProps) => {
  const { state, projectId } = useContext(TagStateContext);
  const { data } = useContext(TagFormContext);

  const canSave = useMemo(() => tagFormIsValid(data), [data]);

  const { mutateAsync, isLoading } = useCreateTagMutation(projectId);
  const saveTag = useCallback(() => {
    mutateAsync({
      tag: {
        baseTag: {
          color: data.color,
          name: data.title,
          id: null,
        },
        parentTagId: data.parentId,
        ordinal: Math.max(0, ...state.tags.concat(state.tags.flatMap(t => t)).map(t => t.ordinal)) + 1,
      },
    }).then(onClose);
  }, [mutateAsync, onClose, state.tags, data]);
  return (
    <>
      <TagForm disableParent={!!parentTagId} />
      <div className={styles.actions}>
        <Button
          variant='brick'
          color='destructive'
          onClick={onClose}>
          Cancel
        </Button>
        <ButtonActivityIndicator
          disabled={!canSave}
          implicitDisable={false}
          loading={isLoading}
          variant='brick'
          onClick={saveTag}>
          Save
        </ButtonActivityIndicator>
      </div>
    </>
  );
};