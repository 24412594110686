import { useCallback, useMemo, useState } from 'react';
import { useMessageTestingData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';

export const useCanSaveMTMessageAnalysis = (data: SurveyTemplate.MessageTesting.Data['messageAnalysis']) => {

  return useMemo(() => {
    return data.every(e => !!e.dimension && !!e.leftAxis && !!e.rightAxis);

  }, [data]);
};

export const useTemplateMTMessageAnalysis = () => {
  const [templateData] = useMessageTestingData();
  const [items, setItems] = useState(templateData.messageAnalysis);
  const [_, dispatch] = useSurveyBuilderState();

  const onChange = useCallback((id: string) => (data: SurveyTemplate.MessageTesting.MessageAnalysisItem) => {
    setItems(s => s.reduce((acc, x) => {
      return x.id === id
        ? acc.concat(data)
        : acc.concat(x);
    }, []));
  }, []);

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-data-updated',
      value: {
        ...templateData,
        messageAnalysis: items,
      },
    });
  }, [items, dispatch, templateData]);

  return {
    items,
    onChange,
    onSubmit,
  };
};