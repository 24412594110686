import { useCallback, useContext } from 'react';
import type { Node } from 'slate';
import * as api from '@api';
import { VideoAdditionalDetailsContext } from '@containers/Discovery/Context';
import { FeedItemType } from '@enums';
import { CommentsBlock } from '@/components/Posts';

type Props = unknown;

export const Comments = (props: Props) => {
  const { comments, mediaId } = useContext(VideoAdditionalDetailsContext);

  const handleSubmit = useCallback((val: Node[]) => {
    return api.comments.saveComment({
      comment: val,
      itemId: mediaId,
      itemTypeId: FeedItemType.Video,
    })
    .then(async item => {
      await comments.query.refetch();

      return item;
    });
  }, [
    comments.query,
    mediaId,
  ]);

  const items = comments.query.data?.items || [];

  return (
    <CommentsBlock
      items={items}
      loading={comments.query.isLoading}
      onSubmitComment={handleSubmit} />
  );
};

Comments.displayName = 'Discovery.Post.AdditionalDetails.Tab.Comments';