import * as api from '@api';
import type * as API from '@api/interfaces';
import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';

type Params = API.Transcripts.DownloadConferenceTagClip.Request;

export const useDownloadTranscriptTagClip = (params: Params, options: UseDownloaderOptions<API.Transcripts.DownloadConferenceTagClip.Response> = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:transcript/tag/clip`,
      params,
    ],
    queryFn: () => {
      return api.transcripts.downloadTagClip({
        momentId: params.momentId,
        transcriptId: params.transcriptId,
      });
    },
    downloaderOptions: {
      onResponse: res => ({
        type: 'websocket',
        value: res.websocketKey,
      }),
    },
    ...options,
  });
};