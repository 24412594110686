import { cx } from '@utils';
import { Google, Outlook } from '@/components/icons';
import type {
  BaseLabelProps,
  LabelProps,
} from './interfaces';
import styles from './style/ConnectAccount.css';

const Base = (props: BaseLabelProps) => {
  const { className, textClassName, ...otherProps } = props;

  return (
    <div className={cx(styles.root, className)} {...otherProps}>
      {props.children}
      <div className={cx(styles.text, textClassName)}>{props.text}</div>
    </div>
  );
};

export const GmailLabel = (props: LabelProps) => (
  <Base
    {...props}
    text='Gmail'>
    <Google className={cx(styles.img, props.imgClassName)} />
  </Base>
);

export const OutlookLabel = (props: LabelProps) => (
  <Base
    {...props}
    text='Outlook'>
    <Outlook className={cx(styles.img, props.imgClassName)} />
  </Base>
);