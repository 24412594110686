import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import type { SurveySettings } from '@/types/survey';
import { CheckboxLabel } from '@/components/Checkbox';

type Props = {
  className?: string;
  disabled?: boolean;
  label?: string;
};

export const RandomizeCheckbox = ({
  className,
  disabled,
  label = 'Randomize Options',
}: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const randomize = (item.settings as SurveySettings.Randomization).randomization;

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    dispatch({
      type: 'randomize-updated',
      payload: {
        questionIdentifier: item.base.identifier,
        value: !randomize,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    randomize,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={randomize}
        label={label}
        onChange={handleToggle} />
    </div>
  );
};

export const RandomizeRowsCheckbox = ({
  className,
  disabled,
  label = 'Randomize Rows',
}: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const value = (item.settings as SurveySettings.RandomizeRows).randomizeRows;

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        randomizeRows: !value,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
    value,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={value}
        label={label}
        onChange={handleToggle} />
    </div>
  );
};

export const RandomizeOptionsCheckbox = ({
  className,
  disabled,
  label = 'Randomize Options',
}: Props) => {

  const item = useQuestionBuilderItem();

  const [_, dispatch] = useSurveyBuilderState();

  const value = (item.settings as SurveySettings.RandomizeOptions).randomizeOptions;

  const handleToggle = useCallback((e: React.ChangeEvent) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        randomizeOptions: !value,
      },
    });
  }, [
    dispatch,
    item.base.identifier,
    item.settings,
    value,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={value}
        label={label}
        onChange={handleToggle} />
    </div>
  );
};