import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { useMatchUserIdFromUrl } from '@containers/UserProfile/hooks';
import { Breadcrumbs } from './Breadcrumbs';
import { GroupContactRecordContext } from './Context';
import * as Layout from './Layout';
import * as Section from './Section';
import styles from './style/ContactRecord.css';

type Props = unknown;

export const ContactRecord = (props: Props) => {
  const group = useSelectGroup();
  const id = useMatchUserIdFromUrl();

  const query = useQuery({ queryKey: [
    `get:groups/contacts/records`,
    group?.id,
    id,
  ], queryFn: () => {
    return api.groups.contacts.fetchRecord({
      contactId: id,
      groupId: group.id,
    });
  } });

  const value = {
    query,
  };

  return (
    <GroupContactRecordContext.Provider value={value}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Breadcrumbs />
            <Layout.Section>
              <Section.Contact />
            </Layout.Section>
            <Layout.Section>
              <Section.Tags />
            </Layout.Section>
          </div>
        </div>
      </div>
    </GroupContactRecordContext.Provider>
  );
};

ContactRecord.displayName = 'Group.Contacts.Record';