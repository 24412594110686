import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';

type Props = {
  chatInstanceId: number;
  onSuccess?: () => void;
};

export const useRenameChatInstanceMutation = ({ chatInstanceId, onSuccess }: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation({ mutationKey: ['chat:patch:rename'], mutationFn: (name: string) => {
    return $api.renameChatInstance({
      chatInstanceId,
      name,
    });
  }, onSuccess: res => {
    dispatch({
      type: 'chat-instance-renamed',
      payload: {
        chatInstance: res.chatInstance,
      },
    });
    onSuccess?.();
  } });

  return mutation;
};