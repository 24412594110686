import { useMemo } from 'react';
import type { SearchType } from '@enums';
import { getLocationFor } from '@utils';
import { Link } from './Link';
import { ReadOnly } from './ReadOnly';
import type { SearchTagProps } from './interfaces';

export const MaybeSearchTag = <T extends SearchType>({
  className,
  disabled,
  item,
  type,
}: SearchTagProps<T>) => {

  const to = useMemo(() => {
    const params = {
      [type]: [{
        id: item.id,
        name: item.name,
      }],
    };

    return getLocationFor.search(params);
  }, [
    item,
    type,
  ]);

  if (disabled) {
    return (
      <ReadOnly className={className}>
        {item.name}
      </ReadOnly>
    );
  }

  return (
    <Link
      className={className}
      to={to}>
      {item.name}
    </Link>
  );
};

export default MaybeSearchTag;