import { useCallback } from 'react';
import type { SurveyTemplateType } from '@/enums/survey.template';
import { getInitialTemplateData, template } from '../template';
import { useSurveyBuilderOptions, useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSetSurveyTemplate = () => {

  const [state, dispatch] = useSurveyBuilderState();
  const options = useSurveyBuilderOptions();

  return useCallback((t: SurveyTemplateType) => {

    const templateData = getInitialTemplateData(t);

    dispatch({
      value: {
        ...template.custom.initialSurveyData,
        surveyId: state.survey.surveyId,
        surveyDetails: options.defaultSurveyDetails,
        template: {
          type: t,
          data: templateData,
        },
      },
      type: 'reset-survey',
    });

  }, [
    dispatch,
    state.survey.surveyId,
    options.defaultSurveyDetails,
  ]);
};