import { Component } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import { Invitation } from '@screens/Scheduling';
import { device } from '@utils';
import { RecordingConsent as RecordingConsentModal } from '@/components/Modal/RecordingConsent';
import { ProjectOnboardingResponsive } from './ProjectOnboardingResponsive';
import type { SchedulingProjectOnboardingComponentProps, SchedulingSubmitReturnType } from './interfaces';

type Props =
    SchedulingProjectOnboardingComponentProps;

type State = {
  recordingConsentStatusId: enums.ApprovalStatus | null;
  showRecordingConsentModal: boolean;
};

class SchedulingProjectOnboarding extends Component<Props, State> {
  state: State = {
    recordingConsentStatusId: null,
    showRecordingConsentModal: false,
  };

  componentDidMount() {
    this.getRecordingStatus();
  }

  getRecordingStatus = () => {
    api.scheduling.getRecordingConsentState({
      projectId: this.props.projectId,
    })
    .then(({ recordingConsentStatusId }) => this.setState({
      recordingConsentStatusId,
      showRecordingConsentModal: recordingConsentStatusId === enums.ApprovalStatus.NeedsApproval,
    }));
  };

  handleConsentChange = (recordingConsentStatusId: enums.ApprovalStatus) => {
    this.setState({
      recordingConsentStatusId,
      showRecordingConsentModal: false,
    });
  };

  handleSubmitCalendar = (data: SchedulingSubmitReturnType) => {
    return this.props.onSubmit({
      creator: data.creator,
      new: data.new,
      recordingConsentStatusId: this.state.recordingConsentStatusId,
      removed: data.removed,
      scheduleeId: data.scheduleeId,
      schedulerId: data.schedulerId,
      user: data.user,
    });
  };

  render() {
    const Screen = device.phone
        ? ProjectOnboardingResponsive
        : Invitation;

    return (
      <>
        <Screen
          creatorId={this.props.creatorId}
          onSubmit={this.handleSubmitCalendar}
          scheduleeId={this.props.scheduleeId}
          schedulerId={this.props.schedulerId}
          userId={this.props.userId} />
        <RecordingConsentModal
          onSubmit={this.handleConsentChange}
          visible={this.state.showRecordingConsentModal} />
      </>
    );
  }
}

export default SchedulingProjectOnboarding;