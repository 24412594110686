import type { AxiosResponse } from 'axios';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { GetCallDetails } from '@api/interfaces/calls';
import * as api from '@api';

type Props = ICallId;

type Data = GetCallDetails.Response;
type QueryKey = [string, Props];

export function useCallDetailsQuery(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery({ queryKey: [CallDetailsQueryKey, data], queryFn: ({ queryKey }) => {
    const params = queryKey[1] as Props;

    return api.calls.getCallDetails(params);

  }, ...options });
}

const CallDetailsQueryKey = 'call-details';
