import type { RefObject } from 'react';
import { useEffect, useMemo } from 'react';

type Params<T extends HTMLElement = HTMLElement> = {
  ref: RefObject<T>;
  options: IntersectionObserverInit;
};

export const useIntersectionObserver = <T extends HTMLElement = HTMLElement>(handler: IntersectionObserverCallback, params: Params<T>) => {

  const observer = useMemo(() => {
    return new IntersectionObserver(handler, params.options);
  }, [
    handler,
    params.options,
  ]);

  useEffect(() => {

    if (params.ref.current) {
      observer.observe(params.ref.current);
    }

  }, [
    observer,
    params.ref,
  ]);

};