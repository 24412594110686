import { io } from 'socket.io-client';
import type { PipelineSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { Pipeline } from './interfaces';

const log = createLogger(SocketNamespace.Pipeline);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Pipeline}`, resolveSocketOptions());

function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: PipelineSocketEvent, payload: unknown) => log.event(event, payload));
  socket.open();
}

function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

const on = <T extends PipelineSocketEvent>(event: T, handler: Pipeline.RegisterHandler<T>) => {
  return socket.on<PipelineSocketEvent | LifeCycleSocketEvent>(event, handler);
};

const off = <T extends PipelineSocketEvent>(event: T, handler: Pipeline.RegisterHandler<T>) => {
  return socket.off<PipelineSocketEvent | LifeCycleSocketEvent>(event, handler);
};

const pipeline = {
  close,
  off,
  on,
  open,
};

export { pipeline };
export default pipeline;