import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { ProjectInviteReferralEmailContext } from '@/components/ProjectInviteReferral/Context';
import Toast from '@/components/Toast';

export const useSubmitConsultantInviteReferralEmail = () => {
  const ctx = useContext(ProjectInviteReferralEmailContext);

  return useMutation({ mutationFn: (projectId: number) => {
    return api.projects.links.sendConsultantRefererInviteEmail({
      message: ctx.data.message,
      projectId,
      recipients: ctx.data.addresses,
    });
  }, onSuccess: res => {
    Toast.success({
      title: `Invitation Sent!`,
    });
    ctx.clear();
  } });
};