import http from '@services/http';
import { qs } from '@utils';
import type { Insights } from './interfaces/projects';

export const downloadSummary = (data: Insights.DownloadSummary.Request) => {
  const params = {
    export: true,
    format: data.format || `pptx`,
  };

  return http.get<Blob>(`/projects/${data.projectId}/insights/export?${qs.stringify(params)}`, { responseType: 'blob' })
  .then<Insights.DownloadSummary.Response>(res => ({
    file: res,
    name: `Early Insights Summary.${params.format}`,
  }));
};