import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '@store/actions';
import * as api from '@api';
import * as consts from '@consts';
import { getLocationFor, useRouteStepper } from '@utils';
import {
  useFetchProjectGoal,
} from '@utils/api';
import {
  ProjectCloning,
  ProjectOverview,
  ProjectSurvey,
} from '@screens/ProjectCloning';
import {
  useSurveyBuilderState,
  ProjectSurveyBuilderState,
} from '@containers/SurveyBuilder';
import { SurveyQuotasGoalContext } from '@containers/SurveyBuilder';
import type {
  BaseGroupProjectCloningForm,
  GroupProjectCloningForm } from './Context';
import {
  GroupProjectCloningContext,
  GroupProjectCloningFormContext,
  CloneGroupProjectContext,
} from './Context';
import {
  useProjectCloning,
  useProjectCloningForm,
  useProjectState,
} from './hooks';

export const GroupProjectCloningFormContainer = (props: ChildrenProps) => {

  const [form, setForm] = useState<GroupProjectCloningForm>(null);
  const [state] = useSurveyBuilderState();
  const [goalResponse, fetchProjectGoal] = useFetchProjectGoal();
  const { project, projectParent } = useProjectCloning();

  useEffect(() => {
    fetchProjectGoal(project.id);
  }, [
    fetchProjectGoal,
    project.id,
  ]);

  const setFormState = useCallback((data: Partial<BaseGroupProjectCloningForm>) => {
    setForm({ ...form, ...data });
  }, [form, setForm]);

  useEffect(() => {
    if (!goalResponse.loading && !form && goalResponse.value?.goal) {
      setFormState({
        id: project.id,
        description: project.description,
        frequency: projectParent.frequency,
        goal: goalResponse.value.goal.value,
        name: project.name,
        // startDate: null,
        targetCompleteDate: null,
      });
    }
  }, [
    form,
    goalResponse,
    project.id,
    project.description,
    projectParent.frequency,
    project.name,
    setFormState,
  ]);

  const value = useMemo(() => {
    return {
      form: form ? {
        ...form,
        survey: {
          alternateImageExercises: state.survey.alternateImageExercises,
          classifications: state.survey.classifications,
          items: state.survey.items,
          logic: state.survey.logic,
          messages: state.survey.messages,
          questions: state.survey.questions,
          quotas: state.survey.quotas,
          sections: state.survey.sections,
          tagging: state.survey.tagging,
          template: state.survey.template,
        },
      } : null,
      setFormState,
    };
  }, [
    form,
    setFormState,
    state.survey,
  ]);

  return (
    <GroupProjectCloningFormContext.Provider value={value}>
      <SurveyQuotasGoalContext.Provider value={goalResponse.value?.goal?.value}>
        {props.children}
      </SurveyQuotasGoalContext.Provider>
    </GroupProjectCloningFormContext.Provider>
  );
};

export const CloneGroupProjectContainer = (props: ChildrenProps) => {

  const [form] = useProjectCloningForm();
  const { project } = useProjectCloning();
  const history = useHistory();
  const dispatch = useDispatch();

  const save = useCallback(() => {
    return api.projects.cloneProject({
      ...form,
      projectParentId: project.parentId,
    }).then(data => {
      dispatch(actions.projectCloned(data));

      const path = getLocationFor.project.root({ slug: data.project.slug });
      history.push(path);
    });
  }, [
    dispatch,
    form,
    history,
    project.parentId,
  ]);

  return (
    <CloneGroupProjectContext.Provider value={save}>
      {props.children}
    </CloneGroupProjectContext.Provider>
  );
};

const GroupProjectCloningContainer = () => {
  const state = useProjectState();
  const location = useLocation();
  const history = useHistory();

  const [_, dispatch] = useSurveyBuilderState();

  const basePath = useMemo(() => {
    return `${consts.path.Projects.Root}/${state.latestProject.slug}/clone`;
  }, [state.latestProject.slug]);

  const routes = useMemo(() => {

    return [
      {
        component: ProjectOverview,
        exact: true,
        path: `${basePath}/overview`,
      },
      {
        component: ProjectSurvey,
        exact: true,
        path: `${basePath}/survey`,
      },
    ];
  }, [
    basePath,
  ]);

  useEffect(() => {

    if (location.pathname !== basePath) {
      history.replace(routes[0].path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.projects.surveys.get({ projectId: state.project.id })
      .then(data => {
        dispatch({
          type: 'reset-survey',
          value: data.survey,
        });
      });
  }, [
    dispatch,
    state.project.id,
  ]);

  const [Routes, actions, step] = useRouteStepper(routes);

  const value = useMemo(() => {
    return {
      actions,
      project: state.latestProject,
      projectParent: state.projectParent,
      step,
      Routes,
    };
  }, [
    actions,
    state.latestProject,
    state.projectParent,
    step,
    Routes,
  ]);

  return (
    <GroupProjectCloningContext.Provider value={value}>
      <GroupProjectCloningFormContainer>
        <CloneGroupProjectContainer>
          <ProjectCloning />
        </CloneGroupProjectContainer>
      </GroupProjectCloningFormContainer>
    </GroupProjectCloningContext.Provider>
  );
};

const GroupProjectCloningContainerWithState = () => {
  const state = useProjectState();
  return (
    <ProjectSurveyBuilderState projectType={state.project.projectType}>
      <GroupProjectCloningContainer />
    </ProjectSurveyBuilderState>
  );
};

export {
  GroupProjectCloningContainerWithState as GroupProjectCloningContainer,
};
export default GroupProjectCloningContainer;