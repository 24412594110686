import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { AccountProfile } from './interfaces';

export const AccountProfileBioContext = createNamedContext<AccountProfile.BioContextValue | undefined>(undefined, 'AccountProfile.BioContext');

export const AccountProfileLoadingContext = createNamedContext<boolean | undefined>(undefined, 'AccountProfileLoadingContext');
export const AccountProfileEditStateContext = createNamedContext<AccountProfile.EditStateContextValue | undefined>(undefined, 'AccountProfile.EditStateContext');
export const AccountProfileFieldsStateContext = createNamedContext<AccountProfile.FieldsStateContextValue | undefined>(undefined, 'AccountProfile.FieldsStateContext');

export const SaveUserProfileContext = createNamedContext<AccountProfile.SaveProfile>(undefined, 'SaveUserProfileContext');
export const DeleteProfileEntryContext = createNamedContext<AccountProfile.DeleteProfileEntry>(undefined, 'DeleteProfileEntryContext');

export const AccountProfileRefetchContext = createNamedContext<AccountProfile.Refetch.ContextValue | undefined>(undefined, 'AccountProfile.RefetchContext');

export const AccountProfileFieldsPopulationContext = createNamedContext<AccountProfile.Enrichment.FieldsPopulationContextValue | undefined>(undefined, 'AccountProfile.FieldsPopulationContext');

export const AccountProfileFeaturePostsContext = createNamedContext<AccountProfile.FeaturePostsContextValue>(undefined, 'AccountProfileFeaturePostsContext');
export const AccountProfileFeaturePostsLoadingContext = createNamedContext<boolean>(undefined, 'AccountProfileFeaturePostsLoadingContext');

export const AccountProfileContactContext = createNamedContext<AccountProfile.ContactContextValue>(undefined, '');

export const useAccountProfileLoading = () => useContext(AccountProfileLoadingContext);
export const useSaveUserProfile = () => useContext(SaveUserProfileContext);
export const useDeleteProfileEntry = () => useContext(DeleteProfileEntryContext);

export const useProfileFeaturedPosts = () => useContext(AccountProfileFeaturePostsContext);
export const useProfileFeaturedPostsLoading = () => useContext(AccountProfileFeaturePostsLoadingContext);

export  const useProfileContact = () => useContext(AccountProfileContactContext);