import { useCallback, useMemo } from 'react';
import type { Node, Path } from 'slate';
import { Text, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import type { RenderElementProps, RenderLeafProps } from 'slate-react';
import { Editable as SlateEditable, Slate, withReact } from 'slate-react';
import { RichTextListboxPopover, RichTextTagEditor } from '@/components/RichTextMentions';
import { useMentionsHelpers, useMentionsPlugin } from '@/components/RichTextMentions/hooks';
import type { Editor } from './interfaces';
import styles from './style/Editor.Editable.css';

type Props = Editor.TextFieldProps;

export const Editable = ({ onChange, value, ...props }: Props) => {
  const withMentions = useMentionsPlugin();
  /* eslint-disable-next-line */
  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const [{ handleKeyDown }] = useMentionsHelpers(editor);

  const decorate = useCallback(([node, path]: [Node, Path]) => {
    if (!Text.isText(node)) {
      return [];
    }

    /* eslint-disable-next-line */
    const regex = /(https?:\/\/|www\.)?[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi;
    /* eslint-disable-next-line */
    const result = node.text.matchAll(regex);

    if (result) {
      const indices = getIndices(result);
      if (!indices.length) return [];

      return indices.map(({ end, start, value }) => ({
        link: true,
        anchor: { path, offset: start },
        focus: { path, offset: end },
        href: value,
      }));
    }

    return [];

  }, []);

  return (
    <Slate
      editor={editor}
      onChange={onChange}
      value={value}>
      <SlateEditable
        decorate={decorate}
        style={{ minHeight: props.minHeight }}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        renderElement={renderElement}
        renderLeaf={renderLeaf} />
      <RichTextListboxPopover className={styles.mentions} />
    </Slate>
  );
};

const defaultProps = {
  minHeight: 75,
};

Editable.defaultProps = defaultProps;
Editable.displayName = 'PostCreation.Editor.Editable';

const Element = (props: RenderElementProps)  => {

  switch (props.element.type) {
    case 'mention': {
      return <RichTextTagEditor {...props} />;
    }

    default:
      return (
        <p
          {...props.attributes}
          className={styles.p}>
          {props.children}
        </p>
      );
  }
};

const Leaf = (props: RenderLeafProps) => {
  if (props.leaf.link) {
    return (
      <span
        {...props.attributes}
        style={{ color: 'var(--pri-02' }}>
        <a
          href={props.leaf.href as string}
          rel="noreferrer"
          target="_blank">
          {props.children}
        </a>
      </span>
    );
  }

  return (
    <span {...props.attributes}>
      {props.children}
    </span>
  );
};

type Indices = {
  end:   number;
  start: number;
  value: string;
};

const getIndices = (regex: IterableIterator<RegExpMatchArray>) => {
  const indices: Indices[] = [];

  for (const re of regex) {
    const match = re[0];

    const safe = match.replace(/^[^\w]+|[^\w]+$/, '');

    indices.push({
      start: re.index,
      end: re.index + safe.length,
      value: safe,
    });
  }

  return indices;
};