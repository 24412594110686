import { memo } from 'react';
import { cx } from '@utils';
import { Preview as PreviewIcon } from '@/components/icons';
import type { Item } from './interfaces';
import styles from './style/Item.css';

export const Preview = memo(({ title = 'Preview', onClick }: Item.Props) => {
  return (
    <div
      className={cx(styles.root)}
      onClick={onClick}>
      <PreviewIcon
        className={styles.icon}
        size={22} />
      {title}
    </div>
  );
});