import type { AxiosResponse } from 'axios';
import type { UseDownloaderOptions } from '@containers/QueryDownloader';
import { useQueryDownloader } from '@containers/QueryDownloader';
import * as $api from '@api';
import type * as API from '@api/interfaces';

type Params = {
  format: 'pptx' | 'docx';
} & IProjectId;

type Data = API.Insights.DownloadSummary.Response;

type QueryKey = readonly [string, Params];

type Options = UseDownloaderOptions<Data, AxiosResponse, Data, QueryKey>;

export const useDownloadProjectInsightsReport = (props: Params, options: Options = {}) => {
  return useQueryDownloader({
    queryKey: [
      `get:projects/insights/export`,
      props,
    ],
    queryFn: ({ queryKey }) => {
      const [_, { format, projectId }] = queryKey;
      return $api.projects.insights.downloadSummary({
        format,
        projectId,
      });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: res.name,
        type: 'blob',
        value: res.file,
      }),
    },
    ...options,
  });
};