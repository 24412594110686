import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api/scheduling.ad-hoc';
import type * as Scheduling from '@api/interfaces/scheduling.ad-hoc';
import * as actions from '@store/actions';

type Data = Scheduling.ConfirmCall.Response;
type Variables = Omit<Scheduling.ConfirmCall.Request, 'callId'>;

export function useConfirmAdHocCall(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: Variables, ctx) => {
    dispatch(actions.batchActions([
      actions.adHocCallUpdated({
        call: res.call,
      }),
    ]));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation({ mutationKey: keyAdHocCallConfirmation(data.callId), mutationFn: params => {

    return api.adHocCall.confirm({
      callId: data.callId,
      slot: params.slot,
    });

  }, ...options, onSuccess });
}

export function keyAdHocCallConfirmation(callId: number) {
  return ['ad-hoc-call-confirm-time', callId];
}

export default useConfirmAdHocCall;