import * as types from '@store/action-types';
import type {
  DocumentsFetchedAction,
  DocumentsFetchedState,
} from '@store/interfaces';

export const documentsFetched = (data: DocumentsFetchedState): DocumentsFetchedAction => {
  return {
    documents: data.documents,
    type: types.DOCUMENTS_FETCHED,
  };
};