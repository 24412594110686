import * as enums from '@enums';
import { hasClientRole } from '@utils';
import type { PipelineRecord, Project } from '@/types';

export const assertProjectRequestAccepted = (record: PipelineRecord) => {
  return record.statusId === enums.UserProjectStatus.Active
      || (record.statusId === enums.UserProjectStatus.InReview);
};

export const assertProjectStatusActive = (project: Project) => {
  return [
    enums.ProjectStatus.Active,
  ]
  .includes(project.projectStatusId);
};

export const createSelectorIsProjectActive = (state: Store.State) => (project: Project) => {
  return hasClientRole(state.user)
    ? assertProjectStatusActive(project)
    : assertProjectRequestAccepted(state.pipeline.me[project.id]);
};