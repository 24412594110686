import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useDebounce } from '@utils/hooks';
import { MedicalBackgroundPracticeContext } from '@containers/MedicalOnboarding/Context';
import Autocomplete from '@/components/AutoComplete';
import styles from './style/Autosuggest.css';

type Props = {
  className?:   string;
  placeholder?: string;
};

export const Hospitals = (props: Props) => {
  const ctx = useContext(MedicalBackgroundPracticeContext);
  const [value, setValue] = useDebounce(ctx.form.employer?.name ?? '', 150);

  const selected = ctx.form.employer;

  const setSelected = useCallback((item: Descriptor) => {
    ctx.setValue({
      employer: item,
    });
  }, [ctx]);

  const params = useMemo(() => {
    return {
      keyword: value.trim(),
    };
  }, [value]);

  const query = useQuery({ queryKey: [
    QK.Search.Medical.Hospitals.Get,
    params.keyword,
  ] as const, queryFn: ctx => {
    return api.search.medical.fetchHospitals({
      keyword: ctx.queryKey[1],
    }).then(res => res.items);
  }, enabled: !!params.keyword });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(value);
    ctx.setValue({
      employer: {
        id: undefined,
        name: value || '',
        custom: true,
      },
    });
  }, [
    ctx,
    setValue,
  ]);

  const handleSelect = useCallback((item: Descriptor) => {
    setValue(item.name);
    setSelected(item);
  }, [
    setSelected,
    setValue,
  ]);

  return (
    <Autocomplete
      className={props.className}
      focusableClasses={{ root: styles.focusable }}
      getItemValue={(item: Descriptor) => item.name}
      item={selected}
      items={query.data}
      name="hospitals"
      onChange={handleChange}
      onSelect={handleSelect}
      placeholder={props.placeholder}
      value={value || ''} />
  );
};

Hospitals.displayName = 'MedicalProfile.Field.Hospitals';