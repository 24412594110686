import type { SurveyBuilder, SurveyQuotasBuilder } from '../interfaces';
import * as $actions from './state.quotas';

export function quotasReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyQuotasBuilder.State {
  const existingQuotas = state.survey.quotas;

  switch (action.type) {
    case 'add-quota-item': {
      return existingQuotas.concat($actions.getDefaultQuotaItem(action));
    }

    case 'remove-quota-item': {
      return existingQuotas.filter(f => f.identifier !== action.identifier);
    }

    case 'save-quota-item': {
      return existingQuotas.reduce((acc, x) => {
        if (x.identifier === action.value.identifier) {
          acc.push(action.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);
    }

    case 'toggle-quota-editing-off': {
      return state.editing.quota.isNew
        ? existingQuotas.filter(f => f.identifier !== action.identifier)
        : existingQuotas;
    }

    // side effects

    case 'revert-uncommitted-changes':
      return state.committed.quotas;

    case 'remove-question-row':
      return $actions.rowRemoved(existingQuotas, action);

    case 'remove-question-option':
      return $actions.optionRemoved(existingQuotas, action);

    case 'question-removed':
      return $actions.questionRemoved(existingQuotas, action);

    case 'remove-section':
      return $actions.sectionRemoved(state, action);

    case 'update-question-identifier':
      return $actions.questionIdentifierUpdated(existingQuotas, action);

    case 'update-question-type':
      return $actions.questionTypeUpdated(existingQuotas, action);

    default:
      return existingQuotas;
  }
}