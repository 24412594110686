import { useCallback, useContext, useState } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundContactContext } from './Context';
import type { FormState, QueryData, SetValueParams } from './interfaces.contact';

type Props = {
  children: React.ReactNode;
};

export const StateContainer = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);

  const [contact, setContact] = useState(getStateFromQueryData(ctx.query.data.contact));

  const rehydrate = useCallback(() => {
    return ctx.query.refetch()
    .then(res => setContact(getStateFromQueryData(res.data.contact)));
  }, [
    ctx.query,
    setContact,
  ]);

  const handleChange = useCallback((data: SetValueParams) => {
    setContact({
      contactMethod: data.contactMethod ?? contact.contactMethod,
      market: data.market ?? contact.market,
      phone: data.phone ?? contact.phone,
      phoneAlternate: data.phoneAlternate ?? contact.phoneAlternate,
      reminderMethod: data.reminderMethod ?? contact.reminderMethod,
      smsConsent: data.smsConsent ?? contact.smsConsent,
      timezone: data.timezone ?? contact.timezone,
      inPersonResearch: data.inPersonResearch ?? contact.inPersonResearch,
      virtualResearch: data.virtualResearch ?? contact.virtualResearch,
      surveyResearch: data.surveyResearch ?? contact.surveyResearch,
      phoneResearch: data.phoneResearch ?? contact.phoneResearch,
    });
  }, [contact]);

  const value = {
    form: contact,
    rehydrate,
    setValue: handleChange,
  };

  return (
    <MedicalBackgroundContactContext.Provider value={value}>
      {props.children}
    </MedicalBackgroundContactContext.Provider>
  );
};

StateContainer.displayName = 'MedicalBackground.Contact.StateContainer';

function getStateFromQueryData(data: QueryData): FormState {
  return {
    contactMethod: data.contactMethod,
    market: data.market,
    phone: data.phone,
    phoneAlternate: data.phoneAlternate,
    reminderMethod: data.reminderMethod,
    smsConsent: data.smsConsent,
    timezone: data.timezone ? String(data.timezone) : new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone,
    inPersonResearch: data.inPersonResearch,
    virtualResearch: data.virtualResearch,
    surveyResearch: data.surveyResearch,
    phoneResearch: data.phoneResearch,
  };
}