import type { IConference } from '@containers/Conference';
import { useConferenceInstance, useLiveRoomScreen } from '@containers/Conference';
import { Title } from '@screens/ConferenceSession';
import { SelectedParticipantContainer } from './SelectedParticipantContainer';
import { SessionStatus } from './Session.Status';
import { SessionVideo } from './Session.Video';
import styles from './style/Session.css';

export function Session() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const screen = useLiveRoomScreen();

  return (
    <SelectedParticipantContainer>
      <div className={styles.root}>
        <Title
          className={styles.title}
          title={instance.conference.title}
          visible={!screen.isFullscreen} />
        <SessionStatus />
        <SessionVideo />
      </div>
    </SelectedParticipantContainer>
  );
}

