import { useContext, useState, useRef, useMemo } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import type { State as PopperState } from '@popperjs/core';
import { X } from 'react-feather';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import type { SurveyFiltering, SurveyQuestionOption } from '@/types';
import type { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { CheckboxGroupItem, CheckboxGroupOnItemToggleItem } from '@/components/Checkbox';
import { CheckboxGroup } from '@/components/Checkbox';
import type { FilterProps, OrderedFilter } from './utils';
import styles from './style/SurveyResponseFilter.css';
import { Selector } from './Selector';
import { CommonRow } from './CommonRow';
import { useFilterQuestion } from './hooks';

export const MultiChoiceFilter = ({ filterEntry, filterActions }: FilterProps<SurveyQuestionType.MultipleChoice>) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(filterEntry.filter?.optionIds || []);
  const [optionsIsSelected, setOptionsIsSelected] = useState<boolean>(filterEntry.filter ? filterEntry.filter.isSelected : true);

  const question = useFilterQuestion(filterEntry);

  const options: SurveyQuestionOption[] = useMemo(() => {
    return question.options;
  }, [question]);

  function onCheckboxToggle(item: CheckboxGroupOnItemToggleItem) {
    let newItems: number[] = [];
    if (selectedOptions.includes(+item.id)) {
      newItems = selectedOptions.filter(o => o !== +item.id);
    } else {
      newItems = [...selectedOptions, +item.id];
    }

    setSelectedOptions(newItems);

    passbackFilter({ optionIds: newItems });
  }

  function onIsSelectedChange(val: boolean) {
    setOptionsIsSelected(val);
    passbackFilter({ isSelected: val });
  }

  function passbackFilter(partialFilter?: Partial<SurveyFiltering.QuestionFilter<SurveyQuestionType.MultipleChoice>>) {
    const constructedFilter: OrderedFilter<SurveyQuestionType.MultipleChoice> = {
      ...filterEntry,
      filter: {
        ...{
          type: 'option-filter',
          optionIds: selectedOptions,
          isSelected: optionsIsSelected,
        }, ...partialFilter,
      },
    };

    filterActions.changeFilter(constructedFilter);
  }

  function clearAll() {
    setSelectedOptions([]);
    passbackFilter({ optionIds: [] });
  }

  function selectAll() {
    const allOptions = options.map(o => o.base.id);
    setSelectedOptions(allOptions);
    passbackFilter({ optionIds: allOptions });
  }

  const checkboxItems = options.map<CheckboxGroupItem>(o => ({
    label: parseSurveyRichText(o.value),
    id: o.base.id,
  }));

  const buttonRef = useRef();

  const displayText = selectedOptions.length
    ? selectedOptions.map(o1 => {
      const option = options.find(o2 => o2.base.id == o1);
      if (!option) return '';
      return parseSurveyRichText(option.value);
    }).filter(Boolean).join(', ')
    : 'Select a value'
    ;

  return (
    <CommonRow filterEntry={filterEntry} filterActions={filterActions}>
      <Selector
        items={[true, false]}
        displayValue={v => v ? 'Selected' : 'Not Selected'}
        onSelect={onIsSelectedChange}
        value={optionsIsSelected} />
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={styles.filterRowInput}>
          <div
            className={styles.selectorButton}
            ref={buttonRef}
            onClick={e => setOpen(!isOpen)}>
            {displayText}
          </div>
          <Popper
            open={isOpen}
            anchorEl={buttonRef.current}
            className={cx(styles.selectorPopover, styles.multichoicePopover)}
            placement={'bottom-start'}
            popperOptions={{
              modifiers: [
                {
                  name: 'update-width',
                  phase: 'afterWrite',
                  enabled: true,
                  fn({ state }: { state: PopperState }) {
                    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
                  },
                },
              ],
              onFirstUpdate: state => {
                state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
              },
            }}>
            <div className={styles.multichoiceToggleAll}>
              <span
                style={{ marginRight: '10px' }}
                onClick={selectAll}>
                Select All
              </span>
              <span onClick={clearAll}>Clear All</span>
            </div>
            <div className={styles.checkboxGroupContainer}>
              <CheckboxGroup
                className={styles.checkboxGroup}
                items={checkboxItems}
                checkedItems={selectedOptions}
                onToggle={onCheckboxToggle} />
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </CommonRow>
  );
};