import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';
import { useValidateTextInput } from './input';

export const useCanSaveMedicalCondition = (target: SurveyTemplate.LinkedEntity) => {

  return useValidateTextInput(target.value, {
    minimum: 2,
  });
};

export const useTemplateMedicalCondition = () => {
  const [templateData] = useTargetProductProfileData();
  const [medicalCondition, setMedicalCondition] = useState(templateData.medicalCondition);
  const [_, dispatch] = useSurveyBuilderState();

  const onChange = useCallback((value: SurveyTemplate.LinkedEntity) => {
    setMedicalCondition(value);
  }, []);

  const onSubmit = useCallback(() => {

    dispatch({
      type: 'template-medical-condition-updated',
      value: medicalCondition,
    });
  }, [
    medicalCondition,
    dispatch,
  ]);

  return {
    onChange,
    onSubmit,
    value: medicalCondition,
  };
};