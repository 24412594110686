import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useSelectUser } from '@containers/Store';
import { MedicalOnboardingCompletionContext } from './Context';

type Props = {
  children: React.ReactNode;
  userId:   number;
};

export const MedicalOnboardingCompletionContainer = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelectUser();

  const mutation = useMutation({ mutationKey: [
    QK.Users.Onboarding.Statuses.Put,
    props.userId,
  ], mutationFn: () => {
    return api.users.onboarding.updateCompletion({
      completed: true,
      userId: props.userId,
    });

  }, onSuccess: res => {
    if (user.id === props.userId) {
      dispatch(actions.onboardingCompleted({
        completedOn: res.completedOn,
      }));
    }
  } });

  const value = {
    mutation,
  };

  return (
    <MedicalOnboardingCompletionContext.Provider value={value}>
      {props.children}
    </MedicalOnboardingCompletionContext.Provider>
  );
};

MedicalOnboardingCompletionContainer.displayName = 'MedicalOnboarding.Completion.Container';