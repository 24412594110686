import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { querykey } from '@consts';

export const useFetchFollowedTopics = (options: Options = {}) => {
  const query = useQuery({ queryKey: [querykey.Users.Topics.Get], queryFn: async () => {

    const result = await api.users.topics.fetchFollowed();

    return result;

  }, ...options });

  return query;
};

type Options =
  UseQueryOptions<
    API.Users.FetchFollowedTopics.Response,
    AxiosResponse,
    API.Users.FetchFollowedTopics.Response>;