import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';

namespace PublishMutation {
  export type Variables = API.Posts.Articles.Publish.Request;

  export type Options =
    UseMutationOptions<
      API.Posts.Articles.Publish.Response,
      unknown,
      Variables>;
}

export const usePublishArticlePostMutation = (options?: PublishMutation.Options) => {
  const mutation = useMutation({ mutationKey: [QK.Posts.Articles.Post], mutationFn: (data: PublishMutation.Variables) => {
    return api.posts.articles.publish({
      content: data.content,
      excerpt: data.excerpt,
      identifier: data.identifier,
      title: data.title,
      topics: data.topics,
    });
  }, ...options });

  return mutation;
};