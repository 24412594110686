import { useSelector } from 'react-redux';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { pathname } from '@consts';
import { CallStatus } from '@enums';
import { device } from '@utils';
import type { AdHocCall } from '@/types';
import { AdHocCallConfirmationCalendar } from './AdHocCall.ConfirmationCalendar';
import { AdHocCallConfirmationStepper } from './AdHocCall.ConfirmationStepper';

type Params = {
  callId: string;
};

type LocationState = {
  scheduleeId: number;
  schedulerId: number;
};

/**
 * @summary  Confirm a timeslot from a participant's provided availability
 * @param scheduleeId Current user
 * @param schedulerId Participant's calendar
 * @param ...rest
**/

export const AdHocCallSchedulingConfirmation = () => {
  const params = useParams<Params>();
  const location = useLocation<LocationState>();

  const isValid = useSelector(selectIsValid);

  const ScreenContainer = device.phone
    ? AdHocCallConfirmationStepper
    : AdHocCallConfirmationCalendar;

  if (!isValid) return <Redirect to={pathname.Home} />;

  return (
    <ScreenContainer
      callId={+params.callId}
      scheduleeId={location.state.scheduleeId}
      schedulerId={location.state.schedulerId} />
  );
};

const selectIsValid = (callId: number) => (state: Store.State) => {
  const call = state.calls[callId] as AdHocCall;

  const validStatus = call.statusId === CallStatus.Rescheduling ||
    (call.statusId === CallStatus.Pending && call.request.accepted);

  return validStatus && call.lastModifiedBy !== state.user.id;
};

export default AdHocCallSchedulingConfirmation;