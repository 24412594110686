import { createContext, useCallback, useContext, useMemo } from 'react';
import type {
  ConnectAccountContextValue,
  ConnectedAccountValue,
} from './interfaces';

export const ConnectAccountContext = createContext<ConnectAccountContextValue>(undefined);
export const useConnectedAccountContext = (params: ConnectedAccountValue) => {
  const [loading, setLoading] = useContext(ConnectAccountContext);

  const isLoading = useMemo(() => {
    return params.provider === loading?.provider &&
      params.type === loading?.type;
  }, [params, loading]);

  const update = useCallback((value: boolean) => {
    if (value) {
      setLoading(params);
    } else {
      setLoading(null);
    }
  }, [params, setLoading]);

  return [isLoading, update] as const;
};