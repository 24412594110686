import { Fragment, useCallback } from 'react';
import { useAccountProfileNewEntry, useDeleteProfileEntry, useProfileEditableState, useAccountProfileEditState, useProfileState, useSaveUserProfile } from '@containers/AccountProfile';
import { Editable } from '@/components/Editable';
import { getMultiEntryTypeValues } from './Profile.Section.utils';
import { useDeleteProfileEntryAlert } from './hooks/useDeleteProfileEntryAlert';
import type { Section } from './interfaces';

export const ProfileSectionEntries = <T extends Section.MultiEntry.ProfileMultiEntry>(props: Section.MultiEntry.EntryProps<T>) => {
  const [user] = useProfileState();
  const save = useSaveUserProfile();
  const deleteEntry = useDeleteProfileEntry();
  const editable = useProfileEditableState();
  const [entryId, onToggleEditState] = useAccountProfileEditState();
  const [toggleDeleteAlert, DeleteEntryAlert] = useDeleteProfileEntryAlert();
  const [editingNewEntry, toggleNewEntry] = useAccountProfileNewEntry(props.type);

  const items = user[props.type] as Section.MultiEntry.Value[];

  const canEdit = editable && !entryId && !editingNewEntry;

  const {
    Form,
    ReadOnly,
  } = getMultiEntryTypeValues(props.type);

  const handleSave: Section.MultiEntry.OnSave<T> = useCallback(data => {
    // @ts-ignore
    return save({
      ...data,
      type: props.type,
      userId: user.id,
    });
  }, [
    props.type,
    save,
    user.id,
  ]);

  const handleDelete = useCallback((id: number) => () => {
    return deleteEntry({
      id,
      type: props.type,
      userId: user.id,
    });
  }, [
    deleteEntry,
    props.type,
    user.id,
  ]);

  const renderForm = useCallback((item: Section.MultiEntry.EntryList[T], toggleEditing: () => unknown) => {
    return (
      <Fragment>
        <Form
          value={item}
          onCancel={toggleEditing}
          onDelete={toggleDeleteAlert}
          onSave={handleSave} />
        <DeleteEntryAlert
          onConfirm={handleDelete(item.id)} />
      </Fragment>
    );
  }, [
    handleDelete,
    handleSave,
    toggleDeleteAlert,
    DeleteEntryAlert,
    Form,
  ]);

  const renderReadOnly = useCallback((item: Section.MultiEntry.EntryList[T], toggleEditing: () => unknown) => {
    return (
      <Editable canEdit={canEdit} onClick={toggleEditing}>
        <ReadOnly
          value={item} />
      </Editable>
    );
  }, [
    canEdit,
    ReadOnly,
  ]);

  return (
    <>
      {items.map((item: Section.MultiEntry.EntryList[T]) => {
        const itemEntryId = `${props.type}-${item.id}`;
        const isEditing = entryId === itemEntryId;
        const toggleEditing = onToggleEditState(itemEntryId);

        return (
          <div key={itemEntryId} className={props.entryClassName}>
            {isEditing
              ? renderForm(item, toggleEditing)
              : renderReadOnly(item, toggleEditing)
            }
          </div>
        );
      })}
      {editingNewEntry &&
        <div key="new" className={props.entryClassName}>
          <Form
            value={null}
            onCancel={toggleNewEntry}
            onDelete={handleDelete(null)}
            onSave={handleSave} />
          <DeleteEntryAlert
            onConfirm={toggleNewEntry} />
        </div>
      }
    </>
  );
};

export default ProfileSectionEntries;