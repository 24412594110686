import { Fragment, useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks/useSurveyQuestionResponsesData';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { useNumberInputTableChartData } from './hooks/number-input-table';
import { useOptionsColors } from './hooks/usePaletteColors';
import AggregateData from './NumberInputTable.Data.Aggregate';
import BucketData from './NumberInputTable.Data.Bucket';
import Chart from './NumberInputTable.Chart';
import OptionData from './NumberInputTable.Data.Option';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';
import type { NumberInputTable } from './interfaces';
import styles from './style/Options.css';

export const NumberInputTableResponses = () => {
  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.NumberInputTable>();
  const {
    question,
    responses,
  } = useSurveyQuestionResponsesData<SurveyQuestionType.NumberInputTable>();

  const colors = useOptionsColors();

  const handleClick = useCallback((rowId: number, optionId: number, bucket?: NumberInputTable.Bucket) => {
    setQueryState({
      bucket,
      optionId,
      rowId,
    });
  }, [setQueryState]);

  const renderData = useCallback(() => {
    if (query.rowId && query.optionId && query.bucket) {
      return (
        <BucketData
          bucket={query.bucket}
          colors={colors}
          onClick={handleClick}
          onReset={resetQueryState}
          item={question}
          optionId={query.optionId}
          rowId={query.rowId}
          responses={responses} />
      );
    } if (query.rowId && query.optionId) {
      return (
        <OptionData
          colors={colors}
          onClick={handleClick}
          onReset={resetQueryState}
          item={question}
          optionId={query.optionId}
          rowId={query.rowId}
          responses={responses} />
      );
    } else {
      return (
        <AggregateData
          colors={colors}
          onClick={handleClick}
          item={question}
          responses={responses} />
      );
    }
  }, [
    colors,
    handleClick,
    question,
    responses,
    query,
    resetQueryState,
  ]);

  const chartData = useNumberInputTableChartData();

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <Chart
        colors={colors}
        data={chartData}
        question={question}
        onBarClick={handleClick} />
      <div className={styles.options}>
        {renderData()}
      </div>
    </Fragment>
  );
};

export default NumberInputTableResponses;