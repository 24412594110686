import { useState, useCallback } from 'react';
import type { StarRatingProps } from './interfaces';
import { StarRating } from './StarRating';

type Props = {
  defaultRate?: number;
} & Pick<StarRatingProps, 'size' | 'rootClassName'>;

export const useStarRating = ({ defaultRate, ...rest }: Props = { }) => {
  const [rate, setRate] = useState(defaultRate);

  const Component = useCallback(() => {
    return <StarRating
      rating={rate}
      onClick={setRate}
      {...rest} />;
  }, [rate, setRate, rest]);

  return [rate, Component] as const;
};