import { useCallback, useMemo, useState } from 'react';
import { useMessageTestingData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';

export const useCanSaveProduct = (product: SurveyTemplate.LinkedEntity) => {

  return useMemo(() => {
    return product.value.length >= 2;
  }, [product.value]);
};

export const useTemplateProduct = () => {
  const [templateData] = useMessageTestingData();
  const [product, setProduct] = useState(templateData.product);
  const [_, dispatch] = useSurveyBuilderState();

  const onChange = useCallback((value: SurveyTemplate.LinkedEntity) => {
    setProduct(value);
  }, []);

  const onSubmit = useCallback(() => {

    dispatch({
      type: 'template-product-updated',
      payload: {
        value: product,
      },
    });
  }, [
    product,
    dispatch,
  ]);

  return {
    onChange,
    onSubmit,
    value: product,
  };
};