import { Fragment, useCallback } from 'react';
import { PostMediaType } from '@enums';
import * as consts from '@consts';
import { getLocationFor } from '@utils';
import type { Activity } from '@/types';
import { Button } from '@/components/Button';
import { Carousel } from '@/components/Discovery';
import { PostPreview } from '@/components/Posts';
import { usePostsCarousel } from '@/components/Posts/hooks/usePostsCarousel';
import { Header } from './Layout';
import styles from './style/Panel.css';

type Props = {
  items: Activity.Item.Post[];
  slug:  string;
};

export function PostsCarousel({ slug, items }: Props) {
  const carousel = usePostsCarousel({
    size: items.length,
  });

  const renderItem = useCallback((item: Activity.Item.Post) => {
    switch (item.media?.typeId) {
      case PostMediaType.Video:
        return (
          <PostPreview.Video
            className={styles.post}
            to={getLocationFor.discovery.video({ postId: item.id })}
            item={item} />
        );

      default:
        return (
          <PostPreview.Text
            className={styles.post}
            to={getLocationFor.post.root({ postId: item.id })}
            item={item} />
        );
    }
  }, []);

  return (
    <div>
      <Header text="Posts">
        <div className={styles.btns}>
          <Button.Secondary
            variant="brick"
            className={styles.seeMore}
            to={`${consts.path.Topics.Root}/${slug}/posts`}>
            See More
          </Button.Secondary>
          {(carousel.actions.canGoBack || carousel.actions.canGoNext) &&
            <Carousel.ButtonSet
              {...carousel.actions} />
          }
        </div>
      </Header>
      <div
        className={styles.carousel}
        ref={carousel.ref}>
        <div
          className={styles.carouselItems}
          style={carousel.style}>
          {items.map(item => (
            <Fragment key={item.id}>
              {renderItem(item)}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}