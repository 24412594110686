import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import { qs } from '@utils';
import type { Files } from './interfaces/projects';

export const getProjectFiles = (data: Files.GetProjectFiles.Request): Promise<Files.GetProjectFiles.Response> => {
  const query = qs.stringify({
    filters: JSON.stringify(data.filters || {}),
    parentObjectId: data.parentObjectId,
  }, { arrayFormat: 'bracket' });
  return http.get(`/projects/${data.projectId}/files?${query}`, {
    transformResponse: transformDates,
  });
};

export const getFilePreview = (data: Files.GetFilePreview.Request): Promise<Files.GetFilePreview.Response> => {
  return http.get<Files.GetFilePreview.Response>(`/projects/${data.projectId}/files/${data.fileId}/preview`, {
    transformResponse: transformDates,
  });
};