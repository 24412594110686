import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MessageSquare } from 'react-feather';
import { useSelectUser } from '@containers/Store';
import * as utils from '@utils';
import type { IChat } from '@/types';
import ActivityIndicator from '@/components/ActivityIndicator';
import { UserAvatar } from '@/components/UserAvatar';
import PenPad from '@/components/icons/PenPad';
import styles from './style/Channels.css';

type Props = {
  activeConversationSid: string;
  items: IChat.DirectoryItem[];
  loading: boolean;
  onClick: () => unknown;
  setChannel: (item: IChat.DirectoryItem) => unknown;
  onLoadUserId: number;
};

const mapState = (state: Store.State) => ({
  initialized: state.chat.initialized,
});

export default function Channels({ activeConversationSid, items, loading, onClick, setChannel, onLoadUserId }: Props) {
  const user = useSelectUser();
  const { initialized } = useSelector(mapState);

  const hasDirectory = useMemo(() => utils.hasClientRole(user) && initialized, [user, initialized]);

  const handleSetChannel = useCallback((item: IChat.DirectoryItem) => () => {
    setChannel(item);
  }, [setChannel]);

  const getItemStyles = useCallback((item: IChat.DirectoryItem) => {
    if (activeConversationSid === item.conversation.state.sid) {
      return styles.active;
    }

    return item.conversation.unreadCount || !item.conversation.messagesCount
      ? styles.unread
      : styles.read;
  }, [activeConversationSid]);

  useEffect(() => {
    if (items.length && onLoadUserId) {
      const match = items.find(i => i.participant.contact.id === onLoadUserId);
      if (match) {
        if (match.conversation.state.sid !== activeConversationSid) {
          handleSetChannel(match)();
        }
      }
    }
  }, [activeConversationSid, items, onLoadUserId, handleSetChannel]);

  const renderItems = useCallback(() => {
    if (loading || !initialized) return <Loader />;
    if (!items.length) return <Placeholder />;

    return (
      <div className={styles.items}>
        {items.map(item =>
          <div
            className={getItemStyles(item)}
            key={item.conversation.state.sid}
            onClick={handleSetChannel(item)}>
            <div className={styles.indicator} />
            <div className={styles.user}>
              <div className={styles.avatar}>
                <UserAvatar
                  pictureUrl={item.participant.contact.profile.pictureUrl}
                  size={35} />
              </div>
              <div className={styles.info}>
                <div className={styles.name}>{item.participant.contact.profile.fullname}</div>
                <div className={styles.message}>{item.conversation.mostRecent.body}</div>
              </div>
              <div className={styles.date}>
                <div className={styles.time}>{item.conversation.mostRecent.date}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }, [loading, initialized, items, getItemStyles, handleSetChannel]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.contain}>
          <div className={styles.title}>Messages</div>
          {/* {hasDirectory &&
            <button
              className={styles.btn}
              onClick={onClick}>
              <PenPad className={styles.edit} />
            </button>} */}
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.scroll}>
            {renderItems()}
          </div>
        </div>
      </div>
    </>
  );
}

function Loader() {
  return (
    <div className={styles.busy}>
      <ActivityIndicator.Spinner className={styles.spinner} />
    </div>
  );
}

function Placeholder() {
  return (
    <div className={styles.placeholder}>
      <div className={styles.wrap}>
        <MessageSquare className={styles.icon} />
        <div className={styles.subtitle}>You do not have any active messages</div>
      </div>
    </div>
  );
}

export { Channels };