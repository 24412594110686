import { Fragment, memo } from 'react';
import type { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <Fragment>
      {row.original.current?.company ?? '-'}
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Company
    </Fragment>
  );
});

Cell.displayName = 'Column.Company.Cell';
Filter.displayName = 'Column.Company.Filter';
Header.displayName = 'Column.Company.Header';