import { useCallback } from 'react';
import { MessageCircle } from 'react-feather';
import type { CallRating as CallRatingRecord } from '@/types';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { StarRatingPreview } from '@/components/StarRating';
import styles from './styles.css';

type Props = Pick<CallRatingRecord, 'rating' | 'notes'>;

export const CallRating = (props: Props) => {
  const [toggleModal, Modal] = useModal(NotesModal);

  const Notes = useCallback(() => {
    if (!props.notes) {
      return null;
    } else {
      return (
        <>
          <MessageCircle className={styles.icon} onClick={toggleModal} />
          <Modal notes={props.notes} />
        </>
      );
    }
  }, [props.notes, toggleModal, Modal]);

  return (
    <>
      <StarRatingPreview rating={props.rating} />
      <Notes />
    </>
  );
};

type NotesModalProps = Pick<Props, 'notes'> & Pick<ModalProps, 'open' | 'onClose'>;

const NotesModal = ({ notes, ...props }: NotesModalProps) => {
  return (
    <Modal {...props}>
      {notes}
    </Modal>
  );
};