import { useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';

export const useFilteredOptions = () => {
  const { query } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Options>();
  const { question } = useRepeatQuestionResponsesData();

  const options = useMemo(() => {
    const items = query.optionIds
      ? question.options
          .filter(f => query.optionIds.includes(f.base.id))
      : question.options;

    return [...items].sort((a, b) => a.ordinal - b.ordinal);
  }, [
    query.optionIds,
    question.options,
  ]);

  return options;
};

export default useFilteredOptions;