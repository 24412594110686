import { Fragment } from 'react';
import type { ListProps, ListRenderItemInfo } from './interfaces';

type Props<T> = {
  className?: string;
} & ListProps<T>;

const SectionListItems = <T extends unknown>(props: Props<T>) => {
  function extractKey(item: T, index: number) {
    return props.key ?? props?.keyExtractor(item, index);
  }

  function ListItem(info: ListRenderItemInfo<T>) {
    return props.renderItem({
      item: info.item,
      index: info.index,
      section: props.section,
    });
  }

  return (
    <Fragment>
      {props.section.data.map((x, i) =>
        <ListItem
          key={extractKey(x, i)}
          item={x}
          index={i} />)}
    </Fragment>
  );
};

export { SectionListItems };
export default SectionListItems;