import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { ResponseLengthModal } from '@/components/Modal/ResponseLength';
import { useSubmitAnswer, useSurveyFormQuestionContext, useResponseLengthModal, useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer, SurveyFormItemSubmitContext } from './Context';
import { SurveyFormBackContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const LongTextResponseFormContainer = (props: Props) => {

  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.LongTextResponse>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.LongTextResponse>();
  const validateRationale = useValidateRationale();

  const {
    close: closeModal,
    open: openModal,
    shouldOpen,
    show: showModal,
  } = useResponseLengthModal();

  const {
    mutateAsync: submitAnswerMutateAsync,
    ...submitAnswer
  } = useSubmitAnswer();

  const shouldOpenModal = useCallback(() => {
    return shouldOpen(question, answer);
  }, [question, answer, shouldOpen]);

  const handleModalSubmit = useCallback(() => {
    submitAnswerMutateAsync();
    closeModal();
  }, [
    closeModal,
    submitAnswerMutateAsync,
  ]);

  const next = useCallback(() => {

    if (shouldOpenModal()) {
      return Promise.resolve(openModal());
    }

    return submitAnswerMutateAsync();
  }, [
    openModal,
    shouldOpenModal,
    submitAnswerMutateAsync,
  ]);

  const canSubmit = useMemo(() => {

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    return isTextValid();

    function isTextValid() {
      return !!answer?.value;
    }
  }, [
    answer,
    validateRationale,
  ]);

  const submitCtx = {
    isError: submitAnswer.isError,
    isLoading: submitAnswer.isLoading,
    handler: next,
  };

  return (
    <>
      <SurveyFormCanSubmitContext.Provider value={canSubmit}>
        <SurveyFormItemSubmitContext.Provider value={submitCtx}>
          <SurveyFormBackContainer>
            {props.children}
          </SurveyFormBackContainer>
        </SurveyFormItemSubmitContext.Provider>
      </SurveyFormCanSubmitContext.Provider>
      {showModal &&
        <ResponseLengthModal
          onClose={closeModal}
          onSubmit={handleModalSubmit} />
      }
    </>
  );
};

export default LongTextResponseFormContainer;