import type { EmploymentForm } from '@/types';
import type { ProfileValidation } from './interfaces';
import { generateDuring } from './Profile.DateRange.Form';

export const validateEmployment = (item: EmploymentForm) => {
  const during = generateDuring(item);
  const validations: ProfileValidation.Validation[] = [];

  validations.push(validateDuring(during));

  return validations
    .filter(f => !f.success);
};

function validateDuring(value: [number, number]): ProfileValidation.Validation {
  const start = value[0];
  const end = value[1];

  if (start && end && start > end) {
    return {
      field: 'during',
      message: 'End date can’t be earlier than start date.',
      success: false,
    };
  }

  return {
    field: 'during',
    success: true,
  };
}