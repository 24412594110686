import { useContext } from 'react';
import type { Enum } from '@enums/interfaces';
import { Select } from '@/components/Select';
import { FormDerivedEditStateContext, FormStateContext } from './Context';
import { TextDisplayField } from './TextField';

type Props<T extends EnumGeneric = EnumGeneric, K extends keyof T = keyof T> = {
  className?: string;
  helpers:    Enum<T[K]>;
  name:       string;
  value:      T[K];
};

export const EnumField = (props: Props) => {
  const editing = useContext(FormDerivedEditStateContext);

  return editing
    ? <EnumInputField
        className={props.className}
        helpers={props.helpers}
        name={props.name} />
    : <EnumDisplayField
        className={props.className}
        helpers={props.helpers}
        value={props.value} />;
};

export const EnumDisplayField = (props: Omit<Props, 'name'>) => {
  const value = props.value
      ? props.helpers.getName(props.value)
      : 'Unknown';

  return (
    <TextDisplayField
      className={props.className}
      value={value} />
  );
};

export const EnumInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const options = props.helpers.values().map(x => ({
    id: x,
    name: props.helpers.getName(x),
  }));

  const handleInputEvent = (item: typeof options[number]) => {
    dispatch({
      type: 'value',
      key: props.name as keyof typeof state.values,
      value: item.id,
    });
  };

  return (
    <div className={props.className}>
      <Select
        getItemValue={x => x.name}
        onSelect={handleInputEvent}
        options={options}
        value={props.helpers.getName(state.values[props.name])} />
    </div>
  );
};

type EnumGeneric = {
  [key: string]: string | number;
};

export default EnumField;