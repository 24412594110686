import { qs } from '@utils';
import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import type { Integrations } from './interfaces';

export const getIntegrations = () => {
  return http.get<Integrations.GetIntegrations.Response>(`/integrations`);
};

export const getSlackInstallationUrl = () => {
  return http.get<Integrations.Slack.GetInstallationUrl.Response>(`/integrations/slack/installation-url`);
};

export const disconnectSlack = (): Promise<Integrations.Slack.Disconnect.Response> => {
  return http.post(`/integrations/slack/disconnect`, {});
};

export const updateSlackChannels = (data: Integrations.Slack.UpdateChannels.Request): Promise<Integrations.Slack.UpdateChannels.Response> => {
  return http.patch(`/integrations/slack`, data);
};

export const getSlackChannels = (data: Integrations.Slack.GetChannels.Request): Promise<Integrations.Slack.GetChannels.Response> => {
  return http.get(`/integrations/slack/channels?${qs.stringify(data)}`);
};

export const getSlackTeams = (): Promise<Integrations.Slack.GetTeams.Response> => {
  return http.get(`/integrations/slack/teams`);
};

export const testS3Credentials = (data: Integrations.S3.TestConnection.Request): Promise<Integrations.S3.TestConnection.Response> => {
  return http.post(`/integrations/aws/test-connection`, data);
};

export const saveS3Credentials = (data: Integrations.S3.SaveConnection.Request) => {
  return http.post(`/integrations/aws/connection`, data);
};

export const deleteS3Credentials = (data: Integrations.S3.DeleteConnection.Request) => {
  return http.delete(`/integrations/aws/connection?id=${data.id}`);
};

export const getZoomRecordings = (data: Integrations.Zoom.GetRecordings.Request): Promise<Integrations.Zoom.GetRecordings.Response> => {
  return http.get(`/integrations/zoom/recordings?${qs.stringify(data)}`, { transformResponse: transformDates });
};

export const getZoomRecordingFile = (data: Integrations.Zoom.GetRecordingFile.Request): Promise<Integrations.Zoom.GetRecordingFile.Response> => {
  return http.download(`/integrations/zoom/recording-file?${qs.stringify(data)}`);
};