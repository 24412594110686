import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import {
  useFetchIntegrations,
  useUpdateSlackChannels,
} from '@utils/api';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import ButtonSet from '@/components/Modal/ButtonSet';
import ModalHeader from '@/components/Modal/Header';
import { useSlackChannelsContext } from './Context';
import { SlackChannelsEditor } from './Channels.Editor';
import styles from './style/Channels.Modal.css';

type Props =
  Pick<ModalProps,
    'onClose' |
    'open'>;

export const SlackChannelsModal = ({ onClose, open }: Props) => {

  const [channels] = useSlackChannelsContext();

  const {
    refetch: fetchIntegrations,
  } = useFetchIntegrations({
    refetchOnMount: false,
  });

  const {
    mutateAsync: updateChannels,
    isLoading,
  } = useUpdateSlackChannels({
    onSuccess: res => {
      fetchIntegrations().then(() => {
        onClose();
      });
    },
  });

  const handleSubmit = useCallback(() => {
    updateChannels({
      channels: channels.map(m => m.id),
    });
  }, [channels, updateChannels]);

  return (
    <Modal
      onClose={onClose}
      open={open}>

      <ModalHeader
        text="Slack Channels" />

      <div className={styles.editor}>
        <SlackChannelsEditor />
      </div>

      <ButtonSet>
        <Button
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          color="primary"
          onClick={handleSubmit}
          loading={isLoading}
          variant="brick">
          Submit
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

export const useSlackChannelsModal = () => useModal(SlackChannelsModal);

export default SlackChannelsModal;