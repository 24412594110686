import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import * as Modal from '@screens/Group.Contacts/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import { GroupContactsQueryContext, GroupContactsQueryParamsContext, RemoveContactsContext, SelectedContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const RemoveContactsContainer = (props: Props) => {
  const group = useSelectGroup();
  const [toggle, RemoveContactsModal] = useModal(Modal.RemoveContacts);
  const [selected, setSelected, clear] = useContext(SelectedContext);
  const [, setQueryParams] = useContext(GroupContactsQueryParamsContext);
  const query = useContext(GroupContactsQueryContext);

  const mutation = useMutation({ mutationKey: [QK.Groups.Contacts.Delete, group?.id], mutationFn: () => {
    return api.groups.contacts.remove({
      groupId: group.id,
      ids: Array.from(selected.ids.values()),
    });
  }, onSuccess: () => {
    toggle();

    clear();

    setQueryParams({
      index: undefined,
      name: undefined,
      size: undefined,
    }, 'replace');

    query.refetch();
  } });

  const value = {
    mutation,
    toggle,
  };

  return (
    <RemoveContactsContext.Provider value={value}>
      {props.children}
      <RemoveContactsModal />
    </RemoveContactsContext.Provider>
  );
};

RemoveContactsContainer.displayName = 'Group.Contacts.RemoveContacts.Container';