import type {
  SurveyQuestion,
} from '@/types/survey';
import type { Validation } from '../interfaces';
import { isSurveyRichTextEmpty } from './rich-text.validate';

export function returnAssertFailure(assert: Validation.AssertionFailure, question: SurveyQuestion) {
  return {
    success: false,
    result: {
      message: assert.message,
      question,
      data: null,
    },
  };
}

export function assertHasValue(question: SurveyQuestion): Validation.AssertionResult {
  const isEmpty = isSurveyRichTextEmpty(question.value);

  if (isEmpty) {
    return {
      success: false,
      message: 'Value is empty.',
    };
  }

  return { success: true };
}
