import { useCallback, useMemo } from 'react';
import type { SurveySection } from '@/types/survey';
import DropDown from '@/components/DropDown';

type DropdownItem = {
  id: string;
  value: string;
};

type Props = {
  items: SurveySection[];
  onSelect: (identifier: string) => void;
  value: SurveySection;
};

function formatDisplay(q: SurveySection) {
  return q
    ? `S${q.ordinal}: ${q.name}`
    : null;
}

export const SectionDropdown = ({
  items,
  onSelect,
  value,
}: Props) => {

  const getItemValue = useCallback((item: DropdownItem) => {
    const q = items.find(f => f.identifier === item.id);
    return formatDisplay(q);
  }, [items]);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems: DropdownItem[] = useMemo(() => {
    return items
      .map(m => ({
        id: m.identifier,
        value: m.name,
      }));
  }, [items]);

  const text = useMemo(() => {
    return formatDisplay(value);
  }, [value]);

  return (
    <DropDown<DropdownItem>
      placeholder="Select section"
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default SectionDropdown;