import { useCallback, useState } from 'react';
import { useFetchProjectSurvey as useFetchSurvey } from '@utils/api';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { Surveys } from '@api/interfaces/surveys';

export const useFetchProjectSurvey = ({ surveyId }: ISurveyId) => {
  const [_, dispatch] = useSurveyBuilderState();
  const [isSuccess, setIsSuccess] = useState(false);

  const onSuccess = useCallback((res: Surveys.GetSurvey.Response) => {
    dispatch({
      type: 'reset-survey',
      value: res,
    });
    setIsSuccess(true);
  }, [dispatch]);

  const query = useFetchSurvey({ surveyId }, {
    enabled: !!surveyId,
    // refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess,

  });

  return {
    ...query,
    isSuccess: isSuccess && !query.isLoading,
  };
};