/* import { useRequestCallWithUser } from '@containers/RequestCall/hooks/useRequestCall'; */
import { useSearchParams } from '@containers/Search/hooks/useSearchParams';
/* import { useInitiatePlatformMessage } from '@containers/PlatformMessage/hooks/useInitiatePlatformMessage'; */
import type { Search } from '@/types';
/* import { Button } from '@/components/Button'; */
import Avatar from '@/scenes/search/ResultItem/ItemUserPicture';
import Career from '@/scenes/search/ResultItem/ItemEmployments';
import MatchTags from '@/scenes/search/ResultItem/ItemMatchTags';
import TopicTags from '@/scenes/search/ResultItem/ItemTopicTags';
import DisplayName from '@/scenes/search/ResultItem/ItemUserProfileLink';
import styles from './style/ResultsItem.css';

type Props = {
  item: Search.QueryResultItem;
};

const ResultsItem = (props: Props) => {
  const [params, actions] = useSearchParams();
  /*
  const initiatePlatformMessage = useInitiatePlatformMessage();
  const [initiate, Modal] = initiatePlatformMessage(props.item);

  const requestCall = useRequestCallWithUser(props.item);
  */
  const appendTopicParam = (item: Search.Filter.JobFunction) => {
    actions.push('jobfunction', item);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <div className={styles.avatar}>
              <Avatar item={props.item} />
            </div>
            <div className={styles.info}>
              <DisplayName
                className={styles.name}
                item={props.item} />
              <Career
                current={props.item.career.current}
                former={props.item.career.former} />

              <div className={styles.tags}>
                <MatchTags items={props.item.query.match.filters} />
                <TopicTags
                  items={props.item.topics}
                  onClick={appendTopicParam} />
              </div>
            </div>

          </div>

          <div className={styles.actions}>
            {/* <Button
              className={styles.btn}
              onClick={initiate}
              variant="pill">
              Message
            </Button>
            <Button
              className={styles.btn}
              onClick={requestCall}
              variant="pill">
              Request Call
            </Button> */}
          </div>
        </div>
      </div>
      {/* <Modal /> */}
    </>
  );
};

export { ResultsItem };
export default ResultsItem;