import { useSelector } from 'react-redux';
import * as enums from '@enums';
import type { PipelineRecord, PipelineUsersMap } from '@/types';

const mapState = (state: Store.State) => ({
  pipeline: state.pipeline,
});

const useProjectUsers = (projectId: number) => {
  const { pipeline } = useSelector(mapState);
  const { users } = pipeline.project[projectId];

  const statuses = [
    enums.UserProjectStatus.Active,
    enums.UserProjectStatus.InReview,
    enums.UserProjectStatus.Invited,
    enums.UserProjectStatus.Recommended,
    enums.UserProjectStatus.Added,
  ];

  const records = Object.keys(users).reduce<PipelineRecord[]>((acc, userId) => {
    const record = users[userId as unknown as keyof PipelineUsersMap];

    return statuses.includes(record.statusId)
         ? acc.concat(record)
         : acc;
  }, []);

  return records;
};

export { useProjectUsers };
export default useProjectUsers;