import { Route, Switch, useParams } from 'react-router-dom';
import { useSelectProject } from '@containers/Store';
import { path } from '@consts';
import { AudienceType } from '@enums';
/* import { useSurveyThemingLoading } from '@containers/Branding/hooks/useSurveyThemingLoading'; */
import { SurveyDisqualification } from '@screens/ProjectDisqualification';
import { ProjectSurveyArchived } from '@screens/ProjectSurveyArchived';
/* import * as Whitelabel from '@/components/Whitelabel'; */
/* import { ActivityIndicator } from '@/components/ActivityIndicator'; */
import * as Footer from './Footer';
import { useAssertProjectAudienceType } from './hooks';

type Props = unknown;

export const SurveyOnboardingInvalidContainer = (props: Props) => {
  /*
  const loading = useSurveyThemingLoading();

  if (loading) {
    return (
      <ActivityIndicator show />
    );
  }
  */

  const params = useParams<{ projectId: string }>();
  const project = useSelectProject(+params?.projectId);
  const assertProjectAudienceType = useAssertProjectAudienceType(AudienceType.Payer);

  const decorate = assertProjectAudienceType(project?.audienceType);

  return (
    <Switch>
      <Footer.PoweredBy.ScreenDecorator decorate={decorate}>
        <Route
          component={ProjectSurveyArchived}
          exact
          path={path.Onboarding.Projects.Closed} />
        <Route
          component={SurveyDisqualification}
          exact
          path={path.Onboarding.Projects.End} />
      </Footer.PoweredBy.ScreenDecorator>
    </Switch>
  );
};

SurveyOnboardingInvalidContainer.displayName = 'ProjectOnboarding.SurveyOnboardingInvalid.Container';