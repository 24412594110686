import { SurveyQuotaType } from '@/enums';
import { surveyConditionsReducer, ConditionsBuilder } from '@containers/SurveyBuilder.Logic';
import type { SurveyBasicQuotaBuilder, SurveyBaseQuotaBuilder } from './interfaces';
import { surveyBaseQuotaReducer } from './reducer.base';

export function surveyBasicQuotaReducer(state: SurveyBasicQuotaBuilder.State, action: SurveyBasicQuotaBuilder.Action): SurveyBasicQuotaBuilder.State {

  const newState: SurveyBasicQuotaBuilder.State = {
    ...surveyBaseQuotaReducer(state, action as SurveyBaseQuotaBuilder.Action),
    type: SurveyQuotaType.Basic,
    data: state.data,
    // data: {
    //   items: surveyConditionsReducer(state.data.items, action as ConditionsBuilder.Action),
    // },
  };

  logAction(state, newState, action);

  return newState;
}

function logAction(oldState: SurveyBasicQuotaBuilder.State, newState: SurveyBasicQuotaBuilder.State, action: SurveyBasicQuotaBuilder.Action) {
  if (process.env.VANCERY_ENV !== 'production') {
    console.groupCollapsed('Survey Quota Builder Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}