import type { PostMediaType } from '@enums';
import { safeJsonDate } from '@utils';
import type { Posts } from '@/types';

type PostItem<T = string> =
  & Posts.Post<T>
  & Partial<Posts.AdditionalDetails>;

export const normalizePost = <T extends PostItem<string> = PostItem<string>>(data: T) => {
  if (!data) return null;

  return {
    ...data,
    media: normalizeMedia(data.media),
    createdOn: safeJsonDate(data.createdOn),
  };
};

export const normalizeMedia = (data: Posts.Media.Media<PostMediaType, string>): Posts.Media.Media => {
  if (!data) return null;

  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
};