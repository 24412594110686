import { memo, useCallback } from 'react';
import { format } from 'date-fns';
import { useTagSummaryItemContext } from '@containers/ConferenceTag.Summary/context';
import type { ConferenceTagSummary as CTS } from '@/types';
import { SummaryFeedback, ViewCitations, ViewFeedback } from './Feedback';
import { Insights, UnorderedList } from './Insights';
import { RefreshSummary } from './RefreshSummary';
import { ViewSummaryVersions } from './ViewVersions';
import styles from './style/TagSummary.css';
import { TagSummaryContextMenu } from './TagSummary.ContextMenu';

type ContentProps = {
  item: CTS;
};

export const SummaryContent = ({ item }: ContentProps) => {
  return (
    <div>
      {item.valueBullets.length > 0
        ? <UnorderedList items={item.valueBullets.map(m => ({ text: m }))} />
        : item.value
      }
    </div>
  );
};

export const ConferenceTagSummary = memo(() => {

  const item = useTagSummaryItemContext();

  const renderSummary = useCallback(() => {

    if (!item.summary) return null;

    const generatedOn = format(item.summary.generatedOn, 'MM/dd/yyyy hh:mm a');

    return (
      <>
        <div className={styles.generated}>Last generated: {generatedOn}</div>
        <SummaryContent item={item.summary} />
        <div className={styles.footer}>
          <ViewCitations item={item} />
          <SummaryFeedback item={item} />
        </div>
      </>
    );
  }, [item]);

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>{item.tag.name}</div>
        <div className={styles.headerActions}>
          <TagSummaryContextMenu item={item} />
        </div>
      </div>
      <Insights
        className={styles.insights}
        item={item} />
      {renderSummary()}
    </div>
  );
});