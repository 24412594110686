import type { ProjectSubtype } from '@enums';
import { utils } from '@enums';
import { EnumDropDown } from '@/components/DropDown';
import { Field } from './Overview.Field';
import styles from './style/Overview.css';

type Props = {
  value: ProjectSubtype;
  label: string;
  onChange: (val: ProjectSubtype) => unknown;
  placeholder?: string;
  tooltip?: string;
  required?: boolean;
};

export const Subtype = (props: Props) => {

  return (
    <Field
      alert={props.required ? '*' : null}
      label={props.label}
      tooltip={props.tooltip}>
      <div className={styles.type}>
        <EnumDropDown<ProjectSubtype>
          sort={true}
          enum={utils.ProjectSubtype}
          onSelect={props.onChange}
          value={props.value} />
      </div>
    </Field>
  );
};

Subtype.displayName = 'Project.Creation.Details.Field.Subtype';