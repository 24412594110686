import { Fragment, useCallback, useContext, memo } from 'react';
import { Download } from 'react-feather';
import { Downloader } from '@containers/Downloader';
import {
  ExternalSurveyConfigurationContext,
  ExternalSurveyConfigurationMutationsContext,
  ExternalSurveyConfigurationRefetchContext,
  DownloadExternalSurveyLinksContext,
} from '@containers/Project.ExternalSurvey';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useAlert } from '@/components/Modal/Alert';
import { ExternalLinkUpload } from '@/components/ExternalLinkUpload';
import { RedirectLinks } from '$admin/components/ExternalSurvey';
import { SectionHeader } from './SectionHeader';
import rootStyles from './style.css';
import styles from './style/Section.Settings.Survey.External.css';

type Props = {
  allowEditSection: boolean;
} & IProjectId;

export const OffPlatformSurvey = ({
  allowEditSection,
  projectId,
}: Props) => {

  const handleDownload = useContext(DownloadExternalSurveyLinksContext);

  const configuration = useContext(ExternalSurveyConfigurationContext);
  const refetchConfig = useContext(ExternalSurveyConfigurationRefetchContext);

  const [toggleDelete, DeleteAlert, deleteAlertOpen] = useAlert();

  const {
    deleteUnassignedLinksMutation,
  } = useContext(ExternalSurveyConfigurationMutationsContext);

  const handleDeleteUnassignedLinks = useCallback(() => {
    return deleteUnassignedLinksMutation.mutateAsync(null, {
      onSuccess: async () => {
        await refetchConfig();
        if (deleteAlertOpen) toggleDelete();
      },
    });
  }, [refetchConfig, deleteAlertOpen, toggleDelete, deleteUnassignedLinksMutation]);

  const onExternalLinkUpload = useCallback(() => {
    refetchConfig();
  }, [refetchConfig]);

  return (
    <>
      <SectionHeader
        title="Off Platform Settings"
        canEdit={false}
        canSave={false}
        editing={false}
        onEdit={null}
        onReset={null}
        onSave={null} />
      <div className={rootStyles.grid}>
        <div className={rootStyles.section100}>
          <div className={rootStyles.label}>
            <LinksTitle
              numLinksAvailable={configuration.numLinksAvailable} />
          </div>
          {allowEditSection && (
            <div className={styles.downloadContainer}>
              Import Links
              <ExternalLinkUpload onSuccess={onExternalLinkUpload} />
            </div>
          )}
          <div className={styles.downloadContainer}>
            Export Links
            <Downloader
              onClick={handleDownload}>
              <Download
                className={styles.icon}
                size={20} />
            </Downloader>
          </div>
          {(allowEditSection && configuration.numLinksAvailable > 0) &&
            <div>
              <ButtonActivityIndicator
                variant='brick'
                color='delete'
                size='small'
                onClick={toggleDelete}
                loading={deleteUnassignedLinksMutation.isLoading}>
                Delete Links
              </ButtonActivityIndicator>
              <DeleteAlert
                message={'This will only delete unassigned links. Are you sure you want to delete?'}
                onConfirm={handleDeleteUnassignedLinks} />
            </div>
          }
        </div>
        <div className={rootStyles.section100}>
          <div className={rootStyles.label}>Redirect Links</div>
          <RedirectLinks
            projectId={projectId}
            externalSurveyConfiguration={configuration} />
        </div>
      </div>
    </>
  );
};

type TitleProps = {
  numLinksAvailable: number;
};

const LinksTitle = memo(({ numLinksAvailable }: TitleProps) => {

  const linksClassName = numLinksAvailable > 100 ? styles.linksAvailable : styles.linksLow;

  return (
    <Fragment>
      Off Platform Survey Links <span className={linksClassName}>({numLinksAvailable} links available)</span>
    </Fragment>
  );
});