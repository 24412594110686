import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';

export const useRankingOptionOpenEndedValue = (optionId: number) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Ranking>();

  const handleChange = useCallback((value: string) => {
    const openEndedValues = {
      ...answer.openEndedValues,
      [optionId]: value,
    };

    setAnswer({
      dontKnow: answer.dontKnow,
      options: answer.options,
      openEndedValues,
    });
  }, [setAnswer, answer, optionId]);

  const value = useMemo(() => {
    return answer.openEndedValues[optionId] || '';
  }, [answer.openEndedValues, optionId]);

  return [value, handleChange] as const;
};