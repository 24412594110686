import { useCallback } from 'react';
import queryString from 'query-string';
import { ConnectedAccountProvider, ConnectedAccountType } from '@enums';
import { useConnectUserAccount } from '@utils/api';
import type {
  ProviderSyncProps,
  SyncProps,
} from '@/components/ConnectAccount/interfaces';
import { useConnectedAccountContext } from '../Context';

const Scopes = {
  UserRead: 'https://graph.microsoft.com/User.Read',
  PeopleRead: 'https://graph.microsoft.com/People.Read',
  CalendarRead: 'https://graph.microsoft.com/Calendars.Read',
  CalendarReadWrite: 'https://graph.microsoft.com/Calendars.ReadWrite',
};

const useMicrosoftSync = ({
  onConnected,
  scopes,
  type,
}: ProviderSyncProps) => {
  const params = {
    provider: ConnectedAccountProvider.Microsoft,
    type,
  };
  const [loading, setLoading] = useConnectedAccountContext(params);

  const { mutateAsync } = useConnectUserAccount(params, {
    onSuccess: () => {
      setLoading(false);
      onConnected?.();
    },
    onError: () => setLoading(false),
  });

  const open = useCallback((): Promise<{ code: string }> => {
    const params = {
      client_id: process.env.MICROSOFT_SYNC_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.FRONT_BASE_URL}/_/connect`,
      response_mode: 'query',
      scope: scopes,
    };

    const qs = Object.keys(params).map(k => `${k}=${encodeURI(params[k])}`).join('&');
    const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${qs}`;

    const width = 500;
    const height = 550;

    const options = {
      resizable: 1,
      scrollbars: 1,
      width,
      height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2,
    };

    const optionsArray = Object.keys(options).map((k: keyof typeof options) => `${k}=${options[k]}`);

    const popup = window.open(url, '', optionsArray.join(','));

    if (popup) {
      popup.focus();
    }

    return new Promise((resolve, reject) => {
      const popupInterval = setInterval(() => {
        if (!popup || popup.closed) {
          clearInterval(popupInterval);
          /* eslint-disable-next-line prefer-promise-reject-errors */
          return reject({ reason: new Error('window closed') });
        }

        try {
          const params = queryString.parse(popup.location.search);
          const code = params.code as string;

          if (code) {
            clearInterval(popupInterval);
            popup.close();
            return resolve({ code });
          }
        } catch (e) {
          // console.error(e);
        }
      }, 100);
    });
  }, [scopes]);

  const connect = useCallback(() => {
    setLoading(true);

    open()
    .then(resp => {
      return mutateAsync({
        code: resp.code,
      });

    })
    .catch(err => {
      console.log('error: ', err);
      setLoading(false);
    });
  }, [
    mutateAsync,
    open,
    setLoading,
  ]);

  return {
    connect,
    isLoading: loading,
  };
};

export const useOutlookContactSync = (props?: SyncProps) => {
  const scopes = ['openid', 'email', 'profile', 'offline_access', Scopes.UserRead, Scopes.PeopleRead].join(' ');

  return useMicrosoftSync({
    onConnected: props?.onConnected,
    scopes,
    type: ConnectedAccountType.Contacts,
  });

};

export const useOutlookCalendarSync = (props?: SyncProps) => {
  const scopes = ['openid', 'email', 'profile', 'offline_access', Scopes.CalendarRead].join(' ');

  return useMicrosoftSync({
    onConnected: props?.onConnected,
    scopes,
    type: ConnectedAccountType.Calendar,
  });

};