import { useCallback } from 'react';
import type { UpdatePalette } from '@utils/api/branding';
import { useUpdateChartPaletteMutation as useUpdateChartPaletteMutationAPI } from '@utils/api/branding';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateChartPaletteMutation = (options: UpdatePalette.Options = {}) => {
  const helpers = useMutationHelpers({
    onSuccess: options.onSuccess,
  });

  const mutation = useUpdateChartPaletteMutationAPI({
    ...options,
    ...helpers,
  });

  return mutation;
};

const useMutationHelpers = (options: HelperOptions = {}) => {
  const accessor = useQueryDataAccessor();

  const onSuccess: HelperOptions['onSuccess']  = useCallback((res, variables, context) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        theming: {
          palettes: data.theming.palettes.map(palette => {
            if (palette.id !== variables.paletteId) return palette;

            return {
              ...palette,
              data: {
                ...palette.data,
                ...variables.data,
              },
              name: variables.name,
            };
          }),
          themes: data.theming.themes,
        },
      };
    });

    options.onSuccess?.(res, variables, context);
  }, [
    accessor,
    options,
  ]);

  return {
    onSuccess,
  };
};

type HelperOptions = Pick<UpdatePalette.Options, 'onSuccess'>;