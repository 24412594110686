import { ChevronDown } from 'react-feather';
import type { AccordionProps } from './interfaces';
import styles from './style.css';

type Props =
    Required<Pick<AccordionProps, 'height'>>;

const Icon = (props: Props) => {
  const size = props.height * 0.5;
  const rootStyle = {
    width: size,
    height: size,
  };

  return (
    <div
      className={styles.toggle}
      style={rootStyle}>
      <ChevronDown
        className={styles.icon}
        size={size} />
      <span className={styles.ripple}>
        <span className={styles.enter}>
          <span className={styles.exit} />
        </span>
      </span>
    </div>
  );
};

export { Icon };
export default Icon;