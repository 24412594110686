import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyActionType, SurveyConditionType, SurveyLogicRule, SurveyQuestionConditionType } from '@enums';
import type { SurveyLogic, SurveyQuestionOption } from '@/types';
import { SurveyAction } from '@/components/SurveyBuilder.Logic/Preview.Action';
import { SurveyMetadata } from '../Metadata';

export const useGetRankingOptionLogic = (questionLogic: SurveyLogic.Item[]) => {
  return useCallback((option: SurveyQuestionOption) => {
    const filteredLogic = questionLogic
    .filter(f => f.rule.type !== SurveyLogicRule.CustomExpression)
    .reduce((acc, x) => {
      if (x.conditions.length > 1) return acc;
      if (x.conditions[0].conditionType !== SurveyConditionType.Question) return acc;
      const condition = x.conditions[0].data as SurveyLogic.QuestionTypeConditionData<SurveyQuestionType.Ranking>;

      if ([SurveyQuestionConditionType.OptionRankedFirst, SurveyQuestionConditionType.OptionRankedLast].includes(condition.type)) {
        if ((condition as SurveyLogic.OptionRankedFirstCondition).value.option.identifier === option.base.identifier) {
          return acc.concat({
            action: x.action,
            condition: condition,
          });
        }
      }

      return acc;
    }, [] as {
      action: SurveyLogic.Action;
      condition: SurveyLogic.QuestionTypeConditionData<SurveyQuestionType.Ranking>;
    }[]);

    return filteredLogic.map((m, i) => {

      const Item = (
        <>
          <SurveyAction action={m.action} />
          {m.condition.type === SurveyQuestionConditionType.OptionRankedFirst
            ? ' if ranked first'
            : ' if ranked last'
          }
        </>
      );

      return (
        <SurveyMetadata
          key={i}
          items={[Item]}
          classes={{ red: m.action.type === SurveyActionType.Disqualification }} />
      );
    });
  }, [questionLogic]);
};