import { SurveySumConditionItemType } from '@/enums';
import type { SurveyLogic } from '@/types';
import type { SumConditionValidation, Validation } from './interfaces.validation';

export function validateSumCondition(condition: SurveyLogic.SumCondition): SumConditionValidation {
  const validation: SumConditionValidation = {
    comparate: {
      success: true,
    },
    items: {
      success: true,
    },
    operator: {
      success: true,
    },
  };

  if (condition.data.operator === null) {
    validation.operator = {
      message: 'Operator must be set',
      success: false,
    };
  }
  if (condition.data.comparate === null) {
    validation.comparate = {
      message: 'Value must be set',
      success: false,
    };
  }
  if (!condition.data.items.length) {
    validation.items = {
      message: 'List must have items',
      success: false,
    };
  }

  return validation;
}

export function validateSumConditionItems(items: SurveyLogic.SumConditionItem[]): Validation {

  if (items.length === 1) {
    const item = items[0];
    if (item.values.length < 2) {
      return {
        success: false,
        message: 'Must enter at least 2 values',
      };
    }
  }

  if (!items.length) {

    return {
      success: false,
      message: 'Must enter at least 2 values',
    };
  }

  const itemsValid = items.every(item => {

    if (item.question.identifier === '') return false;

    if (item.type === SurveySumConditionItemType.NumberTableCellValue) {
      return item.values.every(value => {
        return !!value.row.identifier && !!value.option.identifier;
      });
    } else if (item.type === SurveySumConditionItemType.SliderRowValue) {
      return item.values.every(value => {
        return !!value.row.identifier;
      });
    }
    return true;
  });

  if (!itemsValid) {
    return {
      success: false,
      message: 'Some items are missing values',
    };
  }

  return {
    success: true,
  };
}