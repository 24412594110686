import { StarRatingPreview } from '@/components/StarRating';
import type { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <StarRatingPreview rating={props.row.original.user.avgProjectRating} />
  );
};

Cell.displayName = 'Column.Rating.Cell';