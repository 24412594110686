import { useCallback, useEffect, useMemo, useState } from 'react';
import { format, startOfDay } from 'date-fns/fp';
import { addMinutes, endOfDay, isPast, isToday, formatDuration, set, subMinutes } from 'date-fns';
import { getCurrentTimezone } from '@utils';
import type { CalendarRange } from '@/types';
import { DatePicker, TimePicker } from '@/components/DatePicker';
import { Focusable } from '@/components/Focusable';
import type { OnSelectChange } from '$admin/components/Select';
import { SelectUnderlined } from '$admin/components/Select';
import styles from './style/Call.TimePicker.css';

type Props = {
  duration:  number;
  end:       Date;
  interval?: number;
  onChange:  (data: OnChangeParams) => void;
  start:     Date;
};

export const CallTimePicker = ({ interval = 5, onChange, ...props }: Props) => {
  const [day, setDay] = useState<Date>(isPast(props.start) ? new Date() : props.start);
  const [duration, setDuration] = useState(props.duration);
  const [range, setRange] = useState(getRange(day, duration, resolveInitial(props)));

  const setTime = useCallback((date: Date) => {
    const updated = {
      end: addMinutes(date, duration),
      start: date,
    };
    setRange(updated);

    onChange({
      duration,
      end: updated.end,
      start: updated.start,
    });

  }, [
    duration,
    onChange,
  ]);

  const setDate = useCallback((date: Date) => {
    const time = getRange(date, duration, range);

    setDay(date);
    setRange(time);

    onChange({
      duration,
      end: time.end,
      start: time.start,
    });
  }, [
    duration,
    range,
    onChange,
  ]);

  const start = useMemo(() => {
    return isToday(day)
      ? getDayStartTime(day)
      : startOfDay(day);
  }, [day]);

  const handleChangeDuration: OnSelectChange<number> = useCallback(e => {
    const updated = {
      duration: e.target.value,
      end: addMinutes(range.start, e.target.value),
      start: range.start,
    };
    setDuration(updated.duration);

    setRange({
      end: updated.end,
      start: updated.start,
    });

    onChange(updated);
  }, [
    onChange,
    range,
    setDuration,
    setRange,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <div className={styles.label}>Date</div>
            <Focusable>
              <div className={styles.datepicker}>
                <DatePicker
                  minDate={new Date()}
                  selected={day}
                  onChange={setDate} />
              </div>
            </Focusable>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Start Time</div>
            <Focusable>
              <div className={styles.datepicker}>
                <TimePicker
                  dateFormat="hh:mm aa"
                  minDate={start}
                  minTime={start}
                  maxTime={endOfDay(day)}
                  onChange={setTime}
                  renderCustomHeader={null}
                  selected={range.start}
                  showPopperArrow={false}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="hh:mm aa"
                  timeIntervals={interval} />
              </div>
            </Focusable>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>End Time</div>
            <div className={styles.end}>{formatTime(range.end)}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Duration</div>
            <div className={styles.duration}>
              <SelectUnderlined
                classes={{
                  root: styles.select,
                  select: styles.input,
                }}
                options={Durations}
                onChange={handleChangeDuration}
                value={duration} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.timezone}>
        {`All times displayed in ${getCurrentTimezone()}.`}
      </div>
    </div>
  );
};

CallTimePicker.displayName = 'CallTimePicker';

function formatSlotDuration(minutes: number) {
  return formatDuration({ minutes }, { format: ['minutes'] });
}

const formatTime = format('h:mm a');

type Options = {
  interval:   number;
  maxEndTime: Date;
};

function createRange(start: Date, options: Options) {
  const incremented = addMinutes(start, options.interval);

  if (incremented <= options.maxEndTime) {
    return {
      start,
      end: incremented,
    };
  }

  return getLastRange(options.maxEndTime, options.interval);
}

function getLastRange(end: Date, interval: number) {
  return {
    start: subMinutes(end, interval),
    end,
  };
}

function getDayStartTime(date: Date, interval = 5) {
  const resolvedDate = isToday(date)
              ? new Date()
              : startOfDay(date);

  const coeff = 1000 * 60 * interval;
  return new Date(Math.ceil(resolvedDate.getTime() / coeff) * coeff);
}

function getRange(date: Date, interval: number, range?: CalendarRange<Date>) {
  if (range) {

    const earliest = getDayStartTime(date);

    const preserved = set(range.start, {
      month: date.getMonth(),
      date: date.getDate(),
      year: date.getFullYear(),
    });

    const start = preserved < earliest
      ? earliest
      : preserved;

    return {
      end: addMinutes(start, interval),
      start,
    };
  }

  const start = getDayStartTime(date);

  const segment = createRange(start, {
    interval,
    maxEndTime: endOfDay(date),
  });

  return {
    end: segment.end,
    start: segment.start,
  };
}

function resolveInitial(range?: CalendarRange<Date>) {
  return range?.start && range?.end && !isPast(range.start)
    ? range
    : undefined;
}

const Durations = [15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240].map(value => ({
  name: formatSlotDuration(value),
  id: value,
}));

export type OnChangeParams = {
  duration: number;
  end:      Date;
  start:    Date;
};