import { useCallback, useMemo, memo } from 'react';
import { Checkbox } from '@/components/Checkbox';
import type { Results } from '../interfaces/members';
import { IndeterminateCheckbox } from './Indeterminate.Checkbox';
import { useSelected } from './hooks/useSelected';

type Props = Results.TableCellProps;

export const Cell = ({ row }: Props) => {
  const [selected, setSelected] = useSelected();

  const isChecked = useMemo(() => selected.ids.has(row.original.user.id), [selected, row.original.user.id]);

  const handleChange = useCallback((e: React.ChangeEvent) => {
    setSelected(prev => {
      const mapped = {
        user: row.original.user,
      };

      if (isChecked) {
        prev.ids.delete(mapped.user.id);
      } else {
        prev.ids.add(mapped.user.id);
      }

      const itemsCopy = isChecked
        ? prev.items.filter(i => i.user.id !== mapped.user.id)
        : [...prev.items, mapped];

      return {
        ids: prev.ids,
        items: itemsCopy,
      };
    });
  }, [isChecked, row.original, setSelected]);

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleChange} />
  );
};

export const Filter = memo(({ rows }: Props) => {
  return <IndeterminateCheckbox rows={rows} />;
});

export const Header = memo(() => null);

Cell.displayName = 'Column.Checkbox.Cell';
Filter.displayName = 'Column.Checkbox.Filter';
Header.displayName = 'Column.Checkbox.Header';