import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api';
import type { GetRecentFiles } from '@api/interfaces/users';

type Data = GetRecentFiles.Response;

export default function useRecentFiles<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery({ queryKey: [querykey.Users.RecentFiles], queryFn: async () => {
    return api.users.getRecentFiles();
  }, ...options });
}

export { useRecentFiles };