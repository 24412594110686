import type { IConference } from '@containers/Conference';
import { useConferenceInstance } from '@containers/Conference';
import * as utils from './utils';
import styles from './style/WaitingRoom.css';

export function Expired() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{`${instance.conference.title} Call`}</div>
        <div className={styles.date}>
          {utils.formatCallTime(instance.call.start, instance.call.end)}
        </div>
        <div className={styles.text}>{`This conference has completed.`}</div>
      </div>
    </div>
  );
}