import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import type { Permission } from '@/enums';
import { updateUserPermissions } from '$admin/api/users';
import type { UserPermissions } from '$admin/interfaces';

export const useUpdateUserPermissions = (props: Props, options?: Options) => {

  return useMutation({ mutationKey: ['admin.update-user-permissions'], mutationFn: ({ permissions }) => {
    return updateUserPermissions({
      permissions,
      userId: props.userId,
    });
  }, ...options });
};

type Props = IUserId;
type Data = {
  permissions: UserPermissions;
};
type Vars = {
  permissions: Permission[];
};
type Options = UseMutationOptions<Data, AxiosResponse, Vars>;