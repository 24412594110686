import { useContext, useState } from 'react';
import { X } from 'react-feather';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import type { MatrixSliderQuestion, SurveyFiltering } from '@/types';
import type { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import type { SurveyQuestionMatrixRow } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { FilterProps } from './utils';
import styles from './style/SurveyResponseFilter.css';
import { Selector } from './Selector';
import { CommonRow } from './CommonRow';
import { useFilterQuestion } from './hooks/useFilterQuestion';

export const SliderFilter = ({ filterEntry, filterActions }: FilterProps<SurveyQuestionType.Sliders>) => {
  const filterContext = useContext(ProjectResponseFilterContext);

  const [greaterThan, setGreaterThan] = useState<boolean>(filterEntry.filter ? filterEntry.filter.greaterThan : true);
  const [optionId, setOptionId] = useState(filterEntry.filter?.optionId);
  const [filterValue, setFilterValue] = useState(filterEntry.filter?.value);
  const question = useFilterQuestion(filterEntry);
  const sliderOptions = question.matrixRows as MatrixSliderQuestion.Row[];

  function passbackFilter(partialFilter: Partial<SurveyFiltering.QuestionFilter<SurveyQuestionType.Sliders>>) {
    filterActions.changeFilter({
      ...filterEntry,
      filter: {
        ...{
          type: 'slider-filter',
          greaterThan,
          value: filterValue,
          optionId,
        }, ...partialFilter,
      },
    });
  }

  function changeGreaterThan(v: boolean) {
    setGreaterThan(v);
    passbackFilter({ greaterThan: v });
  }

  function changeFilterValue(v: string) {
    const value = parseInt(v);
    setFilterValue(value);
    passbackFilter({ value });
  }

  function changeOption(v: SurveyQuestionMatrixRow) {
    setOptionId(v.base.id);
    passbackFilter({ optionId: v.base.id });
  }

  return (
    <CommonRow filterEntry={filterEntry} filterActions={filterActions}>
      <Selector
        buttonClass={styles.optionSelectorButton}
        items={sliderOptions}
        displayValue={v => v ? parseSurveyRichText(v.value) : 'Select an option'}
        onSelect={changeOption}
        value={sliderOptions.find(o => o.base.id == filterEntry.filter?.optionId)} />
      <Selector
        items={[true, false]}
        displayValue={v => v ? 'Greater than' : 'Less than'}
        onSelect={changeGreaterThan}
        value={greaterThan} />
      <input
        className={cx(styles.filterRowInput, styles.fancyTextInput)}
        type={'number'}
        onChange={e => changeFilterValue(e.target.value)}
        value={filterValue}
        placeholder={'Number'} />
    </CommonRow>);
};