import type { IconProps } from 'react-feather';
import { Check as CheckFeather } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  checkProps?: IconProps;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  size?: number;
};

const Check = ({
  checkProps = {},
  ...props
}: Props) => {
  const { className: checkClassName, ...otherCheckProps } = checkProps;

  return (
    <div
      className={cx(styles.root, props.className, props.disabled && styles.disabled)}
      onClick={props.disabled ? () => { } : props.onClick}>
      <CheckFeather
        className={cx(styles.check, checkClassName)}
        size={props.size}
        {...otherCheckProps} />
    </div>
  );
};

export { Check };
export default Check;