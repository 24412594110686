import { io } from 'socket.io-client';
import type { UserSocketEvent } from '@enums';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import { createLogger, resolveSocketOptions } from './utils';
import type { User } from './interfaces';

const log = createLogger(SocketNamespace.User);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.User}`, resolveSocketOptions());

function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, (...args) => log.event(LifeCycleSocketEvent.ConnectError, args));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.prependAny((event: UserSocketEvent, payload: unknown) => log.event(event, payload));
  socket.open();
}

function close() {
  socket.close();

  socket.offAny();
  socket.removeAllListeners();
}

const on = <T extends UserSocketEvent>(event: T, handler: User.RegisterHandler<T>) => {
  return socket.on<UserSocketEvent | LifeCycleSocketEvent>(event, handler);
};

const off = <T extends UserSocketEvent>(event: T, handler: User.RegisterHandler<T>) => {
  return socket.off<UserSocketEvent | LifeCycleSocketEvent>(event, handler);
};

const user = {
  close,
  off,
  on,
  open,
};

export { user };
export default user;