import { createContext } from 'react';
import type { UseMutationResult } from '@tanstack/react-query';
import { createNamedContext } from '@utils';
import type {
  HonorariaEditingState,
  RateContextValue,
  ContextValue,
  RefetchHonoraria,
} from './interfaces';

export const ProjectHonorariaRefetchContext = createContext<RefetchHonoraria>(null);
export const ProjectHonorariaMutationContext = createContext<UseMutationResult>(null);
export const ProjectHonorariaEditingContext = createContext<HonorariaEditingState>(null);

export const ProjectHonorariaContext = createNamedContext<ContextValue>(undefined, 'Project.Honoraria.Context');
export const ProjectHonorariaRateContext = createNamedContext<RateContextValue>(undefined, 'Project.Honoraria.Rate.Context');