import http from '@services/http';
import type { Users } from './interfaces';

export const getConnectedAccounts = () => {
  return http.get<Users.GetConnectedAccounts.Response>(`/users/connected-accounts`);
};

export const connectAccount = (data: Users.ConnectAccount.Request): Promise<Users.ConnectAccount.Response> => {
  return http.post(`/users/connected-accounts`, data);
};

export const disconnectAccount = (data: Users.DisconnectAccount.Request) => {
  return http.delete(`/users/connected-accounts/${data.id}`);
};