import { createElement, useCallback, useMemo, Fragment } from 'react';
import { usePointStyle, useSerieStyle, useChartEventHandlers } from './hooks';
import type { EnhancedSerie, EnhancedPoint, CustomChartLayerProps } from './interfaces';
import SerieLineItem from './SerieLineItem';
import SeriePoint from './SeriePoint';

export const CustomChartLayer = ({
  lineGenerator,
  points: rawPoints,
  series: rawSeries,
  onPointClick,
  onSerieClick,
  PointsTooltip,
  SeriesTooltip,
}: CustomChartLayerProps) => {

  const { activePoints, activeSeries, pointHandlers, serieHandlers } = useChartEventHandlers({
    onPointClick,
    onSerieClick,
    points: rawPoints,
    series: rawSeries,
    PointsTooltip,
    SeriesTooltip,
  });

  const getSerieStyle = useSerieStyle({ activeSeries });
  const getPointStyle = usePointStyle({ activePoints, activeSeries });

  const series: EnhancedSerie[] = useMemo(() => {

    return rawSeries
      .map(serie => ({
        ...serie,
        style: getSerieStyle(serie),
      }));
  }, [
    getSerieStyle,
    rawSeries,
  ]);

  const points: EnhancedPoint[] = useMemo(() => {

    return rawPoints.map(p => ({
      ...p,
      style: getPointStyle(p),
    }));
  }, [
    getPointStyle,
    rawPoints,
  ]);

  const renderLines = useCallback(() => {
    return series
      .map(serie => (
        <SerieLineItem
          key={serie.id}
          serie={serie}
          onClick={serieHandlers.onClick}
          onMouseEnter={serieHandlers.onMouseEnter(serie)}
          onMouseLeave={serieHandlers.onMouseLeave}
          onMouseMove={serieHandlers.onMouseMove(serie)}
          lineGenerator={lineGenerator} />
      ));
  }, [
    lineGenerator,
    series,
    serieHandlers,
  ]);

  const renderPoints = useCallback(() => {
    return points.map(point => {
      return createElement(SeriePoint, {
        key: point.id,
        point,
        onClick: pointHandlers.onClick,
        onMouseEnter: pointHandlers.onMouseEnter(point),
        onMouseLeave: pointHandlers.onMouseLeave,
        onMouseOver: pointHandlers.onMouseOver(point),
      });
    });
  }, [
    pointHandlers,
    points,
  ]);

  return (
    <Fragment>
      {renderLines()}
      {renderPoints()}
    </Fragment>
  );

};

export default CustomChartLayer;