import { useCallback } from 'react';
import { ResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import { Accordion } from '@/components/Accordion';
import styles from './style/Matrix.Data.css';
import type { MatrixGroupItem } from './interfaces';

type Props = {
  items: MatrixGroupItem[];
  onClick: (rowId: number, key: string) => void;
};

function formatOptionSubtitle(count: number) {
  return `(${count} ${formatPlurality('Respondent', count)})`;
}

export const MatrixAggregateData = ({ items, onClick }: Props) => {

  const renderItem = useCallback((item: MatrixGroupItem) => {
    return (
      <Accordion
        key={item.id}
        collapsed
        height={50}
        grows={false}
        className={styles.row}
        showCount={false}
        label={item.name}>
        {item.options.map(o => {
          const count = o.userIds.length;
          const subtitle = formatOptionSubtitle(count);

          return (
            <ResponseOptionRow
              key={o.id}
              className={styles.selectableOption}
              color={o.color}
              label={o.name}
              subtitle={subtitle}
              onClick={() => onClick(item.id, o.id.toString())} />
          );
        })}
      </Accordion>
    );
  }, [onClick]);

  return (
    <>
      {items.map(renderItem)}
    </>
  );
};

export default MatrixAggregateData;