import { Fragment, useCallback } from 'react';
import type { Node } from 'slate';
import {
  useVideoPostCanPublish,
  useVideoPostObjectURL,
  useVideoPostPublish,
  useVideoPostStateContext,
  useVideoPostStepperContext,
  useVideoPostSubmitState,
} from '@containers/PostCreation/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Button } from '@/components/Button';
import * as Post from '@/components/ContentFeed/Post';
import * as Editor from './Editor';
import * as Publisher from './Publisher';
import { TopicTags } from './Topics.Tags';
import { NativePlayerPoster } from './Video.NativePlayerPoster';
import styles from './style/Video.ShareForm.MediaPreview.css';

type Props = unknown;

export const ShareForm = (props: Props) => {
  const [state, dispatch] = useVideoPostStateContext();
  const { thumbnail } = useVideoPostObjectURL();
  const actions = useVideoPostStepperContext();
  const publish = useVideoPostPublish();
  const submitState = useVideoPostSubmitState();

  const handleChangeBody = useCallback((value: Node[]) => {
    dispatch({
      type: 'update-post-body',
      value: { value },
    });
  }, [dispatch]);

  const canPublish = useVideoPostCanPublish();

  const src = thumbnail || state.snapshot;

  return (
    <Fragment>

      <Publisher.Header>
        Create Post
      </Publisher.Header>

      <Publisher.Author className={styles.author} />

      <div className={styles.shared}>
        <Editor.TextField
          minHeight={40}
          placeholder="Add a comment about your video"
          onChange={handleChangeBody}
          value={state.body.value} />
      </div>

      <div className={styles.post}>
        {src &&
          <div className={styles.preview}>
            <img
              className={styles.img}
              src={src} />
          </div>}
        {!src &&
          <NativePlayerPoster className={styles.video} />}

        <div className={styles.title}>{state.title}</div>

        <div className={styles.body}>
          <Post.Body
            maxLines={3}
            value={state.description.value} />
        </div>
      </div>

      <TopicTags className={styles.topics} />

      <Publisher.Footer>
        <Button
          className={styles.back}
          disabled={submitState.submitting}
          onClick={actions.back}
          variant="text">
          Back
        </Button>
        <ButtonActivityIndicator
          className={styles.next}
          classes={{
            indicator: styles.indicator,
            root: styles.next,
          }}
          color="affirmative"
          disabled={!canPublish}
          implicitDisable={submitState.submitting}
          loading={submitState.submitting}
          onClick={publish}
          variant="brick">
          Post
        </ButtonActivityIndicator>
      </Publisher.Footer>
    </Fragment>
  );
};

ShareForm.displayName = 'PostCreation.Frame.Video.ShareForm';