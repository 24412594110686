import type { IconProps as FeatherProps } from 'react-feather';
import { X } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  crossProps?: FeatherProps;
  className?: string;
  onClick?: () => void;
  size?: number;
};

const XCircle = ({
  crossProps = {},
  ...props
}: Props) => {
  const { className: crossClassName, ...otherCrossProps } = crossProps;

  return (
    <div
      className={cx(styles.root, props.className)}
      onClick={props.onClick}>
      <X
        className={cx(styles.cross, crossClassName)}
        size={props.size}
        {...otherCrossProps} />
    </div>
  );
};

export { XCircle };
export default XCircle;