import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { useProjectFormContext } from '@screens/ProjectCreation/context';
import { BackButton, Footer, NextButton } from './Footer';
import { ProjectFiltersContainer } from './Filters.Container';
import { useSubmitNewProject } from './hooks/useSubmitNewProject';
import styles from './style.css';

type Props = unknown;

const FiltersWithRef = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const [form, setFormState] = useProjectFormContext();
  const [submitProject, isSubmitting] = useSubmitNewProject();

  return (
    <ProjectFiltersContainer
      form={form}
      onChange={setFormState}
      ref={ref}>
      <Footer>
        <BackButton />
        <NextButton
          className={styles.complete}
          disabled={isSubmitting}
          onClick={submitProject}>
          Complete
        </NextButton>
      </Footer>
    </ProjectFiltersContainer>
  );
};

FiltersWithRef.displayName = 'Project.Creation.Frame.Filters';

export const Filters = forwardRef(FiltersWithRef);