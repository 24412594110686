import { memo } from 'react';
import type { SurveyLogic } from '@/types/survey';
import styles from './style/Preview.Name.css';

type Props = {
  item: SurveyLogic.Item;
  ordinal: number;
};

export const LogicBuilderNamePreview = memo(({ item, ordinal }: Props) => {

  return (
    <div className={styles.root}>
      Logic Item {ordinal}
    </div>
  );
});