import { useContext, useMemo, useState, useCallback } from 'react';
import { Edit2 } from 'react-feather';
import { useQuery } from '@tanstack/react-query';
import type { ColumnDef, CellContext, SortingState } from '@tanstack/react-table';
import { useReactTable, getCoreRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import * as api from '@api';
import { utils as enumUtils } from '@enums';
import { TranscriptIdContext, TranscriptEntitiesContext, TranscriptHydrationContext } from '@containers/Transcript/context';
import { useTranscriptToolsPermission } from '@containers/Transcript/hooks/useTranscriptToolsPermission';
import { EntitiesTableEmpty } from '@screens/Project.Entities/Entities.Table.Empty';
import { cx } from '@utils';
import type { Transcribe } from '@/types';
import { Input } from '@/components/Input';
import * as Table from '@/components/Table';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import { EditEntitiesModal } from './Modal.EditEntity';
import styles from './style/Entities.css';

type Props = unknown;

export const Entities = (props: Props) => {
  const [editing, setEditing] = useState<Pick<Transcribe.Entity, 'entityType' | 'id' | 'value'>>(null);
  const entities = useContext(TranscriptEntitiesContext);
  const hydration = useContext(TranscriptHydrationContext);
  const transcriptId = useContext(TranscriptIdContext);
  const permission = useTranscriptToolsPermission();

  const types = useQuery({
    queryKey: [`get:search/transcriptions/entities`],
    queryFn: api.search.transcriptions.fetchEntityTypes,
    refetchOnWindowFocus: false,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const onEntityClick = useCallback((entity: typeof editing) => {
    setEditing(entity);
  }, []);

  const columns = useMemo<ColumnDef<Transcribe.Entity>[]>(() => [
    {
      id: 'entity',
      header: ({ column }) => {
        return (
          <SortableHeader
            className={styles.entity}
            onClick={column.getToggleSortingHandler()}
            sortable={column.getCanSort()}
            sorted={column.getIsSorted()}>
            Entity
            <Input
              classes={{
                root: styles.filter,
              }}
              placeholder='Filter'
              value={column.getFilterValue() as string}
              onChange={e => column.setFilterValue(e.target.value)}
              onClick={e => e.stopPropagation()} />
          </SortableHeader>
        );
      },
      filterFn: 'includesString',
      accessorFn: entity => entity.value,
      cell: (props: CellContext<Transcribe.Entity, Transcribe.Entity>) => {
        return (
          <div className={styles.name}>
            {permission.entity &&
              <button
                className={styles.edit}
                onClick={() => onEntityClick(props.row.original)}>
                <Edit2 className={styles.icon} />
              </button>}
            {!permission.entity && <div className={styles.edit} />}
            <div className={styles.value}>{props.row.original.value}</div>
          </div>
        );
      },
    },
    {
      id: 'type',
      header: ({ column }) => {
        return (
          <SortableHeader
            onClick={column.getToggleSortingHandler()}
            sortable={column.getCanSort()}
            sorted={column.getIsSorted()}>
            Type
          </SortableHeader>
        );
      },
      accessorFn: entity => enumUtils.TranscriptEntityType.getName(entity.entityType),
    },
    {
      id: 'occurrencesCount',
      header: ({ column }) => {
        return (
          <SortableHeader
            onClick={column.getToggleSortingHandler()}
            sortable={column.getCanSort()}
            sorted={column.getIsSorted()}>
            Occurrences
          </SortableHeader>
        );
      },
      accessorFn: entity => entity.occurrences.length,
    },
  ], [permission.entity, onEntityClick]);

  const loading = (entities.query.isInitialLoading
    && !entities.query.data)
    && !(hydration.state.entities
      && hydration.state.tags)
    || entities.query.isLoading;

  const data = useMemo(() => {
    return [...(loading
      ? getLazyTableData(25)
      : entities.query.data?.entities ?? []
    )].sort((a, b) => b.occurrences?.length - a.occurrences?.length);
  }, [loading, entities.query.data?.entities]);

  const empty = entities.query.isFetchedAfterMount && !loading && !data.length;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: !loading ? 1 : -1,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const paginationProps = {
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageIndex: table.getState().pagination.pageIndex,
    nextPage: table.nextPage,
    pageSize: table.getState().pagination.pageSize,
    previousPage: table.previousPage,
    pageCount: data.length || 0,
    pageOptions: table.getPageOptions(),
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Table.Layout.Box>
          <Table.Layout.Header title="Entities" />

          <Table.Root
            EmptyComponent={EntitiesTableEmpty}
            empty={empty}
            maxScrollHeight={224}
            loading={loading}>
            <Table.Header
              classes={{
                thead: styles.thead,
                tr: styles.tr,
                th: styles.th,
              }}
              getHeaderGroups={table.getHeaderGroups} />
            <Table.Body
              classes={{
                tr: styles.tr,
              }}
              getRowModel={table.getRowModel} />
            <Table.Layout.Footer>
              <Pagination {...paginationProps} />
            </Table.Layout.Footer>
          </Table.Root>
        </Table.Layout.Box>
      </div>
      {editing &&
        <EditEntitiesModal
          transcriptId={transcriptId}
          id={editing.id}
          onClose={() => setEditing(null)}
          type={editing.entityType}
          types={types.data?.items ?? []}
          value={editing.value} />}
    </div>
  );
};

Entities.displayName = 'Transcript.Tab.Entities';

const getLazyTableData = <T extends Transcribe.Entity>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};

type SortableHeaderProps = {
  children: React.ReactNode;
  className?: string;
  onClick: (e: unknown) => void;
  sortable: boolean;
  sorted: 'asc' | 'desc' | false;
};

const SortableHeader = (props: SortableHeaderProps) => {
  const desc = props.sorted === 'desc';

  return (
    <div
      className={cx(styles.sortable, props.className)}
      onClick={props.onClick}>
      {props.children}
      {(props.sorted && !desc) && <ArrowDropUpIcon />}
      {(props.sorted && desc) && <ArrowDropDownIcon />}
    </div>
  );
};