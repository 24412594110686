import DeleteIcon from '@mui/icons-material/HighlightOff';
import { format } from 'date-fns/fp';
import { createConditional } from '@containers/Conditional';
import { cx } from '@utils';
import type { TimeslotProps } from './interfaces';
import styles from './style.css';

type Props = {
  onDelete: (info: TimeslotProps) => unknown;
} & TimeslotProps;

const Timeslot = (props: Props) => {
  const fTime = format('hh:mm a');

  function formatTime(datestring: Date | string) {
    return fTime(new Date(datestring));
  }

  function handleDelete() {
    props.onDelete({
      item: props.item,
      section: props.section,
    });
  }

  function RemoveEventButton() {
    return (
      <button
        className={styles.remove}
        onClick={handleDelete}>
        <DeleteIcon />
      </button>
    );
  }

  const CanDeleteTimeslot = createConditional(!props.item.isBusy, <div className={styles.remove} />);

  const className = cx(styles.row, {
    [styles.busy]: props.item.isBusy,
  });

  return (
    <div className={className}>
      <CanDeleteTimeslot>
        <RemoveEventButton />
      </CanDeleteTimeslot>
      <div className={styles.start}>{formatTime(props.item.start)}</div>
      <div className={styles.end}>{formatTime(props.item.end)}</div>
    </div>
  );
};

export { Timeslot };
export default Timeslot;