import { useState } from 'react';
import * as api from '@services/api';
import Toast from '@/components/Toast';
import type { ReferralModalOnSendProps } from '@/components/Modal/Referral';
import { ReferralExpertModal as InviteModal } from '@/components/Modal/Referral';
import styles from './style/InviteContacts.css';

const InviteContacts = () => {
  const [modal, setModal] = useState(false);

  const handleClick = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleSend = (data: ReferralModalOnSendProps) => {
    toggleModal();

    const multiple = data.emails.length > 1;

    Toast.alert({
      title: multiple
             ? 'Invites Sent'
             : 'Invite Sent',
      body: multiple
            ? 'Your contacts will receive your invitations shortly.'
            : 'Your contact will receive your invitation shortly.',
    });

    api.user.sendReferral({
      emails: data.emails,
      note: data.note,
      referral: data.referral,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.copy}>
        Invite contacts to join Sentiment to grow your industry reach.
      </div>
      <button
        className={styles.btn}
        onClick={handleClick}>
        Add Contacts
      </button>
      <InviteModal
        onClose={toggleModal}
        onSend={handleSend}
        visible={modal}
        subtitle={`Invite your peers to join our network.`} />
    </div>
  );
};

export { InviteContacts };
export default InviteContacts;