import { format } from 'date-fns/fp';
import { cx } from '@utils';
import type { MessageComponentType, PlatformMessage } from './interfaces';
import styles from './style/ThreadMessage.css';

type Props = {
  children:   MessageComponentType;
  className?: string;
  item:       PlatformMessage;
};

const ThreadMessage = (props: Props) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.header}>

            <div className={styles.info}>
              <div className={styles.sender}>{props.item.sender.name}</div>
              <div className={styles.date}>{format('M/d/y h:mm a', props.item.message.createdOn)}</div>
            </div>

            {props.children}

          </div>

          <div className={styles.body}>{props.item.message.body}</div>

        </div>
      </div>
    </div>
  );
};

export { ThreadMessage };
export default ThreadMessage;