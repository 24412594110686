import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConferenceTagType } from '@enums';
import { cx } from '@utils';
import { useLiveRoomScreen } from '@containers/Conference';
import { TaggingContext } from './Context';
import { Action, ColorSelect, Input, TimeRange } from './Tagging';
import type { Tagging } from './interfaces';
import styles from './style/Tagging.Tag.css';

type Props = {
  className?: string;
  item: Omit<Tagging.Tag, 'identifier' | 'typeId'>;
} & Pick<Tagging.Tag, 'identifier'>
  & Pick<Tagging.Context,
    | 'discard'
    | 'remove'
    | 'setFocus'
    | 'setValue'>;

export const Editable = ({ item, identifier, remove, setFocus, ...props }: Props) => {
  const [saved, setSaved] = useState(false);
  const { mutation } = useContext(TaggingContext);
  const ref = useRef<HTMLDivElement>(null);
  const liveRoomScreen = useLiveRoomScreen();

  const handleSave = useCallback(() => {
    if (saved) return;

    mutation.mutateAsync({
      identifier,
      item: {
        color: item.color,
        start: item.start,
        end: new Date(),
        id: item.id,
        name: item.name,
        typeId: ConferenceTagType.UserCreated,
      },
    }, {
      onSuccess: (res, variables, context) => {
        setSaved(true);
      },
    });
  }, [
    identifier,
    item,
    mutation,
    saved,
    setSaved,
  ]);

  const removeSelfAfterSave = useCallback(() => {
    remove(identifier);
  }, [
    identifier,
    remove,
  ]);

  useEffect(() => {
    const node = ref.current;

    if (node !== null) {
      node.addEventListener('animationend', removeSelfAfterSave);
    }

    return () => {
      node?.removeEventListener('animationend', removeSelfAfterSave);
    };
  }, [
    removeSelfAfterSave,
  ]);

  return (
    <div
      className={cx(styles.root, props.className, {
        [styles.saved]: saved,
      })}
      ref={ref}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.row}>
            <Input
              color={item.color}
              id={item.id}
              name={item.name} />
            {!item.start
              ? <Action.Discard onClick={() => props.discard(identifier)} />
              : <Action.Collapse onClick={() => setFocus(null)} />}
          </div>

          <div className={styles.row}>
            <ColorSelect
              color={item.color}
              id={item.id}
              onChange={color => props.setValue('color', color)}
              popperAnchor={liveRoomScreen.containerRef.current} />
            <div className={styles.controls}>
              <TimeRange
                end={item.end}
                start={item.start} />
              {!item.start &&
                <Action.Start onClick={() => props.setValue('start', new Date())} />}
              {!!item.start &&
                <Action.End
                  disabled={!item.name?.trim()?.length}
                  loading={mutation.isLoading && mutation.variables.identifier === identifier}
                  onClick={handleSave}
                  saved={saved} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Editable.displayName = 'Tagging.Tag.Editable';