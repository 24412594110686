import { useProjectSurveyResponsesState } from '@/containers/SurveyResponses/hooks';
import type { SurveyQuestionType } from '@/enums';
import { MatrixRangeQuestion } from '@/types';
import { MatrixRangeRowData as RowData } from './MatrixRange.Data.Row';
import { MatrixRangeOptionData as OptionData } from './MatrixRange.Data.Option';
import styles from './style/MatrixRange.Data.css';

export const MatrixRangeData = () => {

  const { query } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixRange>();

  if (query.rowId && query.optionId) {
    return <OptionData />;
  } else if (query.rowId) {
    return <RowData />;
  } else {
    return (
      <div className={styles.placeholder}>
        Click a row to view more details.
      </div>
    );
  }
};