import { Label } from '@presentation/Label';
import { cx } from '@utils';
import type { UserEmploymentProps } from './interfaces';
import styles from './style/UserEmployment.css';

type Props =
  UserEmploymentProps;

const Title = (props: Pick<Props, 'title'>) => {
  return <>{props.title}</>;
};

const Company = (props: Pick<Props, 'company' | 'title'>) => {
  return props.company
         ? <>{`${props.title ? ' at' : ''} ${props.company}`}</>
         : null;
};

const Tenure = (props: Pick<Props, 'tenure'>) => {
  return props.tenure
         ? <span className={styles.tenure}>{` (${props.tenure})`}</span>
         : null;
};

export const UserEmploymentText = (props: Omit<Props, 'label'>) => (
  <span className={cx(styles.text, props.className)}>
    <Title {...props} />
    <Company {...props} />
    <Tenure {...props} />
  </span>
);

export const UserEmployment = (props: Props) => {
  if (!props.label) {
    return (
      <UserEmploymentText {...props} />
    );
  }

  return (
    <Label label={props.label}>
      <UserEmploymentText {...props} />
    </Label>
  );
};