import { useCallback, useMemo } from 'react';
import * as api from '$admin/api';
import { ShareLinkPopper } from './ShareLink';
import type { UserReferralLinkProps } from './interfaces';

export const UserReferralLink = ({
  placement,
  userId,
}: UserReferralLinkProps) => {
  const fetchToken = useCallback(() => {
    return api.getReferralToken({
      userId,
    });
  }, [userId]);

  const cacheKey = useMemo(() => ['user-referral', userId], [userId]);

  return (
    <ShareLinkPopper
      cacheKey={cacheKey}
      fetchToken={fetchToken}
      placement={placement} />
  );
};

export default UserReferralLink;