import { useCallback, useMemo } from 'react';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@/containers/SurveyResponses/hooks';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { ResponsesViewAll as ViewAll, CloseRow } from '@presentation/ProjectSurveyResponses';
import type { SurveyQuestionType } from '@/enums';
import { formatPlurality, roundToPlace } from '@/utils';
import styles from './style/MatrixRange.Data.css';

export const MatrixRangeRowData = () => {

  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MatrixRange>();

  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixRange>();

  const row = useMemo(() => {
    return question.matrixRows.find(f => f.base.id === query.rowId);
  }, [question.matrixRows, query.rowId]);

  const rowData = responses[query.rowId];

  const renderHeader = useCallback(() => {
    const roundedAvg = roundToPlace(rowData.avg, 1);
    return (
      <div className={styles.rowHeader}>
        <div className={styles.left}>
          <div className={styles.label}>{parseSurveyRichText(row.value)}</div>
          <div className={styles.subtitle}>(Avg. {roundedAvg})</div>
        </div>
        <CloseRow onClick={resetQueryState} />
      </div>
    );
  }, [rowData.avg, row.value, resetQueryState]);

  return (
    <>
      {renderHeader()}
      {question.options.map(option => {
        const optionData = rowData.options[option.base.id];
        const count = optionData.userIds.length;
        const onClick = () => setQueryState({ rowId: row.base.id, optionId: option.base.id });
        return (
          <div
            className={styles.option}
            key={option.base.id}
            onClick={onClick}>
            <div className={styles.left}>
              <div className={styles.label}>{parseSurveyRichText(option.value)}</div>
              <div className={styles.subtitle}>({count} {formatPlurality('Respondent', count)})</div>
            </div>
            <ViewAll
              className={styles.viewAll}
              title="View All Respondents"
              onClick={onClick} />
          </div>
        );
      })}
    </>
  );
};