import type { Ref } from 'react';
import { forwardRef, memo, useCallback, useMemo, Fragment } from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import styles from './style/Breadcrumbs.css';

type BreadcrumbProps = {
  className?: string;
  loading?: boolean;
} & ChildrenProps;

export const Breadcrumb = forwardRef(({ children, className, loading }: BreadcrumbProps, ref: Ref<HTMLDivElement>) => {

  const breadcrumb = (
    <div ref={ref} className={cx(styles.crumb, className)}>
      {children}
    </div>
  );

  if (!loading) return breadcrumb;

  return (
    <Skeleton
      variant="rounded">
      {breadcrumb}
    </Skeleton>
  );
});

type BreadcrumbsProps = {
  className?: string;
  children: React.ReactNode[] | React.ReactNode;
};

export const Breadcrumbs = ({ children, className }: BreadcrumbsProps) => {

  const filteredChildren = useMemo(() => {
    if (!Array.isArray(children)) {
      return [children];
    }

    return children.filter(Boolean);
  }, [children]);

  const renderChild = useCallback((child: React.ReactNode, i: number) => {
    return (
      <Fragment key={i}>
        {child}
        {i !== filteredChildren.length - 1 && <ChevronRight className={styles.separator} />}
      </Fragment>
    );
  }, [filteredChildren]);

  return (
    <div className={cx(styles.root, className)}>
      {filteredChildren.map(renderChild)}
    </div>
  );
};

type SkeletonProps = {
  className?: string;
};

export const SkeletonBreadcrumbs = memo((props: SkeletonProps) => {
  return (
    <div className={cx(styles.stretch, props.className)}>
      <Skeleton
        height={30}
        width={70}
        variant="rounded" />
    </div>
  );
});