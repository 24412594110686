import { useContext } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { TranscriptCommentActionsContext, TranscriptCommentsStateContext, TranscriptFocusedCommentContext } from '@containers/Transcript.Commenting/Context';

type Data = unknown;
type Variables = {
  commentId: number;
  highlightId: number;
  parentCommentId: number;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useRemoveComment(options?: Options) {

  const { removeComment } = useContext(TranscriptCommentActionsContext);
  const [_, dispatch] = useContext(TranscriptCommentsStateContext);
  const [, setFocusedComment] = useContext(TranscriptFocusedCommentContext);

  return useMutation({ mutationKey: ['remove-transcript-comment'], mutationFn: ({ commentId }) => {

    return removeComment({
      commentId,
    });

  }, ...options, onSuccess: (res, v, ctx) => {

    const isParent = v.parentCommentId === v.commentId;

    if (isParent) {
      dispatch({
        commentId: v.commentId,
        type: 'comment-removed',
      });
      setFocusedComment(null);
    } else {
      dispatch({
        commentId: v.commentId,
        parentCommentId: v.parentCommentId,
        type: 'reply-removed',
      });
    }

    options?.onSuccess?.(res, v, ctx);
  } });
}
