import { useContext } from 'react';
import type { ContextValue, ItemValue } from '../Carousel';
import { Context } from '../Carousel';

export const useCarouselContext = <T extends ItemValue>() => {
  const context = useContext(Context);

  return context as ContextValue<T>;
};

export default useCarouselContext;