import { getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import type { UserDetailsProps } from '../interfaces';
import styles from './style.css';

const UserDetails = (props: UserDetailsProps) => {
  const { slug } = props.user.profile;
  const to = slug && getLocationFor.user.profile({ slug });

  return (
    <div className={styles.user}>
      <MaybeLink to={to}>
        <UserAvatar
          className={styles.avatar}
          pictureUrl={props.user.profile.pictureUrl} />
      </MaybeLink>
      <MaybeLink
        className={styles.usernameLink}
        to={to}>
        <h1 className={styles.username}>{props.user.profile.fullname}</h1>
      </MaybeLink>
    </div>
  );
};

export default UserDetails;