import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ConferenceTagSummaryItem } from '@/types/conferences.tags';
import * as $api from '@api';
import { TagSummaryFeedbackModal } from '@/components/ConferenceTag.Summary/Modal.Feedback.Summary';
import { SummaryFeedbackClickedContext } from './context';

type Props = ChildrenProps & ITranscriptId;

export const TranscriptSummaryFeedbackSummarysContainer = ({ children, transcriptId }: Props) => {

  const [openItem, setOpenItem] = useState<ConferenceTagSummaryItem>();
  const tagSummaryId = openItem?.summary?.id;

  const {
    data,
    isInitialLoading: isLoading,
  } = useQuery({ queryKey: ['get:transcript:tag-summaries:feedback-summary', {
    transcriptId,
    tagSummaryId,
  }], queryFn: ({ queryKey }) => {
    return $api.transcripts.getTagSummaryFeedbackSummary({
      transcriptId,
      tagSummaryId,
    });
  }, enabled: !!tagSummaryId, placeholderData: {
    items: [],
  }, refetchOnWindowFocus: false });

  const handleOpen = useCallback((item: ConferenceTagSummaryItem) => {
    setOpenItem(item);
  }, []);

  const handleClose = useCallback(() => {
    setOpenItem(null);
  }, []);

  const open = !!tagSummaryId;

  return (
    <SummaryFeedbackClickedContext.Provider value={handleOpen}>
      {children}
      {open && (
        <TagSummaryFeedbackModal
          summaryItem={openItem}
          items={data.items}
          open={open}
          onClose={handleClose} />
      )}
    </SummaryFeedbackClickedContext.Provider>
  );
};