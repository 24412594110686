import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Cell as RTCell } from 'react-table';
import { FileIcon } from '@/components/icons';
import { getLocationFor, trunc } from '@utils';
import type { RecentFilesItem } from '@/types/workspace.table';
import styles from './style/Cells.css';

function NameCell({ row: { original: data } }: RTCell<RecentFilesItem>) {

  const to = getLocationFor.workspaces.file({
    fileId: data.object.entityId,
  });

  return (
    <div className={styles.name}>
      <FileIcon className={styles.fileIcon} extension={data.file.extension} />
      <Link className={styles.link} to={to}>
        {data.object.name}
      </Link>
    </div>
  );
}

function CreatedOnCell({ row: { original: data } }: RTCell<RecentFilesItem>) {
  return format(data.object.createdOn, 'MM/dd/yyyy');
}

function selectMyUserId(state: Store.State) {
  return state.user.id;
}

function OwnerCell({ row: { original: data } }: RTCell<RecentFilesItem>) {

  const myUserId = useSelector(selectMyUserId);
  const owner = data.object.owner;

  const display = owner.id === myUserId
    ? 'Me'
    : [
      owner.firstName,
      owner.lastName ? `${owner.lastName[0]}.` : null,
    ].filter(Boolean).join(' ');

  return display;
}

export const Cell = {
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
};