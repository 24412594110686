import { useCallback } from 'react';
import cuid from 'cuid';
import { SurveyActionType } from '@enums/Survey';
import type { SurveyTemplateQuestion } from '@enums/survey.template';
import {
  SurveyTemplateEntityType, CompetitiveIntelligenceQuestion,
  PersonaResearchQuestion,
  SurveyTemplateOptionKey,
  NetPromoterScoreQuestion,
  CompetitiveIntelligenceTemplateSection,
  NetPromoterScoreTemplateSection,
  SurveyTemplateType,
} from '@enums/survey.template';
import type {
  MultiselectQuestion,
  SurveySection,
  SurveyQuestion,
  SurveyTemplate } from '@/types/survey';
import {
  MatrixGridQuestion,
} from '@/types/survey';
import type { SurveyBuilder, SurveyOptionsBuilder, SurveyRowsBuilder } from '../interfaces';
import { SurveyQuestionsBuilder } from '../interfaces';
import * as $templateUtils from '../state/state.template.utils';
import * as $template from '../template/utils';
import * as $netPromoter from '../template/template.nps';
// import { generateSubjectAwarenessResponseLogic } from '../template/template.ci';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSubmitTemplateCompetitors = () => {
  const [state, dispatch] = useSurveyBuilderState();

  return useCallback((items: SurveyTemplate.Competitor[]) => {

    const existingCompetitors = (state.survey.template.data as { competitors: SurveyTemplate.Competitor[] }).competitors;

    const actions = computeActions({
      items,
      oldItems: existingCompetitors,
      sections: state.survey.sections,
      questions: state.survey.questions,
      template: state.survey.template.type,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type: 'batch-actions',
    });
  }, [
    dispatch,
    state.survey.questions,
    state.survey.sections,
    state.survey.template,
  ]);
};

type ComputeActions = {
  items: SurveyTemplate.Competitor[];
  oldItems: SurveyTemplate.Competitor[];
  questions: SurveyQuestion[];
  sections: SurveySection[];
  template: SurveyTemplateType;
};

function computeActions({
  items,
  oldItems,
  questions,
  sections,
  template,
}: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const {
    added: addedCompetitors,
    removed: removedCompetitors,
    updated: updatedCompetitors,
  } = $templateUtils.computeChangedValues({
    oldValues: oldItems,
    values: items,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    CompetitiveIntelligenceQuestion.SubjectProductValue,
    CompetitiveIntelligenceQuestion.CurrentCustomerReplacement,
    CompetitiveIntelligenceQuestion.FormerCustomerReplacement,
    PersonaResearchQuestion.OrganizationProducts,
    NetPromoterScoreQuestion.OrgCurrentCustomer,
  ];
  const rowQuestions: SurveyTemplateQuestion[] = [
    CompetitiveIntelligenceQuestion.SubjectAwareness,
    CompetitiveIntelligenceQuestion.SubjectBrandStrength,
    CompetitiveIntelligenceQuestion.SubjectPrice,
    CompetitiveIntelligenceQuestion.SubjectServiceQuality,
  ];

  const removedSections = $templateUtils.computeRemovedSections({
    key: SurveyTemplateEntityType.Competitor,
    removedItems: removedCompetitors,
    sections,
  });

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.Competitor,
    questions,
    removedItems: removedCompetitors,
  });

  const removedRows = $templateUtils.computeRemovedRows({
    key: SurveyTemplateEntityType.Competitor,
    questions,
    removedItems: removedCompetitors,
  });

  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateCompetitorOption,
    addedItems: addedCompetitors,
    questions,
    toCheck: optionQuestions,
  });

  const addedRows = $templateUtils.computeAddedRows({
    generateRow: $template.generateCompetitorRow,
    addedItems: addedCompetitors,
    questions,
    toCheck: rowQuestions,
  });

  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions,
    toCheck: optionQuestions,
    updatedItems: updatedCompetitors,
  });

  const updatedRows = $templateUtils.computeUpdatedRows({
    questions,
    toCheck: rowQuestions,
    updatedItems: updatedCompetitors,
  });

  // const subjectAwarenessActions = computeSubjectAwarenessActions({
  //   addedCompetitors,
  //   addedRows,
  //   questions,
  //   sections,
  //   template,
  // });

  const netPromoterScoreActions = computeNetScoreActions({
    addedCompetitors,
    addedOptions,
    competitors: items,
    questions,
    sections,
    template,
  });

  const actions: SurveyBuilder.NonBatchActions[] = [];

  actions.push({
    type: 'template-competitors-updated',
    value: items,
  });

  // subjectAwarenessActions.forEach(action => actions.push(action));

  removedSections.forEach(f => {
    actions.push({
      type: 'remove-section',
      ...f,
    });
  });

  netPromoterScoreActions.forEach(action => actions.push(action));

  removedOptions.forEach(f => {
    actions.push({
      type: 'remove-question-option',
      ...f,
    });
  });
  removedRows.forEach(f => {
    actions.push({
      type: 'remove-question-row',
      ...f,
    });
  });
  addedOptions.forEach(f => {
    actions.push({
      type: 'add-question-options',
      ...f,
    });
  });
  addedRows.forEach(f => {
    actions.push({
      type: 'add-question-rows',
      ...f,
    });
  });
  updatedOptions.forEach(f => {
    actions.push({
      type: 'update-question-option-value',
      ...f,
    });
  });
  updatedRows.forEach(f => {
    actions.push({
      type: 'update-question-row-value',
      ...f,
    });
  });

  return actions;
}

type ComputeNetScoreActions = {
  addedCompetitors: SurveyTemplate.Competitor[];
  addedOptions: SurveyOptionsBuilder.AddOptions.State[];
  competitors: SurveyTemplate.Competitor[];
  questions: SurveyQuestion[];
  sections: SurveySection[];
  template: SurveyTemplateType;
};

function computeNetScoreActions({
  addedCompetitors,
  addedOptions,
  questions,
  sections,
  template,
}: ComputeNetScoreActions): SurveyBuilder.NonBatchActions[] {

  if (template !== SurveyTemplateType.NetPromoterScore) return [];

  if (!addedCompetitors.length) return [];

  const lastSection = [...sections].reverse()
    .find(f => f.metadata.template?.key === NetPromoterScoreTemplateSection.NetPromoterCompetitor);
  // let startingQuestionOrdinal = [...questions].reverse()
  //   .find(f => f.section.identifier === lastSection.identifier).ordinal + 1;

  const customerQuestion = questions.find(f => f.metadata.template.key === NetPromoterScoreQuestion.OrgCurrentCustomer) as MultiselectQuestion.Question;
  const addedOptionsAction = addedOptions.find(f => f.questionIdentifier === customerQuestion.base.identifier);

  const {
    actions,
    // logicToAdd,
  } = addedCompetitors.reduce((acc, competitor, i) => {

    const section = $netPromoter.generateNetPromoterCompetitorSection(competitor, lastSection.ordinal + i + 1);

    // const newQuestions = $netPromoter.generateNetScoreCompetitorQuestions({
    //   competitor,
    //   section,
    //   startingOrdinal: startingQuestionOrdinal,
    // });

    // startingQuestionOrdinal = startingQuestionOrdinal + 3;

    acc.actions.push({
      type: 'section-added',
      value: section,
    });

    // newQuestions.forEach(q => {
    //   acc.actions.push({
    //     type: 'question-added',
    //     value: q,
    //   });
    // });

    // Add response logic for showing section
    const option = addedOptionsAction.options.find(f => f.metadata.template.linkedEntity.id === competitor.id);

    // acc.logicToAdd.push({
    //   id: cuid(),
    //   action: {
    //     identifier: section.identifier,
    //     type: SurveyActionType.IncludeSection,
    //   },
    //   condition: {
    //     type: SurveyQuestionConditionType.Chosen,
    //     value: {
    //       option: {
    //         identifier: option.base.identifier,
    //       },
    //     },
    //   },
    // });

    return acc;
  }, {
    actions: [] as SurveyBuilder.NonBatchActions[],
    // logicToAdd: [] as MultiselectQuestion.Logic.Response[],
  });

  // const updatedLogicAction: SurveyQuestionsBuilder.UpdateQuestionLogic.Action = {
  //   type: 'update-question-logic',
  //   identifier: customerQuestion.base.identifier,
  //   logic: {
  //     ...customerQuestion.logic,
  //     response: [
  //       ...customerQuestion.logic.response,
  //       ...logicToAdd,
  //     ],
  //   },
  // };

  // actions.push(updatedLogicAction);

  return actions;
}

type ComputeSubjectAwarenessActions = {
  addedCompetitors: SurveyTemplate.Competitor[];
  addedRows: SurveyRowsBuilder.AddRows.State[];
  questions: SurveyQuestion[];
  sections: SurveySection[];
  template: SurveyTemplateType;
};

// function computeSubjectAwarenessActions({
//   addedCompetitors,
//   addedRows,
//   questions,
//   sections,
//   template,
// }: ComputeSubjectAwarenessActions): SurveyQuestionsBuilder.UpdateQuestionLogic.Action[] {

//   if (template !== SurveyTemplateType.CompetitiveIntelligence) return [];

//   if (!addedCompetitors.length) return [];

//   const question = questions
//     .find(f => f.metadata.template.key === CompetitiveIntelligenceQuestion.SubjectAwareness) as MatrixGridQuestion.Question;

//   if (!question) return [];

//   const currentCustomerOption = question.options.find(f => f.metadata.template.key === SurveyTemplateOptionKey.CurrentCustomer);
//   const formerCustomerOption = question.options.find(f => f.metadata.template.key === SurveyTemplateOptionKey.FormerCustomer);

//   const currentCustomerSection = sections.find(f => f.metadata.template.key === CompetitiveIntelligenceTemplateSection.CurrentCustomer);
//   const formerCustomerSection = sections.find(f => f.metadata.template.key === CompetitiveIntelligenceTemplateSection.FormerCustomer);

//   const newRows = addedRows.find(f => f.questionIdentifier === question.base.identifier);

//   const logicToAdd = newRows.rows.reduce<MatrixGridQuestion.Logic.Response[]>((acc, r) => {
//     acc.push(generateSubjectAwarenessResponseLogic({
//       optionIdentifier: formerCustomerOption.base.identifier,
//       rowIdentifier: r.base.identifier,
//       sectionIdentifier: formerCustomerSection.identifier,
//     }));
//     acc.push(generateSubjectAwarenessResponseLogic({
//       optionIdentifier: currentCustomerOption.base.identifier,
//       rowIdentifier: r.base.identifier,
//       sectionIdentifier: currentCustomerSection.identifier,
//     }));

//     return acc;
//   }, []);

//   return [];

//   // return [{
//   //   type: 'update-question-logic',
//   //   identifier: question.base.identifier,
//   //   logic: {
//   //     ...question.logic,
//   //     response: question.logic.response.concat(logicToAdd),
//   //   },
//   // }];

// }