import { useCallback, useMemo } from 'react';
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { VisibilityLogicContainer } from '@containers/SurveyBuilder.Section';
import { useSurveyLogicBuilderContext } from '@/containers/SurveyBuilder.Logic';
import { Button } from '@/components/Button';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { SurveyLogicBuilder } from '@/components/SurveyBuilder/LogicBuilder';
import styles from './style/Modal.VisibilityLogic.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

const SectionVisibilityLogicModal = ({ open, onClose }: Props) => {

  const { editing, handleSubmit: submit } = useSurveyLogicBuilderContext();

  const handleSubmit = useCallback(() => {
    submit();
    onClose();
  }, [onClose, submit]);

  const isEditing = useMemo(() => {
    return !!editing.identifier;
  }, [editing]);

  return (
    <Modal
      disableOverlayClick={isEditing}
      hideCloseIcon={isEditing}
      classes={{
        root: styles.modal,
      }}
      onClose={onClose}
      open={open}>

      <ModalHeader className={styles.header}>
        Section Visibility Logic
      </ModalHeader>

      <div className={styles.body}>
        <SurveyLogicBuilder />
      </div>

      <div className={styles.footer}>
        <ButtonOutlined
          disabled={isEditing}
          borderWidth="medium"
          className={styles.cancel}
          color="silver"
          onClick={onClose}>
          Cancel
        </ButtonOutlined>
        <Button
          disabled={isEditing}
          color="primary"
          onClick={handleSubmit}
          variant="brick">
          Save
        </Button>
      </div>
    </Modal>
  );
};

const WithState = (props: Props) => {

  return (
    <VisibilityLogicContainer>
      <SectionVisibilityLogicModal {...props} />
    </VisibilityLogicContainer>
  );
};

export { WithState as SectionVisibilityLogicModal };
export const useVisibilityLogicModal = () => useModal(WithState);