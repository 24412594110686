import { useMemo } from 'react';
import { TranscriptType } from '@enums';
import type { IWorkspaceTranscriptItem } from '@/types/workspace.table';
import { getLocationFor } from '@utils';

export const useTranscriptLink = (item: IWorkspaceTranscriptItem) => {
  const to = useMemo(() => {
    if (item.transcript.context.type === TranscriptType.WorkspaceFile) {
      return getLocationFor.workspaces.transcript({
        transcriptId: item.transcript.id,
      });
    } else if (item.transcript.context.type === TranscriptType.VideoCall) {
      return getLocationFor.call.transcript({
        callId: item.transcript.context.callId,
        slug: item.transcript.context.projectId.toString(),
      });
    }
  }, [item.transcript]);

  return to;
};