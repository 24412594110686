import { cx, getLocationFor } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const to = !props.row.original.respondent.offPlatform
    ? getLocationFor.user.profile({ slug: String(props.row.original.respondent.id) })
    : undefined;

  return (
    <MaybeLink to={to}>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <UserAvatar
            pictureUrl={props.row.original.respondent.pictureUrl}
            size={25} />
        </div>
        <div className={cx({ [styles.link]: !props.row.original.respondent.offPlatform })}>
          {props.row.original.respondent.name}
        </div>
      </div>
    </MaybeLink>
  );
};

Cell.displayName = 'Column.Respondent.Cell';