import { cdn } from '@utils';
import type { UserAvatarProps } from './interfaces';

export function resolveImageSource({ pictureUrl, resize, size }: ResolveImageSource) {
  if (!size) return pictureUrl;

  const newResize = resize
    ? resize
    : resizeOptions.find(f => f > size * 2);

  return newResize
    ? cdn.buildAvatarUrl(pictureUrl, newResize)
    : pictureUrl;
}

const resizeOptions = [50, 100, 200];

type ResolveImageSource =
  Pick<UserAvatarProps,
    'pictureUrl' |
    'resize' |
    'size'>;