import { memo } from 'react';
import type { SurveyTagging } from '@/types';
import { SurveyTaggingResultType } from '@enums';
import { ClassificationTagAction } from './Preview.Action.Classification';

type Props = {
  action: SurveyTagging.Action;
};

export const TaggingAction = memo(({ action }: Props) => {

  if (action.type === SurveyTaggingResultType.Classification) {
    return <ClassificationTagAction action={action} />;
  }

  return null;
});