import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as QK from '@consts/querykey.admin';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import Toast from '@/components/Toast';
import type { SchedulerCalendar } from '$admin/interfaces/project.scheduling';
import * as api from '$admin/api';
import { CalendarsContext, FrameContext, ParamsContext } from './Context';
import { useSchedulerCallRoleSelector } from './hooks';
import { FrameKey } from './interfaces';
import styles from './style/RequestAvailability.css';

type Props = unknown;

export const RequestAvailability = (props: Props) => {
  const ctx = {
    calendars: useContext(CalendarsContext),
    frame: useContext(FrameContext),
    scheduling: useContext(ParamsContext),
  };
  const [schedulers, setSchedulers] = useState(new Set<number>());

  const handleChangeScheduler = useCallback((checked: boolean, item: SchedulerCalendar) => {
    checked
      ? schedulers.add(item.id)
      : schedulers.delete(item.id);

    setSchedulers(new Set([...schedulers]));
  }, [schedulers]);

  const userIds = useMemo(() => {

    return [...schedulers];

  }, [schedulers]);

  const mutation = useMutation({ mutationKey: QK.Projects.Scheduling.RequestAvailability.Post({
    projectId: ctx.scheduling.params.projectId,
  }), mutationFn: () => {
    return api.projects.scheduling.requestAvailability({
      projectId: ctx.scheduling.params.projectId,
      userIds,
    });
  }, onSuccess: () => {
    Toast.success({
      title: `Success!`,
    });
    ctx.scheduling.resetContext();
  } });

  const selectCallRole = useSchedulerCallRoleSelector();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.subtitle}>{copy.subtitle}</div>
          {ctx.calendars.query.data.items.map(item =>
            <Option
              checked={schedulers.has(item.id)}
              key={item.id}
              label={`${item.name} (${selectCallRole(item.email)?.name})`}
              onChange={checked => handleChangeScheduler(checked, item)} />)}
        </div>

        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            disabled={mutation.isLoading}
            onClick={() => ctx.frame.goToFrame({ frame: FrameKey.Booking })}>
            Back
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            color="affirmative"
            disabled={schedulers.size < 1}
            loading={mutation.isLoading}
            implicitDisable={false}
            onClick={() => mutation.mutate()}>
            Send Request
          </ButtonActivityIndicator>
        </ButtonSet>
      </div>
    </div>
  );
};

RequestAvailability.displayName = 'Frame.RequestAvailability';

const copy = {
  subtitle: `Send an email reminder to the selected respondents to update their calendar availabilities in Sentiment.`,
};

type OptionProps = {
  checked:  boolean;
  label:    string;
  onChange: (checked: boolean) => void;
};

const Option = (props: OptionProps) => {
  return (
    <div className={styles.option}>
      <Checkbox
        checked={props.checked}
        onChange={(e, checked) => props.onChange(checked)} />
      <div className={styles.name}>{props.label}</div>
    </div>
  );
};