import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Search } from '@api/interfaces';
import * as QK from '@consts/querykey';

export function useResearchParticipationOptions() {
  const participation = useQuery({ queryKey: [QK.Search.Medical.Options.ResearchParticipation.Get], queryFn: api.search.medical.fetchResearchParticipationOptions, refetchOnWindowFocus: false });

  const filterSortMap = useCallback((params: FilterSortMapParams) => {
    return params.options.filter(f => f.type === params.type)
                         .sort((a, b) => a.ordinal - b.ordinal)
                         .map(o => ({ id: o.id, name: o.value }));
  }, []);

  const options = useMemo(() => participation.data?.options || [], [participation.data?.options]);

  return useMemo(() => ({
    inPerson: filterSortMap({ type: 'in-person', options }),
    virtual: filterSortMap({ type: 'virtual', options }),
    survey: filterSortMap({ type: 'survey', options }),
    phone: filterSortMap({ type: 'phone', options }),
  }), [filterSortMap, options]);
}

type FilterSortMapParams = {
  type: Search.FetchResearchParticipationOptions.Option['type'];
  options: Search.FetchResearchParticipationOptions.Option[];
};