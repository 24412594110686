import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Calendar, ChevronLeft, XCircle } from 'react-feather';
import { selectClientStateConnected, selectMessageItems } from '@store/selectors';
import { cx, getLocationFor, hasClientRole } from '@utils';
import type { Contact } from '@/types';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style/ChannelHeader.css';

const mapState = (state: Store.State) => ({
  isConnected: selectClientStateConnected(state),
  me: state.user,
  messages: selectMessageItems(state),
});

type Props = {
  channelSid:     string;
  member:         Contact;
  onClose:        () => unknown;
  onDelete:       () => unknown;
  onScheduleCall: () => unknown;
};

const ChannelHeader = (props: Props) => {
  const state = useSelector(mapState);
  const items = state.messages[props.channelSid];

  const CalenderButton = useCallback(() => {

    if (!hasClientRole(state.me)) return null;

    return (
      <button
        className={styles.action}
        onClick={props.onScheduleCall}>
        <Calendar className={styles.calendar} />
      </button>
    );

  }, [
    props.onScheduleCall,
    state.me,
  ]);

  const DeleteButton = useCallback(() => {

    if (items && items.length) return null;

    return (
      <button
        className={styles.action}
        onClick={props.onDelete}>
        <XCircle className={styles.remove} />
      </button>
    );

  }, [
    items,
    props.onDelete,
  ]);

  const memberIsClient = hasClientRole(props.member.persona);

  const ProfileLink = useCallback((params: { children: React.ReactNode }) => {

    const location = getLocationFor.user.profile({
      slug: props.member.profile.slug,
    });

    const showLink = !memberIsClient && props.member.profile.slug;

    return (
      <MaybeLink to={showLink && location}>
        {params.children}
      </MaybeLink>
    );

  }, [
    memberIsClient,
    props.member.profile.slug,
  ]);

  const UserCareer = useCallback(() => {

    const employment = props.member.career?.current;

    if (!employment?.title || !employment?.company) return null;

    return (
      <div className={styles.career}>
        {`${employment.title}, ${employment.company}`}
      </div>
    );

  }, [props.member.career]);

  const bannerStyles = cx(styles.banner, {
    [styles.online]: state.isConnected,
    [styles.offline]: !state.isConnected,
  });

  const avatarStyles = cx(styles.avatar, {
    [styles.nolink]: memberIsClient,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <button
          className={styles.btn}
          onClick={props.onClose}>
          <ChevronLeft className={styles.back} />
        </button>

        <div className={styles.user}>
          <div className={avatarStyles}>
            <ProfileLink>
              <UserAvatar
                pictureUrl={props.member.profile.pictureUrl}
                size={47} />
            </ProfileLink>
          </div>

          <div className={styles.info}>
            <div className={styles.name}>
              <ProfileLink>
                {props.member.profile.fullname}
              </ProfileLink>
            </div>

            <UserCareer />
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <CalenderButton />
        <DeleteButton />
      </div>
      <div className={bannerStyles}>
        Offline
      </div>
    </div>
  );
};

export { ChannelHeader };
export default ChannelHeader;