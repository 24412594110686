import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { Selecting } from '@screens/Scheduling/Selecting';
import { useAdHocCallSchedulingNotifier } from '@containers/Scheduler/Notifier.AdHocCall';
import { qs } from '@utils';
import { useConfirmAdHocCall } from '@utils/api';
import type { CalendarConfirmTimeData } from './interfaces';

type Props = {
  scheduleeId: number;
  schedulerId: number;
} & ICallId;

const AdHocCallConfirmationCalendar = (props: Props) => {
  const history = useHistory();
  const notifier = useAdHocCallSchedulingNotifier();

  const { mutateAsync } = useConfirmAdHocCall({ callId: props.callId }, {
    onError: e => {
      notifier.confirmation.error();
    },
    onSuccess: res => {
      notifier.confirmation.success(res.call);

      const from = qs.getParam('from');

      history.replace(from || consts.pathname.HOME);
    },
  });

  const handleSubmit = useCallback((data: CalendarConfirmTimeData) => {
    return mutateAsync(data);
  }, [
    mutateAsync,
  ]);

  return (
    <Selecting
      callId={props.callId}
      onSubmit={handleSubmit}
      scheduleeId={props.scheduleeId}
      schedulerId={props.schedulerId} />
  );
};

export { AdHocCallConfirmationCalendar };
export default AdHocCallConfirmationCalendar;