import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ChipProps } from '@mui/material/Chip';
import Chip from '@mui/material/Chip';
import Phone from '@mui/icons-material/Phone';
import { cx, getLocationFor } from '@utils';
import styles from './style/Calls.css';

type Props = {
  className?: string;
  count:      number;
} & IProjectId
  & IUserId;

export const Calls = (props: Props) => {
  const to = getLocationFor.admin.call.aggregate({
    attendeeId: props.userId,
    projectId: props.projectId,
  });

  return (
    <Badge
      className={props.className}
      icon={<Phone />}
      label={props.count}
      to={to} />
  );
};

type BadgeProps =
  & Pick<LinkProps, 'to'>
  & Pick<ChipProps,
    | 'className'
    | 'icon'
    | 'label'>;

function Badge(props: BadgeProps) {
  const classes = {
    root: styles.badge,
    outlined: styles.badge,
    icon: styles.icon,
  };

  return (
    <div className={cx(styles.root, props.className)}>
      <Link to={props.to}>
        <Chip
          classes={classes}
          clickable
          icon={props.icon}
          label={props.label}
          size="small"
          variant="outlined" />
      </Link>
    </div>
  );
}

Calls.displayName = 'Badge.Calls';