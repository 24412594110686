import { useMemo } from 'react';
import type { Datum, Serie } from '@nivo/line';
import { useConjointAnalysisAttributeColors, useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks';
import type { SurveyQuestionType } from '@enums';
import { roundToPlace } from '@utils';

export const useRepeatConjointAnalysisChartData = () => {
  const qData = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();
  const colors = useConjointAnalysisAttributeColors();

  const chartData: Serie[] = useMemo(() => {
    return qData.question.attributes.reduce((acc, attribute) => {
      const data = qData.projectIds.reduce((acc2, projectId, i) => {
        const projectData = qData.historical[projectId].data;
        const attributeData = projectData.attributes[attribute.base.id];

        if (attributeData.responses.userIds.length) {

          const value: Datum = {
            x: i + 1,
            y: roundToPlace(attributeData.importance, 1),
          };

          return [
            ...acc2,
            value,
          ];
        } else {
          return acc2;
        }
      }, [] as Datum[]);

      return [
        ...acc,
        {
          color: colors[attribute.base.id],
          data,
          id: attribute.base.id,
        },
      ];
    }, [] as Serie[]);
  }, [
    colors,
    qData.historical,
    qData.projectIds,
    qData.question.attributes,
  ]);

  return chartData;
};

export default useRepeatConjointAnalysisChartData;