import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import type * as API from '@api/interfaces';
import type { Settings } from '@/types';

type Data = API.Settings.UpdateSMSSettings.Response;
type Variables = Partial<Settings.SMS>;
type Context = Settings.SMS;

type Options = UseMutationOptions<Data, AxiosResponse, Variables, Context>;

const selectSMSSettings = (state: Store.State) => state.settings.sms;

export const useUpdateUserSMSSettings = (options?: Options) => {
  const settings = useSelector(selectSMSSettings);
  const dispatch = useDispatch();

  return useMutation({ mutationKey: ['update-user-sms-settings'], mutationFn: (data: Variables) => {

    dispatch(actions.preferencesSettingChanged({
      sms: {
        ...settings,
        ...data,
      },
    }));

    return api.settings.updateSMSSettings(data)
      .catch(e => {
        dispatch(actions.preferencesSettingChanged({ sms: settings }));
        throw e;
      });
  }, ...options });
};