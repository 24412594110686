import type {
  ActionWithQuestion } from '@containers/SurveyBuilder.Logic/utils';
import {
  QuestionActions,
} from '@containers/SurveyBuilder.Logic/utils';
import type { SurveyLogic } from '@/types';
import { SurveyConditionType, SurveyLogicRule } from '@/enums';
import { isOptionInAction, isOptionInCondition, isQuestionInAction, isQuestionInCondition, isRowInAction, isRowInCondition, isSectionInAction } from '@/containers/SurveyBuilder.Logic/utils.lookup';
import type {
  SurveyBuilder,
  SurveyLogicBuilder,
  SurveyQuestionsBuilder,
  SurveySectionsBuilder,
  SurveyOptionsBuilder,
  SurveyRowsBuilder,
} from '../interfaces';
import { updateQuestionInCondition } from './state.conditions';

type State = SurveyLogicBuilder.State;

export function logicUnsavedItemAdded(state: State, { payload }: SurveyLogicBuilder.UnsavedItemAdded.Action): State {
  return state.concat({
    id: null,
    identifier: payload.identifier,
    conditions: [],
    action: {
      type: null,
    },
    metadata: {
      canDelete: true,
      canEdit: true,
    },
    rule: {
      expression: null,
      type: SurveyLogicRule.AllOfTheAbove,
    },
  });
}

export function logicItemsAdded(state: State, { payload }: SurveyLogicBuilder.ItemsAdded.Action): State {
  return state.concat(payload.items);
}

export function logicItemAdded(state: State, { payload }: SurveyLogicBuilder.ItemAdded.Action): State {
  return state.concat(payload.item);
}

export function logicItemRemoved(state: State, { payload }: SurveyLogicBuilder.ItemRemoved.Action): State {
  return state.filter(f => f.identifier !== payload.identifier);
}

export function logicItemSaved(state: State, { payload }: SurveyLogicBuilder.ItemSaved.Action): State {
  return state.reduce((acc, x) => {
    if (x.identifier === payload.value.identifier) {
      acc.push(payload.value);
    } else {
      acc.push(x);
    }

    return acc;
  }, []);
}

export function logicEditingToggledOff(state: SurveyBuilder.State, { payload }: SurveyLogicBuilder.EditingToggledOff.Action): State {
  return state.editing.logic.isNew
    ? state.survey.logic.filter(f => f.identifier !== payload.identifier)
    : state.survey.logic;
}

export function logicEditingToggledOn(state: SurveyBuilder.State, { payload }: SurveyLogicBuilder.EditingToggledOn.Action): State {
  return state.survey.logic;
}

export function rowRemoved(state: State, action: SurveyRowsBuilder.RemoveRow.Action): State {
  return state.filter(item => {
    const inAction = isRowInAction({ action: item.action, row: action.row });
    const inConditions = item.conditions.some(condition => isRowInCondition({
      condition,
      row: action.row,
    }));

    return !(inAction || inConditions);
  });
}

export function optionRemoved(state: State, action: SurveyOptionsBuilder.RemoveOption.Action): State {
  return state.filter(item => {
    const inAction = isOptionInAction({ action: item.action, option: action.option });
    const inConditions = item.conditions.some(condition => isOptionInCondition({
      condition,
      option: action.option,
    }));
    return !(inAction || inConditions);
  });
}

export function sectionRemoved(state: SurveyBuilder.State, action: SurveySectionsBuilder.RemoveSection.Action): State {
  const removedQuestionIdentifiers = state.survey.questions
    .filter(f => f.section.identifier === action.identifier)
    .map(m => m.base.identifier);

  return state.survey.logic.filter(item => {

    if (isSectionInAction({
      action: item.action,
      section: { identifier: action.identifier },
    })) return false;

    const questionsInAction = removedQuestionIdentifiers.some(questionIdentifier => isQuestionInAction({
      action: item.action,
      question: { identifier: questionIdentifier },
    }));

    if (questionsInAction) return false;

    const questionInConditions = item.conditions.some(condition => {
      return removedQuestionIdentifiers.some(questionIdentifier => isQuestionInCondition({
        condition,
        question: { identifier: questionIdentifier },
      }));
    });

    if (questionInConditions) return false;

    return true;
  });
}

export function questionTypeUpdated(state: State, action: SurveyQuestionsBuilder.UpdateQuestionType.Action): State {
  return questionRemoved(state, { questionIdentifier: action.questionIdentifier });
}

export function questionRemoved(state: State, action: Omit<SurveyQuestionsBuilder.QuestionRemoved.State, 'itemIdentifier'>): State {
  return state.filter(item => {

    const questionInAction = isQuestionInAction({
      action: item.action,
      question: { identifier: action.questionIdentifier },
    });

    if (questionInAction) return false;

    const questionInConditions = item.conditions.some(condition => isQuestionInCondition({
      condition,
      question: { identifier: action.questionIdentifier },
    }));

    if (questionInConditions) return false;

    return true;
  });
}

export function questionIdentifierUpdated(state: State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): State {

  return state.reduce((acc, x) => {
    const conditions = x.conditions.map(m => updateQuestionInCondition(m, action.oldIdentifier, action.newIdentifier));

    const newAction = QuestionActions.includes(x.action.type) &&
      (x.action as SurveyLogic.Action<ActionWithQuestion>).question.identifier === action.oldIdentifier
      ? {
        ...x,
        question: { identifier: action.newIdentifier },
      } : x.action;

    return acc.concat({
      ...x,
      action: newAction,
      conditions,
    });
  }, []);
}