import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import { utils } from '@enums';
import { useToggle } from '@utils';
import type { CoveredLivesItem } from '@/types/medical.covered';
import * as Table from '@/components/MedicalProfile/Table';
import * as api from '$admin/api';
import { SectionFormHeader } from '$admin/shared';
import { ProfileMedicalQueryContext, ProfileOverviewQueryContext } from './Context';
import { Tile } from './Layout';
import styles from './style/Tab.Medical.css';

type Props = unknown;

export const Medical = (props: Props) => {
  const overview = useContext(ProfileOverviewQueryContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.col}>
            <Specialty />
            <Identification />
          </div>
          <div className={styles.col}>
            {overview.data?.classification?.payer &&
              <CoveredLives />}
            <AdditionalExpertise />
            <Practice />
            <Leadership />
          </div>
        </div>
      </div>
    </div>
  );
};

Medical.displayName = 'Tab.Medical';

const copy = {
  label: {
    additionalPracticeInfo: `Is there any additional information about your practice, area of expertise, or patient focus that you would like to share?`,
    clinicalPracticeStartedOn: `Year of starting clinical practice`,
    country: `Country`,
    deaNumber: `DEA Number`,
    employer: `Current hospital or practice employer`,
    meNumber: `ME Number`,
    medicalFacilityType: `Primary Patient Facility`,
    npiNumber: `NPI Number`,
    payerAdminType: `USA Payer / Admin Type`,
    role: `Role`,
    stateLicenseNumber: `State License Number`,
    therapeuticAreas: `What are the top 3 therapeutic areas you treat?`,
  },
};

type LabelProps = {
  children?: React.ReactNode;
};

const Label = (props: LabelProps) => {
  return (
    <div className={styles.label}>
      {props.children}
    </div>
  );
};

Label.displayName = 'Tab.Medical.Label';

const Field = (props: LabelProps) => {
  return (
    <div className={styles.field}>
      {props.children}
    </div>
  );
};

const Value = (props: LabelProps) => {
  if (!props.children) {
    return (
      <div className={styles.na}>
        N/A
      </div>
    );
  }

  return (
    <div className={styles.value}>
      {props.children}
    </div>
  );
};

const Specialty = (props: unknown) => {
  const query = useContext(ProfileMedicalQueryContext);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Specialty" />
    );
  }

  return (
    <Tile.Root>
      <Tile.Header>Specialty</Tile.Header>
      <Tile.Body>
        <Field>
          <Label>Role</Label>
          <Value>{utils.ProfessionalRole.getName(query.data.professionalRole)}</Value>
        </Field>
        <Field>
          <Label>Primary Specialty Group</Label>
          <Value>{query.data.specialty.primary.group?.name}</Value>
        </Field>
        <Field>
          <Label>Primary Subspecialty</Label>
          <Value>{query.data.specialty.primary.subspecialty?.name}</Value>
        </Field>
        <Field>
          <Label>Secondary Specialty Group</Label>
          <Value>{query.data.specialty.secondary.group?.name}</Value>
        </Field>
        <Field>
          <Label>Secondary Subspecialty</Label>
          <Value>{query.data.specialty.secondary.subspecialty?.name}</Value>
        </Field>
      </Tile.Body>
    </Tile.Root>
  );
};

const Identification = (props: unknown) => {
  const query = useContext(ProfileMedicalQueryContext);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Medical Background" />
    );
  }

  const clinicalPracticeStartedOn = query.data.identification.clinicalPracticeStartedOn
      ? format(new Date(query.data.identification.clinicalPracticeStartedOn), 'yyyy-MM')
      : null;

  return (
    <Tile.Root>
      <Tile.Header>Medical Background</Tile.Header>
      <Tile.Body>
        <Field>
          <Label>{copy.label.meNumber}</Label>
          <Value>{query.data.identification.meNumber}</Value>
        </Field>
        <Field>
          <Label>{copy.label.deaNumber}</Label>
          <Value>{query.data.identification.deaNumber}</Value>
        </Field>
        <Field>
          <Label>{copy.label.stateLicenseNumber}</Label>
          <Value>{query.data.identification.stateLicenseNumber}</Value>
        </Field>
        <Field>
          <Label>{copy.label.country}</Label>
          <Value>{query.data.identification.country}</Value>
        </Field>
        <Field>
          <Label>{copy.label.clinicalPracticeStartedOn}</Label>
          <Value>{clinicalPracticeStartedOn}</Value>
        </Field>
        <Field>
          <Label>{copy.label.payerAdminType}</Label>
          <Value>{utils.PayerAdminType.getName(query.data.identification.payerAdminType)}</Value>
        </Field>
      </Tile.Body>
    </Tile.Root>
  );
};

const CoveredLives = (props: unknown) => {
  const [map, setMap] = useState<Map<string, CoveredLivesItem>>(new Map());
  const [editing, toggle] = useToggle(false);
  const overview = useContext(ProfileOverviewQueryContext);
  const query = useContext(ProfileMedicalQueryContext);

  const userId = overview.data?.id;

  const mutation = useMutation({ mutationKey: [
    `patch:admin/members/medical/payer`,
    userId,
  ], mutationFn: (data: CoveredLivesItem[]) => {
    return api.members.medical.updatePayerFields({
      userId,
      covered: {
        items: data,
      },
    })
    .then(() => query.refetch())
    .then(() => toggle());
  } });

  const items = useMemo(() => {
    if (!query.data?.covered?.items) return [];

    return !editing
      ? query.data?.covered?.items
      : Array.from(map.values());
  }, [
    editing,
    query.data?.covered,
    map,
  ]);

  const handleChange = useCallback((data: CoveredLivesItem) => {
    setMap(map => {
      return new Map(map).set(`${data.planId}_${data.typeId}`, data);
    });
  }, []);

  const handleReset = useCallback(() => {
    toggle();
    setMap(buildMap(query.data?.covered?.items || []));
  }, [
    query.data?.covered,
    toggle,
  ]);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Covered Lives" />
    );
  }

  return (
    <Tile.Root>
      <SectionFormHeader
        disabled={mutation.isLoading}
        editing={editing}
        onReset={handleReset}
        onSave={() => mutation.mutate(items)}
        onEdit={toggle}
        submitting={mutation.isLoading}
        title="Covered Lives" />
      <Tile.Body>
        <Table.CoveredLives
          editing={editing}
          items={items}
          onChange={handleChange} />
      </Tile.Body>
    </Tile.Root>
  );
};

type CoveredLivesItemMap = Map<string, CoveredLivesItem>;

function buildMap(items: CoveredLivesItem[]) {
  return items.reduce((acc: CoveredLivesItemMap, item) => {
    return acc.set(`${item.planId}_${item.typeId}`, item);
  }, new Map<string, CoveredLivesItem>());
}

const AdditionalExpertise = (props: unknown) => {
  const query = useContext(ProfileMedicalQueryContext);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Areas of Expertise" />
    );
  }

  const boardEligibility = query.data.additionalExpertise.boardEligibility.question.options.find(option =>
    option.id === query.data.additionalExpertise.boardEligibility.answer?.value) ?? null;

  const conditions = query.data.additionalExpertise.therapeuticAreas?.length
      ? query.data.additionalExpertise.therapeuticAreas.map(x => x.name).join(', ')
      : null;

  return (
    <Tile.Root>
      <Tile.Header>Areas of Expertise</Tile.Header>
      <Tile.Body>
        <Field>
          <Label>{copy.label.therapeuticAreas}</Label>
          <Value>{conditions}</Value>
        </Field>
        <Field>
          <Label>{copy.label.additionalPracticeInfo}</Label>
          <Value>{query.data.additionalExpertise.description}</Value>
        </Field>
        <Field>
          <Label>{query.data.additionalExpertise.boardEligibility.question.value}</Label>
          <Value>{boardEligibility?.value}</Value>
        </Field>
      </Tile.Body>
    </Tile.Root>
  );
};

const Practice = (props: unknown) => {
  const query = useContext(ProfileMedicalQueryContext);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Practice And Medical Activities" />
    );
  }

  const medicalFacilityType = query.data.practice.medicalFacilityType.question.options.find(option =>
    option.id === query.data.practice.medicalFacilityType.answer?.value) ?? null;

  return (
    <Tile.Root>
      <Tile.Header>Practice And Medical Activities</Tile.Header>
      <Tile.Body>
        {query.data.practice.practiceTime.question.rows.map(x =>
          <Field key={x.id}>
            <Label>{`% of time spent on ${x.value}`}</Label>
            <Value>{query.data.practice.practiceTime.answer.items.find(answer => answer.matrixRowId === x.id)?.value ?? null}</Value>
          </Field>)}
        <Field>
          <Label>{copy.label.medicalFacilityType}</Label>
          <Value>{medicalFacilityType?.value}</Value>
        </Field>
        <Field>
          <Label>{copy.label.employer}</Label>
          <Value>{query.data.practice.employer?.name}</Value>
        </Field>
      </Tile.Body>
    </Tile.Root>
  );
};

const Leadership = (props: unknown) => {
  const query = useContext(ProfileMedicalQueryContext);

  if (query.isInitialLoading) {
    return (
      <EntrySkeleton title="Thought Leadership Participation" />
    );
  }

  return (
    <Tile.Root>
      <Tile.Header>Thought Leadership Participation</Tile.Header>
      <Tile.Body>
        {query.data.leadership.map(x =>
          <Field key={x.question.id}>
            <Label>{x.question.value}</Label>
            <Value>{x.question.options.find(option => option.id === x.answer?.value)?.value ?? null}</Value>
          </Field>)}
      </Tile.Body>
    </Tile.Root>
  );
};

type EntrySkeletonProps = {
  title: string;
};

const EntrySkeleton = (props: EntrySkeletonProps) => {
  return (
    <Tile.Root>
      <Tile.Header>{props.title}</Tile.Header>
      <Tile.Body>
        <Field>
          <Skeleton
            width={80}
            height={18}
            variant="rounded" />
          <Skeleton
            width={100}
            height={18}
            style={{ marginTop: 10 }}
            variant="rounded" />
        </Field>
        <Field>
          <Skeleton
            width={80}
            height={18}
            variant="rounded" />
          <Skeleton
            width={100}
            height={18}
            style={{ marginTop: 10 }}
            variant="rounded" />
        </Field>
      </Tile.Body>
    </Tile.Root>
  );
};