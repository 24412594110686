import { memo } from 'react';
import { format } from 'date-fns';
import { cx } from '@utils';
import type { DateRange } from './interfaces';
import styles from './style/Profile.DateRange.css';

type Props =
  DateRange.Props;

export const ProfileDateRange = memo(({
  className,
  endMonth,
  endYear,
  startMonth,
  startYear,
}: Props) => {

  const startDate = `${parseReadableMonth(startMonth)} ${startYear}`;
  const endDate = endYear ? `${parseReadableMonth(endMonth)} ${endYear}` : 'Present';

  return (
    <div className={cx(styles.root, className)}>
      {`${startDate} - ${endDate}`}
    </div>
  );
});

function parseReadableMonth(month: number) {
  if (month === 0) {
    return '';
  }
  const num = month % 100;
  return format(new Date(null, num - 1), 'MMM');
}

export default ProfileDateRange;