import { useCallback, useEffect, useRef } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';

type Params = {
  ref: React.RefObject<HTMLVideoElement>;
} & API.Discovery.UpdateVideoViewCount.Request;

const useVideoAnalytics = ({ ref, ...params }: Params) => {
  const counted = useRef(false);

  const handleLoadedData = useCallback(() => counted.current = false, []);

  const handlePlay = useCallback(() => {
    if (counted.current) return;

    counted.current = true;

    api.discovery.updateVideoViewCount(params);

  }, [params]);

  useEffect(() => {

    const player = ref.current;

    if (!player) return;

    player.addEventListener('loadeddata', handleLoadedData);
    player.addEventListener('play', handlePlay);

    return () => {
      player.removeEventListener('loadeddata', handleLoadedData);
      player.removeEventListener('play', handlePlay);
    };

  }, [
    handleLoadedData,
    handlePlay,
    ref,
  ]);

  return ref;
};

export { useVideoAnalytics };
export default useVideoAnalytics;