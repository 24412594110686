import { Fragment, useCallback, useContext, useMemo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { TranscriptEnhancementStateContext, TranscriptIdContext } from '@containers/Transcript/context';
import { useTranscriptEnhancementState, useTranscriptEnhancementStateMutation } from '@containers/Transcript/hooks';
import { TranscriptionRevisionStatus, utils } from '@enums';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx } from '@utils';
import { useModal } from '@/components/Modal';
import { PublishingTranscriptModal } from '@/components/Transcript/Publishing.Transcript.Modal';
import styles from './style/Tool.css';

type Props = unknown;

export const EnhancementActions = (props: Props) => {
  const enhancementState = useContext(TranscriptEnhancementStateContext);

  const state = useTranscriptEnhancementState();
  const transcriptId = useContext(TranscriptIdContext);

  const mutation = useTranscriptEnhancementStateMutation({ transcriptId });

  const handleClickSelfAssign = useCallback(() => {
    mutation.mutate({
      statusId: TranscriptionRevisionStatus.InProgress,
    });
  }, [mutation]);

  const internalUserCanPublish = useMemo(() => {
    const inPublishableState = !state.status.isCompleted && !state.status.isInProgress;
    const userCanSetCompleted = state.user.isInternalUser && state.user.hasTranscriptWritePermission;

    return inPublishableState && userCanSetCompleted;
  }, [
    state.status.isCompleted,
    state.status.isInProgress,
    state.user.isInternalUser,
    state.user.hasTranscriptWritePermission,
  ]);

  const [toggle, PublishModal] = useModal(PublishingTranscriptModal);

  if (!state.enabled && state.status.isCompleted) {
    return (
      <EnhancementStatus statusId={enhancementState?.statusId} />
    );
  }

  if (!state.enabled && !internalUserCanPublish) return null;

  if (!state.enabled && internalUserCanPublish) {
    return (
      <Fragment>
        <ButtonActivityIndicator
          className={styles.internal}
          color="affirmative"
          indicatorSize={13}
          implicitDisable
          onClick={toggle}>
          Publish
        </ButtonActivityIndicator>
        <PublishModal
          transcriptId={transcriptId}
          onSuccess={toggle} />
      </Fragment>
    );
  }

  if (state.user.isViewer && !!enhancementState?.statusId) {
    return (
      <EnhancementStatus statusId={enhancementState?.statusId}>
        {internalUserCanPublish &&
          <ButtonActivityIndicator
            className={styles.internal}
            color="affirmative"
            indicatorSize={13}
            implicitDisable
            onClick={toggle}>
            Publish
          </ButtonActivityIndicator>}
        <PublishModal
            transcriptId={transcriptId}
            onSuccess={toggle} />
      </EnhancementStatus>
    );
  }

  return (
    <div className={styles.cleanup}>
      {state.user.canSelfAssign &&
        <ButtonActivityIndicator
          className={styles.submit}
          indicatorSize={13}
          implicitDisable
          loading={mutation.isLoading}
          onClick={handleClickSelfAssign}>
          Assign To Me
        </ButtonActivityIndicator>}
      {state.user.canPublish &&
        <Fragment>
          <ButtonActivityIndicator
            className={styles.submit}
            indicatorSize={13}
            implicitDisable
            onClick={toggle}>
            Publish
          </ButtonActivityIndicator>
          <PublishModal
            transcriptId={transcriptId}
            onSuccess={toggle} />
        </Fragment>}
    </div>
  );
};

EnhancementActions.displayName = 'Transcript.Toolbar.EnhancementActions';

type EnhancementStatusProps = {
  children?: React.ReactNode;
  statusId: TranscriptionRevisionStatus;
};

const EnhancementStatus = (props: EnhancementStatusProps) => {
  const Icon = iconmap[props.statusId];

  const classnames = cx(styles.status, {
    [styles.completed]: props.statusId === TranscriptionRevisionStatus.Completed,
  });

  return (
    <div className={styles.cleanup}>
      <div className={classnames}>
        <Icon classes={{
          root: styles.icon,
        }} />
        <span>{utils.TranscriptionRevisionStatus.getName(props.statusId)}</span>
      </div>
      {props.children}
    </div>
  );
};

EnhancementStatus.displayName = 'Transcript.Toolbar.EnhancementStatus';

const iconmap = {
  [TranscriptionRevisionStatus.Completed]: CheckCircleIcon,
  [TranscriptionRevisionStatus.InProgress]: LockIcon,
  [TranscriptionRevisionStatus.InQueue]: HourglassTopOutlinedIcon,
};