import { useState } from 'react';
import Button from '@/components/Button';
import Header from '@/components/Modal/Header';
import Modal from '@/components/Modal/Modal.Legacy';
import { TextareaCharacterLimit } from '@/components/Textarea';
import type { ApprovalNotesProps } from './interfaces';
import styles from './style.css';

export const ApprovalNotesModal = (props: ApprovalNotesProps) => {
  const [notes, setNotes] = useState<string>('');
  const action = props.approve ? 'Approve' : 'Reject';
  const title = `${action} ${props.user} for the ${props.project} Project?`;
  const copy = `Please provide notes on your approval decision for our records.`;

  return (
    <Modal
      onClose={props.onClose}
      visible={true}>
      <div className={styles.root}>
        <Header
          className={styles.header}
          text={title} />
        <div className={styles.copy}>{copy}</div>
        <div className={styles.textarea}>
          <TextareaCharacterLimit
            maxLength={props.maxLength}
            onChange={e => setNotes(e.target.value)}
            placeholder='Compliance review notes (optional).'
            value={notes} />
        </div>
        <div className={styles.footer}>
          <Button.Destructive
            className={styles.cancel}
            onClick={props.onClose}
            variant='brick'>
            Cancel
          </Button.Destructive>
          <Button.Primary
            className={styles.submit}
            disabled={notes.length > props.maxLength}
            onClick={() => props.onSubmit(notes)}
            variant='brick'>
            Submit
          </Button.Primary>
        </div>
      </div>
    </Modal>
  );
};

export default ApprovalNotesModal;