import { memo, Fragment } from 'react';
import type { FieldProps } from 'formik';
import { Field } from 'formik';
import { Textarea } from '@/components/Textarea';
import styles from './style.css';

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: React.ReactChild;
};

export function FieldTextArea({ editing, label, name, displayValue }: Props) {
  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div>
          <div className={styles.label}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <Textarea
              className={styles.textarea}
              invalid={meta.touched && !!meta.error}
              invalidClassName={styles.textareaError}
              {...field} />
          </div>
        </div>
      )}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.textareaValue}>{displayValue ?? '-'}</span>
    </Fragment>
  );
});