import { useCallback, useRef, useState } from 'react';
import type * as API from '@api/interfaces';
import * as Search from '@containers/PostCreation/Search.Topics.Container';
import { TopicTagsContainer } from '@containers/PostCreation/Topic.Tags.Container';
import { ArticleCoverImageContext, ArticleFormContext, ArticleIdentifierContext } from './Context';
import { useDeleteCoverImageMutation } from './hooks/useDeleteCoverImageMutation';
import type { CoverImageObject, OnSubmitImageContent } from './interfaces';

type Props = {
  children: React.ReactNode;
  postId:   number;
} & Pick<API.Posts.Articles.FetchPostEditContext.Response, 'attachments'>
  & Pick<API.Posts.Articles.FetchPostEditContext.Response['post'],
    | 'identifier'
    | 'cover'
    | 'title'>;

export const PostArticleEditContainer = (props: Props) => {
  const [coverimage, setCoverImage] = useState<CoverImageObject>(props.cover);
  const [title, setTitle] = useState(props.title);
  const identifier = useRef(props.identifier).current;

  const deleteCoverImageMutation = useDeleteCoverImageMutation();

  const maybeDeleteCoverImage = useCallback(async () => {
    if (props.cover?.url && !coverimage?.url) {
      await deleteCoverImageMutation.mutateAsync({
        postId: props.postId,
      });
    }
  }, [
    coverimage,
    deleteCoverImageMutation,
    props.cover,
    props.postId,
  ]);

  const handleImageObjects: OnSubmitImageContent = useCallback(async ({ helpers }, handler) => {
    await maybeDeleteCoverImage();

    const images = helpers.findImageObjects();

    const added = images.filter(x => !props.attachments.some(attachment => attachment.url === x.src));

    const sources = await handler(added);

    const lookup = helpers.createImageURLLookup(added, sources);

    return helpers.replaceImageSrc(lookup);
  }, [
    maybeDeleteCoverImage,
    props.attachments,
  ]);

  const value = {
    onSubmitImageContent: handleImageObjects,
    setTitle,
    title,
  };

  return (
    <ArticleIdentifierContext.Provider value={identifier}>
      <ArticleCoverImageContext.Provider value={[coverimage, setCoverImage]}>
        <ArticleFormContext.Provider value={value}>
          <Search.TopicsContainer>
            <TopicTagsContainer>
              {props.children}
            </TopicTagsContainer>
          </Search.TopicsContainer>
        </ArticleFormContext.Provider>
      </ArticleCoverImageContext.Provider>
    </ArticleIdentifierContext.Provider>
  );
};

PostArticleEditContainer.displayName = 'Post.Article.EditContainer';