import { useCallback, useState } from 'react';
import { useSelectUser } from '@containers/Store';
import { FormButtons } from '@presentation/FormButtons';
import { phone, useToggle } from '@utils';
import { useUpdateUserAvatar, useUpdateUserContactInfo } from '@utils/api';
import { ButtonLinkVariant } from '@presentation/Buttons';
import { SettingsTitle } from '@/components/Settings';
import { useProfileAvatarModal } from '@/components/Modal/ProfileAvatar';
import { EditableUserAvatar } from '@/components/UserAvatar';
import type { TelInputState } from '@/components/TelInput';
import { PhoneInput } from '@/components/TelInput/PhoneInput';
import styles from './style/Settings.Account.css';

export const ContactInformation = () => {

  const [editing, toggleEditing] = useToggle();
  const me = useSelectUser();

  const [toggleModal, ProfileAvatarModal] = useProfileAvatarModal();
  const { mutateAsync: mutateAvatar } = useUpdateUserAvatar(me.id);
  const { mutateAsync: mutateContactInfo } = useUpdateUserContactInfo();

  const updateImage = useCallback((blob: Blob) => {
    return mutateAvatar({ blob });

  }, [mutateAvatar]);

  const updatePhone = useCallback((value: string) => {
    return mutateContactInfo({
      phone: value,
    }, {
      onSuccess: res => {
        toggleEditing();
      },
    });
  }, [mutateContactInfo, toggleEditing]);

  return (
    <>
      <SettingsTitle title="Account Information" />

      <EditableUserAvatar
        className={styles.avatar}
        onClick={toggleModal}
        size={70}
        pictureUrl={me.profile.pictureUrl} />

      <div className={styles.details}>
        <div className={styles.item}>
          <div className={styles.label}>First Name</div>
          <div className={styles.value}>
            {me.profile.firstName}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Last Name</div>
          <div className={styles.value}>
            {me.profile.lastName}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Phone</div>
          {!editing &&
            <div className={styles.field}>
              <div className={styles.value}>
                {me.contact.phone
                      ? phone.format(me.contact.phone)
                      : 'Not Set'}
              </div>
              <ButtonLinkVariant
                className={styles.edit}
                color="secondary"
                onClick={toggleEditing}>
                Edit
              </ButtonLinkVariant>
            </div>
          }
          {editing &&
            <EditingPhone
                  onCancel={toggleEditing}
                  onSubmit={updatePhone}
                  value={me.contact.phone} />}

        </div>

        <div className={styles.item}>
          <div className={styles.label}>Email</div>
          <div className={styles.value}>
            {me.contact.email}
          </div>
        </div>
      </div>

      <ProfileAvatarModal
        onSave={updateImage}
        pictureUrl={me.profile.pictureUrl} />
    </>
  );
};

type PhoneProps = {
  onCancel: () => void;
  onSubmit: (value: string) => void;
  value: string;
};

const EditingPhone = ({ onCancel, onSubmit, value }: PhoneProps) => {
  const [state, setState] = useState<TelInputState>({
    empty: !!value,
    valid: true,
    value,
  });

  return (
    <div className={styles.field}>
      <div className={styles.phone}>
        <PhoneInput
          editing={true}
          defaultValue={value}
          invalidClassName={styles.invalid}
          onChange={(e, data) => setState(data)}
          onChangeCountryCode={data => setState(data)} />
      </div>
      <FormButtons
        className={styles.btns}
        size={16}
        disabled={!state.valid}
        onCancel={onCancel}
        onSubmit={() => onSubmit(state.value)} />
    </div>
  );
};

export default ContactInformation;