import { createNamedContext } from '@utils';
import type { Discovery } from '@/types';
import { useCarousel } from './hooks/useCarousel';

export type ItemValue =
  Discovery.VideoTopicPost;

export const Context = createNamedContext<ContextValue | undefined>(undefined, 'Carousel.Context');

type Props<T> = {
  children: React.ReactNode;
  items:    T[];
};

export const Container = <T extends ItemValue = ItemValue>(props: Props<T>) => {
  const carousel = useCarousel(props.items.length);

  const context = {
    ...carousel,
    items: props.items,
  };

  return (
    <Context.Provider value={context}>
      {props.children}
    </Context.Provider>
  );
};

Container.displayName = 'Carousel.Container';

export type ContextValue<T extends ItemValue = ItemValue> = {
  back:      () => void;
  canGoBack: boolean;
  canGoNext: boolean;
  items:     T[];
  itemSize:  number;
  next:      () => void;
  position:  number;
};