import { useContext, useEffect, useMemo } from 'react';
import { pathname } from '@consts';
import type { IUniversalSearch } from '@/types';
import { qs, hash64 } from '@utils';
import {
  KeyboardContext, QueryContext, ResetContext, ResultsContext,
} from './Context';
import EmptyQuery from './Results.EmptyQuery';
import * as Skeleton from './Skeleton';
import ResultsItems from './Results.Items';

export default function Results() {
  const { query } = useContext(QueryContext);

  return query.length
    ? <HasQuery />
    : <EmptyQuery />;
}

function findExpertsItem(query: string): IUniversalSearch.SearchBarResultFindExperts {
  const encoded = qs.encode({ keyword: [{ id: query }] });
  return {
    id: hash64(`find-experts:${query}`),
    name: query,
    path: `${pathname.SEARCH.substring(1)}?${qs.stringify({ q: encoded })}`,
    type: 'find-experts',
  };
}

function seeAllItem(query: string): IUniversalSearch.SearchBarResultSeeAll {
  return {
    id: hash64(`see-all:${query}`),
    name: query,
    path: `${pathname.UniversalSearch.substring(1)}?${qs.stringify({ q: query })}`,
    type: 'see-all',
  };
}

function HasQuery() {
  const { data, isLoading } = useContext(ResultsContext);
  const { index } = useContext(KeyboardContext);
  const { updatedResultsReset } = useContext(ResetContext);
  const { query } = useContext(QueryContext);

  const items = useMemo(() => data?.items ?? [], [data?.items]);

  const findExperts = useMemo(() => findExpertsItem(query), [query]);
  const seeAll = useMemo(() => seeAllItem(query), [query]);

  const results = useMemo(() => [
    !isLoading ? findExperts : null,
    ...items,
    !isLoading ? seeAll : null,
  ].filter(Boolean), [isLoading, findExperts, items, seeAll]);

  useEffect(() => {
    updatedResultsReset(results.length);
  }, [updatedResultsReset, results.length]);

  return (
    <>
      <ResultsItems
        activeIndex={index}
        items={results} />
      {isLoading && <Skeleton.Results count={12} />}
    </>
  );
}