import { Fragment, memo } from 'react';
import { utils as enumUtils } from '@enums';
import type { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {

  const value = row.original.professionalRole
    ? enumUtils.ProfessionalRole.getName(row.original.professionalRole)
    : '-'
  ;

  return (
    <Fragment>{value}</Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Role
    </Fragment>
  );
});

Cell.displayName = 'Column.Rate.Cell';
Filter.displayName = 'Column.Rate.Filter';
Header.displayName = 'Column.Rate.Header';