import { BasicRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { Settings } from '@screens/Settings';
import type * as Routing from '@routes/interfaces';

export const routes: Routing.Route[] = [
  {
    component: Settings,
    path: path.Settings.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Settings.Root,
});