import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getTranscriptEntities } from '@api/transcripts';

type Props = ITranscriptId;

export const useTranscriptEntities = (props: Props, options: Options = {}) => {
  return useQuery({
    queryKey: ['transcript-entities', props],
    queryFn: ({ queryKey }) => {
      return getTranscriptEntities(queryKey[1] as Props);
    },
    ...options,
  });
};

type Data = Awaited<ReturnType<typeof getTranscriptEntities>>;
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;