import { useCallback, useState } from 'react';
import type { CalendarSubmitCallbackParams } from '@containers/ProjectOnboarding/interfaces';
import { useProjectOnboardingContext, useSubmitOnboardingCalendar } from '@containers/ProjectOnboarding/hooks';
import { useSelectUser, useSelectProject } from '@containers/Store';
import SchedulingInvitation from '@screens/Scheduling/ProjectOnboarding';
import SchedulingTutorial from '@screens/SchedulingTutorial';
import type { RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { Alert } from '@/components/Modal/Alert';

type Props = unknown;

export const InvitationCalendar = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const calendarSubmit = useSubmitOnboardingCalendar(ctx.projectId);
  const [shouldBlock, setShouldBlock] = useState(true);

  const submit = useCallback((params: CalendarSubmitCallbackParams) => {
    setShouldBlock(false);
    return calendarSubmit(params);
  }, [setShouldBlock, calendarSubmit]);
  const { id: userId } = useSelectUser();
  const project = useSelectProject(ctx.projectId);

  return (
    <>
      <SchedulingInvitation
        creatorId={project.creatorId}
        scheduleeId={project.creatorId}
        schedulerId={userId}
        projectId={ctx.projectId}
        onSubmit={submit}
        userId={userId} />
      <RouteLeavingGuard block={shouldBlock}>
        {LeavePrompt}
      </RouteLeavingGuard>
    </>
  );
};

export const Tutorial = (props: Props) => {
  const ctx = useProjectOnboardingContext();

  return (
    <>
      <SchedulingTutorial
        nextScreen={ctx.next} />
      <RouteLeavingGuard block={true}>
        {LeavePrompt}
      </RouteLeavingGuard>
    </>
  );
};

const LeavePrompt = (data: RouteLeavingGuardState) => {
  return <Alert
    open={data.open}
    message={`You won't be compensated for the project unless you select call times. Are you sure you wish to leave?`}
    onClose={data.closePrompt}
    onConfirm={data.confirmNavigation} />;
};