import type { Reducer } from 'react';
import { useCallback, useReducer } from 'react';
import { useMounted } from '@utils';
import type { FormContextValue, FormErrors, FormMessage, FormState, FormValues } from '@/components/Form/interfaces';

const initialErrors: FormErrors<unknown> = {};

type Props<Values> = {
  initialValues: FormValues<Values>;
  onSubmit: (values: FormValues<Values>) => void | Promise<unknown>;
};

export function useForm<Values>({ onSubmit, ...props }: Props<Values>): FormContextValue<Values> {
  const isMounted = useMounted();

  const [state, dispatch] = useReducer<Reducer<FormState<Values>, FormMessage>>(formReducer, {
    errors: initialErrors,
    isSubmitting: false,
    values: props.initialValues,
  } as FormState<Values>);

  function setFieldError(field: string, message: string) {
    dispatch({
      field,
      type: 'SET_ERROR',
      value: message,
    });
  }

  function setFieldValue(field: string, value: unknown) {
    dispatch({
      field,
      type: 'SET_VALUE',
      value,
    });
  }

  const submitForm = useCallback(() => {
    dispatch({ type: 'SUBMIT_ATTEMPT' });

    return new Promise((resolve, reject) => {
      const returnVal = onSubmit(state.values);

      return resolve(returnVal);
    })
    .then(() => {
      if (isMounted()) {
        dispatch({ type: 'SUBMIT_SUCCESS' });
      }
    })
    .catch(e => {
      if (isMounted()) {
        dispatch({ type: 'SUBMIT_FAILURE' });

        throw e;
      }
    });
  }, [
    dispatch,
    isMounted,
    onSubmit,
    state.values,
  ]);

  const context: FormContextValue<Values> = {
    errors: state.errors,
    isSubmitting: state.isSubmitting,
    setFieldError,
    setFieldValue,
    values: state.values,
    submitForm,
  };

  return context;
}

function formReducer<Values>(acc: FormState<Values>, x: FormMessage) {
  switch (x.type) {
    case 'SET_ERROR':
      return {
        ...acc,
        errors: {
          ...acc.errors,
          [x.field]: x.value,
        },
      };

    case 'SET_VALUE':
      return {
        ...acc,
        values: {
          ...acc.values,
          [x.field]: x.value,
        },
      };

    case 'SUBMIT_ATTEMPT':
      return {
        ...acc,
        isSubmitting: true,
      };

    case 'SUBMIT_FAILURE':
    case 'SUBMIT_SUCCESS':
      return {
        ...acc,
        isSubmitting: false,
      };

    default:
      return acc;
  }
}