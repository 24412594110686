import { useState, useCallback, useEffect, useRef } from 'react';
import { useChainedCommands, useHelpers, useRemirrorContext } from '@remirror/react';
import type { TextColorExtension } from '../Extension.TextColor';

export const useTextColorCommands = () => {
  const isMountedRef = useRef(true);
  const chainedCommands = useChainedCommands<TextColorExtension | Remirror.Extensions>();
  const helpers = useHelpers<TextColorExtension>();

  const [activeColor, setActiveColor] = useState<string | undefined>(helpers.getActiveColor());

  const recalculateActiveColor = useCallback(() => {
    if (isMountedRef.current === true) {
      setActiveColor(helpers.getActiveColor());
    }
  }, [setActiveColor, helpers]);

  const setTextColor = useCallback((color: string) => {
    chainedCommands
      .setTextColor(color)
      .focus()
      .run();
  }, [chainedCommands]);

  const { manager } = useRemirrorContext();

  //There's no way to unlisten to this event so we need to jump through hoops to make sure it doesn't cause an error once this is unmounted
  useEffect(() => {
    manager.addHandler('stateUpdate', recalculateActiveColor);
  }, [manager, recalculateActiveColor]);

  useEffect(() => {
    return () => { isMountedRef.current = false; };
  }, []);

  return {
    activeColor,
    setTextColor,
  };
};