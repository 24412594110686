import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api/transcripts';
import type { GetTranscriptMedia } from '@api/interfaces/transcripts';

type Props = {
  transcriptId: number;
};

export const useFetchTranscriptMedia = (props: Props, options: Options = {}) => {

  const query = useQuery({
    queryKey: ['transcript.media', props], queryFn: ({ queryKey }) => {
      const params = queryKey[1] as Props;
      return api.getTranscriptMedia(params);
    }, ...options,
  });

  return query;
};

type Data = GetTranscriptMedia.Response;
type QK = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;