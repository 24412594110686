import { useCallback, useMemo } from 'react';
import { MaxDiffOptionOpen, MaxDiffOptionClosed } from '@presentation/ProjectSurveyResponses';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { SurveyQuestionOption, MaxDifferenceQuestion } from '@/types';
import Choices from './MaxDiff.Data.Option.Choices';

export default function MaxDiffDataOption({ activeOptionId, activeChoiceId, option, response, settings, onClick, onReset }: Props) {
  const active = activeOptionId === option.base.id;

  const handleOption = useCallback(() => {
    if (active) {
      onReset();
    } else {
      onClick(undefined);
    }
  }, [active, onClick, onReset]);

  const handleChoice = useCallback((choice: MaxDifferenceQuestion.Choices) => {
    onClick(choice);
  }, [onClick]);

  if (activeOptionId && !active) return null;

  return (
    <>
      {active
        ? <MaxDiffOptionOpen
            label={parseSurveyRichText(option.value)}
            score={response.score}
            onClick={handleOption} />
        : <MaxDiffOptionClosed
            label={parseSurveyRichText(option.value)}
            score={response.score}
            onClick={handleOption} />}
      {active &&
        <Choices
          active={activeChoiceId}
          response={response}
          settings={settings}
          onClick={handleChoice} />}
    </>
  );
}

export { MaxDiffDataOption };

type Props = {
  activeOptionId: number;
  activeChoiceId: MaxDifferenceQuestion.Choices;
  option: SurveyQuestionOption;
  response: MaxDifferenceQuestion.Aggregate.OptionResults;
  settings: MaxDifferenceQuestion.Settings;
  onClick: (choice: MaxDifferenceQuestion.Choices) => unknown;
  onReset: () => unknown;
};