import { cx } from '@utils';
import type { LabelProps } from './interfaces';
import styles from './style/Label.css';

type Props =
  LabelProps;

export const Label = (props: Props) => {
  const root = cx(styles.root, props.className);
  const label = cx(styles.label, props.labelStyle);

  return (
    <div className={root}>
      <span className={label}>{`${props.label}`}</span>
      {props.children}
    </div>
  );
};