import { useCallback, useMemo } from 'react';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@api';
import { querykey } from '@consts';
import type { PipelineProjectAttributes, ProjectPipelineUserAttributes } from '@/types';

type Props = IProjectId;
type Data = PipelineProjectAttributes;
type QueryKey = readonly [string, IProjectId];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectPipeline = (props: Props, options?: Options) => {

  const qk = querykey.Projects.Pipeline.Project.Hydrate.Get({ projectId: props.projectId });

  return useQuery({ queryKey: qk, queryFn: ({ queryKey }) => {
    const [, { projectId }] = queryKey;
    return api.projects.pipeline.getProjectPipeline({ projectId })
      .then(res => res.pipeline);
  }, ...options });
};

export const useInvalidateProjectPipelineQuery = () => {

  const queryClient = useQueryClient();

  return useCallback((data: IProjectId) => {
    const qk = querykey.Projects.Pipeline.Project.Hydrate.Get({ projectId: data.projectId });
    return queryClient.invalidateQueries(qk);
  }, [queryClient]);
};

export function extractUserPipelineData(data: PipelineProjectAttributes, userId: number): ProjectPipelineUserAttributes {
  return {
    linkUse: data.linkUses?.[userId],
    respondent: data.respondents?.[userId],
    user: data.users[userId],
  };
}

export const useFetchProjectPipelineUser = (props: IProjectId & IUserId, options?: Options) => {

  const q = useFetchProjectPipeline(props, options);

  return useMemo(() => {
    if (!q.data) return null;

    return extractUserPipelineData(q.data, props.userId);
  }, [q.data, props.userId]);
};