import { useMemo, memo } from 'react';
import { cx } from '@utils';
import type { FocusableClasses } from './interfaces';
import styles from './style.css';

type Props = {
  className?: string;
  classes?:   FocusableClasses;
  focused:    boolean;
  readOnly?:  boolean;
};

export const FocusableAnimation = memo(({ className = '', classes = {}, focused, readOnly }: Props) => {
  const blurClasses = useMemo(() => cx({
    [styles.blurred]: !focused,
    [styles.unblurred]: focused,
  }, cx(className, classes.root, classes.unfocused, classes.invalid)), [
    focused,
    className,
    classes.root,
    classes.unfocused,
    classes.invalid,
  ]);

  const focusClasses = useMemo(() => cx({
    [styles.focused]: focused,
    [styles.unfocused]: !focused,
  }, cx(className, classes.root, classes.focused, classes.invalid)), [
    focused,
    className,
    classes.root,
    classes.focused,
    classes.invalid,
  ]);

  if (readOnly) return <div className={styles.readonly} />;

  return (
    <>
      <div className={blurClasses} />
      <div className={focusClasses} />
    </>
  );
});

export default FocusableAnimation;