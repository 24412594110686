import { SurveyQuotaType } from '@enums';
import type { SurveyQuota } from '@/types';

export function validateQuota(quota: SurveyQuota.Record) {

  const dataValidation = quota.type === SurveyQuotaType.Advanced
    ? validateAdvancedQuotaData(quota.data)
    : { success: true };

  if (!dataValidation.success) return dataValidation;

  return {
    message: '',
    success: true,
  };
}

function validateAdvancedQuotaData(data: SurveyQuota.Advanced.Value) {
  const messages = [] as string[];
  for (const set of data.sets) {

    if (!set.question?.identifier) {
      messages.push('Question is not set');
    }

    let sum = 0;
    for (const item of set.items) {
      sum += item.pct;
      if (!item.condition.value) {
        messages.push('Option is not set');
      }
    }

    if (sum > 100) {
      messages.push('Sum is > 100');
    }
  }

  return {
    message: messages.join(','),
    success: !messages.length,
  };
}