import { Fragment, useCallback, useContext } from 'react';
import { PlusCircle } from 'react-feather';
import { FieldArray, Form, Formik, FormikConfig, FormikHelpers  } from 'formik';
import cuid from 'cuid';
import { ProjectHonorariaContext } from './Context';
import { Entry } from './Row.Entry';
import type * as PH from './interfaces';
import styles from './style/Project.Honoraria.css';
import * as $honoraria from './utils';

type Props = {
  children?:       React.ReactNode;
  onChangeValues?: PH.Form.OnChangeValues;
  onSubmit?:       PH.Form.OnSubmit;
};

export const ProjectHonorariaForm = (props: Props) => {
  const ctx = useContext(ProjectHonorariaContext);

  return (
    <Formik<PH.Form.State>
      initialValues={{ honoraria: ctx.items }}
      onSubmit={(values, helpers) => props.onSubmit?.(values, helpers)}
      validate={values => {
        const errors = $honoraria.validateForm(values.honoraria);

        props.onChangeValues?.(values, Object.keys(errors).length <= 0);

        return errors;
      }}>
      {formik =>
        <Fragment>
          {props.children}
          <FieldArray
            name="honoraria">
            {helpers =>
              <Form>
                <div className={styles.header}>
                  <div className={styles.title}>Title</div>
                  <div className={styles.currency}>Currency</div>
                  <div className={styles.amount}>Amount</div>
                </div>
                {formik.values.honoraria.map((item, index) =>
                  <Entry
                    helpers={helpers}
                    index={index}
                    item={item}
                    key={item.id} />)}

                <div className={styles.add}>
                  <button
                    className={styles.plus}
                    onClick={() => helpers.push({
                      currency: null,
                      id: cuid(),
                      title: $honoraria.getInitialTitle(formik.values.honoraria.map(x => x.title)),
                      value: '',
                    })}
                    type="button">
                    <PlusCircle
                      size={22}
                      strokeWidth={2.5} />
                    <span>Add Honoraria</span>
                  </button>
                </div>
              </Form>
            }
          </FieldArray>
        </Fragment>
      }
    </Formik>
  );
};

ProjectHonorariaForm.displayName = 'Project.Honoraria.Form';