import type { Tokens } from '@api/interfaces';
import type { ActionableLinkType } from '@enums';
import { xhr } from '@services/http';

export const findProject = (data: Tokens.FindProject.Request) => {
  return xhr.get<Tokens.FindProject.Response>(`/tokens/projects/${data.token}`)
    .then(res => res.data);
};

export const handleActionableLink = <T extends ActionableLinkType>(data: Tokens.HandleActionableLink.Request<T>): Promise<Tokens.HandleActionableLink.Response<T>> => {
  return xhr.put(`/tokens/actionables/${data.token}`, data)
    .then(res => res.data);
};