import { useCallback } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import { useSurveyQuotasGoal } from '@/containers/SurveyBuilder';
import { NumberInput } from '@/components/Input';

type Props = {
  onChange: (value: number) => void;
  value: number;
};

export const QuotaBuilderLimit = ({ onChange, value }: Props) => {

  const goal = useSurveyQuotasGoal();

  const updateLimit = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value === ''
      ? null
      : +e.target.value;

    onChange(value);

  }, [onChange]);

  const handleLimitBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(+e.target.value);
  }, [onChange]);

  return (
    <NumberInput
      allowLeadingZeros={false}
      allowNegative={false}
      onBlur={handleLimitBlur}
      onChange={updateLimit}
      isAllowed={({ floatValue, value }: NumberFormatValues) => {
        if (value === '') return true;
        if (floatValue >= 0 && floatValue <= goal) return true;

        return false;
      }}
      value={value} />
  );
};