import { useCallback, ChangeEvent } from 'react';
import type { SurveyTemplate } from '@/types/survey';
import { Input } from '@/components/Input';
import styles from './style/Input.MessageExerciseItem.css';

type Props = {
  onChange: (id: string) => (data: SurveyTemplate.MessageTesting.MessageAnalysisItem) => void;
  value: SurveyTemplate.MessageTesting.MessageAnalysisItem[];
};

export const TemplateMessageAnalysisInput = (props: Props) => {

  return (
    <>
      <div className={styles.title}>Exercise 1</div>
      <MessageAnalysisItem
        onChange={props.onChange(props.value[0].id)}
        value={props.value[0]} />
      <div className={styles.title}>Exercise 2</div>
      <MessageAnalysisItem
        onChange={props.onChange(props.value[1].id)}
        value={props.value[1]} />
    </>
  );
};

type ItemProps = {
  onChange: (data: SurveyTemplate.MessageTesting.MessageAnalysisItem) => void;
  value: SurveyTemplate.MessageTesting.MessageAnalysisItem;
};

const MessageAnalysisItem = ({ onChange, ...props }: ItemProps) => {

  const handleChange = useCallback((field: keyof SurveyTemplate.MessageTesting.MessageAnalysisItem) => (value: string) => {
    return onChange({
      ...props.value,
      [field]: value,
    });
  }, [onChange, props.value]);

  return (
    <div className={styles.item}>
      <div className={styles.row}>
        <div className={styles.label}>Dimension</div>
        <Input
          placeholder="Enter dimension"
          onChange={e => handleChange('dimension')(e.target.value)}
          value={props.value.dimension} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Left Axis</div>
        <Input
          placeholder="Enter an axis header"
          onChange={e => handleChange('leftAxis')(e.target.value)}
          value={props.value.leftAxis} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Right Axis</div>
        <Input
          placeholder="Enter an axis header"
          onChange={e => handleChange('rightAxis')(e.target.value)}
          value={props.value.rightAxis} />
      </div>
    </div>
  );
};