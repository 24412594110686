import { useCallback, useContext } from 'react';
import { Camera } from 'react-feather';
import { ArticleCoverImageContext } from '@containers/Post.Article.Form/Context';
import { ImageCrop } from '@/components/ImageCrop';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import * as Article from '@/components/Post.Article';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/CoverImage.css';

type Props = unknown;

export const CoverImage = (props: Props) => {
  const [toggle, UploadModal] = useModal(ImageUploadModal);
  const [image, setImage] = useContext(ArticleCoverImageContext);

  const handleRemove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setImage(null);
  }, [setImage]);

  if (!image?.url) {
    return (
      <div
        className={styles.root}>
        <Placeholder toggle={toggle} />
        <UploadModal />
      </div>
    );
  }

  return (
    <div
      className={styles.root}>
      <Article.CoverImage
        classes={{ root: styles.display }}
        editable
        onClick={toggle}
        onRemove={handleRemove}
        src={image?.url} />
      <UploadModal />
    </div>
  );
};

CoverImage.displayName = 'Post.Article.New.CoverImage';

type PlaceholderProps = {
  toggle: () => unknown;
};

const Placeholder = (props: PlaceholderProps) => {
  return (
    <div
      className={styles.display}
      onClick={props.toggle}>
      <div className={styles.noimage}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <Camera size={30} />
          </div>
          <div className={styles.header}>Upload a cover image</div>
          <div className={styles.p}>{copy.line1}</div>
          <div className={styles.p}>{copy.line2}</div>
        </div>
      </div>
      <div className={styles.placeholder} />
    </div>
  );
};

const copy = {
  line1: `Consider adding a cover image that complements your article to attract more readers.`,
  line2: `We recommend uploading an image with a pixel size of 1280 x 720.`,
};

Placeholder.displayName = 'Post.Article.New.CoverImage.Placeholder';

type ImageUploadModalProps = Pick<ModalProps, 'onClose' | 'open'>;

const ImageUploadModal = (props: ImageUploadModalProps) => {
  const [image, setImage] = useContext(ArticleCoverImageContext);

  return (
    <Modal
      classes={{ root: styles.modal }}
      onClose={props.onClose}
      open={props.open}>
      <ImageCrop
        classes={{
          root: styles.cropper,
          picker: styles.picker,
          preview: {
            image: styles.cropped,
          },
          original: styles.original,
        }}
        aspect={2 / 1}
        crop={{
          width: 1280,
          height: 640,
        }}
        imageUrl={image?.url}
        isSubmitting={false}
        onSave={data => {
          setImage({
            blob: data.blob,
            url: data.url,
          });
          props.onClose();
        }}
        ruleOfThirds={true} />
    </Modal>
  );
};

ImageUploadModal.displayName = 'Post.Article.New.CoverImage.ImageUploadModal';