import { useCallback, useMemo } from 'react';
import type { Column as RTColumn, UsePaginationInstanceProps, TableOptions } from 'react-table';
import { useFilters, useTable, useFlexLayout, usePagination } from 'react-table';
import type { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import { isCallLike, isSurveyLike } from '@containers/GroupProject/utils/actions';
import type { ProjectType } from '@enums';
import * as Table from '@/components/Table';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import * as Column from './Column';
import { RespondentsTableEmpty } from './Respondents.Table.Empty';
import type { PaginationData, RespondentItem } from './interfaces';

type Props = {
  data:       RespondentItem[];
  empty:      boolean;
  loading:    boolean;
  pagination: PaginationData;
  projectType: ProjectType;
  query:      DecodedValueMap<QPConfig>;
  setQuery:   SetQuery<QPConfig>;
};

export const RespondentsTable = ({ projectType, query, setQuery, ...props }: Props) => {
  const callLike = isCallLike({ projectType });
  const surveyLike = isSurveyLike({ projectType });

  const columns = useMemo<RTColumn<RespondentItem>[]>(() => [
    {
      Cell: Column.Id.Cell,
      id: `id`,
      Header: `ID`,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      Cell: Column.Name.Cell,
      id: `name`,
      Header: `Name`,
      maxWidth: 200,
      minWidth: 200,
    },
    surveyLike ? {
      Cell: Column.Response.Cell,
      id: `response`,
      Header: `Response`,
      maxWidth: 100,
      minWidth: 120,
    } : null,
    callLike ? {
      Cell: Column.Status.Cell,
      id: `status`,
      Header: `Status`,
      maxWidth: 60,
      minWidth: 120,
    } : null,
    {
      Cell: Column.Added.Cell,
      id: `added`,
      Header: `Added`,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      Cell: Column.Actions.Cell,
      id: `actions`,
      Header: `Actions`,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      Cell: Column.Note.Cell,
      id: `note`,
      Header: `Note`,
      maxWidth: 150,
      minWidth: 150,
    },
  ].filter(Boolean), [
    callLike,
    surveyLike,
  ]);

  const params: TableOptions<RespondentItem> = {
    columns,
    data: props.data,
    initialState: {
      pageIndex: 0,
      pageSize: query.size,
    },
    manualFilters: true,
    manualPagination: true,
    pageCount: props.pagination.pageCount,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
    setPageSize,
  } = useTable<RespondentItem>(
    params,
    useFilters,
    useFlexLayout,
    usePagination,
  );

  const nextPage = useCallback(() => {
    setQuery({ index: query.index + 1 }, 'pushIn');
  }, [
    query.index,
    setQuery,
  ]);

  const previousPage = useCallback(() => {
    const prevIndex = query.index - 1;
    const index = prevIndex > 0
      ? prevIndex
      : undefined;
    setQuery({ index }, 'pushIn');
  }, [
    query.index,
    setQuery,
  ]);

  const canPreviousPage = query.index > 0;
  const canNextPage = query.index + 1 < props.pagination.pageCount;

  const paginationProps: PaginationProps = {
    canNextPage,
    canPreviousPage,
    nextPage,
    pageCount: props.pagination.pageCount,
    pageIndex: query.index,
    pageSize: query.size,
    previousPage,
    pageOptions,
    setPageSize,
  };

  return (
    <Table.Root
      EmptyComponent={RespondentsTableEmpty}
      empty={props.empty}
      loading={props.loading}>
      <Table.Header headerGroups={headerGroups} />
      <Table.Body
        {...getTableBodyProps()}
        prepareRow={prepareRow}
        rows={rows} />
      <Table.Layout.Footer>
        <Pagination {...paginationProps} />
      </Table.Layout.Footer>
    </Table.Root>
  );
};

RespondentsTable.displayName = 'Project.Pipeline.Respondents.Table';

type QPConfig = {
  index: QueryParamConfig<number, number>;
  size:  QueryParamConfig<number, number>;
};

type PaginationProps = {
  pageIndex:        number;
  pageSize:         number;
  pageSizeOptions?: number[];
} & Pick<UsePaginationInstanceProps<RespondentItem>,
    | 'canNextPage'
    | 'canPreviousPage'
    | 'nextPage'
    | 'previousPage'
    | 'pageCount'
    | 'pageOptions'>
  & Partial<Pick<UsePaginationInstanceProps<RespondentItem>,
    | 'setPageSize'>>;