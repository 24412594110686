import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api/universal-search';
import type { UniversalSearch } from '@api/interfaces';

type Data = UniversalSearch.Bar.Response;

export function useFetchUniversalSearchBar<TData = Data>(
  query: string,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {
  return useQuery({ queryKey: keyUseFetchUniversalSearchBar(query), queryFn: async () => {
    const source = Axios.CancelToken.source();

    const start = Date.now();
    const p = api.bar({
      query,
      cancelToken: source.token,
    });

    p.cancel = () => {
      source.cancel();
    };

    const result = await p;
    const delta = Date.now() - start;

    await new Promise<void>(resolve => setTimeout(() => resolve(), Math.max(0, 200 - delta)));

    return result;
  }, staleTime: 60000, ...options });
}

export function keyUseFetchUniversalSearchBar(query: string) {
  return ['universal-search', query, 'bar'];
}