import { useCallback } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelectUser } from '@containers/Store';
import type { ProjectSurveyDetailsExternalUser } from '@/types';
import { cx, getLocationFor, hasInternalAdminRole } from '@utils';
import { SurveyDetail } from '@/scenes/surveys/CompletedSurvey/SurveyDetail';
import { SurveyResponseRating } from '@/components/SurveyResponseRating';
import type { SurveyDetailsProps } from './interfaces';
import { useTerminationModal } from './TerminationModal';
import styles from './style.css';

export const SurveyDetailsExternalUser = (props: SurveyDetailsProps<ProjectSurveyDetailsExternalUser>) => {
  const user = useSelectUser();
  const completedOn = format(new Date(props.survey.completedOn), 'MMM do yyyy');

  const [toggleTerminationModal, TerminationModal] = useTerminationModal();

  const renderTermination = useCallback(() => {
    if (!props.termination) return null;

    return (
      <div className={styles.termination} onClick={toggleTerminationModal}>View Termination Reason</div>
    );
  }, [
    props.termination,
    toggleTerminationModal,
  ]);

  return (
    <div className={styles.details}>
      {hasInternalAdminRole(user) &&
        <SurveyDetail
          name={'Source'}>
          {props.user.fromLabel}
        </SurveyDetail>
      }
      {hasInternalAdminRole(user) &&
        <SurveyDetail
          name="Group">
          {props.group.name}
        </SurveyDetail>
      }
      <SurveyDetail
        name={'Project'}>
        {props.project.name}
      </SurveyDetail>
      <SurveyDetail
        name={'Completed On'}>
        {completedOn}
      </SurveyDetail>
      {hasInternalAdminRole(user) &&
        <SurveyDetail name="Client Fee">
          {
            props.billing?.chargeable?.id
              ? (
                <Link to={getLocationFor.admin.billing.chargeables.details({
                  chargeableId: props.billing.chargeable.id,
                })}>
                  ${props.billing.chargeable.amount}
                </Link>
              ) : 'Not available'
          }
        </SurveyDetail>
      }
      {!!props.survey.classifications.length &&
        <SurveyDetail
          name="Classifications">
          {props.survey.classifications.map(m => m.name).join(', ')}
        </SurveyDetail>}
      <SurveyResponseRating
        rating={props.rating}
        projectSurveyResponseId={props.project.responseId}
        projectId={props.project.id} />
      {renderTermination()}
      <TerminationModal termination={props.termination} />
    </div>
  );
};

export default SurveyDetailsExternalUser;