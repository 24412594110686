import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import { Scheduling, AdHocCallScheduling } from '@consts/path.main';
import {
  AdHocCallProposals,
  AdHocCallSchedulingConfirmation,
  InvitationCalendar,
  ProposalCalendar,
  ProjectCallSchedulingConfirmation,
} from '@containers/Calendar';
import type * as Routing from '@routes/interfaces';

export const routes: Routing.Route[] = [
  {
    component: AdHocCallSchedulingConfirmation,
    exact: true,
    path: AdHocCallScheduling.Confirmations,
  },
  {
    component: AdHocCallProposals,
    exact: true,
    path: AdHocCallScheduling.Proposals,
  },
  {
    component: InvitationCalendar,
    exact: true,
    path: Scheduling.Project,
  },
  {
    component: ProjectCallSchedulingConfirmation,
    exact: true,
    path: Scheduling.Confirmations,
  },
  {
    component: ProposalCalendar,
    exact: true,
    path: Scheduling.Proposals,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Scheduling.Root,
});