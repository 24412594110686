import type { ModalProps } from '@/components/Modal/Modal';
import { Alert } from '@/components/Modal/Alert';
import { useModal } from '@/components/Modal/hooks/useModal';

type Props = {
  onConfirm: () => unknown;
} & Pick<ModalProps,
    | 'onClose'
    | 'open'>;

export const DiscardPostAlert = (props: Props) => {
  return (
    <Alert
      confirmText="Discard"
      message="Are you sure you want to discard changes?"
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      open={props.open} />
  );
};

DiscardPostAlert.displayName = 'PostCreation.Publisher.DiscardPostAlert';

export const useDiscardPostAlert = () => useModal(DiscardPostAlert);