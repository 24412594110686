import { useCallback } from 'react';
import { format } from 'date-fns';
import { utils as $enumUtils } from '@enums';
import type { ConferenceTagSummaryItem, TagSummaryFeedback } from '@/types/conferences.tags';
import { Chip } from '@presentation/Chip';
import { SeeMore, Collapser } from '@presentation/SeeMore';
import { Header } from '@/components/Modal/Header';
import { StarRatingPreview } from '@/components/StarRating';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/Modal.Feedback.css';

type Props = {
  items: TagSummaryFeedback[];
  summaryItem: ConferenceTagSummaryItem;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const TagSummaryFeedbackModal = ({
  items,
  open,
  onClose,
  summaryItem,
}: Props) => {

  const renderItem = useCallback((item: TagSummaryFeedback) => {

    return (
      <div className={styles.item} key={item.id}>
        {item.rating && (
          <div className={styles.rating}>
            <div className={styles.label}>Rating</div>
            <StarRatingPreview
              rating={item.rating} />
          </div>
        )}
        {item.structuredFeedback.length > 0 && (
          <div className={styles.structuredFeedback}>
            <div className={styles.label}>Issues</div>
            <div className={styles.tags}>
              {item.structuredFeedback.map(f => (
                <Chip
                  key={f}
                  color="selected"
                  className={styles.tag}>
                  {$enumUtils.StructuredSummaryFeedback.getName(f)}
                </Chip>
              ))}
            </div>
          </div>
        )}
        {item.notes && (
          <div className={styles.notes}>
            <div className={styles.label}>Additional Feedback</div>
            <div className={styles.value}>
              <SeeMore
                lineHeight={20}
                maxLines={10}
                CollapserComp={Collapser}>
                {item.notes}
              </SeeMore>
            </div>
          </div>
        )}
        <div className={styles.user}>
          {item.user.name} <span className={styles.updatedOn}>({format(item.lastUpdatedOn, 'MM/dd/yyyy hh:mm a')})</span>
        </div>
      </div>
    );
  }, []);

  const title = `${summaryItem.tag.name} Feedback`;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <Header text={title} />

      <div className={styles.body}>
        {items.map(renderItem)}
      </div>

    </Modal>
  );
};

export const useTagSummaryFeedbackModal = () => useModal(TagSummaryFeedbackModal);