import * as Branding from '@containers/Branding';
import { BrandingContainer } from '@containers/Branding';
import { BadgeNotificationsContainer, NotificationsContainer } from '@containers/Notifications';
import * as Post from '@containers/PostCreation/Post';
import { QueryDownloaderContainer } from '@containers/QueryDownloader';
import { RateLimitingContainer } from '@containers/RateLimiting';
import { RehydrationContainer } from '@containers/Store';
import BannerState from '@containers/Banner/BannerState';
import { ConsentManagementContainer } from '@containers/ConsentManagement';
import { WebSocketContainer } from '@containers/WebSocket';
import { FileUploadsState, FileUploadsProgressContainer } from '@containers/WorkspaceFileUpload/';
import { BrandInsightsContainer } from '@containers/BrandInsights';
import { UserProfileReattestation } from './UserProfileReattestation';
import { MuiThemeProvider } from './MuiThemeProvider';

type Props = ChildrenProps;

export const MainSubscriber = (props: Props) => {

  return (
    <RehydrationContainer>
      <BannerState>
        <ConsentManagementContainer>
          <UserProfileReattestation>
            <BadgeNotificationsContainer>
              <NotificationsContainer>
                <WebSocketContainer>
                  <Post.Video.UploaderContainer>
                    <BrandingContainer>
                      <MuiThemeProvider>
                        <Branding.WhitelabelingPaletteContainer>
                          <Branding.SurveyTheming>
                            <QueryDownloaderContainer>
                              <RateLimitingContainer>
                                <FileUploadsState>
                                  <BrandInsightsContainer>
                                    <FileUploadsProgressContainer>
                                      {props.children}
                                    </FileUploadsProgressContainer>
                                  </BrandInsightsContainer>
                                </FileUploadsState>
                              </RateLimitingContainer>
                            </QueryDownloaderContainer>
                          </Branding.SurveyTheming>
                        </Branding.WhitelabelingPaletteContainer>
                      </MuiThemeProvider>
                    </BrandingContainer>
                  </Post.Video.UploaderContainer>
                </WebSocketContainer>
              </NotificationsContainer>
            </BadgeNotificationsContainer>
          </UserProfileReattestation>
        </ConsentManagementContainer>
      </BannerState>
    </RehydrationContainer>
  );
};

export default MainSubscriber;