import { useMemo, useReducer } from 'react';
import { ContentPublisherContext } from './Context';
import * as Post from './Post';
import * as PostCreation from './PostCreation.Subscription';
import type { Subscription } from './interfaces';
import { PostContentType } from './interfaces';

type Props = {
  children:     React.ReactNode;
  onPublished?: Subscription.OnPublished.Fn;
  onError?:     (e: Error) => unknown;
  onTextError?:      (e: Error) => unknown;
  onTextPublished?:  Subscription.OnPublished.Fn<PostContentType.Text>;
  onTextSubmit?:     Subscription.OnTextSubmit.Fn<PostContentType.Text>;

  onVideoError?:     (e: Error) => unknown;
  onVideoPublished?: Subscription.OnPublished.Fn<PostContentType.Video>;
  onVideoSubmit?:    Subscription.OnVideoSubmit.Fn;
};

export const Container = (props: Props) => {
  const [modal, dispatch] = useReducer(reducer, null);

  const state = useMemo(() => ({
    text: {
      active: PostContentType.Text === modal,
      toggle: () => dispatch(PostContentType.Text),
    },
    video: {
      active: PostContentType.Video === modal,
      toggle: () => dispatch(PostContentType.Video),
    },
  }), [modal]);

  return (
    <PostCreation.Subscription
      onPublished={props.onPublished}
      onError={props.onError}
      onTextSubmit={props.onTextSubmit}
      onVideoSubmit={props.onVideoSubmit}>
      <ContentPublisherContext.Provider value={state}>
        {props.children}
        <Post.Text.Container />
        <Post.Video.Container />
      </ContentPublisherContext.Provider>
    </PostCreation.Subscription>
  );
};

const reducer = (active: PostContentType, key: PostContentType) => {
  return active === key
    ? null
    : key;
};

Container.displayName = 'PostCreation.Container';