import http from '@services/http';
import type { Branding } from '@api/interfaces/groups';

export const fetchSurveyTheming = (data: Branding.Surveys.FetchSurveyTheming.Request) => {
  return http.get<Branding.Surveys.FetchSurveyTheming.Response>(`/groups/${data.groupId}/branding/surveys/theming`);
};

export const removeImage = (data: Branding.Surveys.RemoveImage.Request) => {
  return http.delete<Branding.Surveys.RemoveImage.Response>(`/groups/${data.groupId}/branding/surveys/pages/completion/image`);
};

export const updateImage = (data: Branding.Surveys.UpdateImage.Request): Promise<Branding.Surveys.UpdateImage.Response> => {
  const formData = new FormData();
  formData.append('blob', data.blob);

  return http.post(`/groups/${data.groupId}/branding/surveys/pages/completion/image`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export const updateText = (data: Branding.Surveys.UpdateText.Request): Promise<Branding.Surveys.UpdateText.Response> => {
  const { groupId, ...params } = data;

  return http.put(`/groups/${groupId}/branding/surveys/pages/completion/text`, params);
};

export const updatePaletteColor = (data: Branding.Surveys.UpdatePaletteColor.Request) => {
  const { groupId, ...params } = data;

  return http.put(`/groups/${groupId}/branding/surveys/palettes`, params);
};