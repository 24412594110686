import { memo, useContext } from 'react';
import { Lightbulb } from '@/components/icons/Lightbulb';
import { AppThemingContext } from '@containers/Branding/Context';
import styles from './style/Insights.LoadingProgress.css';

type Props = unknown;

export const LoadingProgress = memo((props: Props) => {
  const ctx = useContext(AppThemingContext);

  const fill = ctx?.enabled ? ctx?.palette?.primary?.main : undefined;

  return (
    <div className={styles.root}>
      <svg viewBox="0 0 100 100">
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="1.5"
              floodColor="#5573b1" />
          </filter>
        </defs>
        <circle
          className={styles.spinner}
          cx="50"
          cy="50"
          r="45" />
      </svg>
      <div className={styles.lightbulb}>
        <Lightbulb
          className={styles.bulb}
          fill={fill}
          size={24} />
      </div>
    </div>
  );
});

LoadingProgress.displayName = 'Insights.Panel.LoadingProgress';