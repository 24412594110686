import type { Enum, EnumKey } from '@enums/interfaces';

export const fromEnum = <T extends EnumKey>(item: Enum<T>) => {
  return item.values().reduce<OptionsMap>((acc, x) => ({
    ...acc,
    [x]: item.getName(x),
  }), {});
};

export const fromArray = (items: (number | string)[]) => {
  return items.reduce<OptionsMap>((acc, x) => ({
    ...acc,
    [x]: `${x}`,
  }), {});
};

export type OptionsMap = { [key: string]: string };