import { useCallback } from 'react';
import { ProjectStatus } from '@enums';
import type { OnSelectChange } from '$admin/components/Select';
import { Select } from '$admin/components/Select';

type Props = {
  className?: string;
  onChange: (value: ProjectStatus | 0) => void;
  value: ProjectStatus | 0;
};

const StatusFilter = {
  [0]: 'All',
  [ProjectStatus.Active]: 'Active',
  [ProjectStatus.Archived]: 'Archived',
};

export const ProjectStatusFilter = ({ className, onChange, value }: Props) => {

  const handleChange: OnSelectChange<0 | ProjectStatus> = useCallback(e => {
    const status = e.target.value
      ? +e.target.value as ProjectStatus
      : null;
    onChange(status);
  }, [onChange]);

  return (
    <Select
      className={className}
      defaultValue={ProjectStatus.Active}
      id="status"
      name="status"
      onChange={handleChange}
      options={StatusFilter}
      value={value} />
  );
};

export default ProjectStatusFilter;