import type { SurveyQuestionNumberSettings } from '@/types';
import { SurveyQuestionNumberType } from '@/types';
import { CURRENCY_FORMATTERS_LEGACY } from '@utils/format';

type FormatOpts = {
  roundPlaces?: number;
};

export const DefaultFormatOpts: FormatOpts = {
  roundPlaces: 2,
};

export const AggregateFormatOpts: FormatOpts = {
  roundPlaces: 2,
};

export const formatSurveyNumber = (val: number, settings: SurveyQuestionNumberSettings, opts = DefaultFormatOpts) => {
  if (val == null) {
    return null;
  }
  const rounded = +val.toFixed(opts.roundPlaces ?? 2);
  switch (settings?.type ?? SurveyQuestionNumberType.Number) {
    case SurveyQuestionNumberType.Number:
      return rounded.toLocaleString('en-US');
    case SurveyQuestionNumberType.Currency: {
      const formatterOptions = CURRENCY_FORMATTERS_LEGACY[settings.currency ?? 'USD'].resolvedOptions();
      let minimumFractionDigits: number = formatterOptions.minimumFractionDigits;
      let maximumFractionDigits: number = formatterOptions.maximumFractionDigits;
      if (minimumFractionDigits === 0 && rounded % 1 !== 0) {
        minimumFractionDigits = Math.max(opts.roundPlaces, 2);
        maximumFractionDigits = Math.max(minimumFractionDigits, maximumFractionDigits);
      }

      const resolvedOptions = {
        ...formatterOptions,
        minimumFractionDigits,
        maximumFractionDigits,
      };
      const formatter = Intl.NumberFormat(formatterOptions.locale, resolvedOptions);
      return formatter.format(rounded);
    }
    case SurveyQuestionNumberType.Unit:
      return `${rounded.toLocaleString('en-US')} ${settings.unitType}`;
  }
};