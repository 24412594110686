import { memo } from 'react';
import styled from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = {
  className?: string;
  index: number;
  count: number;
  onPrevious: () => unknown;
  onNext: () => unknown;
};

export const MessageVariantSelector = memo(({ className, index, count, onPrevious, onNext }: Props) => {
  const leftEnabled = index > 0;
  const rightEnabled = index + 1 < count;

  return (
    <Root className={className}>
      <Wrap>
        <ArrowLeft disabled={!leftEnabled} onClick={leftEnabled ? onPrevious : undefined}>
          <ArrowLeftIcon />
        </ArrowLeft>
        <Text>{`${index + 1} / ${count}`}</Text>
        <ArrowRight disabled={!rightEnabled} onClick={rightEnabled ? onNext : undefined}>
          <ArrowRightIcon />
        </ArrowRight>
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.palette.black.light1};
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Arrow = styled.button`
  color: ${props => !props.disabled ? props.theme.palette.black.light1 : props.theme.palette.gray.light1};
  cursor: ${props => !props.disabled ? 'pointer' : 'initial'};
  display: inline-flex;
  font-size: 13px;
  font-family: ${props => props.theme.fonts.semiBold};
`;

const ArrowLeft = styled(Arrow)`
  margin-right: 2px;
`;

const ArrowLeftIcon = styled(ChevronLeftIcon)`
  font-size: 18px;
`;

const ArrowRight = styled(Arrow)`
  margin-left: 2px;
`;

const ArrowRightIcon = styled(ChevronRightIcon)`
  font-size: 18px;
`;
