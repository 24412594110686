import { Redirect, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { pathname } from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import img from 'static/icons/file-locked.svg?url';
import styles from './style/Access.Request.css';

type Props = unknown;

export const AccessRequest = (props: Props) => {
  const params = useParams<Stringify<IWorkspaceObjectId>>();

  const qc = useQueryClient();

  const query = useQuery({ queryKey: [
    'get:access/objects',
    params?.objectId,
  ], queryFn: () => {
    return api.access.fetchRequest({
      objectId: +params?.objectId,
    });
  } });

  const create = useMutation({ mutationKey: [
    'post:access/requests',
    params?.objectId,
  ], mutationFn: () => {
    return api.access.createRequest({
      objectId: +params?.objectId,
    })
    .then(res => {
      qc.setQueryData([
        'get:access/objects',
        params?.objectId,
      ], (data: API.Access.FetchRequest.Response) => ({
        ...data,
        request: res.request,
      }));
    });
  } });

  const resend = useMutation({ mutationKey: [
    'post:access/requests/resend',
    query.data?.request?.id,
    query.data?.request?.objectId,
  ], mutationFn: () => {
    return api.access.resendRequest({
      objectId: query.data?.request?.objectId,
      requestId: query.data?.request?.id,
    })
    .then(res => {
      qc.setQueryData([
        'get:access/objects',
        params?.objectId,
      ], (data: API.Access.FetchRequest.Response) => ({
        ...data,
        request: res.request,
      }));
    });
  } });

  if (query.isLoading) return null;

  const exists = !!query.data?.request?.id;
  const pending = exists
    && query.data?.request?.approvedOn === null
    && query.data?.request?.declinedOn === null;

  if (query.data?.capabilities?.canView) {
    return (
      <Redirect to={pathname.Home} />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.graphic}>
            <img src={img} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>{copy.title}</div>
            <div className={styles.subtitle}>{copy.subtitle}</div>
            {pending &&
              <div className={styles.message}>
                {copy.message}
              </div>}
            <div className={styles.footer}>
              {!exists &&
                <ButtonActivityIndicator
                  className={styles.btn}
                  loading={create.isLoading}
                  onClick={() => create.mutate()}>
                  Request Access
                </ButtonActivityIndicator>}
              {pending &&
                <div className={styles.notify}>
                  <ButtonActivityIndicator
                    className={styles.btn}
                    loading={resend.isLoading}
                    onClick={() => resend.mutate()}>
                    Resend Request
                  </ButtonActivityIndicator>

                  <div className={styles.timestamp}>
                    <div className={styles.meta}>Request sent on</div>
                    <div className={styles.meta}>{format(new Date(query.data.request.updatedOn), 'MM/dd/yyyy @ h:mm a')}</div>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccessRequest.displayName = 'Access.Request';

const copy = {
  message: `Awaiting approval. Please check your email for further updates.`,
  subtitle: `Your account does not have permission to view this file. You can request access from the owner below.`,
  title: `You need access`,
};