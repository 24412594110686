import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api';
import type { GetUpcomingCalls } from '@api/interfaces/users';

type Data = GetUpcomingCalls.Item[];

export default function useUpcomingCalls<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery({ queryKey: [querykey.Users.UpcomingCalls], queryFn: async () => {
    return api.users.getUpcomingCalls();
  }, ...options });
}

export { useUpcomingCalls };