import Skeleton from '@mui/material/Skeleton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { formatCurrency } from '@utils/format';
import type { Currencies } from '@/types';
import { Button } from '@/components/Button';
import { StarRatingPreview } from '@/components/StarRating';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style/Profile.Header.css';

type Props = {
  hourlyRate: number;
  loading?: boolean;
  name: string;
  pictureUrl: string;
  publicUrl: string;
  rateCurrency: Currencies.CurrencyCode;
  rating: number;
};

export const ProfileHeader = (props: Props) => {
  const rate = props.hourlyRate && formatCurrency(props.hourlyRate, props.rateCurrency || 'USD', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.avatar}>
            {props.loading &&
              <Skeleton
                height={95}
                width={95}
                variant="circular" />}
            {!props.loading &&
              <UserAvatar
                pictureUrl={props.pictureUrl}
                size={95} />}
          </div>

          <div className={styles.info}>
            {props.loading &&
              <Skeleton
                width={150}
                height={30}
                style={{ margin: `10px 0` }}
                variant="rounded" />}
            {!props.loading &&
              <h1 className={styles.name}>{props.name}</h1>}

            {props.loading &&
              <div className={styles.details}>
                <div className={styles.field}>
                  <Skeleton
                    width={100}
                    height={22}
                    variant="rounded" />
                </div>
              </div>}

            {!props.loading &&
              <div className={styles.details}>
                {props.rating &&
                  <div className={styles.field}>
                    <div className={styles.label}>Rating:</div>
                    <StarRatingPreview
                      className={styles.star}
                      rating={props.rating} />
                  </div>}
                {!!props.hourlyRate &&
                  <div className={styles.field}>
                    <div className={styles.label}>Rate:</div>
                    <div className={styles.rate}>{`${rate}/hr`}</div>
                  </div>}
              </div>}
          </div>
        </div>

        {props.publicUrl &&
          <div className={styles.icon}>
            <Button
              href={props.publicUrl}
              variant="link">
              <LinkedInIcon />
            </Button>
          </div>}
      </div>
    </div>
  );
};

ProfileHeader.displayName = 'Profile.Header';