import type { AlertModalProps } from '@/components/Modal/Alert';
import Alert from '@/components/Modal/Alert';

type Props =
  Omit<AlertModalProps,
    'confirmText' | 'message'>;

export const DeleteQuestionAlert = (props: Props) => (
  <Alert
    confirmText="Delete"
    message={(
      <>
        <div style={{
          fontFamily: 'var(--font-semibold)',
          fontSize: 18,
        }}>Are you sure you want to delete this question?
        </div>
        <br />
        <div>This will also remove any logic, quotas, tagging, or conditional options associated with it.</div>
      </>
    )}
    {...props} />
);

export default DeleteQuestionAlert;