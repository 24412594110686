import type { ConferencePlatform } from '@enums';
import { utils as enumUtils } from '@enums';
import { EnumDropDown } from '@/components/DropDown';

type Props = {
  items?: ConferencePlatform[];
  onSelect: (value: ConferencePlatform) => void;
  value: ConferencePlatform;
};

export const ConferencePlatformDropdown = ({
  items = enumUtils.ConferencePlatform.values(),
  onSelect,
  value,
}: Props) => {

  return (
    <EnumDropDown
      enum={enumUtils.ConferencePlatform}
      items={items}
      onSelect={onSelect}
      value={value} />
  );
};

export default ConferencePlatformDropdown;