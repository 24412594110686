import { useCallback, useMemo, useState } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { ConjointAnalysisQuestion } from '@/types';
import {
  useSurveyFormQuestionContext,
} from './hooks';
import { ConjointAnalysisFormContext, ConjointAnalysisNavigationContext, useSurveyFormQuestionAnswer } from './Context';
import NavigationContainer from './ConjointAnalysis.Navigation';

type Props =
  ChildrenProps;

function resolveInitialSet(answer: ConjointAnalysisQuestion.RespondentAnswer.Value) {
  return answer.items.every(e => e.selected === null)
    ? 1
    : answer.items.length;
}

export const ConjointAnalysisFormContainer = (props: Props) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ConjointAnalysis>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.ConjointAnalysis>();

  const [currentSet, setCurrentSet] = useState(resolveInitialSet(answer));

  const back = useCallback(() => {
    return setCurrentSet(s => s - 1);
  }, []);

  const next = useCallback(() => {
    return setCurrentSet(s => s + 1);
  }, []);

  const setSetAnswer = useCallback((setNo: number) => (conceptNo: number) => {

    const items = answer.items.reduce<ConjointAnalysisQuestion.RespondentAnswer.SetAnswer[]>((acc, x, i) => {
      if (setNo === i + 1) {
        const set = question.sets[setNo - 1];
        const concepts = set.concepts.reduce<ConjointAnalysisQuestion.RespondentAnswer.Concept[]>((acc2, concept) => {
          const items = Object.keys(concept).map(attributeId => ({
            attributeId: +attributeId,
            levelId: concept[+attributeId].id,
          }));
          acc2.push(items);
          return acc2;
        }, []);

        return acc.concat({
          concepts,
          selected: conceptNo,
        });
      }

      return acc.concat(x);
    }, []);

    setAnswer({ items });
  }, [
    answer,
    setAnswer,
    question.sets,
  ]);

  const nextDisabled = useMemo(() => {
    return answer.items[currentSet - 1].selected === null;
  }, [answer.items, currentSet]);

  const navigation = {
    back,
    next,
    nextDisabled,
  };

  const form = {
    currentSet,
    setSetAnswer,
  };

  return (
    <ConjointAnalysisFormContext.Provider value={form}>
      <ConjointAnalysisNavigationContext.Provider value={navigation}>
        <NavigationContainer>
          {props.children}
        </NavigationContainer>
      </ConjointAnalysisNavigationContext.Provider>
    </ConjointAnalysisFormContext.Provider>
  );
};

export default ConjointAnalysisFormContainer;