import { useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as $email from '@services/auth/email';
import { useAsyncStateLazy } from '@utils';
import { LoginFormContext, LoginSubmitContext, SignupFormContext, SignupSubmitContext } from './Context';
import type { Email, FormAction, FormState } from './interfaces';

type Props = {
  children: React.ReactNode;
};

const AuthContainer = (props: Props) => {
  const [signup, setSignupField] = useFormFields(initial.signup);
  const [login, setLoginField] = useFormFields(initial.login);
  const dispatch = useDispatch();

  const handleSubmitLogin = useCallback(() => {

    return $email.login({
      username: login.values.email,
      password: login.values.password,
    })
    .then(() => {
      dispatch(actions.userAuthenticationChange());
    });

  }, [
    dispatch,
    login.values,
  ]);

  const handleSubmitSignup = useCallback((data: { token?: string } = {}) => {

    // gtag('event', 'Submit', {
    //   event_category: 'Contact',
    //   event_label: 'Contact Page Form',
    //   value: 50,
    // });

    return $email.signup({
      email: signup.values.email,
      password: signup.values.password,
      metadata: {
        company: signup.values.company,
        firstName: signup.values.firstName,
        lastName: signup.values.lastName,
        phone: signup.values.phone?.valid ? signup.values.phone.value : undefined,
        token: data.token,
        urlReferrer: document.referrer.slice(0, 499),
      },
    })
    .then(() => {
      dispatch(actions.userAuthenticationChange());
    });

  }, [
    dispatch,
    signup.values,
  ]);

  const [signupResponse, signupSubmit] = useAsyncStateLazy(handleSubmitSignup, [signup.values]);
  const [loginResponse, loginSubmit] = useAsyncStateLazy(handleSubmitLogin, [login.values]);

  return (
    <SignupFormContext.Provider value={[signup, setSignupField]}>
      <LoginFormContext.Provider value={[login, setLoginField]}>
        <SignupSubmitContext.Provider value={[signupResponse, signupSubmit]}>
          <LoginSubmitContext.Provider value={[loginResponse, loginSubmit]}>
            {props.children}
          </LoginSubmitContext.Provider>
        </SignupSubmitContext.Provider>
      </LoginFormContext.Provider>
    </SignupFormContext.Provider>
  );
};

const initial = {
  login: {
    email: '',
    password: '',
  },
  signup: {
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: null as Email.Signup.Form['values']['phone'],
  },
};

export function useFormFields<T extends unknown>(values: T) {
  const errors = Object.keys(values).reduce<FormState<T>['errors']>((acc, key) => ({
    ...acc,
    [key]: undefined,
  }), {} as FormState<T>['errors']);

  const [state, dispatch] = useReducer((acc: FormState<T>, x: FormAction<T>) => {

    switch (x.type) {
      case 'error':
        return {
          ...acc,
          errors: { ...acc.errors, [x.key]: x.error },
        };

      case 'value':
        return {
          ...acc,
          values: { ...acc.values, [x.key]: x.value },
        };

      default:
        return acc;
    }

  }, {
    errors,
    values,
  } as FormState<T>);

  return [state, dispatch] as const;
}

export { AuthContainer };
export default AuthContainer;