import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import type { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import * as consts from '@consts';
import { Breadcrumb } from '@presentation/Breadcrumbs';
import { cx } from '@utils';
import type { Workspace } from '@/types';
import { WorkspacesContextMenuPopper } from '@/components/Workspace/WorkspacesMenu';
import styles from './style/Breadcrumbs.css';

type Props = {
  workspace: Pick<Workspace, 'name'>;
};

export const WorkspaceBreadcrumb = ({ workspace }: Props) => {
  const renderAnchor = useCallback((state: PopupStateProps) => {
    const className = cx({
      [styles.open]: state.isOpen,
    });

    return (
      <Breadcrumb className={className}>
        {workspace.name}
      </Breadcrumb>
    );
  }, [workspace]);

  return (
    <WorkspacesContextMenuPopper
      renderAnchor={renderAnchor} />
  );
};

export const HomeBreadcrumb = () => {
  return (
    <Link to={consts.pathname.Home}>
      <Breadcrumb>
        Home
      </Breadcrumb>
    </Link>
  );
};

export const SharedBreadcrumb = () => {
  const renderAnchor = useCallback((state: PopupStateProps) => {
    const className = cx({
      [styles.open]: state.isOpen,
    });

    return (
      <Breadcrumb className={className}>
        Shared
      </Breadcrumb>
    );
  }, []);

  return (
    <WorkspacesContextMenuPopper
      renderAnchor={renderAnchor} />
  );
};