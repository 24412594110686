import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { querykey } from '@consts';
import * as api from '@api/integrations';
import type * as Integrations from '@api/interfaces/integrations';
import type { Integrations as Is } from '@/types';

type Data = Integrations.Slack.Disconnect.Response;

export const useDisconnectSlack = (
  options?: UseMutationOptions<Data, AxiosResponse, void>
) => {

  const queryClient = useQueryClient();

  return useMutation({ mutationKey: ['disconnect-slack'], mutationFn: () => {
    return api.disconnectSlack();
  }, onSuccess: (res, variables, context) => {

    queryClient.setQueryData<Is.Integrations>([querykey.Integrations.Get], old => ({
      ...old,
      slack: res.slack,
    }));

    options?.onSuccess?.(res, variables, context);
  }, ...options });
};