import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import type { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddOtherAttribute: boolean;
  canRemoveOtherAttribute: boolean;
  otherAttributes: SurveyTemplate.LinkedEntity[];
  onAddOtherAttribute: () => void;
  onRemoveOtherAttribute: (id: string) => () => void;
  onUpdateOtherAttribute: (id: string) => (v: string) => void;
};

export const TemplateOtherAttributesInput = ({
  canAddOtherAttribute,
  canRemoveOtherAttribute,
  onAddOtherAttribute,
  onRemoveOtherAttribute,
  onUpdateOtherAttribute,
  otherAttributes,
}: Props) => {

  const items = useMemo(() => {
    return otherAttributes.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [otherAttributes]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter an attribute"
        removeItem={onRemoveOtherAttribute}
        removeItemEnabled={canRemoveOtherAttribute}
        updateItemValue={onUpdateOtherAttribute} />
      {canAddOtherAttribute &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddOtherAttribute} />}
    </>
  );
};