import type { SurveyBuilder, SurveySettingsBuilder, SurveyQuestionsBuilder } from '../interfaces';
import * as $actions from './state.settings';

export function settingsReducer(state: SurveyBuilder.State, action: SurveySettingsBuilder.Action): SurveyQuestionsBuilder.State {

  const questions = state.survey.questions;

  switch (action.type) {

    case 'toggle-not-applicable':
      return $actions.toggleNotApplicable(questions, action);

    case 'toggle-allow-empty-values':
      return $actions.toggleAllowEmptyValues(questions, action);

    case 'rationale-updated':
      return $actions.rationaleUpdated(questions, action);

    case 'preset-options-updated':
      return $actions.presetOptionsUpdated(questions, action);

    case 'randomize-updated':
      return $actions.randomizeUpdated(questions, action);

    case 'reuse-options-order-updated':
      return $actions.reuseOptionsOrderUpdated(questions, action);

    case 'reuse-rows-order-updated':
      return $actions.reuseRowsOrderUpdated(questions, action);

    default:
      return state.survey.questions;
  }

}