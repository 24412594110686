import { memo } from 'react';
import type { FileIconProps } from './interfaces';

export const PdfIcon = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g
id="Artboard"
stroke="none"
strokeWidth="1"
fill="none"
fillRule="evenodd"
strokeLinecap="round">
        <g
id="Group-3"
transform="translate(4.000000, 2.000000)"
stroke="#E12E40"
strokeLinejoin="round"
strokeWidth="2">
          <g id="Group-8">
            <g id="Group-6">
              <path d="M10,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18 C0,19.1045695 0.8954305,20 2,20 L14,20 C15.1045695,20 16,19.1045695 16,18 L16,6 L10,0 Z" id="Path" />
            </g>
          </g>
        </g>
        <path
d="M8.41262954,12.4026622 C9.23151408,12.4026622 9.64095635,12.0713755 9.64095635,11.408802 C9.52889444,10.7830307 8.98190899,10.5278069 8,10.6431308 L8,13.6328384"
id="Path-7"
stroke="#E12E40"
strokeLinejoin="round" />
        <path
d="M12.9891739,12.1049116 C12.9891739,11.0035584 12.3225072,10.5207286 10.9891739,10.6564223 L10.9891739,13.6242269 C12.3225072,13.7127032 12.9891739,13.2062648 12.9891739,12.1049116 Z"
id="Path-7"
stroke="#E12E40"
strokeLinejoin="round" />
        <line
x1="14.5"
y1="10.6833118"
x2="14.5"
y2="13.6328384"
id="Path-7"
stroke="#E12E40"
strokeLinejoin="round" />
        <line
x1="14.5"
y1="10.616212"
x2="16.1929966"
y2="10.616212"
id="Path-10"
stroke="#E12E40" />
        <line
x1="15"
y1="12.1580751"
x2="15.6694261"
y2="12.1580751"
id="Path-10"
stroke="#E12E40" />
      </g>
    </svg>
  );
});

export default PdfIcon;