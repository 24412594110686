import { useCallback } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { SearchConsultantsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const ConsultantsContainer = (props: Props) => {

  const search = useCallback((data: API.Members.Search.Request) => api.members.search(data), []);

  return (
    <SearchConsultantsContext.Provider value={search}>
      {props.children}
    </SearchConsultantsContext.Provider>
  );
};

ConsultantsContainer.displayName = 'PostCreation.Search.ConsultantsContainer';