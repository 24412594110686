import type { RespondentProps } from '@/types';
import { cx } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import { RespondentBaseballCard } from '@/components/BaseballCard';
import ViewAll from './ViewAll';
import styles from './style/CollapsableResponseItem.css';

export type CustomResponseItemProps = {
  className?: string;
  respondent: RespondentProps;
  renderItem: () => React.ReactNode;
  wrapClassName?: string;
};

export const CustomResponseItem = ({ className, respondent, renderItem, wrapClassName }: CustomResponseItemProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <div className={cx(styles.wrap, wrapClassName)}>
        <div className={styles.textblock}>
          <div className={styles.comment}>
            {renderItem()}
            <div className={styles.author}>
              <RespondentBaseballCard
                classes={{
                  container: styles.cardContainer,
                }}
                {...respondent}>
                <MaybeLink to={respondent.paths.profile}>
                  {respondent.user.name}
                </MaybeLink>
              </RespondentBaseballCard>
            </div>
          </div>

          <ViewAll
            onClick={respondent.onViewResponse}
            to={respondent.paths.response} />
        </div>
      </div>
    </div>
  );
};