import { useCallback } from 'react';
import { isBefore } from 'date-fns';
import { TranscriptCommentingCommentParentContainer } from '@containers/Transcript.Commenting';
import { TranscriptChildCommentContext } from '@containers/Transcript.Commenting/Context';
import { cx } from '@utils';
import type { TranscriptComment } from '@/types/transcribe.rich-text';
import { Comment } from './Comment.Child';
import { CommentFocusContainer } from './Comment.Focus';
import { ReplyToComment } from './Comment.Reply';
import styles from './style/Comment.Parent.css';

type Props = {
  className?: string;
  item: TranscriptComment;
};

export const ParentComment = ({ className, item }: Props) => {

  const renderComments = useCallback(() => {

    const sorted = [...item.children]
      .sort((a, b) => isBefore(a.createdOn, b.createdOn) ? -1 : 1);

    return sorted
      .map((c, i) => {
        const isLast = i === item.children.length - 1;
        const className = cx({
          [styles.borderBottom]: !isLast,
        });
        return (
          <TranscriptChildCommentContext.Provider
            key={c.identifier}
            value={c}>
            <Comment className={className} />
          </TranscriptChildCommentContext.Provider>
        );
      });

  }, [
    item.children,
  ]);

  return (
    <TranscriptCommentingCommentParentContainer item={{ highlight: item.highlight, parentComment: item.parentComment }}>
      <CommentFocusContainer
        className={className}
        identifier={item.parentComment.identifier}>
        {renderComments()}
        <ReplyToComment
          className={styles.borderTop} />
      </CommentFocusContainer>
    </TranscriptCommentingCommentParentContainer>
  );
};