import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Buffer } from 'buffer/';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { TextStateContext, TextURLPreviewContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const URLPreviewContainer = (props: Props) => {
  const [url, setUrl] = useState<string>(null);
  const [{ form }] = useContext(TextStateContext);
  const uploadImage = useUploadPreviewImage();

  const query = useQuery({ queryKey: [QK.Posts.External.Get, url], queryFn: () => {
    return api.posts.fetchURLPreview({
      url,
    });
  }, enabled: !!url, refetchOnWindowFocus: false });

  const image = useMemo(() => {
    if (!url || !query.data?.image) return null;

    const { original: _, ...other } = query.data.image || {} as typeof query.data.image;
    const { url: src, ...rest } = other || {} as Omit<typeof query.data.image, 'original'>;

    return other.url
        ? { ...rest, src }
        : null;
  }, [
    query,
    url,
  ]);

  const uploadPreviewImage = useCallback(async () => {

    if (!image) return null;

    const response = await uploadImage({
      format: image.format,
      height: image.height,
      identifier: form.identifier,
      src: image.src,
      type: image.type,
      width: image.width,
    });

    return response;

  }, [
    form.identifier,
    image,
    uploadImage,
  ]);

  const ctx = {
    clear: () => setUrl(null),
    query,
    setUrl,
    uploadPreviewImage,
    url,
  };

  return (
    <TextURLPreviewContext.Provider value={ctx}>
      {props.children}
    </TextURLPreviewContext.Provider>

  );
};

URLPreviewContainer.displayName = 'Post.Text.URLPreviewContainer';

type Params = {
  format:     string;
  height:     number;
  identifier: string;
  src:        string;
  type:       string;
  width:      number;
};

const useUploadPreviewImage = () => {
  const uploadImage = async (params: Params) => {
    const response = await api.posts.uploads.queue({
      format: params.format,
      postIdentifier: params.identifier,
      type: params.type,
    });

    const { credentials } = await api.posts.uploads.start({
      fileUploadId: params.identifier,
      s3Key: response.data.s3Key,
    });

    const client = new S3Client({
      credentials,
      region: 'us-east-1',
      useAccelerateEndpoint: true,
    });

    const body = Buffer.from(params.src.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    const ul = new Upload({
      client,
      params: {
        Body: body,
        Bucket: response.data.bucket,
        ContentType: params.type,
        Key: response.data.s3Key,
        StorageClass: 'STANDARD_IA',
      },
    });

    await ul.done();

    return {
      format: params.format,
      height: params.height,
      identifier: params.identifier,
      s3Key: response.data.s3Key,
      type: params.type,
      width: params.width,
    };

  };

  return useCallback(uploadImage, []);
};