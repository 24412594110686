import type { RenderElementProps, RenderLeafProps } from 'slate-react';

export const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = (props: RenderLeafProps) => {
  const { attributes, children, leaf } = props;

  let updated = children;

  if (leaf.bold) {
    updated = <strong>{updated}</strong>;
  }

  if (leaf.code) {
    updated = <code>{updated}</code>;
  }

  if (leaf.italic) {
    updated = <em>{updated}</em>;
  }

  if (leaf.underline) {
    updated = <u>{updated}</u>;
  }

  return <span {...attributes}>{updated}</span>;
};