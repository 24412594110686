import type { Ref, HTMLAttributes } from 'react';
import { forwardRef, memo } from 'react';
import { cx } from '@utils';
import styles from './style/ProgressBar.css';

type Props = {
  className?: string;
  pct: number;
} & HTMLAttributes<HTMLDivElement>;

export const ProgressBar = memo(forwardRef(({
  className,
  pct,
  ...rest
}: Props, ref: Ref<HTMLDivElement>) => {
  const fillWidth = Math.min(pct, 100);

  return (
    <div
      {...rest}
      className={cx(styles.root, className)}
      ref={ref}>
      <div
        className={styles.fill}
        style={{ width: `${fillWidth}%` }} />
    </div>
  );
}));

export default ProgressBar;