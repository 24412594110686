import type { NumberInputTableQuestion } from '@/types';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { useNumberInputTableMeasurements } from '@/components/SurveyForm/NumberInputTable.Grid';
import styles from './style/NumberInputTable.Grid.css';

type Props = {
  answer: NumberInputTableQuestion.Answer.Value;
  getCellAnswer: (rowId: number, optionId: number) => string;
  getColumnTotal: (optionId: number) => string;
  getDisplayRowText: (row: NumberInputTableQuestion.Row) => string;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableAnswerGrid = ({
  getCellAnswer,
  getColumnTotal,
  getDisplayRowText,
  question,
}: Props) => {

  const {
    cellWidth,
    maxCellWidth,
    firstCellStyles,
  } = useNumberInputTableMeasurements({
    matrixRows: question.matrixRows,
    numOptions: question.options.length,
    maxCellWidth: 180,
    nameWidthPct: 20,
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div
          className={styles.rowName}
          style={firstCellStyles} />
        {question.options.map(option => (
          <div
            key={option.ordinal}
            style={{
              width: cellWidth,
              maxWidth: maxCellWidth,
            }}
            className={styles.cell}>
            {parseSurveyRichText(option.value)}
          </div>
        ))}
      </div>
      <div>
        {question.matrixRows.map(row => {
          const rowText = getDisplayRowText(row);
          return (
            <div
              key={row.id}
              className={styles.row}>
              <div
                className={styles.rowName}
                style={firstCellStyles}>
                {rowText}
              </div>
              {question.options.map(option => {
                const cellAnswer = getCellAnswer(row.id, option.id);
                return (
                  <div
                    key={option.ordinal}
                    style={{ width: cellWidth }}
                    className={styles.cell}>
                    {cellAnswer}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {question.settings.displayTotals &&
        <div className={styles.footer}>
          <div
            className={styles.rowName}
            style={firstCellStyles}>
            Total
          </div>
          {question.options.map(option => (
            <div
              key={option.ordinal}
              style={{ width: cellWidth }}
              className={styles.cell}>
              {getColumnTotal(option.id)}
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default NumberInputTableAnswerGrid;