import { useMemo } from 'react';
import { AddItemButton } from '@presentation/AddItemButton';
import type { SurveyTemplate } from '@/types/survey.templates';
import { TextEntryList } from '@/components/TextEntryList';
import styles from './style/Presets.css';

type Props = {
  canAddComparator: boolean;
  canRemoveComparator: boolean;
  comparators: SurveyTemplate.LinkedEntity[];
  onAddComparator: () => void;
  onRemoveComparator: (id: string) => () => void;
  onUpdateComparator: (id: string) => (v: string) => void;
};

export const TemplateComparatorsInput = ({
  canAddComparator,
  canRemoveComparator,
  onAddComparator,
  onRemoveComparator,
  onUpdateComparator,
  comparators,
}: Props) => {

  const items = useMemo(() => {
    return comparators.map(m => ({
      key: m.id,
      value: m.value,
    }));
  }, [comparators]);

  return (
    <>
      <TextEntryList
        items={items}
        placeholder="Enter a competitive drug"
        removeItem={onRemoveComparator}
        removeItemEnabled={canRemoveComparator}
        updateItemValue={onUpdateComparator} />
      {canAddComparator &&
        <AddItemButton
          className={styles.add}
          label="Add"
          onClick={onAddComparator} />}
    </>
  );
};