import type { Project, ProjectGroupProps } from '@/types';
import { useProjectUsers } from './useProjectUsers';
import styles from './Project.css';

type Props = {
  project: Project<ProjectGroupProps>;
};

const ProjectQueryStats = (props: Props) => {
  const records = useProjectUsers(props.project.id);

  return (
    <div className={styles.stats}>
      {`${records.length} Selected Experts`}
    </div>
  );
};

export { ProjectQueryStats };
export default ProjectQueryStats;