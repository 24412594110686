import type { GroupType, Role } from '@/enums';
import { transformCalendarEventMetadata } from '@/services/api/transformers';
import type {
  AvailableEventMetadata,
  CalendarISOEvent,
  ScheduledEventMetadata,
} from '@/types';
import { hasClientRole, hasConsultantRole, isInternalUser, trunc } from '@/utils';
import type { FullCalEvent, ExternalEventsMap } from './interfaces';
import styles from './style/Calendar.css';

type UnparsedCalendarViewEvent =
  CalendarISOEvent<
    AvailableEventMetadata
  | ScheduledEventMetadata<string>>;

type TransformExternalEventsParams = {
  events: { [uid: string]: UnparsedCalendarViewEvent[] };
} & IUserId;

type TransformEventParams = {
  group: {
    typeId: GroupType;
  };
  event: UnparsedCalendarViewEvent;
  user: {
    roles: Role[];
  };
};

type ResolveScheduledEventTitle = {
  group: {
    typeId: GroupType;
  };
  metadata: ScheduledEventMetadata;
  user: {
    roles: Role[];
  };
};

export const createId = getIncrementor();

function resolveScheduledEventTitle({ group, metadata, user }: ResolveScheduledEventTitle) {
  if (hasClientRole(user) && isInternalUser(group)) {
    return metadata.respondent.profile.fullname;
  }

  return trunc(metadata.call.title, 30);
}

export const transformEvent = ({ group, event, user }: TransformEventParams): FullCalEvent => {

  if (event.isBusy) {
    const e = event as CalendarISOEvent<ScheduledEventMetadata<string>>;
    const metadata = transformCalendarEventMetadata(e.metadata) as ScheduledEventMetadata;
    return {
      id: createId(),
      startEditable: false,
      durationEditable: false,
      extendedProps: {
        isNew: false,
        type: 'busy',
        // @ts-ignore
        segments: e.segments,
        metadata,
        canRemove: false,
      },
      end: new Date(event.end),
      start: new Date(event.start),
      title: resolveScheduledEventTitle({ group, metadata, user }),
      backgroundColor: 'var(--gray-d)',
      borderColor: 'var(--gray-d)',
      classNames: [styles.busy],
    };
  } else {
    const e = event as CalendarISOEvent<AvailableEventMetadata>;
    return {
      id: createId(),
      startEditable: false,
      durationEditable: false,
      extendedProps: {
        isNew: false,
        type: 'available',
        // @ts-ignore
        segments: e.segments,
        metadata: transformCalendarEventMetadata(e.metadata) as AvailableEventMetadata,
        canRemove: true,
      },
      end: new Date(e.end),
      start: new Date(e.start),
      title: 'Available',
      backgroundColor: '',
      borderColor: '',
      classNames: [styles.available],
    };
  }
};

export const transformExternalEvents = ({ userId, events }: TransformExternalEventsParams): ExternalEventsMap => {
  return Object.keys(events).reduce<ExternalEventsMap>((acc, uid) => {
    const isYou = +uid === userId;
    const items: FullCalEvent[] = events[uid].map(event => ({
      id: createId(),
      extendedProps: {
        isNew: false,
        type: 'external',
        canRemove: false,
      },
      startEditable: false,
      durationEditable: false,
      end: new Date(event.end),
      start: new Date(event.start),
      title: event.summary,
      textColor: 'var(--black)',
      backgroundColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
      borderColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
      classNames: [],
    }));
    return {
      ...acc,
      [uid]: items,
    };
  }, {});
};

function getIncrementor() {
  const state = {
    id: 0,
    inc: () => {
      state.id += 1;

      return state.id;
    },
  };

  return () => state.inc();
}