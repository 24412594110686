import { useCallback } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateSurveyCompletionImageMutation = (options: UpdateSurveyCompletionImage.Options = {}) => {
  const group = useSelectGroup();
  const helpers = useMutationHelpers();

  const mutation = useMutation({ mutationKey: [QKey.Groups.Branding.Survey.Pages.Completion.Image.Post, group.id], mutationFn: (params: UpdateSurveyCompletionImage.Variables) => {
    return api.groups.branding.surveys.updateImage({
      blob: params.blob,
      groupId: group.id,
    });
  }, ...options, onSuccess: (...args) => {
    helpers.onSuccess(...args);
    options.onSuccess?.(...args);
  } });

  return mutation;
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onSuccess = useCallback((res: Branding.Surveys.UpdateImage.Response, variables, context) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        survey: {
          ...data.survey,
          page: {
            ...data.survey.page,
            completion: {
              ...data.survey.page.completion,
              headerImage: res?.headerImage,
            },
          },
        },
      };
    });
  }, [accessor]);

  return {
    onSuccess,
  };
};

declare namespace UpdateSurveyCompletionImage {
  export type Variables = {
    blob: Blob;
  };

  export type Options =
    UseMutationOptions<
      Branding.Surveys.UpdateImage.Response,
      unknown,
      Variables>;
}