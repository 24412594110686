import { useCallback, useContext, useRef, useState } from 'react';
import { useEventListener } from '@utils';
import { KeyboardContext, QueryContext, PopupContext } from './Context';
import type { KeyboardActiveHandler } from './interfaces';

export default function KeyboardListener({ children }: ChildrenProps) {
  const elementRef = useRef<HTMLElement>(window.document.body);
  const [index, setIndex] = useState<number>(mergeIndexInitial());
  const [items, setItems] = useState<number>(mergeItemsInitial());
  const activeHandler = useRef<KeyboardActiveHandler>();
  const { isOpen } = useContext(PopupContext);
  const { clearQuery } = useContext(QueryContext);

  const resetKeyboard = useCallback((items?: number) => {
    setIndex(mergeIndexInitial());
    setItems(mergeItemsInitial(items));
    activeHandler.current = null;
  }, []);

  const setActiveHandler = useCallback((handler: KeyboardActiveHandler) => {
    activeHandler.current = handler;
  }, []);

  const onKeydown = useCallback((e: KeyboardEvent) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'Escape': {
        e.preventDefault();
        clearQuery();
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const fallback = items - 1;
        setIndex(prev => prev > 0 ? prev - 1 : fallback);
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();
        const fallback = items ? 0 : -1;
        setIndex(prev => prev < items - 1 ? prev + 1 : fallback);
        break;
      }
      case 'Enter': {
        if (index === -1) return;

        e.preventDefault();

        if (activeHandler.current) {
          activeHandler.current();
        }
        break;
      }
    }
  }, [isOpen, index, items, clearQuery]);

  useEventListener('keydown', onKeydown, elementRef);

  const value = {
    index,
    items,
    setIndex,
    setItems,
    resetKeyboard,
    setActiveHandler,
  };

  return (
    <KeyboardContext.Provider value={value}>
      {children}
    </KeyboardContext.Provider>
  );
}

function mergeIndexInitial(): number {
  return -1;
}

function mergeItemsInitial(items?: number): number {
  return items || 0;
}

export { KeyboardListener };