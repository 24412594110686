import * as types from '@store/action-types';
import { workspaces as initialState } from '@store/initial-state';
import type {
  ApplicationActions,
} from '@store/interfaces';

type Action =
  ApplicationActions
;

export function workspacesReducer(state = initialState, action: Action) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.workspaces;

    default:
      return state;
  }
}

export default workspacesReducer;