import { getLocationFor, slugify } from '@utils';
import type { Members } from '$admin/api/interfaces';

export const transformProfileProps = (data: Members.SearchName.ResponseUnparsed): Members.SearchName.Response => {
  return {
    items: data.items.map(item => ({
      ...item,
      profile: {
        ...item.profile,
        slug: getLocationFor.user.profile({
          slug: slugify({
            id: item.id,
            name: item.profile.fullname,
          }),
        }).pathname,
      },
    })),
  };
};