import { useCallback, useState } from 'react';
import { useTargetProductProfileData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';
import { useValidateTextInput } from './input';

export const useCanSaveDrug = (target: SurveyTemplate.LinkedEntity) => {

  return useValidateTextInput(target.value, {
    minimum: 2,
  });
};

export const useTemplateDrug = () => {
  const [templateData] = useTargetProductProfileData();
  const [drug, setDrug] = useState(templateData.drug);
  const [_, dispatch] = useSurveyBuilderState();

  const onChange = useCallback((value: SurveyTemplate.LinkedEntity) => {
    setDrug(value);
  }, []);

  const onSubmit = useCallback(() => {

    dispatch({
      type: 'template-drug-updated',
      value: drug,
    });
  }, [
    drug,
    dispatch,
  ]);

  return {
    onChange,
    onSubmit,
    value: drug,
  };
};