import { useCallback } from 'react';
import { useSubmitSurveyDraft, useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types';
import { cx } from '@utils';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { ResolvedTPP } from './TPPTable';
import styles from './style/Modal.TPP.css';
import { TPPTableSelection } from './Input.TPP';
import { useTemplateTPP } from './hooks/tpp';

type Props = {
  templateData: SurveyTemplate.TargetProductProfile;
} & Pick<ModalProps,
    'open' |
    'onClose'>;

export const EditableTemplateTPPModal = ({
  onClose,
  open,
}: Omit<Props, 'templateData'>) => {

  const submitDraft = useSubmitSurveyDraft();
  const [templateData] = useTargetProductProfileData();

  const {
    handleSubmit,
    ...inputProps
  } = useTemplateTPP();

  const handleClose = useCallback(() => {
    if (templateData.tppUrl !== inputProps.tppUrl) {
      handleSubmit();
      submitDraft();
    }
    onClose();
  }, [
    handleSubmit,
    onClose,
    submitDraft,
    inputProps.tppUrl,
    templateData.tppUrl,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}>

      {/* <ModalHeader text="TPP" /> */}

      <div className={styles.body}>
        <TPPTableSelection {...inputProps} />
      </div>

    </Modal>
  );
};

export const TemplateTPPModal = ({
  onClose,
  open,
  templateData,
}: Props) => {

  const bodyClassName = cx({
    [styles.body]: !templateData.tppUrl,
    [styles.imageBody]: !!templateData.tppUrl,
  });

  return (
    <Modal
      classes={{
        root: cx({
          [styles.imageModalWrap]: !!templateData.tppUrl,
        }),
      }}
      open={open}
      onClose={onClose}>

      {/* <ModalHeader text="TPP" /> */}

      <div className={bodyClassName}>
        <ResolvedTPP data={templateData} />
      </div>

    </Modal>
  );
};

export const useTPPModal = () => useModal(TemplateTPPModal);
export const useEditableTPPModal = () => useModal(EditableTemplateTPPModal);