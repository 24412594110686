import { ProfileEntry } from '@enums';
import type { Bio, Product, VICS } from '@/types';
import { ProfileEducationFormContainer } from './Profile.Education.Form.Container';
import { ProfileEmploymentFormContainer } from './Profile.Employment.Form.Container';
import type { Section } from './interfaces';
import Education from './Profile.Education';
import Employment from './Profile.Employment';
import Industries from './Profile.Industries';
import IndustriesForm from './Profile.Industries.Form';
import Products from './Profile.Products';
import ProductsForm from './Profile.Products.Form';
import SummaryForm from './Profile.Summary.Form';
import Summary from './Profile.Summary';

const MultiEntryMap = {
  [ProfileEntry.Education]: {
    placeholder: 'Add your educational experience.',
    title: 'Education',
    Form: ProfileEducationFormContainer,
    ReadOnly: Education,
  },
  [ProfileEntry.Employment]: {
    placeholder: 'Add your work experience.',
    title: 'Work Experience',
    Form: ProfileEmploymentFormContainer,
    ReadOnly: Employment,
  },
};

export function getMultiEntryTypeValues(type: Section.MultiEntry.ProfileMultiEntry): Section.MultiEntry.EntryMapValue {
  return MultiEntryMap[type];
}

const SingleEntryMap = {
  [ProfileEntry.Bio]: {
    placeholder: 'Add a professional summary.',
    title: 'Summary',
    Form: SummaryForm,
    ReadOnly: Summary,
    hasValues: (data: Bio) => !!data?.summary?.length,
  },
  [ProfileEntry.Industry]: {
    placeholder: 'Add your expertise.',
    title: 'Expertise',
    Form: IndustriesForm,
    ReadOnly: Industries,
    hasValues: (data: VICS[]) => !!data.length,
  },
  [ProfileEntry.Product]: {
    placeholder: 'Add products you know.',
    title: 'Products I Know',
    Form: ProductsForm,
    ReadOnly: Products,
    hasValues: (data: Product[]) => !!data.length,
  },
};

export function getSingleEntryTypeValues(type: Section.SingleEntry.ProfileSingleEntry): Section.SingleEntry.EntryMapValue {
  return SingleEntryMap[type];
}