import { useCallback } from 'react';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import { Prompt } from '@/components/Modal/Prompt';
import { Button } from '@/components/Button';
import { useDeleteFileContext } from '@/containers/Workspace.File/hooks/useDeleteFileContext';
import { useLock, useMounted } from '@/utils';
import styles from './style/Prompt.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const DeleteFilePrompt = ({
  onClose,
  open,
}: Props) => {
  const onConfirm = useDeleteFileContext();
  const isMounted = useMounted();
  const [busy, lock] = useLock();

  const handleSave = useCallback(() => {
    return onConfirm()
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    onClose,
    onConfirm,
  ]);

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button
          className={styles.btn}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <Button
          className={styles.btn}
          color="primary"
          onClick={lock(handleSave)}
          variant="brick">
          Remove
        </Button>
      </ButtonSet>
    );
  }, [
    handleSave,
    lock,
    onClose,
  ]);

  return (
    <Prompt
      body="Are you sure you want to remove this file?"
      footer={Footer()}
      header="Remove File"
      onClose={onClose}
      visible={open} />
  );
};