import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { WorkspaceExpertFilesItem } from '@/types';

type Data = WorkspaceExpertFilesItem[];
type QueryKey = readonly [string, number];
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export const useMemberFiles = (userId: number, options?: Options) => {

  return useQuery({
    queryKey: ['member-files', userId] as const, queryFn: ({ queryKey }) => {

      const userId = queryKey[1];

      return api.workspaces.expert.getExpertFiles({
        userId,
      }).then(res => res.items);

    },
    ...options,
  });
};