import http from '@services/http';
import * as xform from '@transformers';
import type { GroupSignatureRequest } from '@/types';
import type {
  FetchOwnDocumentsResponse,
  GetEmbeddedSignUrlRequest,
  GetEmbeddedSignUrlResponse,
} from './interfaces';

export const fetchOwnDocuments = () => {
  return http.get(`/documents`)
    .then((res: FetchOwnDocumentsResponse) => ({
      documents: {
        group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId'),
      },
    }));
};

export const getEmbeddedSignUrl = (data: GetEmbeddedSignUrlRequest): Promise<GetEmbeddedSignUrlResponse> => {
  return http.get(`/documents/embedded/${data.userId}/groups/${data.groupId}`);
};