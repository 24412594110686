import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@/enums/Survey';
import { useSurveyFormQuestionAnswer } from '../Context';

export const useNumberInputTableRowValue = (rowId: number) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.NumberInputTable>();

  const value = useMemo(() => {
    const rowMap = answer?.rowText || {};
    return rowMap[rowId] || '';
  }, [answer, rowId]);

  const setValue = useCallback((value: string) => {
    const rowText = answer?.rowText || {};
    setAnswer({
      items: (answer?.items || []),
      rowText: {
        ...rowText,
        [rowId]: value,
      },
    });
  }, [rowId, answer, setAnswer]);

  return [value, setValue] as const;
};
