import { forwardRef, useCallback } from 'react';
import cuid from 'cuid';
import type { ReactFrameworkOutput } from '@remirror/react';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { getFindAndReplaceGroupingId, getFindAndReplaceLabel } from '@/containers/Survey/utils/rich-text.find-and-replace';
import { ToolbarButton } from '@/components/Remirror/Toolbar.Button';
import { useFindAndReplaceEditModal } from './Node.FindAndReplace.Modal.Edit';
import { useAddFindAndReplaceNode } from './hooks/useAddFindAndReplaceNode';
import type { SurveyRichTextEditorExtension } from './interfaces';

type Props = {
  className?: string;
  getContext?: () => ReactFrameworkOutput<SurveyRichTextEditorExtension>;
};

export const FindAndReplaceButton = forwardRef<HTMLButtonElement, Props>(({ className, getContext } = {}, ref) => {
  const [toggleModal, Modal] = useFindAndReplaceEditModal();
  const addNode = useAddFindAndReplaceNode();

  const handleClick = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSubmit = useCallback((label: string) => {
    const identifier = cuid();

    const attrs = {
      identifier,
      groupingId: getFindAndReplaceGroupingId(label, label),
      label: getFindAndReplaceLabel(label, 1, 1),
      value: getFindAndReplaceLabel(label, 1, 1),
    };

    if (getContext) {
      const chain = getContext().chain;
      addNode(attrs, chain);
    } else {
      addNode(attrs);
    }

    toggleModal();
  }, [
    addNode,
    toggleModal,
    getContext,
  ]);

  return (
    <>
      <ToolbarButton
        ref={ref}
        className={className}
        onClick={handleClick}>
        <LabelOutlinedIcon />
      </ToolbarButton>
      <Modal onSubmit={handleSubmit} />
    </>
  );
});