/** @jsxImportSource @emotion/react */
import { useCallback, useState, useRef, memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import { CopyChat } from '@/components/icons/CopyChat';
import { useTheme } from '@/brand-insights/components/Theme';
import { Tooltip } from '@/brand-insights/components/presentation';
import { BaseMessageButton } from './Message.Button';

type Props = {
  className?: string;
  onClick: () => unknown;
  color?: string;
};

const size = 16;

export const CopyToClipboard = memo(({
  color,
  className,
  onClick,
}: Props) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();

  const theme = useTheme();

  const handleClick = useCallback(() => {
    setShowCopied(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setShowCopied(false), 2000);
    onClick();
  }, [onClick]);

  return (
    <Tooltip
      title={showCopied ? `Copied to clipboard` : `Copy to clipboard`}
      enterDelay={1000}
      enterNextDelay={500}>
      <Root className={className} onClick={showCopied ? undefined : handleClick}>
        {showCopied
          ? <StyledCheckIcon />
          : <StyledCopyIcon
            size={size}
            css={css({
              color: color ?? theme.palette.badge.citation.color,
            })} />}
      </Root>
    </Tooltip>
  );
});

export const CopyButton = memo(({
  className,
  onClick,
}: Props) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();

  const handleClick = useCallback(() => {
    setShowCopied(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setShowCopied(false), 2000);
    onClick();
  }, [onClick]);

  return (
    <BaseMessageButton className={className} onClick={showCopied ? undefined : handleClick}>
      {showCopied
        ? <StyledCheckIcon />
        : <StyledCopyIcon size={size} />}

      <ButtonCopy>
        Copy
      </ButtonCopy>
    </BaseMessageButton>
  );
});

const Root = styled.div`
  cursor: pointer;
  transition: 0.3s;
`;

const ButtonCopy = styled.div({
  marginLeft: 5,
});

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => `
  color: ${theme.palette.green.main};
  font-size: 18px !important;
  opacity: 0.5;
`);

const StyledCopyIcon = styled(CopyChat)(({ theme }) => `
  font-size: 18px !important;
`);