import type { PlatformMessage, PlatformMessageThread } from '@/types';
import { Thread } from './Thread';
import type { MessageComponentType } from './interfaces';

type Props = {
  className?:    string;
  data:          PlatformMessageThread;
  renderMessage: (item: PlatformMessage) => MessageComponentType;
};

const MessageThread = (props: Props) => {
  return (
    <Thread
      className={props.className}
      item={props.data}>
      {props.data.messages.map(props.renderMessage)}
    </Thread>
  );
};

export { MessageThread };
export default MessageThread;