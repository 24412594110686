import { useCallback, useMemo } from 'react';
import { MinusCircle } from 'react-feather';
import type { NumberFormatValues } from 'react-number-format';
import { useSurveyAdvancedQuotaState } from '@containers/SurveyBuilder.Quota';
import { AddConditionButton } from '@presentation/SurveyBuilder';
import { OptionsDropdown, QuestionDropdown } from '@presentation/SurveyBuilder/Dropdown';
import type { SurveyQuestion, SurveyQuota } from '@/types';
import { useSurveyBuilderState, useParseSurveyRichText } from '@containers/SurveyBuilder';
import { NumberInput } from '@/components/Input';
import styles from './style/Builder.Advanced.css';

type Props = {
  canRemove: boolean;
  className?: string;
  questions: SurveyQuestion[];
  set: SurveyQuota.Advanced.Set;
};

export const AdvancedSurveyQuotaSetBuilder = ({ canRemove, className, questions, set }: Props) => {
  const [_, dispatch] = useSurveyAdvancedQuotaState();
  const [surveyState] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  const question = useMemo(() => {
    return surveyState.survey.questions.find(f => f.base.identifier === set.question?.identifier);
  }, [
    set.question?.identifier,
    surveyState.survey.questions,
  ]);

  const questionItems = useMemo(() => {
    return questions.map(m => ({
      base: m.base,
      ordinal: m.ordinal,
      value: getQuestionText(m.value),
    }));
  }, [questions, getQuestionText]);

  const getOptionItems = useCallback((item: SurveyQuota.Advanced.SetItem) => {
    const optionIdentifier = item.condition.value?.option?.identifier;
    return question.options
      .filter(f => f.base.identifier === optionIdentifier || !set.items.some(s => s.condition.value?.option?.identifier === f.base.identifier));

  }, [set.items, question?.options]);

  const canAddCondition = useMemo(() => {
    if (!question) return false;

    return set.items.length < question.options.length;
  }, [
    question,
    set.items.length,
  ]);

  const canRemoveCondition = useMemo(() => {
    return set.items.length > 1;
  }, [
    set.items.length,
  ]);

  const removeSet = useCallback(() => {
    dispatch({
      setIdentifier: set.identifier,
      type: 'remove-advanced-item',
    });
  }, [dispatch, set.identifier]);

  const addCondition = useCallback(() => {
    dispatch({
      setIdentifier: set.identifier,
      type: 'add-advanced-item-condition',
    });
  }, [dispatch, set.identifier]);

  const updateQuestion = useCallback((identifier: string) => {
    dispatch({
      question: { identifier },
      setIdentifier: set.identifier,
      type: 'update-advanced-item-question',
    });
  }, [dispatch, set.identifier]);

  const updateConditionPct = useCallback((identifier: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.substring(0, e.target.value.length - 1);

    const value = rawValue === '' ? null : +rawValue;

    dispatch({
      identifier,
      setIdentifier: set.identifier,
      type: 'update-advanced-item-condition-pct',
      value,
    });
  }, [dispatch, set.identifier]);

  const handlePctBlur = useCallback((identifier: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.substring(0, e.target.value.length - 1);

    dispatch({
      identifier,
      setIdentifier: set.identifier,
      type: 'update-advanced-item-condition-pct',
      value: +rawValue,
    });
  }, [dispatch, set.identifier]);

  const updateConditionValue = useCallback((identifier: string) => (optionIdentifier: string) => {
    dispatch({
      identifier,
      setIdentifier: set.identifier,
      type: 'update-advanced-item-condition-value',
      value: {
        option: {
          identifier: optionIdentifier,
        },
      },
    });
  }, [dispatch, set.identifier]);

  const removeCondition = useCallback((identifier: string) => () => {
    dispatch({
      identifier,
      setIdentifier: set.identifier,
      type: 'remove-advanced-item-condition',
    });
  }, [dispatch, set.identifier]);

  const dropdownItem = question
    ? {
      base: question.base,
      ordinal: question.ordinal,
      value: getQuestionText(question.value),
    } : null;

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.question}>
          <QuestionDropdown
            items={questionItems}
            onSelect={updateQuestion}
            value={dropdownItem} />
        </div>
        <div className={styles.remove}>
          {canRemove &&
            <MinusCircle
              className={styles.icon}
              onClick={removeSet} />}
        </div>
      </div>
      {
        set.items.map(item => {
          const option = question?.options.find(f => f.base.identifier === item.condition?.value?.option?.identifier);
          return (
            <div
              key={item.identifier}
              className={styles.condition}>
              <div className={styles.pct}>
                <NumberInput
                  decimalScale={0}
                  onBlur={handlePctBlur(item.identifier)}
                  onChange={updateConditionPct(item.identifier)}
                  suffix="%"
                  isAllowed={({ floatValue, value }: NumberFormatValues) => {
                    if (value.length > 1 && value.startsWith('0')) return false;
                    if (value === '') return true;
                    if (floatValue >= 0 && floatValue <= 100) return true;

                    return false;
                  }}
                  value={item.pct} />
              </div>
              <div className={styles.option}>
                <OptionsDropdown
                  items={getOptionItems(item)}
                  onSelect={updateConditionValue(item.identifier)}
                  value={option} />
              </div>
              <div className={styles.remove}>
                {canRemoveCondition &&
                  <MinusCircle
                    className={styles.icon}
                    onClick={removeCondition(item.identifier)} />}
              </div>
            </div>
          );
        })
      }
      {canAddCondition &&
        <AddConditionButton
          className={styles.addCondition}
          onClick={addCondition} />
      }
    </div>
  );
};

export default AdvancedSurveyQuotaSetBuilder;