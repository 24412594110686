import * as enums from '@enums';
import http from '@services/http';
import * as xform from '$admin/utils';
import type { Messages } from './interfaces';

export const fetchMessages = () => {
  return http.get(`/admin/messages`)
   .then((res: Messages.FetchMessages.Response) => ({
     threads: xform.transformMessageThreads(res.threads),
   }));
};

const updateMessageApproval = (data: Messages.UpdateMessageApproval.Request) => {
  return http.put(`/admin/messages/${data.messageId}/approvals`, data)
   .then((res: Messages.UpdateMessageApproval.Response) => ({
     message: xform.transformMessageItem(res.message),
   }));
};

const updateMessageComplianceApproval = (data: Messages.UpdateMessageComplianceApproval.Request) => {
  return updateMessageApproval({
    messageId: data.messageId,
    approvalStatusId: data.approvalStatusId,
    screeningStatusId: data.screeningStatusId,
  });
};

const updateMessageScreeningApproval = (data: Messages.UpdateMessageScreeningApproval.Request) => {
  return updateMessageApproval({
    messageId: data.messageId,
    screeningStatusId: data.screeningStatusId,
  });
};

export const setMessageScreeningApproved = (data: Messages.Approvals.Request) => {
  return updateMessageScreeningApproval({
    messageId: data.messageId,
    screeningStatusId: enums.ApprovalStatus.Approved,
  });
};

export const setMessageScreeningFlagged = (data: Messages.Approvals.Request) => {
  return updateMessageScreeningApproval({
    messageId: data.messageId,
    screeningStatusId: enums.ApprovalStatus.Rejected,
  });
};

export const setMessageRejected = (data: Messages.Approvals.Request) => {
  return updateMessageComplianceApproval({
    messageId: data.messageId,
    approvalStatusId: enums.ApprovalStatus.Rejected,
    screeningStatusId: enums.ApprovalStatus.Rejected,
  });
};