import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { TranscribeRT } from '@/types';

type Props = {
  tagIds?: number[];
} & IProjectId;
type Data = {
  items: TranscribeRT.ProjectTaggedMoment[];
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectTaggedConferenceMoments = (props: Props, options?: Options) => {

  return useQuery({
    queryKey: ['get-project-tagged-moments', props], queryFn: ({ queryKey }) => {
      const [, { projectId, tagIds }] = queryKey as QueryKey;
      return api.projects.conference.getProjectTaggedMoments({
        projectId,
        tagIds,
      });
    },
    ...options,
  });
};