import * as pathname from '@consts/pathname';
import { join } from '@utils/path';

const research = join(pathname.RESEARCH);

export const Research = {
  Companies: research('/companies'),
  CompanyDetails: research('/companies/:companyId'),
  LinkedinUpload: research(`/linkedin-upload`),
  MemberDetails: research('/experts/:userId'),
  Members: research('/experts'),
  NewCompany: research('/companies/new'),
  Topics: research(`/topics`),
  TopicCreate: research(`/topics/new`),
  TopicDetails: research(`/topics/:topicId`),
  Root: pathname.RESEARCH,
};