import type { Dispatch } from 'react';
import { createContext } from 'react';
import type { ComplianceReview } from './interfaces';

export const AggregateFilterDispatchContext = createContext<ComplianceReview.AggregateFilterDispatch | undefined>(undefined);
export const AggregateFilterParamsContext = createContext<ComplianceReview.AggregateFilterParams | undefined>(undefined);

export const AggregateReviewStateContext = createContext<ComplianceReview.AggregateState | undefined>(undefined);
export const AggregateReviewRefetchContext = createContext<() => void | undefined>(undefined);

export const CallReviewDispatchContext = createContext<Dispatch<ComplianceReview.CallReviewState> | undefined>(undefined);
export const CallReviewStateContext = createContext<ComplianceReview.CallReviewState | undefined>(undefined);

export const MessageReviewDispatchContext = createContext<ComplianceReview.MessageReviewDispatch | undefined>(undefined);
export const MessageReviewStateContext = createContext<ComplianceReview.MessageReviewState | undefined>(undefined);