import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getAvailableTags } from '@api/conferences';

type QueryOptions = Pick<UseQueryOptions, 'enabled'>;

export const useFetchAvailableConferenceTags = ({ conferenceIdentifier }: IConferenceIdentifier, opts: QueryOptions = { enabled: true }) => {
  const query = useQuery({ queryKey: buildQueryKey(conferenceIdentifier), queryFn: () => {
    return getAvailableTags({ conferenceIdentifier }).then(t => t.tags);
  }, enabled: !!conferenceIdentifier && opts.enabled, refetchOnWindowFocus: false, staleTime: 1000 * 60 * 15 });

  return { query } as const;
};

export function buildQueryKey(conferenceIdentifier: number) {
  return ['conference', conferenceIdentifier, 'available-tags'];
}