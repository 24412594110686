import { Fragment, useCallback, memo } from 'react';
import { ChevronLeft } from 'react-feather';
import type { IChat } from '@/types';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style/Directory.css';

type Props = {
  items: Array<{
    items: IChat.DirectoryItem[];
    project: {
      id: number;
      name: string;
    };
  }>;
  onClick: () => unknown;
  setChannel: (item: IChat.DirectoryItem) => unknown;
};

export const Directory = memo(({ items, onClick, setChannel }: Props) => {
  const handleSetChannel = useCallback((item: IChat.DirectoryItem) => () => {
    setChannel(item);
  }, [setChannel]);

  return (
    <Fragment>
      <div className={styles.header}>
        <div className={styles.contain}>
          <button
            className={styles.btn}
            onClick={onClick}>
            <ChevronLeft />
          </button>
          <div className={styles.title}>Directory</div>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.scroll}>
            <div className={styles.items}>
              {items.map(item => (
                <DirectoryProject
                  key={`${item.project.id}`}
                  item={item}
                  handleSetChannel={handleSetChannel} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

type DirectoryProjectProps = {
  item: {
    items: IChat.DirectoryItem[];
    project: {
      id: number;
      name: string;
    };
  };
  handleSetChannel: (item: IChat.DirectoryItem) => () => unknown;
};

const DirectoryProject = memo(({ item, handleSetChannel }: DirectoryProjectProps) => {
  return (
    <Fragment key={item.project.id}>
      <div className={styles.section}>
        <div className={styles.project}>
          {item.project.name}
        </div>
      </div>
      {item.items.map(user =>
        <DirectoryUser
          key={`${user.participant.contact.id}`}
          item={user}
          handleSetChannel={handleSetChannel} />
      )}
    </Fragment>
  );
});

type DirectoryUserProps = {
  item: IChat.DirectoryItem;
  handleSetChannel: (item: IChat.DirectoryItem) => () => unknown;
};

const DirectoryUser = memo(({ item, handleSetChannel }: DirectoryUserProps) => {
  return (
    <div
      className={styles.item}
      onClick={handleSetChannel(item)}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <UserAvatar
            pictureUrl={item.participant.contact.profile.pictureUrl}
            size={35} />
        </div>
        <div className={styles.name}>{item.participant.contact.profile.fullname}</div>
      </div>
    </div>
  );
});

export default Directory;