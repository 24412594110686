import { useMemo, useRef } from 'react';
import type { Editor } from 'slate';
import { createEditor } from 'slate';
import type { ReactEditor } from 'slate-react';
import { withReact } from 'slate-react';
import type { HistoryEditor } from 'slate-history';
import { withHistory } from 'slate-history';

export function useSlateEditor() {
  const ref = useRef<Editor & ReactEditor & HistoryEditor>();

  if (!ref.current) ref.current = withHistory(withReact(createEditor()));

  return ref.current;
}