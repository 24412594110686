import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as api from '@api/pipeline';
import type { Pipeline } from '@api/interfaces';

type Props = IProjectId;

export const useFetchProjectInvite = ({ projectId }: Props, options?: Options) => {

  return useQuery({ queryKey: ['project.invite', projectId], queryFn: () => {
    return api.getProjectRequest({ projectId });
  }, ...options });

};

type Data = Pipeline.GetProjectRequest.Response;
type Error = AxiosResponse;
type QueryKey = readonly [string, number];
type Options = UseQueryOptions<Data, Error, Data, QueryKey>;