import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import * as api from '@api';
import { Profile } from '@consts/path';
import { AccountProfileContainer } from '@containers/AccountProfile/AccountProfileContainer';
import { MedicalOnboardingBackgroundContainer } from '@containers/MedicalOnboarding/MedicalOnboarding.Background.Container';
import { ProfileMedicalQueryContext, ProfileOverviewQueryContext, ProfileProjectHistoryQueryContext, ProfileProfessionalQueryContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const UserProfileContainer = (props: Props) => {
  const userId = useMatchUserIdFromUrl();

  if (!userId) {
    return (
      <Redirect to={Profile.NotFound} />
    );
  }

  return (
    <ProfileOverviewContainer userId={userId}>
      {props.children}
    </ProfileOverviewContainer>
  );
};

UserProfileContainer.displayName = 'User.Profile.Container';

type Params = {
  slug?: string;
} & Partial<Stringify<IUserId>>;

const useMatchUserIdFromUrl = () => {
  const params = useParams<Params>();

  return useMemo(() => {
    const param = params.userId || params.slug;
    const [userId] = param.split('-').reverse();

    return +userId;
  }, [
    params.slug,
    params.userId,
  ]);
};

type ProfileOverviewContainerProps = {
  children: React.ReactNode;
} & IUserId;

const ProfileOverviewContainer = (props: ProfileOverviewContainerProps) => {
  const query = useQuery({ queryKey: [
    `get:users/profiles/overview`,
    props.userId,
  ], queryFn: () => {
    return api.users.profiles.fetchOverview({
      userId: props.userId,
    });
  } });

  const professional = useRouteMatch({
    exact: true,
    path: Profile.Tabs.Professional,
  });
  const projects = useRouteMatch({
    exact: true,
    path: Profile.Tabs.Projects,
  });
  const medical = useRouteMatch({
    exact: true,
    path: Profile.Tabs.Medical,
  });
  const payerEmployment = useRouteMatch({
    exact: true,
    path: Profile.Tabs.Payer,
  });

  return (
    <ProfileOverviewQueryContext.Provider value={query}>
      <ProfileProfessionalContainer
        enabled={!!professional}
        userId={props.userId}>
        <ProfileProjectsContainer
          enabled={!!projects}
          userId={props.userId}>
          <ProfileMedicalContainer
            enabled={!!medical}
            userId={props.userId}>
            <ProfilePayerContainer
              enabled={!!payerEmployment}
              userId={props.userId}>
              {props.children}
            </ProfilePayerContainer>
          </ProfileMedicalContainer>
        </ProfileProjectsContainer>
      </ProfileProfessionalContainer>
    </ProfileOverviewQueryContext.Provider>
  );
};

type ProfileProfessionalContainerProps = {
  children: React.ReactNode;
  enabled: boolean;
} & IUserId;

const ProfileProfessionalContainer = (props: ProfileProfessionalContainerProps) => {
  const query = useQuery({ queryKey: [
    `get:users/profiles/professional`,
    props.userId,
  ], queryFn: () => {
    return api.users.profiles.fetchProfessionalProfile({
      userId: props.userId,
    });
  }, enabled: props.enabled });

  return (
    <ProfileProfessionalQueryContext.Provider value={query}>
      {props.children}
    </ProfileProfessionalQueryContext.Provider>
  );
};

type ProfileProjectsContainerProps = {
  children: React.ReactNode;
  enabled: boolean;
} & IUserId;

const ProfileProjectsContainer = (props: ProfileProjectsContainerProps) => {
  const [qp] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
  });

  const query = useQuery({ queryKey: [
    `get:users/profiles/projects`,
    props.userId,
    qp.index,
    qp.size,
  ], queryFn: () => {
    return api.users.profiles.fetchProjectHistory({
      userId: props.userId,
      index: qp.index,
      size: qp.size,
    });
  }, enabled: props.enabled, keepPreviousData: true });

  return (
    <ProfileProjectHistoryQueryContext.Provider value={query}>
      {props.children}
    </ProfileProjectHistoryQueryContext.Provider>
  );
};

type ProfileMedicalContainerProps = {
  children: React.ReactNode;
  enabled: boolean;
} & IUserId;

const ProfileMedicalContainer = (props: ProfileMedicalContainerProps) => {
  const query = useQuery({ queryKey: [
    `get:users/profiles/medical`,
    props.userId,
  ], queryFn: () => {
    return api.users.profiles.fetchMedicalProfile({
      userId: props.userId,
    });
  }, enabled: props.enabled });

  return (
    <ProfileMedicalQueryContext.Provider value={query}>
      {props.children}
    </ProfileMedicalQueryContext.Provider>
  );
};

type ProfilePayerContainerProps = {
  children: React.ReactNode;
  enabled: boolean;
} & IUserId;

const ProfilePayerContainer = (props: ProfilePayerContainerProps) => {
  return (
    <AccountProfileContainer userId={props.userId}>
      <MedicalOnboardingBackgroundContainer userId={props.userId}>
        {props.children}
      </MedicalOnboardingBackgroundContainer>
    </AccountProfileContainer>
  );
};