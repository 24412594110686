import type { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { ConnectedAccountProvider, ConnectedAccountType } from '@enums';
import * as actions from '@actions';
import * as api from '@api';
import type * as API from '@api/interfaces';

type Data = API.Users.ConnectAccount.Response;
type Variables = {
  code: string;
};

type Props = {
  provider: ConnectedAccountProvider;
  type: ConnectedAccountType;
};

const selectConnectedAccounts = (state: Store.State) => state.user.connected;

type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export const useConnectUserAccount = ({
  provider,
  type,
}: Props, options?: Options) => {

  const dispatch = useDispatch();
  const connectedAccounts = useSelector(selectConnectedAccounts);

  return useMutation({ mutationKey: ['connect-user-account'], mutationFn: ({ code }: Variables) => {

    return api.users.connectAccount({
      code,
      provider,
      type,
    });

  }, ...options, onSuccess: (res, variables, context) => {
    if (res.success) {
      dispatch(actions.connectedAccountsChanged({
        connected: connectedAccounts.concat(res.connection),
      }));
      options?.onSuccess?.(res, variables, context);
    } else {
      options?.onError?.(null, variables, context);
    }
  } });
};