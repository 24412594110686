import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RouteProps } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';

type Props<T extends string> = {
  formatPath: (tab: T, location: RouteProps['location']) => string;
  routes: T[];
};

type Params = {
  tab: string;
};

export const useTabViewState = <T extends string>({ formatPath, routes }: Props<T>) => {
  const params = useParams<Params>();
  const history = useHistory();

  const resolvedTab = useMemo(() => {
    if (!params.tab) return null;
    return params.tab.split('?')[0] as T;
  }, [params.tab]);

  const tabIndex = useMemo(() => {
    const idx = routes.indexOf(resolvedTab);

    return idx >= 0 && idx < routes.length
      ? idx
      : 0;
  }, [
    resolvedTab,
    routes,
  ]);

  const [index, setIndex] = useState(tabIndex);

  const setTabIndex = useCallback(() => {

    setIndex(tabIndex);
  }, [
    setIndex,
    tabIndex,
  ]);

  const setLocation = useCallback((idx: number) => {
    const key = routes[idx];
    const path = formatPath(key, history.location);

    history.push(path);
  }, [
    history,
    formatPath,
    routes,
  ]);

  useEffect(() => {

    if (resolvedTab) {
      setTabIndex();
    }

  }, [
    resolvedTab,
    setTabIndex,
  ]);

  const state = {
    index,
    routes,
  };

  return [state, setLocation] as const;
};

export default useTabViewState;