import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import type { Contact } from '@/types';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style.css';

type Props = {
  item: Pick<Contact, 'profile'>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const ItemUserPicture = (props: Props) => {
  const profileUrl = getLocationFor.user.profile(props.item.profile);

  return (
    <div className={styles.avatar}>
      <Link
        onClick={props.onClick ? props.onClick : () => {}}
        target="_blank"
        to={profileUrl}>
        <UserAvatar
          className={styles.avatar}
          size={65}
          resize={100}
          pictureUrl={props.item.profile.pictureUrl} />
      </Link>
    </div>
  );
};

export default ItemUserPicture;