import { useRouteMatch } from 'react-router-dom';
import type { ExternalProjectSecretType } from '@enums';
import type { SecretParams } from '.././interfaces';

type Params = {
  secret: string;
  projectId: string;
};

export const useParams = (secretType: ExternalProjectSecretType): SecretParams => {
  const params = useRouteMatch<Params>();

  return {
    ...params.params,
    projectId: +params.params.projectId,
    secretType,
  };
};