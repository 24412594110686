import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { MaxDifferenceQuestion } from '@/types';
import {
  useSurveyQuestionResponsesData, useProjectSurveyResponsesState,
  useMaxDiffSortedOptions,
} from './hooks';
import { MaxDiffResponsesContext } from './Context';

export default function MaxDiffSurveyResponsesContainer({ children }: ChildrenProps) {
  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MaxDifference>();
  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MaxDifference>();

  const onOptionClick = useCallback((optionId: number) => (choice: MaxDifferenceQuestion.Choices) => {
    setQueryState({
      optionId,
      key: choice,
    });
  }, [setQueryState]);

  const onReset = useCallback(() => {
    setQueryState({
      optionId: undefined,
      key: undefined,
    });
  }, [setQueryState]);

  const sortedOptions = useMaxDiffSortedOptions();

  const value = {
    query,
    question,
    onOptionClick,
    onReset,
    responses,
    sortedOptions,
  };

  return (
    <MaxDiffResponsesContext.Provider value={value}>
      {children}
    </MaxDiffResponsesContext.Provider>
  );
}

export { MaxDiffSurveyResponsesContainer };