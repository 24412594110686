import { combineReducers } from '@reduxjs/toolkit';
import { settings as initialState } from '@store/initial-state';
import * as types from '@store/action-types';
import type { ApplicationActions, SettingsAction } from '@store/interfaces';

type Action =
  | ApplicationActions
  | SettingsAction;

function emailReducer(state: Store.Settings.Email = initialState.email, action: Action) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED: {
      return { ...state, ...action.settings.email };
    }

    case types.PREFERENCES_SETTING_CHANGED:
      return { ...state, ...action.email };

    default:
      return state;
  }
}

function smsReducer(state: Store.Settings.SMS = initialState.sms, action: Action) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED: {
      return { ...state, ...action.settings.sms };
    }

    case types.PREFERENCES_SETTING_CHANGED:
      return { ...state, ...action.sms };

    default:
      return state;
  }
}

const settings = combineReducers({
  email: emailReducer,
  sms: smsReducer,
});

export default settings;