import { useContext } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { TranscriptCommentActionsContext, TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import type { Comment } from '@/types/transcribe.rich-text';

type Data = Comment;
type Variables = {
  commentId: number;
  parentCommentId: number;
  value: unknown;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useUpdateComment(options?: Options) {

  const { updateComment } = useContext(TranscriptCommentActionsContext);
  const [_, dispatch] = useContext(TranscriptCommentsStateContext);

  return useMutation({ mutationKey: ['update-transcript-comment'], mutationFn: ({ commentId, value }) => {

    return updateComment({ commentId, value });

  }, ...options, onSuccess: (res, v, ctx) => {

    dispatch({
      type: 'comment-updated',
      comment: res,
      parentCommentId: v.parentCommentId,
    });

    options?.onSuccess?.(res, v, ctx);
  } });
}