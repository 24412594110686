import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { GroupConnectedAppsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const ConnectedAppsContainer = (props: Props) => {
  const group = useSelectGroup();

  const query = useQuery({ queryKey: [QK.Groups.Apps.Connected.Get, group?.id], queryFn: () => {
    return api.groups.apps.connected.fetchConnectedApps({
      groupId: group.id,
    });
  }, enabled: !!group?.id, refetchOnWindowFocus: false });

  const value = {
    query,
  };

  return (
    <GroupConnectedAppsContext.Provider value={value}>
      {props.children}
    </GroupConnectedAppsContext.Provider>
  );
};

ConnectedAppsContainer.displayName = 'ConnectedApps.Container';