import { useEffect } from 'react';
import type { Room } from 'twilio-video';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void;

export default function useHandleTrackPublicationFailed(room: Room, onError: Callback) {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', onError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [room, onError]);
}

export { useHandleTrackPublicationFailed };
