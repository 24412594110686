import { useCallback, useMemo } from 'react';
import type { Cell, Column as RTColumn, Row, UsePaginationInstanceProps, UseTableCellProps, TableOptions } from 'react-table';
import { useFilters, useTable, useFlexLayout, usePagination } from 'react-table';
import type { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import { Table } from '$admin/components/Table';
import * as Column from './Column';
import type { PaginationData, QueueItem } from './interfaces';
import styles from './style/Table.css';

type Props = {
  data:       QueueItem[];
  pagination: PaginationData;
  query:      DecodedValueMap<QPConfig>;
  setQuery:   SetQuery<QPConfig>;
};

export const QueueTable = ({ query, setQuery, ...props }: Props) => {

  const columns = useMemo<RTColumn<QueueItem>[]>(() => [
    {
      Cell: Column.Id.Cell,
      id: `call-id`,
      Header: `Call ID`,
      maxWidth: 60,
      minWidth: 60,
    },
    {
      Cell: Column.Project.Cell,
      id: `project`,
      Header: `Project`,
      maxWidth: 220,
      minWidth: 220,
    },
    {
      Cell: Column.Code.Cell,
      id: `netsuite`,
      Header: `Code`,
      maxWidth: 220,
      minWidth: 220,
    },
    {
      Cell: Column.AddedOn.Cell,
      id: `added-on`,
      Header: `Added On`,
    },
    {
      Cell: Column.Status.Cell,
      id: `status`,
      Header: Column.Status.Header,
      maxWidth: 100,
      minWidth: 100,
    },
  ], []);

  const params: TableOptions<QueueItem> = {
    columns,
    data: props.data,
    initialState: {
      pageIndex: 0,
      pageSize: query.size,
    },
    manualFilters: true,
    manualPagination: true,
    pageCount: props.pagination.pageCount,
  };

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
    setPageSize,
  } = useTable<QueueItem>(
    params,
    useFilters,
    useFlexLayout,
    usePagination,
  );

  const nextPage = useCallback(() => {
    setQuery({ index: query.index + 1 }, 'pushIn');
  }, [
    query.index,
    setQuery,
  ]);

  const previousPage = useCallback(() => {
    const prevIndex = query.index - 1;
    const index = prevIndex > 0
      ? prevIndex
      : undefined;
    setQuery({ index }, 'pushIn');
  }, [
    query.index,
    setQuery,
  ]);

  const canPreviousPage = query.index > 0;
  const canNextPage = query.index + 1 < props.pagination.pageCount;

  const paginationProps: PaginationProps = {
    canNextPage,
    canPreviousPage,
    nextPage,
    pageCount: props.pagination.pageCount,
    pageIndex: query.index,
    pageSize: query.size,
    previousPage,
    pageOptions,
    setPageSize,
  };

  return (
    <div
      {...getTableProps()}
      className={styles.root}>
      <div className={styles.wrap}>
        {headerGroups.map((headerGroup, i) => (
          <Table.Header
            key={i}
            {...headerGroup.getHeaderGroupProps({ className: styles.header })}>
            {headerGroup.headers.map((column, i) =>
              <div
                key={column.id}
                {...column.getHeaderProps()}>
                <div className={styles.th}>
                  {column.render('Header')}
                </div>
              </div>)}
          </Table.Header>))}
      </div>

      <div
        {...getTableBodyProps()}
        className={styles.body}>
        {rows.map((row: Row<QueueItem>) => {

          prepareRow(row);

          return (
            <div
              key={`${row.original.call.id}`}
              {...row.getRowProps()}
              className={styles.row}>
              {row.cells.map((cell: Cell<QueueItem>, i) =>
                <div
                  key={i}
                  {...cell.getCellProps(cellProps)}
                  className={styles.cell}>
                  {cell.render('Cell')}
                </div>)}
            </div>
          );
        })}
      </div>
      <Pagination {...paginationProps} />
    </div>
  );
};

QueueTable.displayName = 'Call.Enhancement.Queue.Table';

type QPConfig = {
  index: QueryParamConfig<number, number>;
  size:  QueryParamConfig<number, number>;
};

type PaginationProps = {
  pageIndex:        number;
  pageSize:         number;
  pageSizeOptions?: number[];
} & Pick<UsePaginationInstanceProps<QueueItem>,
    | 'canNextPage'
    | 'canPreviousPage'
    | 'nextPage'
    | 'previousPage'
    | 'pageCount'
    | 'pageOptions'
    | 'setPageSize'>;

type CellFn = Parameters<UseTableCellProps<QueueItem>['getCellProps']>[number];

const cellProps: CellFn = (props, { cell }) => getStyles(props, 'center');

const getStyles = (props, align = 'flex-start') => [
  props,
  {
    style: {
      justifyContent: 'flex-start',
      alignItems: align,
      display: 'flex',
    },
  },
];