import { memo } from 'react';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

const BlankCalendar = memo(({
  className,
  color = 'var(--gray-l)',
  size = 90,
}: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 68 75">
    <g
id="Client-Homepage"
stroke="none"
strokeWidth="1"
fill="none"
fillRule="evenodd">
      <g id="1" transform="translate(-839.000000, -165.000000)">
        <g id="Group" transform="translate(840.000000, 165.000000)">
          <path
d="M19.8819219,8 C32.2314267,8 40.5575784,8 44.8603769,8 M51.1330997,8 C52.8812998,8 55.5035999,8 59,8 C62.8659932,8 66,11.1340068 66,15 L66,67 C66,70.8659932 62.8659932,74 59,74 L7,74 C3.13400675,74 0,70.8659932 0,67 L0,15 C0,11.1340068 3.13400675,8 7,8 L14.0357504,8"
id="Shape"
stroke={color}
strokeWidth="2" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="12"
cy="33"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="26"
cy="33"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="12"
cy="48"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="26"
cy="48"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="40"
cy="33"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="54"
cy="33"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="40"
cy="48"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="54"
cy="48"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="12"
cy="62"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="26"
cy="62"
r="3" />
          <circle
id="Oval"
stroke={color}
strokeWidth="2"
cx="40"
cy="62"
r="3" />
          <rect
id="Rectangle"
fill={color}
x="16"
y="0"
width="2"
height="11"
rx="1" />
          <rect
id="Rectangle"
fill={color}
x="47"
y="0"
width="2"
height="11"
rx="1" />
          <line
x1="0.338541667"
y1="21.5"
x2="64.6614583"
y2="21.5"
id="Line-3"
stroke={color}
strokeWidth="2"
strokeLinecap="square" />
        </g>
      </g>
    </g>
  </svg>
));

export { BlankCalendar };
export default BlankCalendar;