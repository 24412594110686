import type { SurveyTemplate } from '@/types/survey.templates';
import { SurveyTemplateAutocomplete } from './Input.Autocomplete';

type Props = {
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  value: SurveyTemplate.LinkedEntity;
};

export const TemplateCategoryInput = ({ onChange, value }: Props) => {

  const source = {
    industries: true,
    products: true,
  };

  return (
    <SurveyTemplateAutocomplete
      placeholder="Enter a product or industry category"
      source={source}
      value={value}
      onChange={onChange} />
  );
};