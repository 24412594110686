import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import type { XHR } from '@services/http/interfaces';
import * as $session from '@services/auth/session';

export const useFetchApp = () => {
  const dispatch = useDispatch();

  const fetchApp = () => {
    dispatch(actions.userCheckStarted());
    api.fetchApp()
      .then(data => {
        if (data.session) {
          $session.set(data.session);
        }
        dispatch(actions.appDataFetched(data));
      })
      .catch((e: XHR.Error) => {
        dispatch(actions.userCheckFinished({
          error: e,
        }));
      });
  };

  return useCallback(fetchApp, [
    dispatch,
  ]);
};