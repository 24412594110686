import { SurveyActionType } from '@enums';
import type { Validation } from './interfaces';

export function validateAction(actionType: SurveyActionType): Validation {
  if (!actionType && actionType !== SurveyActionType.None) {
    return {
      message: 'Action required',
      success: false,
    };
  }

  return {
    success: true,
  };
}

export function validateLimit(value: number): Validation {
  if (!value) {
    return {
      message: 'Limit required',
      success: false,
    };
  }

  return {
    success: true,
  };
}

export function validateName(value: string): Validation {
  if (!value || value === '') {
    return {
      message: 'Name required',
      success: false,
    };
  }

  return {
    success: true,
  };
}