import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useProjectSurveyRespondent } from '@containers/GroupProject/hooks';
import { ProjectSurveyResponseStatus } from '@/enums';
import { ButtonActivityIndicator } from '@presentation';
import { UserEmploymentText } from '@presentation/UserEmployment';
import { getLocationFor, cx } from '@utils';
import { CreditRateWithLabel } from '@/components/ExpertRate/Credit';
import { MaybeLink } from '@/components/MaybeLink';
import { UserAvatar } from '@/components/UserAvatar';
import styles from './style/Header.css';

const mapState = (state: Store.State) => ({
  group: state.group,
  me: state.user,
});

export const ProjectSurveyResponseHeader = () => {
  const { projectId, projectSurveyResponseId, refetch, respondent, statusId } = useProjectSurveyRespondent();
  const { group, me } = useSelector(mapState);
  const showRate = group?.features?.showConsultantRates && me.id != respondent.user.id;

  const profileLink = useMemo(() => {
    if (respondent.anonymous || respondent.type !== 'internal') return null;
    const slug = respondent.user.profile.slug;
    return slug && getLocationFor.user.profile({ slug });
  }, [respondent]);

  const mutation = useMutation({
    mutationFn: () => {
      return api.projects.surveys.deleteSurveyResponse({
        projectId,
        surveyResponseId: projectSurveyResponseId,
      })
      .then(() => refetch());
    },
    mutationKey: [
      `delete:projects/survey-responses`,
      projectSurveyResponseId,
    ],
  });

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <MaybeLink to={profileLink} className={styles.avatarWrap}>
              <UserAvatar
                className={styles.avatar}
                pictureUrl={respondent.type === 'internal' ? respondent.user.profile.pictureUrl : null}
                size={95} />
            </MaybeLink>
            <div className={styles.mainHeader}>
              <div className={styles.userLinkContainer}>
                <MaybeLink
                  className={styles.userLink}
                  to={profileLink}>
                  <div className={styles.username}>{respondent.user.name}</div>
                </MaybeLink>
              </div>
              {showRate && respondent.type === 'internal' && (
                <div>
                  <CreditRateWithLabel rate={respondent.user.creditRate} />
                </div>
              )}
            </div>
          </div>
          {statusId === ProjectSurveyResponseStatus.Active &&
            <div>
              <ButtonActivityIndicator
                loading={mutation.isLoading}
                onClick={() => mutation.mutate()}>
                Remove
              </ButtonActivityIndicator>
            </div>}
        </div>

      </div>
      {respondent.type === 'internal' && (
        <div className={styles.section}>
          <div className={styles.employmentSection}>
            <div className={cx(styles.row, styles.workExperienceHeader)}>
              <div className={styles.sectionTitle}>
                Work Experience
              </div>
              {profileLink &&
                <Link className={styles.workExperienceLink} to={profileLink}><span>View Profile {'>'}</span></Link>}
            </div>
            {respondent.user.career.current &&
              <div className={styles.row}>
                <span className={styles.label}>Current:</span>
                <UserEmploymentText
                  company={respondent.user.career.current.company}
                  tenure={respondent.user.career.current.duration}
                  title={respondent.user.career.current.title} />
              </div>}
            {respondent.user.career.former && respondent.user.career.former.title &&
              <div className={styles.row}>
                <span className={styles.label}>Former:</span>
                <UserEmploymentText
                  company={respondent.user.career.former.company}
                  tenure={respondent.user.career.former.duration}
                  title={respondent.user.career.former.title} />
              </div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectSurveyResponseHeader;