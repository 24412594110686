import { useCallback, useState } from 'react';
import { useToggle } from '@utils';
import { useUTMParameters } from '@containers/UTMTracking';
import * as api from '$website/api';
import { LeadFormContainer } from '$website/containers/LeadForm';
import { PricingModalContext, PricingModalPlanContext } from './Context';
import { usePricingContext } from './hooks/usePricingContext';
import type { ColumnIdentifer, PricingFormValues } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const PricingFormContainer = (props: Props) => {
  const ctx = usePricingContext();
  const [open, toggle] = useToggle(false);
  const [plan, setPlan] = useState<Exclude<ColumnIdentifer, ColumnIdentifer.Service>>(null);

  const utm = useUTMParameters();

  const handleSubmit = useCallback((data: PricingFormValues) => {
    return api.visitors.pricing({
      company: data.company,
      email: data.email,
      firstName: data.firstName,
      jobTitle: data.jobTitle,
      lastName: data.lastName,
      payment: ctx.monthly ? 'monthly' : 'annual',
      phone: data.phone,
      plan: ctx.plans[plan].name,
      utm,
    });
  }, [
    plan,
    ctx.monthly,
    ctx.plans,
    utm,
  ]);

  return (
    <LeadFormContainer onSubmit={handleSubmit}>
      <PricingModalPlanContext.Provider value={[plan, setPlan]}>
        <PricingModalContext.Provider value={[open, toggle]}>
          {props.children}
        </PricingModalContext.Provider>
      </PricingModalPlanContext.Provider>
    </LeadFormContainer>
  );
};

PricingFormContainer.displayName = 'Pricing.Form.Container';