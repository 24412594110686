import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums';
import { ConjointAnalysisResponsesContext } from './Context';
import { useConjointAnalysisColors, useConjointAnalysisSortedAttributes, useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from './hooks';

type Props =
  ChildrenProps;

export const ConjointAnalysisSurveyResponsesContainer = (props: Props) => {
  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();
  const { query, setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.ConjointAnalysis>();

  const onAttributeClick = useCallback((attributeId: number) => () => {
    setQueryState({
      attributeId,
      levelId: undefined,
    });
  }, [setQueryState]);

  const onLevelClick = useCallback((levelId: number) => () => {
    setQueryState({
      attributeId: undefined,
      levelId,
    });
  }, [setQueryState]);

  const onReset = useCallback(() => {
    setQueryState({
      attributeId: undefined,
      levelId: undefined,
    });
  }, [setQueryState]);

  const sortedValues = useConjointAnalysisSortedAttributes();

  const levelColors = useConjointAnalysisColors(sortedValues);

  const value = {
    levelColors,
    query,
    question,
    onAttributeClick,
    onLevelClick,
    onReset,
    responses,
    sortedValues,
  };

  return (
    <ConjointAnalysisResponsesContext.Provider value={value}>
      {props.children}
    </ConjointAnalysisResponsesContext.Provider>
  );
};

export default ConjointAnalysisSurveyResponsesContainer;