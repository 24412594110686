import { memo, useCallback, Fragment } from 'react';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';
import { Switch } from '@/components/Switch';
import styles from './style.css';

type Props = {
  editing: boolean;
  label: string;
  name: string;
  displayValue: React.ReactChild;
  onChange?: (value: boolean) => void;
};

export function FieldSwitch({ editing, label, name, displayValue, onChange }: Props) {
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback((active: boolean) => {
    setFieldValue(name, active);
    onChange?.(active);
  }, [name, onChange, setFieldValue]);

  if (!editing) {
    return (
      <Display
        label={label}
        displayValue={displayValue} />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div>
          <div className={styles.label}>
            {label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <Switch
              active={field.value as boolean}
              onClick={handleChange} />
          </div>
        </div>
      )}
    </Field>
  );
}

const Display = memo(({ label, displayValue }: Pick<Props, 'label' | 'displayValue'>) => {
  return (
    <Fragment>
      <div className={styles.label}>{label}</div>
      <span className={styles.switchValue}>
        {displayValue ?? '-'}
      </span>
    </Fragment>
  );
});