import { useCallback, useMemo, useState } from 'react';
import { arr } from '@utils';
import type { ConferenceTagsOverview, ProjectConferenceTag } from '@/types';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useProjectConferenceTagsOverview, useExportProjectConferenceTagSummaries } from '@utils/api';
import { CallRole } from '@/enums';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { ProjectConferenceTagsSelection } from './TagSelection';
import { RoleSelection } from './RoleSelection';
import { TagToSummarizeContext, useTagsToSummarize, RoleFilterContext } from './context';
import styles from './style/ConferenceTagsOverview.css';

type Props =
  {
    canSelectWithoutSummary: boolean;
    onDownload: (tagIds: number[]) => Promise<unknown>;
    excludeRoleFilter?: boolean;
  } & BaseProps;

type BaseProps =
  IProjectId
  & Pick<ModalProps,
    'open' |
    'onClose'>;

function resolveCanSummarize(tag: ProjectConferenceTag, data: ConferenceTagsOverview) {

  if (!tag.includeSummary) return false;

  const taggedMoments = data.taggedMoments.filter(f => f.tagId === tag.base.id);
  const transcriptsTagged = arr.distinct(taggedMoments.map(m => m.transcriptId));
  const summaryAvailable = transcriptsTagged.length >= MinimumTaggedTranscriptsForSummary;
  return summaryAvailable;
}

const ProjectConferenceTagsOverviewModal = ({ canSelectWithoutSummary, projectId, onClose, onDownload, open, excludeRoleFilter }: Props) => {

  const [tagsToSummarize, setTagsToSummarize] = useTagsToSummarize();

  const canDownload = useMemo(() => {
    return tagsToSummarize.length > 0;
  }, [tagsToSummarize.length]);

  const { data, isLoading } = useProjectConferenceTagsOverview({ projectId }, {
    onSuccess: data => {
      if (canSelectWithoutSummary) {
        setTagsToSummarize(data.tags.map(m => m.base.id));
      } else {
        const toSummarize: number[] = [];
        data.tags.filter(f => f.includeSummary).forEach(tag => {
          const canSummarize = resolveCanSummarize(tag, data);
          if (canSummarize) {
            toSummarize.push(tag.base.id);
          }
        });

        setTagsToSummarize(toSummarize);
      }
    },
    refetchOnWindowFocus: false,
  });

  const items = useMemo(() => {
    return data?.tags.map(tag => {
      const taggedMoments = data.taggedMoments.filter(f => f.tagId === tag.base.id);
      const transcriptsTagged = arr.distinct(taggedMoments.map(m => m.transcriptId));
      const canSummarize = transcriptsTagged.length >= MinimumTaggedTranscriptsForSummary && tag.includeSummary;

      return {
        canSelect: canSelectWithoutSummary ? true : canSummarize,
        canSummarize,
        tag,
        transcriptIds: transcriptsTagged,
      };
    });
  }, [canSelectWithoutSummary, data]);

  const downloadSummary = useCallback(() => {
    onDownload(tagsToSummarize).then(onClose);
  }, [onClose, onDownload, tagsToSummarize]);

  return (
    <Modal
      classes={{
        root: styles.modal,
      }}
      hideCloseIcon
      open={open}
      onClose={onClose}>

      {!isLoading && (
        <div>
          <ProjectConferenceTagsSelection
            items={items}
            transcriptIds={data.transcripts} />
          {!excludeRoleFilter && <RoleSelection />}
          <div className={styles.footer}>
            <div className={styles.helpText}>
              Once initiated, report may take up to a couple minutes to generate.
            </div>
            <ButtonActivityIndicator
              className={styles.button}
              disabled={!canDownload}
              loading={false}
              implicitDisable={false}
              onClick={downloadSummary}>
              Download
            </ButtonActivityIndicator>
          </div>
        </div>
      )}
    </Modal>
  );
};

const MinimumTaggedTranscriptsForSummary = 3;

export const ProjectResponseGridModal = (props: BaseProps) => {

  const [tagsToSummarize, setTagsToSummarize] = useState<number[]>([]);
  const [roleFilter, setRoleFilter] = useState<CallRole[]>([CallRole.Respondent, CallRole.PrimaryRespondent]);

  const format = 'xlsx';

  const { download: startDownload } = useExportProjectConferenceTagSummaries({
    format,
    projectId: props.projectId,
    tagIds: tagsToSummarize,
    rolesToInclude: roleFilter,
  });

  const downloadSummary = useCallback(() => {
    return startDownload({
      extension: format,
      name: `Response Grid`,
      title: `Generating Report`,
    });
  }, [
    format,
    startDownload,
  ]);

  return (
    <TagToSummarizeContext.Provider value={[tagsToSummarize, setTagsToSummarize]}>
      <RoleFilterContext.Provider value={[roleFilter, setRoleFilter]}>
        <ProjectConferenceTagsOverviewModal
          {...props}
          canSelectWithoutSummary={true}
          onDownload={downloadSummary} />
      </RoleFilterContext.Provider>
    </TagToSummarizeContext.Provider>
  );
};

export const ProjectConferenceTagSummariesModal = (props: BaseProps) => {

  const [tagsToSummarize, setTagsToSummarize] = useState<number[]>([]);
  const [roleFilter, setRoleFilter] = useState<CallRole[]>([]);

  const format = 'docx';

  const { download: startDownload } = useExportProjectConferenceTagSummaries({
    format,
    projectId: props.projectId,
    tagIds: tagsToSummarize,
    rolesToInclude: [],
  });

  const downloadSummary = useCallback(() => {
    return startDownload({
      extension: format,
      name: `Project Tag Summary`,
      title: `Generating Report`,
    });
  }, [
    format,
    startDownload,
  ]);

  return (
    <TagToSummarizeContext.Provider value={[tagsToSummarize, setTagsToSummarize]}>
      <RoleFilterContext.Provider value={[roleFilter, setRoleFilter]}>
        <ProjectConferenceTagsOverviewModal
          {...props}
          excludeRoleFilter={true}
          canSelectWithoutSummary={false}
          onDownload={downloadSummary} />
      </RoleFilterContext.Provider>
    </TagToSummarizeContext.Provider>
  );
};

export const useProjectResponseGridModal = () => useModal(ProjectResponseGridModal);
export const useProjectConferenceTagSummariesModal = () => useModal(ProjectConferenceTagSummariesModal);