import { useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import type { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import type { SurveyQuestionType } from '@enums';
import { ProjectVersionDataRow, ResponseOption } from '@presentation/ProjectSurveyResponses';
import { roundToPlace } from '@utils';
import type { MaxDifferenceQuestion, SurveyQuestionOption } from '@/types';
import { SimpleAccordion } from '@/components/Accordion';
import { useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export default function MaxDiffRepeatDataAggregate() {
  const { setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.MaxDiff>();
  const { projectIds } = useRepeatSurveyData();
  const { aggregate, historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('optionIds');

  const items = useMemo(() => {
    return Object.keys(aggregate)
      .map(optionId => {
        const option = question.options.find(o => o.base.id === +optionId);
        return {
          data: aggregate[option.base.id],
          option,
        };
      })
      .sort((a, b) => b.data.avg - a.data.avg);
  }, [aggregate, question.options]);

  const versionData = useMemo(() => {
    return items.reduce<VersionData>((agg, { option }) => {
      const versions = projectIds.reduce((agg2, projectId, i) => {
        const score = historical[projectId]?.data?.options[option.ordinal]?.score;
        return score != null
          ? {
            ...agg2,
            [projectId]: {
              version: i + 1,
              score,
            },
          }
          : agg2;
      }, {});

      return {
        ...agg,
        [option.base.id]: versions,
      };
    }, {});
  }, [projectIds, historical, items]);

  const handleVersionClick = useCallback((optionId: number) => (projectId: number) => {
    setQueryState({
      optionIds: [optionId],
      projectId,
    });
  }, [setQueryState]);

  return (
    <div className={styles.root}>
      {items.map(({ data, option }) => {
        return (
          <SimpleAccordion
            key={option.base.id}
            open={isAccordionOpen(option.base.id)}
            toggleOpen={toggleAccordion(option.base.id)}
            grows={false}
            className={styles.row}
            height={50}
            label={<OptionLabel data={data} option={option} />}>
            <Versions
              optionId={option.base.id}
              projectIds={projectIds}
              data={versionData}
              onVersionClick={handleVersionClick(option.base.id)} />
          </SimpleAccordion>
        );
      })}
    </div>
  );
}

function OptionLabel({ data, option }: OptionLabelProps) {
  const color = chart.maxdiff.getOptionColor(option.ordinal);
  const subtitle = `(Score: ${roundToPlace(data.avg, 2)} Avg.)`;
  const label = parseSurveyRichText(option.value);

  return (
    <ResponseOption
      color={color}
      label={label}
      subtitle={subtitle} />
  );
}

function Versions({ optionId, projectIds, data, onVersionClick }: VersionsProps) {
  const handleVersionClick =
    useCallback((projectId: number) => () => onVersionClick(projectId), [onVersionClick]);

  const optionData = data[optionId];
  if (!optionData) return null;

  return (
    <>
      {projectIds.map(projectId => {
        const projectData = optionData[projectId];
        if (!projectData) return null;

        return (
          <ProjectVersionDataRow
            key={`${optionId}:${projectId}`}
            label={`(Score: ${projectData.score} Avg.)`}
            version={projectData.version}
            onClick={handleVersionClick(projectId)} />
        );
      })}
    </>
  );
}

export { MaxDiffRepeatDataAggregate };

type OptionLabelProps = {
  data: MaxDifferenceQuestion.RepeatData.Data;
  option: SurveyQuestionOption;
};

type VersionsProps = {
  data: VersionData;
  projectIds: number[];
  optionId: number;
  onVersionClick: (projectId: number) => unknown;
};

type VersionData = {
  [optionId: number]: {
    [projectId: number]: {
      version: number;
      score: number;
    };
  };
};