import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import type { Location, LocationState } from 'history';
import { usePreviousDistinct } from '@utils/hooks/usePreviousDistinct';

export type LocationComparator<T = LocationState, R = unknown> = (previous: Location<T>, current: Location<T>) => R;

const useLocationComparator = <T = LocationState>() => {
  const current = useLocation<T>();
  const previous = usePreviousDistinct(current);

  const onChange = useCallback(<R>(fn: LocationComparator<T, R>) => {

    if (previous) {
      return fn(previous, current);
    }

  }, [
    current,
    previous,
  ]);

  return onChange;
};

export { useLocationComparator };
export default useLocationComparator;