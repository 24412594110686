import { Discovery, Website } from '@consts/path';
import { Blog, FAQ } from '@consts/urls';
import type { Menu, MenuItem } from './interfaces';

const product = {
  name: `Product`,
  items: [{
    name: `Customer Research`,
    to: Website.CustomerResearch,
  }, {
    name: `Quant2Qual`,
    to: Website.Quant2Qual,
  }, {
    name: `Collaboration Tools`,
    to: Website.CollaborationTools,
  }, {
    name: `Compliance`,
    to: Website.Compliance,
  }],
};

const learn = {
  name: `Learn`,
  items: [{
    name: `Discovery`,
    to: Discovery.Root,
  }, {
    name: `Blog`,
    href: Blog,
  }],
};

const about = {
  name: `About Us`,
  items: [{
    name: `Our Story`,
    to: Website.About,
  }, {
    name: `FAQs`,
    href: FAQ,
  }, {
    name: `Careers`,
    to: Website.Careers,
  }, {
    name: `Contact Us`,
    to: Website.Contact,
  }],
};

const pricing = {
  name: `Pricing`,
  to: Website.Pricing,
};

export const menus: Menu[] = [
  product,
  learn,
  about,
];

export const links: MenuItem[] = [
  pricing,
];