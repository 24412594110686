import type { Ref } from 'react';
import { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { useProjectSurveyRespondent, useProjectState } from '@containers/GroupProject/hooks';
import { VersionTag } from '@presentation/VersionTag';
import { getLocationFor } from '@utils';
import { selectProjectsForParent } from '@store/selectors';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/Version.css';

type MenuProps = {
  onClose: () => unknown;
};

const RespondentProjectVersionContextMenu = forwardRef(({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const { versions } = useProjectSurveyRespondent();
  const { project } = useProjectState();
  const children = useSelector(selectProjectsForParent(project.parentId));

  const items = useMemo(() => {
    return versions.map(m => {
      const index = children.findIndex(f => f.id === m.projectId);
      const project = children.find(f => f.id === m.projectId);
      const to = getLocationFor.project.surveyResponse({
        projectSurveyResponseId: m.projectSurveyResponseId,
        slug: project.slug,
      });

      return {
        children: (
          <>
            Version {index + 1} <span className={styles.date}>{format(project.createdOn, 'M/d/yyyy')}</span>
          </>
        ),
        to,
      };
    });
  }, [
    children,
    versions,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <Link
          key={i}
          replace
          to={item.to}>
          <PopperMenuItem className={styles.item} onClick={onClose}>
            {item.children}
          </PopperMenuItem>
        </Link>
      ))}
    </PopperMenu>
  );
});

const ProjectVersionTag = forwardRef((_, ref: Ref<HTMLDivElement>) => {

  const { project } = useProjectState();
  const children = useSelector(selectProjectsForParent(project.parentId));

  const text = useMemo(() => {
    const index = children.findIndex(f => f.id === project.id);
    return `V${index + 1}`;
  }, [
    children,
    project,
  ]);

  return (
    <VersionTag ref={ref} className={styles.versionTag}>
      {text}
      <div className={styles.chevron} />
    </VersionTag>
  );
});

type Props = {
  className?: string;
};

export const RespondentProjectVersion = ({ className }: Props) => {

  return (
    <PopupState variant="popper" popupId="project-version-popper">
      {popupState => (
        <div className={className}>
          <div {...bindToggle(popupState)}>
            <ProjectVersionTag />
          </div>
          <Popper
            className={styles.popper}
            {...bindPopper(popupState)}
            placement="bottom-end">
            <ClickAwayListener
              onClickAway={popupState.close}>
              <RespondentProjectVersionContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

export default RespondentProjectVersion;