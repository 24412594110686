import { useCallback } from 'react';
import type { SurveyTemplateQuestion } from '@enums/survey.template';
import { MessageTesting, SurveyTemplateEntityType } from '@enums/survey.template';
import { useMessageTestingData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';
import type { SurveyBuilderVersion } from '@/types';
import type { SurveyBuilder } from '@/containers/SurveyBuilder/interfaces';
import * as $templateUtils from '@/containers/SurveyBuilder/state/state.template.utils';
import * as $template from '@/containers/SurveyBuilder/template/utils';
import { useCanSaveList, useListElements } from './list-elements';

const minimum = 1;
const maximum = 15;

export const useCanSaveAttitudinals = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minimum,
    maxItems: maximum,
  });
};

export const useTemplateAttitudinals = () => {
  const [templateData] = useMessageTestingData();
  const submit = useSubmitTemplateAttitudinals();

  const {
    canAdd,
    canRemove,
    items: values,
    onAddItem,
    onRemoveItem,
    onUpdateItem,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.attitudinals,
  });

  const onSubmit = useCallback(() => {
    submit(values);
  }, [submit, values]);

  return {
    values,
    canAdd,
    canRemove,
    onAddItem,
    onRemoveItem,
    onUpdateItem,
    onSubmit,
  };
};

const useSubmitTemplateAttitudinals = () => {
  const [state, dispatch] = useSurveyBuilderState();

  return useCallback((values: SurveyTemplate.LinkedEntity[]) => {

    const actions = computeActions({
      saved: state.survey,
      values,
    });
    // console.log('actions', actions);

    dispatch({
      actions,
      type: 'batch-actions',
    });
  }, [
    dispatch,
    state.survey,
  ]);
};

type ComputeActions = {
  saved: SurveyBuilderVersion;
  values: SurveyTemplate.LinkedEntity[];
};

function computeActions({ saved, values }: ComputeActions): SurveyBuilder.NonBatchActions[] {

  const oldValues = (saved.template.data as SurveyTemplate.MessageTesting.Data).attitudinals;

  const {
    added,
    removed,
    updated,
  } = $templateUtils.computeChangedValues({
    oldValues,
    values,
  });

  const rowQuestions: SurveyTemplateQuestion[] = [
    MessageTesting.Question.Attitudinals,
  ];

  const removedRows = $templateUtils.computeRemovedRows({
    key: SurveyTemplateEntityType.Attitudinal,
    questions: saved.questions,
    removedItems: removed,
  });

  const addedRows = $templateUtils.computeAddedRows({
    generateRow: $template.generateAttitudinalRow,
    addedItems: added,
    questions: saved.questions,
    toCheck: rowQuestions,
  });

  const updatedRows = $templateUtils.computeUpdatedRows({
    questions: saved.questions,
    toCheck: rowQuestions,
    updatedItems: updated,
  });

  const actions: SurveyBuilder.NonBatchActions[] = [];

  removedRows.forEach(f => {
    actions.push({
      type: 'remove-question-row',
      ...f,
    });
  });
  addedRows.forEach(f => {
    actions.push({
      type: 'add-question-rows',
      ...f,
    });
  });
  updatedRows.forEach(f => {
    actions.push({
      type: 'update-question-row-value',
      ...f,
    });
  });

  actions.push({
    type: 'template-attitudinals-updated',
    payload: { value: values },
  });

  return actions;
}