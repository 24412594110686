import * as enums from '@enums';
import type {
  Project,
} from '@/types';

export function includeFrequency(project: Project, features: Store.Group.Features) {
  const types = [
    enums.ProjectType.Survey,
    enums.ProjectType.SurveyCall,
  ];

  return features.repeatSurveys &&
    types.includes(project.projectType);
}