import type { LinkProps } from 'react-router-dom';
import { cx } from '@utils';
import { MaybeLink } from '@/components/MaybeLink';
import styles from './style/Cell.Link.css';

type Props = {
  children: React.ReactNode;
  classes?: {
    root?: string;
    link?: string;
  };
  to: LinkProps['to'] | undefined;
};

export const Link = ({ classes = {}, ...props }: Props) => {

  return (
    <div className={cx(styles.root, classes.root)}>
      <MaybeLink to={props.to}>
        <div className={cx({ [styles.link]: !!props.to }, styles.trunc, classes.link)}>
          {props.children}
        </div>
      </MaybeLink>
    </div>
  );
};

Link.displayName = 'Cell.Link';