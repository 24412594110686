import { useState, useRef } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import type { State as PopperState } from '@popperjs/core';
import { cx } from '@utils';
import styles from './style/SurveyResponseFilter.css';

type Props<T> = {
  items: T[];
  displayValue: (v: T) => string;
  onSelect: (v: T) => void;
  value: T;
  buttonClass?: string;
};

export const Selector = <T, >(props: Props<T>) => {
  const [isOpen, setOpen] = useState(false);
  const btnRef = useRef();

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <div
            className={cx(styles.selectorButton, props.buttonClass)}
            ref={btnRef}
            onClick={() => setOpen(!isOpen)}>
            {props.displayValue(props.value)}
          </div>
          <Popper
            className={styles.selectorPopover}
            open={isOpen}
            anchorEl={btnRef.current}
            placement={'bottom-start'}
            popperOptions={{
              modifiers: [
                {
                  name: 'update-width',
                  phase: 'afterWrite',
                  enabled: true,
                  fn({ state }: { state: PopperState }) {
                    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
                  },
                },
              ],
              onFirstUpdate: state => {
                state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
              },
            }}>
            <div>
              {props.items.map((i, k) => (
                <div
                  key={k}
                  className={styles.selectorPopoverRow}
                  onClick={() => { props.onSelect(i); setOpen(false); }}>
                  {props.displayValue(i)}
                </div>
              ))}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};