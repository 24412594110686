import type { ReactEditor } from 'slate-react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Tooltip } from '@presentation/Tooltip';
import { Editor } from '@/components/Conference.Panel/Pane.Notes';
import { useConferenceNotes } from './hooks';
import styles from './style/Notes.css';

type Props = {
  editor: ReactEditor;
  callId: number;
};

export function Notes({ editor, callId }: Props) {
  const { notes, update } = useConferenceNotes(callId);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <div className={styles.label}>Notes</div>
        <Tooltip
          title='Call notes are private'
          placement='left'
          tooltipstyles={{ padding: '5px' }}>
          <div className={styles.private}>
            <VisibilityOffIcon />
          </div>
        </Tooltip>
      </div>
      <Editor
        editor={editor}
        value={notes}
        onChange={value => update(value)} />
    </div>
  );
}