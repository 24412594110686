import type { CSSProperties } from 'react';
import { forwardRef } from 'react';
import type { PopperPlacementType } from '@mui/material/Popper';
import { cx } from '@utils';
import type { Topics } from '@/types';
import styles from './style/Topics.Input.List.css';

type Props = {
  items:     Topics.Descriptor[];
  onSelect:  (option: Topics.Descriptor) => () => unknown;
  placement: PopperPlacementType;
  style?:    CSSProperties;
};

const TopicInputList = (props: Props, ref: React.Ref<HTMLDivElement>) => {
  const className = cx(styles.root, {
    [styles.openTop]: props.placement.startsWith('top'),
    [styles.openBottom]: props.placement.startsWith('bottom'),
  });

  return (
    <div
      className={className}
      ref={ref}
      style={props.style}>
      {props.items.map(x =>
        <div
          className={styles.option}
          key={x.id}
          onMouseDown={props.onSelect(x)}>
          <div>{x.name}</div>
        </div>)}
    </div>
  );
};

TopicInputList.displayName = 'PostCreation.Topic.Input.List';

const TopicInputListWithRef = forwardRef<HTMLDivElement, Props>(TopicInputList);

export {
  TopicInputListWithRef as TopicInputList,
};