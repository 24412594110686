import { useCallback, useMemo } from 'react';
import { useSelectPayoutSettings } from '@containers/Store';
import { PayoutSetupContext } from './Context';
import type { PayoutSetupContextValue } from './interfaces';

type Props
  = ChildrenProps;

export default function PayoutSetupContainer(props: Props) {
  const settings = useSelectPayoutSettings();

  const handleRefetch = useCallback(() => {
    return Promise.resolve();
  }, []);

  const value: PayoutSetupContextValue = useMemo(() => ({
    tipaltiPayeeId: settings.tipaltiPayeeId,
    payeeStatus: settings.payeeStatus,
    isPayable: settings.isPayable,
    refetch: handleRefetch,
  }), [
    settings.tipaltiPayeeId,
    settings.payeeStatus,
    settings.isPayable,
    handleRefetch,
  ]);

  return (
    <PayoutSetupContext.Provider value={value}>
      {props.children}
    </PayoutSetupContext.Provider>
  );
}

export { PayoutSetupContainer };