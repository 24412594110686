import type { AxiosResponse } from 'axios';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import * as api from '@api/calls';
import type * as Calls from '@api/interfaces/calls';

type Data = unknown;
type Variable = Omit<Calls.SubmitCallRatingRequest, 'callId'>;

export function useSubmitCallRating(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variable>
) {

  return useMutation({ mutationKey: keySubmitCallRating(data.callId), mutationFn: params => {

    return api.submitCallRating({
      callId: data.callId,
      isSkipped: params.isSkipped,
      rating: params.rating,
      notes: params.notes,
      tags: params.tags,
    });

  }, ...options });
}

export function keySubmitCallRating(callId: number) {
  return ['submit-call-rating', callId];
}