import { path } from '@consts';
import { Skip } from '@presentation';
import { cx } from '@utils';
import type { ForwardNavigationProps } from '@screens/AccountProfileSetup/interfaces';
import { Button } from '@/components/Button';
import styles from './style/AccountProfileEnrichmentCheck.css';

type Props = {
  navigation: ForwardNavigationProps;
};

const SetupPrompt = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>{title}</div>
          <div className={styles.body}>
            <div className={styles.text}>{msg1}</div>
            <div className={styles.text}>{msg2}</div>
          </div>
          <div className={cx(styles.footer, styles.column)}>
            <div className={styles.proceed}>
              <Button
                className={styles.btn}
                onClick={props.navigation.onClick}
                replace={props.navigation.replace}
                to={props.navigation.to}
                variant="brick">
                Create Profile
              </Button>
            </div>
            <div className={styles.proceed}>
              <Skip
                className={styles.skip}
                to={path.Projects.Root} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const title = `Want to participate in more paid surveys? Set up a profile to get started.`;

const msg1 = `By creating a profile, you will become eligible for more paid projects on Sentiment! Please take a moment to fill out your work experiences, education, and a professional bio.`;
const msg2 = `Once your profile is complete, we’ll begin recommending you for paid projects on the topics you are best fit to discuss.`;

export { SetupPrompt };
export default SetupPrompt;