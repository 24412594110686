import type {
  SurveyTemplateQuestion } from '@enums/survey.template';
import {
  SurveyTemplateEntityType,
  TargetProductProfileQuestion,
} from '@enums/survey.template';
import type { SurveyTemplate, SurveyQuestion, SurveyQuestionMatrixRow, MatrixGridQuestion } from '@/types/survey';
import type {
  SurveyBuilder,
  SurveyTemplateBuilder,
} from '../interfaces';
import * as $template from '../template/utils';
import * as $templateUtils from './state.template.utils';
import * as $utils from './state.questions.template.utils';

export function qualityOfLifeUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.QualityOfLifeUpdated.State): SurveyQuestion[] {
  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    qualityOfLife: action.value,
  });
}

export function drugIndicationUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.DrugIndicationUpdated.State): SurveyQuestion[] {
  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    drugIndication: action.value,
  });
}

export function trialDesignUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.TrialDesignUpdated.State): SurveyQuestion[] {
  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    trialDesign: action.value,
  });
}

const lookupExistingRow = (question: MatrixGridQuestion.Question) => ({ id, type }: LookupExistingRow) => {
  const row = question.matrixRows.find(f => {

    if (type) {
      return f.metadata.template.linkedEntity?.type === type &&
        f.metadata.template.linkedEntity?.id === id;
    } else {
      return !f.metadata.template.linkedEntity?.type;
    }
  });

  return row;
};

type LookupExistingRow = {
  id?: string;
  type: SurveyTemplateEntityType;
};

function recomputeAttributeRows(state: SurveyBuilder.State, data: SurveyTemplate.TargetProductProfile): SurveyQuestion[] {

  return state.survey.questions.reduce<SurveyQuestion[]>((acc, q) => {
    if (q.metadata.template.key === TargetProductProfileQuestion.RateAttributes) {
      const lookupRow = lookupExistingRow(q as MatrixGridQuestion.Question);

      const matrixRows: SurveyQuestionMatrixRow[] = [];

      const drugIndicationRow = lookupRow({
        type: SurveyTemplateEntityType.DrugIndication,
      });

      matrixRows.push($template.generateDrugIndicationRow({
        id: drugIndicationRow?.id,
        base: drugIndicationRow?.base,
        item: {
          id: '',
          value: data.drugIndication,
        },
        ordinal: matrixRows.length + 1,
      }));

      const trialDesignRow = lookupRow({
        type: SurveyTemplateEntityType.TrialDesign,
      });

      matrixRows.push($template.generateTrialDesignRow({
        id: trialDesignRow?.id,
        base: trialDesignRow?.base,
        item: {
          id: '',
          value: data.trialDesign,
        },
        ordinal: matrixRows.length + 1,
      }));

      const primaryEndpoint = data.efficacyEndpoints.primary[0];
      const coPrimaryEndpoint = data.efficacyEndpoints.primary[1];

      if (primaryEndpoint) {
        const eeRow = lookupRow({
          type: SurveyTemplateEntityType.PrimaryEfficacyEndpoint,
        });
        matrixRows.push($template.generatePrimaryEfficacyEndpointRow({
          id: eeRow?.id,
          base: eeRow?.base,
          item: {
            id: '',
            value: primaryEndpoint.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      }

      if (coPrimaryEndpoint) {
        const eeRow = lookupRow({
          type: SurveyTemplateEntityType.CoPrimaryEfficacyEndpoint,
        });
        matrixRows.push($template.generateCoPrimaryEfficacyEndpointRow({
          id: eeRow?.id,
          base: eeRow?.base,
          item: {
            id: '',
            value: coPrimaryEndpoint.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      }

      data.efficacyEndpoints.secondary.forEach(e => {
        const eeRow = lookupRow({
          type: SurveyTemplateEntityType.SecondaryEfficacyEndpoint,
        });
        matrixRows.push($template.generateSecondaryEfficacyEndpointRow({
          id: eeRow?.id,
          base: eeRow?.base,
          item: {
            id: e.id,
            value: e.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      });

      data.safetyEndpoints.forEach(e => {
        const seRow = lookupRow({
          type: SurveyTemplateEntityType.SafetyEndpoint,
        });
        matrixRows.push($template.generateSafetyEndpointRow({
          id: seRow?.id,
          base: seRow?.base,
          item: {
            id: e.id,
            value: e.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      });

      if (data.qualityOfLife) {
        const qolRow = lookupRow({
          type: SurveyTemplateEntityType.QualityOfLife,
        });

        matrixRows.push($template.generateQualityOfLifeRow({
          id: qolRow?.id,
          base: qolRow?.base,
          item: {
            id: '',
            value: data.qualityOfLife,
          },
          ordinal: matrixRows.length + 1,
        }));
      }

      data.otherAttributes.forEach(e => {
        const oaRow = lookupRow({
          type: SurveyTemplateEntityType.OtherAttribute,
        });
        matrixRows.push($template.generateOtherAttributeRow({
          id: oaRow?.id,
          base: oaRow?.base,
          item: {
            id: e.id,
            value: e.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      });

      data.doseAdministration.forEach(e => {
        const daRow = lookupRow({
          type: SurveyTemplateEntityType.DoseAdministration,
        });
        matrixRows.push($template.generateDoseAdministrationRow({
          id: daRow?.id,
          base: daRow?.base,
          item: {
            id: e.id,
            value: e.value,
          },
          ordinal: matrixRows.length + 1,
        }));
      });

      const overallRow = lookupRow({
        type: null,
      });

      matrixRows.push($template.generateRowRichText({
        id: overallRow?.id,
        base: overallRow?.base,
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal: matrixRows.length + 1,
        value: $template.generateRichTextValue([$template.generateBoldTextNode(`Overall added value`)]),
      }));

      return acc.concat({
        ...q,
        matrixRows,
      } as MatrixGridQuestion.Question);
    }

    return acc.concat(q);
  }, []);

}

export function safetyEndpointsUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.SafetyEndpointsUpdated.State): SurveyQuestion[] {

  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    safetyEndpoints: action.value,
  });

}

export function efficacyEndpointsUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.EfficacyEndpointsUpdated.State): SurveyQuestion[] {

  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    efficacyEndpoints: action.value,
  });
}

export function otherAttributesUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.OtherAttributesUpdated.State): SurveyQuestion[] {

  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    otherAttributes: action.value,
  });
}

export function doseAdministrationUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.DoseAdministrationUpdated.Action): SurveyQuestion[] {

  return recomputeAttributeRows(state, {
    ...(state.survey.template.data as SurveyTemplate.TargetProductProfile),
    doseAdministration: action.payload.value,
  });
}

export function pricingComparatorsUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.PricingComparatorsUpdated.State): SurveyQuestion[] {
  const oldValues = (state.survey.template.data as SurveyTemplate.TargetProductProfile).pricingComparators;

  const {
    added: addedItems,
    removed: removedItems,
    updated: updatedItems,
  } = $templateUtils.computeChangedValues({
    oldValues,
    values: action.value,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    TargetProductProfileQuestion.PricingComparators,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.PricingComparator,
    questions: state.survey.questions,
    removedItems,
  });
  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generatePricingComparatorOption,
    addedItems,
    questions: state.survey.questions,
    toCheck: optionQuestions,
  });
  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: state.survey.questions,
    toCheck: optionQuestions,
    updatedItems,
  });

  return $utils.applyChangesToSurvey({
    addedOptions,
    survey: state.survey,
    removedOptions,
    updatedOptions,
  });
}

export function treatmentComparatorsUpdated(state: SurveyBuilder.State, action: SurveyTemplateBuilder.TreatmentComparatorsUpdated.State): SurveyQuestion[] {
  const oldValues = (state.survey.template.data as SurveyTemplate.TargetProductProfile).treatmentComparators;

  const {
    added: addedItems,
    removed: removedItems,
    updated: updatedItems,
  } = $templateUtils.computeChangedValues({
    oldValues,
    values: action.value,
  });

  const optionQuestions: SurveyTemplateQuestion[] = [
    TargetProductProfileQuestion.ClinicalComparators,
  ];

  const removedOptions = $templateUtils.computeRemovedOptions({
    key: SurveyTemplateEntityType.TreatmentComparator,
    questions: state.survey.questions,
    removedItems,
  });
  const addedOptions = $templateUtils.computeAddedOptions({
    generateOption: $template.generateTreatmentComparatorOption,
    addedItems,
    questions: state.survey.questions,
    toCheck: optionQuestions,
  });
  const updatedOptions = $templateUtils.computeUpdatedOptions({
    questions: state.survey.questions,
    toCheck: optionQuestions,
    updatedItems,
  });

  return $utils.applyChangesToSurvey({
    addedOptions,
    survey: state.survey,
    removedOptions,
    updatedOptions,
  });
}