import { useCallback } from 'react';
import { SeeMore } from '@presentation/SeeMore';
import { AnalyticsEvent } from '@enums';
import { cx } from '@utils';
import { useTrackEvent } from '@utils/analytics';
import type { ContentFeed } from '@/types';
import { Footer, Header } from './NewsArticle';
import styles from './style/Feed.NewsArticle.css';

type Props = {
  className?: string;
  item:       ContentFeed.NewsArticle;
};

export const NewsArticle = ({ className, item }: Props) => {
  const imageUrl = item.cachedImageUrl || item.articleImageUrl;

  const trackEvent = useTrackEvent(AnalyticsEvent.ViewArticle);
  const trackArticleView = useCallback(() => {
    trackEvent({
      articleId: item.id,
    });
  }, [
    item.id,
    trackEvent,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <Header
        className={styles.header}
        item={item} />

      <div className={styles.title}>
        <ExternalLink
          href={item.articleUrl}
          onClick={trackArticleView}>
          {item.title}
        </ExternalLink>
      </div>

      {item.description &&
        <SeeMore
          lineHeight={21}
          maxLines={2}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }} />
        </SeeMore>}

      {!!imageUrl &&
        <div className={styles.picture}>
          <ExternalLink
            href={item.articleUrl}
            onClick={trackArticleView}>
            <img
              className={styles.img}
              src={imageUrl} />
          </ExternalLink>
        </div>}

      <Footer item={item} />
    </div>
  );
};

NewsArticle.displayName = 'Feed.NewsArticle';

type ExternalLinkProps = {
  children: React.ReactNode;
  href:     string;
  onClick?: () => unknown;
};

const ExternalLink = (props: ExternalLinkProps) => {
  return (
    <a
      href={props.href}
      onClick={props.onClick}
      target="_blank"
      rel="noreferrer">
      {props.children}
    </a>
  );
};

ExternalLink.displayName = 'Feed.NewsArticle.ExternalLink';