import { useCallback, useState } from 'react';
import * as enums from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import Button from '@/components/Button';
import { Body } from '@/components/Modal/Body';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import * as api from '$admin/api';
import type { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type RejectItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
  pipeline: Pick<Project.Members.Item['pipeline'], 'statusId'>;
};

type Props = {
  projectId: number;
  items: RejectItem[];
  onConfirm?: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const RejectMembers = ({ projectId, items, open, onClose, onConfirm }: Props) => {
  const [notify, setNotify] = useState<number[]>([]);

  const handleConfirm = useCallback(() => {
    return api.projects.members.rejectUsers({
      notifyUserIds: notify,
      projectId: projectId,
      userIds: items.map(i => i.user.id),
    })
    .finally(onConfirm);
  }, [projectId, notify, items, onConfirm]);

  const [response, confirm] = useAsyncStateLazy(handleConfirm, [handleConfirm]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Reject ${items.length} expert${items.length > 1 ? 's' : ''}?`}</Header>
      <Body>
        <NotifyByEmail
          items={items}
          notify={notify}
          setNotify={setNotify} />
      </Body>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={response.loading}
          onClick={confirm}
          variant="brick">
          Reject
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

type NotifyByEmailProps = {
  items: RejectItem[];
  notify: number[];
  setNotify: React.Dispatch<React.SetStateAction<number[]>>;
};

function NotifyByEmail({ items, notify, setNotify }: NotifyByEmailProps) {
  const invalidStatuses = [
    enums.UserProjectStatus.Staging,
    enums.UserProjectStatus.Recommended,
    enums.UserProjectStatus.Added,
  ];

  const eligible = items.filter(u => !invalidStatuses.includes(u.pipeline.statusId));

  if (!eligible.length) return null;

  return (
    <label
      className={styles.notify}
      htmlFor="notify">
      <input
        id="notify"
        className={styles.check}
        type='checkbox'
        onChange={() => setNotify(notify.length ? [] : eligible.map(i => i.user.id))}
        checked={!!notify.length} />
      {eligible.length == items.length
        ? `Notify expert${items.length > 1 ? 's' : ''} by email?`
        : `Notify ${eligible.length} eligible expert${eligible.length > 1 ? 's' : ''} by email?`}
    </label>
  );
}

RejectMembers.displayName = 'Modal.Project.RejectMembers';