import { useCallback, useContext, useState, useRef } from 'react';
import type { HeroSearchBarRef } from '@presentation/HeroSearchBar';
import { QueryContext, PopupContext } from './Context';

export function Query({ children }: ChildrenProps) {
  const [query, setQuery] = useState<string>('');
  const barRefs = useRef<HeroSearchBarRef>();

  const { isOpen, setOpen } = useContext(PopupContext);

  const clearQuery = useCallback(() => {
    if (isOpen) {
      setOpen(false);
    }
    setQuery('');
    barRefs.current.input.blur();
  }, [isOpen, setOpen]);

  const value = {
    query,
    setQuery,
    clearQuery,
    barRefs,
  };

  return (
    <QueryContext.Provider value={value}>
      {children}
    </QueryContext.Provider>
  );
}