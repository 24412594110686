import http from '@services/http';
import type { Calls } from './interfaces';

export const cancel = (data: Calls.Bookings.Cancel.Request) => {
  const { callId, ...rest } = data;

  return http.put<Calls.Bookings.Cancel.Response>(`/admin/calls/${callId}/bookings/cancellations`, rest);
};

export const reschedule = (data: Calls.Bookings.Reschedule.Request) => {
  const { callId, ...rest } = data;

  return http.put<Calls.Bookings.Reschedule.Response>(`/admin/calls/${callId}/bookings/rescheduling`, rest);
};

export const updateParticipants = (data: Calls.Bookings.UpdateParticipants.Request) => {
  const { callId, ...rest } = data;
  return http.put<Calls.Bookings.UpdateParticipants.Response>(`/admin/calls/${callId}/bookings/participants`, rest);
};