import type { HelpersFromExtensions } from '@remirror/core';
import { usePositioner } from '@remirror/react';
import { useHelpers } from '@remirror/react';
import type { Positioner, GetActiveProps } from '@remirror/extension-positioner';
import { selectionPositioner, hasStateChanged } from '@remirror/extension-positioner';
import { usePreviousDistinct } from '@utils/hooks';
import type { KaraokeExtension } from '@/components/Transcript/Extension';
import type { TranscriptExtensions } from '@/components/Transcript/interfaces.extension.remirror';

export const useKaraokePositioner = () => {

  const { getKaraokePosition } = useHelpers<KaraokeExtension>();
  const position = getKaraokePosition();
  const previousPosition = usePreviousDistinct(position);

  return usePositioner((): Positioner<{ from: Coords; to: Coords }> => {
    return selectionPositioner.clone({
      hasChanged: props => {
        return hasStateChanged(props) || previousPosition !== position;
      },
      getActive: ({ helpers, view }: GetActiveProps & { helpers: HelpersFromExtensions<TranscriptExtensions> }) => {
        const position = helpers.getKaraokePosition();

        if (!position) {
          return [];
        }

        const from = view.coordsAtPos(position.from);
        const to = view.coordsAtPos(position.to);

        return [{ from, to }];
      },
    });

  }, [position, previousPosition]);
};

type Coords = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};