import { useMutation } from '@tanstack/react-query';
import { useInvalidateFilesQueries } from '@utils/api';
import * as api from '@api';
import type { WorkspaceFolders } from '@api/interfaces/workspace.folder';
import Toast from '@/components/Toast';
import type { CreateFolderParams } from '../interfaces';

type Props = {
  onSuccess?: (res: WorkspaceFolders.CreateFolder.Response<Date>) => void;
};

export const useCreateFolder = (props: Props = {}) => {
  const invalidateQueries = useInvalidateFilesQueries();

  return useMutation({ mutationKey: ['create-folder'], mutationFn: (data: CreateFolderParams) => {
    return api.workspaces.folder.createFolder({
      name: data.name,
      parentObjectId: data.parentObjectId,
      workspaceId: data.workspaceId,
    });
  }, onSuccess: data => {
    Toast.alert({
      title: 'New Folder Created',
    });
    invalidateQueries();
    props.onSuccess?.(data);
  }, onError: e => {
    Toast.error({
      title: 'Error',
      body: `We're sorry, there was an issue creating your folder`,
    });
  } });
};

export default useCreateFolder;