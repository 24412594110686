import type { CellItem } from '@containers/Group.Contacts/interfaces';
import styles from './style/Table.css';

type Props = CellItem;

export const Cell = (props: Props) => {

  return (
    <div
      className={styles.trunc}
      title={props.row.original.email}>
      {props.row.original.email}
    </div>
  );
};

Cell.displayName = 'Column.Email.Cell';