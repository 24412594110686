import { path } from '@consts';
import { RouteAuth } from '@enums';
import { SessionRouter } from '@routes/Routers';
import type * as Routing from '@routes/interfaces';
import { ProjectCompletionContainer } from '@containers/ProjectOnboarding.Completion';
import { ProjectOnboardingDisqualificationContainer } from '@containers/ProjectOnboarding/ProjectDisqualificationContainer';
import { ProjectOnboardingContainer } from '@containers/ProjectOnboarding/ProjectOnboarding.Container';
import { SurveyDisqualification } from '@screens/ProjectDisqualification';
import { SurveyOnboardingInvalidContainer } from '@containers/ProjectOnboarding/SurveyOnboardingInvalid.Container';
import { MedicalOnboarding } from '@screens/MedicalOnboarding.Background';
import { ProjectSurveyArchived } from '@screens/ProjectSurveyArchived';
import { TopicOnboarding } from '@screens/Topic.Onboarding';
import { AdHocCallRequest } from '@screens/AdHocCallRequest';
import { PayoutOnboarding } from '@screens/PayoutSetup';
import { ScreenerOnboarding } from '@screens/ScreenerOnboarding';
import * as utils from '@utils';

export const routes: Routing.Route[] = [
  {
    component: ProjectSurveyArchived,
    exact: true,
    path: path.Onboarding.Projects.Archived,
  },
  {
    component: SurveyDisqualification,
    exact: true,
    path: path.Onboarding.Projects.Disqualified,
  },
  {
    component: SurveyOnboardingInvalidContainer,
    exact: true,
    path: [
      path.Onboarding.Projects.Closed,
      path.Onboarding.Projects.End,
    ],
  },
  {
    component: ProjectCompletionContainer,
    exact: true,
    path: [
      path.Onboarding.Projects.Completion,
    ],
  },
  {
    component: ProjectOnboardingDisqualificationContainer,
    exact: true,
    path: path.Onboarding.Projects.Exit,
  },
  {
    component: ProjectOnboardingContainer,
    exact: true,
    path: path.Onboarding.Projects.Project,
  },
  {
    component: MedicalOnboarding,
    path: [
      path.Onboarding.Background.Root,
      path.Onboarding.Expertise.Root,
    ],
  },
  /*
  {
    component: ProfileWizard,
    path: path.Onboarding.Profile,
  },
  {
    component: Compliance,
    path: path.Onboarding.Compliance,
  },
  */
  {
    component: PayoutOnboarding,
    path: path.Onboarding.PayoutSetup,
  },
  {
    component: TopicOnboarding,
    exact: true,
    path: path.Onboarding.Topics,
  },
  {
    component: ScreenerOnboarding,
    exact: true,
    path: path.Onboarding.SecondaryScreener,
  },
  {
    component: AdHocCallRequest,
    path: path.CallOnboarding.Call,
  },
];

export const router = SessionRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Onboarding.Root,
  roles: utils.roles.consultant,
});