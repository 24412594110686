import { useSlate } from 'slate-react';
import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { cx } from '@utils';
import * as utils from './utils';
import styles from './style.css';

export type MarkButtonFormat =
    'block-quote' | 'bold' | 'code' | 'heading-one' | 'heading-two' | 'italic' | 'underline';

export type MarkButtonProps = {
  className?: string;
  format: MarkButtonFormat;
};

export const MarkButton = (props: MarkButtonProps) => {
  const editor = useSlate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault();
    utils.toggleMark(editor, props.format);
  };

  function Icon() {
    switch (props.format) {
      case 'block-quote': return <BlockQuote />;
      case 'bold': return <Bold />;
      case 'code': return <Code />;
      case 'heading-one': return <HeadingOne />;
      case 'heading-two': return <HeadingTwo />;
      case 'italic': return <Italic />;
      case 'underline': return <Underline />;
      default: return null;
    }
  }

  const active = utils.isMarkActive(editor, props.format);

  return (
    <button
      className={cx(styles.icon, { [styles.active]: active }, props.className)}
      onTouchStart={handleClick}
      onMouseDown={handleClick}>
      <Icon />
    </button>
  );
};

const BlockQuote = () => (
  <div className={styles.blockQuote}>
    <FormatQuoteIcon />
  </div>
);

const Bold = () => (
  <div className={styles.bold}>
    <FormatBoldIcon />
  </div>
);

const Code = () => (
  <div className={styles.code}>
    <CodeIcon />
  </div>
);

const HeadingOne = () => (
  <div className={styles.h1}>
    <LooksOneIcon />
  </div>
);

const HeadingTwo = () => (
  <div className={styles.h2}>
    <LooksTwoIcon />
  </div>
);

const Italic = () => (
  <div className={styles.italic}>
    <FormatItalicIcon />
  </div>
);

const Underline = () => (
  <div className={styles.underline}>
    <FormatUnderlinedIcon />
  </div>
);

export default MarkButton;