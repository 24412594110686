import { useContext } from 'react';
import { Input } from '@/components/Input';
import { FormDerivedEditStateContext, FormStateContext } from './Context';
import type { FormState } from './interfaces';

type Props = {
  className?: string;
  name:       string;
  validate?:  <T>(value: string, state?: FormState<T>) => string | undefined;
  value:      string | number;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>,
    | 'placeholder'
    | 'type'>;

export const TextField = (props: Props) => {
  const editing = useContext(FormDerivedEditStateContext);

  const InputField = props.type === 'number'
      ? NumberInputField
      : TextInputField;

  return editing
    ? <InputField
        className={props.className}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        validate={props.validate} />
    : <TextDisplayField
        className={props.className}
        value={props.value} />;
};

export const TextDisplayField = (props: Pick<Props, 'className' | 'value'>) => {
  return (
    <div className={props.className}>{props.value}</div>
  );
};

export const TextInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const handleInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {

    dispatch({
      key: e.target.name as keyof typeof state.values,
      value: e.target.value,
      type: 'value',
    });

    handleValidation(e);
  };

  const handleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = props.validate?.(e.target.value, state);

    if (state.errors[props.name] === error) return;

    dispatch({
      key: e.target.name as keyof typeof state.values,
      error,
      type: 'error',
    });
  };

  return (
    <div className={props.className}>
      <Input
        name={props.name}
        onBlur={handleValidation}
        onChange={handleInputEvent}
        value={state.values[props.name]}
        type={props.type} />
    </div>
  );
};

const NumberInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const handleInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      key: e.target.name as keyof typeof state.values,
      value: parseInt(e.target.value) || null,
      type: 'value',
    });

    handleValidation(e);
  };

  const handleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = props.validate?.(e.target.value, state);

    if (state.errors[props.name] === error) return;

    dispatch({
      key: e.target.name as keyof typeof state.values,
      error,
      type: 'error',
    });
  };

  const value = state.values[props.name] ?? '';

  return (
    <div className={props.className}>
      <Input
        min={0}
        name={props.name}
        placeholder={props.placeholder}
        onBlur={handleValidation}
        onChange={handleInputEvent}
        value={value}
        type={props.type} />
    </div>
  );
};

export default TextField;