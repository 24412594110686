import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Toast from '@/components/Toast';
import * as api from '$admin/api';
import { CallReschedulingContext } from './Context';
import { Modal } from './Rescheduling.Modal';
import type { MutationParams, OnSuccess, SetContext } from './interfaces';

type Props = {
  children:   React.ReactNode;
  onSuccess?: OnSuccess;
};

export const Container = (props: Props) => {
  const [state, dispatch] = useState<SetContext.Params>(null);

  const resetContext = useCallback(() => {
    dispatch(null);
  }, []);

  const mutation = useMutation({ mutationKey: [
    `put:admin/calls/bookings/rescheduling`,
    state?.callId,
  ], mutationFn: (data: MutationParams) => {
    return api.calls.bookings.reschedule({
      callId: state.callId,
      notify: data.notify,
    });
  }, onSuccess: res => {
    props.onSuccess?.(res);

    Toast.success({
      title: `Success`,
    });
  } });

  const value = {
    mutation,
    params: state,
    resetContext,
    setContext: dispatch,
  };

  return (
    <CallReschedulingContext.Provider value={value}>
      {props.children}
      {state &&
        <Modal />}
    </CallReschedulingContext.Provider>
  );
};

Container.displayName = 'Call.Rescheduling.Container';