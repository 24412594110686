import http from '@services/http';
import * as xform from '@transformers';
import type { Messages } from '@services/api/interfaces';

export const consumed = (data: Messages.Consumed.Request): Promise<Messages.Consumed.Response> => {
  return http.patch(`/messages/conversations/${data.conversationSid}`, data);
};

export const getToken = (data: Messages.GetTokenRequest): Promise<Messages.GetTokenResponse> => {
  return http.get(`/messages/${data.userId}/tokens`);
};

export const send = (data: Messages.SendMessage.Request) => {
  return http.post<Messages.SendMessage.Response<string>>(`/messages/${data.userId}`, data)
  .then(res => ({
    bypass: res.bypass,
    conversationSid: res.conversationSid,
    platformMessage: xform.normalizePlatformMessage(res.platformMessage),
  }));
};

export const accept = (data: Messages.AcceptMessageRequest) => {
  return http.post<Messages.AcceptMessageResponse<string>>(`/messages/${data.userId}/accept`, {
    messageId: data.messageId,
  })
  .then(res => ({
    connection: xform.normalizeConnection(res.connection),
  }));
};

export const reject = (data: Messages.RejectMessageRequest): Promise<Messages.RejectMessageResponse> => {
  return http.post(`/messages/${data.userId}/reject`, {
    messageId: data.messageId,
  });
};