import { Check as ApprovedIcon } from 'react-feather';
import { X as RejectedIcon } from 'react-feather';
import { Flag } from '@/components/icons';
import type { PlatformMessage } from './interfaces';
import styles from './style/ThreadMessageActions.css';

type Props = {
  isApproved: boolean;
  isBypassed: boolean;
  isFlagged:  boolean;
  isRejected: boolean;
  item:       PlatformMessage;
};

const ThreadMessageStatus = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.status}>
        {props.isRejected && <RejectedIcon className={styles.icon} />}

        {props.isFlagged &&
          <Flag
            active={true}
            className={styles.flag} />}

        {props.isApproved && <ApprovedIcon className={styles.icon} />}

        {props.isBypassed
          ? null
          : props.isRejected
            ? <div className={styles.text}>Rejected</div>
            : <div className={styles.text}>Approved</div>}
      </div>
    </div>
  );
};

export { ThreadMessageStatus };
export default ThreadMessageStatus;