import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function ClickContainer({ className, item, children }: Props) {
  const history = useHistory();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    history.push({
      pathname: `/${item.path}`,
      state: { from: `${window.location.pathname}${window.location.search}` },
    });
  }, [item, history]);

  return (
    <a
className={className}
href={`/${item.path}`}
onClick={handleClick}>
      {children}
    </a>
  );
}

export { ClickContainer };

type Props = {
  className?: string;
  children: React.ReactNode;
  item: {
    path: string;
  };
};