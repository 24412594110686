import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Topics } from '@api/interfaces';

type Data = Topics.FetchOverview.Response;

export function useFetchTopic<TData = Data>(
  slug: string,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery({ queryKey: keyUseFetchTopic(slug), queryFn: () => {
    return api.topics.fetchOverview({
      slug,
    });
  }, ...options });
}

export function keyUseFetchTopic(slug: string) {
  return ['topic', slug];
}