import type { RespondentProps } from '@/types';
import { ResponsesViewAll } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import { RespondentBaseballCard as BaseballCard } from '@/components/BaseballCard';
import styles from './style/MaxDiff.Data.User.css';

export default function MaxDiffDataUser(props: Props) {
  return (
    <>
      <div className={styles.text}>
        <div className={styles.name}>
          <BaseballCard {...props}>
            {props.user.name}
          </BaseballCard>
        </div>
        <div className={styles.count}>{`(${props.count} ${formatPlurality('time', props.count)})`}</div>
      </div>
      <ResponsesViewAll />
    </>
  );
}

export { MaxDiffDataUser };

type Props = {
  count: number;
} & RespondentProps;