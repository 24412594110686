import type { SurveyLogic } from '@/types/survey';
import { cx } from '@utils';
import { SurveyLogicRule, utils as enumUtils } from '@enums';
import { SurveyRuleExpression } from './Preview.Expression';
import styles from './style/Preview.css';

type Props = {
  className?: string;
  rule: SurveyLogic.SurveyConditionsRule;
};

export const SurveyConditionsRule = ({ className, rule }: Props) => {

  return (
    <div className={cx(styles.row, className)}>
      <div className={styles.label}>Rule:</div>
      {rule.type !== SurveyLogicRule.CustomExpression
        ? enumUtils.SurveyLogicRule.getName(rule.type)
        : <SurveyRuleExpression value={rule.expression} />
      }
    </div>
  );
};