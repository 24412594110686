import type { SurveyBaseQuotaBuilder } from './interfaces';

export function surveyBaseQuotaReducer(state: SurveyBaseQuotaBuilder.State, action: SurveyBaseQuotaBuilder.Action): SurveyBaseQuotaBuilder.State {

  switch (action.type) {

    case 'update-action':
      return {
        ...state,
        actionType: action.value,
      };

    case 'update-limit':
      return {
        ...state,
        limit: action.value,
      };

    case 'update-name':
      return {
        ...state,
        name: action.value,
      };

    default:
      return state;
  }
}