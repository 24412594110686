// @ts-nocheck
import type { AvailableEventMetadata } from '@/types';
import type { FullCalEvent } from './interfaces';

export function getAvailableEvents(events: FullCalEvent[]) {
  return events
        .filter(e => e.extendedProps.type === 'available')
        .map(e => ({ start: e.start, end: e.end }));
}

export function getEventAvailabilityId(event: FullCalEvent) {
  return getEventMetadata(event).availabilityId;
}

export function getEventMetadata(event: FullCalEvent): Partial<AvailableEventMetadata> {
  return event?.extendedProps?.metadata ?? {};
}

export function getNewEvents(events: FullCalEvent[]) {
  return events
        .filter(e => e.extendedProps.isNew && e.extendedProps.type === 'available')
        .map(e => ({ start: e.start, end: e.end }));
}

export function getRemovedEvents(events: FullCalEvent[]) {
  return [...new Set(events.map(e => getEventAvailabilityId(e)))];
}