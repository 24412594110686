import { useCallback } from 'react';
import type { ProfileEntry } from '@enums';
import { VICS as VICSEnum, VICSType, utils as enumUtils } from '@enums';
import type { Section } from './interfaces';
import Tags from './Profile.Tags';
import styles from './style/Profile.Industries.css';

type Props =
  Section.SingleEntry.ReadOnlyProps<ProfileEntry.Industry>;

export const ProfileIndustries = (props: Props) => {

  const renderSection = useCallback((typeId: VICSEnum) => {
    const items = props.value.filter(f => f.typeId === typeId);

    if (!items.length) return null;

    return (
      <div className={styles.section}>
        <div className={styles.title}>{enumUtils.VICS.getName(typeId)}</div>
        <Tags
          className={styles.tags}
          items={items}
          type={VICSType.getSearchType(typeId)} />
      </div>
    );
  }, [
    props.value,
  ]);

  return (
    <div>
      {renderSection(VICSEnum.Sector)}
      {renderSection(VICSEnum.Industry)}
      {renderSection(VICSEnum.SubIndustry)}
    </div>
  );
};

export default ProfileIndustries;