import { useCallback, useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import type { ExclusiveOptionsQuestion as EO } from '@/types';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { RadioGroup } from '@/components/Radio';
import type { RadioGroupOnInputChange, RadioGroupOnOptionChange } from '@/components/Radio/interfaces';

type Props = {
  className?: string;
  question: EO.FormQuestion;
  sectionId: EO.OptionSection['identifier'];
};

function isExclusive(option: EO.FormOption) {
  return option.type === SurveyOptionType.NoneOfTheAbove ||
    option.metadata.isExclusive;
}

export default function MultipleChoice({ question, className, sectionId }: Props) {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ExclusiveOptions>();

  const optionsMapped = useMemo(() => {
    return question.options
      .filter(o => o.metadata.sectionId === sectionId)
      .map(o => ({
        allowText: o.metadata.isOpenEnded,
        value: o.id,
        label: parseSurveyRichText(o.value),
        disabled: false,
        text: answer.items.find(ao => ao.optionId === o.id)?.text,
      }));
  }, [answer, question, sectionId]);
  const sectionOptionIds = useMemo(() => optionsMapped.map(o => o.value), [optionsMapped]);

  const handleOptionChange: RadioGroupOnOptionChange =
    useCallback(item => {
      const optionId = +item.optionId;
      const otherOptions = answer.items.filter(i => !sectionOptionIds.includes(i.optionId));
      const answerSelected = answer.items.some(i => i.optionId === optionId);

      const option = question.options.find(f => f.id === optionId);

      if (isExclusive(option)) {
        setAnswer({ items: [{ optionId }] });
      } else {
        const items = [
          ...otherOptions.filter(o => {
            const option = question.options.find(f => f.id === o.optionId);
            return !isExclusive(option);
          }),
          !answerSelected ? { optionId } : null,
        ].filter(Boolean);

        setAnswer({ items });
      }
    }, [
      setAnswer,
      sectionOptionIds,
      answer,
      question.options,
    ]);

  const handleInputChange: RadioGroupOnInputChange = useCallback(item => {
    const optionId = +item.optionId;
    const items = answer.items.map(m => ({
      ...m,
      text: m.optionId === optionId ? item.text : m.text,
    }));

    setAnswer({ items });
  }, [answer.items, setAnswer]);

  const selectedOption = answer.items.find(i => sectionOptionIds.includes(i.optionId))?.optionId ?? '';

  return (
    <RadioGroup
      className={className}
      selectedOption={selectedOption}
      onOptionChange={handleOptionChange}
      onInputChange={handleInputChange}
      options={optionsMapped} />
  );
}

export { MultipleChoice };