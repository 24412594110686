import { cx } from '@utils';
import type { TelInputState } from '@/components/TelInput';
import { PhoneInput as TelInput } from '@/components/TelInput';
import styles from './style/Input.Phone.css';

type Props = {
  classes?: {
    invalid?: string;
    root?:    string;
  };
  defaultValue?:       string;
  id?:                 string;
  invalid?:            boolean;
  name?:               string;
  onChange:            (e: React.ChangeEvent<HTMLInputElement>, data: TelInputState) => unknown;
  onChangeCountryCode: (data: TelInputState) => unknown;
};

export const Phone = ({ classes = {}, ...props }: Props) => {
  const classnames = cx({
    [styles.input]: true,
    [styles.invalid]: props.invalid,
  });

  return (
    <div className={styles.root}>
      <style>{`
        .iti--container {
          margin-left: -78px;
        }
      `}
      </style>
      <TelInput
        classes={{
          focusable: {
            root: styles.focusable,
          },
          input: classnames,
        }}
        defaultValue={props.defaultValue}
        editing
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        onChangeCountryCode={props.onChangeCountryCode} />
    </div>
  );
};

Phone.displayName = 'MedicalOnboarding.Input.Phone';