import { useSelector } from 'react-redux';
import * as api from '@api';
import { useAsyncState } from '@utils';
import type * as API from '@api/interfaces';

type UseProjectTargetCompanyReturn =
  API.Projects.FetchTargetCompanyResponse['targetCompany'];

const mapState = (state: Store.State) => ({
  group: state.group,
});

const useProjectTargetCompany = (projectId: number) => {
  const { group } = useSelector(mapState);
  const enabled = group?.features?.projectTargetCompany;

  const [state] = useAsyncState<UseProjectTargetCompanyReturn>(() => {

    if (!enabled) return Promise.resolve(null);

    return api.projects.targetCompany.fetch({ projectId })
    .then(res => res?.targetCompany ?? null);

  }, [projectId]);

  return state;
};

export { useProjectTargetCompany };
export default useProjectTargetCompany;