import { useState } from 'react';
import { ConnectAccountContext } from './Context';
import type { ConnectedAccountValue } from './interfaces';

type Props =
  ChildrenProps;

const ConnectAccountProvider = (props: Props) => {
  const [connecting, setConnecting] = useState<ConnectedAccountValue>(null);

  return (
    <ConnectAccountContext.Provider value={[connecting, setConnecting]}>
      {props.children}
    </ConnectAccountContext.Provider>
  );
};

export { ConnectAccountProvider };
export default ConnectAccountProvider;