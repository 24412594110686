import { useState } from 'react';
import type { Slack } from '@/types';
import { useFetchIntegrations } from '@utils/api';
import { SlackChannelsContext } from './Context';

type Props =
  ChildrenProps;

export const SlackChannelsContainer = (props: Props) => {
  const [channels, setChannels] = useState<Slack.Channel[]>(null);

  const {
    isLoading,
  } = useFetchIntegrations({
    onSuccess: res => {
      if (!channels) {
        setChannels(res.slack.channels);
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <SlackChannelsContext.Provider value={[channels, setChannels]}>
      {props.children}
    </SlackChannelsContext.Provider>
  );
};

export default SlackChannelsContainer;