import * as xform from '@services/api/transformers/helpers';
import { safeJsonDate } from '@utils';
import type { IChat } from '@/types';

export const normalizeChat = (chat: UnparsedChat) => {
  return {
    connections: xform.toMap<number, IChat.Connection>(chat.connections.map(normalizeConnection), 'userId') as Store.Chat.Connections,
    platformMessages: chat.platformMessages.map(normalizePlatformMessage)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.channelSid]: (acc[curr.channelSid] || []).concat(curr),
        };
      }, {} as Store.Chat.PlatformMessages),
  };
};

export const normalizeConnection = (connection: UnparsedConnection) => {
  if (!connection) return null;

  const runTransforms = xform.compose<IChat.Connection, UnparsedConnection>(
    normalizeConnectionDates,
  );

  return runTransforms(connection);
};

function normalizeConnectionDates(connection: UnparsedConnection): IChat.Connection {
  return {
    ...connection,
    createdOn: safeJsonDate(connection.createdOn),
  };
}

export const normalizePlatformMessage = (message: UnparsedPlatformMessage) => {
  if (!message) return null;

  const runTransforms = xform.compose<IChat.PlatformMessage, UnparsedPlatformMessage>(
    normalizePlatformMessageDates,
  );

  return runTransforms(message);
};

function normalizePlatformMessageDates(message: UnparsedPlatformMessage): IChat.PlatformMessage {
  return {
    ...message,
    createdOn: safeJsonDate(message.createdOn),
  };
}

export type UnparsedChat = {
  connections: UnparsedConnection[];
  platformMessages: UnparsedPlatformMessage[];
};

type UnparsedConnectionDates = 'createdOn';

export type UnparsedConnection = {
  [K in keyof Pick<IChat.Connection, UnparsedConnectionDates>]: string;
} & Omit<IChat.Connection, UnparsedConnectionDates>;

type UnparsedPlatformMessageDates = 'createdOn';

export type UnparsedPlatformMessage = {
  [K in keyof Pick<IChat.PlatformMessage, UnparsedPlatformMessageDates>]: string;
} & Omit<IChat.PlatformMessage, UnparsedConnectionDates>;