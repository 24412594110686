import type { SurveyTagging, SurveyBuilderVersion } from '@/types/survey';
import { validateConditions, validateRule } from '@containers/SurveyBuilder.Logic';
import { validateTaggingAction } from './validation.action';

export * from './validation.action';

export function validateTaggingItem(item: SurveyTagging.Item, survey: SurveyBuilderVersion) {

  const actionValidation = validateTaggingAction(item, survey);

  const {
    map: conditionsMap,
    success: conditionsValid,
  } = validateConditions(item.conditions, survey);

  const ruleValidation = validateRule(item.rule);

  return {
    action: actionValidation,
    conditions: conditionsMap,
    rule: ruleValidation,
    success: actionValidation.success &&
      ruleValidation.success &&
      conditionsValid,
  };
}