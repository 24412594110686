import { useEffect } from 'react';
import { useSurveyExpressionEditor } from '@/components/SurveyBuilder.Logic/hooks';
import { SurveyLogicRule } from '@/enums';
import type { SurveyLogicExpression } from '@/types/survey.logic.expression';
import { Remirror } from '@/types';
import { ExpressionEditorContainer } from './Expression.EditorContainer';
import { useSurveyRuleState } from './context';

type Props = ChildrenProps;

const defaultContent: SurveyLogicExpression.RootNode = {
  type: Remirror.NodeType.Doc,
  content: [{
    content: [],
    type: Remirror.NodeType.Paragraph,
  }],
};

export const SyncSurveyExpressionContainer = (props: Props) => {

  const [ruleState] = useSurveyRuleState();

  const { manager, state, setState } = useSurveyExpressionEditor({
    initialState: ruleState.expression || defaultContent,
  });

  useEffect(() => {
    if (ruleState.type === SurveyLogicRule.CustomExpression && ruleState.expression) {
      const newState = manager.createState({ content: ruleState.expression });
      manager.view.updateState(newState);
    }
  }, [manager, ruleState]);

  return (
    <ExpressionEditorContainer
      editable={false}
      state={state}
      setState={setState}
      manager={manager}>
      {props.children}
    </ExpressionEditorContainer>
  );
};