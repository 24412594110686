import { useCallback, useMemo, useState } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder/hooks';
import { useSurveyLogicValidations, useSurveyLogicItemState, useSurveyConditionsValidations } from '../context';
import type { SurveyMatchValidation, LogicItemValidations, QuestionConditionValidation, SumConditionValidation, ClassificationConditionValidation } from '../interfaces';
import { ConditionsValidationsMap } from '../interfaces';
import { validateLogicItem } from '../validation';

export const useSurveyLogicActionValidations = () => {
  const ctx = useSurveyLogicValidations();
  return ctx.action;
};

export const useSurveyQuestionConditionValidations = (identifier: string) => {
  const validationsMap = useSurveyConditionsValidations();

  return useMemo((): QuestionConditionValidation => {
    return validationsMap[identifier] as QuestionConditionValidation || defaultQuestionConditionValidations;
  }, [validationsMap, identifier]);
};

export const useSurveySumConditionValidations = (identifier: string) => {
  const validationsMap = useSurveyConditionsValidations();

  return useMemo((): SumConditionValidation => {
    return validationsMap[identifier] as SumConditionValidation || defaultSumConditionValidations;
  }, [validationsMap, identifier]);
};

export const useClassificationConditionValidations = (identifier: string) => {
  const validationsMap = useSurveyConditionsValidations();

  return useMemo((): ClassificationConditionValidation => {
    return validationsMap[identifier] as ClassificationConditionValidation || defaultClassificationConditionValidation;
  }, [validationsMap, identifier]);
};

const defaultQuestionConditionValidations: QuestionConditionValidation = {
  question: { success: true },
  type: { success: true },
  value: { success: true },
};

const defaultSumConditionValidations: SumConditionValidation = {
  comparate: { success: true },
  items: { success: true },
  operator: { success: true },
};

const defaultClassificationConditionValidation: ClassificationConditionValidation = {
  value: { success: true },
};

export const useRunSurveyLogicValidations = () => {
  const [validations, setValidations] = useState<LogicItemValidations>({
    action: { success: true },
  });
  const [matchValidation, setMatchValidation] = useState<SurveyMatchValidation>({
    conditions: {},
    rule: { success: true },
  });

  const [item] = useSurveyLogicItemState();
  const [state] = useSurveyBuilderState();

  const runValidations = useCallback(() => {
    const { success, conditions, action, rule } = validateLogicItem(item, state.survey);

    setValidations({ action });
    setMatchValidation({ conditions, rule });
    return success;
  }, [item, state.survey]);

  return {
    validations,
    matchValidation,
    runValidations,
  };
};