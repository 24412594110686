import { useMemo, useRef } from 'react';
import { useActiveQuestion } from '@containers/SurveyResponses/hooks/useActiveQuestion';
import type { SurveyAggregate } from '@/types';
import { RepeatSurveyQuestionResponses } from '@/components/SurveyResponses.Repeat/QuestionResponses';
import { RepeatChartRefContext, RepeatQuestionResponsesContext } from './Context';
import { useRepeatSurveyData } from './hooks/useRepeatSurveyData';

export const RepeatQuestionResponsesContainer = () => {
  const question = useActiveQuestion();
  const data = useRepeatSurveyData();
  const chartRef = useRef<HTMLDivElement>();

  const aggregate = useMemo(() => data.aggregate[question.base.id], [data.aggregate, question.base.id]);
  const historical = useMemo(() => {
    return data.projectIds.reduce((acc, projectId) => {
      return {
        ...acc,
        [projectId]: data.historical[projectId][question.base.id],
      };
    }, {} as SurveyAggregate.QuestionMap);
  }, [
    data.historical,
    data.projectIds,
    question.base.id,
  ]);

  const value = useMemo(() => ({
    aggregate,
    historical,
    question,
    projectIds: data.projectIds,
  }), [
    aggregate,
    historical,
    question,
    data.projectIds,
  ]);

  return (
    <RepeatQuestionResponsesContext.Provider value={value}>
      <RepeatChartRefContext.Provider value={chartRef}>
        <RepeatSurveyQuestionResponses />
      </RepeatChartRefContext.Provider>
    </RepeatQuestionResponsesContext.Provider>
  );

};

export default RepeatQuestionResponsesContainer;