import { createRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useVideoAnalytics } from '@utils/analytics';
import { useIntersectionObserver, getLocationFor } from '@utils';
import type { Posts } from '@/types';
import { Body } from './Post.Body';
import styles from './style/Post.Media.Video.css';

type Props = {
  item: Omit<Posts.Post, 'transcript'>;
};

function determineIfPlaying(video: HTMLVideoElement) {
  return video.currentTime > 0 && !video.paused && !video.ended
    && video.readyState > video.HAVE_CURRENT_DATA;
}

export const Video = ({ item }: Props) => {
  const ref = createRef<HTMLVideoElement>();

  const onIntersection = useCallback((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry: Omit<IntersectionObserverEntry, 'target'> & { target: HTMLVideoElement }) => {
      if (entry.isIntersecting) {

        if (entry.intersectionRatio === 1) {
          entry.target.play();
        } else {
          const isPlaying = determineIfPlaying(entry.target);

          if (isPlaying) {
            entry.target.pause();
          }
        }
      }
    });
  }, []);

  useVideoAnalytics({
    ref,
    mediaId: item.media.id,
  });

  useIntersectionObserver(onIntersection, {
    options: {
      rootMargin: '0px',
      threshold: [0.25, 1],
    },
    ref,
  });

  return (
    <div className={styles.root}>
      <div className={styles.player}>
        <video
          className={styles.video}
          controls
          muted
          playsInline
          poster={item.media.thumbnail?.url}
          preload="metadata"
          ref={ref}
          src={item.media.content.url}
          width="100%" />
      </div>

      <div className={styles.title}>
        <Link
          rel="noreferrer"
          target="_blank"
          to={getLocationFor.discovery.video({ postId: item.id })}>
          {item.media.title}
        </Link>
      </div>

      {item.media.content.description &&
        <Body
          maxLines={3}
          value={item.media.content.description} />}
    </div>
  );
};

Video.displayName = 'Post.Media.Video';