import { Button } from '@/components/Button';
import type { ButtonProps } from '@/components/Button/interfaces';
import styles from './style.css';

export const CopiedButton = (props: ButtonProps) => (
  <Button.Secondary
    className={styles.btn}
    variant='brick'
    {...props}>
    Copied
  </Button.Secondary>
);

export const CopyButton = (props: ButtonProps) => (
  <Button.Secondary
    className={styles.btn}
    variant='brick'
    {...props}>
    Copy
  </Button.Secondary>
);
