import type { ProjectSubtype } from '@enums';
import { createNamedContext } from '@utils';
import type { TagItem } from '@/components/Project.Tags/interfaces';

type OnboardingStepperContextValue = {
  back:     () => void;
  next:     () => void;
  redirect: () => void;
} & IProjectId;

type OnboardingFormContextValue = {
  categories: TagItem[];
  subtypes:   ProjectSubtype[];
  onChangeSubtype: (subtype: ProjectSubtype, checked: boolean) => void;
  onRemovePresetCategory: (id: number) => void;
  replaceCategoriesState: React.Dispatch<React.SetStateAction<TagItem[]>>;
};

export const OnboardingStepperContext = createNamedContext<OnboardingStepperContextValue>(undefined, 'Onboarding.Stepper.Context');
export const OnboardingFormContext = createNamedContext<OnboardingFormContextValue>(undefined, 'Onboarding.Form.Value');