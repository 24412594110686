import { useMemo } from 'react';
import * as utils from '@utils';
import { useUserRoles } from './useUserRoles';

export const useHasComplianceRole = () => {
  const roles = useUserRoles();

  const hasComplianceRole = useMemo(() => {
    return utils.hasComplianceRole({ roles });
  }, [roles]);

  return hasComplianceRole;
};

export default useHasComplianceRole;