import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import type { ConjointAnalysisQuestion } from '@/types';
import { useSurveyQuestionResponsesData } from './useSurveyQuestionResponsesData';

export const useConjointAnalysisSortedAttributes = () => {

  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();

  return useMemo(() => {
    const sortedAttributes = [...question.attributes].sort((a, b) => {
      return responses.attributes[b.base.id].importance - responses.attributes[a.base.id].importance;
    });

    return sortedAttributes.reduce((acc, attr) => {
      const sortedLevels = [...attr.levels].sort((a, b) => {
        return responses.levels[b.base.id].utility - responses.levels[a.base.id].utility;
      });

      return [
        ...acc,
        {
          ...attr,
          levels: sortedLevels,
        },
      ];
    }, [] as ConjointAnalysisQuestion.Attribute[]);
  }, [
    question.attributes,
    responses.attributes,
    responses.levels,
  ]);
};

export default useConjointAnalysisSortedAttributes;