import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { CSVFieldMappingContext, CSVQueriesContext } from './Context';

type Props = {
  children: React.ReactNode;
  file:     File;
};

export const CSVQueriesContainer = (props: Props) => {
  const group = useSelectGroup();
  const ctx = useContext(CSVFieldMappingContext);

  const fields = useQuery({ queryKey: [
    QK.Groups.Contacts.Import.Analyze.Post,
    group?.id,
    props.file,
  ], queryFn: () => {
    return api.groups.contacts.analyzeCSV({
      file: props.file,
      groupId: group.id,
    });
  }, enabled: !!props.file, refetchOnWindowFocus: false });

  const summary = useQuery({ queryKey: [
    QK.Groups.Contacts.Import.Finalize.Post,
    ctx.values.email,
    group?.id,
    props.file,
  ], queryFn: () => {
    return api.groups.contacts.finalizeCSV({
      file: props.file,
      email: ctx.values.email,
      groupId: group.id,
    });
  }, enabled: false, refetchOnWindowFocus: false });

  const value = {
    fields,
    summary,
  };

  return (
    <CSVQueriesContext.Provider value={value}>
      {props.children}
    </CSVQueriesContext.Provider>
  );
};

CSVQueriesContainer.displayName = 'Groups.Contacts.Import.CSV.Queries.Container';