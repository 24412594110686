import { SurveyLogicBuilderState } from './State';
import { SurveyLogicItemValidationContainer } from './ValidationContainer';
import { LogicItemSaveContainer } from './LogicItem.SaveContainer';
import type { LogicItemBuilder } from './interfaces';

type Props = {
  initial: LogicItemBuilder.State;
} & ChildrenProps;

export const SurveyLogicItemContainer = ({ children, initial }: Props) => {

  return (
    <SurveyLogicBuilderState initial={initial}>
      <SurveyLogicItemValidationContainer>
        <LogicItemSaveContainer>
          {children}
        </LogicItemSaveContainer>
      </SurveyLogicItemValidationContainer>
    </SurveyLogicBuilderState>
  );
};