import { useMemo } from 'react';
import { scaleLinear } from 'd3-scale';
import type { SurveyQuestionType } from '@enums';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks/useSurveyQuestionResponsesData';
import type { NumberInputTable } from '../interfaces';

export const useNumberInputTableBuckets = () => {

  const {
    question,
    responses,
  } = useSurveyQuestionResponsesData<SurveyQuestionType.NumberInputTable>();

  return useMemo(() => {
    const allValues = question.matrixRows.reduce((acc, row) => {
      const rowData = responses[row.base.id];
      const values = question.options.map(o => rowData.options[o.base.id].avg);

      return acc.concat(values);
    }, [] as number[]);

    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);

    const ticks = scaleLinear()
      .domain([minValue, maxValue])
      .nice()
      .ticks();

    return ticks.reduce<NumberInputTable.Bucket[]>((acc, t, i) => {
      if (i !== 0 ) {

        acc.push({
          maxValue: t,
          minValue: ticks[i - 1],
        });
      }

      return acc;
    }, []);
  }, [
    question.matrixRows,
    question.options,
    responses,
  ]);

};