import { useContext } from 'react';
import { ProjectSurveyResponsesStateContext } from '@containers/SurveyResponses';
import type {
  RepeatSurvey,
  RepeatSurveyState,
} from '../interfaces';

export const useRepeatSurveyResponsesState = <T extends RepeatSurvey.QueryState.Value = RepeatSurvey.QueryState.Value>() => {
  const context = useContext(ProjectSurveyResponsesStateContext);

  return context as RepeatSurveyState<T>;
};

export default useRepeatSurveyResponsesState;