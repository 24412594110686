import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useSubmitTemplateCompetitors } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';
import { minCompetitors, maxCompetitors } from '../utils';
import { useListElements } from './list-elements';

export const useCanSaveCompetitors = (competitors: SurveyTemplate.Competitor[]) => {

  const [state] = useSurveyBuilderState();

  return useMemo(() => {
    const numberValid = competitors.length >= minCompetitors
      && competitors.length <= maxCompetitors;

    if (!numberValid) return false;

    const contentValid = !competitors
      .some((s, i) => s.value.length < 2 ||
        s.value === (state.survey.template.data as SurveyTemplate.ITarget).target.value ||
        competitors.some((ss, ii) => ss.value === s.value && i !== ii)
      );

    return contentValid;
  }, [
    competitors,
    state.survey.template.data,
  ]);
};

export const useTemplateCompetitors = () => {
  const [state] = useSurveyBuilderState();
  const submit = useSubmitTemplateCompetitors();

  const {
    canAdd: canAddCompetitor,
    canRemove: canRemoveCompetitor,
    items: competitors,
    onAddItem: onAddCompetitor,
    onRemoveItem: onRemoveCompetitor,
    onUpdateItem: onUpdateCompetitor,
  } = useListElements({
    min: minCompetitors,
    max: maxCompetitors,
    initial: (state.survey.template.data as SurveyTemplate.ICompetitors).competitors,
  });

  const onSubmit = useCallback(() => {
    submit(competitors);
  }, [submit, competitors]);

  return {
    canAddCompetitor,
    canRemoveCompetitor,
    onAddCompetitor,
    onRemoveCompetitor,
    onUpdateCompetitor,
    onSubmit,
    competitors,
  };
};