import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { createTag } from '@api/projects.conference';
import type { ConferenceTags } from '@api/interfaces/projects';
import { indexBy } from '@utils/array';
import { TagStateContext } from '@/components/Project.Tags/Context';

type MutationParams = Omit<ConferenceTags.CreateTag.Request, 'projectId'>;
export const useCreateTagMutation = (projectId: number) => {
  const { dispatch } = useContext(TagStateContext);
  return useMutation({ mutationKey: ['create-project-tag', projectId], mutationFn: (data: MutationParams) => {
    return createTag({
      projectId,
      ...data,
    });
  }, onSuccess: result => {
    dispatch({
      type: 'add-tag',
      tag: result.tag,
    });
    dispatch({
      type: 'update-ordinals',
      ordinals: indexBy(result.rebalancedOrdinals, k => k.id, v => v.ordinal),
    });
  } });
};