import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { cx } from '@utils';
import type { SurveyQuestionFormOption } from '@/types';
import { Input } from '@/components/Input';
import { RadioButton } from '@/components/Radio';
import { Checkbox } from '@/components/Checkbox';
import { SurveyRichTextEditor, SurveyRichTextEditorContainer, useSurveyRichTextEditor } from '@/components/Survey.RichText';
import { useMatrixFormContext } from '@/components/SurveyForm/context';
import type { MatrixForm } from '@/components/SurveyForm/interfaces';
import styles from './style/Grid.css';

type Props = MatrixForm.GridItemProps;

const SurveyMatrixGridRowItem = ({
  updateRowAnswer,
  updateRowTextValue,
  ...props
}: Props) => {

  const ctx = useMatrixFormContext();

  const onChange = useCallback((optionId: number) => () => {
    updateRowAnswer(optionId);
  }, [updateRowAnswer]);

  const onOpenEndedValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateRowTextValue(e.target.value);
  }, [updateRowTextValue]);

  const renderToggle = useCallback((optionId: number) => {
    const checked = ctx.values[props.row.id][optionId];
    if (props.variant === 'single') {

      return (
        <RadioButton
          onChange={onChange(optionId)}
          checked={checked} />
      );
    } else {
      return (
        <Checkbox
          onChange={onChange(optionId)}
          checked={checked} />
      );
    }
  }, [
    ctx.values,
    props.row.id,
    onChange,
    props.variant,
  ]);

  const renderCell = useCallback((option: SurveyQuestionFormOption, width?: string) => {
    const className = cx(styles.cell, props.cellClassName, {
      [styles.cellNa]: option.type === SurveyOptionType.NotApplicable,
    });
    return (
      <div
        key={option.id}
        className={className}
        style={{ width }}>
        <div className={styles.cellWrap}>
          {renderToggle(option.id)}
        </div>
      </div>
    );
  }, [
    renderToggle,
    props.cellClassName,
  ]);

  const naOption = useMemo(() => props.options.find(f => f.type === SurveyOptionType.NotApplicable), [props.options]);

  return (
    <div className={styles.row}>
      <div
        style={{ maxWidth: props.maxNameWidth }}
        className={styles.rowName}>
        <div>
          <SurveyRichTextEditor />

          {props.row.metadata.isOpenEnded &&
            <Input
              className={styles.input}
              onChange={onOpenEndedValueChange}
              value={props.openEndedValue || ''} />}
        </div>
      </div>
      {props.options
        .filter(f => f.type !== SurveyOptionType.NotApplicable)
        .map(option => renderCell(option, props.cellWidth))}
      {naOption && renderCell(naOption)}
    </div>
  );
};

const Container = (props: Props) => {

  const { manager, state, onChange } = useSurveyRichTextEditor({
    initialState: props.row.value,
  });

  return (
    <SurveyRichTextEditorContainer
      editable={false}
      manager={manager}
      state={state}
      onChange={onChange}>
      <SurveyMatrixGridRowItem {...props} />
    </SurveyRichTextEditorContainer>
  );
};

export { SurveyMatrixGridRowItem as Container };
export default Container;