import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation';
import { formatPlurality, useAsyncStateLazy } from '@utils';
import Button from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Body } from '@/components/Modal/Body';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import * as api from '$admin/api';
import type { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type FollowupItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  project: Descriptor;
  items: FollowupItem[];
  onConfirm?: (project: Descriptor, items: FollowupItem[]) => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export function FollowupMembers({ project, items, open, onClose, onConfirm }: Props) {
  const handleConfirm = useCallback(() => {
    return api.projects.members.followupMembers({
      projectId: project.id,
      userIds: items.map(i => i.user.id),
    })
    .finally(() => onConfirm(project, items));
  }, [project, items, onConfirm]);

  const [response, confirm] = useAsyncStateLazy(handleConfirm, [handleConfirm]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <Header>{`Follow up with ${items.length} ${formatPlurality('expert', items.length)}?`}</Header>
      <Body>An e-mail with a reminder, project name, and project description will be sent to the selected {formatPlurality('expert', items.length)} if they have had less than 3 followups for this project and {`haven't`} already received one for this project within the last 24 hours.</Body>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          loading={response.loading}
          onClick={confirm}
          variant="brick">
          Follow up
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
}

FollowupMembers.displayName = 'Modal.Project.FollowupMembers';