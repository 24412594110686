import type { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import Toast from '@/components/Toast';

type Data = {
  id: number;
};
type Variables = {
  id: number;
};

const selectConnectedAccounts = (state: Store.State) => state.user.connected;

type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export const useDisconnectUserAccount = (options?: Options) => {

  const dispatch = useDispatch();
  const connectedAccounts = useSelector(selectConnectedAccounts);

  return useMutation({ mutationKey: ['disconnect-user-account'], mutationFn: ({ id }: Variables) => {

    return api.users.disconnectAccount({ id })
      .then(_ => {
        return { id };
      });

  }, ...options, onSuccess: (res, variables, context) => {
    dispatch(actions.connectedAccountsChanged({
      connected: connectedAccounts.filter(c => c.id !== res.id),
    }));
    Toast.alert({
      title: 'Connected Account Removed',
      body: 'Your connected account has been successfully removed.',
    });
    options?.onSuccess?.(res, variables, context);
  } });
};