import { useMessageApprovalActions } from '@containers';
import { ApprovalStatus } from '@enums';
import type { PlatformMessage, PlatformMessageThread } from '@/types';
import { Message, MessageThread } from '@/components/MessageThread';

type Props = {
  data: PlatformMessageThread;
};

const Thread = (props: Props) => {

  function renderMessage(item: PlatformMessage) {
    const needsApproval = item.message.approvalStatusId === ApprovalStatus.NeedsApproval;

    const props = {
      item,
      key: item.message.id,
    };

    return !needsApproval
      ? <StaticMessage {...props} />
      : <ActiveMessage {...props} />;
  }

  return (
    <MessageThread
      data={props.data}
      renderMessage={renderMessage} />
  );
};

function ActiveMessage(props: { item: PlatformMessage }) {
  const actions = useMessageApprovalActions();
  const isFlagged = props.item.message.screeningStatusId === ApprovalStatus.Rejected;

  function approve() {
    actions.approve(props.item.message.id);
  }

  function reject() {
    actions.reject(props.item.message.id);
  }

  return (
    <Message.Active
      isFlagged={isFlagged}
      onApprove={approve}
      onReject={reject}
      item={props.item} />
  );
}

function StaticMessage(props: { item: PlatformMessage }) {
  const { approvalStatusId, screeningStatusId } = props.item.message;

  const isApproved = approvalStatusId === ApprovalStatus.Approved ||
                     approvalStatusId === ApprovalStatus.NotRequired
                  && screeningStatusId === ApprovalStatus.Approved;

  const isBypassed = screeningStatusId === ApprovalStatus.NotRequired
                  && approvalStatusId === ApprovalStatus.NotRequired;

  const isFlagged = screeningStatusId === ApprovalStatus.Rejected;

  const isRejected = approvalStatusId === ApprovalStatus.Rejected;

  return (
    <Message.Static
      isApproved={isApproved}
      isBypassed={isBypassed}
      isFlagged={isFlagged}
      isRejected={isRejected}
      item={props.item} />
  );
}

export { Thread };
export default Thread;