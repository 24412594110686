import type { ProjectType } from '@/enums';
import { SurveyType } from '@/enums';

type Props = {
  projectType: ProjectType;
  surveys: { typeId: SurveyType }[];
};

export const useSurveyTypesToExclude = ({ projectType, surveys }: Props) => {
  return surveys.filter(t => t.typeId === SurveyType.Project).map(s => s.typeId);
};