import { useCallback } from 'react';
import { useHelpers } from '@remirror/react';

export const useGetText = () => {
  /* eslint-disable no-control-regex */
  const { getText } = useHelpers();

  return useCallback(() => {
    return getText()
    .replace(/[\x00]/g, '')
    .split('\n')
    .filter(Boolean)
    .join(' ');
  }, [getText]);
};