import type { AxiosResponse } from 'axios';
import type { QueryFunctionContext, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { querykey } from '@consts';

type QFContext<
  S extends string = string,
  T extends unknown = unknown,
  P extends unknown = unknown> =
    QueryFunctionContext<[S, T], P>;

const QKey = querykey.Topics.Suggested.Get;

export const useFetchSuggestedTopics = (params: Params = {}, options: Options = {}) => {
  const query = useInfiniteQuery([QKey, params], fetchSuggestedTopics, {
    getNextPageParam: lastPage => lastPage.pagination.next,
    enabled: true,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

const fetchSuggestedTopics = (ctx: Context) => {
  const [key, params] = ctx.queryKey;

  return api.topics.fetchSuggestedItems({
    index: ctx.pageParam,
    size: params.size,
  });
};

type Context = QFContext<typeof QKey, Params, number>;

type Params = {
  size?: number;
};

type Options =
  UseInfiniteQueryOptions<
    API.Topics.FetchSuggestedItems.Response,
    AxiosResponse,
    API.Topics.FetchSuggestedItems.Response>;