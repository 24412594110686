import type { ComponentType } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyFormTemplateData } from '@containers/SurveyForm/hooks';
import { useIsEditingSurvey, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplateType } from '@/enums';
// eslint-disable-next-line import/no-cycle
import { useEditableTemplateTPPModal, useTemplateTPPModal } from '@/components/Survey.Templates/Modal.TPP.RichText';
import styles from './style/Node.ViewStimulus.css';

export const ViewTPP_RTNode: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />
  ;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const editing = useIsEditingSurvey();

  const [toggleModal, Modal] = useEditableTemplateTPPModal();

  return (
    <>
      <span className={styles.link} onClick={toggleModal}>
        View Product
      </span>
      <Modal canEdit={!editing} />
    </>
  );
}

function Node({ node }: NodeViewComponentProps) {

  const [toggleModal, Modal] = useTemplateTPPModal();
  const data = useSurveyFormTemplateData<SurveyTemplateType.MessageTesting>();

  if (!data) return null;

  return (
    <>
      <span className={styles.link} onClick={toggleModal}>
        View Product
      </span>
      {data && <Modal tpp={data.tpp} />}
    </>
  );
}