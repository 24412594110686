import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { ContentFeed } from '@/types';

type Data = {
  items: ContentFeed.CarouselItem[];
};

export default function useProfileFeaturedPosts<TData = Data>(
  userId: number,
  options?: UseQueryOptions<Data, AxiosError, TData>
) {

  return useQuery({ queryKey: ['profile-featured-posts', userId], queryFn: async () => {
    return api.profiles.activity.fetchFeaturedItems({
      userId,
    });
  }, ...options });
}

export { useProfileFeaturedPosts };