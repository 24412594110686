import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Profiles } from '@api/interfaces';
import type { Contact } from '@/types';

type Data = Partial<Contact>;

export default function useFetchContactInfo<TData = Data>(
  data: Profiles.GetContactInfo.Request,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery({ queryKey: ['post', data.userId, data.properties], queryFn: async () => {
    const results = await api.profiles.getContactInfo(data);

    return results;
  }, ...options });
}

export { useFetchContactInfo };