import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { PayoutSetupContainer } from '@containers/PayoutSetup';
import { usePayoutSetup } from '@containers/PayoutSetup/hooks';
import { useStepper } from '@utils';
import { PayoutSetupStepperContext } from './Context';
import * as Overview from './Overview';
import * as Setup from './Setup';
import * as Done from './Done';
import type { StepperProps } from './interfaces';

export default function AdHocCall(props: StepperProps) {
  return (
    <PayoutSetupContainer>
      <Stepper {...props } />
    </PayoutSetupContainer>
  );
}

function Stepper({ hasPayoutAccount, hasStepsAfter, onComplete, onSkip }: StepperProps) {
  const options = {
    index: hasPayoutAccount ? 2 : 0,
  };

  const [Screen, actions] = useStepper([
    CallOverview,
    CallSetup,
    CallDone,
  ], options);

  const value = {
    ...actions,
    hasPayoutAccount,
    hasStepsAfter,
    onComplete,
    onSkip,
  };

  return (
    <PayoutSetupStepperContext.Provider value={value}>
      <Screen />
    </PayoutSetupStepperContext.Provider>
  );
}

function CallOverview() {
  const { next } = useContext(PayoutSetupStepperContext);

  const navigation = {
    back: null,
    next,
    skip: null,
  };

  return (
    <Overview.AdHocCall
      navigation={navigation} />
  );
}

function CallSetup() {
  const { back, next } = useContext(PayoutSetupStepperContext);

  const navigation = {
    back,
    next,
    skip: null,
  };

  return (
    <Setup.Setup navigation={navigation} />
  );
}

function CallDone() {
  const history = useHistory();
  const { hasStepsAfter, onComplete } = useContext(PayoutSetupStepperContext);
  const { refetch } = usePayoutSetup();

  const handleNext = useCallback(() => {
    refetch();

    if (onComplete) {
      onComplete();
    } else {
      history.push(pathname.HOME);
    }
  }, [history, refetch, onComplete]);

  const navigation = {
    back: null,
    next: handleNext,
    skip: null,
  };

  return (
    <Done.AdHocCall
      navigation={navigation}
      nextLabel={hasStepsAfter ? 'Next' : 'Back to Home'} />
  );
}

export { AdHocCall };