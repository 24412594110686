import * as types from '@store/action-types';
import type {
  ProjectPipelineItemChangedAction,
  ProjectPipelineItemChangedState,
  ProjectPipelineUpdatedAction,
  ProjectPipelineUpdatedState,
} from '@store/interfaces';

export const projectPipelineItemChanged = (data: ProjectPipelineItemChangedState): ProjectPipelineItemChangedAction => {
  return {
    type: types.PROJECT_PIPELINE_ITEM_CHANGED,
    record: data.record,
  };
};

export const projectPipelineUpdated = (data: ProjectPipelineUpdatedState): ProjectPipelineUpdatedAction => {
  return {
    type: types.PROJECT_PIPELINE_UPDATED,
    pipeline: data.pipeline,
    projectId: data.projectId,
  };
};