import { useSurveyClassification } from '@/containers/SurveyBuilder';
import type { SurveyTaggingResultType } from '@/enums';
import type { SurveyTagging } from '@/types';

type Props = {
  action: SurveyTagging.Action<SurveyTaggingResultType.Classification>;
};

export const ClassificationTagAction = ({ action }: Props) => {

  const classification = useSurveyClassification(action.value.identifier);

  return (
    <span>Classify as {classification.name}</span>
  );
};