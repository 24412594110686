import { useMemo } from 'react';
import type { ConferencePlatform } from '@enums';
import { utils as enumUtils } from '@enums';
import { FieldSelectNumber } from './Field.Select';
import styles from './style.css';

type Props = {
  classes?: {
    label?: string;
  };
  isEditing?: boolean;
  label?: string;
  name?: string;
  onChange?: (value: ConferencePlatform) => void;
  displayValue: string;
};

export function FieldConferencePlatform({
  classes = {},
  isEditing = false,
  label = 'Platform',
  name = 'conferencePlatform',
  onChange,
  displayValue,
}: Props) {
  const options = useMemo(() => {
    const items = enumUtils.ConferencePlatform.values();

    return items.reduce<{ [k: string]: string }>((acc, curr) => {
      acc[curr] = enumUtils.ConferencePlatform.getName(curr);
      return acc;
    }, {});
  }, []);

  return (
    <div className={styles.section}>
      <FieldSelectNumber
        classes={classes}
        editing={isEditing}
        onChange={onChange}
        label={label}
        name={name}
        displayValue={displayValue}
        options={options} />
    </div>
  );
}