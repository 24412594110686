import { Fragment, useCallback, useContext, useMemo, memo } from 'react';
import { Checkbox } from '@/components/Checkbox';
import { MatchListQueryParamsContext, MatchListSelectedContext, MembersIndeterminateSelectedContext } from '$admin/Search/Context';
import type { Results } from '$admin/Search/interfaces/members';
import { useSelected } from './hooks/useSelected';
import styles from './style/Table.css';

type Props = {
  rows: Results.TableRow[];
};

export function IndeterminateCheckbox({ rows }: Props) {
  const [selected, setSelected, clear] = useSelected();
  const { enabled, page, setSelectPageOnly } = useContext(MatchListSelectedContext);
  const [list] = useContext(MatchListQueryParamsContext);
  const state = useContext(MembersIndeterminateSelectedContext);

  const rowsOnPage = useMemo(() => rows.map(r => ({
    user: r.original.user,
  })), [rows]);

  const userIdsOnPage = useMemo(() => rowsOnPage.map(m => m.user.id), [rowsOnPage]);

  const handleChange = useCallback(() => {
    if (state.indeterminate) {
      if (setContainsAll(selected.ids, userIdsOnPage)) {
        setSelected(prev => ({
          ids: new Set(Array.from(prev.ids).filter(e => !userIdsOnPage.some(p => p === e))),
          items: prev.items.filter(e => !rowsOnPage.some(p => p.user.id === e.user.id)),
        }));
      } else {
        setSelectPageOnly(false);
        setSelected(prev => ({
          ids: new Set([...prev.ids, ...userIdsOnPage]),
          items: [...prev.items, ...rowsOnPage],
        }));
      }
    } else {
      if (selected.ids.size) {
        setSelectPageOnly(true);
        clear();
      } else {
        setSelectPageOnly(false);
        setSelected({
          ids: new Set(userIdsOnPage),
          items: rowsOnPage,
        });
      }
    }
  }, [
    clear,
    state.indeterminate,
    rowsOnPage,
    selected,
    setSelected,
    setSelectPageOnly,
    userIdsOnPage,
  ]);

  const displayTotal = useMemo(() => {
    const visible = state.count.selected;

    if (!enabled) return visible;

    return !page && state.checked && !state.indeterminate
      ? list?.count ?? state.count.selected
      : state.count.selected;

  }, [
    enabled,
    list?.count,
    page,
    state.checked,
    state.count.selected,
    state.indeterminate,
  ]);

  return (
    <div className={styles.checkbox}>
      <Checkbox
        checked={state.checked}
        disabled={!rows.length}
        indeterminate={state.indeterminate}
        onChange={handleChange} />
      <SelectedDisplay size={displayTotal} />
    </div>
  );
}

type SelectedDisplayProps = {
  size: number;
};

const SelectedDisplay = memo(({ size }: SelectedDisplayProps) => {
  if (!size) return null;
  return (
    <Fragment>
      ({size})
    </Fragment>
  );
});

function setContainsAll<T>(source: Set<T>, check: T[]) {
  for (const value of check) {
    if (!source.has(value)) return false;
  }
  return true;
}