import type { Dispatch } from 'react';
import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { SurveyTagging } from '@/types/survey';
import type { TaggingItemBuilder, TaggingItemValidations } from './interfaces';

export type SurveyTaggingItemStateContextValue =
  [TaggingItemBuilder.State, Dispatch<TaggingItemBuilder.Action>];

export type RunSurveyTaggingValidations = () => boolean;

export const SurveyTaggingItemStateContext = createNamedContext<SurveyTaggingItemStateContextValue>(null, 'SurveyTaggingItemStateContext');
export const SurveyTaggingItemValidationsContext = createNamedContext<TaggingItemValidations>(null, 'SurveyTaggingItemValidationsContext');
export const RunSurveyTaggingValidationsContext = createNamedContext<RunSurveyTaggingValidations>(null, 'RunSurveyTaggingValidationsContext');

export const useSurveyTaggingValidations = () => useContext(SurveyTaggingItemValidationsContext);
export const useSurveyTaggingItemState = () => useContext(SurveyTaggingItemStateContext);
export const useRunTaggingValidationsCtx = () => useContext(RunSurveyTaggingValidationsContext);