import type {
  ApplySchemaAttributes,
  NodeExtensionSpec,
  NodeSpecOverride } from '@remirror/core';
import {
  extension,
  ExtensionTag,
  NodeExtension,
} from '@remirror/core';
import * as Remirror from '@/types/rich-text';
import { TemplateTarget } from './Node.Template.Target';

@extension({
  defaultOptions: {},
})
export class TemplateTargetExtension extends NodeExtension {

  get name() {
    return Remirror.NodeType.TemplateTarget;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        target: {},
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = TemplateTarget;
}