import { useCallback, useContext, useMemo } from 'react';
import { utils } from '@enums';
import { cx } from '@utils';
import { Switch } from '@/components/Switch';
import { SelectLanguage } from '$admin/Project.Creation/Field.Setup.Language';
import type { OptionsMap } from '$admin/components/Select';
import { SelectUnderlined, fromArray, fromEnum } from '$admin/components/Select';
import { getConferenceTypes, showConferenceMediaType } from '$admin/containers/Call';
import { ProjectCreationSetupContext } from './Context';
import type { Setup as S } from './interfaces';
import styles from './style/Frame.Setup.css';

type Props = unknown;

export const Conference = (props: Props) => {
  const { form, setValue } = useContext(ProjectCreationSetupContext);

  const setConference = useCallback((params: Partial<S.FormState['conference']>) => {
    setValue({
      ...form,
      conference: {
        ...form.conference,
        ...params,
      },
    });
  }, [
    form,
    setValue,
  ]);

  const conference = useConferenceFields({
    conference: form.conference,
  });

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field}>
          <div className={cx(styles.label, { [styles.disabled]: conference.platform.disabled })}>
            Conference Platform
          </div>
          <SelectUnderlined
            disabled={conference.platform.disabled}
            onChange={e => setConference({ platform: +e.target.value })}
            options={conference.platform.options}
            value={form.conference.platform} />
        </div>
        <div className={styles.field}>
          <div className={cx(styles.label, { [styles.disabled]: conference.type.disabled })}>
            Conference Type
          </div>
          <SelectUnderlined
            disabled={conference.type.disabled}
            onChange={e => setConference({ type: +e.target.value })}
            options={conference.type.options}
            value={form.conference.type} />
        </div>
      </div>
      <div className={styles.row}>
        {/* <div className={styles.field}>
          <div className={cx(styles.label, { [styles.disabled]: conference.mediaType.disabled })}>
            Conference Media Type
          </div>
          <SelectUnderlined
            disabled={conference.mediaType.disabled}
            onChange={e => setConference({ mediaType: +e.target.value })}
            options={conference.mediaType.options}
            value={form.conference.mediaType} />
        </div> */}
        <div className={styles.field}>
          <div className={cx(styles.label, { [styles.disabled]: conference.defaultDuration.disabled })}>
            Conference Duration
          </div>
          <SelectUnderlined
            disabled={conference.defaultDuration.disabled}
            onChange={e => setConference({ defaultDuration: +e.target.value })}
            options={conference.defaultDuration.options}
            renderValue={(value: number) => `${value} minutes`}
            value={form.conference.defaultDuration} />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Video Sharing</div>
          <SelectUnderlined
            disabled={conference.videoSharing.disabled}
            onChange={e => setConference({ videoShareTypeId: +e.target.value })}
            options={conference.videoSharing.options}
            value={form.conference.videoShareTypeId} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.field}>
          <div className={styles.label}>Screen Sharing</div>
          <SelectUnderlined
            disabled={conference.screenSharing.disabled}
            onChange={e => setConference({ screenShareTypeId: +e.target.value })}
            options={conference.screenSharing.options}
            value={form.conference.screenShareTypeId} />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Primary Transcription Language</div>
          <SelectLanguage
            defaultValues={form.conference.languages.map(l => l.id)}
            onChange={vals => setConference({ languages: vals })} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.field}>
          <div className={styles.label}>Enhanced Human Transcriptions</div>
          <Switch
            active={form.conference.transcriptionQa}
            onClick={value => setConference({ transcriptionQa: value })} />
        </div>
      </div>
    </>
  );
};

Conference.displayName = 'Field.Setup.Conference';

const useConferenceFields = (params: Pick<S.FormState, 'conference'>) => {
  const fields = useMemo(() => {
    const invalidPlatform = !showConferenceMediaType({
      conferencePlatform: params.conference.platform,
      conferenceType: params.conference.type,
    });

    const conferenceTypes = getConferenceTypes({ conferencePlatform: params.conference.platform });
    const conferenceTypeOptions = conferenceTypes.reduce<OptionsMap>((acc, x) => ({
      ...acc,
      [x]: utils.ConferenceType.getName(x),
    }), {});

    return {
      defaultDuration: {
        disabled: false,
        options: fromArray([15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240]),
      },
      mediaType: {
        disabled: invalidPlatform,
        options: fromEnum(utils.ConferenceMediaType),
      },
      platform: {
        disabled: false,
        options: fromEnum(utils.ConferencePlatform),
      },
      videoSharing: {
        disabled: false,
        options: fromEnum(utils.ConferenceFeatureShareType),
      },
      screenSharing: {
        disabled: false,
        options: fromEnum(utils.ConferenceFeatureShareType),
      },
      type: {
        disabled: false,
        options: conferenceTypeOptions,
      },
    };
  }, [params]);

  return fields;
};