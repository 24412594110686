import { useMemo } from 'react';
import type { IConference } from '@containers/Conference';
import { useTwilioVideo, useConferenceInstance } from '@containers/Conference';

export function useYourParticipant() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const twilio = useTwilioVideo();

  return useMemo(() => {
    return instance.participants.find(p => p.twilioIdentity === twilio.room.localParticipant.identity);
  }, [twilio.room.localParticipant.identity, instance.participants]);
}