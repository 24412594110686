import type { ComponentType } from 'react';
import type { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder/hooks';
import type { SurveyRichText, SurveyTemplate } from '@/types/survey';
import { TemplateCategory as Label } from '@/components/Survey.Templates/Label.Category';

export const TemplateCategory: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const category = (state.survey.template.data as SurveyTemplate.ICategory).category;

  return (
    <Label
      disabled={editing}
      value={category.value} />
  );
}

function Node({ node }: NodeViewComponentProps) {
  const category = (node.attrs as SurveyRichText.CategoryNode['attrs']).category;

  return (
    <Label
      disabled={true}
      value={category} />
  );
}

export default TemplateCategory;