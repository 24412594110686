import { useMemo } from 'react';
import type { SurveyQuestionType } from '@enums';
import { SurveyOptionType } from '@enums';
import { useSurveyFormQuestionContext, useValidateRationale } from './hooks';
import { SurveyFormCanSubmitContext, useSurveyFormQuestionAnswer } from './Context';
import { SurveyFormBackContainer, SurveyFormSubmitContainer } from './BaseNavigation';

type Props =
  ChildrenProps;

export const MultiselectFormContainer = (props: Props) => {
  const [answer] = useSurveyFormQuestionAnswer<SurveyQuestionType.Multiselect>();
  const question = useSurveyFormQuestionContext<SurveyQuestionType.Multiselect>();
  const validateRationale = useValidateRationale();

  const canSubmit = useMemo(() => {
    if (!answer?.items) return false;

    const isRationaleValid = validateRationale();

    if (!isRationaleValid) return false;

    const noneOption = question.options.find(f => f.type === SurveyOptionType.NoneOfTheAbove);
    const noneChecked = answer.items.some(s => s.optionId === noneOption?.id);

    if (noneChecked) return true;

    const otherOptions = question.options.filter(f => f.metadata.isOpenEnded);
    const invalidOpenEnded = otherOptions.some(o => {
      const optionAnswer = answer.items.find(s => s.optionId === o.id);
      return optionAnswer && !optionAnswer.text;
    });

    if (invalidOpenEnded) return false;

    const selectionsValid = hasEnoughSelection();

    if (!selectionsValid) return false;

    return true;

    function hasEnoughSelection() {
      if (question.settings.maximumSelections &&
        answer.items.length > question.settings.maximumSelections) {
        return false;
      }
      if (question.settings.minimumSelections &&
        answer.items.length < question.settings.minimumSelections) {
        return false;
      }

      return true;
    }
  }, [
    answer,
    question.options,
    question.settings,
    validateRationale,
  ]);

  return (
    <SurveyFormCanSubmitContext.Provider value={canSubmit}>
      <SurveyFormBackContainer>
        <SurveyFormSubmitContainer>
          {props.children}
        </SurveyFormSubmitContainer>
      </SurveyFormBackContainer>
    </SurveyFormCanSubmitContext.Provider>
  );
};

export default MultiselectFormContainer;