import { useState } from 'react';
import * as enums from '@enums';
import Button from '@/components/Button';
import { Body } from '@/components/Modal/Body';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import Modal from '@/components/Modal/Modal.Legacy';
import type { Project } from '$admin/interfaces';
import styles from './style/Project.RejectMember.css';

type Props = {
  statusId: enums.UserProjectStatus;
  onCancel: () => unknown;
  onConfirm: (notifyMember: boolean) => unknown;
} & Pick<Project.Members.Item, 'user'>;

export const RejectMemberLegacy = (props: Props) => {
  const [notify, setNotify] = useState<boolean>(false);
  const name = [props.user.firstName, props.user.lastName].join(' ');

  return (
    <Modal onClose={props.onCancel}>
      <Header>{`Reject ${name}?`}</Header>
      <Body>
        Are you sure you want to reject {name} from the project?
        <NotifyByEmail
          name={name}
          statusId={props.statusId}
          notify={notify}
          setNotify={setNotify} />
      </Body>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          onClick={props.onCancel}
          variant="brick">
          Cancel
        </Button>
        <Button
          className={styles.btn}
          color="primary"
          onClick={() => props.onConfirm(notify)}
          variant="brick">
          Confirm
        </Button>
      </ButtonSet>
    </Modal>
  );
};

type NotifyByEmailProps = {
  name: string;
  statusId: enums.UserProjectStatus;
  notify: boolean;
  setNotify: React.Dispatch<React.SetStateAction<boolean>>;
};

function NotifyByEmail({ name, statusId, notify, setNotify }: NotifyByEmailProps) {
  const invalidStatuses = [
    enums.UserProjectStatus.Staging,
    enums.UserProjectStatus.Recommended,
    enums.UserProjectStatus.Added,
  ];

  if (invalidStatuses.includes(statusId)) {
    return null;
  }

  return (
    <label
      className={styles.notify}
      htmlFor="notify">
      <input
        id="notify"
        className={styles.check}
        type='checkbox'
        onChange={_ => setNotify(!notify)}
        checked={notify} />
      Notify {name} by email?
    </label>
  );
}

RejectMemberLegacy.displayName = 'Modal.Project.RejectMemberLegacy';