import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { OptionsMap } from '$admin/components/Select';
import type { NumberTable } from './interfaces';

export type NumberTableWarningContextValue = {
  cell: NumberTable.WarningsMap;
  totals: NumberTable.TotalsWarningsMap;
};

export const NumberTableWarningsContext = createNamedContext<NumberTableWarningContextValue>(undefined, 'NumberTableWarningsContext');
export const NumberTableAnswerMapContext = createNamedContext<NumberTable.AnswerMap>(undefined, 'NumberTableAnswerMapContext');
export const NumberTableColumnTotalsContext = createNamedContext<NumberTable.TotalsMap>(undefined, 'NumberTableColumnTotalsContext');
export const NumberInputDropdownValuesContext = createNamedContext<OptionsMap>(null, 'NumberInputDropdownValuesContext');

export const useNumberTableWarnings = () => useContext(NumberTableWarningsContext);
export const useNumberTableAnswerMap = () => useContext(NumberTableAnswerMapContext);
export const useNumberTableColumnTotals = () => useContext(NumberTableColumnTotalsContext);
export const useNumberInputDropdownValuesContext = () => useContext(NumberInputDropdownValuesContext);