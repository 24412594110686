import { Proposals } from '@/components/Calendar';
import type {
  CalendarState,
  SchedulingProposingComponentProps,
} from './interfaces';

type Props = {
  className?: string;
} & Omit<SchedulingProposingComponentProps, 'schedulerId'>;

const Proposal = (props: Props) => {
  const handleSubmit = (data: CalendarState) => {
    return props.onSubmit({
      new: data.new,
      removed: data.removed,
    });
  };

  const screenProps = {
    call: { id: props.callId },
    with: { id: props.scheduleeId },
  };

  return (
    <Proposals
      call={screenProps.call}
      className={props.className}
      onSubmit={handleSubmit}
      with={screenProps.with} />
  );
};

export { Proposal };
export default Proposal;