import { useAccountProfilePopulateFields } from '@containers';
import { Skip } from '@presentation';
import { cx } from '@utils';
import type { UI } from '@/types';
import { Button } from '@/components/Button';
import { PrepopulatingProfile } from './PrepopulatingProfile';
import styles from './style/PopulateProfile.css';

type Props = {
  redirect: UI.AbstractEffectParam;
};

const PrepopulatePrompt = (props: Props) => {
  const [response, populate] = useAccountProfilePopulateFields(props.redirect);

  if (response.loading) {
    return <PrepopulatingProfile />;
  }

  const skipProps = typeof props.redirect === 'function'
      ? { onClick: props.redirect }
      : { to: props.redirect };

  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, styles.sync)}>
        <div className={styles.main}>
          <div className={styles.header}>{title}</div>
          <div className={styles.body}>
            <div className={styles.text}>{msg1}</div>
            <div className={styles.text}>{msg2}</div>
          </div>
          <div className={cx(styles.footer, styles.column)}>
            <div className={styles.proceed}>
              <Button
                className={styles.btn}
                disabled={response.loading}
                onClick={populate}
                variant="brick">
                Sync Profile
              </Button>
            </div>
            <div className={styles.proceed}>
              <Skip
                className={styles.skip}
                {...skipProps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const title = `Now, let's get your profile set up!`;

const msg1 = `We can automatically enhance your profile with one click so that you're ready for client projects.`;
const msg2 = `Give us permission to pull in your Linkedin employment history and we can pre-populate your profile on Sentiment.`;

export { PrepopulatePrompt };
export default PrepopulatePrompt;