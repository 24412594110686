import http from '@services/http';
import { qs } from '@utils';
import type { Products } from '$admin/api/interfaces';

export const createProduct = (data: Products.CreateProduct.Request): Promise<Products.CreateProduct.Response> => {
  return http.post(`/admin/products`, data);
};

export const findProducts = (data: Products.FetchProducts.Request) => {
  const q = qs.stringify(data);

  return http.get<Products.FetchProducts.Response>(`/admin/products?${q}`);
};
