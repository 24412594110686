import { useCallback, useRef, useState } from 'react';
import cuid from 'cuid';
import * as api from '@api';
import type { ImageResource } from '@/components/URLPreview/crop';
import { ArticleFeedPublisherContext } from './Context';
import { useResolveArticlePreviewImageSrc, useUploadArticlePreviewImage } from './hooks';
import type { ArticleFeedPublisherState, BootstrapFeedPostForm, PublishArticleToFeed, RemirrorParams } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const PostArticleFeedPublisherContainer = (props: Props) => {
  const [state, dispatch] = useState<ArticleFeedPublisherState>(null);
  const identifier = useRef(cuid()).current;
  const src = useRef<string>(null);
  const resolveArticlePreviewImageSrc = useResolveArticlePreviewImageSrc();
  const uploadArticlePreviewImage = useUploadArticlePreviewImage();

  const bootstrapFeedPostForm = useCallback((data: BootstrapFeedPostForm.Params) => {
    dispatch({
      description: data.description,
      postId: data.postId,
      title: data.title,
      topics: data.topics,
    });
  }, [dispatch]);

  const handleArticlePreviewImage = useCallback(async (image: ImageResource) => {
    if (!image) return null;

    const response = await uploadArticlePreviewImage({
      format: image.format,
      height: image.height,
      identifier,
      src: image.url,
      type: image.type,
      width: image.width,
    });

    return response;

  }, [
    identifier,
    uploadArticlePreviewImage,
  ]);

  const publishArticleToFeed = useCallback(async (params: PublishArticleToFeed.Params) => {
    const image = await handleArticlePreviewImage(params.preview.image);

    const preview = {
      description: params.preview.description,
      domain: params.preview.domain,
      image,
      sitename: params.preview.sitename,
      title: params.preview.title,
      url: params.preview.url,
    };

    return api.posts.publishPost({
      body: params.body,
      identifier,
      media: null,
      preview,
      topics: params.topics.map(x => x.id),
    });
  }, [
    handleArticlePreviewImage,
    identifier,
  ]);

  const resolvePreviewImageSrc = useCallback(({ helpers }: RemirrorParams) => {
    const source = resolveArticlePreviewImageSrc({ helpers });

    src.current = source;

    return source;
  }, [resolveArticlePreviewImageSrc]);

  const value = {
    bootstrapFeedPostForm,
    previewImage: src.current,
    publishArticleToFeed,
    resolvePreviewImageSrc,
    state,
  };

  return (
    <ArticleFeedPublisherContext.Provider value={value}>
      {props.children}
    </ArticleFeedPublisherContext.Provider>
  );
};

PostArticleFeedPublisherContainer.displayName = 'Post.Article.FeedPublisher.Container';