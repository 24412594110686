import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppReadyState } from '@containers/AppReadyState/hooks/useAppReadyState';
import { useHasClientRole } from '@containers/User/hooks/useHasClientRole';
import { getLocationFor } from '@utils';
import { PaidPlatformMessage } from '@/components/Modal/PlatformMessage/PaidPlatformMessage';
import { SendMessage } from '@/components/Modal/PlatformMessage/SendMessage';
import { AuthConnectModal } from '$website/components/AuthConnectModal/AuthConnectModal';
import { PaidMessagingContext } from './Context';
import { useAllowMessageModal } from './hooks/useAllowMessageModal';
import type { PaidMessagingRecipientUser } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const PaidMessagingContainer = (props: Props) => {
  const history = useHistory();
  const [recipient, setRecipient] = useState<PaidMessagingRecipientUser>(null);
  const allowModal = useAllowMessageModal();
  const [payment, setPaymentOpen] = useState(false);
  const hasPaymentMethod = false;
  const clientRole = useHasClientRole();

  const app = useAppReadyState();

  const handleClose = useCallback(() => setRecipient(null), [setRecipient]);
  const handlePaymentSetupComplete = useCallback(() => setPaymentOpen(false), []);

  const handleAction = useCallback((user: PaidMessagingRecipientUser) => {
    const userId = user.id;

    return app.hydrated && !allowModal(userId)
      ? history.push(getLocationFor.messages.root({ userId }))
      : setRecipient(user);

  }, [
    allowModal,
    app.hydrated,
    history,
    setRecipient,
  ]);

  const auth = (!app.authenticated || app.hydrating) && !!recipient;
  const setup = app.hydrated && !!recipient && (!hasPaymentMethod || payment);
  const messaging = app.hydrated && !!recipient && !setup;

  return (
    <PaidMessagingContext.Provider value={[recipient, handleAction]}>
      {props.children}
      <div onClick={e => e.stopPropagation()}>
        {auth &&
          <AuthConnectModal
            recipient={recipient}
            open={auth}
            onClose={handleClose} />}

        {/* {setup &&
          <PaymentsSetup.BankCard
            open={setup}
            onClose={handleClose}
            onComplete={handlePaymentSetupComplete} />} */}

        {(messaging && !clientRole) &&
          <PaidPlatformMessage
            open={messaging}
            onClose={handleClose}
            onComplete={handleClose}
            user={recipient} />}

        {(messaging && clientRole) &&
          <SendMessage
            onClose={handleClose}
            user={recipient}
            visible={messaging} />}
      </div>
    </PaidMessagingContext.Provider>
  );
};

PaidMessagingContainer.displayName = 'PlatformMessage.PaidMessagingContainer';