import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import { QuestionSettingTitle } from '@/presentation/SurveyBuilder';
import type { SurveySettings } from '@/types/survey';
import { toTitleCase } from '@utils';
import { DropDown } from '@/components/DropDown';
import styles from './style/Settings.MatrixDisplay.css';

type Props = {
  className?: string;
  value: SurveySettings.MatrixGroupByValue;
};

export const MatrixGroupBySetting = ({ className, value }: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItem();

  const handleSelect = useCallback((value: SurveySettings.MatrixGroupByValue) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        groupBy: value,
      },
    });
  }, [
    dispatch,
    item.settings,
    item.base.identifier,
  ]);

  return (
    <div className={className}>
      <QuestionSettingTitle title="Require Answer for each" />
      <div className={styles.dropdown}>
        <MatrixGroupByDropdown
          onSelect={handleSelect}
          value={value} />
      </div>
    </div>
  );
};

type DropdownProps = {
  onSelect: (value: SurveySettings.MatrixGroupByValue) => void;
  value: SurveySettings.MatrixGroupByValue;
};

type DropdownItem = {
  id: SurveySettings.MatrixGroupByValue;
  value: string;
};

const MatrixGroupByDropdown = ({ onSelect, value }: DropdownProps) => {

  const items: DropdownItem[] = useMemo(() => {
    return [
      { id: 'row', value: 'Row' },
      { id: 'column', value: 'Column' },
    ];
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  return (
    <DropDown<DropdownItem>
      items={items}
      onSelect={handleSelect}
      text={value ? toTitleCase(value) : ''}
      getItemValue={item => item.value} />
  );
};