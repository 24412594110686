import { useCallback, useMemo } from 'react';
import { useNumberTableWarnings } from '@containers/SurveyForm/Context';
import { getOperatorText } from '@utils/operator';
import type { NumberInputTableQuestion } from '@/types/survey';
import styles from './style/NumberInputTable.css';

type Props = {
  item: NumberInputTableQuestion.FormQuestion;
};

export const NumberInputTableWarnings = ({ item }: Props) => {

  const warnings = useNumberTableWarnings();

  const showTotalsWarnings = useMemo(() => {
    return Object.values(warnings.totals).some(s => !!s);
  }, [warnings.totals]);

  const showCellWarnings = useMemo(() => {
    return item.matrixRows.reduce((acc, row) => {
      return acc || item.options.reduce((acc2, option) => {
        return acc2 || warnings.cell[row.id][option.id];
      }, false);
    }, false);
  }, [
    item.options,
    item.matrixRows,
    warnings.cell,
  ]);

  const renderWarnings = useCallback((items: string[]) => {
    if (!items.length) return null;
    return (
      <div className={styles.warnings}>
        {items.map((w, i) => (
          <div
            key={i}
            className={styles.warning}>
            {w}
          </div>
        ))}
      </div>
    );
  }, []);

  const renderCellWarnings = useCallback(() => {
    if (!showCellWarnings) return null;

    const items: string[] = [];

    if (!item.settings.allowEmptyValues) {
      items.push('All values must be filled out');
    }

    if (item.settings.dropdowns.enabled) {
      return renderWarnings(items);
    }

    const { minValue, maxValue } = item.settings.validations;

    if (minValue !== null && maxValue === null) {
      items.push(`Answers must be greater than or equal to ${minValue}`);
    }

    if (minValue === null && maxValue !== null) {
      items.push(`Answers must be less than or equal to ${maxValue}`);
    }

    if (minValue !== null && maxValue !== null) {
      items.push(`Answers must be greater than or equal to ${minValue} and less than or equal to ${maxValue}`);
    }

    return renderWarnings(items);
  }, [
    item.settings,
    renderWarnings,
    showCellWarnings,
  ]);

  const renderTotalsWarning = useCallback(() => {
    if (!showTotalsWarnings) return null;

    const operatorText = getOperatorText(item.settings.total.operator);

    const warnings = `All column totals must be ${operatorText} to ${item.settings.total.comparate}`;

    return renderWarnings([warnings]);
  }, [
    item.settings.total,
    renderWarnings,
    showTotalsWarnings,
  ]);

  return (
    <>
      {renderCellWarnings()}
      {renderTotalsWarning()}
    </>
  );
};