import { SurveyLogicRule } from '@/enums';
import { Remirror } from '@/types';
import type { SurveyLogicExpression } from '@/types/survey.logic.expression';
import { SurveyLogicExpressionNode } from '@/types/survey.logic.expression';
import type { ConditionsBuilder, SurveyMatchBuilder, RuleBuilder } from './interfaces';

type State = RuleBuilder.State;

export function surveyRuleReducer(state: State, action: SurveyMatchBuilder.Action): State {
  switch (action.type) {

    case 'rule-type-updated':
      return {
        ...state,
        expression: null,
        type: action.payload.value,
      };

    case 'rule-expression-updated':
      return {
        ...state,
        expression: action.payload.value,
      };

    // side effects
    case 'condition-removed':
      return conditionRemoved(state, action);

    default:
      return state;
  }
}

function conditionRemoved(state: State, action: ConditionsBuilder.ConditionRemoved.Action): State {
  if (state.type !== SurveyLogicRule.CustomExpression) return state;
  if (!state.expression?.content) return state;

  const newContent = state.expression.content.reduce<SurveyLogicExpression.ParagraphNode[]>((acc, x) => {
    return acc.concat({
      type: x.type,
      content: x.content.filter(f => {
        if (f.type === SurveyLogicExpressionNode.Condition) {
          return f.attrs.identifier !== action.payload.identifier;
        }
        return true;
      }),
    });
  }, []);

  const newDoc: SurveyLogicExpression.RichTextValue = {
    type: Remirror.NodeType.Doc,
    content: newContent,
  };

  return {
    type: SurveyLogicRule.CustomExpression,
    expression: newDoc,
  };
}