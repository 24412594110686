import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';
import * as SetupFields from '$admin/Project.Creation/Field.Setup';
import { Display } from './Field.Select';
import styles from './style.css';

type Props = {
  displayValue: React.ReactChild;
  editing:      boolean;
  label:        string;
  name:         string;
  options:      Descriptor[];
  placeholder?: string;
};

export const FieldSelectSearch = (props: Props) => {
  const { setFieldValue } = useFormikContext();

  if (!props.editing) {
    return (
      <Display
        displayValue={props.displayValue}
        label={props.label} />
    );
  }

  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <div>
          <div className={styles.label}>
            {props.label} {meta.touched && meta.error && <span className={styles.formError}>{meta.error}</span>}
          </div>
          <div className={styles.value}>
            <SetupFields.SelectSearch
              name={props.name}
              onBlur={field.onBlur}
              onChange={x => setFieldValue(props.name, x.id)}
              options={props.options}
              placeholder="Assignee Name"
              value={(field.value ?? undefined) as number} />
          </div>
        </div>)}
    </Field>
  );
};

FieldSelectSearch.displayName = 'Field.SelectSearch';