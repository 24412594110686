import { useCallback } from 'react';
import type { ReactEditor } from 'slate-react';
import { Editable, Slate } from 'slate-react';
import type { Node } from 'slate';
import { cx } from '@utils';
import { useSlateEditor } from '@/screens/Conference.Room.Video/hooks';
import * as RichText from '@/components/RichText';
import ActivityIndicator from '../ActivityIndicator';
import { PanelToolsHeader } from './Pane.Header';
import { useConferenceNotes } from './hooks';
import styles from './style/Pane.Notes.css';

type Props = {
  className?: string;
  callId: number;
  toggleNotes: () => void;
  popOutNotes: () => void;
};

export const Notes = ({ callId, toggleNotes, popOutNotes, ...props }: Props) => {
  const { notes, query, update } = useConferenceNotes(callId);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <PanelToolsHeader
          onClickMinimize={toggleNotes}
          onClickPopout={popOutNotes}
          title="Notes" />
        <div className={styles.main}>
          {query.isFetching ?
            <ActivityIndicator /> :
            <NotesEditor
              value={notes}
              onChange={value => update(value)} />
          }
        </div>
      </div>
    </div>
  );
};

type NotesEditorProps = {
  value: Node[];
  onChange: (value: Node[]) => void;
};
const NotesEditor = ({ value, onChange }: NotesEditorProps) => {
  const editor = useSlateEditor();
  return (
    <Editor
      editor={editor}
      value={value}
      onChange={onChange} />
  );
};

Notes.displayName = 'Pane.Notes';

type EditorProps = {
  editor: ReactEditor;
  onChange: (value: Node[]) => void;
  value: Node[];
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export const Editor = (props: EditorProps) => {
  const renderElement = useCallback(props => <RichText.Element {...props} />, []);
  const renderLeaf = useCallback(props => <RichText.Leaf {...props} />, []);

  const handleOnChange = (value: Node[]) => {
    props.onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (RichText.isHotkey(hotkey, event.nativeEvent)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
        RichText.toggleMark(props.editor, mark);
      }
    }
  };

  return (
    <div className={styles.editor}>
      <Slate
        editor={props.editor}
        onChange={handleOnChange}
        value={props.value || RichText.emptyValue}>
        <div className={styles.editable}>
          <Editable
            style={{ height: '100%' }}
            onKeyDown={handleKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={`Type notes on the call...`}
            spellCheck />
        </div>
        <div className={styles.tools}>
          <RichText.MarkButton className={styles.icon} format='bold' />
          <RichText.MarkButton className={styles.icon} format='italic' />
          <RichText.MarkButton className={styles.icon} format='underline' />
          <RichText.BlockButton className={styles.icon} format='bulleted-list' />
        </div>
      </Slate>
    </div>
  );
};

Editor.displayName = 'Notes.Editor';