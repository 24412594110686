import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import * as api from '@api';

type Data = API.Projects.FetchSurveyResponses.Response;
type QueryKey = [string, Props];
type Props = {
  limit?: number;
  projectId: number;
  surveyId: number;
  questionId?: number;
};

export function useFetchProjectSurveyResponses<TData = Data>(
  props: Props,
  options?: UseQueryOptions<Data, AxiosResponse, TData, QueryKey>
) {

  return useQuery({ queryKey: keyUseFetchSurveyResponses(props), queryFn: ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.projects.surveys.fetchResponses(params);
  }, ...options });
}

function keyUseFetchSurveyResponses(props: Props) {
  return ['project-survey-responses', props];
}

export default useFetchProjectSurveyResponses;