import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Users } from '@api/interfaces';

type Data = Users.GetSessions.Response;

export function useFetchSessions<TData = Data>(
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery({ queryKey: ['auth-sessions'], queryFn: () => {
    return api.users.getSessions();
  }, ...options });
}