import LockIcon from '@mui/icons-material/Lock';
import { TranscriptionRevisionStatus, utils } from '@enums';
import { cx } from '@utils';
import type { TableCellItem } from './interfaces';
import styles from './style/Table.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {
  const inprogress = props.row.original.enhancement.statusId === TranscriptionRevisionStatus.InProgress;

  const classnames = cx(styles.status, {
    [styles.inprogress]: inprogress,
  });

  return (
    <div className={classnames}>
      <div className={styles.icon}>
        {inprogress && <LockIcon />}
      </div>
      <div className={styles.text}>
        {utils.TranscriptionRevisionStatus.getName(props.row.original.enhancement.statusId)}
      </div>
    </div>
  );
};

Cell.displayName = 'Column.Status.Cell';

export const Header = (props: unknown) => {
  return (
    <div className={cx(styles.status, styles.header)}>
      Status
    </div>
  );
};

Header.displayName = 'Column.Status.Header';