import { useMutation } from '@tanstack/react-query';
import cuid from 'cuid';
import { uploadStimSequenceAttachment } from '@api/surveys';
import type { SurveyAIEBuilder } from '@/containers/SurveyBuilder';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import Toast from '@/components/Toast';

type Params = {
  exerciseIdentifier: string;
  groupIdentifier?: string;
};

type Variables = {
  blob: Blob;
};

export const useUploadAIEAttachmentMutation = ({ exerciseIdentifier, groupIdentifier }: Params) => {
  const [state, dispatch] = useSurveyBuilderState();

  return useMutation({ mutationKey: ['upload-stim-sequence-attachment'], mutationFn: (data: Variables) => {
    return uploadStimSequenceAttachment(data);
  }, onSuccess: results => {
    const actions: SurveyAIEBuilder.Action[] = [];

    const exercise = state.survey.alternateImageExercises.find(e => e.identifier === exerciseIdentifier);

    for (const r of results.results) {
      actions.push({
        type: 'aie-group-added',
        exerciseIdentifier,
        entries: [{
          ordinal: 1,
          imageUrl: r.url,
          identifier: cuid(),
          name: `Page ${r.pageNum}`,
        }],
        name: `P-${r.pageNum}`,
      });
    }

    //If we initiated this from a group, remove that group if it doesn't have any entries
    if (groupIdentifier) {
      const group = exercise.groups.find(g => g.identifier === groupIdentifier);

      if (!group?.entries?.length) {
        actions.push({
          type: 'aie-group-removed',
          exerciseIdentifier,
          groupIdentifier,
        });
      }
    }
    dispatch({
      type: 'batch-actions',
      actions,
    });
  }, onError: () => {
    Toast.error({
      title: 'There was an error uploading the attachments',
    });
  } });
};