import { useState } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import { useAsyncStateLazy } from '@utils';
import type { Search } from '@/types';
import { SearchQueryContext, SearchQueryDispatchContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const SearchQueryContainer = (props: Props) => {
  const [state, dispatch] = useState<Search.QueryState>(SearchQueryContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async (data: API.Search.FetchIndividualQueryResults.Request) => {

    const res = await api.search.fetchIndividualQueryResults(data);

    const items = data.searchAfter
        ? state.items.concat(res.items)
        : res.items;

    const value: Search.QueryState = {
      items,
      new: !data.searchAfter,
      total: res.total,
    };

    dispatch(value);

    return value;

  }, [
    dispatch,
    state,
  ]);

  const value = {
    loading: response.loading,
    value: state,
  };

  return (
    <SearchQueryDispatchContext.Provider value={dispatch}>
      <SearchQueryContext.Provider value={[value, fetch]}>
        {props.children}
      </SearchQueryContext.Provider>
    </SearchQueryDispatchContext.Provider>
  );
};

const initial: Search.QueryState = {
  items: [],
  new: false,
  total: 0,
};

SearchQueryContainer.initialState = initial;

export { SearchQueryContainer };
export default SearchQueryContainer;