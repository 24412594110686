import { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as consts from '@consts';
import { useSelectAdHocCall } from '@containers/Store';
import { useAdHocCallSchedulingNotifier } from '@containers/Scheduler/Notifier';
import { CallStatus } from '@enums';
import { Proposal, ProposalResponsive } from '@screens/Scheduling';
import { device, qs } from '@utils';
import { useProposeAdHocCallTimes } from '@utils/api';
import type { CalendarState } from '@/types';

type RouteParams = {
  callId: string;
};

type RouteState = {
  schedulerId: number;
  scheduleeId: number;
};

const AdHocCallProposals = () => {
  const params = useParams<RouteParams>();
  const { state } = useLocation<RouteState>();
  const history = useHistory();
  const notifier = useAdHocCallSchedulingNotifier();
  const callId = +params.callId;
  const [call] = useState(useSelectAdHocCall(callId));

  const { mutateAsync, ...propose } = useProposeAdHocCallTimes({ callId }, {
    onSuccess: res => {
      if (call.statusId === CallStatus.Scheduled) {
        notifier.rescheduling.success(callId);
      } else {
        notifier.proposal.success(callId);
      }

      history.replace(qs.getParam('from') || consts.pathname.HOME);
    },
    onError: e => {
      notifier.proposal.error();
    },
  });

  const handleSubmit = useCallback((data: CalendarState) => {
    return mutateAsync({
      new: data.new,
      removed: data.removed,
    });
  }, [mutateAsync]);

  const Screen = device.phone
      ? ProposalResponsive
      : Proposal;

  return (
    <Screen
      callId={callId}
      onSubmit={handleSubmit}
      scheduleeId={state.scheduleeId} />
  );
};

export { AdHocCallProposals };
export default AdHocCallProposals;