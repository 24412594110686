import { useMemo } from 'react';
import { useEditorState } from '@remirror/react';
import type { CommentDocument } from '@/types/transcript.rich-text.comments';

export const useCommentEditorContent = () => {

  const state = useEditorState();

  return useMemo(() => {
    const content = state.doc.content.toJSON() as CommentDocument.RootNode['content'];

    return content.reduce((acc, x) => {

      if (!x.content) return acc;

      const { mentions, text } = x.content.reduce((acc2, node) => {
        if (node.type === 'mentionAtom') {
          return {
            ...acc2,
            mentions: acc2.mentions.concat(node.attrs.id),
          };
        } else if (node.type === 'text') {
          return {
            ...acc2,
            text: acc2.text.concat(node.text),
          };
        }
      }, {
        mentions: [] as string[],
        text: '',
      });

      return {
        mentions: acc.mentions.concat(mentions),
        text: acc.text.concat(text),
      };

    }, {
      mentions: [] as string[],
      text: '',
    });
  }, [state.doc]);

};