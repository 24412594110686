import http from '@services/http';
import type { Settings } from './interfaces';

export const updateEmailSettings = (data: Settings.UpdateEmailSettings.Request): Promise<Settings.UpdateEmailSettings.Response> => {
  return http.patch(`/settings/email`, data);
};

export const updateSMSSettings = (data: Settings.UpdateSMSSettings.Request): Promise<Settings.UpdateSMSSettings.Response> => {
  return http.patch(`/settings/sms`, data);
};

export * as unsubscribe from './settings.unsubscribe';