import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Select, { createFilter, type ActionMeta, type MenuPlacement, type OnChangeValue } from 'react-select';
import * as api from '@api';
import { createSelectStyles, createSelectTheme } from './select-styles';

type Props = {
  classes?: {
    container?: string;
    control?: string;
    input?: string;
    menu?: string;
    option?: string;
    singleValue?: string;
    valueContainer?: string;
  };
  className?: string;
  isInvalid?: boolean;
  menuPlacement?: MenuPlacement;
  onChange:   (newValue: OnChangeValue<Item, false>, actionMeta: ActionMeta<Item>) => unknown;
  value:      string;
};

export const StateOfPractice = ({ classes = {}, ...props }: Props) => {
  const query = useQuery({ queryKey: [`get:search/states`], queryFn: () => {
    return api.search.fetchStates()
    .then(res => {
      return res.items.map(x => ({
        code: x.code,
        value: x.name,
      }));
    });
  }, refetchOnWindowFocus: false, staleTime: 60 * 60 * 1000 });

  const filterOption = useCallback((option: FilterOptionOption<Item>, rawInput: string) => {
    const defaultFilter = createFilter<Item>({
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: 'start',
    });

    const countryCodeFilter = (option: FilterOptionOption<Item>, input: string) => {
      if (input) {
        return option.data.code.toLowerCase() === input.toLowerCase();
      }
      return true;
    };

    return defaultFilter(option, rawInput) || countryCodeFilter(option, rawInput);
  }, []);

  const selectStyles = useMemo(() => createSelectStyles<Item>({ isInvalid: props.isInvalid }), [props.isInvalid]);
  const selectTheme = useMemo(() => createSelectTheme(), []);

  return (
    <Select<Item>
      className={props.className}
      classNames={{
        container: () => classes.container,
        control: () => classes.control,
        input: () => classes.input,
        menu: () => classes.menu,
        option: () => classes.option,
        valueContainer: () => classes.valueContainer,
        singleValue: () => classes.singleValue,
      }}
      defaultValue={query.data?.find(i => i.value === props.value)}
      value={query.data?.find(i => i.value === props.value)}
      menuPlacement={props.menuPlacement}
      options={query.data || []}
      getOptionLabel={o => `${o.value} (${o.code})`}
      getOptionValue={o => o.value}
      noOptionsMessage={v => `State not found`}
      placeholder="Select state"
      onChange={props.onChange}
      filterOption={filterOption}
      styles={selectStyles}
      theme={selectTheme} />
  );
};

StateOfPractice.displayName = 'MedicalProfile.Field.StateOfPractice';

type Item = {
  code: string;
  value: string;
};

type FilterOptionOption<D> = {
  label: string;
  value: string;
  data: D;
};