import { useCallback, useContext, useMemo } from 'react';
import { type CellProps, type Column as RTColumn, type UsePaginationInstanceProps, useTable, useFlexLayout, usePagination } from 'react-table';
import { NumberParam, type QueryParamConfig, decodeString, encodeString, useQueryParams, withDefault } from 'use-query-params';
import * as Table from '@/components/Table';
import { QueryContainer, LeadsResultsContext, QueryParamsContext, SelectedStateContainer, RemoveLeadsContainer } from '$admin/Search/Leads/containers';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import { Actions } from '$admin/Search/Leads/Dashboard.Toolbar.Actions';
import { SearchFilter } from '$admin/Search/Leads/Dashboard.Toolbar.Search';
import type { CellItem } from '$admin/Search/Leads/interfaces';
import type { Lead } from '$admin/interfaces/leads';
import * as Column from './Column';
import styles from './style/Search.Leads.css';

type Props = unknown;

export const SearchLeads = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    name: TextInputConfig,
    title: TextInputConfig,
    size: withDefault(NumberParam, 25),
    statusId: withDefault(NumberParam, 0),
  });

  return (
    <QueryParamsContext.Provider value={[qp, setQuery]}>
      <QueryContainer source="GHAB">
        <SelectedStateContainer>
          <RemoveLeadsContainer>
            <div className={styles.root}>
              <div className={styles.wrap}>
                <div className={styles.header}>
                  <div className={styles.tools}>
                    <div className={styles.filters}>
                      <SearchFilter className={styles.inputs} />
                      <Column.Status.Filter />
                    </div>
                    <div className={styles.actions}>
                      <Actions source="GHAB" />
                    </div>
                  </div>
                </div>
                <div className={styles.main}>
                  <ResultsTable />
                </div>
              </div>
            </div>
          </RemoveLeadsContainer>
        </SelectedStateContainer>
      </QueryContainer>
    </QueryParamsContext.Provider>
  );
};

SearchLeads.displayName = 'Search.Leads';

const TextInputConfig: QueryParamConfig<string> = {
  encode: (val: string) => {
    return typeof val === 'string' && !val.length
      ? encodeString(undefined)
      : encodeString(val);
  },
  decode: (val: string) => decodeString(val),
};

const ResultsTable = (props: Props) => {
  const query = useContext(LeadsResultsContext);
  const [qp, setQuery] = useContext(QueryParamsContext);
  const columns = useMemo<RTColumn<Lead>[]>(() => [
    {
      Cell: Column.Checkbox.Cell,
      Header: Column.Checkbox.Filter,
      id: 'checkbox',
      minWidth: 50,
      maxWidth: 50,
      width: 50,
    },
    {
      Cell: Column.Name.Cell,
      Header: `Name`,
      id: `name`,
      width: 100,
    },
    /* {
      Cell: (data: CellProps<CellItem>) => (
        <div
          className={styles.trunc}
          title={data.row.original.emailAddress}>
          {data.row.original.emailAddress}
        </div>
      ),
      Header: `Email`,
      id: `email`,
      width: 100,
    }, */
    {
      Cell: (data: CellProps<CellItem>) => (
        <div
          className={styles.trunc}
          title={data.row.original.currentTitle}>
          {data.row.original.currentTitle}
        </div>
      ),
      Header: `Title`,
      accessor: `title`,
      id: `title`,
      width: 80,
    },
    {
      Header: `Company`,
      accessor: `company`,
      Cell: (data: CellProps<CellItem>) => (
        <div
          className={styles.trunc}
          title={data.row.original.currentCompany}>
          {data.row.original.currentCompany}
        </div>
      ),
      id: `company`,
      width: 80,
    },
    {
      Header: `Tags`,
      Cell: Column.Tags.Cell,
      id: `tags`,
      width: 80,
    },
    {
      Header: `Notes`,
      Cell: Column.Notes.Cell,
      id: `notes`,
      width: 80,
    },
  ], []);

  const params = {
    columns,
    data: query.data?.items ?? [],
    initialState: {
      pageIndex: 0,
      pageSize: 25,
    },
    manualPagination: true,
    pageCount: query.data?.pagination?.pageCount ?? 0,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
    setPageSize,
  } = useTable<Lead>(
    params,
    useFlexLayout,
    usePagination,
  );

  const nextPage = useCallback(() => {
    setQuery({ index: qp.index + 1 }, 'pushIn');
  }, [
    qp.index,
    setQuery,
  ]);

  const previousPage = useCallback(() => {
    const prevIndex = qp.index - 1;
    const index = prevIndex > 0
      ? prevIndex
      : undefined;
    setQuery({ index }, 'pushIn');
  }, [
    qp.index,
    setQuery,
  ]);

  const canPreviousPage = qp.index > 0;
  const canNextPage = qp.index + 1 < (query.data?.pagination?.pageCount ?? 0);

  const paginationProps: PaginationProps = {
    canNextPage,
    canPreviousPage,
    nextPage,
    pageCount: query.data?.pagination?.pageCount ?? 0,
    pageIndex: qp.index,
    pageSize: qp.size,
    previousPage,
    pageOptions,
    setPageSize,
  };

  return (
    <Table.Layout.Box>
      <Table.Root
        empty={false}
        loading={false}>
        <Table.Header
          classes={{
            thead: styles.thead,
            tr: styles.tr,
          }}
          headerGroups={headerGroups} />
        <Table.Body
          {...getTableBodyProps()}
          classes={{ td: styles.td }}
          prepareRow={prepareRow}
          rows={rows} />
        <Table.Layout.Footer>
          <Pagination {...paginationProps} />
        </Table.Layout.Footer>
      </Table.Root>
    </Table.Layout.Box>
  );
};

ResultsTable.displayName = 'Search.Leads.ResultsTable';

type PaginationProps = {
  pageIndex:        number;
  pageSize:         number;
  pageSizeOptions?: number[];
} & Pick<UsePaginationInstanceProps<Lead>,
    | 'canNextPage'
    | 'canPreviousPage'
    | 'nextPage'
    | 'previousPage'
    | 'pageCount'
    | 'pageOptions'>
  & Partial<Pick<UsePaginationInstanceProps<Lead>,
    | 'setPageSize'>>;