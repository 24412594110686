import { memo } from 'react';
import { Image } from 'react-feather';
import type { FileIconProps } from './interfaces';

export const ImageIcon = memo((props: FileIconProps) => {

  return (
    <Image
      color="var(--pri-05)"
      {...props} />
  );
});

export default ImageIcon;