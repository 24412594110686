import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Posts } from '@/types';

type Data =
  & Posts.Post
  & Posts.AdditionalDetails;

export default function useFetchPost<TData = Data>(
  postId: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery({ queryKey: ['post', postId], queryFn: async () => {
    const results = await api.posts.getPost({
      postId,
    });

    return results;
  }, ...options });
}

export { useFetchPost };