import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api/scheduling.ad-hoc';
import type * as Scheduling from '@api/interfaces/scheduling.ad-hoc';
import * as actions from '@store/actions';

type Data = Scheduling.AcceptCallRequest.Response;
type Vars = Omit<Scheduling.AcceptCallRequest.Request, 'callId'>;

export function useAcceptAdHocCallRequest(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: Vars, ctx) => {
    dispatch(actions.adHocCallUpdated({
      call: res.call,
    }));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation({ mutationKey: keyAdHocCallAccept(data.callId), mutationFn: async vars => {

    return api.adHocCall.accept({
      callId: data.callId,
      new: vars.new,
      removed: vars.removed,
    });

  }, ...options, onSuccess });
}

export function keyAdHocCallAccept(callId: number) {
  return ['accept-ad-hoc-call-request', callId];
}