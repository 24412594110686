import type { MaxDifferenceQuestion } from '@/types';
import Options from './Options';
import { SurveySettings } from './Settings';

type Props = {
  item: MaxDifferenceQuestion.Question;
};

export default function MaxDiffQuestion({ item }: Props) {
  return (
    <>
      <Options item={item} />
      <SurveySettings>
        {<>Attributes per set: {item.settings.optionsPerSet}</>}
        {<>Sets: {item.settings.sets}</>}
      </SurveySettings>
    </>
  );
}

export { MaxDiffQuestion };

