import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type { Topics } from '@api/interfaces';

type Data = Topics.FollowTopic.Response;
type Vars = {
  follow: boolean;
};

export default function useFollowTopic(
  slug: string,
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation({ mutationKey: ['follow-topic', slug], mutationFn: ({ follow }) => {
    if (follow) {
      return api.topics.followTopic({ slug });
    } else {
      return api.topics.unfollowTopic({ slug });
    }
  }, ...options });
}

export { useFollowTopic };