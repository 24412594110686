import { useCallback, useMemo } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import * as survey from '@containers/Survey/utils';
import * as surveyForm from '@containers/SurveyForm/utils';
import { cx, roundToPlace, useDebounceCallback } from '@utils';
import type { MatrixSliderQuestion } from '@/types';
import { SurveyQuestionNumberType } from '@/types';
import SurveyMatrixSliderHeader from './Header';
import useSliderTextAnswers from './hooks/useSliderTextAnswers';
import useSliderValidation from './hooks/useSliderValidation';
import type { SurveyMatrixSlidersProps } from './interfaces';
import { MatrixSliderRow } from './Row';
import styles from './style/SurveyMatrixSliders.css';

export default function SurveyMatrixSliders({ answer, question, rows, updateRowAnswer, updateRowText }: Props) {
  const [textAnswers, setTextAnswers] = useSliderTextAnswers(answer);
  const validationMessage = useSliderValidation(answer, question);

  const settings = useMemo(() => {
    const increment = survey.questions.isSliderReversed(question.settings)
      ? -question.settings.slider.increment
      : question.settings.slider.increment;
    return {
      ...question.settings,
      slider: {
        ...question.settings.slider,
        increment,
      },
    };
  }, [question]);

  const runningTotal = useMemo(() => {
    return surveyForm.matrixSlider.getRunningTotal(answer);
  }, [answer]);

  const formatPctOfTotal = useCallback((value: number) => {
    if (value === 0) return 0;

    const total = question.settings.slider.total?.comparate
      ? question.settings.slider.total?.comparate
      : runningTotal;

    if (total === 0) return 0;

    const pct = (value || 0) / total * 100;

    return roundToPlace(pct, 1);
  }, [question.settings.slider, runningTotal]);

  const renderHeader = useCallback(() => {
    if (settings.slider.hideSlider) return null;

    return (
      <div className={styles.header}>
        <div className={styles.label} />
        <div className={styles.valueHeader} />
        <SurveyMatrixSliderHeader
          className={styles.headerContent}
          settings={settings} />
      </div>
    );
  }, [settings]);

  const updateSliderFromInput = useDebounceCallback(useCallback((rowId: number, value: number) => {
    updateRowAnswer(rowId)(value);
  }, [updateRowAnswer]), 100);

  const handleInputChange = useCallback((rowId: number) => (value: NumberFormatValues) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: value.value }));

    const casted = parseFloat(value.value.length ? value.value : `${getMidValue(settings.slider)}`);
    if (!isNaN(casted)) {
      const validated = validateSliderValue(casted, settings.slider);
      updateSliderFromInput(rowId, validated);
    }
  }, [settings, setTextAnswers, updateSliderFromInput]);

  const handleBlur = useCallback((rowId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value.length && rowId in textAnswers) {
      const mid = getMidValue(settings.slider);
      updateRowAnswer(rowId)(mid);
      setTextAnswers(prev => ({ ...prev, [rowId]: `${mid}` }));
    } else if (value.length) {
      const rowAnswer = answer.items.find(a => a.matrixRowId === rowId);
      const value = rowAnswer?.value?.toString() ?? '';

      setTextAnswers(prev => ({
        ...prev,
        [rowId]: value,
      }));
    }
  }, [answer.items, settings, setTextAnswers, textAnswers, updateRowAnswer]);

  const handlePositionChange = useCallback((rowId: number) => (position: number) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: `${position ?? ''}` }));
    updateRowAnswer(rowId)(position);
  }, [setTextAnswers, updateRowAnswer]);

  const renderRow = useCallback((row: MatrixSliderQuestion.Row) => {
    const item = answer.items.find(f => f.matrixRowId === row.id);

    return (
      <MatrixSliderRow
        key={row.ordinal}
        row={row}
        settings={settings}
        answerItem={item}
        textAnswer={textAnswers[row.id] ?? ''}
        onBlur={handleBlur(row.id)}
        onInputChange={handleInputChange(row.id)}
        onPositionChange={handlePositionChange(row.id)}
        pctOfTotal={formatPctOfTotal(item?.value)}
        updateRowText={updateRowText(row.id)} />
    );
  }, [
    answer,
    settings,
    textAnswers,
    handleBlur,
    handleInputChange,
    handlePositionChange,
    formatPctOfTotal,
    updateRowText,
  ]);

  const renderTotal = useCallback(() => {
    const showTotal = settings.slider.ensureAnswerTotalEqualsMax || settings.slider.total.comparate !== null;

    if (!showTotal) return null;

    const unit = settings.numberSettings.type === SurveyQuestionNumberType.Unit
      ? settings.numberSettings.unitType
      : '';

    const footerClassName = cx(styles.footer, {
      [styles.extendedFooter]: settings.slider.displayPctOfTotal,
      [styles.noSlider]: settings.slider.hideSlider,
    });

    return (
      <div className={footerClassName}>
        <div className={styles.label}>Total</div>
        <div className={styles.totalRow}>
          <div className={styles.total}>{runningTotal}{unit}</div>
          {settings.slider.displayPctOfTotal &&
            <div className={styles.pct}>({formatPctOfTotal(runningTotal)}%)</div>}
        </div>
      </div>
    );
  }, [
    formatPctOfTotal,
    runningTotal,
    settings,
  ]);

  return (
    <div className={styles.root}>
      {renderHeader()}
      {rows.map(renderRow)}
      {renderTotal()}
      {validationMessage &&
        <div className={styles.validation}>{validationMessage}</div>
      }
    </div>
  );
}

function getMidValue(settings: GetMidValue) {
  if (settings.minValue !== null && settings.maxValue !== null && settings.increment) {
    const increments = Math.abs((settings.maxValue - settings.minValue) / settings.increment);
    return settings.minValue + (settings.increment * Math.ceil(increments / 2));
  }

  return 0;
}

type GetMidValue =
  Pick<MatrixSliderQuestion.SliderSettings, 'minValue' | 'maxValue' | 'increment'>;

function validateSliderValue(value: number, settings: ValidateSliderValue): number {
  if (settings.minValue === null && settings.maxValue === null) return +value.toFixed(2);
  if (settings.minValue !== null && settings.maxValue === null) return +Math.max(settings.minValue, value).toFixed(2);
  if (settings.minValue === null && settings.maxValue !== null) return +Math.min(settings.maxValue, value).toFixed(2);

  return settings.minValue > settings.maxValue
    ? +Math.max(settings.maxValue, Math.min(settings.minValue, value)).toFixed(2)
    : +Math.min(settings.maxValue, Math.max(settings.minValue, value)).toFixed(2);
}

type ValidateSliderValue =
  Pick<MatrixSliderQuestion.SliderSettings, 'minValue' | 'maxValue'>;

type Props =
  SurveyMatrixSlidersProps;

export { SurveyMatrixSliders };