import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as api from '@api';
import * as enums from '@enums';
import type { ProjectSurveyDetails, ProjectSurveyDetailsInternalUser } from '@/types';
import ActivityIndicator from '@/components/ActivityIndicator';
import { ProjectSurveyApprovalView } from './View';
import { ProjectSurveyApprovalReview } from './Review';

const mapState = (state: Store.State) => state.group;

const ComplianceSurveyReview = () => {
  const group = useSelector(mapState);
  const params = useParams<{ responseId: string }>();
  const [response, setResponse] = useState<ProjectSurveyDetails>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    api.groups.compliance.fetchSurveyResponseReview({
      groupId: group.id,
      responseId: +params.responseId,
    })
    .then(setResponse)
    .then(() => setLoading(false));
  }, [group.id, params.responseId]);

  const handleInternalUpdate = useCallback((data: ProjectSurveyDetailsInternalUser) => {
    setResponse({
      type: 'internal-user',
      data,
    });
  }, []);

  if (loading || !response) return <ActivityIndicator show />;

  return response.type === 'internal-user'
    ? (
      <>
        {
          response.data.survey.approvalStatusId === enums.ApprovalStatus.NeedsApproval
            ? <ProjectSurveyApprovalReview item={response.data} onUpdate={handleInternalUpdate} />
            : <ProjectSurveyApprovalView item={response.data} />
        }
      </>
    )
    : null;
};

export { ComplianceSurveyReview };
export default ComplianceSurveyReview;