import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { GroupContactsQueryContext, GroupContactsQueryParamsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const QueryContainer = (props: Props) => {
  const query = useFetchContactsQuery();

  return (
    <GroupContactsQueryContext.Provider value={query}>
      {props.children}
    </GroupContactsQueryContext.Provider>
  );
};

QueryContainer.displayName = 'Group.Contacts.Query.Container';

const useFetchContactsQuery = () => {
  const group = useSelectGroup();
  const [queryparams, setQuery] = useContext(GroupContactsQueryParamsContext);

  const query = useQuery({ queryKey: [
    QK.Groups.Contacts.Get,
    group?.id,
    queryparams.index,
    queryparams.name,
    queryparams.size,
  ], queryFn: () => {
    return api.groups.contacts.fetch({
      groupId: group.id,
      index: queryparams.index,
      name: queryparams.name,
      size: queryparams.size,
    });
  }, enabled: !!group?.id, keepPreviousData: true });

  return query;
};