import { useContext, useMemo } from 'react';
import { Optional, Required, validators } from '@containers/MedicalOnboarding/utils';
import { MedicalExperienceContext } from '@containers/ProjectOnboarding.MedicalExperience/Context';
import type * as MedicalExperience from '@containers/ProjectOnboarding.MedicalExperience/interfaces';
import * as Experience from '@screens/ProjectOnboarding/Experience';
import type { State } from './interfaces';

export enum Step {
  Identification = 'Identification',
  Overview       = 'Overview',
  Role           = 'Role',
  Specialty      = 'Specialty',
  /*
  Specialties    = 'Specialties',
  Subspecialties = 'Subspecialties',
  */
  ThankYou       = 'ThankYou',
}

const StepScreens = {
  [Step.Identification]: Experience.Identification,
  [Step.Overview]: Experience.Overview,
  [Step.Role]: Experience.Role,
  /* [Step.Specialties]: Experience.Specialties, */
  [Step.Specialty]: Experience.Specialty,
  /* [Step.Subspecialties]: Experience.Subspecialties, */
  [Step.ThankYou]: Experience.ThankYou,
};

const StepChecks: MedicalExperience.StepCheck<typeof Step> = {
  [Step.Role]: state => !state.role,
  [Step.Identification]: state => {
    const isCountryUSA = validators.isCountryUSA(state.country);
    const isNonMedical = validators.isNonMedical(state.role);

    return isNonMedical
      ? [
        state.country,
        ...(state.isPayer ? [state.payerAdminType] : []),
      ].some(x => !x)
      : [
        state.country,
        state.clinicalPracticeStartedOn,
        ...((isCountryUSA && !Optional.npiNumber.includes(state.role)) ? [state.npiNumber] : []),
        ...((isCountryUSA && Required.stateLicenseNumber.includes(state.role)) ? [state.stateLicenseNumber] : []),
      ].some(x => !x);
  },
  [Step.Specialty]: state => {
    const legacy = {
      specialties: state?.specialties,
      subspecialties: state?.subspecialties,
    };

    if (legacy.specialties && legacy.subspecialties) return false;

    return !state?.specialty?.primary?.group?.id
        || !state?.specialty?.primary?.subspecialty?.id;
  },
  /*
  [Step.Specialties]: state => !state.specialties,
  [Step.Subspecialties]: state => !state.subspecialties,
  */
};

export const StepsMap = {
  Background: [
    Step.Identification,
  ],
  Expertise: [
    Step.Overview,
    Step.Role,
    Step.Specialty,
  ],
};

const Steps = [
  ...StepsMap.Expertise,
  ...StepsMap.Background,
];

const useSelectMedicalExperienceSteps = () => {
  const ctx = useContext(MedicalExperienceContext);

  const steps = Steps.filter(step => {
    const validator = StepChecks[step];

    return validator
      ? validator(ctx.state)
      : true;
  });

  return steps.length
    ? [...steps, Step.ThankYou]
    : steps;
};

export const useMedicalExperienceStepsSelector = (filter?: StepsFilter) => {
  const steps = useSelectMedicalExperienceSteps();

  const screens = typeof filter === 'function'
      ? filter(steps).map(step => StepScreens[step])
      : steps.map(step => StepScreens[step]);

  return {
    screens,
    steps,
  };
};

export const useMedicalExperienceEnabled = (state: State) => {
  const enabled = useMemo(() => {
    const isNonMedical = validators.isNonMedical(state.role);
    const checks = isNonMedical
      ? [
        StepChecks[Step.Identification],
      ]
      : [
        StepChecks[Step.Identification],
        StepChecks[Step.Specialty],
      ];
    return checks.map(validator => validator(state)).some(x => x);
  }, [state]);

  return enabled;
};

type StepsFilter = (steps: Step[]) => Step[];