import type { MaxDifferenceQuestion } from '@/types';
import { OptionsColors } from './options';

const MostColor = 'rgba(92, 153, 255, 1)';
const LeastColor = 'rgba(242, 87, 83, 1)';
const NotChosenColor = 'rgba(203, 203, 203, 1)';

export function getChoiceColor(choice: MaxDifferenceQuestion.Choices) {
  switch (choice) {
    case 'least': return LeastColor;
    case 'most': return MostColor;
    case 'nc': return NotChosenColor;
  }
}

export function getOptionColor(ordinal: number) {
  return OptionsColors[(ordinal - 1) % OptionsColors.length];
}