import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CallStatus } from '@enums';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';
import { FrameContext, ParamsContext, ReadyStateContext, UnconfirmedCallsContext } from './Context';
import { FrameKey } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const StateResolver = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    scheduling: useContext(ParamsContext),
  };

  const [ready, setReady] = useState(!!ctx.scheduling.params.callId);

  const query = useQuery({ queryKey: [
    `get:admin/projects/scheduling/respondents/unconfirmed`,
    ctx.scheduling.params.projectId,
    ctx.scheduling.params.userId,
  ], queryFn: () => {

    if (!ctx.scheduling.params.userId) {
      return {
        items: [],
      } as API.Projects.Scheduling.FindRespondentUnconfirmedCalls.Response;
    }

    return api.projects.scheduling.findRespondentUnconfirmedCalls({
      projectId: ctx.scheduling.params.projectId,
      userId: ctx.scheduling.params.userId,
    });
  }, refetchOnWindowFocus: false });

  const state = useMemo(() => {
    return {
      pending: query.data?.items?.find?.(x => x.statusId === CallStatus.Pending),
      rescheduling: query.data?.items?.find?.(x => x.statusId === CallStatus.Rescheduling),
    };
  }, [query.data?.items]);

  const setCallIdContext = useCallback(() => {
    ctx.scheduling.setContext({
      callId: state.pending.id,
      projectId: ctx.scheduling.params.projectId,
      userId: ctx.scheduling.params.userId,
    });

    ctx.frame.goToFrame({ frame: FrameKey.ModeratorSchedulers });
  }, [
    ctx.frame,
    ctx.scheduling,
    state.pending,
  ]);

  const resolveSchedulingFlow = useCallback(() => {
    state.rescheduling
      ? ctx.frame.goToFrame({ frame: FrameKey.UnconfirmedCalls })
      : state.pending
        ? setCallIdContext()
        : ctx.frame.goToFrame({ frame: FrameKey.ModeratorSchedulers });

  }, [
    ctx.frame,
    setCallIdContext,
    state.pending,
    state.rescheduling,
  ]);

  useEffect(() => {

    if (!ready && query.data?.items) {
      resolveSchedulingFlow();
      setReady(true);
    }

  }, [
    ctx.scheduling.params.callId,
    query.data?.items,
    ready,
    resolveSchedulingFlow,
  ]);

  return (
    <ReadyStateContext.Provider value={ready}>
      <UnconfirmedCallsContext.Provider value={query}>
        {props.children}
      </UnconfirmedCallsContext.Provider>
    </ReadyStateContext.Provider>
  );
};

StateResolver.displayName = 'CallScheduling.StateResolver';