import { useState } from 'react';
import { isValidEmail, phone } from '@utils';
import { useForm } from '@/components/Form/hooks/useForm';
import type { LeadFormData } from '$website/types';

const initialValues = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  jobTitle: '',
};

type Props = {
  onSubmit: (data: LeadFormData) => Promise<unknown>;
};

export const useLeadForm = (props: Props) => {
  const [isSubmitted, setSubmitted] = useState(false);

  const form = useForm({
    initialValues,
    onSubmit: (data: LeadFormData) => {
      const valid = [
        validator.company,
        validator.email,
        validator.firstName,
        validator.lastName,
        validator.jobTitle,
      ].every(Boolean);

      if (valid) {
        return props.onSubmit({
          company: data.company,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: phone.parseDigits(data.phone),
          jobTitle: data.jobTitle,
        }).then(_ => setSubmitted(true));
      }
    },
  });

  const validator = {
    get company() {
      const valid = !!form.values.company.length;

      form.setFieldError('company', valid ? '' : 'Company required');

      return valid;
    },
    get email() {
      const valid = isValidEmail(form.values.email);

      form.setFieldError('email', valid ? '' : 'Enter a valid email address');

      return valid;
    },
    get firstName() {
      const valid = !!form.values.firstName.length;

      form.setFieldError('firstName', valid ? '' : 'First name required');

      return valid;
    },
    get lastName() {
      const valid = !!form.values.lastName.length;

      form.setFieldError('lastName', valid ? '' : 'Last name required');

      return valid;
    },

    get jobTitle() {
      const valid = !!form.values.jobTitle.length;

      form.setFieldError('jobTitle', valid ? '' : 'Job title required');

      return valid;
    },
  };

  return {
    ...form,
    isSubmitted,
  };
};

export default useLeadForm;