import { getActiveConversation } from '@services/chat';
import * as enums from '@enums';
import type { IChat, Project, ProjectGroupProps } from '@/types';
import { selectActiveProjects } from './projects';

export const selectChatDirectoryProjects = (state: Store.State): Project<ProjectGroupProps>[] => {
  return selectActiveProjects(state)
    .filter(project => !state.pipeline.project[project.id]?.anonymity) as Project<ProjectGroupProps>[];
};

export const selectClientStateConnected = ({ chat }: Store.State) => {
  return enums.ChatConnectionState.Connected === chat.connectionState;
};

export function isMessageInvitation(message: IChat.Message, hasConnection: boolean) {
  if (message.type !== 'platform') return false;
  return message.data.attributes.paid || !hasConnection;
}

export const selectUnreadMessagesCount = (state: Store.State) => {
  const { chat, user } = state;

  const messages = selectMessageItems(state);

  return chat.conversations.ids.reduce<number>((acc, id) => {
    if (getActiveConversation() === id) return acc;

    const conversationMessages = messages[id];

    if (!conversationMessages || !conversationMessages.length) return acc;

    const lastMessage = conversationMessages[conversationMessages.length - 1];

    const authorId = lastMessage.type === 'pending' ? user.id : lastMessage.data.userId;
    if (authorId === user.id) return acc;

    const conversation = chat.conversations[id];
    const lastIndex = Number(conversation.lastMessage && conversation.lastMessage.index) || 0;
    const lastReadMessageIndex = Number(conversation.lastReadMessageIndex) || 0;

    const invitations = conversationMessages.filter(message => {
      if (message.type === 'pending') return false;
      return message.data.userId !== user.id && isMessageInvitation(message, chat.connections.ids.includes(message.data.userId));
    }).length;

    const twilio = conversation.lastReadMessageIndex === null
      ? conversation.lastMessage
        ? lastIndex + 1
        : 0
      : Math.max(0, lastIndex - lastReadMessageIndex);

    return acc + twilio + invitations;
  }, 0);
};

export function selectMessageItems({ chat }: Store.State) {
  return chat.conversations.ids.reduce<Store.Chat.Messages>((acc, id) => {
    const messages =
      (chat.messages[id] ?? [])
      .filter(m => m.type === 'twilio').map((m: IChat.ResolvedTwilioMessage) => m);

    const twPlatform = messages.map(m => m.data.attributes.messageId).filter(Boolean);

    const platform: IChat.ResolvedPlatformMessage[] =
      (chat.platformMessages[id] ?? []).map(m => ({
        type: 'platform' as const,
        data: {
          conversationSid: id,
          userId: +m.userId,
          body: m.body,
          timestamp: m.createdOn,
          attributes: {
            inComplianceReview: m.inComplianceReview,
            inRecipientReview: m.inRecipientReview,
            recipientRejected: m.recipientRejected,
            complianceRejected: m.complianceRejected,
            messageId: m.id,
            paid: m.paid,
          },
        },
      }))
      .filter(p => !twPlatform.includes(p.data.attributes.messageId));

    const merged: IChat.Message[] = [...messages, ...platform]
      .sort((a, b) => a.data.timestamp.getTime() - b.data.timestamp.getTime());

    return {
      ...acc,
      [id]: merged,
    };
  }, {});
}