import { useCallback, useState } from 'react';
import type { SetQuestionAnswer, SurveyAnswerMap } from '../interfaces';

export const useAnswerMap = () => {
  const [answers, setAnswers] = useState<SurveyAnswerMap>({});

  const updateAnswer: SetQuestionAnswer = useCallback(data => {
    setAnswers(s => {
      return Object.assign(s, {
        [data.questionId]: data.value,
      });
    });
  }, []);

  return [answers, updateAnswer] as const;
};

export default useAnswerMap;