import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { Chat } from '@/brand-insights/types';
import * as $api from '@/brand-insights/api';
import { ChatStateDispatchContext } from '@/brand-insights/components/Chat.State';
import { ChatSessionQueriesContext } from '@/brand-insights/components/Chat/context';

type Props = {
  chatInstanceId: number;
  queryIdentifier: string;
};

export const useUpdateQueryRatingMutation = ({ chatInstanceId, queryIdentifier }: Props) => {

  const sessionQueries = useContext(ChatSessionQueriesContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const dispatchRating = useCallback((rating: Chat.Rating) => {
    dispatch({
      type: 'query-rated',
      payload: {
        queryIdentifier,
        rating,
      },
    });
  }, [dispatch, queryIdentifier]);

  const mutation = useMutation({ mutationKey: ['query:patch:rating'], mutationFn: (rating: Chat.Rating) => {
    return $api.updateQueryRating({
      chatInstanceId,
      queryIdentifier,
      rating,
    });
  }, onMutate: rating => {
    const query = sessionQueries[queryIdentifier];
    dispatchRating(rating);
    return { oldRating: query.rating };
  }, onError: (res, variables, context) => {
    dispatchRating(context.oldRating);
  }, onSuccess: res => {
    dispatchRating(res.query.rating);
  } });

  return mutation;
};