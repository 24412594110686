import { useState } from 'react';
import { format } from 'date-fns';
import { useLock } from '@utils';
import Button from '@/components/Button';
import type { PaidProps } from './interfaces';
import styles from './style/PaidMessage.css';

type Props =
    PaidProps;

export function PaidMessage(props: Props) {
  const { sender, item } = props;

  const Content = sender.currentUser
    ? <Sender {...props} />
    : <Recipient {...props} />;

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={sender.currentUser ? styles.current : styles.name}>{sender.name}</div>
        <div className={styles.time}>{format(item.data.timestamp, 'h:mm a')}</div>
      </div>
      {Content}
    </div>
  );
}

function Sender(props: Props) {
  const { sender, item } = props;

  // const complianceRejected = item.data.attributes.complianceRejected;
  // const recipientRejected = item.data.attributes.recipientRejected;
  const complianceRejected = item.type === 'platform' && item.data.attributes.complianceRejected;
  const recipientRejected = item.type === 'platform' && item.data.attributes.recipientRejected;

  const complianceReview = item.type === 'platform' && item.data.attributes.inComplianceReview;
  const recipientReview = item.type === 'platform' && item.data.attributes.inRecipientReview;
  const inReview = complianceReview || recipientReview;

  const status =
    complianceRejected
        ? 'Compliance Rejected'
        : recipientRejected
            ? 'Expert Rejected'
            : inReview
              ? complianceReview
                  ? 'Pending Compliance'
                  : 'Pending Expert'
              : 'Accepted';

  return (
    <div className={styles.paid}>
      <div className={styles.paidHeader}>Paid Message Opportunity</div>
      <div className={styles.paidBody}>{item.data.body}</div>
      <div className={styles.paidStatus}>{status}</div>
    </div>
  );
}

function Recipient(props: Props) {
  const { sender, item } = props;

  function Status() {
    return item.type === 'platform'
      ? <RequiresAction />
      : <Accepted />;
  }

  function Accepted() {
    return <div className={styles.paidStatus}>Accepted</div>;
  }

  function RequiresAction() {
    const [complete, setComplete] = useState(false);
    const [isBusy, lock] = useLock();

    const handleReject = () => {
      setComplete(true);
      return props.onReject({ item })
      .catch(e => {
        console.error(e);
        setComplete(false);
      });
    };

    const handleAccept = () => {
      setComplete(true);
      return props.onAccept({ item })
        .catch(e => {
          console.error(e);
          setComplete(false);
        });
    };

    const btnDisabled = complete || isBusy;

    return (
      <div className={styles.actions}>
        <Button.Destructive
          className={styles.reject}
          variant='brick'
          title='Not Interested'
          disabled={btnDisabled}
          onClick={lock(handleReject)} />
        <Button.Affirmative
          className={styles.accept}
          variant='brick'
          title='Accept'
          disabled={btnDisabled}
          onClick={lock(handleAccept)} />
      </div>
    );
  }

  return (
    <div className={styles.paid}>
      <div className={styles.paidHeader}>Paid Message Opportunity</div>
      <div className={styles.paidBody}>{item.data.body}</div>
      <Status />
    </div>
  );
}

export default PaidMessage;