import { createRef } from 'react';
import { Link } from 'react-router-dom';
import { UGCImpressionType } from '@enums';
import { cx } from '@utils';
import { useCreateImpression } from '@utils/analytics/useCreateImpression';
import type { ArticlePostItem } from '@/types';
import { Header } from './Post';
import styles from './style/Feed.ArticlePost.css';

type Props = {
  item: ArticlePostItem;
};

export const ArticlePost = (props: Props) => {
  const ref = createRef<HTMLDivElement>();
  const main = cx(styles.main, styles.full);

  useCreateImpression({
    itemId: props.item.id,
    ref,
    typeId: UGCImpressionType.ArticlePostCard,
  });

  return (
    <div
      className={styles.root}
      ref={ref}>
      <Header
        className={styles.header}
        item={props.item} />

      <div className={styles.article}>
        <div className={styles.wrap}>
          <Link to={`/posts/articles/${props.item.id}`}>
            <div className={main}>
              {props.item.preview?.image?.url &&
                <div className={styles.cover}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${props.item.preview.image.url})` }} />
                </div>}
              <div className={styles.info}>
                <div className={styles.details}>
                  <div className={styles.content}>
                    <div className={styles.title}>{props.item.preview?.title}</div>
                    <div className={styles.url}>{props.item.author.profile.fullname}</div>
                    <div className={styles.description}>{props.item.preview.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

ArticlePost.displayName = 'Feed.ArticlePost';