import { memo } from 'react';
import { StarRatingPreview } from '@/components/StarRating';
import type { Results } from '../interfaces/members';

type Props = Results.TableCellProps;

export const Cell = ({ row }: Props) => {
  if (!row.original.user.avgProjectRating) {
    return <>-</>;
  } else {
    return <StarRatingPreview rating={row.original.user.avgProjectRating} />;
  }
};

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <>
      Avg Project Rating
    </>
  );
});

Cell.displayName = 'Column.ProjectRating.Cell';
Filter.displayName = 'Column.ProjectRating.Filter';
Header.displayName = 'Column.ProjectRating.Header';