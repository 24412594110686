import type { RefObject } from 'react';
import { useCallback, useRef } from 'react';
import type { UGCImpressionType } from '@enums';
import { useIntersectionObserver } from '@utils';
import { useCreateImpressionMutation } from '@utils/api';

type Params<T extends HTMLElement> = {
  itemId: number;
  ref: RefObject<T>;
  typeId: UGCImpressionType;
};

export const useCreateImpression = <T extends HTMLElement>({ ref, ...params }: Params<T>) => {
  const seen = useRef(false);
  const mutation = useCreateImpressionMutation(params.typeId);

  const onIntersection = useCallback((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry: Omit<IntersectionObserverEntry, 'target'> & { target: HTMLDivElement }) => {
      if (entry.isIntersecting && !seen.current) {
        mutation.mutate(params.itemId);
        seen.current = true;
      }
    });
  }, [
    mutation,
    params,
    seen,
  ]);

  useIntersectionObserver(onIntersection, {
    options: {
      rootMargin: '0px',
      threshold: [0.25, 1],
    },
    ref,
  });

};