import type { Ref } from 'react';
import { forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { useProjectState, useGroupProjectTabViewState } from '@containers/GroupProject/hooks';
import { VersionTag } from '@presentation/VersionTag';
import { selectProjectsForParent } from '@store/selectors';
import { getLocationFor, slugify } from '@utils';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import styles from './style/ProjectVersion.css';

type MenuProps = {
  onClose: () => unknown;
};

export const ProjectVersionContextMenu = forwardRef(({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const { latestProject, projectParent } = useProjectState();
  const children = useSelector(selectProjectsForParent(projectParent.id));
  const [tabView] = useGroupProjectTabViewState();
  const location = useLocation();

  const getLocation = useCallback((slug: string) => {
    const key = tabView.routes[tabView.index];
    const { pathname } = getLocationFor.project.root({ slug });

    return `${pathname}/${key}${location.search}`;
  }, [tabView, location.search]);

  const childItems = useMemo(() => {
    return children.map((child, i) => ({
      children: (
        <>
          Version {i + 1} <span className={styles.date}>{format(child.createdOn, 'M/d/yyyy')}</span>
        </>
      ),
      to: getLocation(child.slug),
    }));
  }, [
    children,
    getLocation,
  ]);

  const all = useMemo(() => {
    const slug = slugify({
      id: `P${projectParent.id}`,
      name: latestProject.name,
    });
    return {
      children: <span className={styles.bold}>View All</span>,
      to: getLocation(slug),
    };
  }, [
    getLocation,
    latestProject.name,
    projectParent.id,
  ]);

  const items = useMemo(() => [
    ...childItems,
    all,
  ], [all, childItems]);

  return (
    <PopperMenu className={styles.menu} ref={ref}>
      {items.map((item, i) => (
        <Link
          key={i}
          replace
          to={item.to}>
          <PopperMenuItem className={styles.item} onClick={onClose}>
            {item.children}
          </PopperMenuItem>
        </Link>
      ))}
    </PopperMenu>
  );
});

const ProjectVersionTag = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const state = useProjectState();
  const children = useSelector(selectProjectsForParent(state.projectParent.id));

  const text = useMemo(() => {
    if (state.project) {
      const version = children.findIndex(f => f.id === state.project.id);
      return `V${version + 1}`;
    } else {
      return 'All';
    }
  }, [
    children,
    state.project,
  ]);

  return (
    <VersionTag
      ref={ref}
      className={styles.versionTag}>
      {text}
      <div className={styles.chevron} />
    </VersionTag>
  );
});

type Props = {
  className?: string;
};

export const ProjectVersion = ({ className }: Props) => {

  return (
    <PopupState variant="popper" popupId="project-version-popper">
      {popupState => (
        <div className={className}>
          <div {...bindToggle(popupState)}>
            <ProjectVersionTag />
          </div>
          <Popper
            className={styles.popper}
            {...bindPopper(popupState)}
            placement="bottom-end">
            <ClickAwayListener
              onClickAway={popupState.close}>
              <ProjectVersionContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

export default ProjectVersion;