import { Fragment, memo } from 'react';
import { GroupTag } from '@presentation';
import type { Results } from '../interfaces/members';
import styles from './style/Table.css';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <div className={styles.topics}>
      {row.original.topics.map(t => (
        <GroupTag
          className={styles.topic}
          key={t.id}>
          {t.name}
        </GroupTag>
      ))}
    </div>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Topics
    </Fragment>
  );
});

Cell.displayName = 'Column.Topics.Cell';
Filter.displayName = 'Column.Topics.Filter';
Header.displayName = 'Column.Topics.Header';