import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';
import { AccountProfileBioContext } from './Context';
import { useProfileState } from './hooks';
import type { AccountProfile } from './interfaces';

type Props = {
  children: React.ReactNode;
};

const AccountProfileBioContainer = (props: Props) => {
  const [state, dispatch, refetch] = useProfileState();
  const me = useSelectUser();

  if (!dispatch) {
    throw new Error(`AccountProfileBioContainer must be used within AccountProfileContainer`);
  }

  const [response, submit] = useAsyncStateLazy(() => {
    return api.profiles.bio.updateSummary({
      summary: state.bio?.summary ?? '',
      userId: me.id,
    })
    .then(() => refetch());
  }, [
    me.id,
    refetch,
    state.bio,
  ]);

  const handleSubmit: AccountProfile.BioContextValue['form']['handleSubmit'] = cb => {
    return submit().then(() => cb?.());
  };

  const handleChange: AccountProfile.BioContextValue['form']['onChange'] = e => {
    dispatch({
      type: 'bio',
      data: { [e.target.name]: e.target.value },
    });
  };

  const form = {
    handleSubmit,
    onChange: handleChange,
    submitting: response.loading,
  };

  return (
    <AccountProfileBioContext.Provider value={{ form }}>
      {props.children}
    </AccountProfileBioContext.Provider>
  );
};

export { AccountProfileBioContainer };
export default AccountProfileBioContainer;