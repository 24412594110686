import { useCallback } from 'react';
import { useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey.templates';
import { useCanSaveList, useListElements } from './list-elements';

const minimum = 1;
const maximum = 10;

export const useCanSaveSafetyEndpoints = (items: SurveyTemplate.LinkedEntity[]) => {

  return useCanSaveList({
    items,
    minItems: minimum,
    maxItems: maximum,
  });
};

export const useTemplateSafetyEndpoints = () => {
  const [templateData, dispatch] = useTargetProductProfileData();

  const {
    canAdd: canAddSafetyEndpoint,
    canRemove: canRemoveSafetyEndpoint,
    items: safetyEndpoints,
    onAddItem: onAddSafetyEndpoint,
    onRemoveItem: onRemoveSafetyEndpoint,
    onUpdateItem: onUpdateSafetyEndpoint,
  } = useListElements({
    min: minimum,
    max: maximum,
    initial: templateData.safetyEndpoints,
  });

  const onSubmit = useCallback(() => {
    dispatch({
      type: 'template-safety-endpoints-updated',
      value: safetyEndpoints,
    });
  }, [dispatch, safetyEndpoints]);

  return {
    canAddSafetyEndpoint,
    canRemoveSafetyEndpoint,
    onAddSafetyEndpoint,
    onRemoveSafetyEndpoint,
    onUpdateSafetyEndpoint,
    onSubmit,
    safetyEndpoints,
  };
};