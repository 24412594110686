import type { AxiosResponse } from 'axios';
import { useMutation, useQuery, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';
import * as $api from '@/brand-insights/api/chat-instances';

type Props = {
  chatInstanceIdentifier: string;
};

type Data = Awaited<ReturnType<typeof $api.getChatInstance>>;
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;
type QueryKey = readonly [string, Props];

function getQueryKey(props: Props): QueryKey {
  return [`chat:get:instance`, props];
}

export const useChatInstanceQuery = (props: Props, options?: Options) => {
  const qk = getQueryKey(props);

  return useQuery({ queryKey: qk, queryFn: ({ queryKey }) => {
    return $api.getChatInstance({
      chatInstanceIdentifier: queryKey[1].chatInstanceIdentifier,
    });
  }, ...options });
};

type MutationOptions = UseMutationOptions<Data, AxiosResponse, Props>;

export const useFetchChatInstanceMutation = (options?: MutationOptions) => {
  return useMutation({ mutationKey: [`chat:get:instance`], mutationFn: ({ chatInstanceIdentifier }) => {
    return $api.getChatInstance({
      chatInstanceIdentifier,
    });
  }, ...options });
};