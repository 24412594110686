import { Fragment, memo, useCallback } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Popover from '@mui/material/Popover';
import { SurveyConditionType, SurveyLogicRule, SurveyQuestionConditionType, SurveyQuestionType } from '@enums/Survey';
import type { SurveyLogic } from '@/types';
import { SurveyLogicExpressionNode } from '@/types/survey.logic.expression';
import { wrapInParentheses, generateConditionString } from './utils';
import styles from './style/ConditionsPopover.css';

type Props = {
  items: SurveyLogic.SurveyEnrichedCondition[];
  rule: SurveyLogic.SurveyConditionsRule;
};

const ConditionsPopperContainer = ({ items, rule }: Props) => {

  const showPopover = items.some(s => s.conditionType === SurveyConditionType.Question);

  if (showPopover) {
    return (
      <ConditionsPopper
        rule={rule}
        items={items} />
    );
  } else {
    return (
      <ConditionsShorthand
        rule={rule}
        items={items} />
    );
  }
};

const ConditionsPopper = ({ items, rule }: Props) => {
  const popupState = usePopupState({
    popupId: 'survey-conditions-popover',
    variant: 'popover',
  });

  return (
    <>
      <div
        onMouseEnter={popupState.open}
        onMouseLeave={popupState.close}>
        <ConditionsShorthand
          items={items}
          rule={rule} />
      </div>
      <Popover
        sx={{
          pointerEvents: 'none',
          marginTop: '10px',
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'var(--pri-01)',
              border: '1px solid transparent',
              borderRadius: '4px',
              boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
            },
          },
        }}
        disableScrollLock
        open={popupState.isOpen}
        anchorEl={popupState.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={popupState.close}
        disableRestoreFocus>
        <ConditionsPopover
          items={items}
          rule={rule} />
      </Popover>
    </>
  );
};

export const ConditionsShorthand = memo(({ items, rule }: Props) => {

  let text = '';
  if (rule.type === SurveyLogicRule.CustomExpression) {
    const nodes = rule.expression.content[0].content;
    const textNodes = nodes.reduce<string[]>((acc, node) => {
      switch (node.type) {

        case SurveyLogicExpressionNode.ClosingParenthesis:
          return acc.concat(')');

        case SurveyLogicExpressionNode.OpeningParenthesis:
          return acc.concat('(');

        case SurveyLogicExpressionNode.Condition: {
          const condition = items.find(f => f.identifier === node.attrs.identifier);
          return acc.concat(generateConditionString(condition));
        }

        case SurveyLogicExpressionNode.LogicalOperator: {
          return acc.concat(node.attrs.operator.toUpperCase());
        }

        default:
          throw new UnreachableCaseError(node);
      }
    }, []);
    text = textNodes.join(' ');
  } else {
    text = items
      .map(generateConditionString)
      .map(m => items.length > 1 ? wrapInParentheses(m) : m)
      .join(rule.type === SurveyLogicRule.AllOfTheAbove ? ' AND ' : ' OR ');
  }

  if (items.some(s => !s)) return null;

  return (
    <div className={styles.root}>
      {text}
    </div>
  );
});

const ConditionsPopover = memo(({ items, rule }: Props) => {

  const renderQuestionConditionValue = useCallback((item: SurveyLogic.EnrichedQuestionCondition) => {
    switch (item.data.question.typeId) {
      case SurveyQuestionType.Dropdown:
      case SurveyQuestionType.ExclusiveOptions:
      case SurveyQuestionType.MultipleChoice: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.MultipleChoice>);

        return (
          <div className={styles.condition}>
            <div className={styles.ordinal}>A{condition.data.value.option.ordinal}</div>
            {condition.data.value.option.value}
          </div>
        );
      }

      case SurveyQuestionType.Ranking: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Ranking>);
        return (
          <div className={styles.condition}>
            <div className={styles.ordinal}>A{condition.data.value.option.ordinal}</div>
            {condition.data.value.option.value}
          </div>
        );
      }

      case SurveyQuestionType.Sliders: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Sliders>);

        if (condition.data.type === SurveyQuestionConditionType.SliderValuesTally) {
          return null;
        }

        return (
          <div className={styles.condition}>
            <div className={styles.condition}>
              <div className={styles.ordinal}>R{condition.data.value.row.ordinal}</div>
              {condition.data.value.row.value}
            </div>
          </div>
        );
      }

      case SurveyQuestionType.NumberInputTable: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.NumberInputTable>);
        return (
          <div className={styles.condition}>
            <div className={styles.condition}>
              <div className={styles.ordinal}>R{condition.data.value.row.ordinal}</div>
              {condition.data.value.row.value}
            </div>
            <div className={styles.condition}>
              <div className={styles.ordinal}>A{condition.data.value.option.ordinal}</div>
              {condition.data.value.option.value}
            </div>
          </div>
        );
      }

      case SurveyQuestionType.Multiselect: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.Multiselect>);

        if (condition.data.type === SurveyQuestionConditionType.NumberOfOptionsSelected) {
          return (
            <div className={styles.condition}>

            </div>
          );
        }

        return (
          <div className={styles.condition}>
            <div className={styles.ordinal}>A{condition.data.value.option.ordinal}</div>
            {condition.data.value.option.value}
          </div>
        );
      }

      case SurveyQuestionType.MatrixRange:
      case SurveyQuestionType.MatrixMultiselect:
      case SurveyQuestionType.MatrixGrid: {
        const condition = (item as SurveyLogic.EnrichedQuestionCondition<SurveyQuestionType.MatrixGrid>);

        if (condition.data.type === SurveyQuestionConditionType.Straightline) {

          return (
            <>Straightline {!condition.data.value ? 'all' : condition.data.value.comparate} rows
            </>
          );
        }

        return (
          <>
            <div className={styles.condition}>
              <div className={styles.ordinal}>R{condition.data.value.row.ordinal}</div>
              {condition.data.value.row.value}
            </div>
            <div className={styles.condition}>
              <div className={styles.ordinal}>A{condition.data.value.option.ordinal}</div>
              {condition.data.value.option.value}
            </div>
          </>
        );
      }

      case SurveyQuestionType.LongTextResponse:
      case SurveyQuestionType.ShortTextResponse:
      case SurveyQuestionType.MaxDifference:
      case SurveyQuestionType.ConjointAnalysis:
      case SurveyQuestionType.MultiTextbox:
      case SurveyQuestionType.ImageMarkup:
        return null;

      default:
        throw new UnreachableCaseError(item.data.question.typeId);
    }
  }, []);

  const renderQuestionCondition = useCallback((item: SurveyLogic.EnrichedQuestionCondition) => {

    return (
      <div className={styles.item}>
        <div className={styles.question}>
          <div className={styles.ordinal}>Q{item.data.question.ordinal}</div>
          {item.data.question.value}
        </div>
        {renderQuestionConditionValue(item)}
      </div>
    );

  }, [renderQuestionConditionValue]);

  const renderCondition = useCallback((item: SurveyLogic.SurveyEnrichedCondition) => {
    if (item.conditionType === SurveyConditionType.Question) {
      return renderQuestionCondition(item);
    }

    return null;
  }, [
    renderQuestionCondition,
  ]);

  const renderItem = useCallback((item: SurveyLogic.EnrichedQuestionCondition, i: number) => {

    if (rule.type === SurveyLogicRule.CustomExpression) {
      return null;
    }

    const isLast = i === items.length - 1;
    const separator = rule.type === SurveyLogicRule.AllOfTheAbove ? ' AND ' : 'OR';

    return (
      <Fragment key={i}>
        {renderCondition(item)}
        {!isLast &&
          <div className={styles.divider}>
            <div className={styles.line} />
            <div className={styles.and}>{separator}</div>
          </div>
        }
      </Fragment>
    );
  }, [
    items.length,
    renderCondition,
    rule,
  ]);

  return (
    <div className={styles.popover}>
      {items.map(renderItem)}
    </div>
  );
});

export { ConditionsPopperContainer as ConditionsPopper };