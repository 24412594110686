import { memo } from 'react';
import { animated, config as presets, useSpring } from '@react-spring/web';
import type { PointProps } from './interfaces';

export const SeriePoint = memo(({
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  point,
}: PointProps) => {

  const animatedProps = useSpring({
    x: point.x,
    y: point.y,
    radius: point.style.size / 2,
    color: point.color,
    borderColor: point.borderColor,
    borderWidth: point.style.borderWidth,
    opacity: point.style.opacity,
    config: presets['gentle'],
    immediate: false,
  });

  return (
    <animated.circle
      cx={animatedProps.x}
      cy={animatedProps.y}
      r={animatedProps.radius.to(v => Math.max(v, 0))}
      onClick={onClick}
      cursor="pointer"
      opacity={animatedProps.opacity}
      fill={animatedProps.color}
      strokeWidth={animatedProps.borderWidth}
      stroke={animatedProps.borderColor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver} />
  );
});

export default SeriePoint;