import { useMemo, useCallback } from 'react';
import type { IDropDownItem } from '@/components/DropDown';
import { Select } from '@/components/Select';
import styles from './style/MaxDiff.Question.css';

type Props = {
  min: number;
  max: number;
  onChange: (value: number) => void;
  placeholder?: string;
  value: number;
};

export const SettingsSelect = ({
  min,
  max,
  onChange,
  placeholder,
  value,
}: Props) => {

  const items = useMemo(() => {
    return Array.from({ length: max - min + 1 }, (v, k) => ({
      id: k + min,
    }));
  }, [
    max,
    min,
  ]);

  const onSelect = useCallback(({ id }: IDropDownItem) => {
    onChange(+id);
  }, [onChange]);

  return (
    <div className={styles.dropdown}>
      <Select
        options={items}
        getItemValue={v => v.id.toString()}
        onSelect={onSelect}
        placeholder={placeholder}
        value={value?.toString() || ''} />
    </div>
  );
};

export default SettingsSelect;