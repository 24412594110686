import { useCallback, useEffect, useState } from 'react';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import {
  TaggedProjectsContext,
  TaggedProjectsDispatchContext,
  TaggedProjectsFetchContext,
  TaggedProjectsLoadingContext,
  TaggedProjectsUpdateContext,
} from './Context';
import type { TaggedProjectsContextValue, TaggedProject } from './interfaces';

type Props =
  IWorkspaceFileId &
  IWorkspaceId &
  ChildrenProps;

export const FileProjectsContainer = ({ children, fileId, workspaceId }: Props) => {

  const [state, dispatch] = useState<TaggedProjectsContextValue>([]);

  const [response, fetch] = useAsyncStateLazy(async () => {
    const result = await api.workspaces.file.getFileProjects({
      fileId,
      workspaceId,
    });

    dispatch(result.projects);

    return result.projects;
  }, [fileId, workspaceId]);

  const update = useCallback(async (items: TaggedProject[]) => {
    const result = await api.workspaces.file.saveFileProjects({
      fileId,
      projectIds: items.map(m => m.id),
      workspaceId,
    });
    dispatch(result.projects);
  }, [fileId, workspaceId]);

  useEffect(() => {
    if (workspaceId) {
      fetch();
    }
  }, [fetch, workspaceId]);

  return (
    <TaggedProjectsContext.Provider value={state}>
      <TaggedProjectsLoadingContext.Provider value={response.loading}>
        <TaggedProjectsFetchContext.Provider value={fetch}>
          <TaggedProjectsUpdateContext.Provider value={update}>
            <TaggedProjectsDispatchContext.Provider value={dispatch}>
              {children}
            </TaggedProjectsDispatchContext.Provider>
          </TaggedProjectsUpdateContext.Provider>
        </TaggedProjectsFetchContext.Provider>
      </TaggedProjectsLoadingContext.Provider>
    </TaggedProjectsContext.Provider>
  );
};

export default FileProjectsContainer;