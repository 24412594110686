import type { Ref } from 'react';
import { forwardRef, memo } from 'react';
import { cx } from '@utils';
import styles from './style/VersionTag.css';

type Props = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const VersionTag = memo(forwardRef(({
  children,
  className,
  ...props
}: Props, ref: Ref<HTMLDivElement>) => {

  return (
    <div
      {...props}
      ref={ref}
      className={cx(styles.root, className)}>
      {children}
    </div>
  );
}));

export default VersionTag;