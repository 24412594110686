import { SurveyQuotaType } from '@/enums';
import type { SurveyQuota as SQ } from '@/types/survey';
import type { SurveyAdvancedQuotaBuilder, SurveyBaseQuotaBuilder } from './interfaces';
import { surveyBaseQuotaReducer } from './reducer.base';
import { getDefaultAdvancedSet, getDefaultAdvancedSetItem } from './utils';

export function surveyAdvancedQuotaReducer(state: SurveyAdvancedQuotaBuilder.State, action: SurveyAdvancedQuotaBuilder.Action): SurveyAdvancedQuotaBuilder.State {

  return {
    ...surveyBaseQuotaReducer(state, action as SurveyBaseQuotaBuilder.Action),
    type: SurveyQuotaType.Advanced,
    data: {
      sets: advancedDataReducer(state.data.sets, action),
    },
  };
}

function advancedDataReducer(state: SQ.Advanced.Value['sets'], action: SurveyAdvancedQuotaBuilder.Action): SQ.Advanced.Value['sets']  {
  switch (action.type) {
    case 'add-advanced-item': {
      return [
        ...state,
        getDefaultAdvancedSet(),
      ];
    }

    case 'remove-advanced-item': {
      return state.filter(f => f.identifier !== action.setIdentifier);
    }

    case 'update-advanced-item-question': {
      return state.reduce((acc, x) => {
        if (x.identifier === action.setIdentifier) {
          acc.push({
            ...x,
            items: [getDefaultAdvancedSetItem()],
            question: action.question,
          });
        } else {
          acc.push(x);
        }
        return acc;
      }, []);
    }

    case 'add-advanced-item-condition': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          acc.push({
            ...set,
            items: [
              ...set.items,
              getDefaultAdvancedSetItem(),
            ],
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'remove-advanced-item-condition': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          acc.push({
            ...set,
            items: set.items.filter(f => f.identifier !== action.identifier),
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'update-advanced-item-condition-pct': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          const items = set.items.reduce((acc2, item) => {
            if (item.identifier === action.identifier) {
              acc2.push({
                ...item,
                pct: action.value,
              });
            } else {
              acc2.push(item);
            }

            return acc2;
          }, []);

          acc.push({
            ...set,
            items,
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'update-advanced-item-condition-value': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          const items = set.items.reduce((acc2, item) => {
            if (item.identifier === action.identifier) {
              acc2.push({
                ...item,
                condition: {
                  ...item.condition,
                  value: action.value,
                },
              });
            } else {
              acc2.push(item);
            }

            return acc2;
          }, []);

          acc.push({
            ...set,
            items,
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    default:
      return state;
  }
}