import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import type { CalendarSubmitCallbackParams } from '../interfaces';
import { useProjectOnboardingContext } from './useProjectOnboardingContext';

export const useSubmitOnboardingCalendar = (projectId: number) => {
  const done = useRef(false);
  const dispatch = useDispatch();
  const { next } = useProjectOnboardingContext();

  const [state, submit] = useAsyncStateLazy((data: CalendarSubmitCallbackParams) => {
    return api.projects.pipeline.onboarding.complete({
      creator: data.creator,
      new: data.new,
      projectId,
      recordingConsentStatusId: data.recordingConsentStatusId,
      removed: data.removed,
      scheduleeId: data.creator.id,
      schedulerId: data.schedulerId,
      user: data.user,
    })
    .then(res => {
      done.current = true;

      dispatch(actions.projectPipelineUpdated({
        projectId,
        pipeline: res.pipeline,
      }));

    });
  }, [
    projectId,
  ]);

  return useCallback((data: CalendarSubmitCallbackParams) => {
    if (!state.loading && !done.current) {
      return submit(data).then(() => next());
    }
    return Promise.resolve();
  }, [
    next,
    state.loading,
    submit,
  ]);
};

export default useSubmitOnboardingCalendar;