import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConferenceTagType } from '@enums';
import { cx } from '@utils';
import { TaggingContext } from './Context';
import { Action, ColorCircle, TimeRange } from './Tagging';
import type { Tagging } from './interfaces';
import styles from './style/Tagging.Tag.css';

type Props = {
  className?: string;
  item:       Omit<Tagging.Tag, 'identifier'>;
} & Pick<Tagging.Tag, 'identifier'>
  & Pick<Tagging.Context,
    | 'discard'
    | 'remove'
    | 'setFocus'
    | 'setValue'>;

export const Ongoing = ({ discard, identifier, item, remove, setFocus, ...props }: Props) => {
  const [saved, setSaved] = useState(false);
  const [discarded, setDiscarded] = useState(false);
  const { mutation } = useContext(TaggingContext);
  const ref = useRef<HTMLDivElement>(null);

  const handleSave = useCallback(() => {
    if (saved) return;

    mutation.mutateAsync({
      identifier,
      item: {
        color: item.color,
        start: item.start,
        end: new Date(),
        id: item.id,
        name: item.name,
        typeId: ConferenceTagType.UserCreated,
      },
    }, {
      onSuccess: (res, variables, context) => {
        setSaved(true);
      },
    });
  }, [
    identifier,
    item,
    mutation,
    saved,
    setSaved,
  ]);

  const removeSelfAfterDiscard = useCallback(() => {
    saved
      ? remove(identifier)
      : discard(identifier);
  }, [
    discard,
    identifier,
    remove,
    saved,
  ]);

  useEffect(() => {
    const node = ref.current;

    if (node !== null) {
      node.addEventListener('animationend', removeSelfAfterDiscard);
    }

    return () => {
      node?.removeEventListener('animationend', removeSelfAfterDiscard);
    };
  }, [
    removeSelfAfterDiscard,
  ]);

  const busy = useMemo(() => {
    return {
      submitting: mutation.isLoading && mutation.variables.identifier === identifier,
      removing: saved || discarded,
    };
  }, [
    discarded,
    identifier,
    mutation,
    saved,
  ]);

  const handleFocus = useCallback(() => {
    if (!busy.submitting && !busy.removing) {
      setFocus(identifier);
    }
  }, [
    busy,
    identifier,
    setFocus,
  ]);

  return (
    <div
      className={cx(styles.root, props.className, {
        [styles.discarded]: discarded,
        [styles.saved]: saved,
      })}
      ref={ref}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.compact}>
            <div
              className={cx(styles.labeling, { [styles.untitled]: !busy.submitting && !busy.removing })}
              onClick={handleFocus}>
              <div className={styles.color}>
                <ColorCircle color={item.color} />
              </div>
              {item.name &&
                <div className={styles.name}>{item.name}</div>}
              {!item.name &&
                <div className={styles.placeholder}>
                  Add tag name
                </div>}

            </div>
            <div className={styles.controls}>
              <TimeRange
                end={{ item }.item.end}
                start={{ item }.item.start} />
              {!!item.start &&
                <Action.End
                  disabled={!item.name?.trim()?.length || busy.submitting}
                  loading={busy.submitting}
                  onClick={handleSave}
                  saved={saved} />}
              <Action.Discard
                disabled={busy.submitting || busy.removing}
                onClick={() => setDiscarded(true)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Ongoing.displayName = 'Tagging.Tag.Ongoing';