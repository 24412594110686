import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import type { UGCImpressionType } from '@enums';
import { UGCViewType } from '@enums';

export const useCreateImpressionMutation = (typeId: UGCImpressionType) => {
  const mutation = useMutation({ mutationKey: [QK.Posts.Analytics.Impressions.Post, typeId], mutationFn: (itemId: number) => {
    return api.posts.analytics.createImpressionEvent({
      itemId,
      typeId,
    });
  } });

  return mutation;
};

export const useCreateViewEventMutation = (typeId: UGCViewType) => {
  const mutation = useMutation({ mutationKey: [QK.Posts.Analytics.Views.Post, typeId], mutationFn: (itemId: number) => {
    return api.posts.analytics.createViewEvent({
      itemId,
      typeId,
    });
  } });

  return mutation;
};

export const useCreateViewArticlePostMutation = () => {
  return useCreateViewEventMutation(UGCViewType.ArticlePost);
};

export const useCreateViewFeedPostMutation = () => {
  return useCreateViewEventMutation(UGCViewType.FeedPost);
};

export const useCreateViewVideoMediaMutation = () => {
  return useCreateViewEventMutation(UGCViewType.DiscoveryVideo);
};