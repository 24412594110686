import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { ProjectPipelineQueryContext, ProjectPipelineQueryParamsContext, ProjectPipelineStateContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
} & IProjectId;

export const ProjectPipelineQueryContainer = (props: Props) => {
  const [qp, setQuery] = useQueryParams({
    index: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 25),
  });

  const group = useSelectGroup();

  const state = useQuery({ queryKey: [
    `get:groups/projects/pipeline/state`,
    props.projectId,
    group.id,
  ], queryFn: () => {
    return api.groups.projects.fetchRespondentPipelineState({
      projectId: props.projectId,
      groupId: group.id,
    });
  }, enabled: props.enabled, refetchOnWindowFocus: false });

  const query = useQuery({ queryKey: [
    `get:projects/respondents`,
    props.projectId,
    qp.index,
    qp.size,
  ], queryFn: () => {
    return api.projects.fetchRespondents({
      projectId: props.projectId,
      index: qp.index,
      size: qp.size,
    });
  }, enabled: props.enabled, keepPreviousData: true });

  return (
    <ProjectPipelineStateContext.Provider value={state}>
      <ProjectPipelineQueryParamsContext.Provider value={[qp, setQuery]}>
        <ProjectPipelineQueryContext.Provider value={query}>
          {props.children}
        </ProjectPipelineQueryContext.Provider>
      </ProjectPipelineQueryParamsContext.Provider>
    </ProjectPipelineStateContext.Provider>
  );
};

ProjectPipelineQueryContainer.displayName = 'Project.Pipeline.Query.Container';