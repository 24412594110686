import { memo } from 'react';
import { cx } from '@utils';
import { Mail } from '@/components/icons';
import type { Item } from './interfaces';
import styles from './style/Item.css';

export const Invite = memo(({ title = 'Invite', onClick }: Item.Props) => {
  return (
    <div
      className={cx(styles.root, styles.disabled)}
      onClick={onClick}>
      <Mail
        className={styles.icon}
        size={22} />
      {title}
    </div>
  );
});