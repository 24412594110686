import type { GetUpcomingCalls } from '@api/interfaces/users';

export const buildCallsMap = (items: GetUpcomingCalls.Item[], keyExtractor: (item: GetUpcomingCalls.Item) => string) => {
  return items.reduce<Map>((acc, x) => {
    const key = keyExtractor(x);

    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(x) : [x],
    };
  }, {});

  type Map = {
    [key: string]: GetUpcomingCalls.Item[];
  };
};