import type { PercentCrop } from 'react-image-crop';
import { centerCrop, makeAspectCrop } from 'react-image-crop';

export const getBlob = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    fetch(url)
    .then(data => resolve(data.blob()));
  });
};

const validContentTypes = [
  'image/jpeg',
  'image/png',
];

export function checkContentType(value: string) {
  return validContentTypes.includes(value);
}

export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
): PercentCrop {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 95,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  ) as PercentCrop;
}