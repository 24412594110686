import { useCallback } from 'react';
import { cx } from '@utils';
import { useNumberInputDropdownValuesContext } from '@containers/SurveyForm/Context';
import type { OnSelectChange } from '$admin/components/Select';
import { Select } from '$admin/components/Select';
import type { NumberInputTable } from './interfaces';
import styles from './style/NumberInputTable.Dropdown.css';

type Props = NumberInputTable.InputProps;

export const NumberInputTableDropdown = ({
  id,
  invalid,
  onChange,
  value,
  ...props
}: Props) => {

  const options = useNumberInputDropdownValuesContext();

  const handleSelect: OnSelectChange = useCallback(e => {
    const v = e.target.value !== ''
      ? +e.target.value
      : null;
    onChange(v);
  }, [onChange]);

  return (
    <div className={cx(styles.root, props.className)}>
      <Select
        style={invalid ? {
          border: '1px solid var(--pri-04)',
          borderRadius: '4px',
        } : undefined}
        placeholder="-"
        id={id}
        name={id}
        onChange={handleSelect}
        options={options}
        value={value?.toString() || ''} />
    </div>
  );
};