import { useContext } from 'react';
import type { SurveyTemplateType } from '@enums';
import type { SurveyTemplate } from '@/types';
import { SurveyFormTemplateContext } from '../Context';

export const useSurveyFormTemplateData = <T extends SurveyTemplateType = SurveyTemplateType>() => {

  const ctx = useContext(SurveyFormTemplateContext);

  return ctx?.data as SurveyTemplate.Data<T>;
};