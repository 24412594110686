import type { NumberInputTableQuestion, SurveyQuestion, SurveyQuestionOption, SurveyQuestionOptionMetadata } from '@/types';
import { hasOptions } from '@/containers/Survey/utils/questions';
import type { SurveyQuestionsBuilder, SurveyOptionsBuilder } from '../interfaces';

type State = SurveyQuestionsBuilder.State;

export function isAnchorUpdated(state: State, payload: SurveyOptionsBuilder.OptionIsAnchorUpdated.Payload): State {

  return updateMetadata<{ isAnchored: boolean }>({
    optionIdentifier: payload.option.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isAnchored: payload.value,
    },
  });
}

export function isExclusiveUpdated(state: State, payload: SurveyOptionsBuilder.OptionIsExclusiveUpdated.Payload): State {
  return updateMetadata<{ isExclusive: boolean }>({
    optionIdentifier: payload.option.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isExclusive: payload.value,
    },
  });
}

export function isOpenEndedUpdated(state: State, payload: SurveyOptionsBuilder.OptionIsOpenEndedUpdated.Payload): State {
  return updateMetadata<{ isOpenEnded: boolean }>({
    optionIdentifier: payload.option.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isOpenEnded: payload.value,
    },
  });
}

export function readonlyUpdated(state: State, payload: SurveyOptionsBuilder.OptionReadonlyUpdated.Payload): State {
  return updateMetadata<{ isReadOnly: boolean }>({
    optionIdentifier: payload.option.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      isReadOnly: payload.value,
    },
  });
}

export function numberInputTableOptionValidationsUpdated(state: State, payload: SurveyOptionsBuilder.NumberInputTableColumnValidationsUpdated.Payload): State {
  return updateMetadata<{ validations: NumberInputTableQuestion.ColumnValidation[] }>({
    optionIdentifier: payload.option.identifier,
    questionIdentifier: payload.questionIdentifier,
    state,
    metadata: {
      validations: payload.value,
    },
  });
}

type UpdateMetadata<T extends Record<string, unknown>> = {
  questionIdentifier: string;
  optionIdentifier: string;
  metadata: Partial<SurveyQuestionOptionMetadata<T>>;
  state: State;
};

function updateMetadata<T extends Record<string, unknown>>({
  optionIdentifier,
  questionIdentifier,
  metadata,
  state,
}: UpdateMetadata<T>) {

  return state.reduce((acc, q: SurveyQuestion) => {
    if (q.base.identifier === questionIdentifier && hasOptions(q)) {
      const options = (q.options as SurveyQuestionOption[]).reduce((acc2, o) => {
        if (o.base.identifier === optionIdentifier) {
          return acc2.concat({
            ...o,
            metadata: {
              ...o.metadata,
              ...metadata,
            },
          });
        }
        return acc2.concat(o);
      }, []);
      return acc.concat({ ...q, options });
    }
    return acc.concat(q);
  }, []);
}