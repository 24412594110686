import { type ReactNode, useContext, useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { ChatSkin } from '@/brand-insights/enums';
import { theme } from '@/brand-insights/components/Theme/theme';
import { WhitelabelingPaletteContext } from '@containers/Branding/Context';
import { useThemeOverride } from '@/containers/Branding/hooks';
import { ChatSkinContext } from './context';

type Props = {
  children: ReactNode;
};

export const SentimentThemeContainer = ({ children }: Props) => {
  const muiTheme = createTheme(theme);

  const wl = useContext(WhitelabelingPaletteContext);
  /*
  const overwrite = useMemo(() => {
    if (!wl.theme) {
      return muiTheme;
    }

    return {
      ...muiTheme,
      palette: {
        ...muiTheme.palette,
        sentiment: {
          ...muiTheme.palette.sentiment,
          primary: {
            ...muiTheme.palette.sentiment.primary,
            main: wl.palette.primary.main,
            light: wl.palette.primary.light,
            dark: wl.palette.primary.main,
          },
        },
      },
    };
  }, [
    muiTheme,
    wl,
  ]);
  */
  const overwrite = useThemeOverride(muiTheme);
  return (
    <MUIThemeProvider theme={overwrite}>
      <ChatSkinContext.Provider value={ChatSkin.Sentiment}>
        {children}
      </ChatSkinContext.Provider>
    </MUIThemeProvider>
  );
};