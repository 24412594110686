import * as xform from '@services/api/transformers/helpers';
import { safeJsonDate } from '@utils';
import type { Call, CallRecord, CompletedCall } from '@/types';

type CallType<T = string> =
  T extends Call<T>
    ? Call<T>
    : T extends CallRecord<T>
      ? CallRecord<T>
      : Call<T> & Partial<CallRecord<T>>;

export const normalizeCall = <T extends CallType<string> = CallType<string>, U extends CallType<Date> = CallType<Date>>(call: T) => {
  const runTransforms = xform.compose<U, T>(
    normalizeCallDates,
  );

  return runTransforms(call);
};

export const normalizeCompletedCall = (data: CompletedCall<string>): CompletedCall => {
  return Object.assign(data, {
    createdAt: safeJsonDate(data.createdAt),
    timeStart: safeJsonDate(data.timeStart),
    timeEnd: safeJsonDate(data.timeEnd),
  });
};

export const normalizeCalls = <T extends CallType<string>[] = CallType<string>[]>(calls: T) => {
  return xform.toMap<number, CallType<Date>>(calls.map(normalizeCall));
};

function normalizeOrUndefined(value: string): Date {
  return typeof value !== 'undefined'
    ? safeJsonDate(value)
    : undefined;
}

function normalizeCallDates<T extends CallType<string> = CallType<string>, U extends CallType<Date> = CallType<Date>>(call: T): U {

  const value = Object.assign(call, {
    approvedOn: normalizeOrUndefined(call.approvedOn),
    createdAt: normalizeOrUndefined(call.createdAt),
    screenedOn: normalizeOrUndefined(call.screenedOn),
    timeEnd: normalizeOrUndefined(call.timeEnd),
    timeStart: normalizeOrUndefined(call.timeStart),
  });

  return value as unknown as U;
}