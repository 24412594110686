import qs from 'query-string';
import type { Companies } from '@api/interfaces';
import http from '@services/http';

export const fetchOverview = (data: Companies.FetchOverview.Request) => {
  return http.get<Companies.FetchOverview.Response>(`/companies/${data.slug}`);
};

export const fetchEmployees = (data: Companies.FetchEmployees.Request) => {
  const query = qs.stringify({
    c: data.cursor,
    current: data.current ? 'true' : 'false',
  });
  return http.get<Companies.FetchEmployees.Response>(`/companies/${data.slug}/employees?${query}`);
};
