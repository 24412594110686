import { Fragment } from 'react';
import type { TableCellItem } from './interfaces';
import styles from './style/Search.Members.css';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <Fragment>
      {props.row.original.countryCodes.length ? props.row.original.countryCodes.join(', ') : '-'}
    </Fragment>
  );
};

Cell.displayName = 'Column.Rate.Country';