import { useCallback } from 'react';
import type { SurveyLogic } from '@/types/survey';
import { SurveyLogicItemContainer, SurveyMatchContainer, useSurveyLogicBuilderContext } from '@containers/SurveyBuilder.Logic';
import { AddLogicButton, SurveyLogicItemBuilder, SurveyLogicItemPreview } from '@/components/SurveyBuilder.Logic';
import styles from './style/QuotasBuilder.css';

export const SurveyLogicBuilder = () => {
  const state = useSurveyLogicBuilderContext();

  const renderItem = useCallback((item: SurveyLogic.Item, ordinal: number) => {
    if (item.identifier === state.editing.identifier) {
      return (
        <SurveyLogicItemContainer initial={item}>
          <SurveyLogicItemBuilder />
        </SurveyLogicItemContainer>
      );
    } else {
      return (
        <SurveyMatchContainer value={{
          conditions: item.conditions,
          rule: item.rule,
        }}>
          <SurveyLogicItemPreview
            hideContextMenu={!!state.editing.identifier}
            ordinal={ordinal}
            item={item} />
        </SurveyMatchContainer>
      );
    }
  }, [state.editing]);

  return (
    <div className={styles.root}>
      {state.items.map((item, i) => (
        <div key={item.identifier}>
          {renderItem(item, i + 1)}
          {i !== (state.items.length - 1) && <div className={styles.divider} />}
        </div>
      ))}
      <div className={styles.addItem}>
        {!state.editing.identifier && <AddLogicButton />}
      </div>
    </div>
  );
};

export default SurveyLogicBuilder;