import http from '@services/http';
import type { Groups } from '$admin/api/interfaces';

export const getPreCallRecording = ({ groupId }: Groups.GetPreCallRecording.Request): Promise<Groups.GetPreCallRecording.Response> => {
  return http.get(`/admin/groups/${groupId}/pre-call-recording`);
};

export const savePreCallRecording = ({ groupId, ...body }: Groups.SavePreCallRecording.Request): Promise<Groups.SavePreCallRecording.Response> => {
  return http.post(`/admin/groups/${groupId}/pre-call-recording`, body);
};

export const getPreCallRecordingPreviewUrl = ({ groupId }: Groups.GetPreCallRecordingPreviewUrl.Request): Promise<Groups.GetPreCallRecordingPreviewUrl.Response> => {
  return http.get(`/admin/groups/${groupId}/pre-call-recording/preview`);
};

export const generatePreCallRecordingPreview = ({ groupId, ...body }: Groups.GeneratePreCallRecordingPreview.Request): Promise<Groups.GeneratePreCallRecordingPreview.Response> => {
  return http.post(`/admin/groups/${groupId}/pre-call-recording/preview`, body);
};