import { useCallback, useEffect, useRef } from 'react';
import type { ConjointAnalysisQuestion } from '@/types';
import { RadioButton } from '@/components/Radio';
import styles from './style/ConjointAnalysis.Set.css';

type Props = {
  attributes: ConjointAnalysisQuestion.Attribute[];
  onChange: (conceptNo: number) => unknown;
  set: ConjointAnalysisQuestion.Form.Set;
  value: ConjointAnalysisQuestion.RespondentAnswer.SetAnswer;
};

export const ConjointAnalysisSet = ({
  attributes,
  onChange,
  set,
  value,
}: Props) => {

  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    scrollRef.current?.scrollTo({
      left: 0,
    });
  }, [set.index]);

  const NoneOfTheAbove = useCallback(() => {
    if (!set.includeNoneOfTheAbove) return null;

    return (
      <div className={styles.none}>
        None of the above
      </div>
    );
  }, [set.includeNoneOfTheAbove]);

  const Attribute = useCallback(({ attribute }: { attribute: Pick<ConjointAnalysisQuestion.Attribute, 'id' | 'value'> }) => {
    return (
      <div className={styles.attribute}>
        {attribute.value}
      </div>
    );
  }, []);

  const Level = useCallback(({ level }: { level: ConjointAnalysisQuestion.Level }) => {
    return (
      <div className={styles.level}>
        <div className={styles.levelWrap}>
          {level.value}
        </div>
      </div>
    );
  }, []);

  const Set = useCallback(() => {
    return (
      <div className={styles.set}>
        {attributes.map(attribute => (
          <div key={attribute.id} className={styles.row}>
            <Attribute attribute={attribute} />
            {set.concepts.map((pkg, i) => (
              <Level
                key={i}
                level={pkg[attribute.id]} />
            ))}
          </div>
        ))}
      </div>
    );
  }, [
    attributes,
    set,
    Attribute,
    Level,
  ]);

  const onSelect = useCallback((conceptNo: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(conceptNo);
  }, [onChange]);

  const Form = useCallback(() => {

    return (
      <div className={styles.form}>
        <div className={styles.formWrap}>
          <div className={styles.selection} />
          {set.concepts.map((_, i) => (
            <div key={i} className={styles.selection}>
              <RadioButton
                checked={value?.selected == i}
                onChange={onSelect(i)} />
            </div>
          ))}
          {set.includeNoneOfTheAbove &&
            <div className={styles.selection}>
              <RadioButton
                checked={value?.selected === null}
                onChange={onSelect(null)} />
            </div>
          }
        </div>
      </div>
    );
  }, [
    onSelect,
    set.includeNoneOfTheAbove,
    set.concepts,
    value?.selected,
  ]);

  return (
    <div ref={scrollRef} className={styles.root}>
      <div className={styles.concepts}>
        <Set />
        <NoneOfTheAbove />
      </div>
      <Form />
    </div>
  );
};

export default ConjointAnalysisSet;