import { useMemo } from 'react';
import { useSurveyBuilderItem, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { useDeleteSurveyMessage, useSurveyMessageBuilderItemContext } from '@/containers/SurveyBuilder.Message';
import { useMoveSurveyItemModal } from '@/components/SurveyBuilder/Modal.MoveItem';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';

type Props = {
  className?: string;
};

export const SurveyMessageContextMenu = ({ className }: Props) => {

  const [surveyState, dispatch] = useSurveyBuilderState();
  const item = useSurveyBuilderItem();
  const message = useSurveyMessageBuilderItemContext();

  const section = useMemo(() => {
    return surveyState.survey.sections.find(s => s.identifier === item.section.identifier);
  }, [surveyState.survey.sections, item.section.identifier]);

  const onDelete = useDeleteSurveyMessage();
  const [toggleMoveModal, MoveSurveyItemModal] = useMoveSurveyItemModal();

  const edit = useMemo(() => {
    return {
      children: 'Edit',
      disabled: !message.metadata.canEdit,
      onClick: () => {
        dispatch({
          type: 'toggle-editing',
          itemIdentifier: item.identifier,
        });
      },
    };
  }, [dispatch, item.identifier, message.metadata.canEdit]);

  const remove = useMemo(() => {
    return {
      children: 'Delete',
      disabled: !message.metadata.canDelete,
      onClick: onDelete,
    };
  }, [message.metadata.canDelete, onDelete]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      // disabled: !message.metadata.canDelete,
      onClick: toggleMoveModal,
    };
  }, [toggleMoveModal]);

  const anchor = useMemo(() => {
    if (!section.metadata.randomize) return null;
    return {
      children: 'Anchor',
      checked: message.metadata.anchor,
      onClick: () => {
        dispatch({
          type: 'message-metadata-updated',
          messageIdentifier: message.identifier,
          metadata: {
            anchor: !message.metadata.anchor,
          },
        });
      },
    };
  }, [dispatch, message.identifier, message.metadata.anchor, section.metadata.randomize]);

  const items: ContextMenuItemProps[] = [
    edit,
    move,
    remove,
    anchor,
  ].filter(Boolean);

  return (
    <>
      <SurveyBuilderContextMenu
        className={className}
        items={items} />
      <MoveSurveyItemModal item={item} />
    </>
  );
};