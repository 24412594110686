import { Fragment, memo } from 'react';
import { formatCurrency } from '@utils/format';
import type { Results } from '../interfaces/members';

export const Cell = memo(({ row }: Results.TableCellProps) => {
  return (
    <Fragment>
      {row.original.user.hourlyRate != null ? formatCurrency(row.original.user.hourlyRate, 'USD', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}
    </Fragment>
  );
});

export const Filter = memo(() => null);

export const Header = memo(() => {
  return (
    <Fragment>
      Rate
    </Fragment>
  );
});

Cell.displayName = 'Column.Rate.Cell';
Filter.displayName = 'Column.Rate.Filter';
Header.displayName = 'Column.Rate.Header';