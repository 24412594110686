import { useContext } from 'react';
import type { AxiosResponse } from 'axios';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { Transcripts } from '@api/interfaces';
import type { Highlight } from '@/types/transcribe.rich-text';
import { TranscriptHighlightActionsContext } from '@containers/Transcript/context.highlights';
import { TranscriptCommentsStateContext } from '@containers/Transcript.Commenting/Context';
import { useChainedCommands } from './useCommands';

type Data = Transcripts.RemoveTranscriptHighlight.Response;
type Variables = {
  highlight: Highlight;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useRemoveHighlight(options?: Options) {

  const { removeHighlight } = useContext(TranscriptHighlightActionsContext);
  const commands = useChainedCommands();
  const [_, dispatch] = useContext(TranscriptCommentsStateContext);

  const remove = useMutation({ mutationKey: ['remove-transcript-highlight'], mutationFn: removeHighlight, ...options, onMutate: v => {
    commands
        .blurHighlight()
        .removeHighlights([v.highlight.id])
        .run();
  }, onSuccess: (r, v, ctx) => {
    dispatch({
      type: 'highlight-removed',
      highlightIdentifier: v.highlight.id,
    });

    options?.onSuccess?.(r, v, ctx);
  }, onError: (e, v) => {
    commands
        .addHighlight(v.highlight)
        .run();
  } });

  return remove;
}