import { useParams } from 'react-router-dom';
import { ActionableLinkType } from '@enums';
import { qs } from '@utils';
import { AdHocCallRejection } from './AdHocCallRejection';
import { AdHocCallTimeConfirmation } from './AdHocCallTimeConfirmation';
import { ProjectCallTimeConfirmation } from './ProjectCallTimeConfirmation';
import { RecommendedConsultantApproval } from './RecommendedConsultantApproval';
import type { DecodedParams, QueryParams, URLParams } from './interfaces';

type Props = unknown;

export const ActionableLinkContainer = (props: Props) => {
  const urlParams = useParams<URLParams>();
  const raw = qs.parse<QueryParams>(location.search);

  switch (+raw.type) {
    case ActionableLinkType.RecommendedConsultantApproval: {
      const decoded = decode<ActionableLinkType.RecommendedConsultantApproval>();
      const params = {
        action: +decoded?.action,
      };

      return (
        <RecommendedConsultantApproval
          params={params}
          token={urlParams.token} />
      );
    }

    case ActionableLinkType.ProjectCallTimeConfirmation: {
      const decoded = decode<ActionableLinkType.ProjectCallTimeConfirmation>();
      const params = {
        end: new Date(decoded.end),
        start: new Date(decoded.start),
      };

      return (
        <ProjectCallTimeConfirmation
          params={params}
          token={urlParams.token} />
      );
    }

    case ActionableLinkType.AdHocCallRejection: {
      return (
        <AdHocCallRejection
          token={urlParams.token} />
      );
    }

    case ActionableLinkType.AdHocCallTimeConfirmation: {
      const decoded = decode<ActionableLinkType.AdHocCallTimeConfirmation>();
      const params = {
        end: new Date(decoded.end),
        start: new Date(decoded.start),
      };

      return (
        <AdHocCallTimeConfirmation
          params={params}
          token={urlParams.token} />
      );
    }
  }

};

function decode<T extends ActionableLinkType>() {
  return qs.base64Decode<DecodedParams<T>>(location.search);
}