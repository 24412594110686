import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import type * as API from '@api/interfaces';

const useFetchComplianceMessageThreadReview = () => {

  return useAsyncStateLazy((data: API.Groups.Compliance.FetchMessageThreadReview.Request) => {

    return api.groups.compliance.fetchMessageThreadReview({
      groupId: data.groupId,
      threadId: data.threadId,
    });

  });

};

export { useFetchComplianceMessageThreadReview };
export default useFetchComplianceMessageThreadReview;