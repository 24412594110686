import { useCallback } from 'react';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import type { SurveyQuestionType } from '@/enums';
import type { ShortTextResponseProps } from './interfaces';
import { Rationale } from './Rationale';
import { ThemedInput } from './ThemedInput';

type Props = ShortTextResponseProps;

export const ShortTextResponseQuestion = ({ className, item }: Props) => {

  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.ShortTextResponse>();

  const updateAnswer = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer({
      value: e.target.value,
    });
  }, [setAnswer]);

  const props = {
    className,
    onChange: updateAnswer,
    placeholder: 'Enter an answer',
    value: answer?.value,
  };

  return (
    <>
      <ThemedInput {...props} />
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

export default ShortTextResponseQuestion;