import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import * as consts from '@consts';
import { useDevice, useCookie } from '@utils';
import { useHasConsultantRole } from '@containers/User';
import type { BannerHookReturnType } from './interfaces';

const selectProfileCompleted = (state: Store.State) => state.user.profile.status?.completed;
const selectOnboardingCompleted = (state: Store.State) => state.onboarding.completed;

const useAppBanner = (): BannerHookReturnType => {
  const device = useDevice();

  const onboardingCompleted = useSelector(selectOnboardingCompleted);
  const profileCompleted = useSelector(selectProfileCompleted);
  const hasConsultantRole = useHasConsultantRole();

  const [cookieValue, setCookie, clearCookie] = useCookie(consts.cookies.loggedInAppPrompt);

  const visible = useMemo(() => {
    return cookieValue !== 'dismiss' &&
      device.phone &&
      hasConsultantRole &&
      profileCompleted &&
      onboardingCompleted;
  }, [
    cookieValue,
    device.phone,
    hasConsultantRole,
    onboardingCompleted,
    profileCompleted,
  ]);

  const handleDismissal = useCallback(() => {
    setCookie({
      expires: addDays(new Date(), 14),
      value: 'dismiss',
    });
  }, [setCookie]);

  return [visible, handleDismissal];
};

export { useAppBanner };
export default useAppBanner;