import { memo } from 'react';
import type { FileIconProps } from './interfaces';

export const ArchiveIcon = memo(({
  className,
  size = 24,
}: FileIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g
id="Artboard"
stroke="none"
strokeWidth="1"
fill="none"
fillRule="evenodd"
strokeLinecap="round"
strokeLinejoin="round">
        <g
id="Group-3"
transform="translate(4.000000, 2.000000)"
stroke="#4A4A4A"
strokeWidth="2">
          <g id="Group-8">
            <g id="Group-6">
              <path d="M10,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18 C0,19.1045695 0.8954305,20 2,20 L14,20 C15.1045695,20 16,19.1045695 16,18 L16,6 L10,0 Z" id="Path" />
            </g>
          </g>
        </g>
        <polygon
id="Path-8"
stroke="#4A4A4A"
transform="translate(12.000000, 12.208128) rotate(-180.000000) translate(-12.000000, -12.208128) "
points="11 11.416257 13 11.416257 12 13" />
        <line
x1="12"
y1="9"
x2="12"
y2="9.75463428"
id="Path-7"
stroke="#4A4A4A"
strokeWidth="1.5" />
        <line
x1="12"
y1="6"
x2="12"
y2="6.75463428"
id="Path-7"
stroke="#4A4A4A"
strokeWidth="1.5" />
        <line
x1="12"
y1="3"
x2="12"
y2="3.75463428"
id="Path-7"
stroke="#4A4A4A"
strokeWidth="1.5" />
      </g>
    </svg>
  );
});

export default ArchiveIcon;