import { useEffect, useState } from 'react';
import type { TranscriptComment } from '@/types/transcribe.rich-text';
import { TranscriptCommentReplyActionsVisibilityContext, TranscriptCommentReplyVisibilityContext, TranscriptParentCommentIdentifiersContext } from './Context';
import { useTranscriptCommentFocus } from './hooks';

type Props = {
  children: React.ReactNode;
  item: Pick<TranscriptComment, 'highlight' | 'parentComment'>;
};

export const TranscriptCommentingCommentParentContainer = ({ children, item }: Props) => {
  const [isReplyOpen, setReplyOpen] = useState(false);
  const [replyActionsVisible, setReplyActionsVisible] = useState(false);

  const [isCommentFocused] = useTranscriptCommentFocus(item.parentComment.identifier);

  useEffect(() => {
    if (isCommentFocused) {
      setReplyOpen(true);
    }
  }, [
    isCommentFocused,
  ]);

  return (
    <TranscriptCommentReplyVisibilityContext.Provider value={[isReplyOpen, setReplyOpen]}>
      <TranscriptCommentReplyActionsVisibilityContext.Provider value={[replyActionsVisible, setReplyActionsVisible]}>
        <TranscriptParentCommentIdentifiersContext.Provider value={item}>
          {children}
        </TranscriptParentCommentIdentifiersContext.Provider>
      </TranscriptCommentReplyActionsVisibilityContext.Provider>
    </TranscriptCommentReplyVisibilityContext.Provider>
  );
};

TranscriptCommentingCommentParentContainer.displayName = 'Transcript.Commenting.Comment.Parent.Container';