import { Fragment } from 'react';
import { cx } from '@utils';
import { Input } from '@/components/Input';
import { useLoginForm } from '$website/containers/Auth/hooks/useLoginForm';
import type * as Auth from '$website/containers/Auth/interfaces';
import styles from './style/AuthForm.css';

type Props = {
  autoFocus?: boolean;
  className?: string;
};

export const Login = ({
  autoFocus = false,
  ...props
}: Props) => {
  const [login, dispatch] = useLoginForm();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      key: e.target.name as keyof Auth.Email.Login.State,
      value: e.target.value,
      type: 'value',
    });
  }

  const classes = cx(styles.row, props.className);

  return (
    <Fragment>
      <div className={classes}>
        <Input
          autoFocus={autoFocus}
          autoComplete="on"
          name="email"
          onChange={handleChange}
          placeholder="Email"
          type="email" />
      </div>
      <div className={classes}>
        <Input
          autoComplete="on"
          name="password"
          onChange={handleChange}
          placeholder="Password"
          type="password" />
      </div>
    </Fragment>
  );
};

Login.displayName = 'AuthForm.Email.Login';