import type { SurveyLogic } from '@/types/survey';
import { cx } from '@utils';
import { SurveyCondition } from './Preview.Condition';
import styles from './style/Preview.css';
import { SurveyConditionNamePreview } from './Preview.Condition.Name';

type Props = {
  conditions: SurveyLogic.SurveyCondition[];
};

export const SurveyConditions = ({ conditions }: Props) => {

  return (
    <div>
      <div className={styles.label}>Conditions</div>
      <div className={styles.conditions}>
        {conditions.map((condition, i) => (
          <div key={condition.identifier} className={styles.condition}>
            <SurveyConditionNamePreview
              item={condition}
              ordinal={i + 1} />
            <SurveyCondition item={condition} />
          </div>
        ))}
      </div>
    </div>
  );
};