import { useCallback } from 'react';
import { useSurveyQuestionConditionValue } from '@containers/SurveyBuilder.Logic';
import { OperatorDropdown as Dropdown } from '@presentation/SurveyBuilder';
import type { SurveyLogic } from '@/types';
import styles from './style/Builder.Condition.Question.css';

export const OperatorDropdown = () => {

  const [condition, updateConditionValue] = useSurveyQuestionConditionValue<SurveyLogic.ConditionWithOperator>();

  const selectedOperator = condition.value?.operator;

  const handleUpdate = useCallback((operator: Operator) => {
    updateConditionValue({
      ...condition.value,
      operator,
    });
  }, [updateConditionValue, condition]);

  return (
    <div className={styles.operatorDropdown}>
      <Dropdown
        onSelect={handleUpdate}
        value={selectedOperator} />
    </div>
  );
};