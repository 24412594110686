import { memo } from 'react';
import type { Validation } from '@containers/SurveyBuilder/interfaces';
import { cx } from '@utils';
import styles from './style/Warning.css';

type Props = {
  className?: string;
  result: Validation.AssertionResult;
};

export const Warning = memo(({ className, result }: Props) => {

  if (result.success === true) return null;

  return (
    <div className={cx(styles.warning, className)}>
      {result.message}
    </div>
  );
});

export default Warning;