import { useCallback, useEffect, useMemo, useState } from 'react';
import { GrayOutlineButton } from '@/presentation/Buttons';
import { cx } from '@utils';
import { useParseSurveyRichText, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { QuestionDropdownItem } from '@presentation/SurveyBuilder';
import { QuestionDropdown } from '@presentation/SurveyBuilder';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import ModalHeader from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import { canReuseOptions, canReuseRows } from './ReuseQuestionValues.utils';
import { QuestionValuesSourceDropdown } from './ReuseQuestionValues.SourceDropdown';
import styles from './style/ReuseQuestionValues.Modal.css';

type Props = {
  onSubmit: (sourceIdentifier: string, source: Source) => void;
  questions: QuestionDropdownItem[];
} & Pick<ModalProps,
      'open' |
      'onClose'>;

type Source = 'options' | 'rows';

export const ReuseQuestionModal = ({ onSubmit, open, onClose, questions }: Props) => {

  const [questionIdentifier, setQuestionIdentifier] = useState<string>();
  const [valuesSource, setValuesSource] = useState<Source>(null);

  const [state] = useSurveyBuilderState();
  const getQuestionText = useParseSurveyRichText();

  const selectedQuestion = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === questionIdentifier);
  }, [
    questionIdentifier,
    state.survey.questions,
  ]);

  const sourceDropdownItems = useMemo(() => {
    if (!selectedQuestion) return [];

    const options = canReuseOptions(selectedQuestion);
    const rows = canReuseRows(selectedQuestion);

    const items = [
      options && 'options',
      rows && 'rows',
    ].filter(Boolean) as Source[];

    return items;
  }, [selectedQuestion]);

  useEffect(() => {
    if (sourceDropdownItems.includes('options')) {
      setValuesSource('options');
      return;
    }
    if (sourceDropdownItems.includes('rows')) {
      setValuesSource('rows');
      return;
    }
    setValuesSource(null);
  }, [sourceDropdownItems]);

  const handleSubmit = useCallback(() => {
    onSubmit(questionIdentifier, valuesSource);
    onClose();
  }, [
    onClose,
    onSubmit,
    questionIdentifier,
    valuesSource,
  ]);

  const canSubmit = !!questionIdentifier && !!valuesSource;

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Reuse Question Values" />

      <div className={styles.body}>
        <div className={styles.questions}>
          <QuestionDropdown
            items={questions}
            onSelect={setQuestionIdentifier}
            value={selectedQuestion ? {
              base: selectedQuestion.base,
              ordinal: selectedQuestion.ordinal,
              value: getQuestionText(selectedQuestion.value),
            } : null} />
        </div>
        <div className={styles.sources}>
          {selectedQuestion &&
            <QuestionValuesSourceDropdown
              disabled={sourceDropdownItems.length !== 2}
              onSelect={setValuesSource}
              items={sourceDropdownItems}
              value={valuesSource} />}
        </div>
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Submit"
          variant="brick" />
      </div>

    </Modal>
  );
};

export const useReuseQuestionModal = () => useModal(ReuseQuestionModal);