import { useCallback, useContext, useMemo, useState } from 'react';
import { Check, Edit2, X } from 'react-feather';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import { useToggle } from '@utils';
import type * as API from '$admin/api/interfaces';
import * as api from '$admin/api';
import { SelectSearchUnderlined } from '$admin/components';
import { CallDetailsContext } from './Context';
import styles from './style/Field.Owner.css';

type Props = {
  editable?: boolean;
  label:     string;
};

export const Owner = (props: Props) => {
  const ctx = useContext(CallDetailsContext);
  const [editing, toggle] = useToggle(false);
  const [owner, setOwner] = useState(ctx.query.data?.call?.owner);

  const mutation = useMutation({ mutationKey: [
    `put:admin/calls/access`,
    ctx.query.data?.call?.id,
    owner?.id,
  ], mutationFn: () => {
    return api.calls.updateAccess({
      callId: ctx.query.data.call.id,
      ownerId: owner.id,
    })
    .then(() => ctx.query.refetch());
  }, onSuccess: toggle });

  const handleCancel = useCallback(() => {
    toggle();
    setOwner(ctx.query.data.call.owner);
  }, [
    ctx.query.data.call.owner,
    setOwner,
    toggle,
  ]);

  return (
    <LabelWithEdit
      disabled={mutation.isLoading}
      editable={props.editable}
      editing={editing}
      label={props.label}
      onCancel={handleCancel}
      onEdit={toggle}
      onSubmit={mutation.mutate}>
      {!editing && ctx.query.data.call.owner.name}
      {editing &&
        <Editable
          onChange={setOwner}
          value={owner} />}
    </LabelWithEdit>
  );
};

Owner.displayName = 'Call.Main.Field.Owner';

type EditableProps = {
  onChange: (val: UserOfImportance) => unknown;
  value?:   UserOfImportance;
};

const Editable = (props: EditableProps) => {
  const ctx = useContext(CallDetailsContext);
  const [keyword, setKeyword] = useState('');

  const query = useQuery({ queryKey: QK.Admin.Groups.Users.Get({
    groupId: ctx.query.data.project.groupId,
    keyword,
  }), queryFn: () => {
    return api.groups.getGroupUsers({
      groupId: ctx.query.data.project.groupId,
      size: 15,
      value: keyword,
    });
  }, enabled: !!ctx.query.data.project.groupId && !!keyword.trim().length, refetchOnWindowFocus: false });

  const field = useMemo(() => {
    const items = query.data?.items ?? [];

    return {
      count: items.length,
      options: items.map(x => ({
        email: x.email,
        id: x.id,
        name: x.name,
      })),
    };
  }, [
    query.data?.items,
  ]);

  return (
    <SelectSearchUnderlined
      autoFocus
      defaultValue={props.value ?? field.options?.find?.(x => x.id === props.value?.id)}
      getOptionLabel={x => `${x.name} (${x.email})`}
      getOptionValue={x => String(x.id)}
      onChange={props.onChange}
      onInputChange={setKeyword}
      options={field.options}
      placeholder="Input name"
      styles={{
        control: {
          minHeight: 30,
        },
      }}
      value={field.options?.find?.(x => x.id === props.value?.id)} />
  );
};

Editable.displayName = 'Call.Field.Owner.Editable';

type LabelWithEditProps = {
  children:  React.ReactNode;
  disabled?: boolean;
  editable?: boolean;
  editing?:  boolean;
  label:     string;
  onEdit?:   () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
};

const LabelWithEdit = ({ children, label, ...props }: LabelWithEditProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <span>{label}</span>
        {(props.editable && !props.editing) &&
          <div className={styles.field}>
            <Edit2
              className={styles.edit}
              onClick={props.onEdit}
              size={16} />
          </div>}
        {(props.editable && props.editing) &&
          <div className={styles.field}>
            <X
              className={styles.cancel}
              onClick={props.onCancel}
              size={19} />
            {!props.disabled &&
              <Check
                className={styles.check}
                onClick={props.onSubmit}
                size={19} />}
          </div>}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

type UserOfImportance = API.Calls.FetchCall.Response['call']['owner'];