import { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as api from '$website/api';

type Props = {
  onChange: (data: boolean) => unknown;
};

const CaptchaCheckbox = (props: Props) => {

  const handleChange = useCallback(val => {
    api.visitors.verifyCaptcha({ token: val })
      .then(res => props.onChange(res.success))
      .catch(e => console.log(e));
  }, [props.onChange]);

  useEffect(() => {
    ReactDOM.render(
      <ReCAPTCHA
        sitekey="6LdctuEUAAAAAEMuXfAzQU-9Vb51OhaTrk3ZtzIs"
        onChange={handleChange} />,
      document.getElementById('recaptcha'),
    );

    return () => {
      ReactDOM.unmountComponentAtNode(document.getElementById('recaptcha'));
    };
  }, [handleChange]);

  return (
    <div id="recaptcha" />
  );
};

export { CaptchaCheckbox };
export default CaptchaCheckbox;