import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import cuid from 'cuid';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { uploadSurveyImageAttachment } from '@api/surveys';
import type { Surveys } from '@api/interfaces';
import { useSurveyBuilderState, useTargetProductProfileData } from '@containers/SurveyBuilder/hooks';

type MutationParams = ImageObject;
type Res = Surveys.UploadImageAttachment.Response;

type ImageObject = {
  blob: Blob;
  identifier: string;
};

type Options =
  UseMutationOptions<
    Res,
    unknown,
    MutationParams>;

export const useUploadTPPImage = (options?: Options) => {

  const { isLoading, mutateAsync } = useMutation({ mutationKey: ['upload-tpp-image'], mutationFn: (data: MutationParams) => {
    return uploadSurveyImageAttachment(data);
  }, ...options });

  const handleSubmitUpload = useCallback(async (file: File) => {
    const identifier = cuid();

    return mutateAsync({
      identifier,
      blob: file,
    })
    .then(res => {
      return {
        cid: identifier,
        src: res.attachment.url,
      };
    });
  }, [mutateAsync]);

  return {
    isLoading,
    onSubmit: handleSubmitUpload,
  };
};

export const useHandleTPPFileEvent = (options?: Options) => {

  const {
    isLoading,
    onSubmit,
  } = useUploadTPPImage(options);

  const handleFileSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const validContentTypes = [
        'image/jpeg',
        'image/png',
      ];

      const isValid = validContentTypes.includes(file.type);

      if (isValid) {
        onSubmit(file);
      }
    }

  }, [onSubmit]);

  return {
    handleFileSelect,
    isLoading,
  };
};

export const useRemoveTPPImg = () => {
  const [_, dispatch] = useTargetProductProfileData();

  return useCallback(() => {
    dispatch({
      type: 'template-tpp-updated',
      value: null,
    });
  }, [dispatch]);
};

export const useTemplateTPP = () => {
  const [templateData, dispatch] = useTargetProductProfileData();
  const [tppUrl, setTppUrl] = useState(templateData.tppUrl);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'template-tpp-url-updated',
      value: tppUrl,
    });
  }, [dispatch, tppUrl]);

  return {
    tppUrl,
    setTppUrl,
    handleSubmit,
  };
};