import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { TranscriptEntitiesContext, RefetchTranscriptContext } from '@containers/Transcript/context';
import type { TranscriptEntityType } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import type { Transcribe } from '@/types';
import { Header } from '@/components/Modal/Header';
import { Modal } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { SelectUnderlined } from '$admin/components/Select/Select.Underlined';
import { SelectSearchUnderlined } from '$admin/components/Select/Select.Search.Underlined';
import styles from './style/Entities.css';

type Props = {
  id: number;
  onClose: () => void;
  type: TranscriptEntityType;
  types: {
    id: string;
    name: string;
  }[];
  value: string;
} & ITranscriptId;

export const EditEntitiesModal = (props: Props) => {
  const [hiddenType, setHiddenType] = useState<TranscriptEntityType>(props.type);
  const [name, setName] = useState(props.value);
  const [type, setType] = useState<TranscriptEntityType>(props.type);
  const refetchTranscriptQuery = useContext(RefetchTranscriptContext);
  const entities = useContext(TranscriptEntitiesContext);

  const options = useMemo(() => {
    return [...entities.items]
      .filter(x => x.id !== props.id)
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [
    entities.items,
    props.id,
  ]);

  const isValidNewOption = useCallback((val: string) => {
    return val?.trim()?.length > 1
      && !entities.items.some(x => x.value.toLowerCase() === val?.trim()?.toLowerCase?.());
  }, [entities.items]);

  const mutation = useMutation({ mutationKey: [
    `put:transcripts/entities`,
    props.transcriptId,
    props.id,
  ], mutationFn: () => {
    return api.transcripts.updateTranscriptEntity({
      entityId: props.id,
      transcriptId: props.transcriptId,
      target: {
        name,
        type,
      },
    })
      .then(() => {
        return Promise.all([
          refetchTranscriptQuery(),
          entities.query.refetch(),
        ]);
      })
      .then(props.onClose);
  } });

  const disabled = useMemo(() => {
    return (name?.length || 0) < 1
      || props.type === type && props.value === name;
  }, [
    name,
    props.type,
    props.value,
    type,
  ]);

  const handleChange = useCallback((item: Transcribe.Entity) => {
    setName(item.value);
    setHiddenType(item.entityType);
    setType(item.entityType);
  }, []);

  const handleCreate = useCallback((val: string) => {
    setName(val);
  }, []);

  return (
    <Modal
      classes={{
        root: styles.modal,
        wrap: styles.main,
      }}
      disableOverlayClick={mutation.isLoading}
      onClose={props.onClose}
      open>
      <Header>{`Change '${props.value}' Entity`}</Header>
      <div className={styles.content}>
        <div className={styles.field}>
          <div className={styles.label}>Name</div>
          <div className={styles.select}>
            <SelectSearchUnderlined<Pick<Transcribe.Entity, 'entityType' | 'value'>>
              creatable
              createOptionPosition="first"
              formatCreateLabel={value => value}
              getOptionLabel={x => !x.entityType ? `Create '${x.value}'` : x.value}
              getOptionValue={x => x.value}
              isValidNewOption={isValidNewOption}
              onChange={handleChange}
              onCreateOption={handleCreate}
              maxMenuHeight={200}
              options={options}
              placeholder="Select Entity"
              value={{ entityType: hiddenType, value: name }} />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Type</div>
          <div className={styles.type}>
            <SelectUnderlined
              onChange={e => setType(e.target.value as unknown as TranscriptEntityType)}
              options={props.types}
              value={type} />
          </div>
        </div>
      </div>
      <ButtonSet className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          disabled={disabled}
          implicitDisable={false}
          loading={mutation.isLoading}
          onClick={() => mutation.mutate()}>
          Save
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

EditEntitiesModal.displayName = 'Transcript.Entities.Modal.EditEntity';