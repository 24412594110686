import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFiltersState } from '@containers/UniversalSearch/hooks';
import { getLocationFor } from '@utils';
import type { IUniversalSearch } from '@/types';
import styles from './style/SeeAll.css';

type Props = {
  tab: IUniversalSearch.SearchPageQueryTab;
};

export default function SeeAll({ tab }: Props) {
  const [query] = useFiltersState();

  const to =
    useMemo(() => getLocationFor.universalsearch.search({ query: query.q, tab }), [query.q, tab]);

  return (
    <Link className={styles.root} to={to}>
      {`See All >`}
    </Link>
  );
}

export { SeeAll };