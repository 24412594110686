import { useCallback, useEffect, useRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as consts from '@consts';
import * as actions from '@actions';
import { useLogout } from '@containers/AppStateEffect/hooks/useLogout';
import type { LocationListener } from '@services/history';
import { history, logLocationChange } from '@services/history';

type Props = {
  children: React.ReactElement;
};

export const BrowserHistory = (props: Props) => {
  const locationChange = useLocationChange();
  const initializeHistory = useInitializeHistory();
  const logout = useLogout();

  useEffect(() => {

    initializeHistory();

    const unlisten = history.listen((location, action) => {
      locationChange(location, action);

      if (location.pathname === consts.path.Website.Logout) {
        logout({
          sideEffects: true,
        });
      }
    });

    return () => unlisten();

  }, [
    logout,
    initializeHistory,
    locationChange,
  ]);

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

BrowserHistory.displayName = 'BrowserHistory';

type Location = Parameters<LocationListener>[0];
type Action = Parameters<LocationListener>[1];

const useInitializeHistory = () => {
  const initialized = useRef(false);
  const location = useLocation();
  const locationChange = useLocationChange();
  const logout = useLogout();

  const initializeHistory = () => {
    if (initialized.current) return;

    locationChange(location, 'INIT');

    if (location.pathname === consts.path.Website.Logout) {
      logout({
        sideEffects: true,
      });
    }

    initialized.current = true;
  };

  return useCallback(initializeHistory, [
    logout,
    location,
    locationChange,
  ]);
};

const useLocationChange = () => {
  const dispatch = useDispatch();

  const locationChange = (location: Location, action: Action | 'INIT') => {
    logLocationChange(location, action as Action);

    dispatch(actions.locationChange({
      action,
      location,
    }));
  };

  return useCallback(locationChange, [dispatch]);
};