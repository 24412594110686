import { useQuery } from '@tanstack/react-query';
import * as api from '$admin/api';

type Props = {
  projectId: number;
};

export function useFetchProjectDetails({ projectId }: Props) {
  return useQuery({ queryKey: ['admin-project-details', projectId], queryFn: () => {
    return api.projects.details.fetchProjectDetails({
      projectId,
    });
  }, enabled: !!projectId, refetchOnWindowFocus: false, staleTime: 5000 });
}