import { useCallback, useMemo } from 'react';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks';
import type { SurveyQuestionType } from '@enums';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { OpenedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import type { SurveyQuestionOption } from '@/types';
import { useOptionsColors } from './hooks/usePaletteColors';
import ResponseOptionUsers from './ResponseOptionUsers';

type Props = {
  colorFn?: (opt: SurveyQuestionOption) => string;
};

export const SurveyResponseOptionsOptionData = ({ colorFn }: Props) => {
  const { query, resetQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MultipleChoice>();
  const { question, rationale, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MultipleChoice>();
  const colors = useOptionsColors();

  const option = useMemo(() => {
    return question.options.find(f => f.base.id === query.optionId);
  }, [
    question.options,
    query.optionId,
  ]);

  const data = useMemo(() => {
    return responses[option.base.id];
  }, [
    option.base.id,
    responses,
  ]);

  const renderHeader = useCallback(() => {
    const subtitle = `(${data.userIds.length} ${formatPlurality('Respondent', data.userIds.length)})`;

    return (
      <OpenedResponseOptionRow
        onClose={resetQueryState}
        color={colorFn ? colorFn(option) : colors[option.ordinal - 1]}
        label={parseSurveyRichText(option.value)}
        subtitle={subtitle} />
    );
  }, [
    data,
    colors,
    option,
    resetQueryState,
    colorFn,
  ]);

  const renderUsers = useCallback(() => {
    return (
      <ResponseOptionUsers
        userIds={data.userIds}
        text={data.text}
        option={option}
        rationaleMap={rationale}
        question={question} />
    );
  }, [
    data,
    option,
    question,
    rationale,
  ]);

  return (
    <>
      {renderHeader()}
      {renderUsers()}
    </>
  );
};

export default SurveyResponseOptionsOptionData;