import http from '@services/http';
import type { Users } from './interfaces';
import { normalizeNotification } from './transformers';

export const getNotifications = () => {
  return http.get<Users.GetNotifications.Response>(`/users/notifications`)
    .then(data => data.items.map(normalizeNotification));
};

export const dismissNotifications = (data: Users.DismissNotifications.Request) => {
  return http.post(`/users/notifications/dismiss`, data);
};

export const markNotificationsSeen = (data: Users.MarkNotificationsSeen.Request) => {
  return http.post(`/users/notifications/seen`, data);
};