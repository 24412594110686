import { getLocationFor, phone } from '@utils';
import type { Conference } from '$admin/interfaces/call.details';

export const resolveDialIn = (data: Pick<Conference, 'id' | 'phoneNumbers'>) => {
  if (!data?.id) return 'Not Set';

  const [num] = data.phoneNumbers || [];
  const value = phone.format(num?.phone) || '-';

  return value;
};

export const resolveLinks = (data: Pick<Conference, 'id' | 'identifier' | 'guestPin'>) => {
  if (!data?.id) {
    return {
      guest: `Not Set`,
      member: `Not Set`,
      pin: (pin: string | number) => `Not Set`,
    };
  }

  const loc = getLocationFor.call.conference(data.identifier);
  const link = loc?.pathname
    ? `${process.env.FRONT_BASE_URL}${loc.pathname}`
    : '-';

  return {
    guest: loc?.pathname ? `${link}?pin=${data.guestPin}` : '-',
    member: link,
    pin: (pin: string | number) => loc?.pathname ? `${link}?pin=${pin}` : '-',
  };
};