import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { PreCallRecording } from '@/types';

type Fetch = () => Promise<unknown>;
type Save = () => Promise<unknown>;
type Preview = (value: string) => Promise<unknown>;

export const PreCallRecordingFetchContext = createNamedContext<Fetch>(undefined, 'PreCallRecordingFetchContext');
export const PreCallRecordingPreviewContext = createNamedContext<Preview>(undefined, 'PreCallRecordingPreviewContext');
export const PreCallRecordingLoadingContext = createNamedContext<boolean>(undefined, 'PreCallRecordingLoadingContextPreCallRecordingLoadingContext');
export const PreCallRecordingValueContext = createNamedContext<PreCallRecording>(undefined, 'PreCallRecordingValueContext');
export const PreCallRecordingUrlContext = createNamedContext<string>(undefined, 'PreCallRecordingUrlContext');
export const PreCallRecordingSaveContext = createNamedContext<Save>(undefined, 'PreCallRecordingSaveContext');

export const usePreCallRecordingFetch = () => useContext(PreCallRecordingFetchContext);
export const usePreCallRecordingPreview = () => useContext(PreCallRecordingPreviewContext);
export const usePreCallRecordingLoading = () => useContext(PreCallRecordingLoadingContext);
export const usePreCallRecordingValue = () => useContext(PreCallRecordingValueContext);
export const usePreCallRecordingUrl = () => useContext(PreCallRecordingUrlContext);
export const usePreCallRecordingSave = () => useContext(PreCallRecordingSaveContext);