import { useContext, useCallback, useState, useMemo } from 'react';
import { TranscriptSocketEvent } from '@enums';
import { TranscriptRedactionSocketContainer } from '@containers/Transcript.Redaction';
import { TranscriptIdContext } from '@containers/Transcript/context';
import type { Transcripts } from '@services/websocket/interfaces';
import style from './style/TranscriptLocked.css';

enum State {
  InProgress,
  Complete,
  Error,
}

export const TranscriptLocked = () => {
  const transcriptId = useContext(TranscriptIdContext);

  const [state, setState] = useState<State>(State.InProgress);

  const onRedactionComplete: Transcripts.HandlerList[TranscriptSocketEvent.RedactionComplete] = useCallback(() => {
    setState(State.Complete);
  }, []);

  const onRedactionError: Transcripts.HandlerList[TranscriptSocketEvent.RedactionError] = useCallback(() => {
    setState(State.Error);
  }, []);

  const handlers = useMemo(() => {
    return {
      [TranscriptSocketEvent.RedactionComplete]: onRedactionComplete,
      [TranscriptSocketEvent.RedactionError]: onRedactionError,
    };
  }, [onRedactionError, onRedactionComplete]);
  return (
    <TranscriptRedactionSocketContainer
      handlers={handlers}
      transcriptId={transcriptId}>
      <div className={style.root}>
        {StateMessageMap[state]}
      </div>
    </TranscriptRedactionSocketContainer>
  );
};

const StateMessageMap: Record<State, string> = {
  [State.InProgress]: 'This transcript is currently being processed. Please check back later (Maximum 15 minutes).',
  [State.Complete]: 'The transcript has finished processing. Please reload the page to view the transcript.',
  [State.Error]: 'There was an error processing this transcript. Please try again later or contact support.',
};