import { useMemo } from 'react';
import type { SurveyAIEGroup } from '@/types';
import { useSurveyAlternateImagesBuilderItemContext } from '@containers/SurveyBuilder.AIE';
import { ConceptNumber } from '@presentation/Survey';
import styles from './style/Preview.css';

export const Preview = () => {
  const item = useSurveyAlternateImagesBuilderItemContext();
  return (
    <div>
      <div className={styles.groups}>
        {item.groups.map(g => (
          <Group
            key={g.identifier}
            group={g} />
        ))}
      </div>
      <Settings />
    </div>
  );
};

const Group = ({ group }: { group: SurveyAIEGroup }) => {
  return (
    <div className={styles.group}>
      <ConceptNumber name={group.name} />
      {group.entries.sort((a, b) => a.ordinal - b.ordinal).map(i => (
        <img
          className={styles.image}
          key={i.identifier}
          src={i.imageUrl} />
      ))}
    </div>
  );
};

const Settings = () => {
  const item = useSurveyAlternateImagesBuilderItemContext();

  const timingText = useMemo(() => {
    if (!item.settings.delaySeconds) return 'Manual navigation';
    return `Flash Test (${item.settings.delaySeconds} seconds per concept)`;
  }, [item.settings.delaySeconds]);

  return (
    <div className={styles.settings}>
      <div className={styles.settingsTitle}>Settings</div>
      <ul>
        <li>{timingText}</li>
        {item.settings.randomize && <li>Randomize Concepts</li>}
      </ul>
    </div>
  );
};