import { Fragment, memo } from 'react';
import { cx } from '@utils';
import styles from './style/GDPR.css';

export const PrivacyNotice = memo(() => {
  return (
    <Fragment>
      <div className={styles.p}>
        {`This Research Privacy Policy (the "Policy") is intended to provide you with information about what personal data Trinity (referred to here as “Trinity,” “we” or “us”) collects about you and how it is used.`}
      </div>
      <div className={styles.p}>
        {`This Policy describes how we may obtain personal data, the types of personal data we may obtain, how we use, share, and protect the personal data, the rights of data subjects with respect to their personal data, and how to contact us about our privacy practices.`}
      </div>
      <div className={styles.p}>
        {`This Policy does not apply to personal data other than the personal data relating to primary research. For the Privacy Policy for personnel or representatives of our clients, suppliers and other entities with whom we have entered, or may enter, business transactions, please see our Business Associate Privacy Policy.`}
      </div>
      <div className={styles.p}>
        {`We may revise this Privacy Policy at any time, without notice to you. You are responsible for reviewing it regularly. Your access of the Sites following the posting of changes means you agree to abide by those changes.`}
      </div>
      <div className={styles.p}>
        {`This Privacy Policy was last revised on January 28, 2025.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`About Trinity:`}
      </div>
      <div className={styles.p}>
        {`This Privacy Policy (the “Policy”) describes how Trinity Partners, LLC. (referred to here as “Trinity,” “we” or “us”) operates a panel of members (called “Sentiment”) who take part in and “Activity” and "Activities" (including market research, non-interventional studies, observational studies, and other surveys) on behalf of our clients. This Policy relates to the products and services offered by Trinity and its research panel Sentiment via `} <A url="www.sentimentglobal.com" /> {` (the “Site(s)”) and describes how we may obtain personal data, the types of personal data we may obtain, how we use, share, and protect the personal data, the rights of data subjects with respect to their personal data, and how to contact us about our privacy practices.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`The Types of Personal Data We May Obtain and Process:`}
      </div>
      <div className={styles.p}>
        {`Trinity, through its research panel Sentiment, collects personal data about its members and those that participate in our Activities. We use this personal data to provide you with services as described below. We do not sell personal data to anyone and only share it with third parties who are facilitating the delivery of our services or when you give us explicit additional permission.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data You Give Us About Yourself When You Register:`}
      </div>
      <div className={styles.p}>
        {`We collect the personal data you provide on the registration form; your name, address, telephone number, email address, information about your occupation, and if you are a US-based healthcare professional, your National Provider Information Number (NPI) or state licensing number so that we can target and tailor invitations to participate in Activities. (See “Personal Data You Give Us Within Your Membership Profile” for more information about how we select Activity invitations for you)`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data You Give Us Within Your Membership Profile:`}
      </div>
      <div className={styles.p}>
        {`Trinity collects additional personal data either before Activities or within the site. This is used to allow us to invite you to participate in Activities that you are more likely to be interested in and qualify for. For example, for physicians, this may include information about your medical experience and the types of patients you treat.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data You Give Us Within and Immediately Before or After Online Activities:`}
      </div>
      <div className={styles.p}>
        {`Activities should not contain requests for you to provide personal data. However, Trinity maintains systems that control access to and measure outcomes (for example; if you completed the Activity or failed to qualify) so we may need to collect personal data before and/or after the Activity. This data is not linked directly to the Activity data and is only passed to third parties with your explicit consent and/or as laid out in this Policy. (See also “Payment Details You Give Us for Compensation Payments” and “Personal Data We Collect and Retain for an Extended Period”)`}
      </div>
      <div className={styles.p}>
        {`Online Activities offered to members of `} <A url="www.sentimentglobal.com" /> {` may be on a website provided by Trinity or one provided by a third party. In the latter case, any personal data collected will be subject to the host’s privacy policy. If you take part in other forms of market research with Trinity, for example, discussion groups or telephone interviews, then you will be asked to provide specific consent for any necessary processing of your personal data.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data Related to Your Previous Participation in Activities:`}
      </div>
      <div className={styles.p}>
        {`We collect and store records of the Activities you have been invited to participate in, whether you responded to them, qualified for them, completed or partially completed them. We keep this data so that we make it available via the Sites and can respond to member enquiries. On occasion we may also send you invitations to new Activities based upon your participation in previous Activities.`}
      </div>
      <div className={styles.p}>
        {`We use this data in aggregate to understand how many individuals of any type may participate in any future Activity and to understand trends in and patterns of participation.`}
      </div>
      <div className={styles.p}>
        {`We also maintain records of any data quality issues that may have been identified in the data you provided within an Activity so we can manage the quality of the data we provide to clients – this may affect your invitation to future Activities.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Cookies and Similar Technologies:`}
      </div>
      <div className={styles.p}>
        {`Our website, `} <A url="https://sentimentglobal.com/" /> {` uses cookies and other related technologies (referred to in this Policy as “Cookies”). Cookies are small pieces of information sent by a web server to a web browser which allows the server to uniquely identify the browser on each page. More information about the types of Cookies that we may use and the purposes for which they are used can be found in our Cookie Policy.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Payment Details You Give Us for Compensation Payments:`}
      </div>
      <div className={styles.p}>
        {`We collect and use specific personal data so that we can process payment of your Activity compensation. The personal data we need to use varies depending on the form of payment but can include name, mailing address, email address, bank account details, credit/debit card details, social security number, or tax ID. This is used only to process these payments but may be shared with payment processing partners to process the payment (e.g., we must share bank account details with our bank to process a bank transfer to your account). This personal financial data is retained only for financial reporting, audit and tax purposes.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data We Collect and Retain for an Extended Period:`}
      </div>
      <div className={styles.p}>
        {`In order to manage its business, Trinity needs to retain limited personal data about previous members for longer periods of time. We have carefully limited the personal data retained and the circumstances in which it is needed to the following:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Requests by individuals to not email them`}</li>
        <li className={styles.li}>{`Requests by individuals not to be contacted by Trinity or Sentiment`}</li>
        <li className={styles.li}>{`Individuals who have objected to Trinity processing their personal data (where there is not a legally permissible reason for us continuing to do so)`}</li>
        <li className={styles.li}>{`Individuals who have breached the Sites Terms of Use, including those suspected of fraudulent behavior`}</li>
      </ul>
      <div className={styles.p}>
        {`This personal data is only used to honor your requests or to prevent subsequent Activity participation.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data We Get From Third Parties:`}
      </div>
      <div className={styles.p}>
        {`Clients sometimes send us lists of healthcare professionals who they would like us to include or exclude in specific Activities. We also subscribe to various lists of healthcare professionals that we use to validate registrations and to append a variety of unique identification numbers to assist with deduplicating registrations, matching client lists and with legal reporting obligations. Lists of healthcare professionals are also used for direct marketing purposes.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data We May Collate About You:`}
      </div>
      <div className={styles.p}>
        {`We use a variety of public sources to collate information about healthcare professionals we believe may be suitable to complete specific Activities or may wish to become members of www.sentimentglobal.com.  We may also receive a referral from one of your colleagues and process that information in a similar way. The information would include your name, contact information (address, phone number(s) and possibly email address) and your healthcare specialty.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Purpose and Legal Basis for Processing Your Personal Data:`}
      </div>
      <div className={styles.p}>
        {`This data is used to manage our contact with you, so that, for example, multiple attempts to contact you regarding the same services are eliminated or you are not re-contacted after informing us you are not interested in our services.`}
      </div>
      <div className={styles.p}>
        {`The legal bases we rely upon to offer these services to you are:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Where we have a legitimate interest (i.e., we have a valid business reason) and we have carefully balanced your individual rights against this need;`}</li>
        <li className={styles.li}>{`To fulfill a contractual obligation that we have with you; and/or`}</li>
        <li className={styles.li}>{`Where required, your consent for direct marketing, international transfer, and collection of sensitive information.`}</li>
      </ul>
      <div className={styles.p}>
        {`We will only process your personal data for the purpose it was first collected. If we process the personal data for a new purpose, we will ensure it is either compatible with your original purpose or gain your consent.`}
      </div>
      <div className={styles.p}>
        {`We may use the personal data we obtain for the purpose of: `}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Providing you with the services and support that you may request, and processing and validating your Activities;`}</li>
        <li className={styles.li}>{`Communicating with you about your account or Activities, changes to our policies and other administrative matters, or responding to your questions and comments;`}</li>
        <li className={styles.li}>{`Understanding better your interests in order to provide you with customized market research opportunities and other content;`}</li>
        <li className={styles.li}>{`Administering our research surveys and studies, and improving all of our products, services and operations;`}</li>
        <li className={styles.li}>{`Ensuring compliance with our policies and Website Terms and Conditions, or as we believe is necessary to protect, enforce or defend the legal rights, privacy, safety or property of ourselves and others;`}</li>
        <li className={styles.li}>{`Complying with our legal and regulatory requirements; and`}</li>
        <li className={styles.li}>{`Helping us better search for, communicate with, and validate prospective panel members in our systems in order to match them with particular market research needs.`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`How We May Share Personal Data:`}
      </div>
      <div className={styles.p}>
        {`Trinity does not sell, rent, or trade member’s personal data. We may share your personal data only with:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Affiliates of Trinity to whom it is reasonably necessary or desirable for Trinity to disclose the personal data`}</li>
        <li className={styles.li}>{`Service providers that Trinity has retained to perform services on its behalf, such as, but not limited to, IT service providers. Service providers are not permitted to use the personal data for their own purposes and are prohibited from onward transfer of the personal data without our written consent in each instance`}</li>
        <li className={styles.li}>{`Law enforcement and other government authorities if required by law or reasonably necessary to protect the rights, property, and safety of others or ourselves. This includes lawful requests by public authorities, including to meet national security or law enforcement requirements`}</li>
        <li className={styles.li}>{`Our clients or carefully selected partners, which will, where possible, be anonymized data only. No identifiable personal data will be shared without your consent.`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Links To Other Websites:`}
      </div>
      <div className={styles.p}>
        {`Our website may contain links to other websites. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting other websites and these other websites are not governed by this Privacy Policy. You should exercise caution and look at the privacy statement applicable to the website in question.`}
      </div>
      <div className={styles.p}>
        {`Trinity submits to being subject to the investigatory and enforcement powers of the FTC or any other U.S. authorized statutory body.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`How We Protect Personal Data:`}
      </div>
      <div className={styles.p}>
        {`We maintain appropriate technical and organizational security safeguards designed to protect personal data against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. We update and test our security technology on an ongoing basis. We limit access to your personal data to those employees who need access to provide benefits or services to you. In addition, we train our employees about the importance of confidentiality and maintaining the privacy and security of your personal data.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`How Long We Retain Personal Data:`}
      </div>
      <div className={styles.p}>
        {`We store personal data for as long as necessary to fulfil the purposes for which we collect the data (see "How We Use the Personal Data We Obtain"), except if required otherwise by law. Your personal data may be kept for 10 years after the working relationship has ceased.`}
      </div>
      <div className={styles.p}>
        {`However, in order to manage its business, Trinity needs to retain limited personal data about previous members for longer periods of time. We have carefully limited the personal data retained and the circumstances in which it is needed to the following:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Requests by individuals to not email them`}</li>
        <li className={styles.li}>{`Requests by individuals not to be contacted by Trinity or Sentiment `}</li>
        <li className={styles.li}>{`Individuals who have objected to Trinity processing their personal data (where there is not an overwhelming interest in us continuing to do so)`}</li>
        <li className={styles.li}>{`Individuals who have breached the Sites Terms of Use, including those suspected of fraudulent behavior`}</li>
      </ul>
      <div className={styles.p}>
        {`This personal data is not shared with anyone and is only used to honor your requests or to prevent subsequent Activity participation.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`International Transfers of Personal Data:`}
      </div>
      <div className={styles.p}>
        {`Your personal data may be transferred, processed and/or stored in a country other than the one in which your personal data is collected, including but not limited to the United States, Brazil, Canada, Germany, India, Italy, Mexico, Portugal, and the United Kingdom. Trinity has implemented procedures to ensure that appropriate safeguards are in place to protect the personal data regardless of where it is being transferred to. Where we transfer your data internationally, we will do so on the basis of: (i) an adequacy decision; (ii) your consent; (iii) model contractual clauses; or (iv) another valid transfer mechanism.  For information on the safeguards applied to such transfers, please contact us at `} <Mail email="Compliance@trinitypartners.com" /> {`.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Changes And Updates to The Privacy Policy:`}
      </div>
      <div className={styles.p}>
        {`As our organization, membership and benefits change from time to time, this Privacy Policy and our Terms of Use are expected to change as well. We reserve the right to amend the Privacy Policy and Terms of Use at any time, for any reason, without notice to you, other than the posting of the amended Privacy Policy and Terms of Use at this Site. We may e-mail periodic reminders of our Privacy Policy and Terms of Use and will e-mail members of material changes to it, but you should check our Site frequently to see the current Privacy Policy and Terms of Use that are in effect and any changes that may have been made to them.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Your Rights:`}
      </div>
      <div className={styles.p}>
        {`If you wish to confirm that Trinity is processing your personal data, or to have access to the personal data Trinity may have about you, please contact us.`}
      </div>
      <div className={styles.p}>
        {`You may request information about:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`the purpose of the processing;`}</li>
        <li className={styles.li}>{`the legal basis for that processing;`}</li>
        <li className={styles.li}>{`the categories of personal data and the data subject concerned;`}</li>
        <li className={styles.li}>{`information on the type or identity of third parties to which your data may be disclosed to and the protection provided;`}</li>
        <li className={styles.li}>{`the source of the personal data (if you didn’t provide it directly to us); and`}</li>
        <li className={styles.li}>{`how long it will be stored.`}</li>
      </ul>

      <div className={styles.p}>
        {`You have a right to:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`access your personal data`}</li>
        <li className={styles.li}>{`have inaccurate personal data rectified`}</li>
        <li className={styles.li}>{`request erasure of personal data`}</li>
        <li className={styles.li}>{`restrict the processing of your personal data`}</li>
        <li className={styles.li}>{`object to the processing your personal data`}</li>
        <li className={styles.li}>{`data portability`}</li>
        <li className={styles.li}>{`opt out of data being transferred to a third party, unless there is a legal reason to do so (see "How we may share Personal Data")`}</li>
        <li className={styles.li}>{`opt out of direct marketing`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Privacy Breaches and Complaints `}
      </div>
      <div className={styles.p}>
        {`You also have a right to complain to a data protection authority where you live or work or where you believe a breach may have occurred.`}
      </div>
      <div className={styles.p}>
        {`If you are aware of, or are the victim of, a suspected privacy breach in connection to your relationship with us, you should immediately contact us using the contact details in the “Contact Us” section below. If you have a complaint about the way we have managed your personal data (please write to us using the contact details in the “Contact Us” section below. Once we have received your complaint, we will seek to resolve your concerns as soon as possible and in any case within the time prescribed by the applicable privacy law. If you are dissatisfied with the outcome of your complaint, you may also be able to refer your privacy complaint to the appropriate regulatory body or enforcement authority. `}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Withdrawing Consent or Otherwise Objecting to Direct Marketing:`}
      </div>
      <div className={styles.p}>
        {`Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your data for other purposes, such as those set out above. In some cases, we are able to send you direct marketing without your consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of any direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out in the “Contact Us” section below.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Contact Us:`}
      </div>
      <div className={styles.p}>
        {`If you have questions about this Privacy Policy or would like to exercise your rights, please email us at `} <Mail email="Compliance@trinitypartners.com" /> {`.`}
      </div>
      <div className={styles.p}>
        {`Reasonable access to your personal data will be provided at no cost to you upon your request. Trinity will provide the information to you within the legal time frame. If for some reason access is denied, Trinity will provide an explanation as to why access has been denied.`}
      </div>
      <div className={styles.p}>
        {`For all other questions regarding your account, preference changes, compensation for surveys or our services, please e-mail us at `} <Mail email="Compliance@trinitypartners.com" /> {`.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`SUPPLEMENTAL PRIVACY NOTICE FOR CALIFORNIA RESIDENTS ONLY`}
      </div>
      <div className={styles.p}>
        {`Effective Date: January 1, 2020`}
      </div>
      <div className={styles.p}>
        {`Last Revised: January 28, 2025`}
      </div>
      <br />
      <div className={styles.p}>
        {`This notice supplements the information contained in the main Trinity Privacy Policy (the "Main Privacy Policy") set forth above and applies solely to residents of the State of California ("California Notice at Collection"). We have adopted our California Privacy Policy in order to comply with the California Consumer Privacy Act of 2018 ("CCPA") as amended by the California Privacy Rights Act of 2020 (“CPRA”).`}
      </div>
      <div className={styles.p}>
        {`Our California Notice at Collection is intended to fulfill CCPA's objectives of providing California consumers with a comprehensive disclosure of the collection, sharing, disclosure, and sale of their personal data by particular businesses (as CCPA defines those terms), and of the rights that California consumers have regarding their personal data ("California Privacy Rights").`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Personal Data We Collect:`}
      </div>
      <div className={styles.p}>
        {`Collected information from California residents over the past 12 months may include:`}
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>{`Category of Information Collected`}</th>
            <th>{`Examples`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{`Personal Identifiers`}</td>
            <td>{`Name, address, email, phone number, social security number, driver's license number, passport number`}</td>
          </tr>
          <tr>
            <td>{`Personal data`}</td>
            <td>{`Name, address, email, phone number, bank account details, social security number, driver's license number, passport number, signature, education `}</td>
          </tr>
          <tr>
            <td>{`Protected classification characteristics under California or federal law.`}</td>
            <td>{`Age, nationality, citizenship, marital status place and date of birth`}</td>
          </tr>
          <tr>
            <td>{`Professional or Employment Information`}</td>
            <td>{`Registration details for healthcare professionals, professional information about your occupation, NPI information, state licensing information, records of the Activities participated in, qualified for, completed or partially completed.`}</td>
          </tr>
          <tr>
            <td>{`Internet or Other Similar Network Activity`}</td>
            <td>{`Cookies, web beacons, device fingerprinting/watermarking, IP address`}</td>
          </tr>
          <tr>
            <td>{`Inferences`}</td>
            <td>{`Consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`}</td>
          </tr>
        </tbody>
      </table>

      <div className={cx(styles.p, styles.b)}>
        {`Sensitive Personal Data:`}
      </div>
      <div className={styles.p}>
        {`Subject to the relevant jurisdictional requirements, Trinity may process sensitive personal data. Trinity will only use and disclose sensitive personal data for a purpose for which it was originally collected. Unless we request it, we ask that you not send us, or disclose, any sensitive personal data.`}
      </div>
      <div className={styles.p}>
        {`The sensitive data that Trinity processes may include:`}
      </div>
      <ul className={styles.ul}>
        <li className={styles.li}>{`Information related to racial or ethnic origin.`}</li>
        <li className={styles.li}>{`A social security, driver’s license, state identification card, or passport number.`}</li>
        <li className={styles.li}>{`An individual’s precise geolocation.`}</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        {`Purposes for Collecting Personal Data:`}
      </div>
      <div className={styles.p}>
        {`We collect personal data for business or commercial purposes set forth in “Purpose and Legal Basis for Processing Your Personal Data”, respectively, in the Privacy Policy above.`}
      </div>
      <div className={styles.p}>
        {`Trinity does not sell your personal data or share your personal data for the purpose of cross context behavioral advertising, as defined by the California Privacy Rights Act (CPRA) of 2020.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Retention`}
      </div>
      <div className={styles.p}>
        {`We retain the categories of personal data set forth above in the “Personal Data We Collect” section of this Supplemental California Notice of Collection for those business or commercial purposes described in “Purposes for Collecting Personal Data” in this Supplemental California Notice of Collection and as set forth in “How Long We Retain Personal Data” in the main Privacy Policy above, except as may be required under applicable law, court order or government regulations.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Disclosure of Personal Data:`}
      </div>
      <div className={styles.p}>
        {`Within the last twelve (12) months, we have disclosed each of the following categories of personal data listed in the table below to third-party service providers as described in “How We May Share Personal Data” in the main Privacy Policy above, and we disclose personal data for the purposes of: (a) preventing fraud or violations of our Terms of Service; (b) analyzing site user data and behavior, and tracking page performance; (c) identifying site issues and repair errors that impair intended functionality; (d) providing marketing and survey panel recruitment assistance, such as moderators and translators; (e) providing customer service to our Site users; (f) providing CCPA-required and other verifications of consumers or consumer consent; and/or (g) providing data storage and/or data processing services; and purposes as set forth in “Purpose and Legal Basis for Processing Your Personal Data” in the Privacy Policy above. `}
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>{`Personal Data Category`}</th>
            <th>{`Category of Third-Party Recipients`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{`Personal Identifiers`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
          <tr>
            <td>{`Personal data`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
          <tr>
            <td>{`Protected classification characteristics under California or federal law.`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
          <tr>
            <td>{`Professional or Employment Information`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
          <tr>
            <td>{`Internet or Other Similar Network Activity`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
          <tr>
            <td>{`Inferences`}</td>
            <td>{`Affiliates of Trinity, service providers that Trinity has retained to perform services on its behalf, law enforcement and other government authorities`}</td>
          </tr>
        </tbody>
      </table>

      <div className={cx(styles.p, styles.b)}>
        {`California Privacy Rights Under The CCPA:`}
      </div>
      <div className={styles.p}>
        {`You have certain rights under the CCPA in respect of the personal data we hold and which you may exercise. These rights are:`}
      </div>
      <div className={styles.p}>
        {`Deletion rights: You have the right to request that we delete any of your personal data that we retain, subject to certain exceptions, including, but not limited to, our compliance with U.S., state, local and non-U.S. laws, rules and regulations.`}
      </div>
      <div className={styles.p}>
        {`Disclosure and access rights: You have the right to request that we disclose to you certain information regarding our collection, use, disclosure and sale of personal data specific to you over the last twelve (12) months. Such information includes:`}
      </div>

      <ul className={styles.ul}>
        <li className={styles.li}>{`The categories of personal data we collected about you;`}</li>
        <li className={styles.li}>{`The categories of sources from which the personal data is collected;`}</li>
        <li className={styles.li}>{`Our business or commercial purpose for collecting such personal data;`}</li>
        <li className={styles.li}>{`Categories of third parties or persons with whom we share the personal data;`}</li>
        <li className={styles.li}>{`The specific pieces of personal data we have collected about you; and `}</li>
        <li className={styles.li}>{`Whether we disclosed your personal data to a third party, and if so, the categories of personal data that each recipient obtained.`}</li>
      </ul>
      <div className={styles.p}>
        {`CCPA also provides you the right to receive information about any financial incentives (i.e., compensation payments) that we may offer in connection with use of your personal data for participating in certain of our Activities (as that term is defined in "About Trinity" in our Main Privacy Policy).`}
      </div>
      <div className={styles.p}>
        {`Additional rights: Under CCPA, you also have the right to:`}
      </div>

      <ul className={styles.ul}>
        <li className={styles.li}>{`Request the correction of any inaccurate personal data maintained by us about you.`}</li>
        <li className={styles.li}>{`Request that we limit the use and disclosure of your sensitive personal data. Currently, however, we do not use or disclose your sensitive personal data other than for the purpose(s) for which it was collected. `}</li>
        <li className={styles.li}>{`Choose to opt-out of the sale of personal data or the sharing of personal data for cross-context behavioral advertising. Currently, however, we do not sell or share personal data as defined under the CCPA.  `}</li>
      </ul>
      <div className={styles.p}>
        {`Your rights will in each case be subject to the restrictions set out in the CCPA. Further information on these rights, and the circumstances in which they may arise in connection with our processing of your personal data, can be obtained by contacting us.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Non-Discrimination for Exercising Your Rights:`}
      </div>
      <div className={styles.p}>
        {`We follow the requirements of California Civil Code §1798.125 and will not discriminate against any consumer who exercises the rights set forth in this Privacy Policy.`}
      </div>
      <div className={styles.p}>
        {`We will never penalize or otherwise subject you to discriminatory treatment for exercising any of your California Privacy Rights.`}
      </div>
      <div className={styles.p}>
        {`Please note that exercising your right to delete some or all your personal data may mean that you are not able to participate in certain market research opportunities that we offer.`}
      </div>
      <div className={styles.p}>
        {`For example, if we do not have enough information to determine whether you meet the criteria for a study, you will not receive invitations to participate. In addition, certain studies may require that certain aspects of your personal data be shared with the client (such as a focus group opportunity, where your name, contact information, medical background, and practice location are needed in order to convene a desired focus group).`}
      </div>
      <div className={styles.p}>
        {`Financial incentives, such as per-survey compensation payments, are made for certain of our market research Activities. Market research compensation payment amounts are set at levels determined by considering many variables such as (by way of examples only) the complexity of the subject matter of the research inquiry, the professional education, training, and experience level desired for the respondents, the number of respondents needed, and the number of qualified respondents available.`}
      </div>
      <div className={styles.p}>
        {`If you have questions about your Right to Non-Discrimination, please contact us as described ("Exercising Your California Privacy Rights") below.`}
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Exercising Your California Privacy Rights:`}
      </div>
      <div className={styles.p}>
        {`To exercise any of your rights under the CCPA, please contact us by any of the methods below:`}
      </div>
      <div className={styles.p}>{`By mail:`}</div>
      <div className={styles.p}>{`Trinity`}</div>
      <div className={styles.p}>{`230 Third Avenue`}</div>
      <div className={styles.p}>{`5th Floor`}</div>
      <div className={styles.p}>{`Waltham, MA 02451-7528`}</div>
      <br />
      <div className={styles.p}>{`By email: `} <Mail email="Compliance@trinitypartners.com" /></div>
      <div className={styles.p}>{`By phone: 1-855-56-2229`}</div>

      <div className={cx(styles.p, styles.b)}>
        {`Verifying Your Identity: `}
      </div>
      <div className={styles.p}>
        {`If you choose to contact us with a request, you will need to provide us with identifying information that matches the PII we currently have about you.`}
      </div>
      <div className={cx(styles.p, styles.b)}>
        {`Authorized Agent:`}
      </div>
      <div className={styles.p}>
        {`You have the right to appoint an authorized agent to exercise your rights on your behalf. If you would like to do so, please contact `} <Mail email="Compliance@trinitypartners.com" />
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Accessibility Information:`}
      </div>
      <div className={styles.p}>
        {`For employees with disabilities who need to access this policy in an alternative format, please contact HR at `} <Mail email="Compliance@trinitypartners.com" />
      </div>

      <div className={cx(styles.p, styles.b)}>
        {`Updates To This Supplementary Notice of Collection:`}
      </div>
      <div className={styles.p}>
        {`We may reserve the right to periodically update this Supplemental California Notice of Collection. Please reference the "LAST UPDATED" legend above for the effective date of the most recent updates.`}
      </div>
      <div className={styles.p}>
        {`This Supplemental California Notice of Collection was last updated in January 28, 2025.`}
      </div>
    </Fragment>
  );
});

// function b(text: string) {
//   return <span className={styles.b}>{text}</span>;
// }

// function u(text: string) {
//   return <span className={styles.u}>{text}</span>;
// }

// function i(text: string) {
//   return <span className={styles.i}>{text}</span>;
// }

function A(props: { url: string }) {
  return (
    <a
      className={styles.a}
      href={props.url}
      target="_blank"
      rel="noreferrer">
      {props.url}
    </a>
  );
}

function Mail(props: { email: string }) {
  return (
    <a
      className={styles.a}
      href={`mailto:${props.email}`}
      target="_blank"
      rel="noreferrer">
      {props.email}
    </a>
  );
}

function a(url: string) {
  return (
    <a
      className={styles.i}
      href={url}
      target="_blank"
      rel="noreferrer">
      {url}
    </a>
  );
}

type Props = {
  className?: string;
};

export const PrivacyNoticeHeader = memo((props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.p}>To be consistent with data protection laws, we’re asking you to take a moment to review key points of Sentiment’s GDPR Privacy Notice.</div>
      <div className={styles.p}>Scroll down and click “I agree” when you’re ready to continue.</div>
    </div>
  );
});