import { useContext } from 'react';
import { Buffer } from 'buffer/';
import { useMutation } from '@tanstack/react-query';
import type { QueryParamConfig } from 'use-query-params';
import { useQueryParam } from 'use-query-params';
import * as api from '$admin/api';
import { MatchListMutationContext, MatchListQueryParamsContext, MembersFilterContext } from '$admin/Search/Context';
import type { MatchList } from '$admin/Search/interfaces';

type Props = {
  children: React.ReactNode;
};

export const StateContainerMatchList = (props: Props) => {
  const [list, setList] = useQueryParam('list', ListParam);
  const [, dispatch] = useContext(MembersFilterContext);

  const mutation = useMutation({ mutationKey: [`post:admin/members/import/match`], mutationFn: (file: File) => {
    return api.members.import.match({
      file,
    });
  }, onMutate: () => {
    dispatch({ type: 'init' });
  }, onSuccess: res => {
    setList(res);
  } });

  return (
    <MatchListQueryParamsContext.Provider value={[list, setList]}>
      <MatchListMutationContext.Provider value={mutation}>
        {props.children}
      </MatchListMutationContext.Provider>
    </MatchListQueryParamsContext.Provider>
  );
};

StateContainerMatchList.displayName = 'StateContainerMatchList';

const ListParam: QueryParamConfig<MatchList.QueryValue, MatchList.QueryValue> = {
  encode: (state: MatchList.QueryValue) => state ? Buffer.from(JSON.stringify(state)).toString('base64') : undefined,
  decode: (q: string) => q ? JSON.parse(Buffer.from(q, 'base64').toString()) as MatchList.QueryValue : undefined,
};