import { lazy } from 'react';
import { BasicRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import type * as Routing from '@routes/interfaces';

const Research = lazy(() => import(
  /* webpackChunkName: "research" */
  '$research/Research')
);

export const routes: Routing.Route[] = [
  {
    component: Research,
    path: path.Research.Root,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.Research.Root,
});