import { useCallback, useMemo } from 'react';
import type { Slack } from '@/types';
import type { OnSelectChange } from '$admin/components/Select';
import { Select } from '$admin/components/Select';

type Props = {
  className?: string;
  items: Slack.Team[];
  onSelect: (value: Slack.Team) => void;
  selected: Slack.Team;
};

export const SlackTeamsSelect = ({
  className,
  items,
  onSelect,
  selected,
}: Props) => {

  const options = useMemo(() => {
    return items.reduce((acc, team) => ({
      ...acc,
      [team.id]: team.name,
    }), {} as {
      [id: string]: string;
    });
  }, [items]);

  const handleChange: OnSelectChange<string> = useCallback(e => {
    const id = e.target.value;
    onSelect({
      id,
      name: options[id],
    });
  }, [onSelect, options]);

  return (
    <Select
      className={className}
      id="slack-team"
      name="slack-team"
      onChange={handleChange}
      options={options}
      value={selected.id} />
  );
};

export default SlackTeamsSelect;