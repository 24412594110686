import type { AxiosResponse } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { GetVideoUrl } from '@api/interfaces/calls';

type Props = GetVideoUrl.Request;

type Data = GetVideoUrl.Response;
type QueryKey = readonly [string, Props];

export function useCallVideoUrl(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery({
    queryKey: ['call-video-url', data] as const, queryFn: ({ queryKey }) => {
      const params = queryKey[1];

      return api.calls.getVideoUrl(params);
    },
    ...options,
  });
}
