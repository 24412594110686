import { useContext } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import { MedicalBackgroundContext } from '@containers/MedicalOnboarding/Context';
import type { ButtonActivityIndicatorProps } from '@presentation';
import { ButtonActivityIndicator } from '@presentation';
import * as Layout from '@screens/ProjectCreation/Header';
import { cx } from '@utils';
import styles from './style/Layout.css';

type Props = {
  classes?: {
    root?:     string;
    title?:    string;
    subtitle?: string;
  };
  title:     string;
  subtitle?: string;
};

export const Header = ({ classes = {}, ...props }: Props) => {
  const classnames = {
    root: cx(styles.header, classes.root),
    title: cx(styles.title, classes.title),
    subtitle: cx(styles.subtitle, classes.subtitle),
  };

  return (
    <Layout.Header className={classnames.root}>
      <Layout.Title className={classnames.title}>{props.title}</Layout.Title>
      {props.subtitle && <Layout.Subtitle className={classnames.subtitle}>{props.subtitle}</Layout.Subtitle>}
    </Layout.Header>
  );
};

type FooterProps = {
  children:   React.ReactNode;
  className?: string;
};

export const Footer = (props: FooterProps) => {
  return (
    <div className={cx(styles.footer, props.className)}>
      {props.children}
    </div>
  );
};

type ScreenProps = {
  children: React.ReactNode;
  classes?: {
    root?: string;
    wrap?: string;
  };
};

export const Screen = ({ classes = {}, ...props }: ScreenProps) => {
  return (
    <div className={cx(styles.root, classes.root)}>
      <div className={cx(styles.wrap, classes.wrap)}>
        {props.children}
      </div>
    </div>
  );
};

type NextButtonProps =
  & Partial<Pick<ButtonActivityIndicatorProps, 'loading'>>
  & Omit<ButtonActivityIndicatorProps, 'loading'>;

export const NextButton = ({ className, ...props }: NextButtonProps) => {
  const ctx = useContext(MedicalBackgroundContext);

  const isMutatingCompletionStatus = useIsMutating([
    QK.Users.Onboarding.Statuses.Put,
    ctx.userId,
  ]);

  return (
    <ButtonActivityIndicator
      className={cx(styles.btn, className)}
      {...props}
      loading={props.loading || !!isMutatingCompletionStatus}>
      {props.children ?? `Next`}
    </ButtonActivityIndicator>
  );
};

export { BackButton } from '@screens/ProjectCreation/Footer';