import { safeJsonDate } from '@utils';
import type { Posts } from '$admin/api/interfaces';

export const transformAggregateItems = (data: Posts.FetchVideoPosts.Response<string>): Posts.FetchVideoPosts.Response => {
  return {
    items: data.items.map(item => ({
      author: item.author,
      post: {
        ...item.post,
        createdOn: safeJsonDate(item.post.createdOn),
      },
    })),
  };
};