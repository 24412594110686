import { useCallback, useMemo, useState } from 'react';
import { useCompetitiveIntelligenceData, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import type { SurveyTemplate } from '@/types/survey';

export const useCanSaveTarget = (target: SurveyTemplate.Target) => {

  return useMemo(() => {
    return target.value.length >= 2;
  }, [target.value]);
};

export const useTemplateTarget = () => {
  const [templateData] = useCompetitiveIntelligenceData();
  const [target, setTarget] = useState(templateData.target);
  const [_, dispatch] = useSurveyBuilderState();

  const onChange = useCallback((value: SurveyTemplate.Category) => {
    setTarget(value);
  }, []);

  const onSubmit = useCallback(() => {

    dispatch({
      type: 'template-target-updated',
      value: target,
    });
  }, [
    target,
    dispatch,
  ]);

  return {
    onChange,
    onSubmit,
    value: target,
  };
};