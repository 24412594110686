import { useEffect, useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { external as $service } from '@services/api/projects';
import { ExternalProjectSecretType, ProjectType } from '@enums';
import { Survey } from '@screens/PayoutSetup/Done';
import { useSelectProject } from '@containers/Store';
import { useAppReadyState } from '@containers/AppReadyState';
import { ProjectOnboardingContainer } from '@containers/ProjectOnboarding/ProjectOnboarding.Container';
import { Step, StepsMap } from '@containers/ProjectOnboarding/Steps';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useFetchConsultantReferralSourcingState } from '@/components/ProjectInviteReferral/hooks';
import { useParams, useCompleteProject } from './hooks';
import { RedirectWrapper } from './RedirectWrapper';
import type { SecretParams } from './interfaces';
import styles from './styles.css';

export const ProjectSuccess = () => {
  const params = useParams(ExternalProjectSecretType.Success);

  return (
    <RedirectWrapper {...params}>
      <ProjectSuccessInner {...params} />
    </RedirectWrapper>
  );
};

const ProjectSuccessInner = (props: SecretParams) => {
  const completeProject = useCompleteProject(props);
  const { initialized } = useAppReadyState();
  const project = useSelectProject(props.projectId);

  const { mutate, isLoading, isError } = useMutation({
    mutationKey: ['complete-external-project'], mutationFn: () => {
      return $service.completeProject(props);
    }, onSuccess: completeProject, onError: e => {
      alert('There was an error saving your project response.');
      throw e;
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  const sourcing = useFetchConsultantReferralSourcingState(props.projectId);

  const hasSourcing = sourcing.data?.referrals;

  if (isLoading || !initialized) {
    return <ActivityIndicator />;
  } else if (isError) {
    return <>Error</>;
  } else if (project.projectType === ProjectType.SurveyCall) {
    return <SuccessCallSchedule projectId={props.projectId} />;
  } else {
    return (
      <div className={styles.successContainer}>
        <Survey
          navigation={{
            next: () => { /*TODO: Take us to referral*/ },
            back: () => { },
            skip: () => { },
          }}
          nextLabel={'Back to Home'}
          referrals={hasSourcing} />
      </div>
    );
  }
};

const SuccessCallSchedule = ({ projectId }: IProjectId) => {
  const validSteps = useMemo(() => {
    const allSteps = StepsMap[ProjectType.SurveyCall];
    return allSteps.slice(allSteps.indexOf(Step.ExternalSurvey) + 1);
  }, []);
  return (
    <ProjectOnboardingContainer stepsFilter={step => validSteps.includes(step)} />
  );
};