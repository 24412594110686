import { SurveyConditionType } from '@/enums';
import type { SurveyLogic, SurveyBuilderVersion } from '@/types/survey';
import type { ConditionsValidationsMap } from './interfaces';
import { Validation } from './interfaces';
import { validateClassificationCondition } from './validation.condition.classification';
import { validateQuestionCondition } from './validation.condition.question';
import { validateSumCondition } from './validation.condition.sum';

export function validateConditions(conditions: SurveyLogic.SurveyCondition[], survey: SurveyBuilderVersion) {

  return conditions.reduce<ConditionsValidations>((acc, condition) => {

    if (condition.conditionType === SurveyConditionType.Question) {
      const validation = validateQuestionCondition(condition, survey);

      return {
        map: {
          ...acc.map,
          [condition.identifier]: validation,
        },
        success: acc.success &&
          validation.question.success &&
          validation.type.success &&
          validation.value.success,
      };
    } else if (condition.conditionType === SurveyConditionType.AggregatedSum) {
      const validation = validateSumCondition(condition);

      return {
        map: {
          ...acc.map,
          [condition.identifier]: validation,
        },
        success: acc.success &&
          validation.comparate.success &&
          validation.items.success &&
          validation.operator.success,
      };
    } else if (condition.conditionType === SurveyConditionType.Classification) {

      const validation = validateClassificationCondition(condition);

      return {
        map: {
          ...acc.map,
          [condition.identifier]: validation,
        },
        success: acc.success &&
          validation.value.success,
      };
    }
  }, {
    map: {},
    success: true,
  });
}

type ConditionsValidations = {
  map: ConditionsValidationsMap;
  success: boolean;
};