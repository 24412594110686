import { useMemo } from 'react';
import { useSurveyClassifications } from '@/containers/SurveyBuilder';
import type { SurveyTagging } from '@/types';
import { cx } from '@utils';
import { SurveyConditions } from './Logic.Conditions';
import styles from './style/Logic.Item.css';

type Props = {
  className?: string;
  item: SurveyTagging.Item;
};

export const SurveyTaggingItem = ({ className, item }: Props) => {

  const classifications = useSurveyClassifications();

  const classification = useMemo(() => {
    return classifications.find(f => f.identifier === item.action.value.identifier);
  }, [classifications, item.action.value.identifier]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        Classify as {classification.name}
        <span className={styles.if}>if:</span>
      </div>
      <div className={styles.conditions}>
        <SurveyConditions
          conditions={item.conditions}
          rule={item.rule} />
      </div>
    </div>
  );
};