import { useCallback } from 'react';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { useModal } from '@/components/Modal/hooks';
import type { ModalProps } from '@/components/Modal/Modal';
import { Prompt } from '@/components/Modal/Prompt';
import { Button } from '@/components/Button';

type Props = {
  onSubmit: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const DeleteQuotaAlert = ({ open, onClose, onSubmit }: Props) => {

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onSubmit}
          variant="brick">
          Remove
        </Button>
      </ButtonSet>
    );
  }, [
    onSubmit,
    onClose,
  ]);

  return (
    <Prompt
      body="Are you sure you want to remove this quota?"
      footer={Footer()}
      header="Remove Quota"
      onClose={onClose}
      visible={open} />
  );
};

export const useDeleteQuotaAlert = () => useModal(DeleteQuotaAlert);