import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveyQuestionMatrixRow, SurveySettings } from '@/types/survey';

export const useGetDisplayTotal = (total: SurveySettings.TotalSetting) => {

  const [state] = useSurveyBuilderState();

  const displayTotal = useCallback(() => {

    if (!total.comparate) {
      return '-';
    }

    let value: string;
    if (typeof total.comparate === 'object') {
      const t = total as SurveySettings.TotalSetting<SurveySettings.PipedTotalValue>;
      const question = state.survey.questions.find(f => f.base.identifier === t.comparate.question.identifier);
      if (!question) return '[Invalid]';
      const row = (question.matrixRows as SurveyQuestionMatrixRow[]).find(f => f.base.identifier === t.comparate.row.identifier);
      const option = question.options.find(f => f.base.identifier === t.comparate.option.identifier);

      if (!row || !option) return '[Invalid]';

      value = `Q${question.ordinal}~R${row.ordinal}`;
      if (option) value = `${value}~O${option.ordinal}`;
    } else if (typeof total.comparate === 'number') {
      value = `${total.comparate}`;
    }

    const operatorText = total.operator === '=' ? '' : `${total.operator} `;

    return `${operatorText}${value}`;
  }, [state.survey.questions, total]);

  return displayTotal;
};