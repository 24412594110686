import type { SurveyLogic } from '@/types/survey';
import type { ConditionsBuilder } from './interfaces';

type State = ConditionsBuilder.State;

export function classificationUpdated(state: State, action: ConditionsBuilder.ClassificationUpdated.Action): State {
  return state.reduce<State>((acc, x) => {
    if (x.identifier === action.payload.identifier) {
      const newCondition: SurveyLogic.ClassificationCondition = {
        ...x as SurveyLogic.ClassificationCondition,
        data: {
          identifier: action.payload.value,
        },
      };

      return acc.concat(newCondition);
    }

    return acc.concat(x);
  }, []);
}