import type { IUniversalSearch } from '@/types';
import FindExperts from './Results.FindExperts';
import Keyword from './Results.Keyword';
import Expert from './Results.Expert';
import Topic from './Results.Topic';
import SeeAll from './Results.SeeAll';
import styles from './style/Results.css';

type Props = {
  activeIndex: number;
  items: IUniversalSearch.SearchBarResultItem[];
};

export default function ResultsItems({ activeIndex, items }: Props) {
  return (
    <>
      {items.map((item, i) => {
        switch (item.type) {
          case 'find-experts': {
            return (
              <FindExperts
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
          case 'keyword': {
            return (
              <Keyword
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
          case 'member':
          case 'expert': {
            return (
              <Expert
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
          case 'topic': {
            return (
              <Topic
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
          case 'see-all': {
            return (
              <SeeAll
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
        }
      })}
    </>
  );
}