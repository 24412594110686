import type { Reducer } from 'react';
import { useReducer } from 'react';
import { SurveyConditionsMatchContext } from './context';
import type { SurveyMatchBuilder } from './interfaces';
import { surveyMatchReducer } from './reducer';

type Props = {
  initial: SurveyMatchBuilder.State;
} & ChildrenProps;

export const SurveyConditionsState = ({
  children,
  initial,
}: Props) => {

  const reducer = useReducer<Reducer<SurveyMatchBuilder.State, SurveyMatchBuilder.Action>>(surveyMatchReducer, initial);

  return (
    <SurveyConditionsMatchContext.Provider value={reducer}>
      {children}
    </SurveyConditionsMatchContext.Provider>
  );
};

export default SurveyConditionsState;