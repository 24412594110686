import { createNamedContext } from '@utils';
import type {
  WorkspaceGlobalSearchBarResultsContextValue,
  WorkspaceGlobalSearchBarDispatchContextValue,
  WorkspaceGlobalSearchBarFetchContextValue,
  WorkspaceGlobalSearchBarPopupContextValue,
  WorkspaceGlobalSearchBarQueryContextValue,
  WorkspaceGlobalSearchBarFiltersContextValue,
  WorkspaceGlobalSearchKeyboardContextValue,
  WorkspaceGlobalSearchBarResetContextValue,
} from './interfaces';

export const WorkspaceGlobalSearchBarResultsContext =
  createNamedContext<WorkspaceGlobalSearchBarResultsContextValue>(undefined, 'WorkspaceGlobalSearchBarResultsContext');

export const WorkspaceGlobalSearchBarDispatchContext =
  createNamedContext<WorkspaceGlobalSearchBarDispatchContextValue>(undefined, 'WorkspaceGlobalSearchBarDispatchContext');

export const WorkspaceGlobalSearchBarFetchContext =
  createNamedContext<WorkspaceGlobalSearchBarFetchContextValue>(undefined, 'WorkspaceGlobalSearchBarFetchContext');

export const WorkspaceGlobalSearchBarPopupContext =
  createNamedContext<WorkspaceGlobalSearchBarPopupContextValue>(undefined, 'WorkspaceGlobalSearchBarPopupContext');

export const WorkspaceGlobalSearchBarQueryContext =
  createNamedContext<WorkspaceGlobalSearchBarQueryContextValue>(undefined, 'WorkspaceGlobalSearchBarQueryContext');

export const WorkspaceGlobalSearchBarFiltersContext =
  createNamedContext<WorkspaceGlobalSearchBarFiltersContextValue>(undefined, 'WorkspaceGlobalSearchBarFiltersContext');

export const WorkspaceGlobalSearchKeyboardContext =
  createNamedContext<WorkspaceGlobalSearchKeyboardContextValue>(undefined, 'WorkspaceGlobalSearchKeyboardContext');

export const WorkspaceGlobalSearchBarResetContext =
  createNamedContext<WorkspaceGlobalSearchBarResetContextValue>(undefined, 'WorkspaceGlobalSearchBarResetContext');
