import * as core from './core';

const landing = {
  button: {
    cta: core.main.landing.button.signup,
    login: core.main.landing.button.login,
    enterprise: core.main.landing.button.enterprise,
    individuals: core.main.landing.button.individuals,
  },
  text: {
    copy: `We are an information marketplace where professionals exchange ideas, knowledge, and experiences in paid consulting projects.`,
    title: `Connecting the world’s brightest professionals.`,
  },
};

const banner = {
  button: {
    cta: {
      text: `Find out how much your peers are earning on our network`,
      ...core.ui.link.signup,
    },
  },
};

const benefits = {
  collection: {
    benefits: [{
      image: {
        icon: core.main.benefits.image.value,
      },
      text: {
        title: `Value`,
        copy: `Get paid for sharing your valuable expertise with our enterprise clients.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.discovery,
      },
      text: {
        title: `Discovery`,
        copy: `Connect with other business leaders to advance your career.`,
      },
    }, {
      image: {
        icon: core.main.benefits.image.speed,
      },
      text: {
        title: `Ease of Use`,
        copy: `Our mobile first experience allows for frictionless connectivity and exchange.`,
      },
    }],
  },
};

const funnel = {
  button: {
    cta: core.main.funnel.button.signup,
  },
  text: {
    cta: core.main.funnel.text.signup,
  },
};

export const content = {
  main: {
    banner,
    benefits,
    landing,
    funnel,
    press: core.main.press,
  },
  nav: {
    button: {
      cta: core.nav.button.signup,
      login: core.ui.button.login,
      signup: core.ui.button.signup,
    },
  },
  signup: null,
};