import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { GroupTagUpdateItem } from '@/types';
import { getLocationFor } from '@utils';
import List from './Tags.List';

export default function Readonly() {
  const history = useHistory();

  const handleClick = useCallback((tag: GroupTagUpdateItem) => {
    const location = getLocationFor.workspaces.search({
      keywords: [tag.name],
    });
    history.push(location);
  }, [history]);

  return <List onClick={handleClick} />;
}

export { Readonly };