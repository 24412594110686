import type { RouteProps } from 'react-router-dom';

function isValidPath(v: string) {
  return !['*', '/'].includes(v);
}

type Path = Pick<RouteProps, 'path'>;

export const enumerateRoutePaths = <T extends Path>(routes: T[]) => {
  return routes.reduce((acc, r) => {
    if (Array.isArray(r.path)) {
      return acc.concat((r.path as string[]).filter(isValidPath));
    } else if (isValidPath(r.path as string)){
      acc.push(r.path as string);
    }

    return acc;
  }, [] as string[]);
};