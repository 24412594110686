import { useContext } from 'react';
import { Button } from '@/components/Button';
import { DrawerContainer } from '@/components/AppBar/Drawer';
import { useSegmentedContent } from '$website/containers/MarketSegmentation/hooks/useSegmentedContent';
import { header } from '$website/containers/SiteNavigation';
import { MenuContext } from './Context';
import styles from './style/Drawer.css';

type Props = unknown;

export const Drawer = (props: Props) => {
  const { open } = useContext(MenuContext);
  const content = useSegmentedContent();

  return (
    <DrawerContainer
      className={styles.menu}
      open={open}
      overlayClassName={styles.overlay}>
      <nav className={styles.drawer}>

        {/* <DrawerLinks /> */}

        <div className={styles.btns}>
          <Button
            className={styles.btn}
            color="secondary"
            href={content.nav.button.login.href}
            to={content.nav.button.login.to}
            variant="brick">
            {content.nav.button.login.text}
          </Button>
          <Button
            className={styles.btn}
            color="primary"
            href={content.nav.button.signup.href}
            to={content.nav.button.signup.to}
            variant="brick">
            {content.nav.button.signup.text}
          </Button>
        </div>

      </nav>
    </DrawerContainer>
  );
};

Drawer.displayName = 'Menu.Drawer';

const DrawerLinks = (props: Props) => {
  return (
    <div className={styles.links}>
      {header.drawer.menus.map(item =>
        <div
          className={styles.section}
          key={item.name}>
          <div className={styles.title}>{item.name}</div>
          {item.items.map(x =>
            <Button
              key={x.name}
              className={styles.link}
              href={x.href}
              to={x.to}
              variant="link">
              {x.name}
            </Button>)}
        </div>)}
    </div>
  );
};