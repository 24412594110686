import { Fragment } from 'react';
import { format } from 'date-fns';
import type { TableCellItem } from './interfaces';

type Props = TableCellItem;

export const Cell = (props: Props) => {

  return (
    <Fragment>
      {format(new Date(props.row.original.respondent.addedOn), 'yyyy-MM-dd')}
    </Fragment>
  );
};

Cell.displayName = 'Column.Added.Cell';