import http from '@services/http';
import { qs } from '@utils';
import type { GetCallParticipants } from './interfaces/projects.scheduling';

export const getCallParticipants = (data: GetCallParticipants.Request) => {
  const q = data.callId
      ? `?${qs.stringify({ callId: data.callId })}`
      : data.respondentId
        ? `?${qs.stringify({ respondentId: data.respondentId })}`
        : '';

  return http.get<GetCallParticipants.Response>(`/projects/${data.projectId}/scheduling/participants${q}`);
};