import { SurveyConditionType } from '@/enums';
import type { SurveyLogic } from '@/types';

export function updateQuestionInCondition(item: SurveyLogic.SurveyCondition, oldQuestionIdentifier: string, newQuestionIdentifier: string): SurveyLogic.SurveyCondition {
  if (item.conditionType === SurveyConditionType.Question) {
    return {
      ...item,
      data: {
        ...item.data,
        question: {
          identifier: item.data.question.identifier === oldQuestionIdentifier
            ? newQuestionIdentifier
            : item.data.question.identifier,
        },
      },
    };
  } else if (item.conditionType === SurveyConditionType.AggregatedSum) {

    return {
      ...item,
      data: {
        ...item.data,
        items: item.data.items.map(i => ({
          ...i,
          question: {
            identifier: i.question.identifier === oldQuestionIdentifier
              ? newQuestionIdentifier
              : i.question.identifier,
          },
        })),
      },
    };
  }
}