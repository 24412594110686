import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import * as consts from '@consts';
import { useFetchTopic, useFollowTopic, keyUseFetchTopic } from '@utils/api';
import { TopicMembersContainer } from './TopicMembersContainer';
import { TopicNewsContainer } from './TopicNewsContainer';
import { TopicPostsContainer } from './TopicPostsContainer';
import { TopicItemContext } from './Context';

type Props = {
  children: React.ReactNode;
  enabled:  boolean;
  slug:     string;
};

export function TopicItemContainer({ slug, ...props }: Props) {
  const queryClient = useQueryClient();
  const history = useHistory();

  const query = useFetchTopic(slug, {
    onError: (error: AxiosResponse) => {
      if (error.status === 404) {
        history.replace(consts.path.Topics.NotFound);
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: props.enabled,
  });

  const followTopic = useFollowTopic(query.data?.item?.slug, {
    onMutate: data => {
      queryClient.setQueryData(keyUseFetchTopic(query.data?.item?.slug), {
        ...query.data,
        meta: {
          ...query.data.meta,
          following: data.follow,
        },
      });
    },
    onError: (err, data) => {
      queryClient.setQueryData(keyUseFetchTopic(query.data?.item?.slug), {
        ...query.data,
        meta: {
          ...query.data.meta,
          following: !data.follow,
        },
      });
    },
    onSuccess: () => {
      query.refetch();
    },
  });

  const toggleFollowing = useCallback(() => {
    followTopic.mutate({
      follow: !query.data.meta.following,
    });
  }, [
    followTopic,
    query.data?.meta?.following,
  ]);

  const ready = useMemo(() => {

    const loading = query.status === 'loading';

    return !loading;

  }, [query.status]);

  const value = {
    query,
    ready,
    toggleFollowing,
  };

  return (
    <TopicItemContext.Provider value={value}>
      <TopicStateContainer slug={slug}>
        {props.children}
      </TopicStateContainer>
    </TopicItemContext.Provider>
  );
}

TopicItemContainer.displayName = 'Topic.Item.Container';

const TopicStateContainer = (props: Omit<Props, 'enabled'>) => {
  const { query } = useContext(TopicItemContext);

  const enabled = {
    members: query.data?.meta?.hasExperts ?? false,
    news: query.data?.meta?.hasNews ?? false,
    posts: query.data?.meta?.hasPosts ?? false,
  };

  return (
    <TopicMembersContainer
      enabled={enabled.members}
      slug={props.slug}>
      <TopicPostsContainer
        enabled={enabled.posts}
        slug={props.slug}>
        <TopicNewsContainer
          enabled={enabled.news}
          slug={props.slug}>
          {props.children}
        </TopicNewsContainer>
      </TopicPostsContainer>
    </TopicMembersContainer>
  );
};

TopicStateContainer.displayName = 'Topic.StateContainer';