import http from '@services/http';
import { qs } from '@utils';
import { transformProjectLead } from './transformers/leads';
import type { Projects } from './interfaces';

export const uploadLeadsToProject = async (leadsFile: File, projectId: number) => {
  const formData = new FormData();
  formData.append('leadsFile', leadsFile, leadsFile.name);
  await http.put(`/admin/projects/${projectId}/leads`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchLeads = (params: Projects.FetchProjectLeads.Request): Promise<Projects.FetchProjectLeads.Response> => {
  const { projectId, ...data } = params;
  return http.get(`/admin/projects/${projectId}/leads?${qs.stringify(data as Record<string, unknown>, { arrayFormat: 'bracket' })}`)
    .then((data: Projects.FetchProjectLeads.Response<string>) => ({
      items: data.items.map(i => transformProjectLead(i)),
      pagination: data.pagination,
    }));
};

export const removeLeads = (params: Projects.RemoveProjectLeads.Request): Promise<Projects.RemoveProjectLeads.Response> => {
  const { projectId, ...data } = params;
  return http.post(`/admin/projects/${projectId}/leads/remove`, data);
};