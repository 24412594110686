import { useCallback } from 'react';
import { SurveyConditionType } from '@enums';
import {
  useSurveyConditionsBuilder,
  SumConditionBuilderContainer,
  QuestionConditionBuilderContainer,
  ClassificationConditionBuilderContainer,
} from '@containers/SurveyBuilder.Logic';
import type { SurveyLogic } from '@/types';
import { LogicBuilderQuestionCondition } from './Builder.Condition.Question';
import { LogicBuilderCondition } from './Builder.Condition';
import { LogicBuilderSumCondition } from './Builder.Condition.Sum';
import { LogicBuilderClassificationCondition } from './Builder.Condition.Classification';
import { AddLogicCondition } from './Builder.AddCondition';
import styles from './style/Builder.css';
import { SurveyConditionNamePreview } from './Preview.Condition.Name';

type Props = {
  className?: string;
};

export const LogicBuilderConditions = ({ className }: Props) => {

  const { items, canAddItem } = useSurveyConditionsBuilder();

  const renderCondition = useCallback((item: SurveyLogic.SurveyCondition) => {
    if (item.conditionType === SurveyConditionType.Question) {
      return (
        <QuestionConditionBuilderContainer item={item}>
          <LogicBuilderCondition>
            <LogicBuilderQuestionCondition />
          </LogicBuilderCondition>
        </QuestionConditionBuilderContainer>
      );
    } else if (item.conditionType === SurveyConditionType.AggregatedSum) {
      return (
        <SumConditionBuilderContainer item={item}>
          <LogicBuilderCondition>
            <LogicBuilderSumCondition />
          </LogicBuilderCondition>
        </SumConditionBuilderContainer>
      );
    } else if (item.conditionType === SurveyConditionType.Classification) {
      return (
        <ClassificationConditionBuilderContainer item={item}>
          <LogicBuilderCondition>
            <LogicBuilderClassificationCondition />
          </LogicBuilderCondition>
        </ClassificationConditionBuilderContainer>
      );
    }
  }, []);

  return (
    <div className={className}>
      <div className={styles.label}>Conditions</div>
      <div className={styles.conditions}>
        {items.map((item, i) => {
          return (
            <div className={styles.condition} key={item.identifier}>
              <SurveyConditionNamePreview
                item={item}
                ordinal={i + 1} />
              {renderCondition(item)}
            </div>
          );
        })}
      </div>
      {canAddItem &&
        <AddLogicCondition
          className={styles.addConditionBtn} />}
    </div>
  );
};