import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import type { TranscriptPublishType } from '@enums';
import { PublishSettingsSelect } from './Publishing.Select';

type Props = {
  publishType: TranscriptPublishType;
} & ITranscriptId;

export const PublishSettingsSelectInline = (props: Props) => {
  const [publishType, setPublishType] = useState(props.publishType);

  const mutation = useMutation({ mutationKey: [
    `put:transcripts/settings`,
    props.transcriptId,
  ], mutationFn: (type: TranscriptPublishType) => {
    return api.transcripts.settings.updateSettings({
      transcriptId: props.transcriptId,
      publishType: type,
    });
  } });

  const handleChange = useCallback((value: TranscriptPublishType) => {
    setPublishType(value);
    mutation.mutate(value);
  }, [
    setPublishType,
    mutation,
  ]);

  return (
    <PublishSettingsSelect
      onChange={e => handleChange(e.target.value)}
      value={publishType} />
  );
};

PublishSettingsSelectInline.displayName = 'PublishSettings.Select.Inline';