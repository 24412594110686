import { useCallback, useMemo } from 'react';
import type { SurveyQuestion, SurveyQuestionOption, SurveyQuestionOptionMetadata, SurveySettings } from '@/types/survey';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';

type Props = {
  question: SurveyQuestion;
  option: SurveyQuestionOption<SurveyQuestionOptionMetadata<{ isAnchored: boolean }>>;
};

export const useAnchoredOptionMenuItem = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const isAnchored = !!props.option.metadata.isAnchored;

  const toggle = useCallback(() => {
    dispatch({
      type: 'option-anchor-updated',
      payload: {
        option: { identifier: props.option.base.identifier },
        questionIdentifier: props.question.base.identifier,
        value: !isAnchored,
      },
    });
  }, [
    dispatch,
    props.question.base.identifier,
    props.option.base.identifier,
    isAnchored,
  ]);

  const anchoredMenuItem: ContextMenuItemProps = useMemo(() => {

    if (!(props.question.settings as SurveySettings.Randomization).randomization &&
      !(props.question.settings as SurveySettings.RandomizeOptions).randomizeOptions) return null;

    return {
      checked: isAnchored,
      children: 'Anchored',
      onClick: toggle,
    };
  }, [isAnchored, toggle, props.question.settings]);

  return anchoredMenuItem;
};