import { useCallback, useEffect } from 'react';
import type { PipelineSocketEvent } from '@/enums';
import type { Pipeline } from '../interfaces';
import { pipeline as $pipeline } from '../pipeline';

export const usePipelineSocketEvent = <T extends PipelineSocketEvent>(event: T, fn: Pipeline.RegisterHandler<T>, enabled = true) => {

  useEffect(() => {
    if (enabled) {
      $pipeline.on(event, fn);
      return () => {
        $pipeline.off(event, fn);
      };
    }
  }, [enabled, event, fn]);
};
