import { useCallback } from 'react';
import { SurveyActionType, SurveyConditionType, SurveyQuestionConditionType } from '@enums';
import type { SurveyLogic, SurveyQuestionOption, SurveyTagging } from '@/types';
import { SurveyAction } from '@/components/SurveyBuilder.Logic/Preview.Action';
import { TaggingAction } from '@/components/SurveyBuilder.Tagging/Preview.Action';
import { SurveyMetadata } from '../Metadata';

export const useGetOptionsSelectedLogic = (questionLogic: SurveyLogic.Item[]) => {
  return useCallback((option: SurveyQuestionOption) => {
    const filteredLogic = questionLogic
    .reduce<FilteredLogic[]>((acc, x) => {
      const conditionType = filterConditions(x.conditions, option);

      if (conditionType) {
        return acc.concat({
          action: x.action,
          conditionType,
        });
      }

      return acc;
    }, []);

    return getLogicMetadata(filteredLogic);
  }, [questionLogic]);
};

export const useGetOptionsSelectedTagging = (tagging: SurveyTagging.Item[]) => {
  return useCallback((option: SurveyQuestionOption) => {
    const filteredLogic = tagging
    .reduce<FilteredTagging[]>((acc, x) => {
      const conditionType = filterConditions(x.conditions, option);

      if (conditionType) {
        return acc.concat({
          action: x.action,
          conditionType,
        });
      }

      return acc;
    }, []);

    return getTaggingMetadata(filteredLogic);
  }, [tagging]);
};

type FilteredLogic = {
  action: SurveyLogic.Action;
  conditionType: SurveyQuestionConditionType;
};

type FilteredTagging = {
  action: SurveyTagging.Action;
  conditionType: SurveyQuestionConditionType;
};

function filterConditions(conditions: SurveyLogic.ItemCondition[], option: SurveyQuestionOption): SurveyQuestionConditionType {
  if (conditions.length > 1) return null;
  if (conditions[0].conditionType !== SurveyConditionType.Question) return null;
  const condition = conditions[0].data;

  if ([SurveyQuestionConditionType.OptionSelected, SurveyQuestionConditionType.OptionNotSelected].includes(condition.type)) {
    if ((condition as SurveyLogic.OptionNotSelectedCondition).value.option.identifier === option.base.identifier) {
      return condition.type;
    }
  }
}

function getTaggingMetadata(items: FilteredTagging[]) {
  return items.map((m, i) => {

    const Item = (
      <>
        <TaggingAction action={m.action} />
        {m.conditionType === SurveyQuestionConditionType.OptionSelected
          ? ' if selected'
          : ' if not selected'
        }
      </>
    );

    return (
      <SurveyMetadata
        key={i}
        items={[Item]} />
    );
  });
}

function getLogicMetadata(items: FilteredLogic[]) {
  return items.map((m, i) => {

    const Item = (
      <>
        <SurveyAction action={m.action} />
        {m.conditionType === SurveyQuestionConditionType.OptionSelected
          ? ' if selected'
          : ' if not selected'
        }
      </>
    );

    return (
      <SurveyMetadata
        key={i}
        items={[Item]}
        classes={{ red: m.action.type === SurveyActionType.Disqualification }} />
    );
  });
}