import http from '@services/http';
import * as xform from '@transformers';
import type { WorkspaceObjects as WS } from '@services/api/interfaces';
import type { Workspaces as AdminWS } from '$admin/api/interfaces';

export const getObjectAccess = ({ objectId, workspaceId }: WS.GetObjectAccess.Request) => {
  return http.get(`/admin/workspaces/${workspaceId}/objects/${objectId}/access`)
    .then((data: WS.GetObjectAccess.Response) => ({
      items: data.items,
      object: xform.normalizeWorkspaceObject(data.object),
      workspace: xform.normalizeWorkspace(data.workspace),
    }));
};

export const moveObject = (data: AdminWS.MoveObject.Request) => {
  return http.post(`/admin/workspaces/${data.workspaceId}/objects/${data.objectId}/move`, {
    parentObjectId: data.parentObjectId,
  }).then((data: AdminWS.MoveObject.Response) => ({
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const saveObjectAccess = (data: WS.SaveObjectAccess.Request) => {
  return http.post(`/admin/workspaces/${data.workspaceId}/objects/${data.objectId}/access`, {
    items: data.items,
  });
};

export const updateObjectOwnership = (data: AdminWS.UpdateObjectOwnership.Request): Promise<AdminWS.UpdateObjectOwnership.Response> => {
  return http.post(`/admin/workspaces/${data.workspaceId}/objects/${data.objectId}/owner`, {
    ownerId: data.ownerId,
  });
};