import { useCallback, useEffect, useRef } from 'react';
import { useLocationComparator } from '@utils';
import { ScrollSubscriptionContext } from './Context';
import type { ScrollRestoration } from './interfaces';

type Props = {
  children: React.ReactElement;
};

const ScrollRestorationContainer = (props: Props) => {
  const handlers = useRef(new Set<ScrollRestoration.Subscriber>());
  const compare = useLocationComparator();

  const on = useCallback((fn: ScrollRestoration.Subscriber) => {
    handlers.current.add(fn);
  }, []);

  const off = useCallback((fn: ScrollRestoration.Subscriber) => {
    handlers.current.delete(fn);
  }, []);

  const shouldPreventRestoration = useCallback(() => {
    return Array.from(handlers.current, compare).some(bool => bool);
  }, [
    compare,
  ]);

  useEffect(() => {

    if (!shouldPreventRestoration()) {
      window.scrollTo(0, 0);
    }

  }, [
    shouldPreventRestoration,
  ]);

  return (
    <ScrollSubscriptionContext.Provider value={{ off, on }}>
      {props.children}
    </ScrollSubscriptionContext.Provider>
  );
};

export { ScrollRestorationContainer };
export default ScrollRestorationContainer;