import { Accordion } from '@/components/Accordion';
import { EditableComplianceSurveyResponse, ComplianceSurveyResponse } from '@/components/SurveyResponse';
import type { ComplianceScreenerProps, EditableComplianceScreenerProps, ReadOnlyComplianceScreenerProps } from './interfaces';
import styles from './style.css';

const Screener = (props: Omit<ComplianceScreenerProps, 'survey'>) => (
  <Accordion
    className={styles.accordion}
    grows={false}
    label='Compliance Screener'
    showCount={false}>
    {props.children}
  </Accordion>
);

const ComplianceScreener = (props: ReadOnlyComplianceScreenerProps) => (
  <Screener>
    <ComplianceSurveyResponse
      response={props.survey} />
  </Screener>
);

const EditableComplianceScreener = (props: EditableComplianceScreenerProps) => (
  <Screener>
    <EditableComplianceSurveyResponse
      onSave={props.onSave}
      response={props.survey} />
  </Screener>
);

export {
  ComplianceScreener,
  EditableComplianceScreener,
};
export default ComplianceScreener;