import { useCallback, useState } from 'react';
import { format, compareDesc } from 'date-fns';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import * as $api from '@api';
import { useDiscardSurveyDraft, useDiscardSurveyDrafts } from '@utils/api/survey';
import type { SurveyDraft } from '@/types';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Button } from '@/components/Button';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks/useModal';
import styles from './style/DraftNotification.css';

type DraftModalProps = {
  drafts: SurveyDraft[];
  onDraftLoaded?: () => void;
  onDraftDiscarded?: (surveyVersionId?: number) => void;
} & Pick<ModalProps, 'open' | 'onClose'> & ISurveyId;

const DraftModal = ({ drafts, onDraftDiscarded, onDraftLoaded, surveyId, ...modalProps }: DraftModalProps) => {
  const [_, dispatch] = useSurveyBuilderState();
  const [discardingDrafts, setDiscardingDrafts] = useState<number[]>([]);

  const { mutate: discardDraft } = useDiscardSurveyDraft({ surveyId }, {
    onMutate: data => {
      setDiscardingDrafts(v => [...v, data.surveyVersionId]);
    },
    onSuccess: (res, variables) => {
      onDraftDiscarded?.(variables.surveyVersionId);
    },
    onSettled: (_a, _b, data) => {
      setDiscardingDrafts(v => v.filter(x => x !== data.surveyVersionId));
    },
  });

  const { mutate: discardDrafts, isLoading: isDiscardingAll } = useDiscardSurveyDrafts({ surveyId });

  const loadDraft = useCallback(({ surveyVersionId }: ISurveyVersionId) => {
    $api.surveys.draft.getSurveyDraft({ surveyVersionId }).then(v => {
      dispatch({
        type: 'draft-loaded',
        draft: v,
      });
      if (modalProps.onClose) {
        modalProps.onClose();
      }
      onDraftLoaded?.();
    });
  }, [dispatch, onDraftLoaded, modalProps]);

  return (
    <Modal {...modalProps}>
      <table className={styles.draftTable}>
        <thead>
          <tr>
            <th>
              Last Modified At
            </th>
            <th>
              Num. Questions
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {drafts.sort((a, b) => compareDesc(a.lastModifiedAt, b.lastModifiedAt)).map(d => {
            return (
              <tr className={styles.draftRow} key={d.surveyVersionId}>
                <td>
                  {format(d.lastModifiedAt, 'yyyy-MM-dd p')}
                </td>
                <td className={styles.questionsCell}>
                  {d.questionCount}
                </td>
                <td className={styles.buttonCell}>
                  <Button
                    onClick={() => loadDraft(d)}
                    className={styles.loadBtn}
                    variant='brick'>
                    Load Draft
                  </Button>
                  <ButtonActivityIndicator
                    onClick={() => discardDraft(d)}
                    loading={discardingDrafts.includes(d.surveyVersionId)}
                    color='destructive'
                    variant='brick'>
                    Discard Draft
                  </ButtonActivityIndicator>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ButtonActivityIndicator
        loading={isDiscardingAll}
        color='destructive'
        variant='brick'
        onClick={() => discardDrafts()}>
        Discard All
      </ButtonActivityIndicator>
    </Modal>);
};

export const useDraftModal = () => useModal(DraftModal);