import { useEffect, useMemo } from 'react';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import {
  useRegisterChatPageContext,
  ChatContextType,
  type BrandInsightsPageContext,
} from '@/brand-insights';
import { path, querykey } from '@/consts';
import { WorkspaceObjectType } from '@/enums';
import * as $api from '@/services/api';
import { DownloadFileContainer } from '@/containers/Workspace.File/DownloadFileContainer';
import { GroupFileTagsContainer } from '@/containers/GroupTags';
import {
  WorkspaceFilePreviewContext, WorkspaceFilePreviewLoadingContext,
  WorkspaceFilePreviewReloadContext, WorkspaceFileStateContext,
} from '@/containers/Workspace.File.Preview/Context';
import FileTranscriptStatusContainer from '@/containers/Workspace.File/TranscriptAvailableContainer';
import FileSummaryContainer from '@/containers/Workspace.File/SummaryContainer';
import { WorkspaceBreadcrumbsContext } from '@/containers/WorkspaceObject/Context';
import { FilePreviewSocketContainer } from '@/containers/Workspace.File.Preview/SocketContainer';

type Props =
  ChildrenProps;

type RouteParams = {
  fileId: string;
  projectId: string;
};

const useRouteParams = () => {
  const params = useParams<RouteParams>();
  return useMemo(() => ({
    fileId: +params.fileId,
    projectId: +params.projectId,
  }), [params.fileId, params.projectId]);
};

export const ProjectFilePreviewContainer = (props: Props) => {

  const params = useRouteParams();
  const history = useHistory();

  const query = useQuery({ queryKey: querykey.Projects.FilePreview.Get(params), queryFn: () => {

    return $api.projects.files.getFilePreview({
      fileId: params.fileId,
      projectId: params.projectId,
    });
  }, onError: (error: AxiosResponse) => {
    if (error.status === 404) {
      history.replace(path.Projects.FileNotFound);
    }
  } });

  const documentContext: BrandInsightsPageContext.Document = useMemo(() => {
    if (!query.data?.features?.insightsChat) return null;

    return {
      type: ChatContextType.Document,
      data: {
        documentId: params.fileId,
        projectId: params.projectId,
      },
      metadata: {
        name: query.data?.file?.name,
      },
    };
  }, [query.data?.file?.name, params.fileId, params.projectId, query.data?.features?.insightsChat]);

  const register = useRegisterChatPageContext();
  useEffect(() => {
    if (!documentContext) return;

    if (documentContext.data.documentId && documentContext.metadata.name) {
      register(documentContext);
    }
  }, [register, documentContext]);

  const breadcrumbs = useMemo(() => {
    const items = query.data?.breadcrumbs;

    if (!items) {
      return [];
    }

    const projectIndex = items.findIndex(f => f.typeId === WorkspaceObjectType.ProjectParent);
    const brandIndex = items.findIndex(f => f.typeId === WorkspaceObjectType.Brand) || Number.MAX_SAFE_INTEGER;

    const cutOff = Math.min(projectIndex, brandIndex);

    return items.slice(cutOff, items.length);
  }, [query.data?.breadcrumbs]);

  const state = {
    capabilities: query.data?.capabilities,
    features: query.data?.features,
    project: query.data?.project,
    meta: query.data?.meta,
    file: query.data?.file,
    object: query.data?.object,
    transcript: query.data?.transcript,
  };

  if (query.isFetched && !query.data?.preview) {
    const e = query.error;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Workspaces.FileNotFound} />;
  }

  return (
    <WorkspaceFilePreviewReloadContext.Provider value={query.refetch}>
      <WorkspaceFileStateContext.Provider value={state}>
        <WorkspaceBreadcrumbsContext.Provider value={breadcrumbs}>
          <WorkspaceFilePreviewContext.Provider value={query.data?.preview}>
            <WorkspaceFilePreviewLoadingContext.Provider value={query.isLoading}>
              <GroupFileTagsContainer fileId={params.fileId}>
                <DownloadFileContainer fileId={params.fileId} workspaceId={query.data?.object?.workspaceId}>
                  <FilePreviewSocketContainer fileId={params.fileId}>
                    <FileTranscriptStatusContainer fileId={params.fileId} transcript={query.data?.transcript}>
                      <FileSummaryContainer fileId={params.fileId}>
                        {props.children}
                      </FileSummaryContainer>
                    </FileTranscriptStatusContainer>
                  </FilePreviewSocketContainer>
                </DownloadFileContainer>
              </GroupFileTagsContainer>
            </WorkspaceFilePreviewLoadingContext.Provider>
          </WorkspaceFilePreviewContext.Provider>
        </WorkspaceBreadcrumbsContext.Provider>
      </WorkspaceFileStateContext.Provider>
    </WorkspaceFilePreviewReloadContext.Provider>
  );
};

export default ProjectFilePreviewContainer;
