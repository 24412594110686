import { useState, useMemo, useCallback } from 'react';
import { PlusCircle, X, Check, Edit } from 'react-feather';
import type { ExclusiveOptionsQuestion } from '@/types';
import { cx } from '@utils';
import { useSurveyBuilderState, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import { Switch } from '@/components/Switch';
import { Button } from '@/components/Button';
import { DropDown } from '@/components/DropDown';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal } from '@/components/Modal/Modal';
import { Header } from '@/components/Modal/Header';
import { useModal } from '@/components/Modal/hooks';
import styles from './style/ExclusiveOptions.SettingsModal.css';

type Props = {
  question: ExclusiveOptionsQuestion.Question;
  section: ExclusiveOptionsQuestion.OptionSection;
  options: ExclusiveOptionsQuestion.Option[];
} & Pick<ModalProps, 'open' | 'onClose'>;

export const SettingsModal = ({ open, onClose, section, question, ...props }: Props) => {
  const [state, setState] = useState(section.settings ?? { exclusiveGroups: [], requireSelection: false });
  const [editingId, setEditingId] = useState<string>(null);
  const [surveyState, dispatch] = useSurveyBuilderState();
  const submitSurveyDraft = useSubmitSurveyDraft();

  //  const options = useMemo(() => props.options.filter(o => o.metadata.sectionId === section.identifier), [props.options, section.identifier]);
  const options = useMemo(() => props.options.sort((a, b) => a.metadata.sectionId === section.identifier ? -1 : 1), [props.options, section.identifier]);

  const canSave = editingId == null;

  const toggleRequireResponse = useCallback((val: boolean) => {
    setState(v => ({
      ...v,
      requireSelection: val,
    }));
  }, [setState]);

  const addGroupEntry = useCallback((groupKey: string) => {
    setState(v => {
      const keyOptions = v.exclusiveGroups.find(g => g.primaryOption === groupKey)?.exclusiveOptions ?? [];
      const inUseOptions = [groupKey, ...keyOptions];
      const nextEntry = options.find(o => !inUseOptions.includes(o.base.identifier))?.base.identifier;
      return {
        ...v,
        exclusiveGroups: v.exclusiveGroups.map(g => {
          if (g.primaryOption === groupKey) {
            return {
              ...g,
              exclusiveOptions: [...g.exclusiveOptions, nextEntry],
            };
          } else {
            return g;
          }
        }),
      };
    });
  }, [setState, options]);

  const addNewGroup = useCallback(() => {
    const existingGroupKeys = state.exclusiveGroups.map(g => g.primaryOption);
    const nextKey = options.find(o => !existingGroupKeys.includes(o.base.identifier))?.base.identifier;

    setState(v => {
      return {
        ...v,
        exclusiveGroups: [
          ...v.exclusiveGroups, { primaryOption: nextKey, exclusiveOptions: [] },
        ],
      };
    });
    setEditingId(nextKey);
  }, [options, state.exclusiveGroups]);

  const updateGroupKey = useCallback((oldKey: string, newKey: string) => {
    if (oldKey === newKey) return;
    setState(v => {
      const exclusiveGroups = v.exclusiveGroups.map(g => {
        if (g.primaryOption === oldKey) {
          return {
            ...g,
            primaryOption: newKey,
          };
        } else {
          return g;
        }
      });

      return {
        ...v,
        exclusiveGroups,
      };
    });
    setEditingId(newKey);
  }, [setState]);

  const updateGroupValue = useCallback((key: string, oldVal: string, newVal: string) => {
    function replaceVal(arr: string[], oldVal: string, newVal: string) {
      return arr.map(a => a === oldVal ? newVal : a);
    }

    setState(v => ({
      ...v,
      exclusiveGroups: v.exclusiveGroups.map(g => {
        if (g.primaryOption === key) {
          return {
            ...g,
            exclusiveOptions: replaceVal(g.exclusiveOptions, oldVal, newVal),
          };
        } else {
          return g;
        }
      }),
    }));
  }, [setState]);

  const removeGroup = useCallback((key: string) => {
    setState(v => {
      return {
        ...v,
        exclusiveGroups: v.exclusiveGroups.filter(g => g.primaryOption != key),
      };
    });
    setEditingId(null);
  }, [setState]);

  const saveSettings = useCallback(() => {
    const optionSections = question.settings.optionSections.map(s => {
      if (s.identifier === section.identifier) {
        return {
          ...s,
          settings: state,
        };
      } else {
        return s;
      }
    });
    dispatch({
      'type': 'update-question-settings',
      questionIdentifier: question.base.identifier,
      settings: {
        ...question.settings,
        optionSections,
      },
    });
    submitSurveyDraft();
    onClose();
  }, [dispatch, question, state, section, onClose, submitSurveyDraft]);

  const EditingGroup = useCallback(({ k, v }: { k: string; v: string[] }) => {
    const canSave = v.length && v.every(Boolean);
    return (
      <>
        <div className={cx(styles.group, styles.editingGroup)}>
          <div className={styles.groupColumn}>
            <div className={styles.groupDropdown}>
              <OptionDropdown
                excludeIds={state.exclusiveGroups.map(g => g.primaryOption)}
                options={options}
                selectedId={k}
                onSelect={v => updateGroupKey(k, v)} />
            </div>
          </div>
          <div className={styles.groupColumn}>
            {v.map((x, i) => (
              <div key={i} className={styles.groupDropdown}>
                <OptionDropdown
                  excludeIds={[...v, k]}
                  options={options}
                  selectedId={x}
                  onSelect={v => updateGroupValue(k, x, v)} />
              </div>
            ))}
            {v.length + 1 < options.length &&
              <div className={styles.addBtn} onClick={() => addGroupEntry(k)}>
                <PlusCircle /> Add
              </div>
            }
          </div>
        </div>
        <div className={styles.editControls}>
          <X className={styles.removeBtn} onClick={() => removeGroup(k)} />
          <Check className={cx(styles.saveGroup, canSave ? null : styles.disabled)} onClick={canSave ? () => setEditingId(null) : null} />
        </div>
      </>
    );
  }, [addGroupEntry, options, state.exclusiveGroups, updateGroupKey, updateGroupValue, removeGroup, setEditingId]);

  const ExclusiveOptionSettings = useCallback(() => {
    if (section.type != 'multi-select') return null;

    return (
      <>
        <div className={styles.groupSectionHeader}>
          Exclusive Selection Groups
        </div>
        <div>
          <div className={styles.group}>
            <div className={styles.groupColumn}>
              <div className={styles.groupHeader}>
                Options
              </div>
            </div>
            <div className={styles.groupColumn}>
              <div className={styles.groupHeader}>
                Exclusive Set
              </div>
            </div>
          </div>
          {state.exclusiveGroups.map(({ primaryOption: k, exclusiveOptions: v }) => {
            if (editingId == k) {
              return <EditingGroup
                key={k}
                k={k}
                v={v} />;
            } else {
              const option = options.find(o => o.base.identifier === k);
              const optionValue = parseSurveyRichText(option?.value);
              return (
                <div key={k} className={styles.group}>
                  <div className={styles.groupColumn}>
                    <div>{optionValue}</div>
                  </div>
                  <div className={styles.groupColumn}>
                    <div>
                      {v.map(x => options.find(o => o.base.identifier === x)?.ordinal).sort((a, b) => a - b).map(o => `A${o}`).join(', ')}
                    </div>
                  </div>
                  <div className={styles.editColumn}>
                    {!editingId &&
                      <>
                        <Edit className={styles.editBtn} onClick={() => setEditingId(k)} />
                        <X className={styles.removeBtn} onClick={() => removeGroup(k)} />
                      </>}
                  </div>
                </div>
              );
            }
          })}
        </div>
        {!!options.length && !editingId &&
          <div className={styles.addBtn} onClick={addNewGroup}>
            <PlusCircle /> Create Exclusive Group
          </div>
        }
      </>
    );
  }, [EditingGroup, addNewGroup, removeGroup, editingId, setEditingId, options, section.type, state.exclusiveGroups]);

  return (
    <Modal open={open} onClose={onClose}>
      <Header>
        {`Apply exclusivity logic to the ${section.value} group`}
      </Header>
      <div className={styles.selectionRequirement}>
        <div>
          Require a selection for this group?
        </div>
        <Switch active={state.requireSelection} onClick={toggleRequireResponse} />
      </div>
      <ExclusiveOptionSettings />
      <div className={styles.modalControls}>
        <Button
          variant='brick'
          color='destructive'
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='brick'
          color='primary'
          onClick={saveSettings}
          disabled={!canSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

type DropDownProps = {
  selectedId: string;
  excludeIds: string[];
  onSelect: (val: string) => void;
} & Pick<Props, 'options'>;
const OptionDropdown = ({ options, selectedId, onSelect, excludeIds }: DropDownProps) => {
  const items = useMemo(() =>
    options.filter(o => o.base.identifier === selectedId || !excludeIds.includes(o.base.identifier))
      .map(o => ({ id: o.base.identifier, value: o.value })), [options, selectedId, excludeIds]);

  const selected = items.find(i => i.id === selectedId);

  return (
    <DropDown
      items={items}
      getItemValue={o => parseSurveyRichText(o.value)}
      onSelect={x => onSelect(x.id)}
      text={selected ? parseSurveyRichText(selected.value) : null} />
  );
};

export const useSettingsModal = () => useModal(SettingsModal);