import * as enums from '@enums';
import type { VICS } from '@/types';
import Tag from '@/components/Tag';
import { Title } from './Title';
import styles from './style.css';

type TagProps = VICS;

export const VICSTag = (props: TagProps) => {
  return (
    <Tag
      item={{
        id: props.id,
        name: props.name,
        type: enums.VICSType.getSearchType(props.typeId),
      }} />
  );
};

type TagsProps = {
  items: TagProps[];
  title: string;
};

export const VICSTags = (props: TagsProps) => {
  if (!props.items.length) return null;

  return (
    <div className={styles.row}>
      <Title text={props.title} />
      <div className={styles.tags}>
        {props.items.map(x =>
          <VICSTag
            key={x.id}
            {...x} />)}
      </div>
    </div>
  );
};