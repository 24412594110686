import { memo } from 'react';
import type { ImageMarkupAnswerProps } from './interfaces';
import styles from './style/ImageMarkup.css';

type Props =
  ImageMarkupAnswerProps;

export const ImageMarkupAnswerValue = memo((props: Props) => {
  return (
    <>
      <div className={styles.root}>
        <img className={styles.markedUpImage} src={props.answer.markedUpImage} />
      </div>
    </>
  );
});

export default ImageMarkupAnswerValue;
