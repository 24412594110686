import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { TranscribeRT } from '@/types';

type Props = {
  tagSummaryId: number;
} & ITranscriptId;
type Data = {
  citations: TranscribeRT.TaggedMoment[];
};
type QueryKey = readonly [string, Props];

type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchTranscriptTagSummaryCitations = (props: Props, options?: Options) => {

  return useQuery({
    queryKey: ['get:transcripts/conference-tags/summaries/citations', props], queryFn: ({ queryKey }) => {
      const [, { transcriptId, tagSummaryId }] = queryKey as QueryKey;
      return api.transcripts.getTagSummaryCitations({
        transcriptId,
        tagSummaryId,
      });
    },
    ...options,
  });
};