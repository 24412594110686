import { useCallback } from 'react';
import type { InvalidContentHandler } from '@remirror/core';
import { useRemirror, ReactComponentExtension } from '@remirror/react';
import type { PlaceholderOptions,
  LinkOptions } from 'remirror/extensions';
import {
  DocExtension, TextExtension,
  ParagraphExtension,
  PlaceholderExtension,
  BoldExtension, ItalicExtension,
  LinkExtension, UnderlineExtension,
  BulletListExtension, OrderedListExtension,
} from 'remirror/extensions';
import type { SurveyRichText } from '@/types/survey.rich-text';
import type { ImageOptions } from '@/components/Remirror';
import { ImageExtension, TextColorExtension } from '@/components/Remirror';
import { useRemirrorStyles } from './useRemirrorStyles';

type Props = {
  initialState?: SurveyRichText.RootNode;
  placeholder?: string;
};

export const useBasicSurveyRichTextEditor = ({ initialState, placeholder }: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  // useRemirrorStyles();

  const imageExtensionParams: ImageOptions = {
    enableResizing: true,
  };

  const linkExtensionParams: LinkOptions = {
    autoLink: true,
    defaultTarget: '_blank',
    // defaultProtocol: 'https:',
  };

  const placeholderExtensionParams: PlaceholderOptions = {
    placeholder,
  };

  return useRemirror({
    content: initialState,
    extensions: () => [
      new DocExtension({}),
      new PlaceholderExtension(placeholderExtensionParams),
      new ParagraphExtension({}),
      new TextExtension(),
      new ReactComponentExtension({}),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new TextColorExtension({}),
      new LinkExtension(linkExtensionParams),
      new ImageExtension(imageExtensionParams),
      new BulletListExtension({}),
      new OrderedListExtension({}),
    ],
    onError,
  });

};