import { useContext, useCallback } from 'react';
import { useProjectOrLatestChild, useSuspendInitialLoading } from '@containers/GroupProject/hooks';
import { ProjectCallsQueryContext, ProjectCallsQueryParamsContext } from '@containers/Group.Project.Calls/Context';
import { useHasSchedulingPermission } from '@containers/Store/hooks';
import { ButtonOutlined } from '@presentation';
import { useSchedulingRequiresBoosting } from '@/access/admin/components/Scheduling/hooks/useSchedulingRequiresBoosting';
import { useEntityBoostModal } from '@/components/Modal/EntityBoost';
import * as Table from '@/components/Table';
import { RespondentSetupContext } from '$admin/components/Scheduling/Context';
import { CallsTable } from './Calls.Table';
import type { CallItem } from './interfaces';
import styles from './style/Project.Calls.css';

type Props = unknown;

export const ProjectCalls = (props: Props) => {
  const query = useContext(ProjectCallsQueryContext);
  const [qp, setQuery] = useContext(ProjectCallsQueryParamsContext);
  const project = useProjectOrLatestChild();
  const respondent = useContext(RespondentSetupContext);
  const hasSchedulingPermission = useHasSchedulingPermission();
  const [toggleEntityBoostModal, EntityBoostModal] = useEntityBoostModal();

  const pagination = {
    pageCount: query.data?.pagination?.pageCount ?? 0,
    totalCount: query.data?.pagination?.totalCount ?? 0,
  };

  const loading = query.isInitialLoading && !query.data;

  const data = loading
    ? getLazyTableData(25)
    : query.data?.items ?? [];

  const empty = query.isFetchedAfterMount && !loading && !data.length;

  const suspended = useSuspendInitialLoading({
    data,
    empty,
    isInitialLoading: query.isInitialLoading,
    loading,
    pagination,
  }, 500);

  const requiresBoosting = useSchedulingRequiresBoosting(project);

  const handleCreateCall = useCallback(() => {
    if (requiresBoosting) {
      toggleEntityBoostModal();
    } else {
      respondent.setContext({ projectId: project?.id });
    }
  }, [requiresBoosting, project?.id, respondent, toggleEntityBoostModal]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <Table.Layout.Box>
          <Table.Layout.Header title="Calls">
            <div className={styles.actions}>
              <ButtonOutlined
                className={styles.btn}
                color="blue"
                disabled={!(hasSchedulingPermission && project?.id)}
                fontWeight="bold"
                onClick={handleCreateCall}>
                Create Call
              </ButtonOutlined>
            </div>
          </Table.Layout.Header>

          <CallsTable
            data={suspended.data}
            empty={suspended.empty}
            loading={suspended.loading}
            pagination={suspended.pagination}
            query={qp}
            setQuery={setQuery} />
        </Table.Layout.Box>
      </div>
      <EntityBoostModal projectId={project?.id} />
    </div>
  );
};

ProjectCalls.displayName = 'Project.Calls';

const getLazyTableData = <T extends CallItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};